import { forwardRef } from 'react'

import classNames from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

import { elementRefType } from '~/src/Lib/PropTypes'
import { EMPTY_OBJECT } from '~/src/Lib/Utils/constants'

import { buildThemeFromColor } from '../Theme/mui'

const useStyles = makeStyles(theme => ({
  hero: {
    fontSize: 18,
    fontFamily: theme.typography.boldFontFamily,
    padding: theme.spacing(1.5, 4),
  },
  primaryAction: {
    minWidth: '14rem',
    padding: theme.spacing(0.75, 4),
  },
}), { name: 'Aroya/Button' })

/**
 * The Button component wraps and extends the @mui/material Button component.
 * @component
 * @alias Button
 * @composes
 * from: '@mui/material/Button'
 * link: https://material-ui.com/api/button/#props
 * @example
 * {() => {
 *   const [clicked, setClicked] = React.useState(null)
 *   const buttonOnClick = ({ target }) => {
 *     setClicked(target.closest('button').textContent)
 *   }
 *   return (
 *     <>
 *       {clicked ? <Box textAlign="center" fontSize="2rem">clicked: {clicked}</Box> : null}
 *       <Box
 *         display="grid"
 *         alignContent="center"
 *         alignItems="center"
 *         justifyContent="space-around"
 *         gridTemplateColumns="repeat(auto-fill, minmax(14rem, 1fr))"
 *         gap="1rem"
 *       >
 *         <Button hero onClick={buttonOnClick}>hero button</Button>
 *         <Button variant="text" onClick={buttonOnClick}>text button</Button>
 *         <Button primaryAction onClick={buttonOnClick}>primary action</Button>
 *         <Button color="default" onClick={buttonOnClick}>default color</Button>
 *         <Button color="secondary" onClick={buttonOnClick}>secondary color</Button>
 *         <Button danger hero onClick={buttonOnClick}>danger hero</Button>
 *       </Box>
 *     </>
 *   )
 * }}
 */
const ButtonComponent = ({
  buttonRef = null,
  children,
  className,
  danger = false,
  hero = false,
  muiClasses = EMPTY_OBJECT,
  primaryAction = false,
  ...passthru
}) => {
  const classes = useStyles()

  const buttonContent = (
    <Button
      classes={muiClasses}
      color="primary"
      variant="contained"
      {...passthru}
      className={classNames({
        [className]: Boolean(className),
        [classes.hero]: hero,
        [classes.primaryAction]: primaryAction,
      })}
      ref={buttonRef}
    >
      {children}
    </Button>
  )

  return danger
    ? (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme => buildThemeFromColor(theme.palette.error)}>{buttonContent}</ThemeProvider>
      </StyledEngineProvider>
    )
    : buttonContent
}
ButtonComponent.displayName = 'Button'
ButtonComponent.propTypes = {
  buttonRef: elementRefType,
  children: PropTypes.node.isRequired,
  danger: PropTypes.bool,
  hero: PropTypes.bool,
  muiClasses: PropTypes.objectOf(PropTypes.string),
  /** Make the button match Aroya design-spec primary page action padding and button width */
  primaryAction: PropTypes.bool,
}

const RefForwarding = forwardRef((props, ref) => <ButtonComponent {...props} buttonRef={ref} />)

export default RefForwarding
