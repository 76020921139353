import { SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

const displayName = 'Icon/ECBolt'

export const path = 'M11 15H6L13 1V9H18L11 23V15Z'

export const ECBoltIconComponent = props => {
  const { palette } = useTheme()
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { pore_ec: dataType = {} } = dataTypes
  const { color = palette.yellow.main } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path data-iconid="aroya-ec-bolt" d={path} fill={fill} />
    </SvgIcon>
  )
}
ECBoltIconComponent.displayName = displayName

export default ECBoltIconComponent
