import {
  always,
  either,
  pipe,
  prop,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import createLogger from '~/src/Lib/Logging'

const logger = createLogger('Flags/Has#utils')

/**
 * Given a user's set of available features, check if the user has the required features
 * @param {Set} availableFeatures - Set of available features for this user
 * @param {import('~/src/Flags/flags.types').Flag} features - Required feature(s) to check
 * @param {boolean} any - If more than one feature, allow if user has at least one.
 * @returns
 */
export const available = (availableFeatures = new Set(), features = '', any = false) => {
  if (!features) return true
  /** @type {import('~/src/Flags/flags.types').Flag[]} */
  const requiredFeatures = Array.isArray(features) ? features : Array.of(features)
  logger.debug({ availableFeatures, requiredFeatures, any })
  return requiredFeatures[any ? 'some' : 'every'](flag => {
    const has = typeof flag === 'function' ? flag(availableFeatures) : availableFeatures.has(flag)

    return has
  })
}

const emptyAvailable = new Set()

export const useFlags = pipe(
  useConnect.bind(null, 'selectAvailableFeatures'),
  either(prop('availableFeatures'), always(emptyAvailable))
)

export const useHas = (features, any) => {
  const availableFeatures = useFlags()
  if (!features.length) return true
  return available(availableFeatures, features, any)
}
