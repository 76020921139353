import ms from 'milliseconds'
import { normalize } from 'normalizr'

import createEntityBundle, {
  defaultAsyncHandlersFactory,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

import { UserSetting as schema } from '../Schemas'
import { createAppIsReadySelector } from '../utils'

const STALE_AFTER = ms.minutes(15)
const fetchAll = getAsyncActionIdentifiers('fetch', 'userSettings')
const fetchAllHandlers = defaultAsyncHandlersFactory(fetchAll.types, { action: 'fetch' })

const userSettingsEntityBundle = createEntityBundle({
  name: 'userSettings',
  idAttribute: 'key',
  apiConfig: {
    schema,
    snackbar: false,
    save: {
      handler: ({ apiFetch, payload }) => apiFetch(`/userSettings/byKey/${payload.key}/`, payload, { method: 'POST' }),
    },
    delete: {
      handler: ({ apiFetch, payload }) => apiFetch(`/userSettings/byKey/${payload}/`, null, { method: 'DELETE' }),
    },
    fetch: {
      handler: ({ apiFetch, payload, dispatch }) => apiFetch(`/userSettings/byKey/${payload.id}/`)
        .then(response => {
          dispatch({ actionCreator: 'doUpdateManyMySettings', args: [[response]] })
          return response
        }),
    }
  },
})

export default {
  ...userSettingsEntityBundle,
  reducer: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    if (action.type in fetchAllHandlers) {
      const nextState = fetchAllHandlers[action.type](state, action)
      return action.type == fetchAll.types.succeed ? { ...nextState, fetchedAllAt: Date.now() } : nextState
    }
    return userSettingsEntityBundle.reducer(state, action)
  },
  doUserSettingsFetch: () => async ({ apiFetch, dispatch }) => {
    dispatch({ type: fetchAll.types.start, payload: fetchAll.types.prefix })
    try {
      const response = await apiFetch('/userSettings/')
      const { entities } = normalize(response.results, [schema])
      dispatch({ actionCreator: 'doEntitiesReceived', args: [entities] })
      dispatch({ actionCreator: 'doUpdateManyMySettings', args: [response.results] })
      dispatch({ type: fetchAll.types.succeed, payload: fetchAll.types.prefix })
      return true
    } catch (error) {
      dispatch({ type: fetchAll.types.fail, payload: fetchAll.types.prefix, error })
      return false
    }
  },
  reactFetchUserSettings: createAppIsReadySelector({
    dependencies: ['selectUserSettingsRoot', 'selectAvailableFeatures'],
    resultFn: ({ fetchedAllAt = 0, inflight = EMPTY_OBJECT, dirty = EMPTY_OBJECT }) => {
      if (inflight[fetchAll.types.prefix]) return null
      const now = Date.now()
      const lastErrorTs = dirty[fetchAll.types.prefix]?.lastError?.ts ?? 0
      if (now - lastErrorTs > STALE_AFTER && now - fetchedAllAt > STALE_AFTER) {
        return ({ actionCreator: 'doUserSettingsFetch' })
      }
      return null
    }
  }),
  persistActions: [
    ...userSettingsEntityBundle.persistActions,
    fetchAll.types.start,
    fetchAll.types.succeed,
    fetchAll.types.fail
  ],
}
