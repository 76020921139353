import { QueryClientProvider } from '@tanstack/react-query'
import { ReduxBundlerProvider, useConnect } from 'redux-bundler-hook'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { StylesProvider } from '@mui/styles'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import App from './App'
import { queryClient } from './IO/Api'
import createLogger from './Lib/Logging'
import themes from './UI/Theme'

const logger = createLogger('Root')

const UIWrapper = ({ AppMain, ...rest }) => {
  const { config, locale } = useConnect('selectConfig', 'selectLocale')
  const { [config.APP]: theme = themes.DEFAULT } = themes
  logger.debug('default theme:', config.APP, theme)
  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
            <AppMain {...rest} />
          </LocalizationProvider>
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  )
}

const StoreWrapper = ({ store, ...rest }) => (
  <ReduxBundlerProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <UIWrapper {...rest} />
    </QueryClientProvider>
  </ReduxBundlerProvider>
)

const Root = ({ AppMain = App, store, ...rest }) => (
  store
    ? <StoreWrapper {...rest} AppMain={AppMain} store={store} />
    : <UIWrapper {...rest} AppMain={AppMain} />
)

export default Root
