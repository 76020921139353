import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { ENTITIES_RECEIVED } from '~/src/Lib/createEntityBundle'
import { Recipe } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const RECIPE_LIST_SET_PAGE = 'RECIPE_LIST_SET_PAGE'
const RECIPE_LIST_SET_SORT = 'RECIPE_LIST_SET_SORT'
const RECIPE_LIST_SET_FILTER = 'RECIPE_LIST_SET_FILTER'
const RECIPE_LIST_SET_SEARCH = 'RECIPE_LIST_SET_SEARCH'

const additionalState = {
  searchTerms: '',
  currentFilter: 0,
  currentSort: ['name', 'asc'],
  currentPage: 1,
}

const recipeListBundle = createAsyncResourceBundle({
  name: 'recipeList',
  actionBaseType: 'RECIPE_LIST',
  retryAfter: ms.seconds(5),
  staleAfter: ms.minutes(60),
  getPromise: ({ dispatch, apiFetch, getState }) => {
    const { recipeList } = getState()
    const { searchTerms, currentFilter, currentSort, currentPage } = recipeList
    return apiFetch('/recipes/', {
      cultivarFilter: currentFilter,
      page: currentPage,
      ordering: `${(currentSort[1] === 'desc' ? '-' : '')}${currentSort[0]}`,
      search: searchTerms.length >= 3 ? searchTerms : '',
    }).then(payload => {
      const { entities, result } = normalize(payload, [Recipe])
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      return { count: result.length, results: result }
    })
  }
})

export default {
  ...recipeListBundle,
  reducer: reduceReducers(
    recipeListBundle.reducer,
    (state, action) => {
      switch (action.type) {
        case RECIPE_LIST_SET_PAGE:
          return { ...state, currentPage: action.payload }
        case RECIPE_LIST_SET_SORT:
          return { ...state, currentSort: action.payload }
        case RECIPE_LIST_SET_FILTER:
          return { ...state, currentPage: 1, currentFilter: action.payload }
        case RECIPE_LIST_SET_SEARCH:
          return { ...state, currentPage: 1, searchTerms: action.payload }
        default:
          if (!Object.keys(additionalState).every(key => key in state)) {
            return { ...additionalState, ...state }
          }
          return state
      }
    }
  ),
  doRecipeListSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: RECIPE_LIST_SET_PAGE, payload: page })
    store.doMarkRecipeListAsOutdated()
  },
  doRecipeListSetSort: sort => ({ dispatch, store }) => {
    dispatch({ type: RECIPE_LIST_SET_SORT, payload: sort })
    store.doMarkRecipeListAsOutdated()
  },
  doRecipeListSetFilter: filter => ({ dispatch, store }) => {
    dispatch({ type: RECIPE_LIST_SET_FILTER, payload: filter })
    store.doMarkRecipeListAsOutdated()
  },
  doRecipeListSetSearch: search => ({ dispatch, store }) => {
    dispatch({ type: RECIPE_LIST_SET_SEARCH, payload: search })
    if (search.length >= 3 || search.length === 0) {
      store.doMarkRecipeListAsOutdated()
    }
  },
  reactRecipeListFetch: createAppIsReadySelector({
    dependencies: [
      'selectRecipeListShouldUpdate',
      'selectPermittedActions',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, permittedActions, { pattern }) => {
      const urls = ['/recipes', '/cultivars', '/strainruns', '/harvests']
      if (shouldUpdate && urls.some(path => pattern.startsWith(path)) && permittedActions.has('view_recipe')) {
        return { actionCreator: 'doFetchRecipeList' }
      }
      return undefined
    }
  }),
  selectCurrentRecipeList: createSelector(
    'selectRecipes',
    'selectRecipeListRaw',
    (recipes, recipeListRaw) => {
      if (!recipeListRaw.data) {
        return []
      }
      return recipeListRaw.data.results.map(
        recipeId => (recipes[recipeId])
      ).filter(recipe => (recipe !== undefined))
    }
  ),
}
