import { prop, uniqBy } from 'ramda'

import {
  findBarcodeInSources,
  findEntityWhereTheScannedBarcodeExists,
  getNewQueue,
  getQueuedBySource,
  getSelectedSources,
} from '~/src/Flow/bundle/processing/utils'
import { PROCESSING_FLOW } from '~/src/Flow/constants'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

const INITIALIZE_PROCESSING_STATE = 'INITIALIZE_PROCESSING_STATE'
const PROCESSING_PROCESSED_ROOMS_CHANGE = 'PROCESSING_PROCESSED_ROOMS_CHANGE'
const PROCESSING_SOURCE_CLICK = 'PROCESSING_SOURCE_CLICK'
const PROCESSING_BARCODE_SCAN = 'PROCESSING_BARCODE_SCAN'
const PROCESSING_QUEUED_CLICK = 'PROCESSING_QUEUED_CLICK'
const PROCESSING_DESELECT_QUEUED = 'PROCESSING_DESELECT_QUEUED'
const PROCESSING_REMOVE_QUEUED = 'PROCESSING_REMOVE_QUEUED'
const PROCESSING_REVIEW_CLICK = 'PROCESSING_REVIEW_CLICK'
const PROCESSING_REVIEW_DONE = 'PROCESSING_REVIEW_DONE'
const PROCESSING_SEARCH_CHANGE = 'PROCESSING_SEARCH_CHANGE'
const SUBMIT_PROCESSING_FORM = 'SUBMIT_PROCESSING_FORM'
const CLEAR_PROCESSING_SNACKBAR_MESSAGE = 'CLEAR_PROCESSING_SNACKBAR_MESSAGE'
const RESET_PROCESSING_STATE = 'RESET_PROCESSING_STATE'
const REFRESH_PROCESSING_STATE = 'REFRESH_PROCESSING_STATE'
const UPDATE_PROCESSING_FLOW_ID = 'UPDATE_PROCESSING_FLOW_ID'

const initState = {
  sources: [],
  inventorySelected: [],
  selected: null,
  queue: [],
  queuedBySource: {},
  selectedItems: [],
  lastSelectedItem: null,
  sortedItemCategories: [],
  itemsByCategory: {},
  processedRooms: {},
  review: false,
  search: '',
  harvestId: null,
  id: null,
  lastSnackbarMessage: null,
  initialized: false,
}

export default {
  name: PROCESSING_FLOW,
  reducer: (state = initState, action = EMPTY_OBJECT) => {
    const { type, payload } = action
    switch (type) {
      case INITIALIZE_PROCESSING_STATE: {
        // if the state has already been initialized, we need to filter out sources that are part of the latest cached queue
        if (state.initialized) {
          const { queue, selected } = state
          return {
            ...state,
            sources: payload.sources,
            sortedItemCategories: payload.sortedItemCategories,
            itemsByCategory: payload.itemsByCategory,
            harvestId: payload.harvestId,
            inventorySelected: payload.inventorySelected,
            queuedBySource: selected
              ? getQueuedBySource(queue.filter(q => q.id !== selected.id))
              : getQueuedBySource(queue)
          }
        }

        return {
          ...state,
          ...payload,
          initialized: true,
        }
      }

      case PROCESSING_PROCESSED_ROOMS_CHANGE: {
        return {
          ...state,
          processedRooms: {
            ...state.processedRooms,
            [payload.itemTypeId]: payload.room,
          },
        }
      }

      case PROCESSING_SOURCE_CLICK: {
        const { selected: oldSelected } = state

        if (oldSelected) {
          const selectedSources = getSelectedSources(payload, oldSelected.sources)

          if (selectedSources.length) {
            return {
              ...state,
              selected: {
                ...oldSelected,
                sources: selectedSources,
              }
            }
          }

          if (oldSelected.isInQueue) {
            return {
              ...state,
              selected: {
                ...oldSelected,
                sources: [],
              },
            }
          }

          return {
            ...state,
            selected: null,
          }
        }

        return {
          ...state,
          selected: {
            // TODO: add some keys related to the newly created package
            cultivarId: payload.cultivar,
            cultivarName: payload.cultivarName,
            sources: [payload],
          },
        }
      }

      case PROCESSING_QUEUED_CLICK: {
        const { selected: oldSelected, queue } = state

        const selected = oldSelected?.id === payload.id ? null : payload
        return {
          ...state,
          selected,
          queuedBySource: selected
            ? getQueuedBySource(queue.filter(q => q.id !== selected.id))
            : getQueuedBySource(queue)
        }
      }

      case PROCESSING_DESELECT_QUEUED: {
        return {
          ...state,
          selected: null,
          queuedBySource: getQueuedBySource(state.queue),
        }
      }

      case PROCESSING_REMOVE_QUEUED: {
        const { queue: previousQueue } = state
        const queue = previousQueue.filter(queued => queued.id !== payload.id)
        const selectedItems = uniqBy(prop('id'), queue.map(prop('item')))
        return {
          ...state,
          selected: null,
          selectedItems,
          queue,
          queuedBySource: getQueuedBySource(queue)
        }
      }

      case PROCESSING_BARCODE_SCAN: {
        const { selected: oldSelected, search, sources, queue } = state

        const queueItemWhereTheScannedBarcodeIsLocated = findEntityWhereTheScannedBarcodeExists(payload, queue)
        if (queueItemWhereTheScannedBarcodeIsLocated) {
          const { cultivarName, weight, item: { name } } = queueItemWhereTheScannedBarcodeIsLocated
          return {
            ...state,
            lastSnackbarMessage: `label already part of ${name} ${cultivarName} ${weight} item`,
          }
        }

        const scannedPackageInSource = findBarcodeInSources(payload, sources, search)

        if (scannedPackageInSource) {
          // if we scanned a barcode and we already have some selected packages - we need to add the scanned package to the package that we're trying to create
          if (oldSelected) {
            const alreadySelected = Boolean(oldSelected?.sources.find(selectedPackage => selectedPackage.id === scannedPackageInSource.id))
            const newSelectedSources = alreadySelected ? oldSelected.sources : [...oldSelected.sources, scannedPackageInSource]
            return {
              ...state,
              selected: {
                ...oldSelected,
                sources: newSelectedSources,
              },
            }
          }

          // otherwise, we create a new package where the first selected package is this scanned one
          return {
            ...state,
            selected: {
              // TODO: add some keys related to the newly created package
              cultivarId: scannedPackageInSource.cultivar,
              cultivarName: scannedPackageInSource.cultivarName,
              sources: [scannedPackageInSource],
            },
          }
        }

        if (!scannedPackageInSource) {
          return {
            ...state,
            lastSnackbarMessage: `label not found in sources ${search ? `matching "${search}"` : ''}`,
          }
        }

        return state
      }

      case PROCESSING_REVIEW_CLICK: {
        return {
          ...state,
          review: payload,
        }
      }

      case PROCESSING_REVIEW_DONE: {
        return {
          ...state,
          selected: null,
          selectedItems: [],
          queue: [],
          review: false,
        }
      }

      case PROCESSING_SEARCH_CHANGE: {
        return {
          ...state,
          search: payload,
        }
      }

      case SUBMIT_PROCESSING_FORM: {
        // in case of new queue item, we need to check if the chosen item type was a new one, or selected earlier by some previous queue item
        const queue = getNewQueue(payload, state.queue)
        const newSelectedItems = uniqBy(prop('id'), queue.map(prop('item')))

        return {
          ...state,
          queue,
          search: initState.search,
          selected: null,
          selectedItems: newSelectedItems,
          lastSelectedItem: payload.item,
          queuedBySource: getQueuedBySource(queue),
        }
      }

      case CLEAR_PROCESSING_SNACKBAR_MESSAGE: {
        return {
          ...state,
          lastSnackbarMessage: null,
        }
      }

      case RESET_PROCESSING_STATE: {
        return {
          ...initState,
        }
      }

      case REFRESH_PROCESSING_STATE: {
        return {
          ...state,
          ...payload,
          id: payload.flowId,
        }
      }

      case UPDATE_PROCESSING_FLOW_ID: {
        return {
          ...state,
          id: payload,
        }
      }

      default: {
        return state
      }
    }
  },
  doInitializeProcessingState: initialState => ({ dispatch }) => {
    dispatch({ type: INITIALIZE_PROCESSING_STATE, payload: initialState })
  },
  doOnProcessingProcessedRoomsChange: (itemTypeId, room) => ({ dispatch }) => dispatch({ type: PROCESSING_PROCESSED_ROOMS_CHANGE, payload: { itemTypeId, room } }),
  doOnProcessingSourceClick: source => ({ type: PROCESSING_SOURCE_CLICK, payload: source }),
  doOnProcessingQueuedClick: queued => ({ dispatch }) => dispatch({ type: PROCESSING_QUEUED_CLICK, payload: queued }),
  doProcessingDeselectQueued: () => ({ dispatch }) => dispatch({ type: PROCESSING_DESELECT_QUEUED }),
  doProcessingRemoveQueued: queued => ({ dispatch }) => dispatch({ type: PROCESSING_REMOVE_QUEUED, payload: queued }),
  doOnProcessingBarcodeScan: scannedCode => ({ dispatch }) => dispatch({ type: PROCESSING_BARCODE_SCAN, payload: scannedCode }),
  doOnProcessingReviewClick: () => ({ dispatch }) => dispatch({ type: PROCESSING_REVIEW_CLICK, payload: true }),
  doOnProcessingReviewClose: () => ({ dispatch }) => dispatch({ type: PROCESSING_REVIEW_CLICK, payload: false }),
  doOnProcessingReviewDone: () => ({ dispatch }) => dispatch({ type: PROCESSING_REVIEW_DONE }),
  doOnProcessingSearchChange: search => ({ dispatch }) => dispatch({ type: PROCESSING_SEARCH_CHANGE, payload: search }),
  doOnSubmitProcessingForm: processed => ({ dispatch }) => dispatch({ type: SUBMIT_PROCESSING_FORM, payload: processed }),
  doResetProcessingState: () => ({ dispatch }) => dispatch({ type: RESET_PROCESSING_STATE }),
  doRefreshProcessingState: cloudData => ({ dispatch }) => dispatch({ type: REFRESH_PROCESSING_STATE, payload: cloudData }),
  doUpdateProcessingFlowId: id => ({ dispatch }) => dispatch({ type: UPDATE_PROCESSING_FLOW_ID, payload: id }),
  doClearProcessingSnackbarMessage: () => ({ dispatch }) => dispatch({ type: CLEAR_PROCESSING_SNACKBAR_MESSAGE }),
  selectProcessingState: state => state.processing,
  persistActions: [
    INITIALIZE_PROCESSING_STATE,
    PROCESSING_PROCESSED_ROOMS_CHANGE,
    PROCESSING_SOURCE_CLICK,
    PROCESSING_BARCODE_SCAN,
    PROCESSING_QUEUED_CLICK,
    PROCESSING_DESELECT_QUEUED,
    PROCESSING_REMOVE_QUEUED,
    PROCESSING_REVIEW_CLICK,
    PROCESSING_REVIEW_DONE,
    PROCESSING_SEARCH_CHANGE,
    SUBMIT_PROCESSING_FORM,
    CLEAR_PROCESSING_SNACKBAR_MESSAGE,
    RESET_PROCESSING_STATE,
    UPDATE_PROCESSING_FLOW_ID,
  ],
}
