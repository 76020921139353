import memoizeOne from 'memoize-one'
import {
  groupBy,
  join,
  path,
  pipe,
  prop,
  props as pluckAll,
  uniqBy,
} from 'ramda'

import { scaleLinear } from 'd3-scale'

import createLogger from '~/src/Lib/Logging'
import {
  createShallowEqualsSelector,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  shallowEquals,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'

import {
  ENVIRONMENT_AND_SUBSTRATE_SPLIT,
  IRRIGATION_VIEW_SPLIT,
  ROOM_GRAPH_SINGLE,
} from './constants'

const logger = createLogger('Chart/yAxes')

export const GRAPH_KEYS = ['ALL', 'SOIL', 'OTHER']

const uniqueByBoundsType = uniqBy(({ boundsType, ...rest }) => [boundsType, rest[boundsType]].join('-'))

/**
 * Returns graphs grouped by dataType unit, used when graphs aren't split.
 * @type {function}
 * @param {Array<Object>} graphs
 * @return {Array<Object>} Unique graphs by unit
 */
const groupByUnit = groupBy(prop('unit'))

/**
* Returns graphs grouped by dataType unit per split group, used when graphs are split.
* @param {Object} dataTypes
* @returns {function(Array<Object>): Array<Object>}
*/
const groupByUnitAndCategory = (dataTypes, allGraphs) => groupBy(pipe(
  ({ dataType }) => dataTypes[dataType],
  pluckAll(['unit', 'category']),
  join('-'),
), allGraphs)

const VIEW_MODE_YAXES_GROUPING = {
  [ROOM_GRAPH_SINGLE]: groupByUnit,
  [ENVIRONMENT_AND_SUBSTRATE_SPLIT]: groupByUnitAndCategory,
  [IRRIGATION_VIEW_SPLIT]: (dataTypes, allGraphs) => {
    const grouped = allGraphs.reduce((groups, graph) => {
      const { unit, dataType } = graph
      if (dataType == 'irrigation_drip_event') {
        groups[`${unit}-IRRIGATION_DRIP`] ??= []
        groups[`${unit}-IRRIGATION_DRIP`].push(graph)
      } else if (dataType == 'irrigation_drain_event') {
        groups[`${unit}-IRRIGATION_DRAIN`] ??= []
        groups[`${unit}-IRRIGATION_DRAIN`].push(graph)
      } else {
        const dataTypeCategory = dataTypes[dataType].category
        groups[`${unit}-${dataTypeCategory}`] ??= []
        groups[`${unit}-${dataTypeCategory}`].push(graph)
      }
      return groups
    }, {})
    return grouped
  }
}

const shouldAddDataTypeToAxes = (graph, graphs, bounds) => {
  const { [graph.dataType]: graphBounds } = bounds.dataType ?? EMPTY_OBJECT
  if (!graphBounds) {
    logger.warn('No dataType bounds for graph', graph)
    return false
  }

  return graphs.some(g => {
    if (g === graph) return false
    const { [g.dataType]: gBounds = graphBounds } = bounds.dataType
    if (gBounds === graphBounds) return false
    const graphRange = Math.abs(graphBounds.max - graphBounds.min)
    const gRange = Math.abs(gBounds.max - gBounds.min)
    const maxRange = Math.max(graphRange, gRange)

    return gBounds.min > (graphBounds.max + maxRange)
      || graphBounds.min > gBounds.max + maxRange
  })
}

export const groupsToAxes = memoizeOne((groupGraphs, bounds) => {
  const [first, ...graphs] = groupGraphs
  let firstBoundsType = groupGraphs.length === 1 ? 'graph' : 'unit'
  const { [first.dataType]: dtBounds } = bounds.dataType
  if (graphs.length && graphs.every(g => g.dataType === first.dataType) && dtBounds && dtBounds.max - dtBounds.min) {
    firstBoundsType = 'dataType'
  }
  if (firstBoundsType === 'graph') {
    const { [firstBoundsType]: boundsContainer } = bounds
    const { [first.id]: gBounds } = boundsContainer
    if (gBounds && !(gBounds.max - gBounds.min)) {
      firstBoundsType = (dtBounds && dtBounds.max - dtBounds.min) ? 'dataType' : 'unit'
    }
  }

  return graphs.reduce((groupAxes, graph) => {
    const { dataType, id, inverted = false, unit } = graph
    if (shouldAddDataTypeToAxes(graph, groupGraphs, bounds)) {
      const axes = uniqueByBoundsType(groupAxes.map(axis => ({ ...axis, boundsType: 'dataType' })))
      axes.push({
        dataType,
        graph: id,
        inverted,
        unit,
        boundsType: groupGraphs.filter(g => g.dataType === dataType).length > 1 ? 'dataType' : 'graph'
      })
      return axes
    }
    return groupAxes
  }, [{
    dataType: first.dataType,
    graph: first.id,
    inverted: first.inverted ?? false,
    unit: first.unit,
    boundsType: firstBoundsType
  }])
})

export const getYAxes = createShallowEqualsSelector(
  prop('viewMode'),
  prop('dataTypes'),
  path(['chartData', 'bounds']),
  prop('mainChartGraphs'),
  prop('manualGraphs'),
  (viewMode, dataTypes, bounds, sensor = EMPTY_ARRAY, manual = EMPTY_ARRAY) => {
    if (!bounds) return EMPTY_ARRAY
    const allGraphs = sensor.concat(manual)
    const { [viewMode]: partition } = VIEW_MODE_YAXES_GROUPING
    const grouped = viewMode === ROOM_GRAPH_SINGLE ? partition(allGraphs) : partition(dataTypes, allGraphs)

    return Object.values(grouped).flatMap(group => groupsToAxes(group, bounds))
  }
)

export const getChartYDimensions = shallowEqualsMemoizer((padding, bbox, customHeight) => {
  const { top, bottom } = padding
  const height = (customHeight || (bbox?.height ?? 0)) - bottom

  return [height, top]
}, { depth: 3 })

const yScaleFake = Object.assign(() => 0, { domain: () => [0, 0], range: () => [0, 0] })
/**
 * Gets the current y-axis scaler for the room/field dashboard chart.
 * The scaler is a function that returns the y-position in pixels of a given normalized value within the chart area
 * @param {number[]} yRange
 * @param {number} props.graphCount
 * @returns {function}
 */
export const getYScale = memoizeOne((yRange, groups) => {
  if (yRange[0] < 0 || !Array.isArray(groups)) return yScaleFake

  return scaleLinear()
    .domain([0, groups.reduce((total, { groupHeight = 1 }) => total + groupHeight, 0)])
    .range(yRange)
}, (left, right) => shallowEquals(left, right, 2))
