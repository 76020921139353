import { createReactorBundle } from 'redux-bundler'

import createLogger from '~/src/Lib/Logging'

import { REACTOR_PRIORITIES, TAB_STATES } from '../constants'

const REACTOR_DEBOUNCE_INTERVALS = {
  [REACTOR_PRIORITIES.HIGH]: 0,
  [REACTOR_PRIORITIES.MEDIUM]: 500,
  [REACTOR_PRIORITIES.LOW]: 1000,
}

const logger = createLogger('Store/reactor')

let lastReaction = 0
let deferComplete = false
let deferred = null
let deferrer = null
let store

const initialBundle = createReactorBundle({
  reactorPermissionCheck: (reactor, reaction) => {
    const { priority = REACTOR_PRIORITIES.MEDIUM } = reaction
    const {
      changingMembership: allowHighOnly,
      tabState
    } = store.select([
      'selectChangingMembership',
      'selectTabState'
    ])
    const tabIsActive = tabState === TAB_STATES.ACTIVE || tabState === TAB_STATES.PASSIVE
    const priorityIsHigh = priority === REACTOR_PRIORITIES.HIGH

    if (!tabIsActive || (allowHighOnly && !priorityIsHigh)) {
      return false
    }

    if (!lastReaction || priorityIsHigh) {
      lastReaction = Date.now()
      return true
    }

    if (deferred === reactor && deferComplete) {
      deferComplete = false
      deferred = null
      deferrer = null
      lastReaction = Date.now()
      return true
    }

    const now = Date.now()
    const interval = REACTOR_DEBOUNCE_INTERVALS[priority]
    if ((now - lastReaction) < interval || deferred) {
      deferred = deferred ?? reactor
      if (deferred === reactor && !deferrer) {
        deferrer = requestIdleCallback(() => {
          deferrer = null
          deferComplete = true
          requestIdleCallback(() => {
            if (deferred !== reactor) return
            store.dispatch({ type: 'APP_IDLE' })
            deferred = null
            deferComplete = false
          })
        }, { timeout: (lastReaction + interval) - now })
      }
      return false
    }
    lastReaction = now
    return true
  }
})

export default {
  ...initialBundle,
  init: storeRef => {
    store = storeRef
    return initialBundle.init(store)
  }
}
