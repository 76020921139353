import { SvgIcon } from '@mui/material'

export const path = `
  M2,5.73770492 C4.25806452,4.49180328 8.90322581,2.45901639 13.2903226,2 C15.6774194,2.98360656
  18.448664,4.31750373 22,7.31147541 L21.6129032,9.01639344 C18.1935484,6.91803279 15.0967742,
  5.27868852 9.87096774,4.95081967 C13.4408602,5.95628415 17.2043011,7.68306011 21.1612903,
  10.1311475 C19.0967742,15.5519126 16.4301075,19.5081967 13.1612903,22 C5.16129032,17.0163934
  3.09677419,10.7868852 2,5.73770492 Z M5.48387097,6.78688525 C5.67741935,11.2459016 9.61290323,
  16.557377 13.2258065,19.1803279 C15.7419355,16.8196721 17.8064516,14.1311475 18.9032258,10.5901639
  C13.9354839,7.31147541 9.29032258,6.78688525 5.48387097,6.78688525 Z
`

export default props => <SvgIcon {...props}><path data-iconid="aroya-icons-metrc" d={path} /></SvgIcon>
