import { Component, PureComponent } from 'react'

import { both, once } from 'ramda'

import bows from 'bows'

import getGlobal from './getGlobal'
import safeStorage from './safeStorage'
import { throttle } from './Utils/timing'

const global = getGlobal()

let sepCalled = false
const separatorMessage = () => {
  if (sepCalled) return null
  sepCalled = true
  const mainMsgLongest = '|   - set localStorage.__addiumDebugLoggerInclude__ = \'IO.*|Store.sockets\' to always get debug messages about api requests & WebSockets |'
  const mainMsg = [
    '[Lib/Logging] Debug patterns may be separated by the "|" character: "App.*|RD.Chart|Root"',
    'Debug patterns may be set in local or session storage under the "__addiumDebugLoggerInclude__" key.',
    'Examples:',
    '  - set sessionStorage.__addiumDebugLoggerInclude__ = \'RD.*\' when working on the RoomDashboard',
    mainMsgLongest
  ].map(str => (
    str === mainMsgLongest ? str : `| ${str.padEnd(mainMsgLongest.length - 4)} |`
  )).join('\n')
  const spacer = '\n'.repeat(5)
  return [
    `${spacer}${'-'.repeat(mainMsgLongest.length)}`,
    `|${' '.repeat(mainMsgLongest.length - 2)}|`,
    mainMsg,
    `|${' '.repeat(mainMsgLongest.length - 2)}|`,
    `${'-'.repeat(mainMsgLongest.length)}${spacer}`,
  ].join('\n')
}
/* eslint-disable no-param-reassign */

const now = () => new Date().toISOString().split('T').pop()
/* eslint-disable no-console, no-underscore-dangle */
if (
  (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test')
  || (safeStorage().debug === 'true' && !console.__ts__)
) {
  ['log', 'debug', 'info', 'warn', 'error'].forEach(method => {
    const original = console[method]
    console[method] = (...args) => original.call(console, ...args, '|', now())
  })
  console.save = (data, filename) => {
    if (!data) {
      console.error('Console.save: No data')
      return
    }

    if (!filename) filename = 'console.json'

    if (typeof data === 'object') {
      data = JSON.stringify(data, undefined, 4)
    }

    const blob = new Blob([data], { type: 'text/json' })
    const e = document.createEvent('MouseEvents')
    const a = document.createElement('a')

    a.download = filename
    a.href = global.URL.createObjectURL(blob)
    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
    e.initMouseEvent('click', true, false, global, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    a.dispatchEvent(e)
  }
  console.__ts__ = true
}
/* eslint-enable no-console, no-underscore-dangle */
/**
 * @alias createLogger
 */
export default (ComponentOrName, customNameOrChildName = '') => {
  let namespace = String(ComponentOrName.displayName ?? ComponentOrName)
  let path = []
  if (typeof namespace !== 'string' && typeof customNameOrChildName === 'string' && customNameOrChildName) {
    namespace = customNameOrChildName
  }
  if (typeof namespace === 'string' && typeof customNameOrChildName === 'string' && customNameOrChildName) {
    path.push(customNameOrChildName)
  }

  if (!namespace) {
    throw new TypeError(
      `No Component.displayName (${
        ComponentOrName.displayName
      }) and no custom identifier (${customNameOrChildName || ComponentOrName}) was provided.`
    )
  }
  if (!path.length && namespace.match(/^[A-Z].*[A-Z/#]/)) {
    [namespace, ...path] = namespace
      .replace('Component', '')
      .replace(/([a-z])([A-Z])/, '$1 $2')
      .replace('/', ' ')
      .split(' ')
  }
  const subPath = path.length
    ? `${(`[${path.join('').slice(0, 15)}]`).padEnd(15)} |`
    : ''
  const logger = bows(namespace, subPath)

  // eslint-disable-next-line no-restricted-properties
  logger.debugForce = logger.debug
  const pathId = typeof path[0] === 'string' && path.join('')
  // eslint-disable-next-line no-restricted-properties
  const debugSettingsMessageLogger = once(() => logger.debugForce(...[
    'Please add',
    pathId ? `"${namespace}.${pathId}"` : `"${namespace}"`,
    pathId ? `or "${namespace}.*"` : null,
    'to the __addiumDebugLoggerInclude__ key in local or session storage to enable debug logging for this module.',
    separatorMessage(),
  ].filter(Boolean)))
  const debugSettingPattern = pathId
    ? new RegExp(`\\b${namespace}\\b\\.(\\*|\\b${pathId}\\b)`)
    : new RegExp(`\\b${namespace}\\b`)
  const debugSettingsCheck = throttle(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const which of ['local', 'session']) {
      const storage = safeStorage(which)
      // eslint-disable-next-line no-underscore-dangle
      if (typeof storage.__addiumDebugLoggerInclude__ === 'string') {
        // eslint-disable-next-line no-underscore-dangle
        if (debugSettingPattern.test(storage.__addiumDebugLoggerInclude__)) return true
      }
    }
    debugSettingsMessageLogger()
    return false
  })
  // eslint-disable-next-line no-restricted-properties
  logger.debug = both(debugSettingsCheck, logger.debugForce)

  if (ComponentOrName instanceof Component || ComponentOrName instanceof PureComponent) {
    ComponentOrName.prototype.logger = logger
  }

  return logger
}
