import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createLogger from '~/src/Lib/Logging'
import { parseApiErrors } from '~/src/Lib/Utils'

const logger = createLogger('Device/bundle#found')

export const FOUND_DEVICES_DIALOG_URL = '/devices/search'

export const POLLING_DURATION = ms.minutes(5)
export const POLLING_INTERVAL = ms.seconds(5)

export const SERIAL_PREFIX_TO_MODEL = {
  H110: 'Repeater',
  H111: 'Climate Station',
  H130: 'Repeater',
  H210: 'Gateway',
  H311: 'M8 Sensor ',
  H321: 'M8 Sensor ',
  H331: 'M8 Sensor ',
  H340: 'Repeater',
  H341: 'TEROS 12 ',
  H342: 'TEROS 12 ',
  H343: 'TEROS ONE',
  H344: 'TEROS ONE',
  H411: 'Climate Station',
  H421: 'CLIMATE ONE',
}

// Default action types - `createAsyncResourceBundle`
export const FOUND_DEVICES_CLEARED = 'FOUND_DEVICES_CLEARED'
export const FOUND_DEVICES_FETCH_FAILED = 'FOUND_DEVICES_FETCH_FAILED'
export const FOUND_DEVICES_FETCH_FINISHED = 'FOUND_DEVICES_FETCH_FINISHED'
export const FOUND_DEVICES_FETCH_STARTED = 'FOUND_DEVICES_FETCH_STARTED'

// Polling API state
export const FOUND_DEVICES_POLLING_STARTED = 'FOUND_DEVICES_POLLING_STARTED'
export const FOUND_DEVICES_POLLING_STOPPED = 'FOUND_DEVICES_POLLING_STOPPED'
export const FOUND_DEVICES_SET_POLLING_TIMEOUT = 'FOUND_DEVICES_SET_POLLING_TIMEOUT'

// Gateway beaconing API state
export const OPEN_JOIN_MODE_DISABLED = 'OPEN_JOIN_MODE_DISABLED'
export const OPEN_JOIN_MODE_ENABLED = 'OPEN_JOIN_MODE_ENABLED'
export const OPEN_JOIN_MODE_FAILED = 'OPEN_JOIN_MODE_FAILED'
export const OPEN_JOIN_MODE_INFLIGHT = 'OPEN_JOIN_MODE_INFLIGHT'

export const additionalState = {
  inflight: null,
  openJoinError: null,
  pollingLastUpdated: null,
  pollingStart: null,
  pollingTimeoutID: null,
}

const foundDevicesBundle = createAsyncResourceBundle({
  name: 'foundDevices',
  actionBaseType: 'FOUND_DEVICES',
  getPromise: ({ apiFetch }) => apiFetch('/self_provisioning/').then(res => res.devices),
  retryAfter: Infinity,
  staleAfter: Infinity,
})

export default {
  ...foundDevicesBundle,
  reducer: reduceReducers(foundDevicesBundle.reducer, (state, action) => {
    switch (action.type) {
      case FOUND_DEVICES_FETCH_STARTED:
        return { ...state, pollingLastUpdated: +new Date() }
      case FOUND_DEVICES_FETCH_FINISHED:
        return { ...state, pollingLastUpdated: +new Date() }
      case FOUND_DEVICES_FETCH_FAILED:
        return { ...state, pollingLastUpdated: +new Date() }
      case FOUND_DEVICES_POLLING_STARTED:
        return { ...state, pollingLastUpdated: +new Date(), pollingStart: +new Date() }
      case FOUND_DEVICES_POLLING_STOPPED:
        return { ...state, pollingLastUpdated: +new Date(), pollingStart: null }
      case FOUND_DEVICES_SET_POLLING_TIMEOUT:
        return { ...state, pollingTimeoutID: action.payload }
      case OPEN_JOIN_MODE_INFLIGHT:
        return { ...state, inflight: action.payload, openJoinError: null }
      case OPEN_JOIN_MODE_ENABLED:
        return { ...state, inflight: null }
      case OPEN_JOIN_MODE_DISABLED:
        return { ...state, inflight: null }
      case OPEN_JOIN_MODE_FAILED:
        return { ...state, inflight: null, openJoinError: action.error }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state, data: state.data ?? [] }
        }
        return state
    }
  }),
  doStartFoundDevicesPolling: payload => ({ dispatch, store }) => {
    if (payload?.clearDevices === false) {
      // Indicates server should keep previous found devices
    } else {
      // Match server's default behavior and clear cache
      store.doClearFoundDevices()
    }
    dispatch({ type: FOUND_DEVICES_POLLING_STARTED })
    return store.doEnableOpenJoinMode(payload)
  },
  // Not intended to be called directly
  doEnableOpenJoinMode: payload => ({ apiFetch, dispatch, store }) => {
    dispatch({ type: OPEN_JOIN_MODE_INFLIGHT, payload: 'enable' })
    return apiFetch('/self_provisioning/enable/', payload, { method: 'POST' }).then(response => {
      dispatch({ type: OPEN_JOIN_MODE_ENABLED, payload: response })
      store.doPollDevices()
    }).catch(e => {
      const error = parseApiErrors(e) || e.message || 'Unknown error. Please try again.'
      dispatch({ type: OPEN_JOIN_MODE_FAILED, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })
      store.doStopFoundDevicesPolling()
    })
  },
  // Not intended to be called directly
  doPollDevices: () => ({ dispatch, store }) => {
    const { url } = store.selectDialogRouteInfo()
    const { inflight, isLoading, pollingStart, pollingTimeoutID } = store.selectFoundDevicesRaw()

    if (inflight === 'enable') return  // Short-circuit if user closed and rapidly re-initiated polling

    const pollingDuration = new Date() - new Date(pollingStart)
    const pollingHasEnded = !pollingStart || pollingDuration >= POLLING_DURATION
    const userNavigatedAway = url !== FOUND_DEVICES_DIALOG_URL

    if (pollingHasEnded || userNavigatedAway) {
      store.doStopFoundDevicesPolling()
    } else {
      if (!isLoading) {
        // Prevent redundant parallel fetching; e.g. server timeout
        store.doFetchFoundDevices()
      }
      clearTimeout(pollingTimeoutID)
      const timeoutID = setTimeout(() => store.doPollDevices(), POLLING_INTERVAL)
      dispatch({ type: FOUND_DEVICES_SET_POLLING_TIMEOUT, payload: timeoutID })
    }
  },
  doStopFoundDevicesPolling: () => ({ dispatch, store }) => {
    const { pollingStart } = store.selectFoundDevicesRaw()
    if (!pollingStart) return null
    dispatch({ type: FOUND_DEVICES_POLLING_STOPPED })
    return store.doDisableOpenJoinMode()
  },
  // Not intended to be called directly
  doDisableOpenJoinMode: () => ({ apiFetch, dispatch }) => {
    dispatch({ type: OPEN_JOIN_MODE_INFLIGHT, payload: 'disable' })
    return apiFetch('/self_provisioning/disable/', null, { method: 'POST' }).then(response => {
      dispatch({ type: OPEN_JOIN_MODE_DISABLED, payload: response })
    }).catch(e => {
      const error = parseApiErrors(e) || e.message
      dispatch({ type: OPEN_JOIN_MODE_FAILED, error })
      // No need to notify the user if this fails
    })
  },
  // Allows a page refresh to detect a false polling state outside of its expected duration
  reactDetectStalePollingState: createSelector(
    'selectDialog',
    'selectFoundDevicesRaw',
    (_, { pollingStart }) => {
      if (!pollingStart) return null
      const pollingDuration = new Date() - new Date(pollingStart)
      if (pollingDuration > POLLING_DURATION * 1.1) {
        logger.debug('[reactor] polling state detected beyond expected duration', { pollingDuration: `${(pollingDuration / 1000)} sec` })
        return { actionCreator: 'doStopFoundDevicesPolling' }
      }
      return null
    },
  ),
}
