import { hours } from 'milliseconds'

import { useLock } from '~/src/Flags/Lock'

const ONE_DAY = hours(24)

export const ggV1Test = ({ devices, meLastSuccess }) => {
  if (!meLastSuccess) return false
  const aDayAgoTS = new Date(Date.now() - ONE_DAY)
  return Object.values(devices).some(({ ggVersion, lastCommunication }) => ggVersion === '1' && +new Date(lastCommunication) > aDayAgoTS)
}

export const ggV1Selectors = ['selectDevices', 'selectMeLastSuccess']

export const useGGV1Locked = props => useLock(ggV1Test, ggV1Selectors, props)
