import { useEffect } from 'react'

import i18n from 'i18n-literally'
import { useConnect } from 'redux-bundler-hook'

import T from '~/src/UI/Shared/Typography'

const LogoutComponent = () => {
  const { doLogout } = useConnect('doLogout')

  useEffect(() => {
    setTimeout(() => {
      doLogout()
    }, 1000)
  }, [doLogout])

  return <div className="center-all"><T.H1>{i18n`Logging out…`}</T.H1></div>
}
LogoutComponent.viewMode = 'fullscreen'

export default LogoutComponent
