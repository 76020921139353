export const TASK_PRIORITY_LOW = 1
export const TASK_PRIORITY_MEDIUM = 2
export const TASK_PRIORITY_HIGH = 3

export const priorityOptions = [
  { label: 'Low Priority', value: TASK_PRIORITY_LOW },
  { label: 'Medium Priority', value: TASK_PRIORITY_MEDIUM },
  { label: 'High Priority', value: TASK_PRIORITY_HIGH },
]

export const FACILITY_WIDE = Symbol.for('FACILITY_WIDE')
