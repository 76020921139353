import { createInventoryBundle } from '~/src/Inventory/bundle'
import createLogger from '~/src/Lib/Logging'

import urls from './urls'

const name = 'harvestTransferInventory'
const logger = createLogger(name)

const initialState = {
  existingFor: null,
  types: null,
}

export default createInventoryBundle(name, {
  name,
  initialState,
  // We need transferrable inventory when creating a new harvest or tagging plants in an existing one
  urlTest: (url, pattern) => pattern === urls.create || url.match(/#\/(lot2plant|veg2flower)/)
})
