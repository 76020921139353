import { prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import createLogger from '~/src/Lib/Logging'
import { getDateTime, parseApiErrors } from '~/src/Lib/Utils'

import { prepareApiPayload } from '../Dialog/utils'
import { getDurationUnits } from '../utils'

const EXPECTED_IRRIGATION_FETCH_CLEARED = 'EXPECTED_IRRIGATION_FETCH_CLEARED'
const EXPECTED_IRRIGATION_FETCH_FAILED = 'EXPECTED_IRRIGATION_FETCH_FAILED'
const EXPECTED_IRRIGATION_FETCH_FINISHED = 'EXPECTED_IRRIGATION_FETCH_FINISHED'
const EXPECTED_IRRIGATION_FETCH_STARTED = 'EXPECTED_IRRIGATION_FETCH_STARTED'

const displayName = 'Irrigation/bundle#expected'
const logger = createLogger(displayName)

export const getScheduleFetchId = schedule => `schedules_${schedule?.id ?? 'current'}`

const initialState = {
  current: {},  // irrigation events based on transitory form data
  data: {},     // irrigation events based on saved schedule data
  errors: {},
  fetchedAt: {},
  inflight: {},
}

const expectedIrrigationEventsBundle = {
  name: 'expectedIrrigationEvents',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case EXPECTED_IRRIGATION_FETCH_STARTED:
        return {
          ...state,
          errors: { ...state.errors, [action.fetchId]: null },
          inflight: { ...state.inflight, [action.fetchId]: true },
        }
      case EXPECTED_IRRIGATION_FETCH_FAILED:
        return {
          ...state,
          errors: { ...state.errors, [action.fetchId]: action.error },
          fetchedAt: { ...state.fetchedAt, [action.fetchId]: Date.now() },
          inflight: { ...state.inflight, [action.fetchId]: false },
        }
      case EXPECTED_IRRIGATION_FETCH_FINISHED: {
        const updates = {
          errors: { ...state.errors, [action.fetchId]: null },
          fetchedAt: { ...state.fetchedAt, [action.fetchId]: Date.now() },
          inflight: { ...state.inflight, [action.fetchId]: false },
        }
        if (action.fetchId.startsWith('schedules')) {
          updates.current = action.payload
        } else {
          updates.data = { ...state.data, ...action.payload }
        }
        return {
          ...state,
          ...updates,
        }
      }
      case EXPECTED_IRRIGATION_FETCH_CLEARED:
        return {
          ...state,
          current: {},
        }
      default:
        return state
    }
  },
  selectExpectedIrrigationEventsByController: createSelector(
    'selectExpectedIrrigationEventsRaw',
    prop('data'),
  ),
  selectExpectedIrrigationEventsInflight: createSelector(
    'selectExpectedIrrigationEventsRaw',
    prop('inflight'),
  ),
  selectExpectedIrrigationEventsByPort: createSelector(
    'selectExpectedIrrigationEventsByController',
    eventsByController => Object.values(eventsByController)
      .reduce((ebp, { eventsByPort }) => ({ ...ebp, ...eventsByPort }), {}),
  ),
  selectExpectedIrrigationEventsRaw: prop('expectedIrrigationEvents'),
  selectCurrentExpectedIrrigationEventsByController: createSelector(
    'selectExpectedIrrigationEventsRaw',
    prop('current'),
  ),
  selectCurrentExpectedIrrigationEventsByPort: createSelector(
    'selectCurrentExpectedIrrigationEventsByController',
    eventsByController => Object.values(eventsByController)
      .reduce((ebp, { eventsByPort }) => ({ ...ebp, ...eventsByPort }), {}),
  ),
  doClearCurrentExpectedIrrigationEvents: () => ({ type: EXPECTED_IRRIGATION_FETCH_CLEARED }),
  doFetchExpectedIrrigationEvents: formData => ({ apiFetch, dispatch, store }) => {
    const units = store.selectUnits()
    const durationUnits = getDurationUnits(units)
    const payload = prepareApiPayload(formData, durationUnits)
    const fetchId = getScheduleFetchId(payload)
    dispatch({ type: EXPECTED_IRRIGATION_FETCH_STARTED, fetchId, payload })
    return apiFetch('/irrigationSchedules/get_expected_open_times/', payload, { method: 'POST' }).then(response => {
      dispatch({ type: EXPECTED_IRRIGATION_FETCH_FINISHED, fetchId, payload: response })
      return response
    }).catch(e => {
      let error = parseApiErrors(e) || e.message
      if (error.includes('is not valid JSON')) {
        error = 'Unexpected error'
      }
      dispatch({ type: EXPECTED_IRRIGATION_FETCH_FAILED, fetchId, error })
    })
  },
  doFetchExpectedRoomIrrigationEvents: room => ({ apiFetch, dispatch }) => {
    const roomId = Number.isInteger(Number(room)) ? Number(room) : room.id
    if (!roomId) {
      logger.warn('Expected room or room ID, received:', room)
      return null
    }
    const fetchId = `rooms_${roomId}`
    dispatch({ type: EXPECTED_IRRIGATION_FETCH_STARTED, fetchId, payload: { roomId } })
    return apiFetch(`/irrigationSchedules/get_expected_open_times_by_room/${roomId}/`).then(response => {
      dispatch({ type: EXPECTED_IRRIGATION_FETCH_FINISHED, fetchId, payload: response })
      return response
    }).catch(e => {
      let error = parseApiErrors(e) || e.message
      if (error.includes('is not valid JSON')) {
        error = 'Unexpected error'
      }
      dispatch({ type: EXPECTED_IRRIGATION_FETCH_FAILED, fetchId, error })
    })
  },
  doSetExpectedIrrigationEventsInflight: fetchId => ({ type: EXPECTED_IRRIGATION_FETCH_STARTED, fetchId }),
  reactFetchExpectedRoomIrrigationEvents: createSelector(
    'selectExpectedIrrigationEventsRaw',
    'selectIrrigationSchedules',
    'selectRoomIrrigationSchedules',
    'selectRouteInfo',
    (expectedIrrigationEventsRaw, irrigationSchedules, { results: scheduleIds }, { params, pattern }) => {
      if (pattern !== '/rooms/:id') return null
      const irrigationScheduleEntities = scheduleIds.map(id => irrigationSchedules[id]).filter(Boolean)
      if (!irrigationScheduleEntities.length) return null

      const fetchId = `rooms_${params.id}`
      const {
        errors: { [fetchId]: error },
        fetchedAt: { [fetchId]: fetchedAt },
        inflight: { [fetchId]: inflight },
      } = expectedIrrigationEventsRaw
      if (inflight) return null

      const isOutdated = !fetchedAt
        || irrigationScheduleEntities.some(s => typeof s.fetchedAt !== 'number' || s.fetchedAt > fetchedAt)
      const shouldRetry = error && (getDateTime('now') - fetchedAt > ms.seconds(10))

      if (isOutdated || shouldRetry) {
        return { actionCreator: 'doFetchExpectedRoomIrrigationEvents', args: [params.id] }
      }
      return null
    },
  ),
}

export default expectedIrrigationEventsBundle
