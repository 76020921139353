import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { createCustomAction } from '~/src/Lib/createEntityBundle'
import { parseApiErrors } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../Lib/Utils/constants'
import urls from './urls'

const {
  actionIdentifiers: gallery,
  actionReducer: galleryReducer,
  defaultState: galleryDefaultState,
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'gallery',
  reducerKey: 'gallery',
  loadingKey: 'loading'
})

const harvestGalleryBundle = createAsyncResourceBundle({
  name: 'harvestGallery',
  actionBaseType: 'HARVEST_GALLERY',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest: harvestId } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvestId)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvestId})`))
    }
    const harvest = store.selectHarvests()[harvestId]
    if (!harvest) {
      return Promise.reject(new Error(`Harvest ${harvestId} not found in store`))
    }
    return apiFetch('/gallery/', { harvest: harvestId, start: harvest.startDate })
  },
})

export default {
  ...harvestGalleryBundle,
  reactHarvestGalleryFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestGalleryShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics) {
        return { actionCreator: 'doFetchHarvestGallery' }
      }
      return undefined
    },
  }),
  reducer: reduceReducers(harvestGalleryBundle.reducer, (state = galleryDefaultState, action = EMPTY_OBJECT) => {
    if (action.type.startsWith(gallery.types.prefix)) {
      return galleryReducer(state, action)
    }
    return harvestGalleryBundle.reducer(state, action)
  }),
  doFetchGalleryImages: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: gallery.types.start })
    let result = false
    try {
      result = await apiFetch('/gallery/', payload, { method: 'GET' })
      dispatch({ type: gallery.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({ type: gallery.types.fail, error: parseApiErrors(error) || 'An unexpected error occurred.' })
    }
    return result
  },
  selectGalleryImagesIsLoading: createSelector(
    'selectHarvestGalleryRaw',
    raw => raw?.gallery?.loading
  ),
  selectGalleryImages: createSelector(
    'selectHarvestGalleryRaw',
    raw => raw?.gallery?.data ?? EMPTY_ARRAY
  )
}
