import { always } from 'ramda'

export const HARVESTING_ACKNOWLEDGE_OFFLINE_WARNING = 'HARVESTING_ACKNOWLEDGE_OFFLINE_WARNING'
export const HARVESTING_CLEAR_SNACKBAR_MESSAGE = 'HARVESTING_CLEAR_SNACKBAR_MESSAGE'
export const HARVESTING_CLEAR_STATE = 'HARVESTING_CLEAR_STATE'
export const HARVESTING_CONVERT_QUEUED_WEIGHTS = 'HARVESTING_CONVERT_QUEUED_WEIGHTS'
export const HARVESTING_CURRENT_HARVEST = 'HARVESTING_CURRENT_HARVEST'
export const HARVESTING_DESELECT_QUEUED = 'HARVESTING_DESELECT_QUEUED'
export const HARVESTING_DESELECT_SOURCES = 'HARVESTING_DESELECT_SOURCES'
export const HARVESTING_ENDING_PLANT_ID_CHANGE = 'HARVESTING_ENDING_PLANT_ID_CHANGE'
export const HARVESTING_FLOW_SAVE_FAIL = 'HARVESTING_FLOW_SAVE_FAIL'
export const HARVESTING_FLOW_SAVE_RETRY = 'HARVESTING_FLOW_SAVE_RETRY'
export const HARVESTING_FLOW_SAVE_START = 'HARVESTING_FLOW_SAVE_START'
export const HARVESTING_FLOW_SAVE_SUCCEED = 'HARVESTING_FLOW_SAVE_SUCCEED'
export const HARVESTING_FLOW_SETTINGS = 'HARVESTING_FLOW_SETTINGS'
export const HARVESTING_INITIALIZE_SOURCES = 'HARVESTING_INITIALIZE_SOURCES'
export const HARVESTING_INITIALIZE_STATE = 'HARVESTING_INITIALIZE_STATE'
export const HARVESTING_LAST_MODIFIED_TIMESTAMP = 'HARVESTING_LAST_MODIFIED_TIMESTAMP'
export const HARVESTING_PROCESSED_ROOMS_CHANGE = 'HARVESTING_PROCESSED_ROOMS_CHANGE'
export const HARVESTING_QUEUED_CLICK = 'HARVESTING_QUEUED_CLICK'
export const HARVESTING_RECONCILED_FLOW = 'HARVESTING_RECONCILED_FLOW'
export const HARVESTING_RECONCILING_FLOW = 'HARVESTING_RECONCILING_FLOW'
export const HARVESTING_RECONCILING_FLOW_RESET = 'HARVESTING_RECONCILING_FLOW_RESET'
export const HARVESTING_REFRESH_STATE = 'HARVESTING_REFRESH_STATE'
export const HARVESTING_REMOVE_QUEUED = 'HARVESTING_REMOVE_QUEUED'
export const HARVESTING_RESET_STATE = 'HARVESTING_RESET_STATE'
export const HARVESTING_REVIEW_CLICK = 'HARVESTING_REVIEW_CLICK'
export const HARVESTING_REVIEW_DONE = 'HARVESTING_REVIEW_DONE'
export const HARVESTING_SEARCH_CHANGE = 'HARVESTING_SEARCH_CHANGE'
export const HARVESTING_SELECT_ITEM_BY_BARCODE = 'HARVESTING_SELECT_ITEM_BY_BARCODE'
export const HARVESTING_SOURCE_CLICK = 'HARVESTING_SOURCE_CLICK'
export const HARVESTING_SPLIT_HARVEST = 'HARVESTING_SPLIT_HARVEST'
export const HARVESTING_STARTING_PLANT_ID_CHANGE = 'HARVESTING_STARTING_PLANT_ID_CHANGE'
export const HARVESTING_SUBMIT_FORM = 'HARVESTING_SUBMIT_FORM'
export const HARVESTING_TOGGLE_SEQUENTIAL_TAG_INPUTS = 'HARVESTING_TOGGLE_SEQUENTIAL_TAG_INPUTS'
export const HARVESTING_UPDATE_FLOW_ID = 'HARVESTING_UPDATE_FLOW_ID'

const PERSIST_ACTIONS = [
  HARVESTING_ACKNOWLEDGE_OFFLINE_WARNING,
  HARVESTING_CLEAR_SNACKBAR_MESSAGE,
  HARVESTING_CLEAR_STATE,
  HARVESTING_CONVERT_QUEUED_WEIGHTS,
  HARVESTING_CURRENT_HARVEST,
  HARVESTING_DESELECT_QUEUED,
  HARVESTING_DESELECT_SOURCES,
  HARVESTING_ENDING_PLANT_ID_CHANGE,
  HARVESTING_FLOW_SAVE_FAIL,
  HARVESTING_FLOW_SAVE_RETRY,
  HARVESTING_FLOW_SAVE_START,
  HARVESTING_FLOW_SAVE_SUCCEED,
  HARVESTING_FLOW_SETTINGS,
  HARVESTING_INITIALIZE_SOURCES,
  HARVESTING_INITIALIZE_STATE,
  HARVESTING_LAST_MODIFIED_TIMESTAMP,
  HARVESTING_PROCESSED_ROOMS_CHANGE,
  HARVESTING_QUEUED_CLICK,
  HARVESTING_RECONCILED_FLOW,
  HARVESTING_RECONCILING_FLOW,
  HARVESTING_RECONCILING_FLOW_RESET,
  HARVESTING_REFRESH_STATE,
  HARVESTING_REMOVE_QUEUED,
  HARVESTING_RESET_STATE,
  HARVESTING_REVIEW_CLICK,
  HARVESTING_REVIEW_DONE,
  HARVESTING_SEARCH_CHANGE,
  HARVESTING_SELECT_ITEM_BY_BARCODE,
  HARVESTING_SOURCE_CLICK,
  HARVESTING_SPLIT_HARVEST,
  HARVESTING_STARTING_PLANT_ID_CHANGE,
  HARVESTING_SUBMIT_FORM,
  HARVESTING_TOGGLE_SEQUENTIAL_TAG_INPUTS,
  HARVESTING_UPDATE_FLOW_ID,
]

export default {
  doAcknowledgeOfflineWarning: always({ type: HARVESTING_ACKNOWLEDGE_OFFLINE_WARNING }),
  doClearSnackbarMessage: always({ type: HARVESTING_CLEAR_SNACKBAR_MESSAGE }),
  doConvertQueuedWeights: always({ type: HARVESTING_CONVERT_QUEUED_WEIGHTS }),
  doDeselectQueued: always({ type: HARVESTING_DESELECT_QUEUED }),
  doHarvestingClearState: harvestId => ({ type: HARVESTING_CLEAR_STATE, payload: harvestId }),
  doHarvestingInitializeSources: initialState => ({ type: HARVESTING_INITIALIZE_SOURCES, payload: initialState }),
  doHarvestingInitializeState: (harvestId, meta) => ({ type: HARVESTING_INITIALIZE_STATE, payload: harvestId, meta }),
  doOnBarcodeScanned: (scannedCode, actionType) => ({ type: actionType, payload: scannedCode }),
  doOnDeselectSources: () => ({ type: HARVESTING_DESELECT_SOURCES }),
  doOnEndingPlantIdChange: plantId => ({ type: HARVESTING_ENDING_PLANT_ID_CHANGE, payload: plantId }),
  doOnProcessedRoomsChange: (groupId, room) => ({ type: HARVESTING_PROCESSED_ROOMS_CHANGE, payload: { groupId, room } }),
  doOnQueuedClick: queued => ({ type: HARVESTING_QUEUED_CLICK, payload: queued }),
  doOnReviewClick: always({ type: HARVESTING_REVIEW_CLICK, payload: true }),
  doOnReviewClose: always({ type: HARVESTING_REVIEW_CLICK, payload: false }),
  doOnReviewDone: always({ type: HARVESTING_REVIEW_DONE }),
  doOnSearchChange: search => ({ type: HARVESTING_SEARCH_CHANGE, payload: search }),
  doOnSourceClick: source => ({ type: HARVESTING_SOURCE_CLICK, payload: source }),
  doOnStartingPlantIdChange: plantId => ({ type: HARVESTING_STARTING_PLANT_ID_CHANGE, payload: plantId }),
  doOnSubmitForm: payload => ({ type: HARVESTING_SUBMIT_FORM, payload }),
  doRefreshHarvestingState: cloudData => ({ type: HARVESTING_REFRESH_STATE, payload: cloudData }),
  doRemoveQueued: queued => ({ type: HARVESTING_REMOVE_QUEUED, payload: queued }),
  doResetHarvestingState: meta => ({ type: HARVESTING_RESET_STATE, meta }),
  doSetHarvestingFlowSettings: flowSettings => ({ type: HARVESTING_FLOW_SETTINGS, payload: flowSettings }),
  doSplitHarvest: flag => ({ type: HARVESTING_SPLIT_HARVEST, payload: flag }),
  doToggleSequentialTagInputs: always({ type: HARVESTING_TOGGLE_SEQUENTIAL_TAG_INPUTS }),
  doUpdateHarvestingFlowId: id => ({ type: HARVESTING_UPDATE_FLOW_ID, payload: id }),
  persistActions: PERSIST_ACTIONS,
}
