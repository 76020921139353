import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { allowed } from '~/src/Flags'
import { createAppIsReadySelector } from '~/src/Store/utils'

const complianceStatsBundle = createAsyncResourceBundle({
  name: 'eventStats',
  actionBaseType: 'EVENT_STATS',
  staleAfter: ms.minutes(5),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch }) => {
    const abort = new AbortController()
    setTimeout(() => abort.abort(), ms.seconds(60))
    return apiFetch('/events/unsynced_count/', null, { signal: abort.signal }).then(({ eventCount }) => ({ events: eventCount }))
  }
})

export default {
  ...complianceStatsBundle,
  reactEventStatsFetch: createAppIsReadySelector({
    dependencies: [
      'selectAuth',
      'selectEventStatsShouldUpdate',
      'selectAvailableFeatures',
      'selectPermittedActions'
    ],
    resultFn: ({ authenticated }, shouldUpdate, availableFeatures, permittedActions) => {
      const [isAllowed] = allowed({
        actions: ['sync_cultivation', 'sync_processing', 'view_inbox'],
        anyAction: true,
        features: 'METRC',
        availableFeatures,
        permittedActions,
      })
      if (authenticated && isAllowed && shouldUpdate) {
        return { actionCreator: 'doFetchEventStats' }
      }
      return undefined
    },
  })
}
