import { omit } from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { MetrcLocation as schema } from '~/src/Store/Schemas'

const name = 'metrcLocations'

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
  },
})

export default {
  ...initialBundle,
  selectAvailableMetrcLocations: createSelector(
    'selectRooms',
    'selectCurrentRoomId',
    'selectMetrcLocations',
    (rooms, currentRoomId, metrcLocations) => {
      const assignedMetrcLocations = new Set(
        Object.values(omit([currentRoomId], rooms)).flatMap(room => room.metrcLocations).filter(Boolean)
      )

      return Object.values(metrcLocations).filter(metrcLocation => !assignedMetrcLocations.has(metrcLocation?.id))
    }
  ),
}
