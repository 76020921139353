import zIndex from '@mui/material/styles/zIndex'

import { APP_BAR_HEIGHT, getGlobals } from '~/src/UI/Theme/mui'

import { SHORT_LANDSCAPE_MEDIA_QUERY } from '../Lib/Utils'

export default theme => ({
  '@global': {
    ...getGlobals(theme),
    '.aroya': {
      overflow: 'hidden',
    },
    '.aroya *': {
      boxSizing: 'border-box',
    },
    '.aroya #root': {
      minWidth: '100vw',
      maxWidth: '100vw',
    },
    '.aroya ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      backgroundColor: `var(--scroll-bg, ${theme.palette.primary.active})`,
      borderRadius: 3
    },
    '.aroya ::-webkit-scrollbar-thumb': {
      backgroundColor: `var(--scroll-fg, ${theme.palette.primary.disabled})`,
      borderRadius: 3,
      border: 'none !important',
    },
    '.aroya a:not([class])': {
      color: 'inherit',
    },
    '.aroya a:not(.aroya__link-underline)': {
      textDecoration: 'none',
    },
    '.aroya body': {
      color: theme.palette.text.primary,
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    '.center-all': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    '@keyframes aroya-fade-in': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    '.fade-in': {
      opacity: 0,
      animationDelay: '800ms',
      animationDuration: '150ms',
      animationFillMode: 'forwards',
      animationIterationCount: 1,
      animationName: 'aroya-fade-in',
      animationTimingFunction: 'ease-in-out',
    }
  },
  root: {
    zIndex: 1,
    position: 'relative',
    minHeight: '100vh',
    maxHeight: '100vh',
    minWidth: '100vw',
    maxWidth: '100vw',
    display: 'grid',
    gridTemplateAreas: [
      '"banner banner banner"',
      '"nav appBar drawer"',
      '"nav content drawer"',
      '"nav footer drawer"'
    ].join('\n'),
    gridTemplateColumns: '84px minmax(0, 1fr) minmax(0, max-content)',
    gridTemplateRows: `auto ${APP_BAR_HEIGHT} 1fr auto`,
    overflow: 'clip',
    '&:before': {
      transition: 'backdrop-filter 250ms ease-in-out',
    },
    '& > $appBar': {
      transition: 'filter 250ms ease-in-out',
      paddingTop: 0,
    },
    '@media screen and (max-height: 767px)': {
      '& $appBar': {
        position: 'fixed',
        top: 0,
        left: 84,
        width: 'calc(100vw - 84px)',
      },
      '& $nav': {
        height: '100vh',
      },
      '&:has(#aroya-subscription-banner) $nav': {
        height: 'calc(100vh - 1.75rem)',
      }
    },
    '&$noScroll $content': {
      overflow: 'hidden',
    },
    '@media print': {
      gridTemplateColumns: '0px 1fr !important',
    }
  },
  fullscreen: {
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    '& $content': {
      padding: theme.spacing(0),
      overflow: 'hidden',
      maxHeight: '100vh',
    },
    '& $footer': {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
    }
  },
  noScroll: {},
  appBar: {},
  modalRoot: {
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: zIndex.modal,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    '& > *': {
      pointerEvents: 'auto',
    },
  },
  mainHeader: {
    fontSize: '4rem',
  },
  readProgress: {
    position: 'absolute',
    width: '100%',
    display: 'none',
  },
  writeProgress: {
    position: 'fixed',
    zIndex: 1400,
    bottom: 24,
    left: 24,
    color: theme.palette.error.main,
  },
  offlineIndicator: {
    position: 'fixed',
    right: '.5em',
    top: '.5em',
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '@media print': {
      display: 'none'
    }
  },
  menuToggle: {
    paddingTop: theme.spacing(),
  },
  hide: {
    display: 'none',
  },
  nav: {
    gridArea: 'nav',
    '@media print': {
      display: 'none'
    }
  },
  content: {
    gridArea: 'content',
    overflowY: 'auto',
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 3, 3),
    width: '100%',
    maxHeight: `calc(100vh - var(--app-bar-height, ${APP_BAR_HEIGHT}) - 24px)`,
    '#wrapper:has(#drawerRoot:not(:empty)) &': {
      paddingRight: theme.spacing(0),
    },
    [SHORT_LANDSCAPE_MEDIA_QUERY]: {
      '& > :first-child:last-child:not(.aroya__no-auto-height)': {
        maxHeight: `calc(45rem - var(--app-bar-height, ${APP_BAR_HEIGHT}) - 3rem) !important`,
      },
    },
    '@media (min-height: 45.1rem), (orientatation: portrait)': {
      '& > :first-child:last-child:not(.aroya__no-auto-height)': {
        maxHeight: `calc(100vh - var(--app-bar-height, ${APP_BAR_HEIGHT}) - 3rem) !important`,
      },
    },
    '& > :first-child:last-child:not(.aroya__no-auto-height)': {
      overflowY: 'auto !important',
      paddingRight: '1ch',
    },
    '@media print': {
      padding: 0
    }
  },
  errorHeading: {
    marginTop: theme.spacing(),
    flex: '0 0 100%',
    '@media print': {
      display: 'none'
    }
  },
  errorIcon: {
    color: theme.palette.warning.main,
    '@media print': {
      display: 'none'
    }
  },
  maintenanceMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '200px',
    '@media print': {
      display: 'none'
    }
  },
  footer: {
    gridArea: 'footer',
    width: '100%',
    height: 24,
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '2ch',
    paddingInline: '1ch',
  },
  debugInfo: {
    fontSize: 12,
    pointerEvents: 'none',
    '$fullscreen &': {
      whiteSpace: 'normal'
    },
    '@media print': {
      display: 'none'
    }
  },
  drawerRoot: {
    gridArea: 'drawer',
    zIndex: 1202,
    flex: '0 0 4rem',
    width: '3rem',
    pointerEvents: 'none',
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.easeInOut
    }),
    '&:empty': {
      width: 0,
      transition: theme.transitions.create(['width'], {
        duration: theme.transitions.duration.enterinScreen,
        easing: theme.transitions.easing.easeInOut
      }),
    },
    '&:has(.aroya__drawer__open)': {
      width: 'max-content'
    },
    '& .MuiDrawer-paper.aroya__drawer__open .aroya__drawer__content': {
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeInOut,
      })
    },
    '& .MuiDrawer-paper .aroya__drawer__content': {
      opacity: 0,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.easeInOut,
      })
    },
    '& *': {
      pointerEvents: 'auto',
    },
    '@media screen and (max-width: 89.99rem)': {
      '& .MuiDrawer-root': {
        position: 'absolute',
        right: 0,
        height: '100%',
        '.aroya-subscription-banner &': {
          height: 'calc(100% - 1.75rem)',
        }
      }
    },
    '@media print': {
      display: 'none'
    }
  },
  drawerOpen: {},
  bannerContainer: {
    gridArea: 'banner',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
  bannerOverdue: {
    backgroundColor: theme.palette.error.main,
  },
  bannerWarning: {
    backgroundColor: theme.palette.yellow.main,
    '& svg': {
      fill: theme.palette.black.main
    },
    '& h6': {
      color: theme.palette.black.main
    },
  },
})
