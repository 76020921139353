import { Fragment, memo } from 'react'

import PropTypes from 'prop-types'

import { ChevronLeft } from '@mui/icons-material'
import { IconButton, Portal } from '@mui/material'
import { makeStyles } from '@mui/styles'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, getNodeText, useMatchesBreakpoint } from '~/src/Lib/Utils'
import Head from '~/src/UI/Shared/Head'
import T from '~/src/UI/Shared/Typography'

const displayName = 'PageTitle'
const logger = createLogger(displayName)

const useStyles = makeStyles({
  backButton: {
    marginLeft: -24
  }
})

/**
 * Renders a formatted page title h1 and injects the title text into the head's title element
 * @component
 * @alias PageTitle
 * @named_export PageTitleComponent
 * @example
 * <PageTitle title="Title Page" />
 */
export const PageTitleComponent = ({
  backButtonColor = 'primary',
  backButtonProps = undefined,
  disableTypography = false,
  disablePortal = false,
  headProps = EMPTY_OBJECT,
  noWrap = true,
  title = null,
  titleText = getNodeText(title),
  onClickBack = undefined,
  ...passthru
}) => {
  const classes = useStyles()
  const isSm = useMatchesBreakpoint('only', 'sm')
  const Wrapper = disablePortal ? Fragment : Portal
  const wrapperProps = disablePortal ? EMPTY_OBJECT : {
    container: () => document.getElementById('pageTitle')
  }
  const { children: headChildren, ...headRest } = headProps

  return (
    <>
      {titleText || headChildren
        ? (
          <Head {...headRest}>
            <title>{titleText}</title>
            {headChildren ?? null}
          </Head>
        )
        : null}
      <Wrapper {...wrapperProps}>
        {onClickBack && (
          <IconButton
            {...backButtonProps}
            className={classes.backButton}
            onClick={onClickBack}
            edge="start"
            color={backButtonColor}
            size="large"
          >
            <ChevronLeft fontSize="large" />
          </IconButton>
        )}
        {disableTypography ? (title ?? titleText) : (
          <T
            align="left"
            color="textPrimary"
            component="h1"
            variant={isSm ? 'h2' : 'h1'}
            {...passthru}
            noWrap={noWrap}
          >
            {title ?? titleText}
          </T>
        )}
      </Wrapper>
    </>
  )
}

PageTitleComponent.displayName = 'PageTitle'
PageTitleComponent.propTypes = {
  backButtonColor: PropTypes.oneOf(['primary', 'secondary']),
  backButtonProps: PropTypes.shape(),
  disableTypography: PropTypes.bool,
  disablePortal: PropTypes.bool,
  headProps: PropTypes.objectOf(PropTypes.node),
  noWrap: PropTypes.bool,
  title: PropTypes.node,
  titleText: PropTypes.string,
  onClickBack: PropTypes.func,
}

const Memoized = memo(PageTitleComponent)

export default Memoized
