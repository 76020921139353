import { SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

export const path = `
  M2 11c0-.552.448-1 1-1h6.667C11.572 10 13 11.633 13 13.5S11.572 17 9.667 17c-1.905 0-3.334
  -1.633-3.334-3.5 0-.552.448-1 1-1 .553 0 1 .448 1 1 0 .895.661 1.5 1.334 1.5.672 0 1.333
  -.605 1.333-1.5S10.34 12 9.667 12H3c-.552 0-1-.448-1-1zM1 8c0 .552.448 1 1 1h8.148C12.323
  9 14 7.16 14 5s-1.677-4-3.852-4C7.974 1 6.296 2.84 6.296 5c0 .552.448 1 1 1 .553 0 1-.448
  1-1 0-1.153.877-2 1.852-2C11.124 3 12 3.847 12 5s-.876 2-1.852 2H2c-.552 0-1 .448-1 1zM18
  5c1.326 0 2.4 1.087 2.4 2.429v7.285c.972.738 1.6 1.914 1.6 3.238C22 20.188 20.21 22 18 22s
  -4-1.812-4-4.048c0-1.324.628-2.5 1.6-3.238V7.429C15.6 6.087 16.674 5 18 5zm.8 10.523V7.429c0
  -.448-.358-.81-.8-.81-.442 0-.8.362-.8.81V9c.442 0 .8.362.8.81 0 .447-.358.809-.8.809v1.762
  c.442 0 .8.362.8.81 0 .447-.358.809-.8.809v1.523h-.001H18.8 18.8z
`

const Icon = props => {
  const theme = useTheme()
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { air_temp: dataType = {} } = dataTypes
  const { color = theme.palette.orange.main } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path d={path} fill={fill} fillRule="evenodd" clipRule="evenodd" />
    </SvgIcon>
  )
}

export default Icon
