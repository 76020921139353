import { minutes } from 'milliseconds'
import nanoMemoize from 'nano-memoize'

import { shallowEquals } from './equality'

/**
 * @typedef {Object} MemoizerOptions
 * @property {number} [depth] - The depth of the object to compare
 * @property {number} [maxAge] - The maximum age of the memoized value
 */
/**
 *
 * @type {MemoizerOptions}
 */
export const defaultMemoizerOpts = {
  depth: 2,
  maxAge: minutes(60),
}
const memoize = (fn, opts) => nanoMemoize(fn, { ...defaultMemoizerOpts, ...opts })

/**
 *
 * @param {function} fn
 * @param {MemoizerOptions} [options=defaultMemoizerOpts]
 * @returns {function}
 */
export const shallowEqualsMemoizer = (fn, options = defaultMemoizerOpts) => {
  const { depth, ...opts } = options
  return nanoMemoize(fn, {
    ...defaultMemoizerOpts,
    ...opts,
    equals: (left, right) => shallowEquals(left, right, depth),
  })
}
export default memoize
