import { createRouteBundle } from 'redux-bundler'

import { allowed } from '~/src/Flags'

import createLogger from './Logging'

const logger = createLogger('createRouteBundle')

const processed = new Map()

export default (routesConfig, options) => ({
  ...createRouteBundle(routesConfig, options),
  init: store => {
    const routes = Object.entries(routesConfig)
    logger.debug('init for routes:', routesConfig)

    let callbackID
    let nextImporter
    const autoImporter = () => {
      let pattern
      let preload
      let component
      let config
      if (nextImporter) {
        [pattern, preload] = nextImporter
        if (preload.length) {
          ({ component, ...config } = preload[0])
        }
      } else {
        const dynamicToImport = routes.find(([, routeConfig]) => (
          routeConfig.component
            && !processed.has(routeConfig.component)
            && Boolean(routeConfig.component?.$importer)
        ))
        if (dynamicToImport) {
          [pattern, { component, ...config }] = dynamicToImport
        }
      }

      if (!component) return

      const [haveAccess] = allowed({
        ...config,
        ...store.select(['selectAvailableFeatures', 'selectConfig', 'selectPermittedActions'])
      })
      if (!haveAccess) {
        logger.debug('[autoImporter] no access to route', { component, config, pattern })
        processed.set(component, pattern)
        if (nextImporter) {
          nextImporter[1] = nextImporter[1].filter(p => !processed.has(p.component))
        }
        callbackID = requestIdleCallback(autoImporter)
        return
      }
      component.$importer().then(({ default: imported }) => {
        logger.debug('[autoImporter] imported component for route', { pattern, component: imported })
        if ('$preload' in imported && Array.isArray(imported.$preload)) {
          nextImporter = [pattern, imported.$preload]
        }
      }).finally(() => {
        // whether we've imported or not, we've processed this component
        processed.set(component, pattern)
        if (nextImporter) {
          const remainingPreloads = nextImporter[1].filter(p => !processed.has(p.component) && p.component?.$importer)
          if (remainingPreloads.length) {
            nextImporter[1] = remainingPreloads
          } else {
            nextImporter = null
          }
        }
        callbackID = requestIdleCallback(autoImporter)
      })
    }
    callbackID = requestIdleCallback(autoImporter)
    return () => cancelIdleCallback(callbackID)
  },
})
