/* eslint-disable babel/no-invalid-this, no-restricted-globals */
/*!
 * Copyright 2015 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
 * or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/*
 * @see https://developers.google.com/web/updates/2015/08/using-requestidlecallback
 */
(function ricShim(w) {
  if (!w) return
  w.requestIdleCallback = w.requestIdleCallback
    ?? function rIC(cb) {
      return setTimeout(() => {
        const start = Date.now()
        cb({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start))
          },
        })
      }, 500)
    }

  w.cancelIdleCallback = w.cancelIdleCallback
    ?? function cancelIC(id) {
      clearTimeout(id)
    }
}(typeof window === 'object' ? window : {}))
