/* eslint-disable no-await-in-loop */
import { lazy } from 'react'

import createLogger from '../Logging'

const logger = createLogger('lazyWithRetries')

const errorMatch = 'Failed to fetch dynamically imported module: '

export const lazyWithRetries = importer => {
  const retryImport = async () => {
    try {
      return await importer()
    } catch (error) {
      if (!error.message.includes(errorMatch)) throw error
      let errorToThrow = error
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i += 1) {
        await new Promise(resolve => {
          setTimeout(resolve, 250 * (2 ** i))
        })
        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        const url = /https?:\/\/\w+/.test(error.message) ? new URL(
          error.message
            .replace(errorMatch, '')
            .trim()
        ) : null
        if (url) {
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
          url.searchParams.set('t', `${+new Date()}`)

          try {
            return await import(/* @vite-ignore */ url.href)
          } catch (e) {
            errorToThrow = e
            console.error(e)
            logger.debug('retrying import after', 250 * (2 ** (i + 1)), 'miliseconds')
          }
        }
      }
      throw errorToThrow
    }
  }
  return Object.assign(lazy(retryImport), { $importer: importer })
}
