import { getUrlFactory } from '~/src/Lib/Nav'

export { default as irrigationSchedules } from './entity'
export {
  entityBundle as irrigationScheduleChangeNotifications,
  listBundle as irrigationScheduleChangeNotificationList,
} from './scheduleChangeNotifications'
export {
  default as irrigationSchedulesList,
  facilityIrrigationSchedules,
  roomIrrigationSchedules,
} from './list'
export { default as irrigationScheduleTemplates } from './templatesList'
export * from './controllers'
export const getControllerURL = getUrlFactory('irrigationControllers')
export { default as irrigationProgramHistory } from './programs/programHistory'
export { default as irrigationScheduleExpectedEvents } from './expectedIrrigationEvents'
export { default as irrigationVolume } from './irrigationVolume'
