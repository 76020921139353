export const MAX_STEP_DAYS = 10
export const DESKTOP_ITEM_HEIGHT = 72
export const MOBILE_ITEM_HEIGHT = 64
export const INFINITE_LOADER_THRESHOLD = 15
export const SCOPING_PROPS = ['activeHarvest', 'activeRoom', 'activeUser']
export const FETCH_SCOPING_KEYS = [...SCOPING_PROPS, 'activeTypes']
export const SCOPING_PROP_DEFAULT_VALUE = Symbol.for('SCOPING_PROP_DEFAULT_VALUE')
export const NO_MORE_ITEMS_ID = 'NO_MORE_ITEMS'
export const NO_MORE_ITEMS = Symbol.for(NO_MORE_ITEMS_ID)
export const NO_MORE_ITEMS_ID_LOWERCASE = NO_MORE_ITEMS_ID.toLowerCase()
export const NO_MORE_BEFORE_ITEM = { uuid: (`${NO_MORE_ITEMS_ID}_BEFORE_0`).toLowerCase(), data: NO_MORE_ITEMS }
export const NO_MORE_AFTER_ITEM = { uuid: (`${NO_MORE_ITEMS_ID}_AFTER_0`).toLowerCase(), data: NO_MORE_ITEMS }
export const FILTER_TOGGLE_DEBOUNCE_INTERVAL = 1000
