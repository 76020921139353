import createEntityBundle from '~/src/Lib/createEntityBundle'
import { Floorplan as schema } from '~/src/Store/Schemas'

const name = 'floorplans'

export default createEntityBundle({
  name,
  apiConfig: { schema },
  // scope,
})
