import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { toISODateOrNil } from '~/src/Lib/Data'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { LightSchedule as schema } from '~/src/Store/Schemas'

export const prepareData = data => {
  const {
    id,
    startDate,
    endDate,
    startTime: startTimeRaw,
    duration: durationRaw,
    phase,
    room: rawRoom,
    alert,
    alertThreshold,
    alertWindow,
  } = data

  const room = rawRoom?.id ?? rawRoom

  let startTime = startTimeRaw
  if (typeof startTime === 'number') {
    const minutes = startTime % 60
    const hour = (startTime - minutes) / 60
    startTime = `${hour % 24 < 10 ? `0${hour % 24}` : hour}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:00`
  }

  let duration = durationRaw
  if (typeof duration === 'number') {
    const minutesPart = duration % 1
    const hours = Math.floor(duration)
    const minutes = Math.floor(minutesPart * 60)
    duration = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:00`
  }

  return {
    id,
    startTime,
    duration,
    startDate: toISODateOrNil(startDate),
    endDate: toISODateOrNil(endDate),
    room,
    phase,
    alert,
    alertThreshold,
    alertWindow,
  }
}

const name = 'lightSchedules'
const writesConfig = { wrapper: handler => markAnnotationsOutdatedWrapper(handler, { invalidateChart: true }) }

const entityBundle = createEntityBundle({
  name,
  schema,
  apiConfig: {
    schema,
    prepareData,
    save: writesConfig,
    delete: writesConfig,
  },
})

export default {
  ...entityBundle,
  selectLightSchedulesByPhase: createSelector(
    'selectLightSchedules',
    'selectRecipes',
    (lightSchedules, recipes) => ({
      ...Object.fromEntries(Object.values(lightSchedules).map(
        lightSchedule => (lightSchedule?.phase ? [lightSchedule.phase, lightSchedule] : null)
      ).filter(Boolean)),
      ...Object.fromEntries(Object.values(recipes).reduce((res, recipe) => [...res, ...recipe.phases], [])
        .map(phase => (phase.lightSchedule ? [phase.id, phase.lightSchedule] : null)).filter(([, value]) => Boolean(value?.id)))
    })
  )
}
