import { useCallback, useEffect, useState } from 'react'

import memoizeOne from 'memoize-one'
import { useConnect } from 'redux-bundler-hook'

import { client as filestackClient } from 'filestack-react'
import * as yup from 'yup'

import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'

import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants'
import { randomId } from '../ids'
import memoize, { shallowEqualsMemoizer } from '../memoizer'

const logger = createLogger('filestack')

export const attachmentValidationSchema = yup.lazy(value => {
  if (value == null) return yup.mixed().nullable().optional()
  const attachmentSchema = yup.object({
    handle: yup.string().trim().nullable(),
    filename: yup.mixed().when(['mimetype', 'name'], ([mimetype, name], schema) => (
      mimetype && !mimetype.includes('image') && (!name || !name.trim().length)
        ? yup.string().trim().required('a filename is required')
        : schema.nullable().optional()
    )),
    url: yup.string().trim().required('a URL is required'),
    mimetype: yup.mixed().when(['type', 'url'], ([type, url], schema) => (
      url && !type
        ? yup.string().trim().required('a mimetype is required')
        : schema.nullable().optional()
    )),
  })
  if (!Array.isArray(value)) {
    return attachmentSchema
  }
  return yup.array().of(attachmentSchema).min(0)
})

export const allowedImageTypes = [
  // image types
  'avif', 'bmp', 'gif', 'heic', 'heif', 'jpeg', 'png', 'tiff', 'webp'
].map(type => `image/${type}`)

export const allowedDocumentExtensions = [
  // document types
  // '.doc', '.docm', '.docx',
  // '.odp', '.ods', '.odt',
  '.pdf',
  // '.ppt', '.pptm', '.pptx',
  // '.xls', '.xlsm', '.xlsx',
]

export const getFileName = file => file.name ?? file.filename ?? ''
export const getFileType = file => file.type ?? file.mimetype ?? ''
export const getFileExtension = file => (
  getFileName(file).slice(getFileName(file).lastIndexOf('.'))
)

const sharedTransforms = memoizeOne((height, width) => [(height || width) && `resize=${[
  height && `height:${height}`,
  width && `width:${width}`,
  'fit:crop',
  'align:center'
].filter(Boolean).join(',')}`])

export const getDocumentThumbnailTransforms = shallowEqualsMemoizer((file, { height, width }) => {
  const extension = getFileExtension(file)
  const allowedDoc = allowedDocumentExtensions.includes(extension)
  if (!extension || !allowedDoc) return EMPTY_ARRAY
  return [
    extension !== '.pdf' ? { ...sharedTransforms({ height, width }) } : null,
    'output=f:png,page:1,quality:85'
  ].filter(Boolean)
})
export const getDocumentTransforms = shallowEqualsMemoizer((file, { height, width }) => {
  const extension = getFileExtension(file)
  const allowedDoc = allowedDocumentExtensions.includes(extension)
  if (!extension || !allowedDoc) return EMPTY_ARRAY
  return [
    ...sharedTransforms(height, width),
    extension !== '.pdf' && 'output=f:pdf,quality:input'
  ].filter(Boolean)
})

const defaultImageTransforms = ['rotate=deg:exif', 'auto_image']
export const getImageTransforms = memoize(({ height, width }) => [
  ...sharedTransforms(height, width),
  // this default image transforms is breacking the behavior of upload an image
  // ...defaultImageTransforms,
].filter(Boolean))

export const fakeClient = {
  _progress: null,
  upload(file, { onProgress }) {
    return new Promise(resolve => {
      const global = getGlobal()
      const doResolve = () => resolve({
        handle: randomId(),
        key: `${randomId()}_${file.name}`,
        filename: file.name,
        mimetype: file.type,
        size: file.size,
        get url() { return `https://fake.url/${this.handle}` }
      })
      if (global.setTimeout) {
        let progress = 0
        const doProgress = () => {
          onProgress({ totalPercent: progress })
          if (progress < 100) {
            setTimeout(doProgress, Math.round(50 + (Math.random() * 100)))
            progress = Math.min(100, progress + 5 + Math.round(Math.random() * 10))
            return
          }
          doResolve()
        }
        doProgress()
        return
      }
      onProgress({ totalPercent: 100 })
      doResolve()
    })
  }
}

const EMPTY_FILESTACK = Object.freeze({})
export const useFilestackClient = ({ filestack = EMPTY_FILESTACK }, { client: passedClient }) => {
  const { apikey, ...security } = filestack
  const [client, setClient] = useState(() => {
    if (passedClient) {
      return passedClient
    }
    if (apikey) {
      return filestackClient.init(apikey, { security, forwardErrors: true })
    }
    return null
  })
  useEffect(() => {
    if (client || !apikey) return
    setClient(filestackClient.init(apikey, { security, forwardErrors: true }))
  }, [client, apikey, security])

  return client
}

export const filestackURL = (file, me, transform = EMPTY_OBJECT) => {
  const { handle = '', url } = file
  if (!handle) return null
  const fileType = getFileType(file)

  const [, origin, altHandle] = url.match(/^(http.*)\/(\w+)$/) ?? EMPTY_ARRAY
  const base = [origin]

  const security = me?.filestack
  if (security) {
    base.push(`security=policy:${security.policy},signature:${security.signature}`)
  }
  if (!Array.isArray(transform)) {
    const { transforms = 'AUTO', ...transformOptions } = transform
    if (typeof transforms === 'string' && transforms.startsWith('AUTO')) {
      if (fileType.includes('image')) {
        base.push(getImageTransforms(transformOptions))
      } else {
        base.push(
          ...(
            transforms.endsWith('THUMBNAIL')
              ? getDocumentThumbnailTransforms(file, transformOptions)
              : getDocumentTransforms(file, transformOptions)
          )
        )
      }
    }
  } else {
    const neededTransforms = defaultImageTransforms.filter(t => !transform.includes(t))
    if (neededTransforms.length) base.push(...neededTransforms)
  }
  if (!fileType.includes('image')) {
    logger.debug('document =>', { url, transform, handle })
  }

  return [
    ...base,
    ...(Array.isArray(transform) ? transform : []),
    handle ?? altHandle
  ].join('/')
}

export const useFilestackURL = () => {
  const { me = EMPTY_OBJECT } = useConnect('selectMe')
  const { filestack } = me
  return useCallback((attachment, actions = []) => (
    attachment ? filestackURL(attachment, { filestack }, actions) : null
  ), [filestack])
}
