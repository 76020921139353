import { useMemo } from 'react'

import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import { useConnect } from 'redux-bundler-hook'

import { Typography } from '@mui/material'

import {
  getBattery,
  getSignalIcon,
  getStatus,
  rssiToSignal,
} from '~/src/Device/utils'
import {
  formattedDate,
  getDateTime,
  getShortDateDiff,
  getTimeDiffHours,
} from '~/src/Lib/Utils'

export const getBatteryPercentageWithColor = (value, classes) => {
  if (value >= 30) {
    return <Typography color="primary">{value}%</Typography>
  }
  if (value >= 20) {
    return <Typography className={classes.textWarning}>{value}%</Typography>
  }
  return <Typography color="error">{value}%</Typography>
}

export const getSignalPercentageWithColor = (value, classes) => {
  if (value >= 50) {
    return <Typography color="primary">{value}%</Typography>
  }
  if (value >= 25) {
    return <Typography className={classes.textWarning}>{value}%</Typography>
  }
  return <Typography color="error">{value}%</Typography>
}

const formatDateTime = ts => {
  if (!ts) return 'Never'
  const tsDT = getDateTime(ts)
  const sameDay = getDateTime('now').hasSame(tsDT, 'day')

  return formattedDate(ts, sameDay ? 'SHORT_TIME' : 'SHORT_DATETIME')
}
const getStatusTooltipText = (online, lastCommunication, deepSleep) => {
  if (deepSleep) return `Deep sleep since: ${formatDateTime(deepSleep)}`
  if (lastCommunication && !online) return `Offline since: ${formatDateTime(lastCommunication)}`
  return `Last communicated: ${formatDateTime(lastCommunication)}`
}

// Convert device information into useful data fields for the table
export const getDeviceDataFactory = memoizeOne(({ models, rooms = {}, zones = {} }) => device => {
  if (!device || !models) return null
  const {
    id,
    aqualab,
    battery,
    assigned,
    createdOn,
    deepSleep,
    deviceName,
    lastCommunication,
    online,
    provisionedOn,
    room: roomId,
    rssi,
    linkQuality,
    sensor,
    serialNumber,
    zone: zoneId,
  } = device

  const modelKey = device?.sensor?.modelKey ?? device.modelKey
  const model = modelKey in models ? models[modelKey] : null
  const signal = linkQuality ?? rssiToSignal(rssi)

  const data = {
    id,
    aqualab,
    assigned,
    createdOn,
    provisionedOn,
    deviceName,
    serialNumber,
    model: model?.name ?? '',
    room: roomId in rooms ? rooms[roomId] : null,
    zone: zoneId in zones ? zones[zoneId] : null,
    lastComm: lastCommunication ? getShortDateDiff(lastCommunication) : 'Never',
    statusTooltip: getStatusTooltipText(online, lastCommunication, deepSleep),
    battery,
    signal,
    isGateway: model?.gateway ?? false,
    icons: {
      BatteryIcon: getBattery(battery).Icon,
      SignalIcon: getSignalIcon(signal).Icon,
      StatusIcon: getStatus(online, deepSleep),
    },
  }

  if (sensor) {
    data.sensorLastComm = getShortDateDiff(sensor.lastCommunication)
    data.sensorStatusTooltip = getStatusTooltipText(sensor.online, sensor.lastCommunication)
    data.icons.SensorStatusIcon = getStatus(sensor.online)
  }

  return data
})

export const getDevicePendingStatus = (devicesProvisioningStatus, device = false) => {
  if (device?.serialNumber && devicesProvisioningStatus) {
    return devicesProvisioningStatus.find(d => d?.serialNumber === device?.serialNumber)?.status === 'PENDING'
  }
  return false
}

export const usePendingDevices = () => {
  const { devicesProvisioningStatus } = useConnect('selectDevicesProvisioningStatus')
  return useMemo(() => devicesProvisioningStatus.some(({ status }) => status === 'PENDING'), [devicesProvisioningStatus])
}

export const DEVICE_STATES = {
  ALL: '',
  INSTALLED: 'installed',
  NOT_INSTALLED: 'notInstalled',
  LOW_BATTERY: 'lowBattery',
  LOW_SIGNAL: 'lowSignal',
  ASLEEP: 'asleep',
  OFFLINE: 'offline',
  RECENTLY_PROVISIONED: 'recentlyProvisioned',
}
export const stateFilters = [
  { key: DEVICE_STATES.ALL, label: i18n`All Devices` },
  { key: DEVICE_STATES.INSTALLED, label: i18n`Assigned` },
  { key: DEVICE_STATES.NOT_INSTALLED, label: i18n`Unassigned` },
  { key: DEVICE_STATES.LOW_BATTERY, label: i18n`Low Battery` },
  { key: DEVICE_STATES.LOW_SIGNAL, label: i18n`Low Signal` },
  { key: DEVICE_STATES.ASLEEP, label: i18n`Asleep` },
  { key: DEVICE_STATES.OFFLINE, label: i18n`Offline` },
  { key: DEVICE_STATES.RECENTLY_PROVISIONED, label: i18n`Recently Activated` },
]

export const useNewDevices = devices => {
  const {
    devicesProvisioningStatus,
    provisioningStatusRaw
  } = useConnect(
    'selectProvisioningStatusRaw',
    'selectDevicesProvisioningStatus'
  )
  const { pollingStart } = provisioningStatusRaw
  const pendingDevices = useMemo(() => devicesProvisioningStatus.filter(d => d.status === 'PENDING'), [devicesProvisioningStatus])
  const newDevices = useMemo(() => devices.reduce((acc, d) => {
    const justProvisioned = getTimeDiffHours(getDateTime(pollingStart), getDateTime(d.provisionedOn)) > 0
    const wasProvisioned = getTimeDiffHours(getDateTime(pollingStart), getDateTime(d.provisionedOn)) < 24
    const gatewayIsPending = pendingDevices.find(pendingDevice => d.serialNumber === pendingDevice.serialNumber)
    if (pollingStart && justProvisioned && wasProvisioned && !d?.assigned && !gatewayIsPending) {
      return acc + 1
    }
    return acc
  }, 0), [devices, pollingStart, pendingDevices])
  return [pendingDevices, newDevices]
}
