/* eslint-disable no-plusplus */
import { useCallback, useEffect, useRef } from 'react'

import { pick } from 'ramda'

import config from '~/src/App/config'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { throttle } from '~/src/Lib/Utils/timing'

import { randomId } from '../ids'

const global = getGlobal()
const isBrowser = typeof global.document !== 'undefined'
const localStorage = safeStorage('local')

const logger = createLogger('useBluetoothScale')
const isDev = localStorage.debug === 'true' && config.ENVIRONMENT !== 'production'

const defaultFakeDevice = { name: ['fake_scale', randomId(1)].join('_').toUpperCase() }

const SERVICE_UUID = '175f8f23-a570-49bd-9627-815a6a27de2a'
const READ_CHARACTERISTIC = 'cacc07ff-ffff-4c48-8fae-a9ef71b75e26'
export const formatWeightReading = reading => {
  const [, rawWeight, unit, instability] = reading.split(/\s+/)
  const value = Number(rawWeight) || 0
  const unitSymbol = unit === '?' ? undefined : (unit || undefined)
  const unstable = Boolean(instability) || unit === '?'

  const output = { value, unitSymbol, unstable }

  return output
}

const listeners = new Map()

const sleep = async seconds => new Promise(resolve => {
  setTimeout(resolve, seconds * 1000)
})

export const exponentialBackoff = async (toTry, remaining = 3, delay = 2) => {
  try {
    const result = await toTry()
    return result
  } catch (error) {
    logger.debug('exponential backoff encountered error:', error)
    if (remaining === 0) {
      listeners.forEach(({ onError }) => onError(error))
      throw error
    }
    await sleep(delay)
    return exponentialBackoff(toTry, remaining - 1, delay * 2)
  }
}

const getProgress = step => Math.round((step / 6) * 100)

const OPTION_DEFAULTS = {
  onWeightReceived: () => {
    throw new Error('useBluetoothScale requires an onWeightReceived callback. None provided.')
  },
  onConnect: device => logger.debug('connected to scale:', device?.name),
  onConnectProgress: progress => logger.debug('connection process ', progress, '% complete'),
  onError: error => logger.error(error),
  maxRetries: 3,
  delay: 2,
  noThrottle: false,
}

const callbackPicker = pick(['onWeightReceived', 'onConnect', 'onConnectProgress', 'onError'])

/**
 *
 * @param {Object} options
 * @param {function(Object)} options.onWeightReceived Called with { value: ##.##, unitSymbol: 'lb' }
 * @param {function} [options.onConnect] Called when connection is made
 * @param {function} [options.onConnectProgress] Called with connection progress
 * @param {function(string)} [options.onError] Called if there is an error in communication
 * @param {number} [options.maxRetries=3] One of g, kg, lb, oz
 * @param {number} [options.delay=2] One of g, kg, lb, oz
 */
export const useBluetoothScale = (opts = OPTION_DEFAULTS) => {
  const options = {
    ...OPTION_DEFAULTS,
    ...opts
  }
  const deviceRef = useRef()
  const characteristicRef = useRef()
  const optionsRef = useRef(callbackPicker(options))

  useEffect(() => {
    Object.entries(optionsRef.current).forEach(([key, old]) => {
      if (options[key] !== old) {
        optionsRef.current[key] = options[key]
      }
    })
    listeners.set(deviceRef, optionsRef.current)
  }, Object.values(options))

  const weightNotificationListener = event => {
    if (!event.target.value?.buffer) return
    logger.debug('characteristicListener called:', event)

    const rawWeight = String.fromCharCode.apply(null, new Uint8Array(event.target.value.buffer))
    if (!rawWeight) return
    logger.debug('characteristicListener handling weight:', rawWeight)
    const formatted = formatWeightReading(rawWeight)
    if (!formatted.value) return

    listeners.forEach(({ onWeightReceived }) => onWeightReceived && onWeightReceived(formatted))
  }
  const characteristicListener = useCallback(options.noThrottle ? weightNotificationListener : throttle(weightNotificationListener, 250), [])

  const connectServer = useCallback(async () => {
    logger.debug('connect server called')
    return exponentialBackoff(async () => {
      const { current: device } = deviceRef

      if (!device?.gatt?.connect) {
        listeners.forEach(({ onError }) => onError('No scale service found on connected bluetooth device. Code 100.'))
        return false
      }
      const server = await device.gatt.connect()
      listeners.forEach(({ onConnectProgress }) => onConnectProgress(getProgress(2)))
      const service = await server.getPrimaryService(SERVICE_UUID)
      listeners.forEach(({ onConnectProgress }) => onConnectProgress(getProgress(3)))
      logger.debug('service located:', service)
      const characteristic = await service.getCharacteristic(READ_CHARACTERISTIC)
      characteristicRef.current = characteristic
      listeners.forEach(({ onConnectProgress }) => onConnectProgress(getProgress(4)))
      logger.debug('characteristic retrieved:', characteristic)
      characteristic.addEventListener('characteristicvaluechanged', characteristicListener)
      listeners.forEach(({ onConnectProgress }) => onConnectProgress(getProgress(5)))
      characteristic.startNotifications()
      listeners.forEach(({ onConnect, onConnectProgress }) => {
        onConnectProgress(getProgress(6))
        onConnect(deviceRef.current)
      })

      return true
    }, optionsRef.maxRetries, optionsRef.delay)
  }, [])

  const disconnectListener = useCallback(async () => {
    characteristicRef.current?.removeEventListener('characteristicvaluechanged', characteristicListener)
    logger.warn('Disconnected from scale. Attempting to reconnect.')
    return connectServer()
  }, [connectServer])

  const clickHandler = useCallback(async () => {
    listeners.forEach(({ onConnectProgress }) => onConnectProgress(0))

    try {
      deviceRef.current = await navigator.bluetooth.requestDevice({
        filters: [{ services: [SERVICE_UUID] }]
      })
      logger.debug('device connected:', deviceRef.current)
      if (!deviceRef.current?.addEventListener) {
        listeners.forEach(({ onError }) => onError('Connected bluetooth device is incompatible. Code 10.'))
        return false
      }
      deviceRef.current.addEventListener('gattserverdisconnected', disconnectListener)
      listeners.forEach(({ onConnectProgress }) => onConnectProgress(getProgress(1)))
      await connectServer()
      return true
    } catch (error) {
      logger.error('[useBluetoothScale] error encountered:', error)
      listeners.forEach(({ onError }) => onError('Unable to connect to bluetooth device. Code 1.'))

      return false
    }
  }, [])

  useEffect(() => {
    if (isBrowser && isDev) {
      logger.debug('setting up scale simulator')
      global.simulatedScale = {
        connect: async (fakeDevice = defaultFakeDevice) => {
          deviceRef.current = fakeDevice
          return new Promise(resolve => {
            let step = 0
            const takeStep = () => {
              listeners.forEach(({ onConnect, onConnectProgress }) => {
                onConnectProgress(getProgress(step))
                if (step === 6) {
                  onConnect(fakeDevice)
                }
              })
              if (step === 6) {
                resolve(deviceRef.current)
                return
              }
              step += 1
              setTimeout(takeStep, 500)
            }
            takeStep()
          })
        },
        sendWeight: weightObj => {
          if (!deviceRef.current) {
            deviceRef.current = defaultFakeDevice
            listeners.forEach(({ onConnect }) => onConnect(defaultFakeDevice))
          }
          if (typeof weightObj.value !== 'number' || !String(weightObj.unitSymbol).match(/^k?g|lb|oz$/)) {
            throw new TypeError('simulatedScale.sendWeight accepts only the following object shape: { value: Number, unitSymbol: String(/^g|kg|lb|oz$/)}')
          }
          const { onWeightReceived } = optionsRef.current
          onWeightReceived(weightObj)
        },
        print: string => {
          if (!deviceRef.current) {
            deviceRef.current = defaultFakeDevice
            listeners.forEach(({ onConnect }) => onConnect(defaultFakeDevice))
          }
          characteristicListener({ target: { value: { buffer: Buffer.from(String(string), 'utf8') } } })
        }
      }
    }

    return () => {
      listeners.delete(deviceRef)
      if (typeof deviceRef.current?.gatt?.disconnect === 'function') {
        deviceRef.current?.gatt?.disconnect()
      }
      if (typeof characteristicRef.current?.removeEventListener === 'function') {
        characteristicRef.current?.removeEventListener('characteristicvaluechanged', characteristicListener)
      }
      if (typeof deviceRef.current?.removeEventListener === 'function') {
        deviceRef.current.removeEventListener('gattserverdisconnected', disconnectListener)
      }
    }
  }, [])

  return clickHandler
}
