import { SvgIcon } from '@mui/material'

export default props => (
  <SvgIcon {...props}>
    <g transform="translate(3 4)">
      <path d="M17.4 9.69231C17.4 12.0714 15.5196 14 13.2 14C10.8804 14 9 12.0714 9 9.69231C9 5.49239 13.2 1.4 13.2 1.4C13.2 1.4 17.4 5.6 17.4 9.69231Z" />
      <path d="M7.6 6.44957C7.6 8.29996 6.033 9.8 4.1 9.8C2.167 9.8 0.599998 8.29996 0.599998 6.44957C0.599998 3.18297 4.1 0 4.1 0C4.1 0 7.6 3.26667 7.6 6.44957Z" />
    </g>
  </SvgIcon>
)
