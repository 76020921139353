import PropTypes from 'prop-types'

import { idPropType, regexPropType } from '../Lib/PropTypes'

export const graphShape = PropTypes.shape({
  id: regexPropType(/^[a-z_]+:(cultivar|device|room|zone):(room_\d+|\d+)$/),
  dataType: PropTypes.string,
  unit: idPropType,
})

export const yAxisShape = PropTypes.shape({
  boundsType: PropTypes.oneOf(['dataType', 'graph', 'unit']),
  dataType: PropTypes.string,
  graph: PropTypes.string,
  unit: idPropType,
})

export const graphDataShape = {
  activeDataType: PropTypes.string,
  graphDataTypes: PropTypes.instanceOf(Set),
  groups: PropTypes.objectOf(PropTypes.shape({
    graphs: PropTypes.arrayOf(graphShape),
    key: PropTypes.string,
    title: PropTypes.string,
    yAxes: PropTypes.arrayOf(yAxisShape),
  }))
}
