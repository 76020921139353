import { isValidElement, memo } from 'react'

import PropTypes from 'prop-types'

import createLogger from '~/src/Lib/Logging'
import { renderableType } from '~/src/Lib/PropTypes'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import Null from '~/src/UI/Shared/Null'

import { useCan } from './utils'

const logger = createLogger('Can')

export const CanComponent = ({
  can,
  children = null,
  Fallback = Null,
  FallbackProps = EMPTY_OBJECT
}) => {
  if (can) return children
  return isValidElement(Fallback) || typeof Fallback === 'string'
    ? Fallback
    : <Fallback {...FallbackProps} />
}

CanComponent.propTypes = {
  can: PropTypes.bool.isRequired,
  children: PropTypes.node,
  Fallback: PropTypes.oneOfType([renderableType, PropTypes.element]),
  // eslint-disable-next-line react/forbid-prop-types
  FallbackProps: PropTypes.object,
}

CanComponent.displayName = 'Can'

const Memoized = memo(({
  actions = EMPTY_ARRAY,
  any = false,
  ...passthru
}) => {
  const can = useCan(actions, any)

  return <CanComponent {...passthru} can={can} />
})
Memoized.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ])),
  ]),
  any: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
Memoized.displayName = `Memoized(${CanComponent.displayName})`

export default Memoized
