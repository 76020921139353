import {
  curry,
  groupBy,
  identity,
  partial,
  path,
  pipe,
  prop,
  sortBy,
  values,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { camelize, singularize } from 'inflection'

import {
  doEntitiesReceived,
  doEntitiesRemoved,
  ENTITIES_CLEARED,
  ENTITIES_RECEIVED,
  ENTITIES_REMOVED,
  entityReducerFactory,
  entitySelectorsFactory,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import {
  debounce,
  defer,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formattedNumber,
} from '~/src/Lib/Utils'

const logger = createLogger('Store#entities')
const debouncedWarn = debounce(logger.warn, 1000, true, 5000)

export const names = [
  // sensor related
  'models',
  'units',
  // facility related
  'organizations',
  'permissions',
  'zones',
  'licenses',
  'lightSchedules',
  'wasteMethods',
  'wasteReasons',
  'packageAdjustmentReasons',
  // irrigation/control related
  'controlDailyJournals',
  // misc
  'noteCategories',
  'packageStatuses',
  'itemCategories',
  'notifications',
  'notificationTypes',
  'users',
  // METRC
  'metrcHarvests',
  // cultivar related
  'kpiRecipes'
]

const entitySelectors = names.reduce(
  (acc, name) => ({ ...acc, ...entitySelectorsFactory({ name }) }),
  {}
)

export const ENTITY_ALTERNATE_LOOKUPS = {
  harvestPhases: 'phases',
}

const valueTemplate = '{{ value }}'
const formatter = (entity, value, precision = entity.precision) => (entity.template || valueTemplate).replace(
  valueTemplate,
  !Number.isNaN(Number(value))
    ? formattedNumber(Number(value) || 0, Number(precision) || 0)
    : value
)

const proxifyTemplateHandler = {
  get: (target, key) => {
    if (key === 'format' && target.template && !(key in target)) {
      target.format = formatter.bind(null, target)
    }
    return target[key]
  },
  has: (target, key) => {
    if (key in target) return true
    return key === 'format'
  },
  ownKeys: target => {
    const realKeys = Reflect.ownKeys(target)
    if (realKeys.includes('format')) {
      return realKeys
    }
    return [...realKeys, 'format']
  }
}
export const proxifyTemplateEntity = entity => new Proxy(entity, proxifyTemplateHandler)
const proxifyEntitiesHandler = {
  get: (target, key) => {
    const found = target[key]
    if (!found) return found
    if (typeof found.template === 'string' && found.template.includes(valueTemplate)) {
      return proxifyTemplateEntity(found)
    }
    return found
  },
  ownKeys: target => (
    target === EMPTY_OBJECT
      ? EMPTY_ARRAY
      : Reflect.ownKeys(target)
  )
}
const proxifyEntities = entities => new Proxy(entities, proxifyEntitiesHandler)

export default (entityBundles = []) => {
  let storeHandle
  const scopesByBundle = entityBundles.reduce((byBundle, bundle) => {
    const { name, scope } = bundle
    if (scope) byBundle[name] = scope
    return byBundle
  }, Object.create(null))

  const proxifyEntitiesContainer = object => new Proxy(object, {
    get: (target, key) => {
      if (typeof key !== 'string') {
        return undefined
      }
      let entityKey = !target[key] && key in ENTITY_ALTERNATE_LOOKUPS
        ? ENTITY_ALTERNATE_LOOKUPS[key]
        : key
      let entityId
      let found = target[entityKey]
      if (found === undefined && key.includes('_')) {
        [entityKey, entityId] = key.split('_')
        entityKey = !target[entityKey] && entityKey in ENTITY_ALTERNATE_LOOKUPS
          ? ENTITY_ALTERNATE_LOOKUPS[entityKey]
          : entityKey

        found = path([entityKey, entityId], target)
      }
      let filterEntity
      if (entityKey in scopesByBundle) {
        const { [entityKey]: scope } = scopesByBundle
        const { selector, test } = scope
        let selection = storeHandle
          && selector in storeHandle
          && storeHandle[selector]()
        if (selection == null) {
          debouncedWarn(`No value found for ${selector} wanted by ${entityKey} scope:`, scope)
          selection = EMPTY_OBJECT
        }
        filterEntity = partial(test, [selection])
      }

      if (entityId && found) {
        // if found has a scope test and fails it, then return nothing
        if (filterEntity && !filterEntity(found)) {
          return undefined
        }
        if (found?.template?.includes(valueTemplate)) {
          return proxifyTemplateEntity(found)
        }
        return found
      }
      return found && typeof found === 'object' ? proxifyEntities(found) : found
    },
    ownKeys: target => (
      target === EMPTY_OBJECT
        ? EMPTY_ARRAY
        : [...Reflect.ownKeys(target), ...Reflect.ownKeys(ENTITY_ALTERNATE_LOOKUPS)]
    )
  })

  const entityManager = {
    name: 'entities',
    reducer: entityReducerFactory(),
    init: store => {
      storeHandle = store
    },
    getMiddleware: () => {
      const setting = {}

      return curry((store, next, action) => {
        if (storeHandle !== store) {
          storeHandle = store
        }
        if (action?.type?.endsWith('SET_CURRENT')) {
          next(action)
          return
        }
        const { currents, entities, routeInfo, dialogRouteInfo } = store.select([
          'selectCurrents',
          'selectEntities',
          'selectRouteInfo',
          'selectDialogRouteInfo',
        ])
        if (!dialogRouteInfo && !routeInfo) {
          next(action)
          return
        }
        const { params: dialogParams } = dialogRouteInfo ?? EMPTY_OBJECT
        const { pattern, params: routeParams } = routeInfo ?? EMPTY_OBJECT
        let schema = dialogParams?.schema
        let id = Number(dialogParams?.id)
        if (!schema) {
          const [possibleSchema] = pattern.slice(1).split('/')
          if (entities[possibleSchema] || currents[possibleSchema]) {
            schema = possibleSchema
            id = Number(routeParams?.id)
          }
        }

        if (currents[schema] === id) {
          if (setting[schema] === id) {
            delete setting[schema]
          }
        } else if (schema && id && setting[schema] !== id) {
          const actionName = `do${camelize(singularize(schema))}SetCurrent`
          setting[schema] = id
          defer(() => store?.[actionName]?.(id))
        }
        next(action)
      })
    },
    doClearEntities: () => ({ type: ENTITIES_CLEARED }),
    doEntitiesReceived,
    doEntitiesRemoved,
    selectCurrents: createSelector(
      identity,
      'selectEntities',
      (root, entities) => proxifyEntitiesContainer(Object.keys(entities).reduce((currents, key) => {
        const finalKey = key in ENTITY_ALTERNATE_LOOKUPS ? ENTITY_ALTERNATE_LOOKUPS[key] : key
        return finalKey in root && 'current' in root[finalKey]
          ? { ...currents, [key]: root[finalKey].current }
          : currents
      }, EMPTY_OBJECT))
    ),
    selectEntities: createSelector(
      state => state.entities,
      proxifyEntitiesContainer
    ),
    selectNoteCategoriesByName: createSelector(
      'selectNoteCategories',
      categories => Object.values(categories).reduce(
        (acc, category) => ({
          ...acc,
          [category.name.toLowerCase()]: category,
        }),
        EMPTY_OBJECT
      )
    ),
    selectPermissionsByCategory: createSelector(
      'selectPermissions',
      pipe(values, sortBy(prop('sequence')), groupBy(prop('categoryName')))
    ),
    selectPermissionsByKey: createSelector(
      'selectPermissions',
      permissions => Object.values(permissions).reduce((p, c) => {
        p[c.key] = { ...c }
        return p
      }, {})
    ),
    ...entitySelectors,
    // TODO: Scoped selector to fix blocking bug; other selectors may need a similar fix
    ...entitySelectorsFactory({
      name: 'wasteTypes',
      scope: {
        selector: 'selectCurrentFacility',
        test: (currentFacility, wasteType) => (
          Array.isArray(currentFacility.wasteTypes)
            ? (
              currentFacility && wasteType
              && currentFacility.wasteTypes.includes(wasteType.id)
            )
            : false
        )
      },
    }),
    persistActions: [ENTITIES_RECEIVED, ENTITIES_REMOVED],
  }

  return entityBundles.concat(entityManager)
}
