import createEntityBundle, { createCustomAction, getActionType } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { Recipe as schema } from '~/src/Store/Schemas'

const name = 'recipes'
const singularName = 'recipe'
const defaultConfig = { name, singularName, schema }

const logger = createLogger('Recipe/bundle')

const {
  actionIdentifiers: saveAsRecipe,
  actionReducer: saveAsRecipeReducer,
  defaultState: defaultSaveAsRecipeState,
} = createCustomAction({
  actionType: 'save',
  actionName: 'as_recipe',
  reducerKey: 'saveas',
  loadingKey: 'saving'
})

const {
  actionIdentifiers: saveAsCopy,
  actionReducer: saveAsCopyReducer,
  defaultState: defaultSaveAsCopyState,
} = createCustomAction({
  actionType: 'copy',
  actionName: 'copy_recipe',
  reducerKey: 'copyas',
  loadingKey: 'copying'
})

const initialBundle = createEntityBundle({
  ...defaultConfig,
  apiConfig: { schema },
  scope: {
    selector: 'selectCurrentFacility',
    test: (currentFacility, recipe) => (
      currentFacility && recipe
      && (
        recipe.organizationId === currentFacility.organization
        || recipe.facilityId === currentFacility.id
      )
    )
  },
})

const {
  reducer: entityReducer,
  doRecipeSave: { createSnackbarAction },
} = initialBundle

const initialState = {
  ...entityReducer.defaultState,
  ...defaultSaveAsRecipeState,
  ...defaultSaveAsCopyState,
}

export default {
  ...initialBundle,
  reducer: (state = initialState, action) => {
    if (action.type && action.type.startsWith(saveAsRecipe.types.prefix)) {
      return saveAsRecipeReducer(state, action)
    }
    if (action.type && action.type.startsWith(saveAsCopy.types.prefix)) {
      return saveAsCopyReducer(state, action)
    }
    return entityReducer(state, action)
  },
  doRecipeSaveAs: payload => async ({ dispatch, store, apiFetch }) => {
    dispatch({ type: saveAsRecipe.types.start })
    let result = false
    try {
      result = await apiFetch('/recipes/from_harvest/', payload, { method: 'POST' })
      dispatch({ type: saveAsRecipe.types.succeed, payload: result })
      createSnackbarAction({ dispatch, status: 'succeed', payload: result })
      store.doMarkRecipeListAsOutdated()
    } catch (error) {
      result = error
      dispatch({ type: saveAsRecipe.types.fail, error })
      createSnackbarAction({ dispatch, status: 'fail', payload })
    }
    return result
  },
  doRecipeCopy: payload => async ({ dispatch, store, apiFetch }) => {
    dispatch({ type: saveAsCopy.types.start })
    let result = false
    try {
      payload.phases.forEach(p => p.tasks.forEach(t => delete t.id))
      result = await apiFetch('/recipes/', payload, { method: 'POST' })
      dispatch({ type: saveAsCopy.types.succeed, payload: result })
      createSnackbarAction({ dispatch, status: 'succeed', payload: result })
      store.doMarkRecipeListAsOutdated()
    } catch (error) {
      result = error
      dispatch({ type: saveAsCopy.types.fail, error })
      createSnackbarAction({ dispatch, status: 'fail', payload })
    }
    return result
  },
  persistActions: [getActionType('update', name)],
}
