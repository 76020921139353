import i18n from 'i18n-literally'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { parseApiErrors } from '~/src/Lib/Utils'
import { Kpi as schema } from '~/src/Store/Schemas'

const ACTION_TO_SUCCESS = {
  delete: i18n`deleted`,
  save: i18n`saved`,
}
const ACTION_TO_FAILURE = {
  delete: i18n`delete`,
  save: i18n`save`,
}

const bundle = createEntityBundle({
  name: 'kpis',
  apiConfig: {
    schema,
    snackbar: ({
      status,
      dispatch,
      error,
      action,
    }) => {
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? i18n`Successfully ${ACTION_TO_SUCCESS[action]} KPI`
            : i18n`Unable to ${ACTION_TO_FAILURE[action]} KPI: ${parseApiErrors(error)}`,
        ]
      })
    }
  },
})

export default {
  ...bundle
}
