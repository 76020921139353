import { prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'

import createLogger from '~/src/Lib/Logging'

import {
  climateDataTypes,
  dayNightOptions,
  humidityOptions,
  lightOptions,
  manualDataTypes,
  orderedDataTypes,
  substrateDataTypes,
} from '../Comparison/constants'

const logger = createLogger('Harvest/bundle#comparison')

const RUN_COMPARISON_BASE_CULTIVAR_SET = 'RUN_COMPARISON_BASE_CULTIVAR_SET'
const RUN_COMPARISON_DATA_TYPE_TOGGLED = 'RUN_COMPARISON_DATA_TYPE_TOGGLED'
const RUN_COMPARISON_PARAMS_SET = 'RUN_COMPARISON_PARAMS_SET'
const RUN_COMPARISON_REFRESHED = 'RUN_COMPARISON_REFRESHED'
const RUN_COMPARISON_SETTINGS_UPDATED = 'RUN_COMPARISON_SETTINGS_UPDATED'

const RUN_COMPARISON_FETCH_FAILED = 'RUN_COMPARISON_FETCH_FAILED'
const RUN_COMPARISON_FETCH_FINISHED = 'RUN_COMPARISON_FETCH_FINISHED'
const RUN_COMPARISON_FETCH_STARTED = 'RUN_COMPARISON_FETCH_STARTED'

const initialDataTypes = Object.fromEntries(orderedDataTypes.map(dataTypeKey => [dataTypeKey, true]))

const initialSettings = {
  showYAxis: true,
  dayNight: dayNightOptions[0].value,
  light: lightOptions[0].value,
  humidity: humidityOptions[0].value,
}

const initialState = {
  data: {},
  dataTypes: initialDataTypes,
  fetchedAt: {},
  params: {},
  selectedHarvestCultivars: {},
  settings: initialSettings,
}

const runComparisonBundle = {
  name: 'runComparison',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case RUN_COMPARISON_DATA_TYPE_TOGGLED:
        return {
          ...state,
          dataTypes: {
            ...state.dataTypes,
            [action.payload]: !state.dataTypes[action.payload],
          },
        }
      case RUN_COMPARISON_BASE_CULTIVAR_SET:
        return {
          ...state,
          selectedHarvestCultivars: {
            ...state.selectedHarvestCultivars,
            ...action.payload,
          },
        }
      case RUN_COMPARISON_FETCH_STARTED:
        return {
          ...state,
          inflight: { ...state.inflight, [action.chartId]: true },
          shouldFetch: { ...state.shouldFetch, [action.chartId]: false },
        }
      case RUN_COMPARISON_FETCH_FINISHED:
        return {
          ...state,
          charts: { ...state.charts, ...action.payload },
          inflight: { ...state.inflight, [action.chartId]: false },
        }
      case RUN_COMPARISON_FETCH_FAILED:
        return {
          ...state,
          errors: { ...state.errors, ...action.payload },
          inflight: { ...state.inflight, [action.chartId]: false },
        }
      case RUN_COMPARISON_PARAMS_SET:
        return {
          ...state,
          params: action.payload,
        }
      case RUN_COMPARISON_REFRESHED:
        return {
          ...state,
          fetchedAt: {
            ...state.fetchedAt,
            [action.fetchId]: Date.now(),
          },
        }
      case RUN_COMPARISON_SETTINGS_UPDATED:
        return {
          ...state,
          settings: {
            ...state.settings,
            ...action.payload,
          }
        }
      default:
        return state
    }
  },
  selectRunComparisonRaw: prop('runComparison'),
  selectRunComparisonSelectedHarvestCultivars: createSelector('selectRunComparisonRaw', prop('selectedHarvestCultivars')),
  selectRunComparisonDataTypes: createSelector('selectRunComparisonRaw', prop('dataTypes')),
  selectRunComparisonFetchedAt: createSelector('selectRunComparisonRaw', prop('fetchedAt')),
  selectRunComparisonParams: createSelector('selectRunComparisonRaw', prop('params')),
  selectRunComparisonSettings: createSelector('selectRunComparisonRaw', prop('settings')),
  selectRunComparisonFilters: createSelector(
    'selectCurrentHarvestId',
    'selectRunComparisonSelectedHarvestCultivars',
    'selectRunComparisonDataTypes',
    (harvestId, selectedHarvestCultivars, dataTypeFlags) => ({
      baseHarvestCultivarId: selectedHarvestCultivars[harvestId] ?? null,
      climate: climateDataTypes.filter(dataTypeKey => dataTypeFlags[dataTypeKey]),
      substrate: substrateDataTypes.filter(dataTypeKey => dataTypeFlags[dataTypeKey]),
      manual: manualDataTypes.filter(dataTypeKey => dataTypeFlags[dataTypeKey]),
      get selectedDataTypes() {
        return [
          this.climate,
          this.substrate,
          this.manual,
        ].flat()
      }
    }),
  ),
  doRunComparisonFetch: fetchId => async ({ dispatch }) => {
    dispatch({ type: RUN_COMPARISON_FETCH_STARTED, fetchId })
  },
  doRunComparisonRefresh: params => ({ dispatch, store }) => {
    const {
      harvests,
      recipes,
      runComparisonFetchedAt,
      runComparisonParams,
    } = store.select([
      'selectHarvests',
      'selectRecipes',
      'selectRunComparisonFetchedAt',
      'selectRunComparisonParams',
    ])
    const payload = params ?? runComparisonParams
    const { harvest: harvestId, recipe: recipeId } = payload
    if (!harvestId && !recipeId) return
    const harvest = harvests[harvestId]
    const recipe = recipes[recipeId]
    const fetchId = harvest?.uuid ?? recipe?.uuid
    if (!fetchId) return
    const fetchedAt = runComparisonFetchedAt[fetchId]
    const shouldFetch = !fetchedAt || Date.now() - fetchedAt > minutes(10)
    if (shouldFetch) {
      dispatch({ type: RUN_COMPARISON_REFRESHED, payload, fetchId })
      if (harvest?.payloadType === 'summary') {
        store.doHarvestFetch(harvest.id)
      }
      store.doRunComparisonFetch(fetchId)
    }
  },
  doRunComparisonSetBaseCultivar: harvestCultivar => ({ dispatch, store }) => {
    const harvestCultivarId = harvestCultivar?.id ?? harvestCultivar
    const harvestId = store.selectCurrentHarvestId()
    return dispatch({ type: RUN_COMPARISON_BASE_CULTIVAR_SET, payload: { [harvestId]: harvestCultivarId } })
  },
  doRunComparisonSetParams: params => ({ type: RUN_COMPARISON_PARAMS_SET, payload: params }),
  doRunComparisonToggleDataType: dataTypeKey => ({ type: RUN_COMPARISON_DATA_TYPE_TOGGLED, payload: dataTypeKey }),
  doRunComparisonUpdateSettings: updates => ({ type: RUN_COMPARISON_SETTINGS_UPDATED, payload: updates }),
  reactRunComparisonSetBaseCultivar: createSelector(
    'selectHarvest',
    'selectRunComparisonFilters',
    (harvest, runComparisonFilters) => {
      if (!harvest.cultivars?.length) return null
      const { baseHarvestCultivarId } = runComparisonFilters
      const validHarvestCultivars = harvest.cultivars.filter(hc => hc.flowerZones.length)
      const selectedHarvestCultivar = validHarvestCultivars.find(hc => hc.id === baseHarvestCultivarId)
      if (validHarvestCultivars.length && !selectedHarvestCultivar) {
        const firstValidHarvestCultivar = validHarvestCultivars[0].id
        logger.debug('[reactor] Setting base harvest cultivar:', firstValidHarvestCultivar)
        return { actionCreator: 'doRunComparisonSetBaseCultivar', args: [firstValidHarvestCultivar] }
      }
      if (!validHarvestCultivars.length && baseHarvestCultivarId) {
        logger.debug('[reactor] Unsetting base harvest cultivar')
        return { actionCreator: 'doRunComparisonSetBaseCultivar', args: [null] }
      }
      return null
    },
  ),
}

export default runComparisonBundle
