import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialFutureEventsBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationFutureEvents',
  actionBaseType: 'FACILITY_IRRIGATION_FUTURE_EVENTS',
  getPromise: ({ apiFetch }) => {
    const now = getDateTime('now')
    return apiFetch('/irrigationSchedules/get_future_expected_open_times/', {
      /* eslint-disable babel/camelcase */
      start_date: now.toISODate(),
      end_date: now.plus({ day: 1 }).toISODate(),
      /* eslint-enable babel/camelcase */
    }, { method: 'POST' }).then(response => response.map(event => ({
      ...event,
      schedule: event.scheduleId,
    })))
  },
  staleAfter: ms.minutes(5),
})

export default {
  ...initialFutureEventsBundle,
  reactFetchFacilityIrrigationFutureEvents: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationActiveSchedules',
      'selectFacilityIrrigationFutureEventsShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (activeSchedules, shouldUpdate, { view }, { pattern }) => {
      if (activeSchedules && shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationFutureEvents' }
      }
      return null
    },
  }),
}
