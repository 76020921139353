import i18n from 'i18n-literally'
import { createSelector } from 'redux-bundler'

import { DateTime } from 'luxon'
import { normalize } from 'normalizr'

import createEntityBundle, {
  doEntitiesReceived,
  doEntitiesRemoved,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, getDueDateDiff, uniqueId } from '~/src/Lib/Utils'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Task as schema } from '~/src/Store/Schemas'

import { prepareData } from './shape'

const logger = createLogger('Task/entityBundle')

const createRecipeTaskSnackbar = ({
  dispatch,
  status,
  message,
}) => dispatch({
  actionCreator: 'doAddSnackbarMessage',
  args: [message, status],
})

export const dueDateDiff = (task = {}) => {
  const taskComplete = !!task.completedOn
  const className = taskComplete ? '' : 'text-danger'

  if (task.dueDate) {
    const dueDate = DateTime.fromISO(task.dueDate)
    const { daysDiff, text } = getDueDateDiff(dueDate)

    if (daysDiff < 0) {
      return {
        text,
        className: '',
      }
    }

    return {
      text,
      className,
    }
  }

  return { text: '', className }
}

const name = 'tasks'
const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }

const bundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData,
    schema,
    snackbar: 'title',
    save: writesConfig,
    delete: writesConfig,
  },
  customActions: [
    {
      action: 'complete_toggle',
      handler: markAnnotationsOutdatedWrapper(
        ({ payload, apiFetch }) => apiFetch(`/tasks/${payload.id}/complete/`, null, { method: 'POST' })
      ),
      snackbar: ({
        status,
        dispatch,
        payload,
        response,
      }) => dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? i18n`Task "${response.title}" marked ${response.completedOn ? 'complete' : 'incomplete'}.`
            : i18n`Unable to mark "${payload.title}" ${!payload.completedOn ? 'complete' : 'incomplete'}.`
        ]
      }),
      async: true,
    },
    {
      action: 'start',
      handler: markAnnotationsOutdatedWrapper(
        ({ payload, apiFetch }) => (
          apiFetch(`/tasks/${payload.id}/assignees/${payload.membership}/start/`, null, { method: 'POST' })
        )
      ),
      async: true,
      snackbar: ({
        status,
        dispatch,
        response,
      }) => dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? i18n`Task "${response.title}" has been started`
            : i18n`Unable to start task`
        ]
      }),
    },
    {
      action: 'stop',
      handler: markAnnotationsOutdatedWrapper(
        ({ payload, apiFetch }) => (
          apiFetch(`/tasks/${payload.id}/assignees/${payload.membership}/stop/`, null, { method: 'POST' })
        )
      ),
      async: true,
      snackbar: ({
        status,
        dispatch,
        response,
      }) => dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? i18n`Task "${response.title}" has been stopped`
            : i18n`Unable to stop task`
        ]
      }),
    },
  ],
  scope,
})

const { doTaskSave } = bundle

export default {
  ...bundle,
  doRecipeTaskSave: payload => async ({ dispatch, apiFetch }) => {
    const { id } = payload
    const dispatchPayload = id ?? uniqueId('phaseTasks_')
    dispatch({ type: doTaskSave.types.start, payload: dispatchPayload })
    let result
    try {
      result = id
        ? await apiFetch(`/phaseTasks/${id}/`, payload, { method: 'PUT' })
        : await apiFetch('/phaseTasks/', payload, { method: 'POST' })
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: doTaskSave.types.succeed, payload: dispatchPayload })
      createRecipeTaskSnackbar({
        dispatch,
        status: 'success',
        message: `Save succeeded for task "${payload.title}"`
      })
    } catch (error) {
      dispatch({ type: doTaskSave.types.fail, error, payload: dispatchPayload })
      createRecipeTaskSnackbar({
        dispatch,
        status: 'error',
        message: 'Unable to save recipe task'
      })
    }
    return result
  },
  doRecipeTaskDelete: payload => async ({ dispatch, apiFetch }) => {
    const { id } = payload
    try {
      await apiFetch(`/phaseTasks/${id}/`, null, { method: 'DELETE' })
      createRecipeTaskSnackbar({
        dispatch,
        status: 'success',
        message: `Delete succeeded for task "${payload.title}"`
      })
      dispatch(doEntitiesRemoved({ tasks: [id] }))
    } catch (error) {
      createRecipeTaskSnackbar({
        dispatch,
        status: 'error',
        message: 'Unable to delete recipe task'
      })
    }
  },
  doDeleteTaskSeries: payload => async ({ dispatch, store }) => {
    const parentId = payload?.id
    const phaseTasks = store.selectTasksByPhase()[payload?.phase].reduce((acc, t) => {
      if (t.parentTask === parentId) {
        return [...acc, t.id]
      }
      return acc
    }, [])
    await store.doTaskDelete(payload)
    dispatch(doEntitiesRemoved({ tasks: phaseTasks }))
  },
  doSplitTaskSeriesSave: payload => async ({ dispatch, apiFetch }) => {
    const { id } = payload
    try {
      const result = await apiFetch(`/tasks/${id}/split_series/`, payload, { method: 'PUT' })
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: 'SPLIT_SERIES_SAVED_SUCCEED', payload })
      createRecipeTaskSnackbar({
        dispatch,
        status: 'success',
        message: `Save succeeded for task "${payload.title}"`
      })
    } catch (error) {
      createRecipeTaskSnackbar({
        dispatch,
        status: 'error',
        message: 'Unable to save task series'
      })
    }
  },
  selectTaskCategories: createSelector(
    'selectNoteCategories',
    categories => Object.values(categories).reduce((acc, category) => (
      category.behavior === 'TASK' ? { ...acc, [category.id]: category } : acc
    ), EMPTY_OBJECT)
  ),
  selectRecipeTaskCategories: createSelector(
    'selectNoteCategories',
    categories => Object.values(categories).reduce((acc, category) => (
      category.behavior === 'PHASE_TASK' ? { ...acc, [category.id]: category } : acc
    ), EMPTY_OBJECT)
  ),
  selectTasksByPhase: createSelector(
    'selectPhases',
    'selectRecipes',
    'selectTasks',
    (phases = {}, recipes = {}, tasks = {}) => {
      const tasksByPhase = Object.values(phases).reduce((acc, phase) => {
        if (!phase || !phase.tasks || !phase.tasks.length) return acc
        acc[phase.id] = phase.tasks.map(task => (task in tasks ? tasks[task] : task))
        return acc
      }, {})
      Object.values(recipes).forEach(recipe => {
        if (!recipe || !recipe.phases || !recipe.phases.length) return
        if (recipe.phases.every(phase => !phase.tasks || !phase.tasks.length)) return
        recipe.phases.forEach(phase => {
          tasksByPhase[phase.id] = phase.tasks.map(task => (task in tasks ? tasks[task] : task))
        })
      })
      Object.values(tasks).forEach(task => {
        if (!task || !task.phase) return
        tasksByPhase[task.phase] ??= []
        tasksByPhase[task.phase] = [
          ...tasksByPhase[task.phase].filter(phaseTask => task.id !== phaseTask.id),
          task
        ].sort((a, b) => a.relativeStartDay - b.relativeStartDay)
      })
      return tasksByPhase
    }
  )
}
