/**
 * Given a potential GET param value, returns whether it is a valid, non-empty value
 * @param {any} param
 * @returns {boolean}
 */
export const isValidParam = param => {
  if (Boolean(param) === param) return true
  if (param == null) return false
  if (Array.isArray(param)) return Boolean(param.length)
  if (param instanceof Date) return Boolean(param.getTime())
  const isObject = typeof param === 'object'
  if (isObject && 'isValid' in param) return param.isValid
  if (isObject) return Boolean(Object.keys(param).length)
  return Boolean(param)
}
