import {
  curry,
  identity,
  omit,
  pick,
  pipe,
} from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { camelize, pluralize, underscore } from 'inflection'
import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { permitted } from '~/src/Flags/Can'
import { available } from '~/src/Flags/Has'
import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import {
  defer,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  isValidParam,
} from '~/src/Lib/Utils'

import { REACTOR_PRIORITIES } from '../Store/constants'
import { createAppIsReadySelector } from '../Store/utils'
import createLogger from './Logging'

const logger = createLogger('createListBundle')
const MARK_OUTDATED_TIMEOUT = 400

export const PAGE_HANDLING = {
  REPLACE: 'REPLACE',
  APPEND: 'APPEND',
}

export const defaultInitialState = {
  data: { results: EMPTY_ARRAY },
  search: '',
  filter: 'ALL',
  sort: null,
  page: 1,
}
export const defaultListActions = ['set_filter', 'set_page', 'set_search', 'set_sort']
export const createListActionTypes = (actionBaseType, actions) => (
  actions.map(action => `${actionBaseType}_${action.toUpperCase()}`)
)
export const createActionTypesMap = actionTypes => (
  actionTypes
    .reduce((acc, action) => {
      if (!action.includes('_SET_')) return acc
      return {
        ...acc,
        [action]: camelize(action.split('_SET_').pop().toLowerCase(), true),
      }
    }, EMPTY_OBJECT)
)

export const defaultCase = (additionalState, state) => {
  if (!Object.keys(additionalState).every(key => key in state)) {
    return { ...additionalState, ...state }
  }
  return state
}

const defaultOptions = {
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(10),
}
const defaultGetMeta = () => EMPTY_OBJECT
const defaultSearchTest = (payload = '') => payload.length >= 3 || payload.length === 0

/**
 * @callback fetchHandler
 * @param {{ apiFetch: function, dispatch: function, listState: Object, params: Object }} kwargs
 */
/**
 *
 * @param {Object} config
 * @param {string} config.entityName
 * @param {Object} config.schema
 * @param {fetchHandler} config.fetchHandler
 * @param {Object<string, ?string|string[]|number|number[]>} [config.initialState]
 * @param {string} [config.name=`${config.entityName}List`]
 * @param {string[]} [config.actions]
 * @param {string|function} [config.fetchReactionPriority=REACTOR_PRIORITIES.LOW] Priority of fetch reactions
 * @param {Object} [config.flags] Feature flag config
 * @param {string[]} [config.flags.keys] Array of flags to check
 * @param {boolean} [config.flags.any=false] Is user allowed if any flag in the keys list is found?
 * @param {boolean} [config.flat=false] Is the handler's return flat--the results are at the top level
 * @param {string} [config.pageField='current'] API page field name
 * @param {string} [config.pageHandling='REPLACE'] How to handle new page data
 * @param {Object} [config.permissions] Permissions config
 * @param {string[]} [config.permissions.keys] Array of permissions to check
 * @param {boolean} [config.permissions.any=false] Is permission granted if any permission in the keys list is found?
 * @param {boolean} [config.transformParams=identityFn] function to transform params before passing to fetchHandler
 * @param {function} [config.urlTest=Boolean] function to determine if the url is relevant to this bundle
 * @return {{ name: string, reducer: function }} - asyncResourceBundle
 */
export default config => {
  const {
    actions = defaultListActions,
    entityName,
    name = `${entityName}List`,
    initialState = defaultInitialState,
    fetchHandler,
    schema,
    authorizationSelector = 'selectCurrentMembership',
    fetchReactionPriority = REACTOR_PRIORITIES.LOW,
    flags = EMPTY_OBJECT,
    flat = false,
    pageField = 'current',
    pageHandling = PAGE_HANDLING.REPLACE,
    permissions = EMPTY_OBJECT,
    authorizationTest = Boolean,
    getEntitiesReceivedMeta = defaultGetMeta,
    searchTest = defaultSearchTest,
    transformParams = identity,
    urlTest = Boolean,
    ...options
  } = config

  const lfNameFragment = camelize(name, true)
  const rbNameFragment = camelize(name)
  const actionBaseType = underscore(name).toUpperCase()
  const actionTypes = createListActionTypes(actionBaseType, actions)
  const actionTypesMap = createActionTypesMap(actionTypes, rbNameFragment)
  const markOutdatedName = `doMark${rbNameFragment}AsOutdated`
  let timeout
  let omitKeys = EMPTY_ARRAY

  const { [markOutdatedName]: initialMarkOutdated, ...initialBundle } = createAsyncResourceBundle({
    ...defaultOptions,
    ...options,
    actionBaseType,
    name,
    getPromise: async kwargs => {
      const { dispatch, store } = kwargs
      const {
        [`${lfNameFragment}Raw`]: listState,
        [`${lfNameFragment}ApiParams`]: params
      } = store.select([`select${rbNameFragment}Raw`, `select${rbNameFragment}ApiParams`])

      const response = await fetchHandler({ ...kwargs, listState, params })
      const isFlat = Boolean(flat || Array.isArray(response))
      let results = isFlat ? response : response.results
      if (schema) {
        const normalized = normalize(results, [schema])
        const { entities } = normalized;
        ({ result: results } = normalized)
        dispatch(doEntitiesReceived(entities, getEntitiesReceivedMeta(listState)))
      }
      return isFlat ? { results } : { ...response, results }
    },
  })

  omitKeys = [...Object.keys(initialBundle.reducer(undefined, {})), 'fetchOutdated']

  const bundleLogger = createLogger('listBundle', name)

  return {
    ...initialBundle,
    getMiddleware: flat || pageHandling === PAGE_HANDLING.REPLACE
      ? undefined
      : () => curry((store, next, action) => {
        if (!action.type || action.type !== `${actionBaseType}_FETCH_FINISHED`) {
          if (actionBaseType === 'CONTROL_DAILY_JOURNALS_LIST' && action.type.startsWith(actionBaseType)) {
            bundleLogger('skipping middleware', action.type)
          }
          return next(action)
        }
        const { [pageField]: currentPage = 0, results: oldResults = [] } = store[`select${rbNameFragment}`]() ?? EMPTY_OBJECT
        const { [pageField]: fetchedPage, results: fetchedResults } = action.payload
        return next({
          ...action,
          payload: {
            ...action.payload,
            results: [...new Set(
              currentPage < fetchedPage
                ? [...oldResults, ...fetchedResults]
                : [...fetchedResults, ...oldResults]
            )]
          }
        })
      }),
    reducer: reduceReducers(initialBundle.reducer, (state, action) => {
      if (
        action.type === `${actionBaseType}_CLEARED`
        || action.type === `${actionBaseType}_PARAMS_CLEARED`
      ) {
        return { ...state, ...initialState }
      }
      if (
        (!action.type || !action.type.startsWith(actionBaseType))
        && Object.keys(initialState).some(key => state[key] == null && initialState[key] != null)
      ) {
        return {
          ...state,
          ...Object.entries(initialState).reduce((params, [key, initialValue]) => (
            state[key] == null && initialValue != null ? { ...params, [key]: initialValue } : params
          ), {})
        }
      }
      if (action.type in actionTypesMap) {
        const key = actionTypesMap[action.type]
        return {
          ...state,
          page: initialState.page,
          [key]: action.payload,
        }
      }
      if (action.type === `${actionBaseType}_PARAMS_SET`) {
        const payload = pick(Object.keys(initialState), action.payload)
        return {
          ...(action.meta?.replace ? { ...state, ...initialState } : state),
          ...payload,
          data: action.meta?.clear ? initialState.data ?? null : state.data,
          fetchOutdated: action.meta?.markOutdated !== false && state.isLoading,
          lastSuccess: action.meta?.clear ? null : state.lastSuccess
        }
      }
      if (action.type === `${actionBaseType}_FETCH_FINISHED` && state.fetchOutdated) {
        return { ...state, fetchOutdated: false, isOutdated: true }
      }
      return state
    }),
    [markOutdatedName]: () => ({ dispatch, store }) => {
      if (store[`select${rbNameFragment}IsLoading`]?.()) {
        return
      }
      if (!timeout) {
        bundleLogger.debug('mark outdated not throttled')
        dispatch(initialMarkOutdated())
        return
      }
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        timeout = null
        const now = Date.now()
        const lastError = store[`select${rbNameFragment}LastError`]?.() ?? 0
        const lastSuccess = store[`select${rbNameFragment}LastSuccess`]?.() ?? 0
        // If we haven't fetched in more than the debouncing interval, mark outdated
        if (lastSuccess <= (now - MARK_OUTDATED_TIMEOUT) && !lastError) {
          dispatch(initialMarkOutdated())
        }
      }, MARK_OUTDATED_TIMEOUT)
    },
    [`do${rbNameFragment}Clear`]: () => ({ dispatch }) => {
      dispatch({ type: `${actionBaseType}_CLEARED` })
      dispatch({ actionCreator: markOutdatedName, args: EMPTY_ARRAY })
    },
    [`do${rbNameFragment}ClearParams`]: () => ({ dispatch }) => {
      dispatch({ type: `${actionBaseType}_PARAMS_CLEARED` })
      dispatch({ actionCreator: markOutdatedName, args: EMPTY_ARRAY })
    },
    [`do${rbNameFragment}SetParams`]: (payload, meta = EMPTY_OBJECT) => ({ dispatch, store }) => {
      const prevParams = store[`select${rbNameFragment}Params`]()

      dispatch({ type: `${actionBaseType}_PARAMS_SET`, payload, meta })

      if (meta.markOutdated === false) {
        return
      }
      if (!prevParams.search && payload.search && payload.search.length < 2) {
        bundleLogger.debug('debouncing search')
        timeout = setTimeout(() => {
          timeout = null
        }, MARK_OUTDATED_TIMEOUT)
        return
      }
      dispatch({ actionCreator: markOutdatedName, args: EMPTY_ARRAY })
    },
    ...Object.entries(actionTypesMap).reduce((acc, [type, key]) => ({
      ...acc,
      [`do${rbNameFragment}Set${camelize(key)}`]: type.endsWith('SET_SEARCH')
        ? payload => ({ dispatch, store }) => {
          dispatch({ type, payload })
          if (!searchTest(payload)) {
            return
          }
          defer(() => {
            if (store[`select${rbNameFragment}IsLoading`]()) return
            dispatch({ actionCreator: `doMark${rbNameFragment}AsOutdated`, args: EMPTY_ARRAY })
          })
        }
        : payload => ({ dispatch, store }) => {
          dispatch({ type, payload })
          defer(() => {
            if (store[`select${rbNameFragment}IsLoading`]()) return
            dispatch({ actionCreator: `doMark${rbNameFragment}AsOutdated`, args: EMPTY_ARRAY })
          })
        },
    }), EMPTY_OBJECT),
    [`react${rbNameFragment}Fetch`]: createAppIsReadySelector({
      dependencies: [
        authorizationSelector,
        `select${rbNameFragment}ShouldUpdate`,
        'selectUrlObject',
        'selectRouteInfo',
      ],
      resultFn: (authorizationData, shouldUpdate, urlObject, routeInfo) => {
        const { href, origin } = urlObject
        const url = href.replace(origin, '')
        const routePattern = routeInfo && routeInfo.pattern
        const authorized = authorizationTest(authorizationData)
        const urlAllowed = urlTest(url, routePattern)
        if (authorized && shouldUpdate && urlAllowed) {
          bundleLogger.debug('authorized and should update, fetching')
          return {
            actionCreator: `doFetch${rbNameFragment}`,
            priority: typeof fetchReactionPriority !== 'function'
              ? fetchReactionPriority
              : fetchReactionPriority(url, routePattern),
          }
        }
        if (!authorized) bundleLogger.debug('Not authorized!', authorizationData)
        else if (!urlAllowed) bundleLogger.debug('URL not allowed!', url)
        return undefined
      }
    }),
    [`selectCurrent${rbNameFragment}`]: createSelector(
      `select${pluralize(camelize(entityName))}`,
      `select${rbNameFragment}Raw`,
      (entities, listRoot) => {
        const { data } = listRoot ?? EMPTY_OBJECT
        if (!data || !data.results?.length) return EMPTY_ARRAY
        return data.results.map(id => entities[id]).filter(Boolean)
      }
    ),
    [`select${rbNameFragment}ShouldUpdate`]: createSelector(
      'selectIsOnline',
      `select${rbNameFragment}IsLoading`,
      `select${rbNameFragment}FailedPermanently`,
      `select${rbNameFragment}IsWaitingToRetry`,
      `select${rbNameFragment}Raw`,
      `select${rbNameFragment}IsStale`,
      'selectChangingMembership',
      'selectAvailableFeatures',
      'selectPermittedActions',
      (
        isOnline,
        isLoading,
        failedPermanently,
        isWaitingToRetry,
        { data, lastSuccess },
        isStale,
        changingMembership,
        availableFeatures,
        permittedActions,
      ) => {
        if (!isOnline || isLoading || failedPermanently || isWaitingToRetry || changingMembership) {
          return false
        }
        if (flags !== EMPTY_OBJECT) {
          const { any, keys } = flags
          if (!available(availableFeatures, keys, any)) {
            bundleLogger.debug('failed shouldUpdate flag check:', {
              any,
              flags: keys,
              availableFeatures
            })
            return false
          }
        }
        if (permissions !== EMPTY_OBJECT) {
          const { any, keys } = permissions
          const can = permitted(permittedActions, keys, any)

          if (!can) {
            bundleLogger.debug('failed shouldUpdate permission check:', {
              any,
              permissions: keys,
              permittedActions
            })
            return false
          }
        }
        if ((data == null || data === initialState.data) && !lastSuccess) {
          return true
        }
        return isStale
      }
    ),
    [`select${rbNameFragment}Params`]: createSelector(
      `select${rbNameFragment}Raw`,
      omit(omitKeys)
    ),
    [`select${rbNameFragment}ApiParams`]: createSelector(
      `select${rbNameFragment}Params`,
      pipe(
        params => Object.entries(params).reduce((acc, [key, value]) => {
          if (Array.isArray(value) && value.length === 2) {
            const [field, direction] = value
            if (direction === 'asc' || direction === 'desc') {
              return { ...acc, [key]: (direction === 'asc' ? field : `-${field}`) }
            }
          }
          return isValidParam(value) ? { ...acc, [key]: value } : acc
        }, EMPTY_OBJECT),
        transformParams
      )
    ),
  }
}
