import { useEffect } from 'react'

import classNames from 'clsx'
import { useConnect } from 'redux-bundler-hook'

import { makeStyles } from '@mui/styles'

import createLogger from '~/src/Lib/Logging'
import { lazyWithRetries, useIsLoaded } from '~/src/Lib/Utils'
import Head from '~/src/UI/Shared/Head'

import config from './config'
import styles from './styles'

const SpinnerRoute = lazyWithRetries(() => import('~/src/Routes/SpinnerRoute'))
const displayName = 'App/Public'
const logger = createLogger(displayName)
const useStyles = makeStyles(styles, { name: 'App' })

const PublicWrapper = () => {
  const { appIsReady, landingPage, locale, routeInfo, doUpdateUrl } = useConnect(
    'selectAppIsReady',
    'selectLandingPage',
    'selectLocale',
    'selectRouteInfo',
    'doUpdateUrl'
  )
  const loaded = useIsLoaded(!appIsReady)
  const classes = useStyles()
  const { url, value: route } = routeInfo
  const { additionalProps, title, component, publicAccess = false } = route
  const Route = publicAccess ? (component ?? route) : SpinnerRoute

  useEffect(() => {
    if (!loaded) {
      return
    }
    if (url === '/') {
      doUpdateUrl(landingPage)
    }
  }, [doUpdateUrl, landingPage, loaded, url])

  return (
    <>
      <Head>
        <html className={config.APP.toLowerCase()} lang={locale} />
        {title ? <title>{title}</title> : null}
        <style>
          {'html: { overflow: hidden; }'}
        </style>
      </Head>
      <section
        data-testid="aroya-public"
        id="wrapper"
        className={classNames(classes.root, classes.fullscreen)}
      >
        <main id="content" className={classes.content}>
          <Route {...additionalProps} />
        </main>
      </section>
    </>
  )
}
PublicWrapper.displayName = displayName

export default PublicWrapper
