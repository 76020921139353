import PropTypes from 'prop-types'
import {
  always,
  compose,
  evolve,
  map,
  pick,
  trim,
} from 'ramda'

import { HARVEST_ROOM_TYPES } from '~/src/Harvest/constants'
import { toISODateOrNil, validIdOrNil, validIdOrUndefined } from '~/src/Lib/Data'
import { atLeastOneRequired, datePropType, idPropType } from '~/src/Lib/PropTypes'

const sharedConfig = {
  id: idPropType,
  name: PropTypes.string.isRequired,
  startDate: datePropType,
  harvestDate: datePropType,
  cultivars: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.objectOf(PropTypes.object),
  ]),
  archived: PropTypes.bool,
  flowerReusePreviousZones: PropTypes.bool,
  vegReusePreviousZones: PropTypes.bool,
  metrcSync: PropTypes.bool,
  metrcBatchGroups: PropTypes.arrayOf(PropTypes.number),
  // foreign keys
  currentPhase: idPropType.nullable,
  sourceRecipe: idPropType.nullable,
}

const roomPropTypes = HARVEST_ROOM_TYPES.reduce((types, prefix) => ({
  ...types,
  [`${prefix}Room`]: idPropType.nullable,
}), {})

export const shapeConfig = {
  ...sharedConfig,
  // foreign keys
  ...atLeastOneRequired(roomPropTypes),
}

export const newShapeConfig = {
  ...sharedConfig,
  ...roomPropTypes,
}
export const shape = PropTypes.shape(shapeConfig)

export const phaseShape = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
  totalDays: PropTypes.number,
  phaseType: PropTypes.oneOf(['PROP', 'VEG', 'FLOWER', 'DRY']),
  sequence: PropTypes.number,
})

const transforms = {
  id: validIdOrUndefined,
  name: trim,
  startDate: toISODateOrNil,
  harvestDate: toISODateOrNil,
  vegetativeDate: toISODateOrNil,
  floweringDate: toISODateOrNil,
  archived: Boolean,
  flowerReusePreviousZones: Boolean,
  vegReusePreviousZones: Boolean,
  ...map(always(validIdOrNil), roomPropTypes),
  currentPhase: validIdOrNil,
  sourceRecipe: validIdOrNil,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick([...Object.keys(shapeConfig), 'vegetativeDate', 'floweringDate', 'finishedPlantBatches'])
)

export const prepareData = ({ recipe, ts, date, startDate = ts || date, ...raw }) => {
  const cleanData = cleaner({ startDate, ...raw })

  return recipe?.id ? {
    ...cleanData,
    sourceRecipe: cleanData.sourceRecipe ?? recipe?.id,
    recipeId: recipe?.id
  } : cleanData
}
