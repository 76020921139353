import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import { substratePattern } from '~/src/DataType/constants'
import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Logger } from '~/src/Store/Schemas'

const initialState = {
  ...defaultInitialState,
  room: '',
}

const initialBundle = createListBundle({
  entityName: 'logger',
  actions: EMPTY_ARRAY,
  initialState,
  schema: Logger,
  staleAfter: ms.hours(12),
  retryAfter: ms.minutes(5),
  permissions: { keys: ['view_device'] },
  fetchHandler: ({ apiFetch, params }) => apiFetch('/loggers/', {
    room: params.room,
    search: params.search,
    page: params.page,
  }),
  flags: { keys: ['OUTDOOR'] },
})

export default {
  ...initialBundle,
  selectSoilModels: createSelector(
    'selectDataTypes',
    'selectAvailableModels',
    (dataTypes, availableModels) => Object.values(availableModels).reduce((acc, model) => {
      if (model.dataTypes.some(d => substratePattern.test(dataTypes[d]?.category))) {
        return {
          ...acc,
          [model.id]: model,
        }
      }
      return acc
    }, EMPTY_OBJECT)
  ),
}
