import { SvgIcon } from '@mui/material'

export const path = `
  M17 12.614l2-2.37V17a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h9c.275 0
  .541.037.794.106L15.196 7H7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0
  1-1v-4.386zm-5.286.836l8.522-10.095a1 1 0 1 1 1.528 1.29l-9.286 11a1 1 0 0
  1-1.528 0l-3.714-4.4a1 1 0 0 1 1.528-1.29l2.95 3.495z
`
export default props => (
  <SvgIcon {...props}>
    <path d={path} />
  </SvgIcon>
)
