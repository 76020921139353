import { always, complement, pick } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import createLogger from '~/src/Lib/Logging'

import { permitted } from '../Can'
import { configured } from '../Configured'
import { available } from '../Has'

const logger = createLogger('Flags/useAllowed')

const defaultValueGetter = always(true)

/**
 * Params that only allowed needs
 * @typedef {Object} AllowedParams
 * @property {Set} availableFeatures - Set of available features
 * @property {Set} permittedActions - Set of permitted actions
 * @property {Object} config - Current dotenv configuration
 */

/**
 * Allowed params
 * @typedef {Object} UseAllowedParams
 * @property {string|string[]} [actions] - Actions that the user needs to be permitted
 * @property {boolean} [anyAction=false] - If more than one action, allow if user is permitted any one of the actions
 * @property {string|string[]} [features] - Features that the user needs to be available
 * @property {boolean} [anyFeature=false] - If more than one feature, allow if user has at least one.
 * @property {string|string[]} [apps] - Allowed if current selectConfig.APP is present in apps
 * @property {string|string[]} [negate] - Negate the result of the check for one of actions, features, apps, and tiers
 * @property {string} [setting] - A configuration setting to check for the existence of
 * @property {function(Object<string, string|number>): boolean} [settingTest] - A function to test configuration settings
 * @property {string|string[]} [tiers] - A tier list that will be checked against the currentFacility.tier
*/

/**
 *
 * @param {AllowedParams & UseAllowedParams} params
 * @returns {[boolean, boolean]} [allowed, actionsNotAllowed]
 */
export const allowed = ({
  actions = '',
  anyAction = false,
  features = '',
  anyFeature = false,
  apps = '',
  negate = '',
  setting = null,
  settingTest = null,
  tiers = '',
  // connected props
  availableFeatures,
  permittedActions,
  config,
}) => {
  let haveFeatures = defaultValueGetter
  if (features.length) {
    logger.debug('gating by available features:', { features, anyFeature, availableFeatures })
    haveFeatures = () => available(availableFeatures, features, anyFeature)
  }
  let actionsAllowed = defaultValueGetter
  if (actions.length) {
    logger.debug('gating by permitted actions:', { actions, anyAction, permittedActions })
    actionsAllowed = () => permitted(permittedActions, actions, anyAction)
  }
  let settingConfigured = defaultValueGetter
  if (setting || settingTest) {
    settingConfigured = () => configured({ config, setting, test: settingTest })
  }
  let appMatch = defaultValueGetter
  if (apps?.length) {
    logger.debug('gating by app:', { apps, APP: config.APP })
    appMatch = () => (Array.isArray(apps) ? apps.includes(config.APP) : apps === config.APP)
  }
  let tierMatch = defaultValueGetter
  if (tiers?.length) {
    logger.debug('gating by tier:', { tiers, TIER: config.TIER })
    tierMatch = () => (Array.isArray(tiers) ? tiers.includes(config.TIER) : tiers === config.TIER)
  }
  if (negate.length) {
    logger.debug('negating:', negate)
    if (negate.includes('actions')) {
      actionsAllowed = complement(actionsAllowed)
    }
    if (negate.includes('features')) {
      haveFeatures = complement(haveFeatures)
    }
    if (negate.includes('apps')) {
      appMatch = complement(appMatch)
    }
    if (negate.includes('tiers')) {
      tierMatch = complement(tierMatch)
    }
  }
  return [
    haveFeatures()
      && actionsAllowed()
      && settingConfigured()
      && appMatch()
      && tierMatch(),
    !actionsAllowed
  ]
}

/**
 * Check for allowed actions, features, and apps simultaneously
 * @param {Object} kwargs
 */
export const useAllowed = kwargs => {
  const connectedProps = useConnect('selectConfig', 'selectAvailableFeatures', 'selectPermittedActions')
  const result = allowed({ ...kwargs, ...connectedProps })
  if (!Array.isArray(result)) {
    console.error('[useAllowed] allowed result is not an array', { result, ...kwargs })
    return [false, false]
  }
  return result
}

export const allowedProps = [
  // guards based on user permitted actions
  'actions',
  'anyAction',
  // guards based on defined app for the current session
  'apps',
  // guards based on user available features
  'features',
  'anyFeature',
  // if present must be one of: actions, features, apps, setting, settingTest, tiers
  'negate',
  // guards based on dotenv settings
  'setting', // checks if setting is truthy in dotenv settings
  'settingTest', // checks if dotenv config passes a test function
  // guards based on facility tier
  'tiers',
  // fallback rendering
  'Fallback',
  'FallbackProps',
  'PermissionFallback',
]

export const pickAllowedProps = pick(allowedProps)
