import { capitalize } from 'inflection'

import * as flowConstants from '~/src/Flow/constants'

export const MAX_PLANT_BATCH_SIZE = 100

export const GROWTH_PHASES = Object.freeze(['YOUNG', 'VEG', 'FLOWER'])
export const PHASE_TYPES = Object.freeze(['PROP', 'VEG', 'FLOWER', 'DRY'])
export const CULTIVATION_PHASE_TYPES = Object.freeze(PHASE_TYPES.slice(0, -1))
export const PLANT_TYPES = Object.freeze(['CLONE', 'SEED'])
export const HARVEST_ROOM_TYPES = Object.freeze(PHASE_TYPES.map(name => name.toLowerCase()))
export const CULTIVATION_ROOM_TYPES = Object.freeze(HARVEST_ROOM_TYPES.slice(0, -1))
export const HARVEST_FLOWS = Object.entries(flowConstants).reduce((acc, [key, value]) => {
  if (!key.endsWith('_ROUTE') && `HARVEST_BASED_${key}_ROUTE` in flowConstants) acc.push(value)
  return acc
}, [])

const selectOptions = PHASE_TYPES.map(
  (phaseType, index) => ({
    label: `${capitalize(phaseType.toLowerCase())} phase`,
    type: phaseType,
    value: index + 1,
  })
)
selectOptions.unshift({ label: 'No phase', type: 'NONE', value: selectOptions.length + 1 })
selectOptions.unshift({ label: 'All phases', type: null, value: selectOptions.length + 1 })
export const PHASE_TYPES_SELECT_OPTIONS = Object.freeze(selectOptions)

const defaultSource = { default: 'cultivars', metrc: 'mothers' }
const immature = { ...defaultSource, metrc: 'mothers' }
export const SOURCES_BY_FIRST_ROOM = {
  prop: immature,
  veg: immature,
  flower: { ...defaultSource, metrc: 'plantBatches' },
}

export const OUTPUT_BY_SOURCE = {
  prop: 'plantBatches',
  veg: 'plantBatches',
  flower: 'plants',
  // Old mapping
  mothers: 'plantBatches',
  cultivars: 'cultivars',
  plantBatches: 'plants',
  plants: 'plants',
}
