import { Box, CircularProgress } from '@mui/material'

const spinnerContainerStyles = {
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const fallbackContent = <Box sx={spinnerContainerStyles}><CircularProgress size={100} /></Box>
