import createListBundle from '~/src/Lib/createListBundle'
import { TargetRange as schema } from '~/src/Store/Schemas'
import { schemaName as name } from './constants'

const initialState = {
  room: null,
  start: null,
  end: null
}

export default createListBundle({
  entityName: name,
  schema,
  initialState,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/targetRanges/', params)
})
