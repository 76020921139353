import { SvgIcon } from '@mui/material'

export const path = `
  M4.25,19.74 C4.64,20.13 5.27,20.13 5.66,19.74 L6.05,19.35 C6.44,18.96 6.43,18.33
  6.05,17.95 L6.04,17.94 C5.85316847,17.7527473 5.59951764,17.6475144 5.335,17.6475144
  C5.07048236,17.6475144 4.81683153,17.7527473 4.63,17.94 L4.24,18.33 C3.86,18.73
  3.86,19.35 4.25,19.74 Z M11.99,22.95 L12,22.95 C12.55,22.95 12.99,22.51 12.99,21.96
  L12.99,21 C12.99,20.45 12.55,20.01 12,20.01 L11.99,20.01 C11.44,20.01 11,20.45 11,21
  L11,21.96 C11,22.51 11.44,22.95 11.99,22.95 Z M3.01,11 L1.99,11 C1.44,11 1,11.44
  1,11.99 L1,12 C1,12.55 1.44,12.99 1.99,12.99 L3,12.99 C3.55,12.99 3.99,12.55 3.99,12
  L3.99,11.99 C4,11.44 3.56,11 3.01,11 Z M15,6.81 L15,3 C15,2.45 14.55,2 14,2 L10,2
  C9.45,2 9,2.45 9,3 L9,6.81 C6.96,7.99 5.68,10.33 6.07,12.94 C6.47,15.55 8.63,17.64
  11.25,17.96 C14.89,18.4 18,15.56 18,12.01 C18,9.78 16.79,7.85 15,6.81 Z M20,11.99
  L20,12 C20,12.55 20.44,12.99 20.99,12.99 L22,12.99 C22.55,12.99 22.99,12.55 22.99,12
  L22.99,11.99 C22.99,11.44 22.55,11 22,11 L20.99,11 C20.44,11 20,11.44 20,11.99 Z
  M17.94,19.36 L18.33,19.75 C18.72,20.14 19.35,20.14 19.74,19.75 C20.13,19.36 20.13,18.73
  19.74,18.34 L19.35,17.95 C18.96,17.56 18.33,17.57 17.95,17.95 C17.55,18.35 17.55,18.97
  17.94,19.36 Z
`

const LightsOn = props => (
  <SvgIcon {...props}>
    <path d={path} />
  </SvgIcon>
)

export default LightsOn
