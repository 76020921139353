import memoizeOne from 'memoize-one'
import { groupBy, pipe, reduce } from 'ramda'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT, formattedNumber } from '~/src/Lib/Utils'
import { COMPASS_POINTS } from '~/src/Weather/constants'

const logger = createLogger('Weather#utils')

export const degreesToCompass = degrees => {
  const index = Math.round((degrees % 360) / 45)
  return COMPASS_POINTS[index]
}

export const buildWindGraphs = memoizeOne(pipe(
  groupBy(({ id }) => {
    const [, type, locId] = id.split(':')
    return ['wind', type, locId].join(':')
  }),
  Object.entries,
  reduce((windGraphs, [, graphList]) => {
    const speed = graphList.find(g => g.id.startsWith('wind_speed'))
    const { bounds, dataType, lineColor, title, unit, zone } = speed

    return [...windGraphs, {
      id: speed.id,
      balloonTemplate: datum => [
        speed.template.replace('{{ value }}', formattedNumber(datum.value ?? datum.datum?.y ?? datum.y, 2)),
        datum.direction ?? (datum.datum && datum.datum.direction),
        // datum.gust
      ].filter(Boolean).join(' '),
      bounds,
      dataType,
      depth: null,
      graphList,
      lineColor,
      name: 'Wind',
      template: '',
      title,
      unit,
      zone,
    }]
  }, EMPTY_ARRAY)
))

export const buildWindData = memoizeOne((data, graphs) => graphs.reduce((windData, graph) => {
  const { graphList } = graph
  const speedGraph = graphList.find(g => g.id.startsWith('wind_speed'))
  const directionGraph = graphList.find(g => g.id.startsWith('wind_direction'))
  // const gustGraph = graphList.find(g => g.id.startsWith('wind_gust'))
  const {
    [speedGraph.id]: speedData,
    [directionGraph.id]: directionData,
    // [gustGraph.id]: gustData,
  } = data

  return {
    ...windData,
    [graph.id]: speedData.map((datum, index) => {
      const { [index]: directionDatum } = directionData
      const { y: degrees } = directionDatum
      const direction = degreesToCompass(degrees)
      // if (gustDatum.y > datum.y) {
      //   additional.gust = gustDatum.y
      //   lastDirection = additional.gust
      // }
      return {
        ...datum,
        degrees,
        direction,
      }
    })
  }
}, EMPTY_OBJECT))

export const detectDirectionChanges = memoizeOne(windData => {
  let lastDirection
  let lastDirectionDegrees = 0
  let lastDirectionIndex

  return windData.map((datum, index) => {
    const { degrees, direction } = datum
    const compassChange = direction !== lastDirection
    const degreeDiff = Math.abs(lastDirectionDegrees - degrees)
    const maxGap = (index - lastDirectionIndex) >= 8
    const directionChanged = (compassChange && (degreeDiff > (360 / 16)))

    if (directionChanged) {
      lastDirection = direction
      lastDirectionDegrees = degrees || 0
      lastDirectionIndex = index
    }

    return {
      ...datum,
      directionChanged,
      maxGap,
    }
  })
})
