import { createSelector } from 'redux-bundler'

import createListBundle from '~/src/Lib/createListBundle'
import { Item as schema } from '~/src/Store/Schemas'

export const initialState = {
  search: '',
  ordering: ['name', 'asc'],
}

const { selectItemListShouldUpdate, ...initialBundle } = createListBundle({
  entityName: 'item',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/items/', { ...params, pagination: 0 }),
  flags: { keys: ['METRC'] }
})

export default {
  ...initialBundle,
  selectItemListShouldNormallyUpdate: selectItemListShouldUpdate,
  selectItemListShouldUpdate: createSelector(
    'selectItemListShouldNormallyUpdate',
    'selectItemListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastItemRefresh && lastSuccess < new Date(metrcFacility.lastItemRefresh)
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
