import { omit } from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Phase as schema } from '~/src/Store/Schemas'

import { getLegacyBundle } from './phasesOld'

const name = 'phases'

const entityBundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData: data => omit(['startDate', 'endDate'], data),
    schema,
    url: 'harvestPhases',
  },
})

export default {
  ...entityBundle,
  ...getLegacyBundle(entityBundle),  // TODO: The "old way" of saving harvest/recipe schedules (remove once unused),
  selectRecipePhases: createSelector(
    'selectRecipes',
    recipes => Object.values(recipes).reduce((allphases, recipe) => {
      if (!recipe || !Array.isArray(recipe.phases) || !recipe.phases.length) return allphases
      return {
        ...allphases,
        ...recipe.phases.reduce((response, phase) => ({ ...response, [phase.id]: phase }), EMPTY_OBJECT)
      }
    }, EMPTY_OBJECT)
  )
}
