import memoizeOne from 'memoize-one'
import {
  groupBy,
  join,
  paths,
  pipe,
} from 'ramda'

import { DRYING_FLOW, PROCESSING_FLOW } from '~/src/Flow/constants'
import { shallowEquals } from '~/src/Lib/Utils'

export const sortSourcesByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
}

export const groupItemsByCategory = items => groupBy(item => item?.category, items)

export const getSortedItemCategories = (itemCategories, categoryKeysToRemain) => Object.values(itemCategories)
  .filter(itemCategory => categoryKeysToRemain.find(categoryKey => categoryKey === itemCategory.key))
  .sort((a, b) => a.sequence - b.sequence)

export const getItemsWithColors = (items, itemCategories, categoryKeysToRemain, concreteItemsToRemain = [], units = {}) => Object.values(items)
  .filter(item => units[item.unit]?.category?.name === 'Weight' && item.metrcCategory) // TODO: this filtering is temporary. We need to filter non-metrc items and those who support other units than lb.
  .filter(item => categoryKeysToRemain.find(categoryKey => categoryKey === item.category) || concreteItemsToRemain.find(itemName => itemName === item.name))
  .map(item => ({ ...item, color: itemCategories[item.category].color }))

const packageRoomTypes = new Set(['DRY', 'CURE', 'STORAGE'])
const packageRoomTypeFilter = memoizeOne(value => room => (room.id === value || room.forPackages || packageRoomTypes.has(room.roomType)))
export const roomFilteringMap = {
  WET: memoizeOne(value => room => (room.id === value || room.forHarvests || room.roomType === 'DRY')),
  DRIED: packageRoomTypeFilter,
  PROCESSED: packageRoomTypeFilter,
}

export const getHarvestingFlowItemsToShow = itemsByCategory => {
  const items = itemsByCategory?.WET || []
  const additionalItem = itemsByCategory?.PROCESSED?.find(item => item.key === 'FRESH_FROZEN')
  if (additionalItem) {
    return [...items, additionalItem]
  }

  return items
}

export const getProcessingFlowItemsToShow = memoizeOne((itemsByCategory, flow) => {
  let items = []

  if (flow === DRYING_FLOW) {
    items = itemsByCategory.DRIED || []
  }

  if (flow === PROCESSING_FLOW) {
    items = itemsByCategory.PROCESSED || []
  }
  return items.filter(item => !item.hidden)
}, (left, right) => shallowEquals(left, right, 3))

export const getLicenseItemIdentifier = pipe(paths([['license'], ['item', 'id']]), join('-'))
