import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import createEntityBundle, { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import createListBundle from '~/src/Lib/createListBundle'
import { getDateTime } from '~/src/Lib/Utils'
import {
  facilityScope as scope,
  IrrigationScheduleChangeNotification as schema,
} from '~/src/Store/Schemas'

const initialEntityBundle = createEntityBundle({
  name: 'irrigationScheduleChangeNotifications',
  apiConfig: { schema },
  scope,
})

const {
  doIrrigationScheduleChangeNotificationUpdate: { types: updateTypes },
} = initialEntityBundle

export const entityBundle = {
  ...initialEntityBundle,
  selectCurrentIrrigationScheduleChangeNotification: createSelector(
    'selectIrrigationScheduleChangeNotificationList',
    ({ results: changeNotificationList }) => changeNotificationList.filter(cn => !cn.acknowledgementOn)[0],
  ),
  doViewIrrigationScheduleChangeNotification: entity => async ({ apiFetch, dispatch, store }) => {
    if (entity && !entity.initialViewingOn) {
      try {
        dispatch({ type: updateTypes.start, payload: 'view' })
        const result = await apiFetch(`/irrigationScheduleChangeNotifications/${entity.id}/`, {
          ...entity,
          initialViewingOn: getDateTime('now').toUTC().toISO(),
          initialViewingBy: store.selectMe().id,
        }, { method: 'PUT' })
        dispatch({ type: updateTypes.succeed, payload: result })
        if ('id' in result) {
          const { entities } = normalize(result, schema)
          dispatch(doEntitiesReceived(entities))
        }
      } catch (error) {
        dispatch({ type: updateTypes.fail, payload: error })
      }
    }
  },
  doAcknowledgeIrrigationScheduleChangeNotification: entity => async ({ apiFetch, dispatch, store }) => {
    if (entity && !entity.acknowledgementOn) {
      try {
        dispatch({ type: updateTypes.start, payload: 'acknowledge' })
        const result = await apiFetch(`/irrigationScheduleChangeNotifications/${entity.id}/`, {
          ...entity,
          acknowledgementOn: getDateTime('now').toUTC().toISO(),
          acknowledgementBy: store.selectMe().id,
        }, { method: 'PUT' })
        dispatch({ type: updateTypes.succeed, payload: result })
        if ('id' in result) {
          const { entities } = normalize(result, schema)
          dispatch(doEntitiesReceived(entities))
        }
      } catch (error) {
        dispatch({ type: updateTypes.fail, payload: error })
      }
    }
  },
}

export const listBundle = createListBundle({
  entityName: 'irrigationScheduleChangeNotification',
  fetchHandler: ({ apiFetch }) => apiFetch('/irrigationScheduleChangeNotifications/'),
  permissions: { keys: ['change_irrigation'] },
  schema,
  staleAfter: ms.hours(3),
  retryAfter: ms.minutes(1),
})
