import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import createListBundle, { defaultListActions } from '~/src/Lib/createListBundle'
import { EMPTY_OBJECT, getDateTime, parseApiErrors } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { IrrigationController as schema } from '~/src/Store/Schemas'

const initialState = {
  ports: null,
  sort: ['name', 'asc'],
  search: '',
  rooms: null,
  provisioningStatus: '',
}

const queryKeyMap = {
  filter: 'currentFilter',
  sort: 'ordering',
}

const queryValueMap = {
  end: end => end && getDateTime(end).toUTC().toISO(),
  start: start => start && getDateTime(start).toUTC().toISO(),
}

const stateKeys = [
  'filter',
  'search',
  'sort',
  'rooms',
  'provisioningStatus',
]

const queryBuilder = state => stateKeys.reduce((acc, key) => (
  state[key] ? {
    ...acc,
    [queryKeyMap[key] ?? key]: (
      key in queryValueMap
        ? queryValueMap[key](state[key])
        : state[key]
    )
  } : acc), EMPTY_OBJECT)

const initialBundle = createListBundle({
  entityName: 'irrigationController',
  actions: [...defaultListActions],
  initialState,
  permissions: { keys: ['view_irrigation'] },
  schema,
  retryAfter: ms.seconds(5),
  staleAfter: ms.minutes(60),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/irrigationControllers/', {
    ...queryBuilder(params),
    pagination: 0,
  }),
  fetchReactionPriority: REACTOR_PRIORITIES.HIGH,
})

export default {
  ...initialBundle,
  doIrrigationControllersFetch: payload => async ({ apiFetch, dispatch }) => {
    let result
    try {
      result = await apiFetch('/irrigationControllers/', payload, { method: 'GET' })
    } catch (err) {
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Failed to check related controllers statuses: ${parseApiErrors(err)}`, 'error'],
      })
    }

    return result.results
  },
  selectIrrigationModels: createSelector(
    'selectModels',
    models => Object.fromEntries(
      Object.entries(models).filter(([, modelObj]) => modelObj.irrigation && modelObj.visible)
    ),
  ),
  selectIrrigationModelsById: createSelector(
    'selectModels',
    models => Object.values(models).filter(m => m.irrigation).reduce((irrigationModels, model) => ({
      ...irrigationModels,
      [model.id]: model,
    }), EMPTY_OBJECT),
  ),
}
