const ACTIONS = {
  IRRIGATION_SCHEDULE_VOLUME_FETCH_STARTED: 'IRRIGATION_SCHEDULE_VOLUME_FETCH_STARTED',
  IRRIGATION_SCHEDULE_VOLUME_FETCH_FINISHED: 'IRRIGATION_SCHEDULE_VOLUME_FETCH_FINISHED',
  IRRIGATION_SCHEDULE_VOLUME_FETCH_FAILED: 'IRRIGATION_SCHEDULE_VOLUME_FETCH_FAILED'
}

const irrigationScheduleVolumeBundle = {
  name: 'irrigationSchedulesVolume',
  reducer: (state = {}, action = {}) => {
    switch (action.type) {
      case ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_STARTED:
        return { ...state }
      case ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_FINISHED:
        return { ...state, ...action.payload }
      case ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_FAILED:
        return { ...state }
      default:
        return state
    }
  },
  doFetchIrrigationScheduleVolume: payload => async ({ apiFetch, dispatch }) => {
    dispatch({ type: ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_STARTED })
    return apiFetch(`/irrigationSchedules/${payload}/volume/`).then(response => {
      dispatch({ type: ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_FINISHED, payload: { [payload]: response } })
    }).catch(error => {
      dispatch({ type: ACTIONS.IRRIGATION_SCHEDULE_VOLUME_FETCH_FAILED, error })
    })
  },
  selectIrrigationSchedulesVolume: ({ irrigationSchedulesVolume }) => irrigationSchedulesVolume
}

export default irrigationScheduleVolumeBundle
