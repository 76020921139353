import {
  startTransition,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ReduxBundlerContext } from 'redux-bundler-hook'

function cookArguments(store, args) {
  const keysToWatch = []
  const actions = {}

  args.forEach(keyName => {
    if (keyName.startsWith('select')) {
      keysToWatch.push(keyName)
      return
    }

    if (keyName.startsWith('do')) {
      actions[keyName] = (...params) => {
        if (store.action) {
          return store.action(keyName, params)
        }
        return store[keyName](...params)
      }
      return
    }

    throw Error(`Cannot Connect: ${keyName}`)
  })

  return [keysToWatch, actions]
}

/**
 * Works like redux-bundler-hook's `useConnect` but allows for concurrent mode.
 * @param  {...string} selectorsAndActions names of selectors and action creators to connect
 * @returns {Object}
 */
export default function useConcurrentConnect(...selectorsAndActions) {
  const { store } = useContext(ReduxBundlerContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [keysToWatch, actions] = useMemo(() => cookArguments(store, selectorsAndActions), selectorsAndActions)

  const [state, setState] = useState(() => store.select(keysToWatch))

  const prevKeysToWatchRef = useRef(keysToWatch)

  useEffect(() => {
    if (prevKeysToWatchRef.current !== keysToWatch) {
      prevKeysToWatchRef.current = keysToWatch
      startTransition(() => setState(store.select(keysToWatch)))
    }

    return store.subscribeToSelectors(keysToWatch, changes => {
      startTransition(() => setState(currentState => ({ ...currentState, ...changes })))
    })
  }, [keysToWatch, store])

  if (prevKeysToWatchRef.current !== keysToWatch) {
    return {
      ...actions,
      ...store.select(keysToWatch),
    }
  }

  return {
    ...actions,
    ...state,
  }
}
