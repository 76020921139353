import {
  always,
  either,
  pipe,
  prop,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'

/* istanbul ignore next */
const logger = createLogger('Can/utils')

const emptyPermitted = new Set()

export const permitted = (permittedActions = emptyPermitted, actions = EMPTY_ARRAY, any = false) => {
  const requiredPermissions = Array.isArray(actions) ? actions : Array.of(actions)
  logger.debug({ effectivePermissions: permittedActions, requiredPermissions, any })
  return requiredPermissions[any ? 'some' : 'every'](action => (
    typeof action === 'function'
      ? action(permittedActions)
      : permittedActions.has(action)
  ))
}

export const usePermissions = pipe(
  () => useConnect('selectPermittedActions'),
  either(prop('permittedActions'), always(emptyPermitted))
)

export const useCan = (actions, any) => {
  const permittedActions = usePermissions()
  if (!actions.length) return true
  return permitted(permittedActions, actions, any)
}
