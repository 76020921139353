import './rIC-shim'

import { captureMessage } from '@sentry/react'

import getGlobal from '~/src/Lib/getGlobal'

const global = getGlobal();

// Prevent ResizeObserver not defined errors
(async () => {
  if ('ResizeObserver' in global === false) {
    // Loads polyfill asynchronously, only if required.
    captureMessage('Loading ResizeObserver polyfill')
    const module = await import('https://cdn.skypack.dev/@juggle/resize-observer@3.3.0')
    global.ResizeObserver = module.ResizeObserver
  }
})()
