import { pick } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'

import { createNamespacedLogger } from '../Dashboard/utils/logger'
import { createRoomDashboardReactor } from './utils'

const logger = createNamespacedLogger('lastHarvest')

const SET_ROOM_LAST_HARVEST_PARAMS = 'SET_ROOM_LAST_HARVEST_PARAMS'
const NONE = Object.freeze({})

const initialState = {
  room: null
}

const lastHarvestBundle = createAsyncResourceBundle({
  name: 'lastHarvest',
  actionBaseType: 'LAST_HARVEST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { room } = store.selectRoomHarvestExpectedParams()
    if (!room) {
      return Promise.reject(new Error('No room selected'))
    }
    store.doLastHarvestSetParams({ room })
    return apiFetch(`/rooms/${room}/last_harvest/`).then(res => res ?? NONE)
  },
})

export default {
  ...lastHarvestBundle,
  reducer: reduceReducers(lastHarvestBundle.reducer, (state, action) => {
    switch (action.type) {
      case SET_ROOM_LAST_HARVEST_PARAMS: {
        const { room } = action.payload
        const newState = room === state.room ? state : {
          ...state,
          room,
          data: null
        }
        return newState
      }
      default:
        if (!Object.keys(initialState).every(key => key in state)) {
          return { ...initialState, ...state }
        }
        return state
    }
  }),
  doLastHarvestSetParams: payload => ({ dispatch, store }) => {
    if (!payload) return
    const lastHarvestRaw = store.selectLastHarvestRaw()
    // Don't thrash when the same value is getting set
    if (Object.entries(payload).every(([key, value]) => lastHarvestRaw[key] === value)) return
    dispatch({ type: 'SET_ROOM_LAST_HARVEST_PARAMS', payload })
  },
  selectLastHarvestParams: createSelector(
    'selectLastHarvestRaw',
    pick(Object.keys(initialState)),
  ),
  selectLastHarvestShouldNormallyUpdate: lastHarvestBundle.selectLastHarvestShouldUpdate,
  selectLastHarvestShouldUpdate: createSelector(
    'selectLastHarvestRaw',
    'selectLastHarvestShouldNormallyUpdate',
    'selectRoomHarvestExpectedParams',
    (
      { isLoading, lastSuccess, ...state } = EMPTY_OBJECT,
      shouldNormallyUpdate = false,
      { room: expectedRoom } = EMPTY_OBJECT
    ) => {
      if (isLoading || !expectedRoom) {
        logger.debug('should not update, already fetching or not in room', { isLoading, expectedRoom })
        return false
      }
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }

      if (state.room != expectedRoom) {
        logger.debug('should update, room changed', { expectedRoom, stateRoom: state.room })
        return true
      }
      return false
    }
  ),
  reactLastHarvestFetch: createRoomDashboardReactor({
    dependencies: ['selectLastHarvestShouldUpdate'],
    resultFn: shouldUpdate => logger.debug('[reactLastHarvestFetch] unguarded:', shouldUpdate) || (
      shouldUpdate
        ? { actionCreator: 'doFetchLastHarvest' }
        : null
    )
  }),
  persistActions: [],
}
