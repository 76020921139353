import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import { DEVICE_STATES } from '~/src/Setup/Devices/utils'
import { Device } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { getGroupedByHardware, getGroupedByName, SORT } from '../utils'

const logger = createLogger('Device/bundle/list')

const RECENTLY_PROVISIONED_UPDATE_INTERVAL = ms.minutes(1)

const DEVICE_LIST_SET_PAGE = 'DEVICE_LIST_SET_PAGE'
const DEVICE_LIST_SET_SORT = 'DEVICE_LIST_SET_SORT'
const DEVICE_LIST_SET_STATE_FILTER = 'DEVICE_LIST_SET_STATE_FILTER'
const DEVICE_LIST_SET_ROOM_FILTER = 'DEVICE_LIST_SET_ROOM_FILTER'
const DEVICE_LIST_SET_MODEL_FILTER = 'DEVICE_LIST_SET_MODEL_FILTER'
const DEVICE_LIST_SET_SEARCH = 'DEVICE_LIST_SET_SEARCH'
const DEVICE_LIST_RESET_PARAMS = 'DEVICE_LIST_RESET_PARAMS'

const additionalState = {
  searchTerms: '',
  currentSort: 'room__name',
  currentPage: 1,
  currentModelFilter: '',
  currentStateFilter: '',
  currentRoomFilter: 0
}

const {
  selectDeviceListShouldUpdate: selectDeviceListShouldNormallyUpdate,
  ...deviceListBundle
} = createAsyncResourceBundle({
  name: 'deviceList',
  actionBaseType: 'DEVICE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ dispatch, apiFetch, getState }) => {
    const { deviceList } = getState()
    const { searchTerms, currentRoomFilter, currentSort, currentPage, currentModelFilter, currentStateFilter } = deviceList
    const params = {
      roomFilter: currentRoomFilter,
      modelFilter: currentModelFilter,
      ordering: currentSort,
      search: searchTerms.length >= 3 ? searchTerms : '',
    }
    if (currentStateFilter) {
      params.deviceStateFilter = currentStateFilter
    }
    if (currentStateFilter === DEVICE_STATES.RECENTLY_PROVISIONED) {
      params.pagination = 0
    } else {
      params.page = currentPage
    }
    return apiFetch('/devices/', params).then(response => {
      const { entities, result } = normalize(response.results ?? response, [Device])
      dispatch({ actionCreator: 'doEntitiesReceived', args: [entities] })
      getGlobal().scrollTo({ top: 0 })
      return Array.isArray(response) ? { results: result, count: result.length } : { ...response, results: result }
    })
  },
})

export default {
  ...deviceListBundle,
  reducer: reduceReducers(deviceListBundle.reducer, (state, action) => {
    switch (action.type) {
      case DEVICE_LIST_SET_PAGE:
        return { ...state, currentPage: action.payload }
      case DEVICE_LIST_SET_SORT:
        return { ...state, currentPage: 1, currentSort: action.payload }
      case DEVICE_LIST_SET_STATE_FILTER:
        return { ...state, currentPage: 1, currentStateFilter: action.payload }
      case DEVICE_LIST_SET_ROOM_FILTER:
        return { ...state, currentPage: 1, currentRoomFilter: action.payload }
      case DEVICE_LIST_SET_MODEL_FILTER:
        return { ...state, currentPage: 1, currentModelFilter: action.payload }
      case DEVICE_LIST_SET_SEARCH:
        return { ...state, currentPage: 1, searchTerms: action.payload }
      case DEVICE_LIST_RESET_PARAMS:
        return { ...state, ...additionalState }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doDeviceListSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_PAGE, payload: page })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetSort: sort => ({ dispatch, store }) => {
    const { currentSort } = store.selectDeviceListRaw()
    dispatch({
      type: DEVICE_LIST_SET_SORT,
      payload: sort === currentSort ? additionalState.currentSort : sort,
    })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetStateFilter: filter => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_STATE_FILTER, payload: filter })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetRoomFilter: filter => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_ROOM_FILTER, payload: filter })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetModelFilter: filter => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_MODEL_FILTER, payload: filter })
    store.doMarkDeviceListAsOutdated()
  },
  doDeviceListSetSearch: (search, refresh = true) => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_SET_SEARCH, payload: search })
    if ((search.length >= 3 || search.length === 0) && refresh) {
      store.doMarkDeviceListAsOutdated()
    }
  },
  doDeviceListResetParams: () => ({ dispatch, store }) => {
    dispatch({ type: DEVICE_LIST_RESET_PARAMS })
    store.doMarkDeviceListAsOutdated()
  },
  reactDeviceListFetch: createAppIsReadySelector({
    dependencies: [
      'selectDeviceListShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, { url }) => {
      if (shouldUpdate && url.includes('devices')) {
        return { actionCreator: 'doFetchDeviceList' }
      }
      return undefined
    }
  }),
  selectCurrentDeviceList: createSelector(
    'selectDevices',
    'selectDeviceListRaw',
    (devices, deviceListRaw) => {
      if (!deviceListRaw.data) {
        return []
      }
      const fullDevices = deviceListRaw.data.results
        .map(deviceId => devices[deviceId])
        .filter(device => device !== undefined)
      return fullDevices
    }
  ),
  selectDevicesGrouped: createSelector(
    state => state.deviceList.currentSort,
    'selectCurrentDeviceList',
    'selectEntities',
    'selectCurrentFacility',
    (sort, devices, entities, facility) => {
      if (SORT.room === sort) {
        return getGroupedByName('room', devices, entities.rooms, facility)
      }
      if (SORT.model === sort) {
        return getGroupedByName('modelKey', devices, entities.models)
      }
      if ([SORT.signal, SORT.battery].includes(sort)) {
        return getGroupedByHardware(sort, devices)
      }
      return false
    }
  ),
  selectDeviceListShouldNormallyUpdate,
  selectDeviceListShouldUpdate: createSelector(
    'selectDeviceListShouldNormallyUpdate',
    'selectDeviceListRaw',
    'selectAppTime',
    (shouldUpdate, { currentStateFilter, isLoading, lastSuccess }) => {
      if (isLoading) {
        logger.debug('[selectDeviceListShouldUpdate] already fetching')
        return false
      }
      if (shouldUpdate) {
        logger.debug('[selectDeviceListShouldUpdate] should normally update')
        return true
      }
      const isRecentlyProvisioned = currentStateFilter === DEVICE_STATES.RECENTLY_PROVISIONED
      const now = Date.now()
      const quickStaleAt = now - RECENTLY_PROVISIONED_UPDATE_INTERVAL
      const isQuickStale = lastSuccess < quickStaleAt
      logger.debug('[selectDeviceListShouldUpdate]', {
        isRecentlyProvisioned,
        isQuickStale,
        lastSuccess: new Date(lastSuccess).toISOString(),
        quickStaleAt: new Date(quickStaleAt).toISOString(),
        now: new Date(now).toISOString(),
      })
      return isRecentlyProvisioned && isQuickStale
    }
  )
}
