import { SvgIcon } from '@mui/material'

export const paths = [`
M15.3692 11.3908C15.3692 14.2457 13.0548 16.56 10.2 16.56C7.34506 16.56 5.03072 14.2457 5.03072 11.3908
C5.03072 6.35087 10.2 1.44 10.2 1.44C10.2 1.44 15.3692 6.48 15.3692 11.3908Z
`,
`
M18.3538 0.9C18.3538 0.402944 18.7671 0 19.2769 0C19.7867 0 20.2 0.402944 20.2 0.9V17.1C20.2 17.5971
19.7867 18 19.2769 18C18.7671 18 18.3538 17.5971 18.3538 17.1V0.9Z
`,
`
M0.199951 0.9C0.199951 0.402944 0.613227 0 1.12303 0C1.63283 0 2.04611 0.402944 2.04611 0.9V17.1C2.04611 17.5971 1.63283 18 1.12303 18C0.613227 18 0.199951 17.5971 0.199951 17.1V0.9Z
`
]

export default props => (
  <SvgIcon {...props}>
    <g transform="translate(1.5, 3)">
      {paths.map(path => <path key={path} d={path} />)}
    </g>
  </SvgIcon>
)
