import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'

import { QUICK_STALE_AFTER } from '~/src/App/constants'
import { HARVESTING_FLOW } from '~/src/Flow/constants'
import { HARVEST_FLOWS } from '~/src/Harvest/constants'
import { createInventoryBundle, getInventoryList } from '~/src/Inventory/bundle'
import { INVENTORY_TYPES } from '~/src/Inventory/constants'
import { INVENTORY_PERMISSIONS } from '~/src/Inventory/utils'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'

import urls from './urls'

const name = 'harvestInventory'
const logger = createLogger(name)

const initialState = {
  availableFor: null,
  types: Object.values(INVENTORY_TYPES).flatMap(Object.values)
}

const routeTestPattern = /^\/harvests\/(:id|new)/
const dialogTestPattern = new RegExp(`#\\/(${HARVEST_FLOWS.join('|')})\\/\\d+`)
export const urlTest = (url, pattern) => dialogTestPattern.test(url) || routeTestPattern.test(pattern)

const quickStaleUrlPattern = new RegExp(`#\\/${HARVESTING_FLOW}`)

const { selectHarvestInventoryShouldUpdate, ...bundle } = createInventoryBundle(name, {
  name,
  initialState,
  permissions: { any: true, keys: [...INVENTORY_PERMISSIONS, 'export_csv'] },
  fetchReactionPriority: (url, pattern) => (urlTest(url, pattern) ? REACTOR_PRIORITIES.HIGH : REACTOR_PRIORITIES.LOW),
  flags: undefined,
  staleAfter: minutes(15),
  // We need harvest inventory when viewing or creating a harvest and tagging or harvesting plants in an existing one
  urlTest,
})

export default {
  ...bundle,
  selectCurrentHarvestInventory: createSelector(
    'selectHarvestInventory',
    'selectEntities',
    'selectCurrentHarvestId',
    'selectHarvestInventoryParams',
    'selectRouteInfo',
    'selectDialogRouteInfo',
    (harvestInventory, entities, harvestId, { harvests }, routeInfo = EMPTY_OBJECT, dialogRouteInfo = EMPTY_OBJECT) => {
      const { params: pageParams = EMPTY_OBJECT, pattern } = routeInfo
      const { params: dialogParams = EMPTY_OBJECT, value: dialogRouteHandler } = dialogRouteInfo
      const isHarvestDetailPage = pattern.startsWith(urls.view)
      const isHarvestEntityDialog = (dialogParams.schema === 'harvests' || dialogRouteHandler?.defaultSchema === 'harvests') && dialogParams.id
      if (!harvestInventory || (!harvestInventory.length && !harvestInventory.results?.length)) return EMPTY_ARRAY
      let harvestIds = new Set()
      // if a current harvestId is set, add it
      if (harvestId) {
        harvestIds.add(harvestId)
      }
      // if we're on a /harvests/:id page, add the id param
      if (isHarvestDetailPage) {
        harvestIds.add(Number(pageParams.id))
      }
      // if we have an entity dialog open and the schema param is 'harvests', add the id param
      if (isHarvestEntityDialog) {
        harvestIds.add(Number(dialogParams.id))
      }
      // otherwise, add any set harvests from the inventory params
      if (!harvestIds.size && Array.isArray(harvests)) {
        harvestIds = new Set([...harvests].filter(Boolean))
      }
      const itemFilter = harvestIds.size ? item => item && harvestIds.has(item.harvest) : Boolean
      logger.debug('filtering harvest inventory to harvests', { dialogParams, harvestIds, harvestInventory, entities, harvestId, harvests, pageParams })
      return getInventoryList(harvestInventory, entities, itemFilter)
    }
  ),
  selectHarvestInventoryShouldNormallyUpdate: selectHarvestInventoryShouldUpdate,
  selectHarvestInventoryShouldUpdate: createSelector(
    'selectHarvestInventoryIsLoading',
    'selectHarvestInventoryShouldNormallyUpdate',
    'selectUrlObject',
    'selectHarvestInventoryLastSuccess',
    (alreadyFetching, shouldNormallyUpdate, { pathname, hash }, lastSuccess) => {
      if (alreadyFetching) return false
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }
      const isQuickStaleRoute = quickStaleUrlPattern.test(`${pathname}#${hash}`)
      const isQuickStale = (!lastSuccess || Date.now() - lastSuccess >= QUICK_STALE_AFTER)
      if (isQuickStaleRoute && isQuickStale) {
        logger.debug('should update, quick stale?', { isQuickStaleRoute, isQuickStale })
      }
      return isQuickStaleRoute && isQuickStale
    }
  ),
}
