import { prop } from 'ramda'

import getGlobal from '~/src/Lib/getGlobal'
import safeStorage from '~/src/Lib/safeStorage'
import { throttle } from '~/src/Lib/Utils'

import NOTES from './notes.json'

const localStorage = safeStorage('local')

const INITIALIZE_AUDIO = 'INITIALIZE_AUDIO'
const NOTE_DURATION = 0.5

const SUCCESS_ONE = [
  { tone: NOTES.D4, duration: NOTE_DURATION },
  // { tone: null, duration: NOTE_DURATION },
  // { tone: NOTES.E5, duration: NOTE_DURATION },
]

const SUCCESS_TWO = [
  { tone: NOTES.G4, duration: NOTE_DURATION },
  // { tone: null, duration: NOTE_DURATION },
  // { tone: NOTES.B5, duration: NOTE_DURATION },
]

const ERROR = [
  { tone: NOTES.F4, duration: NOTE_DURATION * 1.5 },
  { tone: null, duration: NOTE_DURATION },
  { tone: NOTES.D4, duration: NOTE_DURATION * 2 },
]

const eventOptions = { capture: true, passive: true }
const interactionEvents = ['keydown', 'mousedown', 'mousemove', 'touchstart']

const playNotes = async (notes, audio) => {
  const { context } = audio
  if (!context || context.state === 'closed') {
    console.warn('attempted to play sounds when no active audio context:', context)
    return Promise.resolve(false)
  }
  if (context.state === 'suspended') {
    await context.resume()
  }
  let start = context.currentTime

  return new Promise(resolve => {
    notes.forEach((note, index) => {
      const { duration = NOTE_DURATION, tone } = note
      if (!tone) {
        start += duration
        return
      }
      const amp = context.createGain()
      amp.gain.setValueAtTime(0.0, 0)
      amp.connect(context.destination)
      const synth = context.createOscillator()
      synth.connect(amp)
      synth.type = 'sine'
      synth.frequency.value = tone
      if (index === notes.length - 1) {
        synth.onended = () => {
          context.suspend()
          resolve(true)
        }
      }
      // play the current note
      synth.start(start)
      amp.gain.setTargetAtTime(1, start, 0.01)
      // schedule the end of the note
      amp.gain.setValueAtTime(1, start + (duration * 0.9))
      amp.gain.exponentialRampToValueAtTime(0.0001, start + duration)
      // signal end of the current note
      synth.stop(start + duration + 0.1)
      start += (note.duration ?? NOTE_DURATION)
    })
  })
}

export default {
  name: 'audio',
  init: ({ selectMySettings, selectAudio, doInitializeAudio }) => {
    const global = getGlobal()
    if (!global) return null
    const handler = throttle(() => {
      const { context } = selectAudio()
      const { audioFeedback } = selectMySettings()
      if (!context && audioFeedback) {
        doInitializeAudio()
      }
    }, 1000)

    interactionEvents.forEach(eventName => global.addEventListener(eventName, handler, eventOptions))

    return () => {
      interactionEvents.forEach(eventName => global.addEventListener(eventName, handler))
    }
  },
  reducer: (
    state = {
      enabled: localStorage.audioOptIn === 'true',
      notes: {
        success: [SUCCESS_ONE, SUCCESS_TWO],
        error: [ERROR],
      }
    },
    action
  ) => {
    if (action.type === INITIALIZE_AUDIO) {
      const { context } = action.payload

      return {
        ...state,
        context,
      }
    }
    return state
  },
  doAudioDisable: () => ({ store }) => store.doUpdateMySettings({ path: ['audioFeedback'], value: false }),
  doAudioEnable: () => ({ store }) => store.doUpdateMySettings({ path: ['audioFeedback'], value: true }),
  doInitializeAudio: () => ({ type: INITIALIZE_AUDIO, payload: { context: new AudioContext() } }),
  doAudioPlay: notes => ({ store }) => playNotes(notes, store.selectAudio()),
  selectAudio: prop('audio'),
}
