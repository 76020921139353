import { createSelector } from 'redux-bundler'

import appConfig from '~/src/App/config'
import { EPOCH_DATE } from '~/src/Lib/Constants'
import { getActionType } from '~/src/Lib/createEntityBundle'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { getDateTime } from '~/src/Lib/Utils'
import themes from '~/src/UI/Theme'

const logger = createLogger('bundles/config')

const global = getGlobal()
const storage = safeStorage()
const configUpdate = getActionType('update', 'config')
const isDevDebug = storage.debug === 'true' && global.config.ENVIRONMENT !== 'production'

const TIER_CONFIG_ADDITIONS = {
  DEFAULT: {
    MINIMUM_FROM: EPOCH_DATE,
  },
  GO: {
    get MINIMUM_FROM() {
      return getDateTime('now').startOf('day').minus({ days: 60 }).toFormat('yyyy-MM-dd')
    }
  }
}

export default {
  name: 'config',
  reducer: (state, action) => {
    let nextState
    if (!state) {
      const { testApp, testTier } = storage
      nextState = {
        APP: [
          isDevDebug ? testApp : undefined,
          appConfig.APP,
          'AROYA'
        ].find(Boolean),
        TIER: [
          isDevDebug ? testTier : undefined,
          'ESSENTIALS'
        ].find(Boolean),
      }
      const { [nextState.TIER]: configAddtions = TIER_CONFIG_ADDITIONS.DEFAULT } = TIER_CONFIG_ADDITIONS
      Object.assign(nextState, configAddtions)
      return nextState
    }
    if (action.type === configUpdate) {
      return { ...state, ...action.payload }
    }
    return state
  },
  doConfigUpdate: payload => ({ type: configUpdate, payload }),
  selectConfig: createSelector(
    state => state.config,
    config => ({ ...appConfig, ...config })
  ),
  reactConfigUpdateApp: createSelector(
    'selectConfig',
    'selectCurrentFacility',
    ({ APP, TIER }, facility) => {
      let newApp
      let newTier
      const { testApp, testTier } = storage
      if (isDevDebug && testApp && APP !== testApp) {
        newApp = testApp
      }
      if (!newApp && facility?.app && APP !== facility.app) {
        newApp = facility.app
      }

      if (isDevDebug && testTier && APP !== testTier) {
        newTier = testTier
      }
      if (!newTier && facility?.tier && APP !== facility.tier) {
        newTier = facility.tier
      }

      if (newApp || newTier) {
        themes.APP = newApp
        const newConfig = {}
        if (newApp && newApp !== APP) newConfig.APP = newApp
        if (newTier && newTier !== TIER) newConfig.TIER = newTier
        if (newConfig.TIER) {
          const { [newConfig.TIER]: additionalConfig = TIER_CONFIG_ADDITIONS.DEFAULT } = TIER_CONFIG_ADDITIONS
          Object.assign(newConfig, additionalConfig)
        }
        return Object.keys(newConfig).length ? { actionCreator: 'doConfigUpdate', args: [newConfig] } : null
      }
      return null
    }
  )
}
