import { memo, useMemo } from 'react'

import classNames from 'clsx'
import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { styled } from '@mui/material/styles'

import { urls as harvestUrls } from '~/src/Harvest/bundle'
import createLogger from '~/src/Lib/Logging'
import { idAndNameShape } from '~/src/Lib/PropTypes'
import getClassPrefixer from '~/src/UI/classPrefixer'
import HarvestIcon from '~/src/UI/Icons/Batch'
import T from '~/src/UI/Shared/Typography'
import { ColorsByKeyThemeProvider } from '~/src/UI/Theme'

const displayName = 'Widgets/HarvestV2'
const logger = createLogger(displayName)

const classes = getClassPrefixer(displayName)
const Container = styled(({ href, children, ...props }) => (
  href ? <a href={href} {...props}>{children}</a> : <div {...props}>{children}</div>
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  'a&': {
    textDecoration: 'none',
  },
  [`& .${classes.harvest}`]: {
    color: theme.palette.appSecondary.main,
  },
  [`& .${classes.icon}`]: {
    color: theme.palette.appSecondary.main,
  },
  [`& .${classes.phaseDay}`]: {
    color: theme.palette.contrast.disabled,
    fontSize: '0.875rem',
    paddingTop: theme.spacing(0.15),
    paddingLeft: theme.spacing(1.25)
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    gap: 2
  },
  [`& .${classes.small}`]: {
    fontSize: '0.875rem'
  },
  [`& .${classes.smallIcon}`]: {
    fontSize: '1.1rem'
  }
}))

const HarvestWidget = ({
  harvest = null,
  link = false,
  phase = null,
  relativeStartDay = null,
  small = false,
}) => (
  <Container href={link && harvest ? harvestUrls.getUrl(harvest) : undefined}>
    <div className={classNames({ [classes.content]: true, [classes.small]: small })}>
      {harvest?.name ? (
        <>
          {harvest.recipe ? (
            <T fontSize="inherit" color="inherit" component="span" display="inline">{i18n`Recipe:`}</T>
          ) : (
            <HarvestIcon className={classNames({ [classes.icon]: true, [classes.smallIcon]: small })} />
          )}
          <T fontSize="inherit" noWrap className={classes.harvest}>
            {harvest.name}{harvest.recipe}
          </T>
        </>
      ) : null}
      {phase?.name ? (
        <>
          <ColorsByKeyThemeProvider>
            <T
              noWrap
              component="span"
              fontSize="inherit"
              color={`${phase.phaseType}.light`}
            >
              {harvest ? '- ' : null}{phase.name}
            </T>
          </ColorsByKeyThemeProvider>
          {relativeStartDay ? <T.Tiny noWrap className={classes.phaseDay}>{i18n`day ${relativeStartDay}`}</T.Tiny> : null}
        </>
      ) : null}
    </div>
  </Container>
)

HarvestWidget.propTypes = {
  harvest: idAndNameShape,
  link: PropTypes.bool,
  phase: idAndNameShape,
  relativeStartDay: PropTypes.number,
  small: PropTypes.bool
}

const Connected = ({
  harvest = null,
  phase = null,
  ...props
}) => {
  const {
    harvests,
    phases,
    recipePhases,
    recipes,
  } = useConnect(
    'selectHarvests',
    'selectPhases',
    'selectRecipePhases',
    'selectRecipes'
  )
  const phaseEntity = useMemo(() => {
    if (phase && phase.id) {
      return phase
    }
    return phases[phase] ?? recipePhases[phase]
  }, [phase, phases, recipePhases])

  const harvestId = phaseEntity?.harvest ?? phaseEntity?.harvestId ?? harvest

  const harvestEntity = useMemo(() => {
    if (typeof harvestId !== 'number' && harvest && harvest.id) {
      return harvest
    }
    return harvests[harvestId] ?? recipes[harvestId]
  }, [harvest, harvestId, harvests, recipes])

  return harvestEntity || phaseEntity ? (
    <HarvestWidget
      {...props}
      harvest={harvestEntity}
      phase={phaseEntity}
    />
  ) : null
}

const Memoized = memo(Connected)
export default Memoized
