import createEntityBundle from '~/src/Lib/createEntityBundle'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { Note as schema } from '~/src/Store/Schemas'

import { prepareData } from './shape'

const name = 'notes'
const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }

export default createEntityBundle({
  name,
  apiConfig: {
    prepareData,
    schema,
    snackbar: 'title',
    save: writesConfig,
    delete: writesConfig,
  },
})
