import { evolve } from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle, { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { validNumberOrNil } from '~/src/Lib/Data'
import createLogger from '~/src/Lib/Logging'
import { parseApiErrors } from '~/src/Lib/Utils'
import { IrrigationController as schema } from '~/src/Store/Schemas'

import { getControllerLocationLabel } from './utils'

const logger = createLogger('Irrigation/bundle#controllers')

const name = 'irrigationControllers'

const entityBundle = createEntityBundle({
  name,
  apiConfig: {
    cascade: [['irrigationControllerPorts', 'controller']],
    prepareData: evolve({
      config: {
        port: validNumberOrNil,
      }
    }),
    schema
  },
})

export default {
  ...entityBundle,
  doProvisionIrrigationController: payload => async ({ apiFetch, dispatch }) => {
    const { id } = payload

    let result
    try {
      const provisioningStatus = await apiFetch(`/irrigationControllers/${id}/provision/`, payload, { method: 'POST' })
      result = { ...payload, provisioningStatus }
      // TODO: think if I need to update corresponding entity in redux
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: ['Successfully started provisioning irrigation controller', 'success'],
      })
    } catch (err) {
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Failed to provision irrigation controller: ${parseApiErrors(err)}`, 'error'],
      })
    }

    return result
  },
  doFetchIrrigationControllerProvisioningStatus: data => async ({ apiFetch, dispatch }) => {
    const { controllerId } = data

    let result
    try {
      result = await apiFetch(`/irrigationControllers/${controllerId}/provision/`, null, { method: 'GET' })

      const entities = { irrigationControllers: { [controllerId]: { provisioningStatus: result } } }
      dispatch(doEntitiesReceived(entities, { replace: false }))
    } catch (err) {
      const errorMessage = parseApiErrors(err)
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [errorMessage] })
    }
    return result
  },
  selectIrrigationControllerPorts: createSelector(
    'selectEntities',
    entities => (entities?.irrigationControllerPorts ?? {}),
  ),
  selectZonePortMap: createSelector(
    'selectIrrigationControllerPorts',
    ports => (!ports ? {} : Object.values(ports)
      .reduce((zonePortMap, port) => (
        Object.assign(zonePortMap, ...port.zones.map(zone => ({ [zone]: port })))
      ), {})),
  ),
  selectIrrigationControllerLocationLabels: createSelector(
    'selectIrrigationControllerPorts',
    'selectIrrigationControllers',
    'selectRooms',
    'selectZones',
    (irrigationControllerPorts, irrigationControllers, rooms, zones) => Object.values(irrigationControllers).reduce((labels, controller) => ({
      ...labels,
      [controller.id]: getControllerLocationLabel(controller, { irrigationControllerPorts, rooms, zones }),
    }), {}),
  ),
  reactDetectRoomIrrigationChanges: createSelector(
    'selectRoomDashboardRoom',
    'selectIrrigationControllers',
    'selectZonePortMap',
    (room, irrigationControllers, zonePortMap) => {
      if (!room.id || room.inflight || !room.irrigationControllers) return null
      const zonePorts = room.zones.map(z => zonePortMap[z.id ?? z]).filter(Boolean)
      const currentRoomControllers = [...new Set(
        zonePorts.map(port => irrigationControllers[port.controller]).filter(Boolean)
      )]

      const controllersUnassigned = Boolean(!currentRoomControllers.length && room.irrigationControllers.length)

      if (controllersUnassigned) {
        logger.debug('[reactDetectRoomIrrigationChanges]', { controllersUnassigned })
        return { actionCreator: 'doRoomFetch', args: [room.id] }
      }
      return null
    },
  ),
}
