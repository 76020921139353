import {
  filter,
  flatten,
  join,
  map,
  pipe,
  trim,
  uniq,
} from 'ramda'

import queryString from 'query-string'
import { createSelectorCreator, defaultMemoize } from 'reselect'

import { EMPTY_ARRAY } from './constants'
import { getDateTime } from './datetime'
import { shallowEquals } from './equality'
import { defaultMemoizerOpts } from './memoizer'

export * from './color'
export * from './constants'
export * from './datetime'
export * from './equality'
export * from './timing'
export * from './dom'
export * from './hooks'
export * from './ids'
export * from './lazyWithRetries'
export * from './measurements'
export { default as memoize, shallowEqualsMemoizer } from './memoizer'
export * from './numbers'
export * from './params'
export * from './strings'
export * from './styles'
export * from './types'
export * from './validation'

export const noop = () => null

export const createShallowEqualsSelector = createSelectorCreator(
  defaultMemoize,
  (left, right) => shallowEquals(left, right, defaultMemoizerOpts)
)
const notANumber = o => typeof o !== 'number'
export const errorParser = response => {
  if (typeof response === 'string' || !response) return [response ?? '']

  let responseObj = response
  if (response.error) {
    if (response.error.response) {
      responseObj = response.error.response
      if (typeof responseObj === 'string') {
        return [responseObj]
      }
    } else if (response.error.message) {
      responseObj = response.error.message
      if (typeof responseObj === 'string') {
        return [responseObj]
      }
    }
  }

  let errorMsg = EMPTY_ARRAY
  if (Array.isArray(responseObj)) {
    errorMsg = responseObj.every(str => typeof str === 'string')
      ? responseObj
      : responseObj.filter(notANumber).map(errorParser)
  }
  if (Object.keys(responseObj)?.length) {
    errorMsg = Object.values(responseObj).filter(notANumber).map(errorParser)
  }

  return errorMsg
}
/**
 * Returns api errors as string with breaks
 * @param {string|object|array} response
 */
export const parseApiErrors = pipe(errorParser, flatten, map(trim), filter(Boolean), uniq, join('\n'))

/**
 * Returns errors as trimmed string with breaks
 * @param {string|array|number} [error]
 * @returns {string}
 */
export const formatError = error => {
  if (!error || typeof error === 'string') return error ? trim(error) : ''
  if (Array.isArray(error)) return error.map(formatError).join('\n')
  return String(error)
}

/**
 * Returns url for the room dashboard with the time range
 * @param {number} roomID
 * @param {number|null} zoneID
 * @param {array|null} entity
 * @param {ts} number
 * @returns {string}
 */
export const getRoomAnnotationURL = ({ roomID, zoneID, entity = [], ts }) => {
  const time = getDateTime(ts)
  const from = time.minus({ hours: 4 }).toISO()
  const to = time.plus({ hours: 4 }).toISO()
  const [key, value] = entity
  const query = {
    from,
    to,
    zones: zoneID,
    individualSensors: true,
    ...(key && value && { [key]: value })
  }
  return `/rooms/${roomID}?${queryString.stringify(query)}`
}

const stringables = ['string', 'number']
/**
 * Utility to get text from rendered React node
 * @param {ReactNode} node
 */
export const getNodeText = node => {
  if (stringables.includes(typeof node)) {
    return String(node)
  }
  if (Array.isArray(node)) {
    return node.map(getNodeText).join('')
  }
  if (typeof node === 'object' && node?.props?.children) {
    return getNodeText(node.props.children)
  }
  return ''
}
