import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import {
  pick,
  pipe,
  prop,
  sort,
  uniqBy,
} from 'ramda'

import { sortSourcesByLabel } from '~/src/Flow/bundle/utils'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT, shallowEqualsMemoizer } from '~/src/Lib/Utils'

import * as actions from '../actions'
import {
  getMetrcQueuedSources,
  getNewQueue,
  getSelectedItems,
  getUpdatedQueue,
  reconcileStateAndFlow,
} from '../utils'
import { SHARED_INITIAL_STATE } from './shared'
import { getSavedAt, setModifiedAt } from './utils'

const logger = createLogger('Harvesting#reducers-metrc')

export const initFlowState = memoizeOne(harvestId => ({
  ...SHARED_INITIAL_STATE,
  harvestId,
  endingPlantId: null,
  itemsByCategory: EMPTY_OBJECT,
  lastSelectedItem: null,
  dequeued: [],
  search: '',
  selectedItems: EMPTY_ARRAY,
  showSequentialTagInputs: false,
  sortedItemCategories: EMPTY_ARRAY,
  startingPlantId: null,
}))

const defaultSequentialTagState = pick(['search', 'startingPlantId', 'endingPlantId', 'selected'], initFlowState())
const uniqueSources = shallowEqualsMemoizer(pipe(uniqBy(prop('id')), sort(sortSourcesByLabel)))
const pickInitialized = pick(['harvestId', 'itemsByCategory', 'sortedItemCategories', 'sources'])

const initializeSources = (state, payload) => {
  const { harvestId, queue, selected } = state
  const { sources } = payload

  const [updatedQueue] = getUpdatedQueue(queue, sources)
  const queuedSources = getMetrcQueuedSources(updatedQueue)
  logger.debug('[initializeSources] new queue data:', { queue, updatedQueue, queuedSources })
  return {
    ...state,
    ...pickInitialized(payload),
    queue: updatedQueue,
    queuedSources,
    selected: payload.harvestId === harvestId ? selected : null,
    selectedItems: getSelectedItems(queue),
  }
}

const reducers = {
  [actions.HARVESTING_INITIALIZE_SOURCES]: initializeSources,

  [actions.HARVESTING_PROCESSED_ROOMS_CHANGE]: (state, payload) => ({
    ...state,
    processedRooms: {
      ...state.processedRooms,
      [payload.groupId]: payload.room,
    },
  }),

  [actions.HARVESTING_SELECT_ITEM_BY_BARCODE]: (state, payload, meta) => {
    const { selected: previousSelected, search, sources, queue } = state
    const { weighingIndividually } = meta.settings

    const queueItemWhereTheScannedPlantIsLocated = queue.find(queueItem => queueItem.sources.find(source => source.label.toLowerCase() === payload.toLowerCase()))
    if (queueItemWhereTheScannedPlantIsLocated) {
      const { cultivarName, weight, item: { name } } = queueItemWhereTheScannedPlantIsLocated
      return {
        ...state,
        lastSnackbarMessage: i18n`label already part of ${name} ${cultivarName} ${weight} item`,
      }
    }

    const lowerSearch = search ? search.toLowerCase() : search
    const availableSources = search ? sources.filter(plant => {
      const { cultivarName, label } = plant
      return label.toLowerCase().includes(lowerSearch) || cultivarName.toLowerCase().includes(lowerSearch)
    }) : sources
    const lowerPayload = payload.toLowerCase()
    const scannedPlantInSource = availableSources.find(source => source.label.toLowerCase() === lowerPayload)

    if (scannedPlantInSource) {
      if (previousSelected && previousSelected.license !== scannedPlantInSource.license) {
        return {
          ...state,
          lastSnackbarMessage: i18n`Scanned tag is from a different license than those already selected`
        }
      }
      const alreadySelected = Boolean(previousSelected?.sources?.find(selectedPlant => selectedPlant.id === scannedPlantInSource.id))

      let newSelectedSources = []
      // if the previous selected obj is defined, then we also need to add already selected plants
      if (!weighingIndividually && previousSelected?.sources) {
        newSelectedSources = alreadySelected
          ? previousSelected.sources
          : [...previousSelected.sources, scannedPlantInSource]
      } else {
        newSelectedSources = alreadySelected
          ? previousSelected.sources
          : [scannedPlantInSource]
      }

      return {
        ...state,
        selected: {
          ...previousSelected,
          cultivarName: scannedPlantInSource.cultivarName,
          harvestCultivarId: scannedPlantInSource.harvestCultivar,
          license: scannedPlantInSource.license,
          sources: newSelectedSources,
        },
      }
    }

    if (!scannedPlantInSource) {
      return {
        ...state,
        lastSnackbarMessage: i18n`label "${payload}" not found in sources ${search ? i18n`matching "${search}"` : ''}`,
      }
    }

    return state
  },

  [actions.HARVESTING_SOURCE_CLICK]: (state, payload, meta) => {
    const {
      selected: previousSelected,
    } = state
    const { weighingIndividually } = meta.settings

    // this means that we already have an object with some selected plants
    if (previousSelected) {
      const isAlreadySelected = previousSelected.sources.find(source => source.id === payload.id)
      if (!weighingIndividually) {
        // here we are adding/removing the selected plant from our harvest batch
        const selectedSources = isAlreadySelected
          ? previousSelected.sources.filter(source => source.id !== payload.id)
          : [...previousSelected.sources, payload]
        // if we still have some selected sources - then we need to update the harvest batch
        if (selectedSources.length) {
          return {
            ...state,
            selected: {
              ...previousSelected,
              sources: selectedSources,
            }
          }
        }

        // isInQueue means that we are trying to edit an already queued harvest batch. In that case we don't need to reset the 'selected' key - we just update the sources array
        if (previousSelected.isInQueue) {
          return {
            ...state,
            selected: {
              ...previousSelected,
              sources: [],
            },
          }
        }
      }

      // if we get here the plant was selected and there are no other selected plants - so we reset the 'selected' key
      return { ...state, selected: null }
    }

    // here we create a new harvest batch
    return {
      ...state,
      selected: {
        cultivarName: payload.cultivarName,
        harvestCultivarId: payload.harvestCultivar,
        license: payload.license,
        sources: [payload],
      },
    }
  },

  [actions.HARVESTING_REMOVE_QUEUED]: (state, payload) => {
    const { queue: previousQueue } = state
    const queue = previousQueue.filter(queued => queued.id !== payload.id)
    const selectedItems = getSelectedItems(queue)

    return {
      ...state,
      dequeued: [...state.dequeued, payload.id],
      selected: null,
      selectedItems,
      queue,
      queuedSources: getMetrcQueuedSources(queue),
    }
  },

  [actions.HARVESTING_REVIEW_DONE]: state => ({
    ...state,
    selected: null,
    selectedItems: [],
    queue: [],
    queuedSources: {},
    review: false,
  }),

  [actions.HARVESTING_SEARCH_CHANGE]: (state, payload) => ({
    ...state,
    search: payload,
    selected: null,
  }),

  [actions.HARVESTING_TOGGLE_SEQUENTIAL_TAG_INPUTS]: state => {
    const { search, sources } = state
    const showSequentialTagInputs = !state.showSequentialTagInputs
    const exactMatch = sources.find(src => src.label === search)
    if (showSequentialTagInputs && search && exactMatch) {
      return {
        ...state,
        ...defaultSequentialTagState,
        showSequentialTagInputs,
        startingPlantId: search,
        selected: {
          harvestCultivarId: exactMatch.harvestCultivar,
          cultivarName: exactMatch.cultivarName,
          sources: [exactMatch],
        },
      }
    }

    return showSequentialTagInputs ? {
      ...state,
      showSequentialTagInputs,
    } : {
      ...state,
      ...defaultSequentialTagState,
      showSequentialTagInputs,
    }
  },

  [actions.HARVESTING_STARTING_PLANT_ID_CHANGE]: (state, payload) => {
    const { sources, endingPlantId } = state
    const startAndEndPlantIdsAreAppropriate = payload.length === 24 && endingPlantId?.length === 24
    if (startAndEndPlantIdsAreAppropriate) {
      const expectedSelectedRange = sources.filter(
        source => source.label.toLowerCase() >= payload?.toLowerCase() && source.label.toLowerCase() <= endingPlantId?.toLowerCase()
      )

      let selected = state.selected
      if (expectedSelectedRange.length) {
        const { 0: first } = expectedSelectedRange
        const { cultivarName, harvestCultivar: harvestCultivarId, license } = first
        selected = selected?.isInQueue ? {
          ...selected,
          cultivarName,
          harvestCultivarId,
          sources: uniqueSources([...selected.sources, ...expectedSelectedRange]),
        } : {
          cultivarName,
          harvestCultivarId,
          license,
          sources: expectedSelectedRange,
        }
      }

      return {
        ...state,
        startingPlantId: payload,
        selected,
      }
    }

    return {
      ...state,
      startingPlantId: payload,
      selected: state.selected?.isInQueue ? state.selected : null,
    }
  },

  [actions.HARVESTING_ENDING_PLANT_ID_CHANGE]: (state, payload) => {
    const { sources, startingPlantId } = state
    const startAndEndPlantIdsAreAppropriate = startingPlantId?.length === 24 && payload.length === 24
    if (startAndEndPlantIdsAreAppropriate) {
      const expectedSelectedRange = sources.filter(
        source => source.label.toLowerCase() >= startingPlantId?.toLowerCase() && source.label.toLowerCase() <= payload?.toLowerCase()
      )

      let selected = state.selected
      if (expectedSelectedRange.length) {
        const { 0: first } = expectedSelectedRange
        const { cultivarName, harvestCultivar: harvestCultivarId, license } = first
        selected = selected?.isInQueue ? {
          ...selected,
          cultivarName,
          harvestCultivarId,
          sources: uniqueSources([...selected.sources, ...expectedSelectedRange]),
        } : {
          cultivarName,
          harvestCultivarId,
          license,
          sources: expectedSelectedRange,
        }
      }

      return {
        ...state,
        endingPlantId: payload,
        selected,
      }
    }

    return {
      ...state,
      endingPlantId: payload,
      selected: state.selected?.isInQueue ? state.selected : null,
    }
  },

  [actions.HARVESTING_SUBMIT_FORM]: (state, payload) => {
    if (!payload?.sources) {
      return state
    }
    // in case of new queue item, we need to check if the chosen item type was a new one, or selected earlier by some previous queue item
    const queue = getNewQueue(payload, state.queue)
    return {
      ...state,
      queue,
      queuedSources: getMetrcQueuedSources(queue),
      search: initFlowState.search,
      selected: null,
      selectedItems: getSelectedItems(queue),
      lastSelectedItem: payload.item,
      startingPlantId: null,
      endingPlantId: null,
      showSequentialTagInputs: false,
    }
  },

  [actions.HARVESTING_RESET_STATE]: state => initFlowState(state.harvestId),

  [actions.HARVESTING_REFRESH_STATE]: (state, payload, meta) => ({
    ...state,
    ...reconcileStateAndFlow(state, payload, meta?.which),
    id: payload.flowId ?? state.id,
    savedAt: getSavedAt(payload, state),
  }),

  [actions.HARVESTING_RECONCILED_FLOW]: (state, payload, meta) => {
    const { sources } = state

    return initializeSources({
      ...state,
      ...reconcileStateAndFlow(state, payload, meta?.which),
      reconciling: 0,
    }, { sources })
  },
}

export default Object.entries(reducers).reduce((acc, [key, reducer]) => ({
  ...acc,
  [key]: setModifiedAt(reducer),
}), {})
