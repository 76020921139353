import { SvgIcon } from '@mui/material'

const mainPath = (`
  M11.413 5.726c.008-.416-.14-.857-.48-1.264a1.191 1.191 0 0 1 .145-1.673 1.178 1.178 0 0 1 1.665
  .145c.669.8 1.048 1.78 1.034 2.815.013.974-.459 1.731-.74 2.183l-.03.048c-.346.557-.41.718-.407
  .865l.002.046-.002.046c-.006.128.048.278.399.838l.034.054c.282.449.76 1.208.75 2.18.012.976-.46
  1.733-.74 2.184l-.03.048c-.347.558-.412.719-.407.866v.086c-.007.135.053.297.396.854l.038.062c.28
  .451.747 1.208.748 2.162 0 1.022-.369 1.993-1.016 2.798-.41.51-1.155.59-1.662.177a1.191 1.191 0 0
  1-.176-1.67c.337-.419.49-.871.49-1.303 0-.22-.102-.44-.432-.976l-.046-.074c-.266-.43-.728-1.172
  -.704-2.088-.012-.913.448-1.646.714-2.07l.05-.08c.325-.523.417-.733.413-.95v-.048c.004-.21-.088
  -.417-.42-.946l-.05-.078c-.268-.424-.74-1.168-.712-2.093-.012-.911.448-1.643.714-2.067l.05-.08
  c.325-.523.417-.733.413-.95l-.001-.023v-.024Z
`).trim()

export const DrainIcon = props => (
  <SvgIcon {...props} viewBox="0 0 25 25">
    <path fillRule="evenodd" clipRule="evenodd" d={mainPath} fill="currentColor" />
    <path d="m18.467 2.505.009 10m-12.96-9.99.008 10.5" stroke="currentColor" />
    <path
      d="m16.016 19.007-4.497 3.003-3.503-3.497"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </SvgIcon>
)

export default DrainIcon
