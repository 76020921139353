/**
 * @file Processing inventory redux bundle.
 * @docz_menu ~/src/Flow
 * @docz_name bundle/processing-inventory
 */
import {
  always,
  compose,
  curry,
  either,
  filter,
  into,
  map,
  path,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import { DRYING_FLOW_ROUTE, PROCESSING_FLOW_ROUTE } from '~/src/Flow/constants'
import { createInventoryBundle } from '~/src/Inventory/bundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { URL_ACTION } from '~/src/Store/constants'

const name = 'processingInventory'
const logger = createLogger(name)

const CLEAR_SELECTED_ITEMS = 'PROCESSING_INVENTORY_CLEAR_SELECTED_ITEMS'
const SET_SELECTED_ITEMS = 'PROCESSING_INVENTORY_SET_SELECTED_ITEMS'

const initialState = { manualReadings: null, types: ['package'], selectedUUIDs: EMPTY_ARRAY }
const initialBundle = createInventoryBundle(
  name,
  {
    name,
    initialState,
    urlTest: url => url.includes(DRYING_FLOW_ROUTE) || url.includes(PROCESSING_FLOW_ROUTE)
  }
)
const payloadUrl = either(path(['payload', 'url']), always(''))

export default {
  ...initialBundle,
  reducer: reduceReducers(initialBundle.reducer, (state, action) => {
    if (action?.type === SET_SELECTED_ITEMS) {
      return {
        ...state,
        selectedUUIDs: action.payload
      }
    }
    if (action?.type === CLEAR_SELECTED_ITEMS) {
      return state.selectedUUIDs !== initialState.selectedUUIDs ? {
        ...state,
        selectedUUIDs: initialState.selectedUUIDs,
      } : state
    }
    return state
  }),
  getMiddleware: () => curry((store, next, action) => {
    const url = payloadUrl(action)
    if (action?.type === URL_ACTION && url) {
      const {
        dialogRouteInfo: previousRouteInfo,
        dialogRouteMatcher: routeMatcher,
        processingInventorySelectedItems,
        processingInventoryParams: params,
      } = store.select([
        'selectDialogRouteInfo',
        'selectDialogRouteMatcher',
        'selectProcessingInventoryParams',
        'selectProcessingInventorySelectedItems',
      ])

      // no hash route, so definitely not in processing or drying flows
      if (!url.includes('#')) {
        next(action)
        return
      }

      const nextRouteUrl = new URL(url)
      const nextRouteInfo = routeMatcher(nextRouteUrl.hash?.slice(1))
      const nextIsDrying = nextRouteInfo?.pattern === DRYING_FLOW_ROUTE
      const previousWasDrying = previousRouteInfo?.pattern === DRYING_FLOW_ROUTE
      const changedToDrying = nextIsDrying && !previousWasDrying
      const nextIsProcessing = nextRouteInfo?.pattern === PROCESSING_FLOW_ROUTE
      const previousWasProcessing = previousRouteInfo?.pattern === PROCESSING_FLOW_ROUTE
      const changedToProcessing = nextIsProcessing && !previousWasProcessing

      // bail early if next URL isn't a harvest detail page
      if (!changedToDrying && !changedToProcessing) {
        next(action)
        return
      }
      const types = Array.isArray(params.types) ? params.types : Array.of(params.types)
      const selected = Array.isArray(processingInventorySelectedItems)
        ? processingInventorySelectedItems
        : EMPTY_ARRAY

      let nextParams = EMPTY_OBJECT
      if (nextIsDrying) {
        const type = 'harvestBatch'
        if (!types.includes(type) || selected.some(uuid => !uuid.startsWith(type))) {
          nextParams = {
            ...nextParams,
            types: [type],
            selected: selected.filter(uuid => uuid.startsWith(type))
          }
        }
      }
      if (nextIsProcessing) {
        const type = 'package'
        if (!types.includes(type) || selected.some(uuid => !uuid.startsWith(type))) {
          nextParams = {
            ...nextParams,
            types: [type],
            selected: selected.filter(uuid => uuid.startsWith(type))
          }
        }
      }
      // we need to alter params
      if (nextParams !== EMPTY_OBJECT) {
        store.dispatch({
          actionCreator: 'doProcessingInventorySetParams',
          args: [nextParams]
        })
      }
    }

    next(action)
  }),
  selectProcessingInventorySelectedItems: path([name, 'selectedUUIDs']),
  selectCurrentProcessingInventory: createSelector(
    'selectEntities',
    'selectProcessingInventory',
    'selectProcessingInventorySelectedItems',
    (entities, list, selectedItems) => {
      if (selectedItems?.length) {
        return into(
          [],
          compose(map(uuid => entities[uuid]), filter(Boolean)),
          selectedItems
        )
      }
      return into(
        [],
        compose(map(entry => entities[entry.uuid]), filter(Boolean)),
        list?.results ?? EMPTY_ARRAY
      )
    }
  ),
  doProcessingInventoryClearSelectedItems: () => ({ type: CLEAR_SELECTED_ITEMS }),
  /**
   * Set the selected items for drying/processing flows
   * @param {string[]} payload An array of uuids
   */
  doProcessingInventorySetSelectedItems: payload => ({ type: SET_SELECTED_ITEMS, payload }),
  persistActions: [...initialBundle.persistActions, SET_SELECTED_ITEMS, CLEAR_SELECTED_ITEMS]
}
