import { inflect } from 'inflection'

const MAX_ROOMS_DISPLAYED = 3
export const getControllerLocationLabel = (controller, entities) => {
  if (!['irrigationControllerPorts', 'rooms', 'zones'].every(e => e in entities)) return ''
  const zoneCountsByRoomName = controller.ports?.reduce((zoneCounts, port) => {
    const portEntity = Number.isInteger(port) ? entities.irrigationControllerPorts[port] : port
    const zones = portEntity?.zones
    if (!zones?.length) return zoneCounts
    const zone = entities?.zones[zones[0]]
    const room = entities?.rooms[zone?.room]
    if (!room) return zoneCounts
    return { ...zoneCounts, [room?.name]: (zoneCounts[room?.name] ?? 0) + zones.length }
  }, {}) || {}

  const roomCount = Object.keys(zoneCountsByRoomName).length
  const totalZoneCount = Object.values(zoneCountsByRoomName).reduce((sum, count) => sum + count, 0)

  if (roomCount === 0) return '(to be assigned)'

  return roomCount > MAX_ROOMS_DISPLAYED ? (
    `${roomCount} Rooms (${totalZoneCount} Zones)`
  ) : (
    Object.entries(zoneCountsByRoomName)
      .map(([roomName, zoneCount]) => `${roomName} (${zoneCount} ${inflect('Zones', zoneCount)})`)
      .join(', ')
  )
}
