import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { FacilityCultivar as schema } from '~/src/Store/Schemas'

const name = 'facilityCultivars'

const initialBundle = createEntityBundle({
  name,
  apiConfig: { schema, returnRaw: true, url: 'cultivars' }
})

export default {
  ...initialBundle,
  selectChildCultivars: createSelector(
    'selectFacilityCultivars',
    facilityCultivars => Object.values(facilityCultivars).reduce((childrenMap, cultivar) => {
      // If not a child, return accumulator (childrenMap)
      if (!cultivar.parent) return childrenMap
      // Get children of this cultivar’s parent, defaulting to a new empty array
      const { [cultivar.parent]: children = [] } = childrenMap
      // add current cultivar’s id to parents children
      children.push(cultivar.id)
      // set children array as entry for parent
      childrenMap[cultivar.parent] = children
      // return accumulator (childrenMap)
      return childrenMap
      // the empty object here is the initial value for childrenMap
    }, {})
  ),
}
