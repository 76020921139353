import { createSelector } from 'redux-bundler'

import { humanize } from 'inflection'

// import config from '~/src/App/config'
import createEntityBundle from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Membership as schema } from '~/src/Store/Schemas'

import { prepareData } from './shape'

const name = 'memberships'
const logger = createLogger(name)

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData,
    schema,
    snackbar: ({ status, dispatch, action }) => {
      if (action === 'fetch') return
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Account ${humanize(action)} ${status}ed`],
      })
    }
  },
})
const bundle = {
  ...initialBundle,
  selectFacilityMemberships: createSelector(
    'selectCurrentFacility',
    'selectMemberships',
    (currentFacility, allMemberships) => {
      const { id: facilityId } = currentFacility ?? EMPTY_OBJECT
      if (!facilityId) return EMPTY_OBJECT

      return Object.values(allMemberships).reduce((acc, membership) => (
        membership.facility === facilityId ? { ...acc, [membership.id]: membership } : acc
      ), EMPTY_OBJECT)
    }
  ),
  doSetPhoneAsDefault: id => async ({ store, apiFetch }) => {
    try {
      await apiFetch(
        `/phones/${id}/setAsDefault/`,
        null,
        { method: 'POST' },
      )
      store.doAddSnackbarMessage('Successfuly set phone as default.')
      await store.doFetchMe()
    } catch (error) {
      store.doAddSnackbarMessage('Failed to set phone as default.')
    }
  },
  doResendPhoneCode: id => async ({ store, apiFetch }) => {
    try {
      await apiFetch(
        `/phones/${id}/resendCode/`,
        null,
        { method: 'GET' },
      )
      await store.doFetchMe()
    } catch (error) {
      store.doAddSnackbarMessage('Failed to resend confirmation code.')
    }
  },
  doCheckConfirmationCode: payload => async ({ store, apiFetch }) => {
    try {
      await apiFetch(
        `/phones/${payload.id}/confirm/`,
        payload.code,
        { method: 'POST' },
      )
      await store.doFetchMe()
      return {
        status: 200,
        message: 'Success'
      }
    } catch (error) {
      store.doAddSnackbarMessage('Incorrect Confirmation Code')
      return {
        status: 400,
        message: 'Failed'
      }
    }
  },
}

export default bundle
