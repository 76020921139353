import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useConnect } from 'redux-bundler-hook'

import { minutes, seconds } from 'milliseconds'

import createLogger from '~/src/Lib/Logging'
import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'
import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'

const logger = createLogger('Room/hooks')

const STALENESS_MINUTES = minutes(5)
const WAIT_BETWEEN_FETCHES = seconds(5)

const haveRoomEntity = (roomId, room) => roomId === room?.id && room?.payloadType === 'entity'
const isStale = room => {
  if (!room?.fetchedAt) {
    return true
  }
  const now = Date.now()
  const fetchedAt = typeof room.fetchedAt === 'number'
    ? room.fetchedAt
    : 0

  const diff = now - fetchedAt
  return diff > 0 && diff >= STALENESS_MINUTES
}

export const useRoomEntity = roomId => {
  const {
    rooms,
    doRoomFetch,
  } = useConnect(
    'selectRooms',
    'doRoomFetch',
  )
  const { [roomId]: entity } = rooms
  const [room, setRoom] = useState(() => (
    haveRoomEntity(roomId, entity) ? entity : null
  ))
  const lastTry = useRef(0)

  useEffect(() => {
    const shouldFetch = (roomId && (
      !haveRoomEntity(roomId, entity) || isStale(entity) || !lastTry.current
    ))
    const now = Date.now()
    const shouldWait = now - WAIT_BETWEEN_FETCHES < lastTry.current

    if (shouldFetch && !entity?.inflight && !shouldWait) {
      lastTry.current = now
      doRoomFetch(roomId)
      return
    } if (shouldWait) {
      console.warn('trying to refetch too often', { roomId, storeRoom: entity, now })
    }
    setRoom(entity)
  }, [doRoomFetch, entity, roomId])

  return room
}

export const useRoomWithHarvests = roomId => {
  const {
    doRoomFetchV2,
    room: entity,
    currentRoomHarvests,
    roomCultivarsByHarvest,
    lastHarvest,
    nextHarvest
  } = useConcurrentConnect(
    'doRoomFetchV2',
    'selectRoom',
    'selectCurrentRoomHarvests',
    'selectRoomCultivarsByHarvest',
    'selectLastHarvest',
    'selectNextHarvest'
  )

  const lastTry = useRef(0)

  const shouldFetch = (roomId && (
    !haveRoomEntity(roomId, entity) || isStale(entity) || !lastTry.current
  ))
  const now = Date.now()
  const shouldWait = now - WAIT_BETWEEN_FETCHES < lastTry.current

  useEffect(() => {
    if (shouldFetch && !entity.inflight && !shouldWait) {
      lastTry.current = now
      doRoomFetchV2(roomId)
    } if (shouldWait) {
      console.warn('trying to refetch too often', { roomId, storeRoom: entity, now })
    }
  }, [doRoomFetchV2, entity, now, roomId, shouldFetch, shouldWait])

  return useMemo(() => {
    const currentHarvests = currentRoomHarvests.map(harvest => (
      {
        ...harvest,
        cultivars: roomCultivarsByHarvest[harvest.id] ?? EMPTY_ARRAY
      }
    ))

    return { ...entity, lastHarvest, nextHarvest, currentHarvests }
  }, [currentRoomHarvests, entity, lastHarvest, nextHarvest, roomCultivarsByHarvest])
}

export const useSetRoomCardHarvestsParams = roomId => {
  const {
    doRoomHarvestsSetParams,
    doRoomCultivarsSetParams
  } = useConnect('doRoomHarvestsSetParams', 'doRoomCultivarsSetParams')
  // we do this to have an immutable date string

  useEffect(() => {
    const today = getDateTime('now').toISODate()
    const params = { room: roomId, start: today, end: today }
    doRoomHarvestsSetParams(params)
    doRoomCultivarsSetParams(params)
  }, [doRoomCultivarsSetParams, doRoomHarvestsSetParams, roomId])
}
