import { SvgIcon } from '@mui/material'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

export const path = `
  M16.66 2.39c.337.438.255 1.066-.183 1.402-.278.214-.386.393-.432.522-.048.132-.063.299-.018
  .537.1.517.417 1.1.85 1.895l.068.122c.415.763.941 1.745 1.039 2.768.051.542-.014 1.11-.27
  1.671-.254.557-.67 1.05-1.237 1.485-.437.337-1.065.256-1.402-.182-.337-.438-.255-1.065.182
  -1.402.364-.28.545-.53.638-.732.09-.197.12-.406.098-.65-.053-.55-.357-1.178-.805-2.001l-.11
  -.202c-.382-.695-.853-1.553-1.015-2.395-.095-.497-.098-1.044.101-1.594.2-.553.574-1.027 1.093
  -1.426.438-.337 1.066-.256 1.403.182zM20.66 2.39c.337.438.255 1.066-.183 1.402-.278.214-.386
  .393-.432.522-.048.132-.063.299-.018.537.1.517.417 1.1.85 1.895l.068.122c.415.763.941 1.745
  1.039 2.768.051.542-.014 1.11-.27 1.671-.254.557-.67 1.05-1.237 1.485-.437.337-1.065.256-1.402
  -.182-.337-.438-.255-1.065.182-1.402.364-.28.545-.53.638-.732.09-.197.12-.406.098-.65-.053-.55
  -.357-1.178-.805-2.001l-.11-.202c-.382-.695-.853-1.553-1.015-2.395-.095-.497-.098-1.044.101
  -1.594.2-.553.574-1.027 1.093-1.426.438-.337 1.066-.256 1.403.182zM7.21 21.742c-.135.297-.484
  .429-.78.29-.295-.137-.05-.573.083-.87l.006-.013.023-.056c.021-.052.052-.131.089-.235.072-.207
  .166-.51.247-.883.072-.332.124-.917.151-1.334-.865.383-1.183.567-1.442.775-.258.208-.765.595
  -1.107.253-.341-.342-.341-.896 0-1.237.145-.145.31-.278.484-.399-1.057-.193-2.165-.567-3.088
  -1.26-.098-.073-.145-.198-.119-.318.026-.12.12-.214.24-.24.931-.208 1.986.01 2.92.334.229.08
  .453.166.67.257-1.35-1.193-2.577-2.71-3.307-4.462-.047-.113-.025-.242.057-.333.083-.09.21-.125
  .326-.089 1.403.432 2.654 1.564 3.545 2.72.202.26.386.526.552.787-.303-1.859-.408-3.734.707
  -6.242.053-.119.173-.193.302-.187.13.007.243.092.284.215.247.74.58 2.161.674 3.713.046.773.034
  1.591-.082 2.38 1.066-1.48 2.296-2.818 3.9-3.62.12-.06.267-.036.363.06.096.095.12.242.059.363
  -1.013 2.026-1.696 3.11-2.466 3.875-.154.152-.31.291-.471.422.936-.185 1.93-.195 2.789.208.103
  .048.172.148.18.261.009.114-.045.223-.14.286-.638.426-1.697.64-2.703.746l-.19.019c.111.078.215
  .162.304.25.341.342.341.896 0 1.238-.342.342-.896.342-1.238 0-.295-.295-.801-.547-1.178-.707
  -.025.55-.108 1.184-.199 1.604-.093.428-.201.779-.287 1.025-.043.123-.081.221-.109.29l-.034.08
  -.01.022-.003.008v.002l-.002.002z
`

const Icon = props => {
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { vpd: dataType = {} } = dataTypes
  const { color = 'olivedrab' } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path d={path} fill={fill} fillRule="evenodd" clipRule="evenodd" />
    </SvgIcon>
  )
}

export default Icon
