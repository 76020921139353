import { createSelector } from 'redux-bundler'

import { camelize, classify } from 'inflection'

import createLogger from '~/src/Lib/Logging'
import { convertWeight, EMPTY_ARRAY, EMPTY_OBJECT, throttle } from '~/src/Lib/Utils'

const logger = createLogger('Store/scales')
const throttledDebug = throttle(logger.debug, 1000)

const defaultState = {
  listenerScales: new Map(),
  scales: new Map(),
  lastWeight: null,
}

const ACTIONS = [{
  type: 'SCALE_CONNECT',
  handler: (state, action) => {
    const { payload } = action
    const { scales } = state

    scales.set(payload.scale, payload)

    return {
      ...state,
      scales: new Map(scales)
    }
  },
}, {
  type: 'SCALE_DISCONNECT',
  handler: (state, action) => {
    const { payload } = action
    const { scales } = state

    scales.delete(payload.scale)

    return {
      ...state,
      scales: new Map(scales)
    }
  },
}, {
  type: 'SCALE_LISTEN',
  handler: (state, action) => {
    const { payload } = action
    const { scale: listenerScale, listenerRef } = payload
    const { listenerScales, scales } = state

    listenerScales.set(listenerRef, listenerScale)
    const scale = scales.get(listenerScale)
    scale.listeners.add(listenerRef)

    return {
      ...state,
      listenerScales: new Map(listenerScales),
    }
  },
}, {
  type: 'SCALE_UNLISTEN',
  handler: (state, action) => {
    const { payload } = action
    const { listenerScales, scales } = state
    const listenerScale = listenerScales.get(payload)
    const scale = scales.get(listenerScale)

    if (!scale) {
      listenerScales.delete(payload)
      return {
        ...state,
        listenerScales: new Map(listenerScales)
      }
    }

    scale.listeners.delete(payload)
    listenerScales.delete(payload)

    return {
      ...state,
      listenerScales: new Map(listenerScales)
    }
  },
}, {
  type: 'SCALE_WEIGHT_RECEIVED',
  handler: (state, action) => {
    const { payload } = action
    const { weight, scale: weightScale } = payload
    const { scales } = state
    const scale = scales.get(weightScale)
    if (scale) {
      scale.listeners.forEach(listenerRef => {
        const { current: listeners = EMPTY_OBJECT } = listenerRef
        const { onWeightReceived } = listeners
        if (onWeightReceived?.call) {
          onWeightReceived(weight)
        }
      })
    } else {
      logger.warn('received weight for an unknown scale:', { weightScale, scales })
    }

    return {
      ...state,
      lastWeight: { scale: weightScale, weight, ts: new Date() }
    }
  }
}]

const TYPES = ACTIONS.reduce((types, { type }) => ({
  ...types,
  [camelize(type.toLowerCase(), true)]: type
}), {})

const HANDLERS = ACTIONS.reduce((types, { type, handler }) => ({
  ...types,
  [type]: handler
}), {})

const VALIDATORS = {
  [TYPES.scaleWeightReceived]: ({ weight }) => {
    if (!weight) return false
    const { value, unitSymbol, unstable } = weight
    const gramWeight = convertWeight(value || 0, unitSymbol ?? 'g', 'g')
    if (gramWeight < 25 || (unstable && gramWeight < 50)) {
      return false
    }
    return true
  }
}

export default {
  name: 'scales',
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    if (action.type in HANDLERS) {
      const { [action.type]: handler } = HANDLERS
      return handler(state, action)
    }
    return state
  },
  ...Object.entries(TYPES).reduce((actionCreators, [name, type]) => {
    const actionCreator = `do${classify(name)}`
    actionCreators[actionCreator] = payload => ({ dispatch }) => {
      if (type in VALIDATORS && !VALIDATORS[type](payload)) {
        throttledDebug('invalid call to', actionCreator, '\npayload:', payload)
        return
      }
      dispatch({ type, payload })
    }
    return actionCreators
  }, {}),
  selectListenerScales: state => state.scales.listenerScales,
  selectScales: createSelector(
    state => state.scales.scales,
    scales => {
      if (!scales || !scales.size) return EMPTY_ARRAY
      logger.debug('re-computing scale options')
      return [...scales].map(([, { scale }]) => {
        if (!scale) {
          logger.warn('no scale!', scales)
          return null
        }
        return { label: scale.name, value: scale }
      }).filter(Boolean)
    }
  ),
}
