import memoizeOne from 'memoize-one'
import {
  always,
  either,
  equals,
  map,
  omit,
  partition,
  path,
  pick,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { minutes, seconds } from 'milliseconds'

import harvestUrls from '~/src/Harvest/bundle/urls'
import { getAsyncActionIdentifiers } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import {
  capitalizeInitial,
  compileTemplate,
  defer,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  shallowEquals,
} from '~/src/Lib/Utils'
import { isRoomDashboardRoute } from '~/src/Room/bundle/urls'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { createAppIsReadySelector } from '~/src/Store/utils'
import { buildWindData, buildWindGraphs } from '~/src/Weather/utils'

import { getChartLimit, getDefaultState, getFetchReducer } from './utils'

export { default as annotations, markAnnotationsOutdatedWrapper } from './annotations'
export * from './utils'

export const EMPTY_CHART = {
  chartId: null,
  data: { data: EMPTY_OBJECT, graphs: EMPTY_ARRAY, range: EMPTY_OBJECT },
  fetch: EMPTY_OBJECT,
  inflight: undefined,
  params: EMPTY_OBJECT,
  stale: undefined,
}

// import mockChartData from '~/src/Store/fixtures/mockChartData.json'
const logger = createLogger('bundles/chart')
const mockChartData = null

const fetch = getAsyncActionIdentifiers('fetch', 'chart')
const fetchReducer = getFetchReducer(fetch.types)

export const CHART_CLEAR = 'CHART_CLEAR'
export const CHART_CLEAR_ALL = 'CHART_CLEAR_ALL'
export const CHART_FETCH = fetch.types
export const CHART_MARK_OUTDATED = 'CHART_MARK_OUTDATED'
export const CHART_UPDATE = 'CHART_UPDATE'
const FACILITY_DATA_EXPORT_FAILED = 'FACILITY_DATA_EXPORT_FAILED'

const limitOmitter = omit(['limit'])
const chartCompiler = memoizeOne((current, chartRoot) => Object.entries(chartRoot).reduce((compiledChart, [key, data]) => {
  if (typeof data !== 'object' || data == null) return compiledChart
  compiledChart[key] = data[current]
  return compiledChart
}, {}), (left, right) => shallowEquals(left, right, 3))

const graphDataType = graph => graph.id.split(':').shift()
const defaultState = getDefaultState()
export default {
  name: 'chart',
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    switch (action.type) {
      case CHART_UPDATE: {
        const { chartId, params } = action.payload
        return {
          ...state,
          current: chartId,
          params: {
            ...state.params,
            [chartId]: params,
          },
          stale: {
            ...state.stale,
            [chartId]: 'chart',
          },
        }
      }
      case CHART_CLEAR_ALL: {
        return getDefaultState()
      }
      case CHART_CLEAR: {
        const { payload: chartId } = action
        if (!chartId) return state
        return map(entry => (
          typeof entry === 'object' && chartId in entry ? omit([chartId], entry) : entry
        ), state)
      }
      case CHART_MARK_OUTDATED: {
        const { chartId } = action.payload
        return {
          ...state,
          current: chartId,
          stale: {
            ...state.stale,
            [chartId]: true,
          },
        }
      }
      default:
        return fetchReducer(state, action)
    }
  },
  selectChartRoot: state => state.chart,
  ...Object.keys(defaultState).reduce((selectors, key) => {
    selectors[`selectChart${capitalizeInitial(key)}`] = either(path(['chart', key]), always(defaultState[key]))
    return selectors
  }, {}),
  selectCharts: createSelector('selectChartRoot', chartRoot => {
    const { params = EMPTY_OBJECT } = chartRoot
    return Object.keys(params).reduce((charts, chartId) => {
      if (chartId) {
        charts[chartId] = chartCompiler(chartId, chartRoot)
      }
      return charts
    }, {})
  }),
  selectCurrentChart: createSelector(
    'selectRoomDashboardContext',
    'selectCharts',
    ({ chartId: current }, { [current]: chart = EMPTY_OBJECT }) => {
      if (!chart.data) {
        if (!chart.inflight) return EMPTY_CHART
        return { ...EMPTY_CHART, ...chart }
      }

      const {
        data = EMPTY_OBJECT,
        graphs: originalGraphs = EMPTY_ARRAY,
        manualGraphs = EMPTY_OBJECT
      } = chart.data
      const [windGraphsRaw, otherGraphs] = partition(({ id }) => id.startsWith('wind'), originalGraphs)
      const windGraphs = buildWindGraphs(windGraphsRaw)
      const windKeys = Object.keys(data).filter(key => key.startsWith('wind'))

      const windData = buildWindData(pick(windKeys, data), windGraphs)
      const graphs = [...otherGraphs, ...windGraphs]
      const graphZones = Array.from(new Set([
        ...graphs.map(({ id, zone }) => {
          let zoneIdentifier = typeof zone === 'number' ? zone : null
          if (zoneIdentifier == null && typeof id === 'string') {
            zoneIdentifier = id.split(':').slice(-2).join('_')
          }
          if (String(zoneIdentifier).startsWith('room')) {
            zoneIdentifier = 'room'
          }
          return zoneIdentifier
        }).filter(Boolean),
        ...Object.values(manualGraphs).filter(g => typeof g.zone === 'number').map(g => g.zone)
      ]))
      const dataTypes = Array.from(new Set(
        graphs.map(graphDataType)
          .concat(Object.values(manualGraphs).map(graphDataType))
      ))

      return {
        ...chart,
        data: {
          ...chart.data,
          data: { ...omit(windKeys, data), ...windData },
          dataTypes,
          graphs,
        },
        graphZones
      }
    }
  ),
  doChartFetch: (chartId, rawParams, meta = EMPTY_OBJECT) => async ({
    dispatch,
    apiFetch,
    store,
  }) => {
    if (!chartId || chartId.includes('NaN')) {
      console.warn('no chartId or invalid chartId:', { chartId, rawParams })
      return false
    }

    if (equals(rawParams, EMPTY_OBJECT)) {
      console.warn('invalid params:', chartId, rawParams)
      store.doChartClear(chartId)
      return false
    }

    const isRoomChart = chartId.startsWith('room')
    const [urlBase, idRaw] = chartId.split(/[-_]/g)
    if (isRoomChart && !meta.force) {
      const { currentRoomId, harvest = EMPTY_OBJECT } = store.select([
        'selectCurrentRoomId',
        'selectHarvest',
      ])
      const roomId = Number(idRaw)
      const rooms = [
        currentRoomId,
        harvest.room,
        harvest.vegRoom,
        harvest.flowerRoom,
        harvest.dryRoom
      ]

      // If we're trying to fetch a chart for a room that's not active, don't.
      if ((isRoomChart && rooms.every(id => id !== roomId))) {
        console.warn('trying to fetch a chart for a room that is not active', {
          chartId,
          possibleRoomIds: rooms.filter(Boolean),
          params: rawParams,
        })
        dispatch({ actionCreator: 'doChartClear', args: [chartId] })
        return false
      }
    }
    const params = {
      ...rawParams,
      limit: getChartLimit(rawParams, chartId),
    }
    const chartRoot = store.selectChartRoot()
    const {
      inflight: { [chartId]: inflight },
      params: { [chartId]: prevParams },
      data: { [chartId]: prevData },
      stale: { [chartId]: markedStale },
      fetch: { [chartId]: prevFetch },
    } = chartRoot
    const lastFetched = inflight ?? prevData?.fetched ?? 0
    const equalParams = equals(params, prevParams)
    const isStale = markedStale || lastFetched < Date.now() - minutes(1)
    const { lastFailAt = 0, failCount = 0 } = prevFetch ?? EMPTY_OBJECT

    if (lastFailAt > Date.now() - seconds(2 ** failCount) && equalParams) {
      console.warn('Attempted to refetch chart before backoff time:', { now: new Date(), backoff: new Date(lastFailAt + seconds(2 ** failCount)) })
      return false
    }
    // Ensure that we're not double-fetching the same params
    if (inflight || (!isStale && equalParams)) {
      console.warn('double-fetch prevention aborted fetch:', { markedStale, lastFetched, prevParams, params })
      dispatch({ type: fetch.types.start, payload: { chartId, params } })
      defer(() => dispatch({ type: fetch.types.succeed, payload: { chartId, data: prevData } }), defer.priorities.lowest)
      return false
    }

    dispatch({ type: fetch.types.start, payload: { chartId, params } })
    let data = null
    const url = `/${urlBase}/${idRaw}/chart/`

    try {
      data = mockChartData || (await apiFetch(url, params))
      const {
        bounds,
        graphs: graphsData,
        manualGraphs: manualGraphsData,
        unitBounds,
        ...dataRest
      } = data
      const graphs = graphsData?.length ? graphsData : EMPTY_ARRAY
      const manualGraphs = Object.keys(manualGraphsData ?? EMPTY_OBJECT).length
        ? manualGraphsData
        : EMPTY_OBJECT
      const graphBounds = {}

      dispatch({
        type: fetch.types.succeed,
        payload: {
          chartId,
          data: {
            ...dataRest,
            bounds: {
              dataType: Object.keys(bounds.max).reduce((dataTypeBounds, dataTypeKey) => ({
                ...dataTypeBounds,
                [dataTypeKey]: { max: bounds.max[dataTypeKey], min: bounds.min[dataTypeKey] }
              }), {}),
              graph: graphBounds,
              unit: unitBounds,
            },
            graphs: graphs !== EMPTY_ARRAY
              ? graphs.map(graph => {
                graphBounds[graph.id] = graph.bounds
                if (!graph.balloonTemplate) return graph
                return {
                  ...graph,
                  balloonTemplate: compileTemplate(`${graph.title}: ${graph.name} ${graph.template}`),
                  type: 'sensor',
                }
              })
              : EMPTY_ARRAY,
            manualGraphs: manualGraphs !== EMPTY_OBJECT
              ? Object.entries(manualGraphs).reduce((acc, [id, { title, name, template, ...rest }]) => {
                graphBounds[id] = rest.bounds

                acc[id] = {
                  ...rest,
                  balloonTemplate: compileTemplate(`${title}: ${name} ${template}`),
                  manual: true,
                  name,
                  template,
                  title,
                  type: 'manual'
                }

                return acc
              }, {})
              : EMPTY_OBJECT
          },
        },
      })
    } catch (error) {
      dispatch({ type: fetch.types.fail, error, payload: chartId })
      return null
    }
    return data
  },
  doChartClear: (chartId = null) => ({ type: CHART_CLEAR, payload: chartId }),
  doChartClearAll: () => ({ type: CHART_CLEAR_ALL }),
  doMarkChartOutdated: chartId => ({ dispatch, store }) => {
    if (!chartId) {
      console.warn('doMarkChartOutdated did not receive a chartId')
      return
    }
    const chart = store.selectChartRoot()
    const { inflight: { [chartId]: inflight } } = chart
    if (inflight && Date.now() - inflight < 50) {
      return
    }
    dispatch({
      type: CHART_MARK_OUTDATED,
      payload: { chartId },
    })
  },
  doChartExportData: ({ start: startParam, end: endParam }) => async ({ dispatch, apiFetch, store }) => {
    let start = startParam
    let end = endParam
    const roomId = store.selectCurrentRoomId()
    if (!start && !end) {
      const roomChart = store.selectChartRoot().params[`rooms_${roomId}`]
      if (roomChart) {
        ({ start, end } = roomChart)
      }
    }
    const user = store.selectMe()
    const { id, email } = user

    const payload = { roomId, userId: id }
    if (start) payload.startDate = start.toUTC()
    if (end) payload.endDate = end.toUTC()

    try {
      const success = await apiFetch('/export/dashboard/', payload, { method: 'POST' })
      if (success) {
        store.doAddSnackbarMessage(`An email with your requested data will be sent to ${email} shortly.`)
      } else {
        store.doAddSnackbarMessage('Failed to export chart data.')
      }
    } catch (error) {
      store.doAddSnackbarMessage('Failed to export chart data.')
      dispatch({ type: FACILITY_DATA_EXPORT_FAILED, payload: error })
    }
  },
  reactUpdateStaleChart: createSelector(
    'selectChartRoot',
    'selectRouteInfo',
    ({ stale, inflight, params }, routeInfo) => {
      if (!isRoomDashboardRoute(routeInfo) && !routeInfo.pattern === harvestUrls.growlog) {
        return undefined
      }
      const needsUpdate = Object.keys(stale).find(key => !inflight[key])
      if (!needsUpdate || typeof needsUpdate !== 'string') return undefined
      const chartParams = params[needsUpdate] ?? EMPTY_OBJECT
      if (shallowEquals(EMPTY_OBJECT, chartParams)) {
        return undefined
      }
      const args = [needsUpdate, limitOmitter(chartParams)]
      logger.debug('chart auto-update running', ...args, chartParams)
      return { actionCreator: 'doChartFetch', args }
    }
  ),
  reactChartFetch: createAppIsReadySelector({
    dependencies: [
      'selectRoomDashboardChartParams',
      'selectChartFetch',
      'selectChartParams'
    ],
    resultFn: ([chartId, expectedChartParams], chartFetch, chartParams) => {
      // If we don't have a chartId or expectedChartParams, we can't fetch
      if (!chartId || !expectedChartParams) return null
      // If we're already fetching, don't fetch again
      const { [chartId]: prevFetch } = chartFetch
      if (prevFetch && prevFetch.loading) return null
      // If we don't have previous params or current params don't match previous params, fetch
      const { [chartId]: prevParams } = chartParams
      if (!prevParams || !shallowEquals(expectedChartParams, limitOmitter(prevParams))) {
        return {
          actionCreator: 'doChartFetch',
          args: [chartId, expectedChartParams],
          priority: REACTOR_PRIORITIES.HIGH
        }
      }
      return null
    }
  }),
  persistActions: [
    Object.values(fetch.types),
    CHART_UPDATE,
    CHART_CLEAR,
    CHART_CLEAR_ALL,
    CHART_MARK_OUTDATED
  ],
}
