import PropTypes from 'prop-types'
import {
  compose,
  evolve,
  pick,
  trim,
} from 'ramda'

import { toISOOrNil, validIdOrNil } from '~/src/Lib/Data'
import { datePropType } from '~/src/Lib/PropTypes'

export const attachmentShapeDef = {
  filename: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  name: PropTypes.string,
  url: PropTypes.string.isRequired,
}
export const attachmentShape = PropTypes.shape(attachmentShapeDef)

const shapeConfig = {
  id: PropTypes.number,
  category: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionMentions: PropTypes.arrayOf(PropTypes.number),
  facility: PropTypes.number,
  room: PropTypes.number,
  zone: PropTypes.number,
  ts: datePropType,
  attachment: PropTypes.arrayOf(PropTypes.shape({
    filename: PropTypes.string,
    handle: PropTypes.string,
    mimetype: PropTypes.string,
    originalPath: PropTypes.string,
    size: PropTypes.number,
    source: PropTypes.string,
    status: PropTypes.string,
    uploadId: PropTypes.string,
    url: PropTypes.string,
  })),
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  category: validIdOrNil,
  facility: validIdOrNil,
  room: validIdOrNil,
  zone: validIdOrNil,
  title: trim,
  description: trim,
  ts: toISOOrNil,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const clean = cleaner(raw)
  return clean
}
