import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

const STALE_AFTER = ms.minutes(3)

const baseType = 'LATEST_READINGS'
const actions = {
  INITIALIZED: `${baseType}_INITIALIZED`,
  VISIBLE: `${baseType}_SET_VISIBLE`,
  STARTED: `${baseType}_FETCH_STARTED`,
  FINISHED: `${baseType}_FETCH_FINISHED`,
  FAILED: `${baseType}_FETCH_FAILED`,
}

const initialRoomState = {
  data: null,
  errorTimes: [],
  errorType: null,
  failedPermanently: false,
  isLoading: false,
  isOutdated: false,
  isVisible: false,
  lastSuccess: null,
}

const mapStateObject = (root, callback) => Object.entries(root)
  .reduce((roomData, [roomId, roomState]) => ({ ...roomData, [roomId]: callback(roomState, Number(roomId)) }), {})

/**
 * Follows a simplified `createAsyncResourceBundle` pattern, partitioned per facility room
 */
export default {
  name: 'latestReadings',
  reducer: (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    const { type, room, payload, error } = action

    if (type === actions.INITIALIZED) {
      const roomIds = payload
      if (!Array.isArray(roomIds)) return state
      return roomIds.reduce((s, roomId) => ({
        ...s,
        [roomId]: {
          ...initialRoomState,
          // IntersectionObserver callback may have already updated visibility state
          isVisible: Boolean(state[roomId]?.isVisible),
        },
      }), state)
    }

    if (type === actions.VISIBLE) {
      const roomVisibilityUpdates = payload
      return Object.entries(roomVisibilityUpdates).reduce((s, [roomId, isVisible]) => ({
        ...s,
        [roomId]: { ...state[roomId], isVisible },
      }), state)
    }

    if (type === actions.STARTED) {
      return {
        ...state,
        [room]: {
          ...state[room],
          isLoading: true,
        },
      }
    }

    if (type === actions.FINISHED) {
      return {
        ...state,
        [room]: {
          ...state[room],
          data: payload,
          errorTimes: [],
          errorType: null,
          failedPermanently: false,
          isLoading: false,
          isOutdated: false,
          lastSuccess: Date.now(),
        },
      }
    }

    if (type === actions.FAILED) {
      const errorTimes = state[room].errorTimes.concat([Date.now()])
      return {
        ...state,
        [room]: {
          ...state[room],
          errorTimes,
          errorType: error?.message ?? error,
          failedPermanently: Boolean(error?.permanent || errorTimes.length > 5),
          isLoading: false,
        },
      }
    }

    return state
  },
  selectLatestReadingsRaw: state => state.latestReadings,
  selectLatestReadings: createSelector('selectLatestReadingsRaw', root => mapStateObject(root, raw => raw.data)),
  selectLatestReadingsLoading: createSelector('selectLatestReadingsRaw', root => mapStateObject(root, raw => raw.isLoading)),
  selectLatestReadingsVisible: createSelector('selectLatestReadingsRaw', root => mapStateObject(root, raw => raw.isVisible)),
  selectLatestReadingsStale: createSelector('selectLatestReadingsRaw', 'selectAppTime', (root, appTime) => mapStateObject(root, raw => {
    if (raw.isOutdated) {
      return true
    } if (!raw.lastSuccess) {
      return false
    }
    return appTime - raw.lastSuccess > STALE_AFTER
  })),
  selectLatestReadingsShouldUpdate: createSelector(
    'selectIsOnline',
    'selectLatestReadingsRaw',
    'selectLatestReadingsStale',
    (isOnline, root, stale) => mapStateObject(root, (raw, id) => {
      if (!isOnline || raw.isLoading || raw.failedPermanently) {
        return false
      }
      if (raw.data === null) {
        return true
      }
      return stale[id]
    }),
  ),
  doFetchLatestReadings: room => async ({ apiFetch, dispatch }) => {
    dispatch({ type: actions.STARTED, room })
    return apiFetch(`/kiosk/${room}/readings/`).then(response => {
      dispatch({ type: actions.FINISHED, room, payload: response })
    }).catch(error => {
      dispatch({ type: actions.FAILED, room, error })
    })
  },
  doFetchLatestReadingsBulk: roomIds => async ({ store }) => {
    const loading = store.selectLatestReadingsLoading()
    roomIds.filter(id => !loading[id]).forEach(id => store.doFetchLatestReadings(id))
  },
  doInitializeLatestReadings: roomsToAdd => ({ dispatch }) => {
    dispatch({ type: actions.INITIALIZED, payload: roomsToAdd })
  },
  doLatestReadingsUpdateVisibility: updates => async ({ dispatch }) => {
    dispatch({ type: actions.VISIBLE, payload: updates })
  },
  reactInitializeLatestReadings: createSelector(
    'selectLatestReadings',
    'selectRooms',
    (latestReadings, rooms) => {
      const unlisted = Object.keys(rooms).map(Number).filter(
        // IntersectionObserver callback may have already updated visibility state
        roomId => !(roomId in latestReadings) || latestReadings[roomId] === undefined
      )
      if (unlisted.length) {
        return { actionCreator: 'doInitializeLatestReadings', args: [unlisted] }
      }
      return undefined
    },
  ),
  reactFetchLatestReadings: createAppIsReadySelector({
    dependencies: [
      'selectLatestReadingsShouldUpdate',
      'selectLatestReadingsVisible',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, visible, routeInfo) => {
      if (routeInfo.pattern.startsWith('/dashboard')) {
        const toUpdate = Object.keys(visible).map(Number).filter(roomId => visible[roomId] && shouldUpdate[roomId])
        if (toUpdate.length) {
          return { actionCreator: 'doFetchLatestReadingsBulk', args: [toUpdate] }
        }
      }
      return undefined
    },
  }),
}
