import { SvgIcon } from '@mui/material'

export const path = `
  M8 20c0 .552-.448 1-1 1s-1-.448-1-1c0-2.641.986-4.529 2.335-6.05.287-.323.588-.628.896-.918l.101
  -.095c.322.267.645.525.96.777l.083.067c.18.143.357.286.532.427-.098.088-.195.176-.29.265l-.514
  .527h1.752c.728.633 1.376 1.278 1.892 2H8.8c-.047 0-.093-.005-.138-.015-.156.32-.288.656-.393
  1.015h6.077c.29.598.495 1.256.592 2H8zm5.59-8l.044-.036C15.821 10.15 18 7.93 18 4c0-.552-.448-1-1
  -1s-1 .448-1 1H8c0-.552-.448-1-1-1s-1 .448-1 1c0 4.482 2.844 6.756 5.293 8.715l.082.066C13.908
  14.807 16 16.532 16 20c0 .552.448 1 1 1s1-.448 1-1c0-3.95-2.209-6.185-4.41-8zM12 10.714l.362-.294
  c.561-.466 1.086-.93 1.553-1.421h-3.827c.565.593 1.215 1.149 1.912 1.715zm-3.337-3.7c.044-.01.09
  -.015.138-.015h6.4c.047 0 .093.005.138.015.156-.32.288-.657.392-1.015H8.27c.103.358.235.695.392
  1.015z
`

export default props => (
  <SvgIcon {...props}>
    <path data-iconid="aroya-icons-strain" d={path} clipRule="evenodd" fillRule="evenodd" />
  </SvgIcon>
)
