import i18n from 'i18n-literally'
import {
  both,
  complement,
  equals,
  filter,
  omit,
  prop,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createEntityBundle, {
  defaultFetchPrepareData,
  doEntitiesReceived,
  getActionType,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT, parseApiErrors } from '~/src/Lib/Utils'
import { EMPTY_CHART, selectChartIdForRoom } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Room as schema } from '~/src/Store/Schemas'

import { prepareData } from './shape'

const name = 'rooms'

const logger = createLogger(`${name}/bundle`)

const allowedDataTypes = dt => dt && dt.active && !dt.hidden

const {
  reducer,
  doRoomFetch: doRoomFetchOld,
  doRoomFetchV2: doRoomFetch,
  ...initialBundle
} = createEntityBundle({
  name,
  apiConfig: { prepareData, schema },
  scope,
  customActions: [
    {
      action: 'fetch_v2',
      name,
      schema,
      prepareData: defaultFetchPrepareData,
      handler: ({ apiFetch, payload }) => apiFetch(`/rooms/${payload.id}/v2/`, payload.params),
      async: true,
    }
  ]
})

const {
  doRoomSave,
  doRoomDelete,
} = initialBundle

const { types: saveTypes, createSnackbarAction: createSaveSnackbarAction } = doRoomSave
const { createSnackbarAction: createDeleteSnackbarAction } = doRoomDelete
const { types: availabilityFetchTypes } = getAsyncActionIdentifiers('fetch', 'room_availability')
const availabilityClear = getActionType('clear', 'room_availability')
const availabilityClearParams = getActionType('clear_params', 'room_availability')
const initialAvailability = { loading: EMPTY_OBJECT, data: EMPTY_OBJECT, params: EMPTY_OBJECT }

export default {
  ...initialBundle,
  reducer: reduceReducers(reducer, (state, action) => {
    switch (action.type) {
      case availabilityFetchTypes.start: {
        const { key, params } = action.payload
        return {
          ...state,
          availability: {
            ...state.availability,
            loading: {
              ...state.availability?.loading,
              [key]: true,
            },
            params: {
              ...state.availability?.params,
              [key]: params,
            },
          }
        }
      }
      case availabilityFetchTypes.fail:
        return {
          ...state,
          availability: {
            ...state.availability,
            loading: initialAvailability.loading,
            error: action.error,
          }
        }
      case availabilityFetchTypes.succeed: {
        const { key, data } = action.payload
        return {
          ...state,
          availability: {
            ...state.availability,
            loading: omit([key], state.availability.loading),
            data: {
              ...omit([key], state.availability?.data),
              [key]: data,
            },
          }
        }
      }
      case availabilityClear:
        return state.loading ? state : {
          ...state,
          availability: action.payload === 'ALL' ? initialAvailability : {
            ...state.availability,
            loading: initialAvailability.loading,
            data: omit([action.payload], state.availability.data),
            params: omit([action.payload], state.availability.params)
          }
        }
      case availabilityClearParams:
        return {
          ...state,
          availability: {
            ...state.availability,
            params: action.payload === 'ALL'
              ? EMPTY_OBJECT
              : omit([action.payload], state.availability.params)
          }
        }
      default:
        return 'availability' in state ? state : {
          ...state,
          availability: initialAvailability,
        }
    }
  }),
  doRoomDelete: payload => async ({ dispatch, store, ...kwargs }) => {
    const deleteResult = await doRoomDelete(payload, { snackbar: false })({
      ...kwargs,
      dispatch,
      store
    })
    if (deleteResult) {
      createDeleteSnackbarAction({ action: 'delete', dispatch, status: 'succeed', payload })
      return deleteResult
    }
    const id = payload?.id || payload
    const { [id]: room } = store.selectRooms()
    const getLocationTerm = store.selectFacilityLocationsTerm()
    const archiveResult = await store.doRoomUpdate({ id, archived: true }, { snackbar: false })
    if (archiveResult) {
      store.doAddSnackbarMessage(i18n`Successfully archived ${getLocationTerm(room)} "${room?.name}"`)
    } else {
      store.doAddSnackbarMessage(i18n`Failed to archive ${getLocationTerm(room)} "${room?.name}"`)
    }
    return archiveResult
  },
  doRoomFetch,
  doRoomFetchOld,
  doRoomSave,
  doRoomIrrigationSettingsSave: data => async ({ dispatch, apiFetch }) => {
    const {
      roomId,
      settings: { emitterFlowRateUnits, ...payload },
    } = data

    let result
    try {
      result = await apiFetch(`/rooms/${roomId}/irrigation_settings/`, {
        ...payload,
        ...emitterFlowRateUnits
      }, { method: 'PUT' })
      createSaveSnackbarAction({ dispatch, status: 'succeed', payload })
      const entities = { rooms: { [roomId]: { ...result } } }
      dispatch(doEntitiesReceived(entities, { replace: false }))
    } catch (err) {
      const error = parseApiErrors(err)
      dispatch({ type: saveTypes.fail, payload, error })
      createSaveSnackbarAction({ dispatch, status: 'fail', payload })
    }
    return result
  },
  doRoomAvailabilityFetch: (key, params) => async ({ apiFetch, dispatch, store }) => {
    const { data: allData, loading: allLoading, params: allParams } = store.selectRoomAvailability()
    const now = Date.now()

    const { [key]: oldParams } = allParams
    const { [key]: loading } = allLoading
    const { [key]: data } = allData
    if (
      loading
      || (
        equals(oldParams, params)
        && (!data || data?.fetched > now - minutes(2))
      )
    ) {
      return true
    }
    dispatch({ type: availabilityFetchTypes.start, payload: { key, params } })

    try {
      const results = await apiFetch('/availability/', params)
      dispatch({
        type: availabilityFetchTypes.succeed,
        payload: {
          key,
          data: results.filter(({ available, unavailable }) => {
            if (!unavailable.length && available[0] === null) return false
            return true
          }).reduce((acc, roomAvailability) => ({
            ...acc,
            [roomAvailability.id]: { ...roomAvailability, fetched: Date.now() },
          }), EMPTY_OBJECT)
        }
      })
      return true
    } catch (error) {
      dispatch({ type: availabilityFetchTypes.fail, error })
      return false
    }
  },
  doAvailabilitiesFetch: config => async ({ apiFetch, dispatch, store }) => {
    store.doRoomAvailabilityClear()
    const {
      data: allData,
      loading: allLoading,
      params: allParams,
    } = store.selectRoomAvailability()
    const now = Date.now()

    const toFetch = Object.entries(config).reduce((list, entry) => {
      const [key, params] = entry
      const { [key]: oldParams } = allParams
      const { [key]: loading } = allLoading
      const { [key]: data } = allData
      const recentlyFetched = oldParams && equals(oldParams, params) && (data && data?.fetched > now - minutes(2))
      if (loading || recentlyFetched) {
        return list
      }
      list.push(entry)
      return list
    }, [])

    if (!toFetch.length) {
      return true
    }

    toFetch.forEach(([key, params]) => dispatch({
      type: availabilityFetchTypes.start,
      payload: { key, params }
    }))

    try {
      const resultsList = await Promise.all(
        toFetch.map(
          ([key, params]) => apiFetch('/availability/', params).then(results => [key, results])
        )
      )
      resultsList.forEach(([key, results]) => {
        dispatch({
          type: availabilityFetchTypes.succeed,
          payload: {
            key,
            data: results.filter(({ available, unavailable }) => {
              if (!unavailable.length && available[0] === null) return false
              return true
            }).reduce((acc, roomAvailability) => ({
              ...acc,
              [roomAvailability.id]: { ...roomAvailability, fetched: Date.now() },
            }), EMPTY_OBJECT)
          }
        })
      })
      return true
    } catch (error) {
      dispatch({ type: availabilityFetchTypes.fail, error })
      return false
    }
  },
  doRoomAvailabilityClear: (payload = 'ALL') => ({ type: availabilityClear, payload }),
  doRoomAvailabilityClearParams: (payload = 'ALL') => ({ type: availabilityClearParams, payload }),
  selectActiveRooms: createSelector(
    'selectRooms',
    filter(complement(prop('archived'))),
  ),
  selectRoomChartId: createSelector(
    'selectRoom',
    both(prop('id'), selectChartIdForRoom)
  ),
  selectRoomChart: createSelector(
    'selectRoomChartId',
    'selectCharts',
    (chartId, charts) => {
      if (!chartId) return EMPTY_CHART
      if (!charts[chartId]) {
        return EMPTY_CHART
      }
      const { [chartId]: chart } = charts
      const {
        data = EMPTY_CHART.data,
        inflight,
        params = EMPTY_CHART.params,
        fetch = EMPTY_CHART.fetch,
        stale = false,
      } = chart
      const { graphs } = data
      const graphZones = graphs && graphs.reduce((acc, { id, zone }) => {
        let zoneIdentifier = zone != null ? zone : id.split(':').slice(-2).join('_')
        if (String(zoneIdentifier).startsWith('room')) {
          zoneIdentifier = 'room'
        }

        if (acc.includes(zoneIdentifier)) {
          return acc
        }
        return [...acc, zoneIdentifier]
      }, EMPTY_ARRAY)

      return { chartId, data, fetch, graphZones, inflight, params, stale }
    }
  ),
  selectRoomDevices: createSelector(
    'selectRoom',
    'selectDevices',
    (room, devices) => room?.devices?.map(id => devices[id]).filter(Boolean) ?? EMPTY_ARRAY
  ),
  selectRoomDefaultDataTypes: createSelector(
    'selectDataTypes',
    'selectModels',
    'selectRoomDevices',
    (dataTypes, models, devices) => {
      const roomSensorTypes = [
        ...new Set(
          devices.reduce(
            (acc, device) => {
              const { [device.modelKey]: model } = models
              if (!model || model.dataTypes?.length) return acc
              return [...acc, ...model.dataTypes]
            },
            EMPTY_ARRAY
          )
        ),
      ]
        .map(dt => dataTypes[dt])
        .filter(allowedDataTypes)
        .map(prop('key'))

      return roomSensorTypes
    }
  ),
  selectRoomDataTypes: createSelector(
    'selectRoomChart',
    'selectRoomDefaultDataTypes',
    (roomChart, roomDefaultDataTypes) => {
      if (roomChart?.data && Array.isArray(roomChart.data.dataTypes)) {
        return roomChart.data.dataTypes
      }
      return roomDefaultDataTypes
    }
  ),
  selectRoomAvailability: createSelector('selectRoomsRoot', rooms => rooms.availability ?? EMPTY_OBJECT),
}
