import getGlobal from '~/src/Lib/getGlobal'
import safeStorage from '~/src/Lib/safeStorage'

const proxy = new Proxy(getGlobal().config ?? {}, {
  get: (_, prop) => {
    const { config } = getGlobal()
    const value = config?.[prop]
    if (prop === 'APP' && !value) {
      return 'AROYA'
    }
    if (prop === 'ENVIRONMENT' && config && config.ENVIRONMENT !== 'production') {
      return safeStorage().testEnvironment ?? config.ENVIRONMENT
    }
    return value
  },
  ownKeys: () => Object.keys(getGlobal().config),
})
export default proxy
