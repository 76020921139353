import i18n from 'i18n-literally'

// Intended to align with AccountButton (padding - scroll gutter)
export const CONTENT_PADDING_RIGHT = 'calc(40px - 1ch)'

export const DASHBOARD_VIEW = {
  READINGS: 'readings',
  MAP: 'map',
  // Irrigation
  SCHEDULES: 'schedules',
  CONTROLLERS: 'controllers',
}

export const IRRIGATION_VIEW_OPTIONS = [
  { label: i18n`Schedules`, value: DASHBOARD_VIEW.SCHEDULES },
  { label: i18n`Controllers`, value: DASHBOARD_VIEW.CONTROLLERS },
]

// CA values are default for now
export const BEHAVIOR_DEFAULTS = { tagPlantBatches: true, trackVegPlants: false }

export const LOCATIONS_TERMS = {
  AROYA: {
    default: {
      room: i18n`rooms`,
      zone: i18n`zones`,
      facility: i18n`facilities`,
      targetRange: i18n`targets`,
    },
    flowMeter: {
      room: i18n`groups`,
      zone: i18n`blocks`,
    },
    outdoor: {
      room: i18n`fields`,
      zone: i18n`zones`,
      facility: i18n`facilities`,
      targetRange: i18n`targets`,
    }
  },
}

export const TIMEZONES = [
  {
    value: 'America/St_Johns',
    label: 'Newfoundland',
  },
  {
    value: 'America/Halifax',
    label: 'Atlantic',
  },
  {
    value: 'America/New_York',
    label: 'Eastern',
  },
  {
    value: 'America/Chicago',
    label: 'Central'
  },
  {
    value: 'America/Denver',
    label: 'Mountain'
  },
  {
    value: 'America/Phoenix',
    label: 'Arizona'
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific'
  },
  {
    value: 'America/Anchorage',
    label: 'Alaska'
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian'
  },
]
