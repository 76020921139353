import PropTypes from 'prop-types'
import {
  both,
  complement,
  evolve,
  isNil,
  pick,
  pipe,
  trim,
} from 'ramda'

import { number, string } from 'yup'

import { getValidator } from '~/src/Lib/Utils'

const shapeConfig = {
  id: PropTypes.number,
  name: PropTypes.string,
  dryingTarget: PropTypes.number,
  location: PropTypes.string,
  orgDryingTarget: PropTypes.bool,
  staffSize: PropTypes.number,
  unitPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.number,
      unit: PropTypes.number,
    })
  ),
  seenFirstFlow: PropTypes.bool,
  timezone: PropTypes.string,
}
export const shape = PropTypes.shape(shapeConfig)

export const validationSchema = {
  id: number().min(1),
  name: string().trim().required(),
  location: string().trim().required(),
  staffSize: number().min(0).max(10000).required(),
  timezone: string().required()
}

export const [validate, isValid] = getValidator(validationSchema)

const transforms = {
  id: Number,
  name: both(complement(isNil), trim),
  location: both(complement(isNil), trim),
}
const transformer = evolve(transforms)
export const cleaner = pipe(
  pick(Object.keys(shapeConfig)),
  transformer
)

export const prepareData = raw => {
  const cleanData = cleaner(raw)
  return cleanData
}
