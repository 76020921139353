import { omit } from 'ramda'

import { normalize } from 'normalizr'

import createEntityBundle, {
  asyncActionFactory,
  doEntitiesReceived,
} from '~/src/Lib/createEntityBundle'
import { facilityIdScope as scope, FacilityRole as schema } from '~/src/Store/Schemas'

const name = 'facilityRoles'

const rolesFetch = asyncActionFactory({
  name,
  singularName: name,
  action: 'fetch',
  handler: async ({ apiFetch, dispatch }) => {
    try {
      const result = await apiFetch(`/${name}/`)
      const { entities } = normalize(result, [schema])

      if (entities && name in entities) {
        dispatch(doEntitiesReceived(entities, { fullReplace: true }))
      }
      return result
    } catch (error) {
      console.error('failed to fetch facility roles:', error.message, error.stack)
      return null
    }
  },
  schema: [schema],
  snackbar: false,
})
const { types: { prefix: rolesFetchPrefix, ...actions } } = rolesFetch

const listOmitter = omit(['list'])

const rolesFetchReducer = (state, action) => {
  switch (action.type) {
    case actions.start:
      return {
        ...state,
        inflight: {
          ...state.inflight,
          list: 'fetch',
        },
        errors: listOmitter(state.errors),
      }
    case actions.succeed:
      return {
        ...state,
        inflight: listOmitter(state.inflight),
      }
    case actions.fail:
      return {
        ...state,
        inflight: listOmitter(state.inflight),
        errors: {
          list: {
            error: action.error,
            ts: Date.now()
          }
        }
      }
    default:
      return state
  }
}

const bundle = createEntityBundle({
  name,
  apiConfig: { schema },
  scope,
})

export default {
  ...bundle,
  reducer: (state, action) => {
    if (action.type.startsWith(rolesFetchPrefix)) {
      return rolesFetchReducer(state, action)
    }
    return bundle.reducer(state, action)
  },
  doFacilityRolesFetch: () => rolesFetch({ id: 'list' }),
}
