import {
  compose,
  filter,
  map,
  path,
  pipe,
  sortBy,
  toLower,
  uniq,
} from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { Membership } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const MEMBERSHIP_LIST_SET_PAGE = 'MEMBERSHIP_LIST_SET_PAGE'
const MEMBERSHIP_LIST_SET_SORT = 'MEMBERSHIP_LIST_SET_SORT'
const MEMBERSHIP_LIST_SET_FILTER = 'MEMBERSHIP_LIST_SET_FILTER'
const MEMBERSHIP_LIST_SET_SEARCH = 'MEMBERSHIP_LIST_SET_SEARCH'
const MEMBERSHIP_LIST_SET_LIST = 'MEMBERSHIP_LIST_SET_LIST'

const additionalState = {
  searchTerms: '',
  currentFilter: 'ALL',
  currentSort: 'user__lastName',
  currentList: []
}

const membershipListBundle = createAsyncResourceBundle({
  name: 'membershipList',
  actionBaseType: 'MEMBERSHIP_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ dispatch, apiFetch, store }) => {
    const myMembershipId = store.selectMyCurrentMembershipId()
    const { [myMembershipId]: myMembership } = store.selectMemberships()
    return apiFetch('/memberships/', {
      pagination: 0,
    }).then(payload => {
      const { entities: { memberships, ...entities }, result } = normalize(
        payload,
        [Membership]
      )
      dispatch(doEntitiesReceived({
        ...entities,
        memberships: {
          ...memberships,
          [myMembershipId]: myMembership
        }
      }))
      const currentRoleList = store.selectFacilityRoles()
      const fullMembershipList = payload.map(membership => ({ ...membership, role: currentRoleList[membership.role] }))
      dispatch({ type: MEMBERSHIP_LIST_SET_LIST, payload: fullMembershipList })
      return { count: payload.length, results: result }
    })
  },
})

export default {
  ...membershipListBundle,
  reducer: reduceReducers(membershipListBundle.reducer, (state, action) => {
    switch (action.type) {
      case MEMBERSHIP_LIST_SET_PAGE:
        return { ...state, currentPage: action.payload }
      case MEMBERSHIP_LIST_SET_SORT:
        return { ...state, currentSort: action.payload }
      case MEMBERSHIP_LIST_SET_FILTER:
        return { ...state, currentPage: 1, currentFilter: action.payload }
      case MEMBERSHIP_LIST_SET_SEARCH:
        return { ...state, currentPage: 1, searchTerms: action.payload }
      case MEMBERSHIP_LIST_SET_LIST:
        return { ...state, ...additionalState, currentList: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doMembershipListSetPage: page => ({ dispatch }) => {
    dispatch({ type: MEMBERSHIP_LIST_SET_PAGE, payload: page })
  },
  doMembershipListSetSort: sort => ({ dispatch }) => {
    dispatch({ type: MEMBERSHIP_LIST_SET_SORT, payload: sort })
  },
  doMembershipListSetFilter: roleKey => ({ dispatch }) => {
    dispatch({ type: MEMBERSHIP_LIST_SET_FILTER, payload: roleKey })
  },
  doMembershipListSetSearch: search => ({ dispatch }) => {
    dispatch({ type: MEMBERSHIP_LIST_SET_SEARCH, payload: search })
  },
  selectCurrentMembershipList: createSelector(
    'selectMembershipListRaw',
    membershipListRaw => {
      if (!membershipListRaw.data) {
        return []
      }

      const { currentSort, currentFilter, searchTerms, currentList } = membershipListRaw
      const currentSearch = searchTerms.toLowerCase()
      let memberships = currentList ?? []

      if (currentSearch) {
        memberships = memberships.filter(mem => (
          mem.user.fullName.toLowerCase().includes(currentSearch) || mem.user.email.toLowerCase().includes(currentSearch)
        ))
      }
      if (currentFilter !== 'ALL') {
        memberships = memberships.filter(mem => mem.role.key === currentFilter)
      }
      return sortBy(compose(toLower, path(currentSort.split('__'))))(memberships)
    }
  ),
  selectMembershipListRoles: createSelector(
    'selectMembershipListRaw',
    'selectFacilityRoles',
    ({ currentList }, facilityRoles) => pipe(
      map(path(['role', 'id'])),
      uniq,
      map(id => facilityRoles[id]),
      filter(Boolean),
    )(currentList),
  ),
  selectMembershipListFilter: createSelector(
    'selectMembershipListRaw',
    ({ currentFilter }) => currentFilter,
  ),
  selectMembershipListSearch: createSelector(
    'selectMembershipListRaw',
    ({ searchTerms }) => searchTerms,
  ),
  reactMembershipListFetch: createAppIsReadySelector({
    dependencies: [
      'selectMembershipListShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { url }) => {
      if (shouldUpdate && url.includes('staff')) {
        return { actionCreator: 'doFetchMembershipList' }
      }
      return undefined
    }
  }),
}
