import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { createAppIsReadySelector } from '~/src/Store/utils'

const activeDeviceListBundle = createAsyncResourceBundle({
  name: 'activeDeviceList',
  actionBaseType: 'ACTIVE_DEVICE_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  // eslint-disable-next-line babel/camelcase
  getPromise: ({ apiFetch }) => apiFetch('/facility/active/', { is_puck: false })
})

export default {
  ...activeDeviceListBundle,
  reactActiveDeviceListFetch: createAppIsReadySelector({
    dependencies: [
      'selectActiveDeviceListShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, { pattern = '' }) => {
      if (shouldUpdate && pattern.startsWith('/account')) {
        return { actionCreator: 'doFetchActiveDeviceList' }
      }
      return null
    }
  }),
}
