import i18n from 'i18n-literally'

export const DEFAULT_TIMEFRAME = '3D'
export const TIMEFRAME_PRESETS = {
  '24H': { hours: 24 },
  '3D': { days: 3 },
  '1W': { days: 7 },
}

export const SETTINGS_PATHS = {
  roomDashboard: ['room', 'chart'],
  growlog: ['harvest', 'growlog'],
}

export const SIDEBAR_WIDTH = 410

export const ZOOM_MODE = 'zoom'
export const DRYBACK_MODE = 'dryback'

export const SUBSTRATES = {
  SOILLESS: { value: 'SOILLESS', label: i18n`Generic soilless` },
  SOIL: { value: 'SOIL', label: i18n`Soil` },
}
