import i18n from 'i18n-literally'

import reduceReducers from 'reduce-reducers'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { parseApiErrors } from '~/src/Lib/Utils'
import { IrrigationManualEvent as schema } from '~/src/Store/Schemas'

const logger = createLogger('Irrigation/bundle#manualEvents')

const ACTION = {
  MANUAL_IRRIGATION_STARTED: 'MANUAL_IRRIGATION_STARTED',
  MANUAL_IRRIGATION_FAILED: 'MANUAL_IRRIGATION_FAILED',
  MANUAL_IRRIGATION_FETCH_FAILED: 'MANUAL_IRRIGATION_FETCH_FAILED',
  MANUAL_IRRIGATION_FETCH_FINISHED: 'MANUAL_IRRIGATION_FETCH_FINISHED',
  MANUAL_IRRIGATION_FETCH_STARTED: 'MANUAL_IRRIGATION_FETCH_STARTED',
  MANUAL_IRRIGATION_STOP_INFLIGHT: 'MANUAL_IRRIGATION_STOP_INFLIGHT',
  MANUAL_IRRIGATION_STOP_FAILED: 'MANUAL_IRRIGATION_STOP_FAILED',
  MANUAL_IRRIGATION_STOP_SUCCEEDED: 'MANUAL_IRRIGATION_STOP_SUCCEEDED',
}

const irrigationManualEventsBundle = createEntityBundle({
  name: 'irrigationManualEvents',
  apiConfig: { schema, url: 'irrigationManualEvents' },
})

const initialState = {
  test: true,
}

export default {
  ...irrigationManualEventsBundle,
  reducer: reduceReducers(irrigationManualEventsBundle.reducer, (state = initialState, action = {}) => {
    switch (action.type) {
      case ACTION.MANUAL_IRRIGATION_STARTED:
        return state
      case ACTION.MANUAL_IRRIGATION_FAILED:
        return state
      default:
        return state
    }
  }),
  doStartManualIrrigation: payload => ({ apiFetch, dispatch }) => {
    logger.debug({ payload })
    return apiFetch('/irrigationManualEvents/bulk/', payload, { method: 'POST' }).then(res => {
      dispatch({ type: ACTION.MANUAL_IRRIGATION_STARTED })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [i18n`Manual irrigation started.`] })
      return res
    }).catch(e => {
      const error = parseApiErrors(e) || e.message || i18n`Unknown error. Please try again.`
      dispatch({ type: ACTION.MANUAL_IRRIGATION_FAILED, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })
    })
  },
  doStopManualIrrigation: eventId => ({ apiFetch, dispatch, store }) => {
    dispatch({ type: ACTION.MANUAL_IRRIGATION_STOP_INFLIGHT, payload: eventId })
    return apiFetch(`/irrigationManualEvents/${eventId}/cancel/`, null, { method: 'POST' }).then(res => {
      dispatch({ type: ACTION.MANUAL_IRRIGATION_STOP_SUCCEEDED })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [i18n`Manual irrigation stopped.`] })
      if (res.id === eventId) {
        store.doEntitiesReceived({ irrigationManualEvents: { [eventId]: res } })
      }
      return res
    }).catch(e => {
      const error = parseApiErrors(e) || e.message || i18n`Unknown error. Please try again.`
      dispatch({ type: ACTION.MANUAL_IRRIGATION_STOP_FAILED, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })
    })
  },
}
