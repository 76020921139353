import { SvgIcon } from '@mui/material'

const JournalNav = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      d="M16.682 10.269a1 1 0 01.05 1.413l-5.6 6a1.003 1.003 0 01-1.463 0l-2.4-2.571a1 1 0 111.462-1.364l1.669 1.787 4.869-5.216a1 1 0 011.413-.05z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M14.83 4H19a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h4.17a3.004 3.004 0 015.66 0zM5 6h4v1a1 1 0 001 1h4a1 1 0 001-1V6h4v14H5V6zm7 0a1 1 0 100-2 1 1 0 000 2z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default JournalNav
