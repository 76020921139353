// shim requestIdleCallback for Edge and Safari
import './Lib/polyfills'

import i18n from 'i18n-literally'

import { createRoot } from 'react-dom/client'

import db from '../i18n.db.json'
import config from './App/config'
import cache from './IO/Cache'
import getGlobal from './Lib/getGlobal'
import createLogger from './Lib/Logging'
import Root from './Root'
import { initializeAppState, initThirdParty, prepareMockServiceWorker } from './utils'

i18n.db = db

const logger = createLogger('AROYA/main')

initThirdParty(logger)

const apiMocked = config.MSW === 'ON'

cache.getAll().then(indexedDBData => {
  // deepcode ignore PromiseNotCaughtGeneral: n/a
  prepareMockServiceWorker(apiMocked, logger).then(async () => {
    const reduxStore = await initializeAppState(indexedDBData, logger)

    if (process.env.NODE_ENV === 'production' && !apiMocked) {
      reduxStore.doMarkMeAsOutdated()
      reduxStore.doMarkSystemAsOutdated()
    }

    const root = createRoot(document.getElementById('root'))
    root.render(<Root store={reduxStore} />)
  })
})

if ('import' in getGlobal() && import.meta && import.meta.hot) {
  import.meta.hot.accept()
}
