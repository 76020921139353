export const REACTOR_PRIORITIES = Object.freeze({
  HIGH: 'REACTOR_PRIORITIES_HIGH',
  LOW: 'REACTOR_PRIORITIES_LOW',
  MEDIUM: 'REACTOR_PRIORITIES_MEDIUM',
})

export const TAB_STATE_TYPE = 'TAB_STATE'

export const TAB_STATES = Object.freeze({
  ACTIVE: 'TAB_STATES_ACTIVE',
  FROZEN: 'TAB_STATES_FROZEN',
  HIDDEN: 'TAB_STATES_HIDDEN',
  PASSIVE: 'TAB_STATES_PASSIVE',
  TERMINATED: 'TAB_STATES_TERMINATED',
})

export const URL_ACTION = 'URL_UPDATED'
