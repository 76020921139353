import createListBundle, { PAGE_HANDLING } from '~/src/Lib/createListBundle'
import { ControlDailyJournal } from '~/src/Store/Schemas'

/**
 * The shape of query params for the control daily journal endpoint
 * @typedef {Object} ControlDailyJournalParams
 * @property {number} page
 * @property {number} pageSize
 * @property {number} [user]
 * @property {number} [harvest]
 * @property {import('luxon').DateTime} [start]
 * @property {import('luxon').DateTime} [end]
 * @property {?string} eventType
 * @property {string} [status]
 */
const initialState = {
  // pagination
  page: 1,
  pageSize: 100,
  // filters
  end: null, // no entries after this datetime
  eventTypes: null,
  harvest: null,
  rooms: null, // array of room ids
  start: null, // no entries before this datetime
  status: null,
  user: null,
}

export default createListBundle({
  entityName: 'controlDailyJournals',
  fetchHandler: ({
    apiFetch,
    params: { eventTypes, ...rest }
  }) => apiFetch(
    '/controlDailyJournals/',
    eventTypes
      // eslint-disable-next-line babel/camelcase
      ? { ...rest, event_types: eventTypes }
      : rest
  ),
  initialState,
  pageHandling: PAGE_HANDLING.APPEND,
  schema: ControlDailyJournal,
  urlTest: (url, pattern) => pattern === '/journal' && url.includes('tab=irrigation')
})
