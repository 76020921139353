import createEntityBundle from '~/src/Lib/createEntityBundle'
import { facilityScope as scope, PlantBatch as schema } from '~/src/Store/Schemas'

const name = 'plantBatches'
export default createEntityBundle({
  name,
  apiConfig: { schema },
  customActions: [
    {
      action: 'change_tag',
      name,
      schema: [schema],
      handler: ({ payload, apiFetch }) => {
        const body = payload.map(([plantBatch, newTag]) => ({ [plantBatch.id]: newTag }))
        return apiFetch('/plantBatches/change_tag/', body, { method: 'PUT' })
      },
      async: true,
      snackbar: ({
        status,
        dispatch,
        payload,
        response
      }) => {
        const successMessage = payload.length > 1 ? 'Invalid plant lot tags have been replaced and updated' : `Invalid plant lot tag ${payload[0][0].label} has been replaced with ${response[0].label}`
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [
            status === 'succeed'
              ? successMessage
              : 'Unable to update plant lot tag'
          ]
        })
      },
    }
  ],
  scope,
})
