import i18n from 'i18n-literally'

export const HARVEST_CREATE_FLOW = 'harvest_create'
export const LOT_TO_PLANT_FLOW = 'lot2plant'
export const LOT_TO_PLANT_FLOW_ROUTE = '/lot2plant'
export const HARVEST_BASED_LOT_TO_PLANT_FLOW_ROUTE = `${LOT_TO_PLANT_FLOW_ROUTE}/:id`
export const VEG_TO_FLOWER_FLOW = 'veg2flower'
export const VEG_TO_FLOWER_FLOW_ROUTE = '/veg2flower'
export const HARVEST_BASED_VEG_TO_FLOWER_FLOW_ROUTE = `${VEG_TO_FLOWER_FLOW_ROUTE}/:id`
export const HARVESTING_FLOW = 'harvesting'
export const HARVESTING_FLOW_ROUTE = '/harvesting'
export const HARVEST_BASED_HARVESTING_FLOW_ROUTE = `${HARVESTING_FLOW_ROUTE}/:id`
export const DRYING_FLOW = 'drying'
export const DRYING_FLOW_ROUTE = '/drying'
export const HARVEST_BASED_DRYING_FLOW_ROUTE = `${DRYING_FLOW_ROUTE}/:id`
export const PROCESSING_FLOW = 'processing'
export const PROCESSING_FLOW_ROUTE = '/processing'
export const HARVEST_BASED_PROCESSING_FLOW_ROUTE = `${PROCESSING_FLOW_ROUTE}/:id`

export const searchByMap = {
  [DRYING_FLOW]: i18n`cultivar name`,
  [PROCESSING_FLOW]: i18n`ID`,
}

export const sourceItemNameMap = {
  [DRYING_FLOW]: i18n`harvest batches`,
  [PROCESSING_FLOW]: i18n`packages`,
}

export const messageForMoreSourcesMap = {
  [DRYING_FLOW]: i18n`record harvest batches to make them appear here`,
  [PROCESSING_FLOW]: i18n`record packages to make them appear here`,
}
