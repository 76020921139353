import { createSelector } from 'redux-bundler'

import { captureEvent } from '@sentry/react'
import { camelize } from 'inflection'
import { minutes, seconds } from 'milliseconds'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'
import { TAB_STATES } from '~/src/Store/constants'
import { MetrcFacility as schema } from '~/src/Store/Schemas'

const EXTEND_SYNC_TIMEOUT = 'EXTEND_SYNC_TIMEOUT'
const SYNC_TIMEOUT = seconds(30)
const SIXTY_SECONDS = 60000

const ROUTE_TO_METRC_DATATYPES = {
  '/compliance': {
    harvest: minutes(1),
    plant: minutes(1),
    batch: minutes(1),
    package: minutes(1),
  },
  '/compliance#/plants/:id': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
  },
  '/compliance#/plantBatches/:id': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
  },
  '/compliance#/harvestBatches/:id': {
    wasteType: minutes(15),
  },
  '/compliance#/packages/:id': {
    packageAdjustmentReason: minutes(15),
  },
  '/inventory': {
    batch: minutes(1),
    harvest: minutes(1),
    package: minutes(1),
    plant: minutes(1),
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
    wasteType: minutes(15),
  },
  '/inventory#/plants/:id': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
  },
  '/inventory#/plantBatches/:id': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
  },
  '/inventory#/harvestBatches/:id': {
    wasteType: minutes(15),
  },
  '/inventory#/packages/:id': {
    packageAdjustmentReason: minutes(15),
  },
  '/harvests/new': {
    batch: minutes(1),
    cultivar: minutes(5),
    package: minutes(1),
    plant: minutes(1),
    wasteMethod: minutes(15),
    wasteReason: minutes(15),
    wasteReasonPlantBatch: minutes(15),
    wasteType: minutes(15),
  },
  '/harvests#/harvesting/:id': {
    wasteMethod: minutes(15),
    wasteReason: minutes(15),
  },
  '/harvesting/:id': {
    item: minutes(5),
    plant: minutes(1),
    wasteMethod: minutes(15),
    wasteReasonPlantBatch: minutes(15),
    wasteType: minutes(15),
  },
  '/harvests/:id/inventory': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
    wasteReasonPlantBatch: minutes(15),
    wasteType: minutes(15),
  },
  '/harvests/:id/inventory#/plants/:id': {
    wasteReason: minutes(15),
    wasteMethod: minutes(15),
  },
  '/harvests/:id/inventory#/plantBatches/:id': {
    wasteMethod: minutes(15),
    wasteReasonPlantBatch: minutes(15),
  },
  '/setup/inventory': {
    item: minutes(5),
    location: minutes(5),
  },
  '/setup/facility': {
    location: minutes(5),
  }
}

const { reducer: initialReducer, ...initialBundle } = createEntityBundle({
  name: 'metrcFacilities',
  idAttribute: 'aroyaFacility',
  apiConfig: {
    schema,
    fetch: {
      handler: ({ apiFetch }) => apiFetch('/facility/metrc/'),
      schema: [schema]
    }
  },
  customActions: [{
    action: 'request_sync',
    async: true,
    snackbar: false,
    handler: ({ apiFetch, payload, dispatch }) => {
      dispatch({ type: EXTEND_SYNC_TIMEOUT })

      return apiFetch('/metrc_sync/', payload, { method: 'POST' }).catch(
        response => {
          if (response.status === 425) {
            captureEvent({
              message: 'Recieved 425 from metrc_sync request.',
              level: 'info',
            })
            dispatch({ type: EXTEND_SYNC_TIMEOUT })
          }
        }
      )
    }
  }]
})

export default {
  ...initialBundle,
  reducer: (state, action) => {
    if (action && action.type === EXTEND_SYNC_TIMEOUT) {
      return { ...state, syncTimeout: Date.now() + SYNC_TIMEOUT }
    }
    return initialReducer(state, action)
  },
  selectMetrcFacilitySyncTimeout: createSelector(
    'selectMetrcFacilitiesRoot',
    metrcFacilitiesRoot => metrcFacilitiesRoot.syncTimeout ?? 0
  ),
  selectCurrentMetrcFacilities: createSelector(
    'selectCurrentFacility',
    'selectMetrcFacilities',
    (currentFacility, metrcFacilities) => (
      currentFacility.id && metrcFacilities
        ? Object.values(metrcFacilities)
          .filter(facility => facility.aroyaFacilityId === currentFacility?.id)
        : EMPTY_ARRAY
    )
  ),
  reactFetchMetrcFacility: createSelector(
    'selectAppIsReady',
    'selectIsOnline',
    'selectCurrentFacility',
    'selectMetrcFacilities',
    'selectMetrcFacilitiesDirty',
    'selectMetrcFacilitiesInflight',
    (appIsReady, isOnline, currentFacility, metrcFacilities, dirty, inFlight) => {
      if (!appIsReady || !isOnline || !currentFacility || inFlight[currentFacility.id]) return null

      if (dirty[currentFacility.id]?.lastError?.ts > Date.now() - SIXTY_SECONDS) return null

      if (!Object.keys(metrcFacilities).length) {
        return {
          actionCreator: 'doMetrcFacilityFetch',
          args: [currentFacility],
        }
      }

      return null
    }
  ),
  reactMetrcSync: createSelector(
    'selectCurrentMetrcFacilities',
    'selectRouteInfo',
    'selectDialogRouteInfo',
    'selectTabState',
    'selectMetrcFacilitySyncTimeout',
    'selectAvailableFeatures',
    (metrcFacilities, routeInfo, dialogRouteInfo, tabState, syncTimeout, availableFeatures) => {
      if (!availableFeatures.has('METRC_SYNC_BY_USER_ACTION')) return null

      const waitingForSync = syncTimeout > Date.now()
      const mFacilities = Object.values(metrcFacilities)
      if (!mFacilities.length || tabState !== TAB_STATES.ACTIVE || waitingForSync) return null

      const dataTypesToSync = []
      let routeInfoPattern = routeInfo.pattern
      let dialogPattern = dialogRouteInfo.pattern
      if (dialogPattern === '*') {
        dialogPattern = ''
      } else {
        routeInfoPattern = `${routeInfoPattern}#`
        if (dialogPattern.includes(':schema')) {
          dialogPattern = dialogPattern.replace(':schema', dialogRouteInfo.params.schema)
        }
      }
      if (`${routeInfoPattern}${dialogPattern}` in ROUTE_TO_METRC_DATATYPES) {
        const { [`${routeInfoPattern}${dialogPattern}`]: routeDataTypes } = ROUTE_TO_METRC_DATATYPES
        dataTypesToSync.push(...Object.keys(routeDataTypes).filter(dataType => {
          let stale = false
          mFacilities.forEach(mFacility => {
            const lastRefreshTS = mFacility[`last${camelize(dataType)}Refresh`]
            if (lastRefreshTS) {
              const staleAfter = routeDataTypes[dataType]
              if (!stale) {
                stale = getDateTime(lastRefreshTS) + staleAfter < Date.now()
              }
            }
          })
          return stale
        }))
      }

      if (dataTypesToSync.length) {
        return {
          actionCreator: 'doMetrcFacilityRequestSync',
          args: [{ dataTypesToSync: Array.from(new Set(dataTypesToSync)) }]
        }
      }
      return null
    }
  ),
}
