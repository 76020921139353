import { SvgIcon } from '@mui/material'

export const DripIcon = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M4.535 20v-5M20 19.5v-5" stroke="currentColor" />
    <path d="M18 11.923c0 1.7-1.343 3.077-3 3.077s-3-1.378-3-3.077C12 8.923 15 6 15 6s3 3 3 5.923Zm-7-2.316C11 10.929 9.88 12 8.5 12S6 10.928 6 9.607C6 7.274 8.5 5 8.5 5S11 7.333 11 9.607Z" fill="currentColor" />
  </SvgIcon>
)

export default DripIcon
