import { createSelector } from 'redux-bundler'

import createListBundle from '~/src/Lib/createListBundle'
import { MetrcLocation as schema } from '~/src/Store/Schemas'

export const initialState = {
  search: '',
  ordering: ['name', 'asc'],
}

const { selectMetrcLocationListShouldUpdate, ...initialBundle } = createListBundle({
  entityName: 'metrcLocation',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/metrcLocations/', { ...params, pagination: 0 }),
  flags: { keys: ['METRC'] }
})

export default {
  ...initialBundle,
  selectMetrcLocationListShouldNormallyUpdate: selectMetrcLocationListShouldUpdate,
  selectMetrcLocationListShouldUpdate: createSelector(
    'selectMetrcLocationListShouldNormallyUpdate',
    'selectMetrcLocationListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastLocationRefresh && lastSuccess < new Date(metrcFacility.lastLocationRefresh)
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
