import { omit } from 'ramda'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialScheduleHistoryBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationScheduleHistory',
  actionBaseType: 'FACILITY_IRRIGATION_SCHEDULE_HISTORY',
  getPromise: ({ apiFetch, store }) => {
    const irrigationSchedules = store.selectIrrigationSchedules()
    const firstProgramIdLookup = Object.values(irrigationSchedules)
      .filter(({ programs }) => programs.length)
      .reduce((acc, { id, programs }) => ({
        ...acc, [id]: programs[0].id,
      }), {})
    const now = getDateTime('now')
    return apiFetch('/controllerSchedulePublishHistories/', {
      ordering: '-publish_on',
      pagination: 0,
      start: now.minus({ day: 2 }).toISO(),
    }).then(response => {
      const scheduleHistory = response.reduce((histories, schedulePublishHistory) => {
        const { lastPublishedSchedules, publishOn, publishedPrograms } = schedulePublishHistory
        Object.values(lastPublishedSchedules).forEach(({ scheduleId, scheduleStartTime: publishEntityStartTime }) => {
          const firstProgramId = firstProgramIdLookup[scheduleId]
          // TODO: After 11/8 all entities should have `scheduleStartTime` and we can drop the fallback
          const scheduleStartTime = publishEntityStartTime ?? publishedPrograms.find(p => p.id === firstProgramId)?.s
          if (scheduleStartTime) {
            histories[scheduleId] ??= []
            histories[scheduleId].push({
              publishOn: getDateTime(publishOn).toMillis(),
              scheduleStartTimeParams: omit(['year', 'month', 'day'], getDateTime(scheduleStartTime).toObject()),
            })
          }
        })
        return histories
      }, {})
      return scheduleHistory
    })
  },
  staleAfter: ms.minutes(15),
})

export default {
  ...initialScheduleHistoryBundle,
  reactFetchFacilityIrrigationScheduleHistory: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationActiveSchedules',
      'selectFacilityIrrigationScheduleHistoryShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (activeSchedules, shouldUpdate, { view }, { pattern }) => {
      if (activeSchedules && shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationScheduleHistory' }
      }
      return null
    },
  }),
}
