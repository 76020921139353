import { memo, PureComponent, useMemo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { trim } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  Alert,
  Box,
  Fade,
  LinearProgress,
} from '@mui/material'

import config from '~/src/App/config'
import { TERMS_AND_CONDITIONS_URL } from '~/src/App/constants'
import { getTokens } from '~/src/Lib/Auth'
import createLogger from '~/src/Lib/Logging'
import LoginForm from '~/src/Routes/Login/LoginForm'
import { getLSM } from '~/src/Store/bundles/me'
import { isValidEmail } from '~/src/Store/bundles/me/shape'
import Button from '~/src/UI/Shared/Button'
import PageTitle from '~/src/UI/Shared/PageTitle'
import T from '~/src/UI/Shared/Typography'

const displayName = 'LoginPage'
const logger = createLogger(displayName)

const bannerMessage = state => {
  if (state.forgot) {
    return i18n`An email was sent with a link to reset your password.`
  }
  return i18n`Your password has been saved. Please login below.`
}

// Emergency switch to disable marketing iframe
const marketingIframeEnabled = config.ENVIRONMENT === 'production'

export class LoginComponent extends PureComponent {
  static displayName = displayName

  static propTypes = {
    auth: PropTypes.shape({
      authenticated: PropTypes.bool,
      loginFailed: PropTypes.bool,
      loginMessage: PropTypes.string,
      loginProcessing: PropTypes.bool,
      forgot: PropTypes.shape({
        success: PropTypes.bool,
      }),
      reset: PropTypes.shape({
        success: PropTypes.bool,
      }),
    }).isRequired,
    queryAdvanced: PropTypes.shape({
      reset: PropTypes.bool,
    }).isRequired,
    switching: PropTypes.bool.isRequired,
    switchingTo: PropTypes.string.isRequired,
    doLogin: PropTypes.func.isRequired,
    doLogout: PropTypes.func.isRequired,
    doForgotClearState: PropTypes.func.isRequired,
    doResetClearState: PropTypes.func.isRequired,
  }

  showLogoutTimeout = null

  constructor(props) {
    super(props)
    this.state = {
      copyYear: new Date().getFullYear(),
      email: '',
      password: '',
      forgot: props.auth.forgot.success,
      reset: props.queryAdvanced?.reset ?? false,
      showLogout: false,
      startLogout: false,
    }
    this.setState = this.setState.bind(this)

    if (this.state.forgot) {
      this.props.doForgotClearState()
    }
  }

  componentDidUpdate(_, prevState) {
    const { auth: { authenticated } } = this.props

    if (authenticated && !this.showLogoutTimeout) {
      logger.debug('seting timeout to show logout button')
      this.showLogoutTimeout = setTimeout(() => {
        logger.debug('[timeout-handler] showing logout button')
        this.setState({ showLogout: true })
      }, 10_000)
    }

    if (!prevState.startLogout && this.state.startLogout) {
      setTimeout(() => this.props.doLogout(), 250)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.showLogoutTimeout)
    if (this.showLogoutTimeout) {
      this.showLogoutTimeout = null
    }
  }

  onSubmit = async event => {
    event.preventDefault()
    this.setState({ forgot: undefined, reset: false })
    this.props.doForgotClearState()
    this.props.doResetClearState()
    if (this.isFormValid()) {
      this.props.doLogin(this.state)
    }
  }

  isFormValid() {
    const { email, password } = this.state
    return Boolean(isValidEmail(email) && trim(password).length)
  }

  render() {
    const {
      auth: { authenticated, loginProcessing, loginFailed, loginMessage },
      switching,
      switchingTo,
    } = this.props

    const { copyYear, forgot, reset, showLogout, startLogout } = this.state
    const showBanner = loginFailed || this.state.forgot || this.state.reset
    if (showLogout) {
      logger.debug('[render] showing logout button')
    }
    if (authenticated || startLogout) {
      const pageTitle = switching ? i18n`Switching to ${switchingTo}` : i18n`Welcome back!`
      return (
        <Fade in={!startLogout}>
          <div className="center-all">
            <Box display="flex" justifyContent="center" alignItems="space-between" flexWrap="wrap" gap="1ch" height="25vh">
              <PageTitle disablePortal title={pageTitle} />
              <T align="center" sx={{ flex: '0 0 100%' }}>
                {i18n`Loading your facility data.`}
              </T>
              <Box flex="0 0 100%" paddingY="1ch" paddingX="calc(50% - 9rem)">
                <LinearProgress variant="indeterminate" color="primary" />
              </Box>
              {showLogout ? (
                <Fade in>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ maxHeight: 42 }}
                    onClick={() => {
                      this.setState({ startLogout: true })
                    }}
                  >
                    {i18n`Retry`}
                  </Button>
                </Fade>
              ) : <Box height={42} />}
            </Box>
          </div>
        </Fade>
      )
    }

    return (
      <Box
        className="aroya__no-auto-height"
        display="grid"
        gridTemplateAreas={{
          xs: '"form"',
          md: marketingIframeEnabled ? '"form marketing"' : '"form"',
        }}
        gridTemplateColumns={{
          xs: '1fr',
          md: marketingIframeEnabled ? '1fr 1fr' : '1fr',
        }}
        height="100vh"
        paddingInline={{ xs: 1, md: marketingIframeEnabled ? 0 : 1 }}
        sx={{
          '& iframe': {
            border: 'none',
          },
        }}
      >
        <Box
          gridArea="form"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src="/images/aroya-logo-large.svg"
            alt="AROYA logo"
            width="10rem"
          />
          {showBanner ? (
            <Box
              position="fixed"
              top={0}
              left={0}
              width="50%"
              display="flex"
              justifyContent="center"
              paddingBlockStart={1}
              sx={theme => ({
                '& .MuiAlert-message': {
                  lineHeight: { xs: 1.2, sm: 1.75 },
                  fontSize: { xs: 14, sm: 16 },
                  fontFamily: theme.typography.boldFontFamily,
                }
              })}
            >
              {loginFailed && !forgot && !reset ? (
                <Alert
                  data-testid="login-failed-banner"
                  severity="error"
                  variant="outlined"
                >
                  {loginMessage}
                </Alert>
              ) : null}
              {reset || forgot ? (
                <Alert
                  data-testid={`login-${this.state.forgot ? 'forgot' : 'reset'}-banner`}
                  severity="success"
                  variant="outlined"
                >
                  {bannerMessage(this.state)}
                </Alert>
              ) : null}
            </Box>
          ) : null}
          <LoginForm
            email={this.state.email}
            isFormValid={this.isFormValid()}
            loginProcessing={loginProcessing}
            onSubmit={this.onSubmit}
            password={this.state.password}
            setState={this.setState}
          />
          <Box
            position="fixed"
            bottom={0}
            left={0}
            width={{ xs: '100%', md: marketingIframeEnabled ? '50%' : '100%' }}
            display={{ xs: 'block', md: marketingIframeEnabled ? 'flex' : 'block' }}
            justifyContent="center"
            paddingBlockEnd={1}
          >
            <T
              paddingInlineEnd={{ xs: 0, md: marketingIframeEnabled ? 1 : 0 }}
              borderRight={{ xs: 'none', md: marketingIframeEnabled ? '1px solid currentColor' : 'none' }}
              textAlign={{ xs: 'center', md: marketingIframeEnabled ? 'left' : 'center' }}
            >
              &copy; {copyYear} {i18n`Addium Inc. All rights reserved.`}
            </T>
            <T.Bold
              color="primary"
              component="a"
              display="block"
              href={TERMS_AND_CONDITIONS_URL}
              paddingInlineStart={{ xs: 0, md: marketingIframeEnabled ? 1 : 0 }}
              target="_blank"
              textAlign={{ xs: 'center', md: marketingIframeEnabled ? 'left' : 'center' }}
            >
              {i18n`Terms & Conditions`}
            </T.Bold>
          </Box>
        </Box>
        {marketingIframeEnabled ? (
          <Box
            component="iframe"
            data-testid="aroya__marketing-iframe"
            display={{ xs: 'none', md: 'block' }}
            gridArea="marketing"
            height="100%"
            width="100%"
            borderWidth={0}
            src="https://addium.marketing/assets/app-banner.html"
            title="marketing"
          />
        ) : null}
      </Box>
    )
  }
}

const Connected = ({ switching = false, ...passthru }) => {
  const connectedProps = useConnect(
    'selectAuth',
    'selectQueryAdvanced',
    'doForgotClearState',
    'doLogin',
    'doLogout',
    'doResetClearState',
  )

  const nextFacilityName = useMemo(() => {
    if (switching) {
      const { facilities } = getTokens()
      const { facility } = getLSM()
      const nextFacility = Array.isArray(facilities)
        ? facilities.find(f => f.id === facility)
        : null
      return nextFacility ? nextFacility.name : ''
    }
    return ''
  }, [switching])

  return (
    <LoginComponent
      {...connectedProps}
      {...passthru}
      switching={switching}
      switchingTo={nextFacilityName}
    />
  )
}
Connected.displayName = 'LoginPage'

const Memoized = memo(Connected)
export default Memoized
