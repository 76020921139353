import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { createAppIsReadySelector } from '~/src/Store/utils'

import urls from './urls'

const harvestYieldsDistributionBundle = createAsyncResourceBundle({
  name: 'harvestYieldsDistribution',
  actionBaseType: 'HARVEST_YIELDS_DISTRIBUTION',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvest)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvest})`))
    }
    const query = store.selectHarvestAnalyticsQueryParams()
    return apiFetch(`/harvests/${harvest}/yields_distribution/`, query)
  },
})

export default {
  ...harvestYieldsDistributionBundle,
  reactHarvestYieldsDistributionFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestYieldsDistributionShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics) {
        return { actionCreator: 'doFetchHarvestYieldsDistribution' }
      }
      return undefined
    },
  }),
}
