import { inflect } from 'inflection'

const eventTemplate = (template, data, rooms, cultivars, defaultValue) => {
  let misses = 0
  const result = (template || '').replace(/{{\s*(\w+)\s*}}/g, (m, key) => {
    if (data?.[key] !== undefined) {
      if (data[key] != null) {
        if (key.indexOf('room') !== -1 && rooms?.[data[key]]?.name !== undefined) {
          return rooms[data[key]].name
        }
        if (key.indexOf('cultivar') !== -1 && cultivars?.[data[key]]?.name !== undefined) {
          return cultivars[data[key]].name
        }
        return String(data[key])
      }
      return ''
    }
    misses += 1
    return ''
  }).replace(/(\d+) (\w+s)/, (_, count, things) => `${count} ${inflect(things, Number(count))}`)
  return misses > 0 ? defaultValue : result
}

const getEventRooms = (data, rooms) => {
  const roomNames = []
  Object.entries(data).forEach(([key, value]) => {
    if (key.toLowerCase().indexOf('room') !== -1) {
      (Array.isArray(value) ? value : [value]).forEach(roomId => {
        if (rooms?.[roomId]?.name !== undefined) {
          roomNames.push(rooms[roomId].name)
        }
      })
    }
  })
  return roomNames
}

const getEventQuantity = (eventType, data, defaultValue = null) => {
  if (data?.count) {
    return `${data.count} ${inflect('plants', data.count)}`
  }
  if (data?.weight) {
    return data?.weight
  }
  if (data?.adjustment) {
    return data?.adjustment
  }
  return defaultValue
}

export const calculateEventFields = (event, eventTypes, rooms, cultivars) => {
  const eventType = eventTypes?.[event?.eventType]

  const eventFields = {
    title: 'Unknown',
    subtitle: null,
    rooms: [],
    quantity: null,
    canSync: false,
    canDownload: false,
    canUndo: false,
  }

  if (!eventType) {
    return eventFields
  }

  const eventData = event?.data ?? {}

  return {
    ...eventFields,
    title: eventTemplate(eventType.title, eventData, rooms, cultivars, eventType.name),
    subtitle: eventTemplate(eventType.subtitle, eventData, rooms, cultivars, null),
    rooms: getEventRooms(eventData, rooms),
    quantity: getEventQuantity(eventType, eventData),
    canSync: event.isApiSync,
    canDownload: event.isCsv,
    canUndo: event.canUndo,
  }
}
