import { setTokens } from '~/src/Lib/Auth'
import createLogger from '~/src/Lib/Logging'
import { NO_DEBOUNCE } from '~/vendor/redux-localstorage-simple'

const logger = createLogger('AccountCreation/bundle')

export const ACCOUNT_CREATION = 'accountCreation'

const initialState = {
  serialNumber: null,
  key: null,
  alreadyHasAccount: false,
  email: null,
  tempUser: undefined,
  facility: null,
  token: false,
}
const ACCOUNT_CREATE = '/account_create'

export const ACTIONS = {
  GATEWAY_CHECKED: 'GATEWAY_CHECKED',
  GATEWAY_CHECK_FAILED: 'GATEWAY_CHECK_FAILED',
  ACCOUNT_CREATION_SUCCEED: 'ACCOUNT_CREATION_SUCCEED',
  ACCOUNT_CREATION_FAILED: 'ACCOUNT_CREATION_FAILED',
  CLEAR_ACCOUNT_TOKEN: 'CLEAR_ACCOUNT_TOKEN',
  CLEAR_ACCOUNT_CREATION: 'CLEAR_ACCOUNT_CREATION',
  VERIFY_EMAIL_SUCCEED: 'VERIFY_EMAIL_SUCCEED',
  VERIFY_EMAIL_FAILED: 'VERIFY_EMAIL_FAILED',
  CREATE_FACILITY_SUCCEED: 'CREATE_FACILITY_SUCCEED',
  CREATE_FACILITY_FAILED: 'CREATE_FACILITY_FAILED',
  SET_PASSWORD_SUCCEED: 'SET_PASSWORD_SUCCEED',
  SET_PASSWORD_FAILED: 'SET_PASSWORD_FAILED',
  SET_HAS_ACCOUNT: 'SET_HAS_ACCOUNT',
  SET_CURRENT_SERIAL_NUMBER: 'SET_CURRENT_SERIAL_NUMBER',
}

export default {
  name: ACCOUNT_CREATION,
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case ACTIONS.GATEWAY_CHECKED:
        return {
          ...state,
          alreadyHasAccount: false,
          serialNumber: action.payload.serialNumber,
          key: action.payload.key,
          token: Boolean(action.payload.token),
        }
      case ACTIONS.ACCOUNT_CREATION_SUCCEED:
        return {
          ...state,
          email: action.payload.email,
          tempUser: action.payload.tempUser,
        }
      case ACTIONS.CREATE_FACILITY_SUCCEED:
        return {
          ...state,
          facility: action.payload.facility
        }
      case ACTIONS.CLEAR_ACCOUNT_TOKEN:
        return {
          ...state,
          alreadyHasAccount: false,
          email: null,
          facility: null,
          tempUser: undefined,
          token: false,
        }
      case ACTIONS.CLEAR_ACCOUNT_CREATION:
        return initialState
      case ACTIONS.SET_HAS_ACCOUNT:
        return {
          ...state,
          alreadyHasAccount: action.payload.alreadyHasAccount
        }
      case ACTIONS.SET_CURRENT_SERIAL_NUMBER:
        return {
          ...state,
          serialNumber: action.payload.serialNumber,
          key: action.payload.key
        }
      default:
        return state
    }
  },
  doSetCurrentSerialNumber: payload => async ({ dispatch }) => dispatch({ type: ACTIONS.SET_CURRENT_SERIAL_NUMBER, payload }),
  doGatewayCheck: payload => async ({ apiFetch, dispatch }) => {
    try {
      const result = await apiFetch(`${ACCOUNT_CREATE}/gateway_check/`, payload, { method: 'POST', authenticated: false })
      if (result) {
        dispatch({ type: ACTIONS.GATEWAY_CHECKED, payload: { ...payload, ...result } })
        setTokens({ access: result.token })
      }
    } catch (error) {
      dispatch({ type: ACTIONS.GATEWAY_CHECK_FAILED, error })
      throw error?.error?.response
    }
    return { success: true }
  },
  doCreateTempAccount: payload => async ({ apiFetch, dispatch }) => {
    let result
    try {
      result = await apiFetch(`${ACCOUNT_CREATE}/create/start/`, payload, {
        method: 'POST',
        authenticated: false
      })
    } catch (error) {
      dispatch({ type: ACTIONS.ACCOUNT_CREATION_FAILED, error })
      throw error
    }
    dispatch({ type: ACTIONS.ACCOUNT_CREATION_SUCCEED, payload: { ...payload, ...result } })
    return result
  },
  doVerifyEmail: payload => async ({ apiFetch, dispatch }) => {
    try {
      await apiFetch(`${ACCOUNT_CREATE}/create/verify/`, payload, {
        method: 'POST',
        authenticated: false
      })
    } catch (error) {
      dispatch({ type: ACTIONS.VERIFY_EMAIL_FAILED, error })
      throw error
    }
    dispatch({ type: ACTIONS.VERIFY_EMAIL_SUCCEED })
    return true
  },
  doCreateAccountFacility: payload => async ({ apiFetch, dispatch, store }) => {
    const { tempUser, alreadyHasAccount } = store.selectAccountCreation()
    let response
    const url = alreadyHasAccount ? `${ACCOUNT_CREATE}/add/facility/` : `${ACCOUNT_CREATE}/create/facility/`
    try {
      response = await apiFetch(url, { ...payload, user: tempUser }, {
        method: 'POST',
        authenticated: false
      })
    } catch (error) {
      dispatch({ type: ACTIONS.CREATE_FACILITY_FAILED, error })
      throw error.error
    }
    dispatch({ type: ACTIONS.CREATE_FACILITY_SUCCEED, payload: response })
    return response
  },
  doSetPassword: payload => async ({ apiFetch, dispatch, store }) => {
    const { tempUser, facility } = store.selectAccountCreation()
    let response
    try {
      response = await apiFetch(
        `${ACCOUNT_CREATE}/create/complete/`,
        {
          password: payload.password1,
          user: tempUser,
          facility
        },
        {
          method: 'POST',
          authenticated: false
        }
      )
    } catch (error) {
      dispatch({ type: ACTIONS.SET_PASSWORD_FAILED, error })
      throw error.error
    }
    dispatch({ type: ACTIONS.SET_PASSWORD_SUCCEED, payload: response })
    return response
  },
  doSetHasAccount: () => ({ type: ACTIONS.SET_HAS_ACCOUNT, payload: { alreadyHasAccount: true } }),
  doSetNotHasAccount: () => ({ type: ACTIONS.SET_HAS_ACCOUNT, payload: { alreadyHasAccount: false } }),
  doClearAccountToken: () => ({ type: ACTIONS.CLEAR_ACCOUNT_TOKEN, meta: { [NO_DEBOUNCE]: true } }),
  doClearAccountCreation: () => ({ type: ACTIONS.CLEAR_ACCOUNT_CREATION }),
  selectAccountCreation: ({ accountCreation }) => accountCreation,
}
