import { groupBy, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import { QUICK_STALE_AFTER } from '~/src/App/constants'
import createListBundle from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { HarvestCultivars as schema } from '~/src/Store/Schemas'

import { createNamespacedLogger } from '../Dashboard/utils/logger'

const logger = createNamespacedLogger('cultivarsBundle')

const initialState = {
  room: null,
  start: null,
  end: null,
}

const ROOM_CULTIVARS_FETCHED_PARAMS = 'ROOM_CULTIVARS_FETCHED_PARAMS'

const {
  selectRoomCultivarsShouldUpdate: selectRoomCultivarsShouldNormallyUpdate,
  ...listBundle
} = createListBundle({
  entityName: 'harvestCultivars',
  name: 'roomCultivars',
  initialState,
  actions: EMPTY_ARRAY,
  fetchReactionPriority: REACTOR_PRIORITIES.HIGH,
  schema,
  fetchHandler: ({ apiFetch, store }) => {
    const { room, start, end } = store.selectRoomHarvestExpectedParams()

    const queryParams = { start }
    if (end) {
      queryParams.end = end
    }
    if (!room) {
      return Promise.reject(new Error('No room selected'))
    }
    store.doRoomCultivarsSetParams({ room, start, end }, { markOutdated: false })
    return apiFetch(`/rooms/${room}/cultivars/`, queryParams)
  },
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(15),
})

export default {
  ...listBundle,
  selectRoomCultivarsByHarvest: createSelector(
    'selectCurrentRoomCultivars',
    groupBy(prop('harvest'))
  ),
  selectRoomCultivarsShouldNormallyUpdate,
  selectRoomCultivarsShouldUpdate: createSelector(
    'selectRoomCultivarsIsLoading',
    'selectRoomCultivarsShouldNormallyUpdate',
    'selectRoomCultivarsLastSuccess',
    'selectRoomCultivarsParams',
    'selectRoomHarvestExpectedParams',
    (alreadyFetching, shouldNormallyUpdate, lastSuccess, fetchedParams, expectedParams = EMPTY_OBJECT) => {
      const { room, start, end } = expectedParams
      if (alreadyFetching || !room || !start) {
        logger.debug('should not update, already fetching or no room or no start')
        return false
      }
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }
      const isQuickStale = (!lastSuccess || (Date.now() - lastSuccess) >= QUICK_STALE_AFTER)
      if (isQuickStale) {
        logger.debug('should update, quick stale?', { isQuickStale, lastSuccess, QUICK_STALE_AFTER })
        return isQuickStale
      }
      const sameRoom = room == fetchedParams.room
      const sameStart = start == fetchedParams.start
      const sameEnd = end == fetchedParams.end
      if (!sameRoom || !sameStart || !sameEnd) {
        logger.debug('should update, params changed', {
          expectedParams,
          fetchedParams,
        })
        return true
      }
      return false
    }
  ),
}
