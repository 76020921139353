import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_ARRAY, EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import urls from './urls'

const initialState = {
  dataType: null,
}

const harvestChartConfidenceBundle = createAsyncResourceBundle({
  name: 'harvestChartConfidence',
  actionBaseType: 'HARVEST_CHART_CONFIDENCE',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest: harvestId } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvestId)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvestId})`))
    }
    const harvest = store.selectHarvests()[harvestId]
    if (!harvest) {
      return Promise.reject(new Error(`Harvest ${harvestId} not found in store`))
    }
    const { dataType } = store.selectHarvestChartConfidenceRaw()
    if (!dataType) {
      return Promise.reject(new Error('Data type required for harvest chart fetch'))
    }
    return apiFetch(`/harvests/${harvestId}/chart/`, {
      grouping: 'ZONE',
      start: getDateTime(harvest.startDate).toUTC().toISO(),
      end: getDateTime(harvest.endDate).toUTC().toISO(),
      dataTypes: [dataType],
      features: ['confidence', 'week_data'],
    })
  },
})

export default {
  ...harvestChartConfidenceBundle,
  reducer: reduceReducers(harvestChartConfidenceBundle.reducer, (state, action) => {
    if (action.type === 'HARVEST_CHART_CONFIDENCE_PARAMS_SET') {
      return {
        ...state,
        ...action.payload,
      }
    }
    if (!Object.keys(initialState).every(key => key in state)) {
      return { ...initialState, ...state }
    }
    return state
  }),
  doHarvestChartConfidenceSetParams: payload => ({ dispatch }) => {
    dispatch({ type: 'HARVEST_CHART_CONFIDENCE_PARAMS_SET', payload })
    if (payload.dataType) {
      dispatch({ actionCreator: 'doMarkHarvestChartConfidenceAsOutdated' })
    }
  },
  reactSetHarvestChartConfidenceParams: createSelector(
    'selectHarvestChartConfidenceRaw',
    'selectTargetRangeDataTypes',
    ({ dataType }, [firstDataType]) => {
      if (!dataType && firstDataType) {
        return { actionCreator: 'doHarvestChartConfidenceSetParams', args: [{ dataType: firstDataType.key }] }
      }
      return undefined
    }
  ),
  reactHarvestChartConfidenceFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestChartConfidenceRaw',
      'selectHarvestChartConfidenceShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: ({ dataType }, shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics && dataType) {
        return { actionCreator: 'doFetchHarvestChartConfidence' }
      }
      return undefined
    },
  }),
  selectHarvestConfidenceChartData: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectHarvestChartConfidenceRaw',
    (harvestId, phasesByHarvest, { data: harvestChartConfidence, dataType }) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !harvestChartConfidence) {
        return EMPTY_OBJECT
      }
      const phases = phasesByHarvest[harvestId]
      const { bounds, data, dataInterval, features } = harvestChartConfidence
      return {
        domain: {
          x: [getDateTime(phases[0].startDate).toJSDate(), getDateTime(phases[phases.length - 1].endDate).toJSDate()],
          y: [bounds.min[dataType], bounds.max[dataType]],
        },
        data: phases.map(
          phase => (data[`${dataType}:room:${phase.room}`] ?? EMPTY_ARRAY)
            .map(datum => ({ ...datum, x: getDateTime(datum.x).toJSDate() }))
            .filter(({ x }) => x >= getDateTime(phase.startDate).toJSDate() && x <= getDateTime(phase.endDate).toJSDate())
        ).flat(),
        confidence: phases.map(
          phase => (features.confidence[`${dataType}:room:${phase.room}`] ?? EMPTY_ARRAY)
            .map(datum => ({ ...datum, x: getDateTime(datum.x).toJSDate() }))
            .filter(({ x }) => x >= getDateTime(phase.startDate).toJSDate() && x <= getDateTime(phase.endDate).toJSDate())
        ).flat(),
        dataInterval,
      }
    },
  ),
  selectHarvestWeekDataByPhase: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectHarvestChartConfidenceRaw',
    'selectHarvests',
    (harvestId, phasesByHarvest, { data: harvestChartConfidence, dataType }, harvests) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !harvestChartConfidence?.features?.week_data) {
        return EMPTY_OBJECT
      }
      const weekData = harvestChartConfidence.features.week_data[dataType]
      if (!weekData || !Array.isArray(weekData)) {
        return EMPTY_OBJECT
      }
      const harvest = harvests[harvestId]
      const harvestStart = getDateTime(harvest.startDate)
      const phases = phasesByHarvest[harvestId]
      return phases.reduce((dbp, phase) => {
        const phaseStart = getDateTime(phase.startDate)
        const phaseEnd = getDateTime(phase.endDate)
        return {
          ...dbp,
          [phase.id]: weekData.reduce((data, week, i) => {
            const weekStart = harvestStart.plus({ days: 7 * i })
            const weekEnd = weekStart.plus({ days: 7 })
            if (phaseStart >= weekEnd || phaseEnd <= weekStart) {
              return data
            }
            let daysInPhase = 7
            if (weekStart < phaseStart) {
              daysInPhase -= Math.round(phaseStart.diff(weekStart).as('days'))
            }
            if (weekEnd > phaseEnd) {
              daysInPhase -= Math.round(weekEnd.diff(phaseEnd).as('days'))
            }
            return [...data, { ...week, daysInPhase }]
          }, EMPTY_ARRAY)
        }
      }, EMPTY_OBJECT)
    },
  ),
}
