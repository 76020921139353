import colorUtil from 'color'
import memoizeOne from 'memoize-one'

import { EMPTY_OBJECT } from './constants'

export const nthLogicStyles = config => config.reduce((acc, { selector, counts, rules }) => ({
  ...acc,
  ...counts.reduce((selectorAcc, count) => {
    const selectorPrefix = `${selector}:first-child:nth-last-child(n+${count})`
    return {
      ...selectorAcc,
      [`${selectorPrefix}, ${selectorPrefix} ~ ${selector}`]: rules
    }
  }, EMPTY_OBJECT)
}), EMPTY_OBJECT)

/**
 * Generates colorScale for VictoryPie
 * @param {number} length
 * @param {string|null} color
 * @param {boolean|null} reverse
 * @returns {array}
 */
export const getColorScale = memoizeOne((length, color = '#ccc', reverse = true) => {
  const maxDark = 1
  const levelDark = maxDark / (length + 2)
  let alpha = 1
  const colors = Array.from({ length }, () => {
    const colorLevel = colorUtil(color).alpha(alpha).string()
    alpha -= levelDark
    return colorLevel
  })
  const arr = Array.from({ length }, (_, i) => colors[i])
  return reverse ? arr.reverse() : arr
})
