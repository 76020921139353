export const DEFAULT_NEXT = '/dashboard'

export const FACILITY_REDIRECT = 'facilityRedirect'
export const FACILITY_ROUTE_PREFIX = '/f/:facility'
export const FACILITY_SEGMENT_PATTERN = /^\/f\/(\d+)/

export const PUBLIC_ROUTES = {
  FORGOT: '/forgot',
  LOGIN: '/login',
  LOGOUT: '/logout',
  REGISTER: '/register',
  RESET: '/reset',
  SWITCHING: '/switching',
}

export const REGISTER_ROUTES = {
  GATEWAY: PUBLIC_ROUTES.REGISTER,
  GATEWAY_SUCCESS: `${PUBLIC_ROUTES.REGISTER}/success`,
  NEW_ACCOUNT: `${PUBLIC_ROUTES.REGISTER}/account/info`,
  FACILITY_INFO: `${PUBLIC_ROUTES.REGISTER}/account/facility`,
  PASSWORD: `${PUBLIC_ROUTES.REGISTER}/account/password`,
  ACCOUNT_SUCCESS: `${PUBLIC_ROUTES.REGISTER}/account/success`,
  ACCOUNT_ERROR: `${PUBLIC_ROUTES.REGISTER}/account/error`,
}
