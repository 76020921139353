import PropTypes from 'prop-types'

import { titleize } from 'inflection'
import { DateTime } from 'luxon'

import { PHASE_TYPES } from '~/src/Harvest/constants'
import { idAndNameShape } from '~/src/Lib/PropTypes'

export const TASK_EDITOR = 'taskEditor'
export const TASK_EDITOR_ROUTE = `/${TASK_EDITOR}/:id`

export const VIEW_TASK = 'view_task'
export const EDIT_TASK = 'edit_task'
export const NEW_TASK = 'new_task'
export const TASKS_MODES = Object.freeze([NEW_TASK, EDIT_TASK, VIEW_TASK])

export const frequencyValues = [
  {
    label: 'Every Day',
    value: 'day',
  },
  {
    label: 'Every Week',
    value: 'week',
  },
  {
    label: 'Every Month',
    value: 'month',
  },
  {
    label: 'Custom',
    value: 'custom',
    triggerMenu: true,
  },
]

export const frequencyUntilValues = phaseType => [
  {
    label: 'end of phase',
    value: 'phase',
  },
  {
    label: `end of ${titleize(phaseType ?? 'stage')}`,
    value: 'stage',
  },
  {
    label: 'end of harvest',
    value: 'harvest',
  },
  {
    label: 'after...',
    value: 'count',
  },
]

export const dimensionsShape = {
  width: PropTypes.shape({
    navRail: PropTypes.number,
    content: PropTypes.number,
    window: PropTypes.number,
  }),
  height: {
    rdHarvestTitle: PropTypes.number,
    rdHarvestTabs: PropTypes.number,
    appBar: PropTypes.number,
    content: PropTypes.number,
    window: PropTypes.number,
  },
  orientation: PropTypes.string,
  scroll: {
    x: PropTypes.number,
    y: PropTypes.number,
  }
}

export const phaseShape = {
  phases: PropTypes.arrayOf(idAndNameShape).isRequired,
  phaseType: PropTypes.oneOf(PHASE_TYPES).isRequired,
}

export const taskShape = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.number,
  parentTask: PropTypes.number,
  relativeStartDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  relativeStartTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(DateTime)]),
  recurrenceRule: PropTypes.shape({
    unit: PropTypes.string,
    interval: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    untilEndOf: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phaseType: PropTypes.string,
  }),
  metadata: PropTypes.shape({
    pesticide: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    applicationRate: PropTypes.string,
    targetPest: PropTypes.string,
  }),
  reminderAmount: PropTypes.number,
  reminderUnit: PropTypes.string,
  priority: PropTypes.number,
}
