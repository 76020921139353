import {
  always,
  curry,
  either,
  map,
  path,
  pipe,
  prop,
  reduce,
  sort,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import reduceReducers from 'reduce-reducers'

import {
  DRYING_FLOW_ROUTE,
  HARVESTING_FLOW_ROUTE,
  PROCESSING_FLOW_ROUTE,
} from '~/src/Flow/constants'
import { urls as harvestUrls } from '~/src/Harvest/bundle'
import createListBundle from '~/src/Lib/createListBundle'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  escapeRegExp,
  parseApiErrors,
  textSort,
} from '~/src/Lib/Utils'

import { URL_ACTION } from '../constants'

const LABEL_DELETE_STARTED = 'LABEL_DELETE_STARTED'
const LABEL_DELETE_FAILED = 'LABEL_DELETE_FAILED'
const LABEL_DELETE_SUCCEED = 'LABEL_DELETE_SUCCEED'

const DIALOG_PATTERNS = [
  /#\/harvesting/,
  /#\/drying/,
  /#\/processing/,
  /#\/lot2plant/,
  /#\/veg2flower/,
  /#\/tags/,
]
const ROUTE_PATTERNS = [
  /^\/harvests\/new/,
  /^\/compliance/,
  /^\/inventory/,
]

const labelSort = sort(textSort)
const { selectCurrentLabelsList: _, reducer: initialReducer, ...initialBundle } = createListBundle({
  actions: EMPTY_ARRAY,
  entityName: 'labels',
  fetchHandler: async ({ apiFetch }) => apiFetch('/labels/').then(
    pipe(
      either(prop('byLicense'), always(EMPTY_ARRAY)),
      reduce((labelsObj, group) => ({
        ...labelsObj,
        [group.license]: {
          ...group,
          packageLabels: labelSort(group.packageLabels ?? EMPTY_ARRAY),
          plantLabels: labelSort(group.plantLabels ?? EMPTY_ARRAY),
        }
      }), EMPTY_OBJECT)
    )
  ),
  flat: true,
  flags: { keys: ['METRC'] },
  initialState: {
    data: { results: EMPTY_OBJECT },
    deleted: EMPTY_ARRAY,
    deleting: EMPTY_ARRAY,
  },
  urlTest: (url, pattern) => (
    ROUTE_PATTERNS.some(routePattern => routePattern.test(pattern))
    || DIALOG_PATTERNS.some(dialogPattern => dialogPattern.test(url))
  ),
})

const actionUrl = either(path(['payload', 'url']), always(''))

const dialogRoutes = [
  HARVESTING_FLOW_ROUTE,
  DRYING_FLOW_ROUTE,
  PROCESSING_FLOW_ROUTE,
  harvestUrls.veg2flower
]
const dialogPattern = new RegExp(`^(${dialogRoutes.map(escapeRegExp).join('|')})`)

export default {
  ...initialBundle,
  getMiddleware: () => curry((store, next, action) => {
    if (actionUrl(action) && action?.type === URL_ACTION) {
      const {
        dialogRouteInfo: previousDialog,
        urlObject: previousUrl
      } = store.select(['selectDialogRouteInfo', 'selectUrlObject'])
      next(action)
      const {
        dialogRouteInfo: nextDialog,
        routeInfo: nextRoute,
        urlObject: nextUrl
      } = store.select(['selectDialogRouteInfo', 'selectRouteInfo', 'selectUrlObject'])
      const changedToFlow = nextDialog?.pattern && previousDialog?.pattern !== nextDialog.pattern
        && dialogPattern.test(nextDialog.pattern)
      const changedToNewHarvest = previousUrl.pathname !== nextUrl.pathname
        && nextRoute.pattern === harvestUrls.create
      if (changedToFlow || changedToNewHarvest) {
        store.dispatch({ actionCreator: 'doMarkLabelsListAsOutdated', args: EMPTY_ARRAY })
      }

      return
    }
    next(action)
  }),
  reducer: reduceReducers(initialReducer, (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
    let newState = state ?? {}

    const deletingNotAnArray = !Array.isArray(state.deleting)
    const deletedNotAnArray = !Array.isArray(state.deleted)
    if (deletingNotAnArray || deletedNotAnArray) {
      newState = {
        ...state,
        deleting: deletingNotAnArray ? EMPTY_ARRAY : state.deleting,
        deleted: deletedNotAnArray ? EMPTY_ARRAY : state.deleted,
      }
    }

    if (!action.type.startsWith('LABEL')) return newState

    switch (action.type) {
      case LABEL_DELETE_STARTED:
        return {
          ...state,
          deleting: [...new Set([...state.deleting, action.payload])]
        }
      case LABEL_DELETE_FAILED:
        return {
          ...state,
          deleting: [...state.deleting].filter(label => label !== action.payload)
        }
      case LABEL_DELETE_SUCCEED:
        return {
          ...state,
          deleted: [...new Set([...state.deleted, action.payload])],
          deleting: [...state.deleting].filter(label => label !== action.payload)
        }
      case 'LABELS_LIST_FETCH_FINISHED':
        return {
          ...state,
          deleted: EMPTY_ARRAY,
        }
      default:
        return newState
    }
  }),
  doLabelsSave: data => async ({ dispatch, apiFetch, store }) => {
    try {
      const response = await apiFetch('/labels/', data, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully saved METRC labels')
      store.doMarkLabelsListAsOutdated()
      return response
    } catch (e) {
      const error = e?.response ? parseApiErrors(e.response) : null
      if (error) store.doAddSnackbarMessage(`Failed to save METRC labels: ${error}`)
      dispatch({ type: 'LABEL_SAVE_FAILED', payload: error ?? e })
      return e
    }
  },
  doLabelDelete: ({ label }) => async ({ dispatch, apiFetch, store }) => {
    dispatch({ type: LABEL_DELETE_STARTED, payload: label })
    try {
      await apiFetch('/labels/', { label }, { method: 'DELETE' })
      store.doAddSnackbarMessage(`Successfully delete ${label} tag`)
    } catch (e) {
      const error = e?.response ? parseApiErrors(e.response) : null
      if (error) store.doAddSnackbarMessage(`Failed to delete ${label} tag`)
      dispatch({ type: LABEL_DELETE_FAILED, payload: label, error })
      return false
    }
    dispatch({ type: 'LABEL_DELETE_SUCCEED', payload: label })
    return true
  },
  doImportLabelsFromFile: data => async ({ dispatch, apiFetch, store }) => {
    try {
      const response = await apiFetch('/labels/excel/', data, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully saved METRC labels')
      store.doMarkLabelsListAsOutdated()
      return response
    } catch (e) {
      const error = e?.response ? parseApiErrors(e.response) : null
      if (error) store.doAddSnackbarMessage(`Failed to save METRC labels: ${error}`)
      dispatch({ type: 'LABEL_SAVE_FAILED', payload: error ?? e })
      return false
    }
  },
  selectCurrentLabelsList: createSelector('selectLabelsList', prop('results')),
  selectPackageLabels: createSelector(
    'selectCurrentLabelsList',
    // from each property in currentLabelsList get its packageLabels property
    map(prop('packageLabels'))
  ),
  selectPlantLabels: createSelector(
    'selectCurrentLabelsList',
    // from each property in currentLabelsList get its plantLabels property
    map(prop('plantLabels'))
  ),
  selectAvailableLabelsCount: createSelector(
    'selectPlantLabels',
    'selectPackageLabels',
    (plantLabels = EMPTY_OBJECT, packageLabels = EMPTY_OBJECT) => (
      Object.values(plantLabels).reduce((count, list) => (Array.isArray(list) ? count + list.length : count), 0)
        + Object.values(packageLabels).reduce((count, list) => (Array.isArray(list) ? count + list.length : count), 0)
    )
  ),
  selectUsedPlantBatchNames: createSelector(
    'selectCurrentLabelsList',
    map(
      // from each property in currentLabelsList
      pipe(
        // get property's usedPlantBatchNames property
        prop('usedPlantBatchNames'),
        // return a Set of all used plant batch names or empty set if none
        used => new Set(used ?? EMPTY_ARRAY)
      )
    )
  ),
  selectUsedHarvestBatchNames: createSelector(
    'selectCurrentLabelsList',
    map(
      // from each property in currentLabelsList
      pipe(
        // get property's usedHarvestBatchNames property
        prop('usedPlantBatchNames'),
        // return a Set of all used harvest batch names or empty set if none
        used => new Set(used ?? EMPTY_ARRAY)
      )
    )
  ),
  selectLabelsDeleted: createSelector(
    'selectLabelsListRaw',
    state => new Set(Array.isArray(state.deleted) ? state.deleted : EMPTY_ARRAY),
  ),
  selectLabelsDeleting: createSelector(
    'selectLabelsListRaw',
    state => new Set(Array.isArray(state.deleting) ? state.deleting : EMPTY_ARRAY),
  ),
  persistActions: [...initialBundle.persistActions, LABEL_DELETE_STARTED, LABEL_DELETE_FAILED, LABEL_DELETE_SUCCEED]
}
