import ms from 'milliseconds'

import createListBundle from '~/src/Lib/createListBundle'
import roomUrls from '~/src/Room/bundle/urls'
import { LightSchedule as schema } from '~/src/Store/Schemas'

const initialState = {
  room: null,
  start: null,
  end: null,
}

export default createListBundle({
  entityName: 'lightSchedule',
  initialState,
  schema,
  retryAfter: ms.seconds(5),
  staleAfter: ms.minutes(60),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/lightSchedules/', params),
  urlTest: (_, pattern) => pattern.startsWith(roomUrls.view),
})
