/**
 * @file Utility functions used in end drying flow state management.
 * @docz_menu ~/src/Flow
 * @docz_name bundle/drying-utils
 */
import {
  getItemsWithColors,
  getSortedItemCategories,
  groupItemsByCategory,
} from '~/src/Flow/bundle/utils'
import createLogger from '~/src/Lib/Logging'
import { convertWeight, EMPTY_OBJECT, WEIGHT_UNIT_SYMBOLS } from '~/src/Lib/Utils'

const logger = createLogger('Flow/drying#utils')

export const getHarvestBatchesReadyForDrying = (harvestBatches, facilityCultivars, items, itemCategories, units) => harvestBatches
  .filter(pckg => {
    const { currentWeight, weightUnit } = pckg
    const { [weightUnit]: hbUnit } = units

    // we filter out harvest batches that have invalid unit or invalid weight
    return Boolean(hbUnit?.symbol && WEIGHT_UNIT_SYMBOLS.find(unitSymbol => unitSymbol === hbUnit.symbol) && currentWeight > 0)
  })
  .map(pckg => {
    const { cultivar, item, currentWeight, readings = [] } = pckg

    const waterActivityReading = readings.find(reading => reading.dataTypeKey === 'wateract') || {}
    const relatedUnitSymbol = items[item] ? units[items[item].unit].symbol : 'no valid unit symbol'

    return {
      ...pckg,
      cultivarName: facilityCultivars[cultivar]?.name || 'No cultivar name',
      currentQuantity: currentWeight, // we map it this way to have similar key throughout the drying/processing flow
      initialItem: items[item] ? {
        ...items[item],
        color: itemCategories[items[item].category]?.color,
        unitSymbol: relatedUnitSymbol,
      } : {},
      item: items[item] ? {
        ...items[item],
        color: itemCategories[items[item].category]?.color,
        unitSymbol: relatedUnitSymbol,
      } : {},
      waterActivityValue: waterActivityReading.value,
    }
  })

export const getAvailableSourcesForDrying = (apiSources, queue, units) => {
  const sourcesWithSubstractedQueuedValues = queue.length ? apiSources.map(source => {
    let { currentQuantity, currentWeight } = source
    const { item: { unit: sUnitId } = {} } = source
    queue.forEach(qSource => {
      const { uuid, unit } = qSource
      if (uuid === source.uuid) {
        currentQuantity -= convertWeight(qSource.currentQuantity, unit, units[sUnitId]?.symbol)
        currentWeight -= convertWeight(qSource.currentWeight, unit, units[sUnitId]?.symbol)
      }
    })
    return { ...source, currentQuantity, currentWeight }
  }) : apiSources

  return sourcesWithSubstractedQueuedValues.filter(src => Boolean(src.currentQuantity))
}

export const formLatestDryingData = props => {
  const {
    harvest,
    apiSources,
    items,
    itemCategories,
    facilityCultivars,
    units,
    inventorySelectedItems,
  } = props

  const sources = getHarvestBatchesReadyForDrying(apiSources, facilityCultivars, items, itemCategories, units)

  const sortedItemCategories = getSortedItemCategories(itemCategories, ['DRIED', 'PROCESSED'])
  const itemsArrWithColors = getItemsWithColors(items, itemCategories, ['DRIED', 'PROCESSED'], [], units)
  const itemsByCategory = groupItemsByCategory(itemsArrWithColors)

  // TODO: ask if we need to preset any drying rooms
  // const driedCategoryItems = itemsArrWithColors.filter(item => item.category === 'DRIED')
  // const initialProcessedRooms = driedCategoryItems.reduce((roomsPerItem, item) => ({ ...roomsPerItem, [item.id]: harvest.dryRoom }), {})

  return {
    sources,
    lastSelectedItem: itemsByCategory.DRIED?.[0], // default requirement
    sortedItemCategories,
    itemsByCategory,
    // processedRooms: initialProcessedRooms,
    processedRooms: {},
    harvestId: harvest?.id,
    inventorySelected: inventorySelectedItems || [],
  }
}

export const getNewDryingQueue = (pckgToAdd, queue) => {
  const indexInQueue = queue.findIndex(elem => elem.id === pckgToAdd.id)
  const newQueue = [...queue]
  // if we've found an index - we need to update an exisitng item in the queue. Otherwise, we need to add a new item at the beginning of the queue
  if (indexInQueue !== -1) {
    newQueue[indexInQueue] = {
      ...pckgToAdd,
      isInQueue: true,
    }
  } else {
    newQueue.unshift({
      ...pckgToAdd,
      isInQueue: true,
    })
  }

  return newQueue
}
export const getAvailableSourcesAfterSubmit = (pckgToAdd, sources) => {
  if (pckgToAdd.isInQueue) {
    return sources
  }

  if (pckgToAdd.finish) {
    return sources.filter(source => source.uuid !== pckgToAdd.uuid)
  }

  return sources
    .map(source => {
      if (source.uuid === pckgToAdd.uuid) {
        const { weight, waste, unit, initialItem } = pckgToAdd
        return {
          ...source,
          currentQuantity: source.currentQuantity - convertWeight(Number(weight) + Number(waste), unit, initialItem?.unitSymbol),
          currentWeight: source.currentWeight - convertWeight(Number(weight) + Number(waste), unit, initialItem?.unitSymbol),
        }
      }

      return source
    })
    .filter(source => Boolean(source.currentQuantity))
}
export const getSourcesAfterDeletingFromQueue = (pckgToBeDeleted, sources) => {
  const { uuid, initialItem, unit } = pckgToBeDeleted
  const relatedHarvestBatchInSource = sources.find(source => source.uuid === uuid)

  if (relatedHarvestBatchInSource) {
    return sources.map(source => {
      if (source.uuid === uuid) {
        return {
          ...source,
          currentQuantity: source.currentQuantity + convertWeight(pckgToBeDeleted.currentQuantity, unit, initialItem.unitSymbol),
          currentWeight: source.currentWeight + convertWeight(pckgToBeDeleted.currentQuantity, unit, initialItem.unitSymbol),
        }
      }

      return source
    })
  }

  return [
    {
      ...pckgToBeDeleted,
      currentQuantity: convertWeight(pckgToBeDeleted.currentQuantity, unit, initialItem.unitSymbol),
      currentWeight: convertWeight(pckgToBeDeleted.currentQuantity, unit, initialItem.unitSymbol),
      item: initialItem,
      uid: '',
      weight: '',
      waste: '',
      isInQueue: false,
    },
    ...sources,
  ]
}

/**
 * Given an array of new package definitions, calculates the sum of quantities taken from each source
 * harvestBatch in grams
 * @param {object[]} queue
 * @param {string} queue[].uuid - harvestBatches_$id
 * @param {string} queue[].unit - One of g, kg, lb, oz
 * @param {number} queue[].weight - New package weight quantity (in unit)
 * @param {number} queue[].waste - Waste quantity generated (in unit)
 * @param {number} queue[].moistureLoss - Calculated moistureLoss when finishing a package (in unit)
 * @returns {object} - A mapping of source harvestBatch uuids to the sum of all weight, waste and moistureLoss for each harvestBatch in the queue
 *  { harvestBatches_123: { quantity: 1000, unit: 'g' } }
 */
export const getQueuedBySource = queue => queue.reduce((queuedBySource, {
  uuid,
  unit,
  weight,
  waste,
  moistureLoss,
}) => {
  const { [uuid]: prev = { quantity: 0 } } = queuedBySource
  const quantity = ((weight || 0) + (waste || 0) + (moistureLoss || 0))
  return {
    ...queuedBySource,
    [uuid]: {
      quantity: prev.quantity + convertWeight(quantity, unit, 'g'),
      unit: 'g',
    }
  }
}, EMPTY_OBJECT)
