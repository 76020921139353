import memoizeOne from 'memoize-one'
import {
  path,
  pipe,
  prop,
  props as pluckAll,
} from 'ramda'

import { scaleTime } from 'd3-scale'

import createLogger from '~/src/Lib/Logging'
import { createShallowEqualsSelector, shallowEquals } from '~/src/Lib/Utils'

import { Y_AXIS_WIDTH } from './constants'
import { getPaddingForChart } from './padding'

const logger = createLogger('Chart#utils')

/**
 * Calculates how far from the right edge of the chart area the graphs should end based on number axes that will be shown.
 * @param {Object} props
 * @param {boolean} props.showYAxis
 * @param {{ yAxes: Object, activeDataType: string }} props.graphsAndAxes
 * @returns {Number} The righthand graph x-offset in pixels
 */
export const getXOffset = createShallowEqualsSelector(
  prop('showYAxis'),
  path(['graphData', 'groups']),
  (showYAxis, groups) => {
    let offset = 0
    if (!showYAxis) {
      return offset
    }
    const yAxisGroups = Object.values(groups)
    const longestGroupCount = yAxisGroups.reduce((largest, group) => {
      if (!group.yAxes) return largest
      return largest > group.yAxes.length ? largest : group.yAxes.length
    }, 0)

    if (longestGroupCount) {
      offset += longestGroupCount * Y_AXIS_WIDTH
    }
    return offset
  }
)

/**
 * Returns an array of the start and end datetimes of a chart request
 * @type {function}
 * @param {{ range: { start: ISO8601TS, end: ISO8601TS } }} chartData
 * @returns {Date[]} x-domain
 */
export const getXDomain = memoizeOne(range => {
  if (!range || !range.start || !range.end) return [0, 0]
  const { start, end } = range
  return [new Date(start), new Date(end)]
}, (left, right) => shallowEquals(left, right, 2))

const xScalePropsPlucker = pipe(prop(0), pluckAll(['chartPadding', 'chartWidth', 'xDomain']))
const xScaleFake = Object.assign(() => 0, { domain: () => [0, 0], range: () => [0, 0] })
/**
 * Returns a d3 timescale function for a given left, width range and start,end x-domain
 * @type {function}
 * @param {{ range: Object, chartWidth: number }} props
 * @returns {function} d3 time-scale function
 */
export const getXScale = memoizeOne(props => {
  const { chartWidth, xDomain } = props

  if (!xDomain[0] || !chartWidth) {
    return xScaleFake
  }
  const chartPadding = props.chartPadding ?? getPaddingForChart(props)
  const { left, right } = chartPadding

  let width = chartWidth
  if (right) {
    width -= right
  }

  return scaleTime(xDomain, [left, width])
}, (left, right) => (
  shallowEquals(xScalePropsPlucker(left), xScalePropsPlucker(right))
))
