import ms from 'milliseconds'

import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { Grade as schema } from '~/src/Store/Schemas'

export const initialState = {
  ...defaultInitialState,
  ordering: ['name', 'asc'],
}

export default createListBundle({
  entityName: 'grade',
  initialState,
  schema,
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/grades/', params),
})
