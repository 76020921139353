export const muiBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  }
}
export const breakpoints = Object.freeze({
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  ...muiBreakpoints.values
})
export const SPACING = 8
export const spacing = (...args) => (args.length ? args.slice(0, 4) : [1]).map(value => `${value * SPACING}px`).join(' ')
