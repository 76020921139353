import { getNewDryingQueue, getQueuedBySource } from '~/src/Flow/bundle/drying/utils'
import { DRYING_FLOW } from '~/src/Flow/constants'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

const INITIALIZE_DRYING_STATE = 'INITIALIZE_DRYING_STATE'
const DRYING_PROCESSED_ROOMS_CHANGE = 'DRYING_PROCESSED_ROOMS_CHANGE'
const DRYING_SOURCE_CLICK = 'DRYING_SOURCE_CLICK'
const DRYING_QUEUED_CLICK = 'DRYING_QUEUED_CLICK'
const DRYING_DESELECT_QUEUED = 'DRYING_DESELECT_QUEUED'
const DRYING_REMOVE_QUEUED = 'DRYING_REMOVE_QUEUED'
const DRYING_REVIEW_CLICK = 'DRYING_REVIEW_CLICK'
const DRYING_REVIEW_DONE = 'DRYING_REVIEW_DONE'
const DRYING_SEARCH_CHANGE = 'DRYING_SEARCH_CHANGE'
const SUBMIT_DRYING_FORM = 'SUBMIT_DRYING_FORM'
const CLEAR_DRYING_SNACKBAR_MESSAGE = 'CLEAR_DRYING_SNACKBAR_MESSAGE'
const RESET_DRYING_STATE = 'RESET_DRYING_STATE'
const REFRESH_DRYING_STATE = 'REFRESH_DRYING_STATE'
const UPDATE_DRYING_FLOW_ID = 'UPDATE_DRYING_FLOW_ID'

export const initState = {
  harvestId: null,
  id: null,
  initialized: false,
  inventorySelected: [],
  itemsByCategory: {},
  lastSelectedItem: null,
  lastSnackbarMessage: null,
  processedRooms: {},
  queue: [],
  queuedBySource: {},
  review: false,
  search: '',
  selected: null,
  selectedItems: [],
  sortedItemCategories: [],
  sources: [],
}

export default {
  name: DRYING_FLOW,
  reducer: (state = initState, action = EMPTY_OBJECT) => {
    const { type, payload } = action
    switch (type) {
      case INITIALIZE_DRYING_STATE: {
        if (state.initialized) {
          const { queue, selected } = state
          return {
            ...state,
            sources: payload.sources,
            sortedItemCategories: payload.sortedItemCategories,
            itemsByCategory: payload.itemsByCategory,
            harvestId: payload.harvestId,
            inventorySelected: payload.inventorySelected,
            queuedBySource: selected
              ? getQueuedBySource(queue.filter(q => q.id !== selected.id))
              : getQueuedBySource(queue)
          }
        }

        return {
          ...state,
          ...payload,
          initialized: true,
        }
      }

      case DRYING_PROCESSED_ROOMS_CHANGE: {
        return {
          ...state,
          processedRooms: {
            ...state.processedRooms,
            [payload.itemTypeId]: payload.room,
          },
        }
      }

      case DRYING_SOURCE_CLICK: {
        return {
          ...state,
          selected: state.selected?.id === payload.id ? null : payload,
        }
      }

      case DRYING_QUEUED_CLICK: {
        const { selected: oldSelected, queue } = state
        const selected = oldSelected?.id === payload.id ? null : payload
        return {
          ...state,
          selected,
          // Remove the selected queued item from the queuedBySource calculation
          // since the queued item's usage of its source will be part of the form state
          // and is available to be used by this queue entry
          queuedBySource: selected
            ? getQueuedBySource(queue.filter(q => q.id !== selected.id))
            : getQueuedBySource(queue)
        }
      }

      case DRYING_DESELECT_QUEUED: {
        return {
          ...state,
          selected: null,
          // Since the queued item is no longer selected, queuedBySource should be calculcated
          // for the entire queue
          queuedBySource: getQueuedBySource(state.queue)
        }
      }

      case DRYING_REMOVE_QUEUED: {
        const { queue: previousQueue } = state
        const queue = previousQueue.filter(queued => queued.id !== payload.id)

        return {
          ...state,
          selected: null,
          selectedItems: [...new Set(queue.map(queued => queued.item))],
          queue,
          queuedBySource: getQueuedBySource(queue),
        }
      }

      case DRYING_REVIEW_CLICK: {
        return {
          ...state,
          review: payload,
        }
      }

      case DRYING_REVIEW_DONE: {
        return {
          ...state,
          selected: null,
          selectedItems: [],
          queue: [],
          review: false,
        }
      }

      case DRYING_SEARCH_CHANGE: {
        return {
          ...state,
          search: payload,
          selected: null,
        }
      }

      case SUBMIT_DRYING_FORM: {
        // in case of new queue item, we need to check if the chosen item type was a new one, or selected earlier by some previous queue item
        const queue = getNewDryingQueue(payload, state.queue)

        return {
          ...state,
          queue,
          search: initState.search,
          selected: null,
          selectedItems: [...new Set(queue.map(queued => queued.item))],
          lastSelectedItem: payload.item,
          queuedBySource: getQueuedBySource(queue),
        }
      }

      case CLEAR_DRYING_SNACKBAR_MESSAGE: {
        return {
          ...state,
          lastSnackbarMessage: null,
        }
      }

      case RESET_DRYING_STATE: {
        return {
          ...initState,
        }
      }

      case REFRESH_DRYING_STATE: {
        return {
          ...state,
          ...payload,
          id: payload.flowId,
        }
      }

      case UPDATE_DRYING_FLOW_ID: {
        return {
          ...state,
          id: payload,
        }
      }

      default: {
        return state
      }
    }
  },
  doInitializeDryingState: initialState => ({ type: INITIALIZE_DRYING_STATE, payload: initialState }),
  doOnDryingProcessedRoomsChange: (itemTypeId, room) => ({ type: DRYING_PROCESSED_ROOMS_CHANGE, payload: { itemTypeId, room } }),
  doOnDryingSourceClick: source => ({ type: DRYING_SOURCE_CLICK, payload: source }),
  doOnDryingQueuedClick: source => ({ type: DRYING_QUEUED_CLICK, payload: source }),
  doDryingDeselectQueued: () => ({ type: DRYING_DESELECT_QUEUED }),
  doDryingRemoveQueued: queued => ({ type: DRYING_REMOVE_QUEUED, payload: queued }),
  doOnDryingReviewClick: () => ({ type: DRYING_REVIEW_CLICK, payload: true }),
  doOnDryingReviewClose: () => ({ type: DRYING_REVIEW_CLICK, payload: false }),
  doOnDryingReviewDone: () => ({ type: DRYING_REVIEW_DONE }),
  doOnDryingSearchChange: search => ({ type: DRYING_SEARCH_CHANGE, payload: search }),
  doOnSubmitDryingForm: processed => ({ type: SUBMIT_DRYING_FORM, payload: processed }),
  doResetDryingState: () => ({ type: RESET_DRYING_STATE }),
  doRefreshDryingState: cloudData => ({ type: REFRESH_DRYING_STATE, payload: cloudData }),
  doUpdateDryingFlowId: id => ({ type: UPDATE_DRYING_FLOW_ID, payload: id }),
  doClearDryingSnackbarMessage: () => ({ type: CLEAR_DRYING_SNACKBAR_MESSAGE }),
  selectDryingState: state => state.drying,
  persistActions: [
    INITIALIZE_DRYING_STATE,
    DRYING_PROCESSED_ROOMS_CHANGE,
    DRYING_SOURCE_CLICK,
    DRYING_QUEUED_CLICK,
    DRYING_DESELECT_QUEUED,
    DRYING_REMOVE_QUEUED,
    DRYING_REVIEW_CLICK,
    DRYING_REVIEW_DONE,
    DRYING_SEARCH_CHANGE,
    SUBMIT_DRYING_FORM,
    CLEAR_DRYING_SNACKBAR_MESSAGE,
    RESET_DRYING_STATE,
    UPDATE_DRYING_FLOW_ID,
  ],
}
