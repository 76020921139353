import { cloneElement, createElement } from 'react'

import PropTypes from 'prop-types'

/**
 * Renders an already rendered or unrendered icon with default color prop
 * @component
 * @alias Icon
 */
const IconComponent = ({ icon, ...props }) => {
  if (
    typeof icon === 'object'
    && (
      icon?.$$typeof === Symbol.for('react.element')
      || icon?.$$typeof === Symbol.for('react.fragment')
    )
  ) {
    return cloneElement(icon, { color: 'primary', ...props })
  }

  return createElement(icon, { color: 'primary', ...props })
}
IconComponent.displayName = 'Icon'
IconComponent.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]).isRequired,
}

export default IconComponent
