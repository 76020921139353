import { memo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { MailOutline as MailOutlineIcon, VpnKey as KeyIcon } from '@mui/icons-material'
import { Box, InputAdornment } from '@mui/material'

import { Allowed } from '~/src/Flags'
import createLogger from '~/src/Lib/Logging'
import Button from '~/src/UI/Shared/Button'
import FormikField from '~/src/UI/Shared/Form/FormikField'
import TextField from '~/src/UI/Shared/Form/TextField'
import Icon from '~/src/UI/Shared/Icon'
import PageTitle from '~/src/UI/Shared/PageTitle'
import T from '~/src/UI/Shared/Typography'

import { PUBLIC_ROUTES, REGISTER_ROUTES } from '../constants'

const displayName = 'LoginForm'
const logger = createLogger(displayName)

const LoginForm = ({
  alreadyHasAccount,
  email,
  handleGoBack,
  isFormValid,
  loginProcessing,
  onSubmit,
  password,
  setState,
  token
}) => (
  <>
    <Box data-testid="login-route" width="100%" paddingBottom={3}>
      <PageTitle disablePortal titleText={i18n`Login`} title={i18n`Please login to continue`} align="center" />
    </Box>
    <Box
      aria-label={i18n`AROYA login`}
      component="form"
      px={{ xs: 0, sm: 'calc(50% - 250px)' }}
      onSubmit={onSubmit}
    >
      <Box component="fieldset" border="none">
        <Allowed setting="ACCOUNT_CREATION">
          {!alreadyHasAccount && (
            <Box display="flex" gap="1ch" alignItems="center">
              <T.Body.Bold>{i18n`Don't have an account?`}</T.Body.Bold>
              <Button variant="text" href={`${REGISTER_ROUTES.GATEWAY}`}>
                <T.Bold color="primary">{i18n`Create AROYA GO account.`}</T.Bold>
              </Button>
            </Box>
          )}
        </Allowed>
        <FormikField
          margin="normal"
          required={i18n`Please enter an email address`}
          type="email"
          id="email"
          name="email"
          label={i18n`Email Address`}
          value={email}
          onChange={setState}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon={MailOutlineIcon} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          required
          margin="normal"
          id="password"
          name="password"
          type="password"
          label={i18n`Password`}
          value={password}
          onChange={setState}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon={KeyIcon} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mt={3} textAlign="center">
        <Button
          type="submit"
          disabled={loginProcessing || !isFormValid}
        >
          {i18n`Login`}
        </Button>
      </Box>
      <Box display="flex" mt="2.5rem" justifyContent={token ? 'space-between' : 'center'}>
        {token ? (
          <Button
            onClick={handleGoBack}
            variant="text"
          >
            {i18n`Go Back`}
          </Button>
        ) : null}
        <Button
          variant="text"
          href={PUBLIC_ROUTES.FORGOT}
        >
          {i18n`Forgot your password?`}
        </Button>
      </Box>
    </Box>
  </>
)

LoginForm.displayName = displayName
LoginForm.propTypes = {
  alreadyHasAccount: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  loginProcessing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  token: PropTypes.bool.isRequired
}

const Connected = props => {
  const {
    accountCreation: { key: _, ...accountCreation },
    doSetNotHasAccount,
    doUpdateUrl
  } = useConnect(
    'selectAccountCreation',
    'doSetNotHasAccount',
    'doUpdateUrl'
  )

  const handleGoBack = async () => {
    await doSetNotHasAccount()
    await doUpdateUrl(REGISTER_ROUTES.GATEWAY_SUCCESS)
  }

  return (
    <LoginForm
      handleGoBack={handleGoBack}
      {...accountCreation}
      {...props}
    />
  )
}

const Memoized = memo(Connected)
export default Memoized
