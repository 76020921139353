import { Box } from '@mui/material'

import AbsoluteHumidity from './AbsoluteHumidity'
import AirTemp from './AirTemp'
import CO2 from './CO2'
import DLI from './DLI'
import Drain from './Drain'
import Drip from './Drip'
import Dryback from './Dryback'
import LightIntensity from './LightIntensity'
import LightsOn from './LightsOn'
import PoreEC from './PoreEC'
import RelativeHumidity from './RelativeHumidity'
import SoilTemp from './SoilTemp'
import VPD from './VPD'
import WaterContent from './WaterContent'

/* eslint-disable babel/camelcase */
export const dataTypeMap = {
  abs_hum: AbsoluteHumidity,
  air_temp: AirTemp,
  co2: CO2,
  dfi: Drip,
  dfi_drain: Drain,
  dli: DLI,
  dryback: Dryback,
  dryback_duration: Dryback,
  dryback_percentage: Dryback,
  flow_vol: Drip,
  flow_vol_drain: Drain,
  pore_ec: PoreEC,
  pore_ec_drain: Drain,
  pore_ec_drip: Drip,
  pore_ec_emulated: PoreEC,
  rel_hum: RelativeHumidity,
  soil_moist: WaterContent,
  soil_temp: SoilTemp,
  soil_temp_drain: Drain,
  soil_temp_drip: Drip,
  solar: LightsOn,
  solar_pv: LightsOn,
  solar_quantum: LightIntensity,
  vpd: VPD,
  water_ec: PoreEC,
  water_potential: ({ color, htmlColor }) => (
    <Box
      width="24px"
      height="24px"
      display="inline-flex"
      justifyContent="center"
      alignContent="baseline"
      fontSize="18px"
      color={htmlColor || color}
    >
      &#x3a8;
    </Box>
  ),
  water_temp: SoilTemp,
}
/* eslint-enable babel/camelcase */
