import { SvgIcon } from '@mui/material'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

export const path = {
  droplets: `
    M10 17.581C10 19.47 8.433 21 6.5 21S3 19.47 3 17.581C3 14.248 6.5 11 6.5 11s3.5 3.333 3.5 6.581z
    M15 19.949C15 21.082 14.105 22 13 22s-2-.918-2-2.051c0-2 2-3.949 2-3.949s2 2 2 3.949z
  `,
  wind: `
    M10 11c0-.552.448-1 1-1h6.667C19.57 10 21 11.633 21 13.5S19.572 17 17.667 17c-1.905 0-3.334
    -1.633-3.334-3.5 0-.552.448-1 1-1 .553 0 1 .448 1 1 0 .895.661 1.5 1.334 1.5.672 0 1.333-.605
    1.333-1.5s-.66-1.5-1.333-1.5H11c-.552 0-1-.448-1-1zM3 8c0 .552.448 1 1 1h14.148C20.323 9 22
    7.16 22 5s-1.677-4-3.852-4c-2.174 0-3.852 1.84-3.852 4 0 .552.448 1 1 1 .553 0 1-.448 1-1 0
    -1.153.877-2 1.852-2C19.124 3 20 3.847 20 5s-.876 2-1.852 2H4c-.552 0-1 .448-1 1z
  `
}
export const RHIconComponent = props => {
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { rel_hum: dataType = {} } = dataTypes
  const { color = 'palevioletred' } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path d={path.droplets} fill={fill} />
      <path clipRule="evenodd" d={path.wind} fill={fill} fillRule="evenodd" />
    </SvgIcon>
  )
}

export default RHIconComponent
