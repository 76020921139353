import { SvgIcon } from '@mui/material'

const path = `
M10.197 7.07a5.17 5.17 0 0 1 1.369-1.901A4.661 4.661 0 0 1 14.637 4c.61 0 1.19.12 1.723.334
a4.93 4.93 0 0 1 2.283 1.92 5.477 5.477 0 0 1 .747 4.093c.67.294 1.253.768 1.696 1.364.572.768.914
1.74.914 2.793 0 1.241-.472 2.365-1.234 3.179-.762.814-1.815 1.317-2.977 1.317H5.653c-1.008 0
-1.922-.437-2.582-1.143a4.026 4.026 0 0 1-1.052-3.136c.09-.997.529-1.878 1.179-2.508a3.55 3.55 0 0
1 1.986-.983 3.973 3.973 0 0 1 .562-2.312c.246-.407.56-.76.923-1.046a3.715 3.715 0 0 1 1.205-.639
3.36 3.36 0 0 1 2.227.081c.03-.082.062-.164.096-.244ZM8.46 9.145c.302-.091.61-.086.91.031A2 2 0 0 0
11.98 8a3.19 3.19 0 0 1 .897-1.32l.002-.002A2.662 2.662 0 0 1 14.637 6c.346 0 .672.067.976.189.53
.214 1.003.611 1.349 1.147M8.46 9.146a1.717 1.717 0 0 0-.553.296 1.916 1.916 0 0 0-.45.51 1.973
1.973 0 0 0-.278 1.152 2 2 0 0 1-1.724 2.107c-.306.043-.61.189-.866.438-.305.296-.532.73-.58 1.251
a2.027 2.027 0 0 0 .52 1.59l.001.001c.312.334.71.509 1.122.509H17.79c.566 0 1.103-.241 1.516-.683
l.002-.002c.417-.445.693-1.08.693-1.81 0-.619-.2-1.173-.518-1.6a2.258 2.258 0 0 0-.896-.727 2 2 0 0
1-1.152-2.249 3.451 3.451 0 0 0-.472-2.593
`
export const CO2Icon = props => (
  <SvgIcon {...props}>
    <path clipRule="evenodd" d={path} fillRule="evenodd" />
  </SvgIcon>
)
CO2Icon.displayName = 'Icon/CO2'

export default CO2Icon
