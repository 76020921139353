import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import { QUICK_STALE_AFTER } from '~/src/App/constants'
import createListBundle from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT, shallowEquals } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { Harvest as schema } from '~/src/Store/Schemas'

import { createNamespacedLogger } from '../Dashboard/utils/logger'
import { selectFrom, selectTo } from './selectors'

const logger = createNamespacedLogger('harvestsBundle')

const initialState = {
  room: null,
  start: null,
  end: null,
}

const {
  selectRoomHarvestsShouldUpdate: selectRoomHarvestsShouldNormallyUpdate,
  ...listBundle
} = createListBundle({
  entityName: 'harvests',
  name: 'roomHarvests',
  initialState,
  actions: EMPTY_ARRAY,
  fetchReactionPriority: REACTOR_PRIORITIES.HIGH,
  schema,
  fetchHandler: ({ apiFetch, store }) => {
    const { room, start, end } = store.selectRoomHarvestExpectedParams()

    const queryParams = { start }
    if (end) {
      queryParams.end = end
    }
    if (!room) {
      return Promise.reject(new Error('No room selected'))
    }
    store.doRoomHarvestsSetParams({ room, start, end }, { markOutdated: false })
    return apiFetch(`/rooms/${room}/harvests/`, queryParams)
  },
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
})

export default {
  ...listBundle,
  selectRoomHarvestsShouldNormallyUpdate,
  selectRoomHarvestsShouldUpdate: createSelector(
    'selectRoomHarvestsIsLoading',
    'selectRoomHarvestsShouldNormallyUpdate',
    'selectRoomHarvestsLastSuccess',
    'selectRoomHarvestsParams',
    'selectRoomHarvestExpectedParams',
    (alreadyFetching, shouldNormallyUpdate, lastSuccess, fetchedParams, expectedParams = EMPTY_OBJECT) => {
      const { room, start } = expectedParams
      if (alreadyFetching || !room || !start) {
        logger.debug('should not update, already fetching or no room or no start', {
          alreadyFetching,
          room,
          start,
        })
        return false
      }
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }
      if (!shallowEquals(expectedParams, fetchedParams)) {
        logger.debug('should update, params changed', {
          expectedParams,
          fetchedParams,
        })
        return true
      }
      const isQuickStale = (!lastSuccess || (Date.now() - lastSuccess) >= QUICK_STALE_AFTER)
      if (isQuickStale) {
        logger.debug('should update, quick stale?', { isQuickStale })
      }
      return isQuickStale
    }
  ),
  selectRoomHarvestExpectedParams: createSelector(
    'selectRoomDashboardRoom',
    'selectRoomDashboardState',
    (room, state) => {
      if (!room?.id) return EMPTY_OBJECT
      const startDT = selectFrom(state)
      const endDT = selectTo(state)
      return {
        room: room.id,
        start: startDT && startDT.toISODate(),
        end: endDT && endDT.toISODate(),
      }
    }
  ),
}
