import { useCallback, useEffect, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import ConfirmationDialog from '~/src/UI/Shared/ConfirmationDialog'

const logger = createLogger('Dialog/NotFound')
const mockLocation = Object.freeze({ hash: '' })

const NotFound = ({ dialogRouteInfo, doCloseDialogRoute }) => {
  const [show, setShow] = useState(false)
  const handleClose = useCallback(() => {
    setShow(false)
    doCloseDialogRoute()
  }, [doCloseDialogRoute])
  useEffect(() => {
    setShow(false)
    const timeoutID = setTimeout(() => {
      setShow(true)
    }, 333)
    return () => clearTimeout(timeoutID)
  }, [dialogRouteInfo.pattern])
  const location = getGlobal().location ?? mockLocation
  if (!show || !dialogRouteInfo.url || !location.hash) return null
  console.warn(`[NotFound] No dialog route handler for #${dialogRouteInfo.url}`)

  return show ? (
    <ConfirmationDialog
      noCancel
      open
      title="Dialog Not Found"
      confirmText="OK"
      onClose={handleClose}
      onConfirm={handleClose}
    />
  ) : null
}

const Wrapped = props => {
  const { dialogRouteInfo, doCloseDialogRoute } = useConnect('selectDialogRouteInfo', 'doCloseDialogRoute')
  if (dialogRouteInfo && dialogRouteInfo.pattern !== '*') {
    const { value: DialogComponent } = dialogRouteInfo
    if (typeof DialogComponent === 'function') {
      return <DialogComponent {...props} />
    }
  }
  return (
    <NotFound
      key={dialogRouteInfo.url}
      dialogRouteInfo={dialogRouteInfo}
      doCloseDialogRoute={doCloseDialogRoute}
    />
  )
}

export default Wrapped
