import * as actions from '~/src/Flow/bundle/harvesting/actions'
import { EPOCH_DATETIME } from '~/src/Lib/Constants'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'

import { reconcileStateAndFlow } from '../utils'
import { setModifiedAt } from './utils'

export const SHARED_INITIAL_STATE = {
  flowSaveError: null,
  flowSaving: 0,
  id: null,
  lastSnackbarMessage: null,
  offlineWarningAcknowledged: false,
  processedRooms: EMPTY_OBJECT,
  queue: EMPTY_ARRAY,
  queuedSources: EMPTY_OBJECT,
  reconciling: 0,
  review: false,
  savedAt: EPOCH_DATETIME,
  selected: null,
  settings: EMPTY_OBJECT,
  sources: EMPTY_ARRAY,
  splitHarvest: false,
  stateModified: EPOCH_DATETIME,
}

const SHARED_REDUCERS = Object.entries({
  [actions.HARVESTING_ACKNOWLEDGE_OFFLINE_WARNING]: state => ({
    ...state,
    offlineWarningAcknowledged: true,
  }),
  [actions.HARVESTING_FLOW_SAVE_START]: state => ({
    ...state,
    flowSaving: Date.now(),
    flowSaveError: null,
  }),
  [actions.HARVESTING_FLOW_SAVE_SUCCEED]: (state, payload, meta) => {
    const { id } = payload
    return {
      ...state,
      ...reconcileStateAndFlow(state, payload, meta?.which),
      flowSaving: 0,
      savedAt: new Date().toISOString(),
      id,
    }
  },
  [actions.HARVESTING_FLOW_SAVE_FAIL]: (state, ignore1, ignore2, error) => ({
    ...state,
    flowSaving: 0,
    flowSaveError: error,
  }),
  [actions.HARVESTING_FLOW_SAVE_RETRY]: state => ({
    ...state,
    flowSaving: 0,
    flowSaveError: null,
  }),
  [actions.HARVESTING_QUEUED_CLICK]: (state, payload) => {
    const { selected: previousSelected } = state
    return {
      ...state,
      selected: previousSelected?.id === payload.id ? null : payload,
    }
  },
  [actions.HARVESTING_DESELECT_SOURCES]: state => ({ ...state, selected: null }),
  [actions.HARVESTING_DESELECT_QUEUED]: state => ({ ...state, selected: null }),
  [actions.HARVESTING_SPLIT_HARVEST]: (state, payload) => ({
    ...state,
    splitHarvest: payload,
  }),
  [actions.HARVESTING_REVIEW_CLICK]: (state, payload) => ({
    ...state,
    review: payload,
  }),
  [actions.HARVESTING_CLEAR_SNACKBAR_MESSAGE]: state => ({
    ...state,
    lastSnackbarMessage: null,
  }),
  [actions.HARVESTING_UPDATE_FLOW_ID]: (state, payload) => ({
    ...state,
    id: payload,
  }),
  // some of the settings are set in redux globally to share across the app, but some of the settings are specific for the current harvesting flow
  [actions.HARVESTING_FLOW_SETTINGS]: (
    state,
    payload,
  ) => {
    const { room, ...otherSettings } = payload

    return {
      ...state,
      processedRooms: room || state.processedRooms,
      settings: {
        ...state.settings,
        ...otherSettings,
      },
    }
  },
  [actions.HARVESTING_RECONCILING_FLOW]: state => ({
    ...state,
    reconciling: Date.now(),
  }),
  [actions.HARVESTING_RECONCILING_FLOW_RESET]: state => ({
    ...state,
    reconciling: 0,
  }),
}).reduce((acc, [key, reducer]) => ({
  ...acc,
  [key]: setModifiedAt(reducer),
}), {})

export default SHARED_REDUCERS
