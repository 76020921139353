/* eslint-disable no-restricted-imports */
import { PureComponent } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { trim } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { MailOutline as MailOutlineIcon } from '@mui/icons-material'
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material'

import { errorMessagePropType } from '~/src/Lib/PropTypes'
import { isValidEmail } from '~/src/Store/bundles/me/shape'
import Button from '~/src/UI/Shared/Button'
import FormikField from '~/src/UI/Shared/Form/FormikField'
import Icon from '~/src/UI/Shared/Icon'
import PageTitle from '~/src/UI/Shared/PageTitle'

import { PUBLIC_ROUTES } from '../constants'

export class ForgotComponent extends PureComponent {
  static propTypes = {
    doUpdateUrl: PropTypes.func.isRequired,
    doForgotPassword: PropTypes.func.isRequired,
    doForgotClearState: PropTypes.func.isRequired,
    tokenValidationResponse: PropTypes.shape({
      loading: PropTypes.bool,
      response: PropTypes.shape({
        valid: PropTypes.bool,
        email: PropTypes.string,
      })
    }).isRequired,
    auth: PropTypes.shape({
      forgot: PropTypes.shape({
        error: errorMessagePropType,
        loading: PropTypes.bool,
      }),
    }).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      expiredEmail: '',
    }
    this.setState = this.setState.bind(this)
  }

  componentDidMount() {
    this.props.doForgotClearState()
    const { loading, response } = this.props.tokenValidationResponse
    const { valid, email } = response

    if (!loading && !valid && email) {
      this.setState({
        expiredEmail: email,
        email
      })
    }
  }

  onSubmit = async e => {
    e.preventDefault()
    if (this.isFormValid()) {
      await this.props.doForgotPassword(this.state)
      this.props.doUpdateUrl(PUBLIC_ROUTES.LOGIN)
    }
  }

  isFormValid = () => {
    const email = trim(this.state.email)
    return Boolean(email && isValidEmail(email))
  }

  render() {
    const {
      auth: {
        forgot: { loading, error },
      },
    } = this.props

    return (
      <Grid
        aria-label={i18n`Forgot password`}
        data-testid="password-forgot-route"
        container
        component="section"
        spacing={0}
      >
        {this.state.expiredEmail || error ? (
          <div className="aroya-login-banner">
            <Typography
              className="alert alert-danger"
              sx={{
                lineHeight: { xs: 1.2, sm: 1.75 },
                fontSize: { xs: 14, sm: 16 },
              }}
              variant="subtitle1"
            >
              {this.state.expiredEmail
                ? i18n`Your email link has expired, click resend email to generate a new link.`
                : null}
              {error || null}
            </Typography>
          </div>
        ) : null}
        <Grid item xs={12} sx={{ paddingBottom: theme => theme.spacing(3) }}>
          <PageTitle disablePortal title={i18n`Reset your password`} align="center" />
        </Grid>
        <Grid item xs={12}>
          <Box
            component="form"
            mx={2}
            px={{ xs: 0, sm: 'calc(50% - 250px)' }}
            onSubmit={this.onSubmit}
          >
            <Box component="fieldset" border="none">
              <FormikField
                required={i18n`Please enter an email address`}
                type="email"
                margin="normal"
                id="email"
                name="email"
                label={i18n`Email Address`}
                value={this.state.email}
                onChange={this.setState}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon={MailOutlineIcon} />
                    </InputAdornment>
                  ),
                }}
              />

              <Box mt={3} textAlign="center">
                <Button type="submit" disabled={loading || !this.isFormValid()}>
                  {this.state.expiredEmail ? i18n`Resend email` : i18n`Reset your password`}
                </Button>
              </Box>
              {this.state.expiredEmail ? null : (
                <Box mt={2.5} textAlign="center" color="text.primary">
                  <Button color="inherit" variant="text" href={PUBLIC_ROUTES.LOGIN}>
                    {i18n`Already have an account?`}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

const Connected = () => {
  const connectedProps = useConnect(
    'doUpdateUrl',
    'doForgotPassword',
    'doForgotClearState',
    'selectTokenValidationResponse',
    'selectAuth',
  )
  return <ForgotComponent {...connectedProps} />
}

export default Connected
/* eslint-enable no-restricted-imports */
