import i18n from 'i18n-literally'

export const SUBSTRATE_PREFERRED_DATATYPES = ['pore_ec', 'soil_moist', 'soil_temp']
Object.freeze(SUBSTRATE_PREFERRED_DATATYPES)
export const CLIMATE_PREFERRED_DATATYPES = ['rel_hum', 'air_temp', 'vpd']
Object.freeze(CLIMATE_PREFERRED_DATATYPES)

export const CATEGORY_CATALOG = {
  CLIMATE: i18n`Climate`,
  SUBSTRATE: i18n`Soil/Substrate`,
  IRRIGATION: i18n`Irrigation`,
  IRRIGATION_FLOW: i18n`Irrigation DFI`,
  OTHER: i18n`Other`,
}

export const climatePattern = /ENVIRONMENT|CLIMATE/
export const irrigationPattern = /IRRIGATION/
export const substratePattern = /SOIL|SUBSTRATE/
