/**
 * @file Utility functions used in processing flow state management.
 * @docz_menu ~/src/Flow
 * @docz_name bundle/processing-utils
 */
// TODO: memoize utils
import { add } from 'ramda'

import {
  getItemsWithColors,
  getSortedItemCategories,
  groupItemsByCategory,
} from '~/src/Flow/bundle/utils'
import { convertWeight, EMPTY_OBJECT, WEIGHT_UNIT_SYMBOLS } from '~/src/Lib/Utils'

export const getAllSourcesFromQueuedEntities = queue => queue.reduce((allQueuedSources, queuedEntity) => {
  const { item, sources } = queuedEntity
  const sourcesWithRelatedItem = sources.map(s => ({ ...s, outputPckgItem: item }))
  return [...allQueuedSources, ...sourcesWithRelatedItem]
}, [])

export const getPackagesReadyForProcessing = (packages, facilityCultivars, items, itemCategories, units) => packages
  .filter(pckg => {
    const { currentQuantity, quantityUnit } = pckg
    const { [quantityUnit]: pckgUnit } = units

    // we filter out packages that have invalid unit or invalid weight
    return Boolean(pckgUnit?.symbol && WEIGHT_UNIT_SYMBOLS.find(unitSymbol => unitSymbol === pckgUnit.symbol) && currentQuantity > 0)
  })
  .map(pkg => {
    const { cultivar, item } = pkg ?? EMPTY_OBJECT
    const relatedUnitSymbol = items[item] ? units[items[item].unit].symbol : 'no valid unit symbol'

    return {
      ...pkg,
      cultivarName: facilityCultivars[cultivar]?.name || 'No cultivar name',
      item: items[item] ? {
        ...items[item],
        color: itemCategories[items[item].category]?.color,
        unitSymbol: relatedUnitSymbol,
      } : {},
    }
  })

export const formLatestProcessingData = props => {
  const {
    harvest,
    apiSources,
    // selectedSources,
    // existingQueue,
    items,
    itemCategories,
    facilityCultivars,
    units,
    inventorySelectedItems,
  } = props

  const sources = getPackagesReadyForProcessing(apiSources, facilityCultivars, items, itemCategories, units)

  const sortedItemCategories = getSortedItemCategories(itemCategories, ['DRIED', 'PROCESSED'])
  const itemsArrWithColors = getItemsWithColors(items, itemCategories, ['DRIED', 'PROCESSED'], [], units)
  const itemsByCategory = groupItemsByCategory(itemsArrWithColors)

  // TODO: ask if we need to preset any processing rooms
  // const processingCategoryItems = itemsArrWithColors.filter(item => item.category === 'PROCESSED')
  // const initialProcessedRooms = processingCategoryItems.reduce((roomsPerItem, item) => ({ ...roomsPerItem, [item.id]: harvest.dryRoom }), {})

  return {
    sources,
    lastSelectedItem: itemsByCategory?.PROCESSED?.[0], // default requirement
    sortedItemCategories,
    itemsByCategory,
    // processedRooms: initialProcessedRooms,
    processedRooms: {},
    harvestId: harvest.id,
    inventorySelected: inventorySelectedItems || [],
  }
}

export const checkIfSourceIsAlreadySelected = (sourceId, selectedSources) => selectedSources.find(source => source.id === sourceId)
export const getSelectedSources = (selectedSource, alreadySelectedSources) => {
  const isAlreadySelected = checkIfSourceIsAlreadySelected(selectedSource.id, alreadySelectedSources)
  return isAlreadySelected ? alreadySelectedSources.filter(source => source.id !== selectedSource.id) : [...alreadySelectedSources, selectedSource]
}
export const getNotSelectedSources = (selectedSource, alreadySelectedSources, sourcesToBeSelected) => {
  const isAlreadySelected = checkIfSourceIsAlreadySelected(selectedSource.id, alreadySelectedSources)
  return isAlreadySelected ? [...sourcesToBeSelected, selectedSource] : sourcesToBeSelected.filter(source => source.id !== selectedSource.id)
}
// if we are deleting the last queued package of a specific item - we need to remove that item because there are no related queued packages. Otherwise, we keep the selectedItems arr as is
export const getSelectedItems = (queuedPackageToRemove, previousQueue, previousSelectedItems) => {
  const queueOfSameItem = previousQueue.filter(queued => queued.id !== queuedPackageToRemove.id && queued.item.id === queuedPackageToRemove.item.id)
  return queueOfSameItem.length ? previousSelectedItems : previousSelectedItems.filter(item => item.id !== queuedPackageToRemove.item.id)
}

export const findEntityWhereTheScannedBarcodeExists = (scannedBarcode, queue) => (
  scannedBarcode
    ? queue.find(
      queueItem => queueItem.sources.find(source => (
        source.label && source.label.toLowerCase() === scannedBarcode.toLowerCase()
      ))
    )
    : undefined
)
export const findBarcodeInSources = (scannedBarcode, sources, search) => (
  scannedBarcode && sources?.length && search?.length
    ? sources.filter(pckg => {
      const { label } = pckg
      return label && label.toLowerCase().includes(search.toLowerCase())
    }).find(source => source.label && source.label.toLowerCase() === scannedBarcode.toLowerCase())
    : undefined
)

export const getNewQueue = (pckgToAdd, queue) => {
  const indexInQueue = queue.findIndex(elem => elem.id === pckgToAdd.id)
  const newQueue = [...queue]
  // if we've found an index - we need to update an exisitng item in the queue. Otherwise, we need to add a new item at the beginning of the queue
  if (indexInQueue !== -1) {
    newQueue[indexInQueue] = { ...pckgToAdd, isInQueue: true }
  } else {
    newQueue.unshift({ ...pckgToAdd, isInQueue: true })
  }

  return newQueue
}

/**
 * Given an array of new package definitions, calculates the sum of quantities taken from each source
 * in grams
 * @param {object[]} queue
 * @param {string} queue[].unit - One of g, kg, lb, oz
 * @param {object} queue[].packageWeightDistribution - One of g, kg, lb, oz
 * @param {string} queue[].packageWeightDistribution[].uuid - packages_$id
 * @param {number} queue[].packageWeightDistribution[].weight - New package weight quantity (in unit)
 * @param {number} queue[].packageWeightDistribution[].waste - Waste quantity generated (in unit)
 * @param {number} queue[].packageWeightDistribution[].moistureLoss - Calculated moistureLoss when finishing a harvest batch (in unit)
 * @returns {object} - A mapping of source package uuids to the sum of all weight, waste and moistureLoss for each package in the queue
 *  { packages_123: { quantity: 1000, unit: 'g' } }
 */
export const getQueuedBySource = queue => queue.reduce((queuedBySource, {
  unit,
  packageWeightDistribution: pwd
}) => Object.entries(pwd).reduce((qbs, [id, queued]) => {
  const { [id]: prev = { quantity: 0 } } = qbs
  const { weight, waste, moistureLoss } = queued

  return {
    ...qbs,
    [id]: {
      // add current entry in packageWeightDistribution to any previously found
      // weight distributions
      quantity: prev.quantity + convertWeight(
        // sum weight, waste and moistureLoss
        [weight, (waste || 0), (moistureLoss || 0)].reduce(add),
        unit,
        'g'
      ),
      unit: 'g',
    }
  }
}, queuedBySource), EMPTY_OBJECT)
