import { useEffect, useRef } from 'react'

import { EMPTY_OBJECT } from '~/src/Lib/Utils/constants'

export const usePrevious = value => {
  const prev = useRef(EMPTY_OBJECT)

  useEffect(() => {
    prev.current = value
  }, [value])

  return prev.current
}
usePrevious.INITIAL_STATE = EMPTY_OBJECT
