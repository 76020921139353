import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import config from '~/src/App/config'
import safeStorage from '~/src/Lib/safeStorage'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { getStartDate } from './chart'
import { isPerformanceRoute } from './utils'

export const PHASES = ['prop', 'veg', 'flower']
export const CATEGORIES = ['wet', 'dried', 'processed']
export const STEPS = {
  METRC: [...PHASES, ...CATEGORIES],
  NONMETRC: [...PHASES, CATEGORIES[0]],
}

const storage = safeStorage()

const facilityInventoryBundle = createAsyncResourceBundle({
  name: 'facilityInventory',
  actionBaseType: 'FACILITY_INVENTORY',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { facility, timeFrame } = store.selectFacilityChartRaw()
    if (!facility) return Promise.reject(new Error('Waiting to load current facility'))
    return apiFetch('/facility/inventory/', {
      start: getStartDate(timeFrame),
      facility,
    })
  },
})

export default {
  ...facilityInventoryBundle,
  reactMarkFacilityInventoryOutdated: createSelector(
    'selectFacilityChartIsStale',
    'selectFacilityInventoryIsStale',
    'selectAvailableFeatures',
    'selectPermittedActions',
    'selectRouteInfo',
    (chartStale, selfStale, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (allowed && chartStale && !selfStale) {
        return { actionCreator: 'doMarkFacilityInventoryAsOutdated' }
      }
      return undefined
    }
  ),
  reactFacilityInventoryFetch: createAppIsReadySelector({
    dependencies: [
      'selectFacilityInventoryShouldUpdate',
      'selectPathname',
      'selectAvailableFeatures',
      'selectPermittedActions',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, pathname, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (allowed && shouldUpdate) {
        return { actionCreator: 'doFetchFacilityInventory' }
      }
      return null
    }
  }),
  selectFacilityCultivarTotals: createSelector(
    'selectFacilityInventory',
    inventory => {
      if (!inventory?.cultivars) {
        return EMPTY_OBJECT
      }
      return Object.entries(inventory.cultivars).reduce((bounds, [key, cultivars]) => {
        const values = Object.values(cultivars)
        if (!values.length) {
          return bounds
        }
        return {
          ...bounds,
          [key]: values.reduce(((total, current) => total + current))
        }
      }, EMPTY_OBJECT)
    }
  ),
  selectFacilityCultivarCounts: createSelector(
    'selectFacilityInventory',
    'selectFacilityCultivarTotals',
    (inventory, totals) => {
      if (!inventory || !inventory.cultivars) return EMPTY_OBJECT
      const normalizedCultivars = Object.entries(inventory.cultivars).reduce((counts, [key, values]) => {
        if (Array.isArray(values)) { return counts }

        const keyVal = Object.entries(values).reduce((k, [id, count]) => ({
          ...k,
          [id]: { value: count, normalized: count / (totals[key] || 1) },
        }), EMPTY_OBJECT)
        return {
          ...counts,
          [key]: keyVal,
        }
      }, EMPTY_OBJECT)

      return Object.entries(normalizedCultivars).reduce((counts, [key, values]) => {
        if (Array.isArray(values)) return counts

        return {
          ...counts,
          [key]: values,
          total: Object.entries(values).reduce((t, [id, { value, normalized }]) => ({
            ...t,
            [id]: {
              value: (t[id]?.value ?? 0) + value,
              normalized: (t[id]?.normalized ?? 0) + normalized
            }
          }), counts.total ?? EMPTY_OBJECT)
        }
      }, EMPTY_OBJECT)
    }
  ),
  // TODO: Store this response in Redux
  doCheckMETRCLicense: () => async ({ apiFetch }) => {
    if (storage.metrcLicenseExpired === 'true' && config.ENVIRONMENT !== 'production') return true
    try {
      let hasLicenseExpired = false
      const apiResult = await apiFetch('/facility/metrc_license_check/', null, { method: 'GET' })
      if (apiResult.invalidLicenses?.length) {
        hasLicenseExpired = true
      }
      return hasLicenseExpired
    } catch (error) {
      return false
    }
  }
}
