import { clamp, omit } from 'ramda'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'

const defaultFetchState = {
  error: false,
  errorMessage: null,
  failCount: 0,
  lastFailAt: 0,
  loading: false,
  loaded: false,
}

export const getDefaultState = () => ({
  data: EMPTY_OBJECT,
  fetch: EMPTY_OBJECT,
  inflight: EMPTY_OBJECT,
  params: EMPTY_OBJECT,
  stale: EMPTY_OBJECT,
})

export const selectChartIdForRoom = room => {
  if (!room) return null
  if (room.uuid) return room.uuid
  return room.id ? `rooms_${room.id}` : null
}

const typeKeys = {
  room: ['devices', 'facility', 'zones'],
  harvest: ['cultivars', 'phases']
}
export const typeChartIdGetters = {
  room: selectChartIdForRoom,
  harvest: ({ uuid, id }) => uuid ?? `harvests_${id}`
}
const getTypeFromObject = obj => Object.entries(typeKeys).reduce(
  (acc, [type, keys]) => acc || (obj && keys.every(key => key in obj) ? type : acc),
  null
)
const getChartIdFromObject = obj => {
  const type = getTypeFromObject(obj)
  const getId = typeChartIdGetters[type]
  return getId ? getId(obj) : null
}

export const provideChartId = fn => (id, ...args) => {
  const chartId = typeof id === 'string' ? id : getChartIdFromObject(id)
  return fn(chartId, ...args)
}

export const getFetchReducer = types => (state, action) => {
  const { type, payload } = action ?? EMPTY_OBJECT
  if (!payload || (types.prefix && !type.startsWith(types.prefix))) {
    return state
  }
  const omitter = omit([payload.chartId])
  switch (type) {
    case types.start: {
      const { chartId, params } = payload
      const { fetch: { [chartId]: prevFetch = EMPTY_OBJECT } } = state
      return {
        ...state,
        current: chartId,
        data: omitter(state.data),
        fetch: {
          ...state.fetch,
          [chartId]: {
            ...prevFetch,
            loaded: false,
            loading: true,
          }
        },
        inflight: { ...state.inflight, [chartId]: +new Date() },
        params: { ...state.params, [chartId]: params },
      }
    }
    case types.fail: {
      const { payload: chartId, error: errorMessage } = action
      return {
        ...state,
        current: chartId,
        fetch: {
          ...state.fetch,
          [chartId]: {
            ...defaultFetchState,
            error: true,
            errorMessage,
            lastFailAt: Date.now(),
            failCount: (state.fetch.failCount || 0) + 1
          }
        },
        inflight: omit(state.inflight, [chartId]),
      }
    }
    case types.succeed: {
      const { chartId } = payload
      const { [chartId]: prevData } = state.data
      return {
        ...state,
        current: chartId,
        fetch: {
          ...state.fetch,
          [payload.chartId]: {
            ...defaultFetchState,
            loaded: true,
          }
        },
        inflight: omitter(state.inflight),
        data: payload.data === null ? state.data : {
          ...state.data,
          [payload.chartId]: Array.isArray(payload.data)
            ? action.payload.data
            : {
              ...prevData,
              ...payload.data,
              fetched: Date.now()
            },
        },
        stale: omitter(state.stale),
      }
    }
    default:
      return state
  }
}

export const getChartLimit = (params, chartId) => {
  if (params?.limit) return params.limit

  const chartElement = document.querySelector(`[data-chartid=${chartId}]`)
  if (chartElement) {
    const { width } = chartElement.getBoundingClientRect()

    if (width) {
      return clamp(80, 1500, Math.ceil((width / 40)) * 10)
    }
  }

  return 300
}
