import { useConnect } from 'redux-bundler-hook'

import FourOhThree from '~/src/App/403'
import ConfirmationDialog from '~/src/UI/Shared/ConfirmationDialog'

const Forbidden = () => {
  const { dialogRouteInfo, doCloseDialogRoute } = useConnect('selectDialogRouteInfo', 'doCloseDialogRoute')

  return (
    <ConfirmationDialog
      noCancel
      open
      confirmText="OK"
      onClose={doCloseDialogRoute}
      onConfirm={doCloseDialogRoute}
    >
      <FourOhThree routeInfo={dialogRouteInfo} />
    </ConfirmationDialog>
  )
}

export default Forbidden
