import {
  always,
  either,
  identity,
  pipe,
  prop,
} from 'ramda'
import { createSelector as createBundleSelector } from 'redux-bundler'

/* eslint-disable no-underscore-dangle */
import { camelize, singularize } from 'inflection'
import { minutes } from 'milliseconds'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { createAppIsReadySelector, createScopedSelector } from '~/src/Store/utils'

import createLogger from '../Logging'

const orEmptyObj = either(identity, always(EMPTY_OBJECT))

/**
 * @typedef {import("~/src/Store/utils").Scope} Scope
 */

/**
 * Returns a redux-bundler selector that is either scoped or normal.
 * @param {Object} config
 * @param {Array<string|function>} config.dependencies The selectors to use as dependencies
 * @param {function} config.resultFn The result function to use
 * @param {Scope} config.scope The scope to use
 * @returns {function(Object: Object|Array)} A redux-bundler selector
 */
const bundleSelectorFactory = ({ dependencies, resultFn, scope, ...rest }) => {
  const selector = scope != null
    ? createScopedSelector({ ...rest, dependencies, resultFn, scope })
    : createBundleSelector(...dependencies, resultFn)
  selector.__bundle__ = true
  return selector
}

export const selectRootFactory = name => pipe(prop(name), orEmptyObj)

export const selectAnnotatedEntitiesFactory = (name, rootSelectorName = `select${camelize(name)}Root`, scope = null) => bundleSelectorFactory({
  dependencies: ['selectEntities', rootSelectorName],
  resultFn: (allEntities, root) => {
    const { [name]: entities = EMPTY_OBJECT } = allEntities
    const { dirty: rootDirty = EMPTY_OBJECT, inflight: rootInflight = EMPTY_OBJECT } = root
    const allIds = [...new Set([
      ...Object.keys(entities),
      ...Object.keys(rootDirty),
      ...Object.keys(rootInflight),
    ])]
    return allIds.reduce((annotatedEntities, id) => {
      const { [id]: data } = entities
      const { [id]: dirty } = rootDirty
      const { [id]: inflight = null } = rootInflight
      annotatedEntities[id] = { ...data, ...dirty, inflight }
      return annotatedEntities
    }, {})
  },
  scope,
  defaultReturnValue: EMPTY_OBJECT,
})
export const selectInflightFactory = rootSelectorName => bundleSelectorFactory({
  dependencies: [rootSelectorName],
  resultFn: root => root?.inflight ?? EMPTY_OBJECT
})
export const selectDirtyFactory = rootSelectorName => bundleSelectorFactory({
  dependencies: [rootSelectorName],
  resultFn: root => root?.dirty ?? EMPTY_OBJECT
})

export const selectCurrentIdFactory = (name, idAttribute, rootSelectorName) => bundleSelectorFactory({
  dependencies: [
    rootSelectorName,
    'selectRouteInfo',
    'selectRouteParams',
    'selectDialogRouteParams',
  ],
  resultFn: (root, { pattern }, routeParams, dialogRouteParams) => {
    if (dialogRouteParams?.schema && dialogRouteParams.schema === name) {
      const dialogRouteId = dialogRouteParams[idAttribute]
      if (dialogRouteId) return Number(dialogRouteId) || dialogRouteId
    }
    const [routeSchema] = pattern.slice(1).split('/').filter(Boolean)
    if (routeSchema === name && routeParams) {
      const { [idAttribute]: id } = routeParams
      if (id) {
        return Number(id) || id
      }
    }
    return root.current
  }
})

export const selectCurrentEntityFactory = (currentIdSelector, entityRootSelector) => bundleSelectorFactory({
  dependencies: [currentIdSelector, entityRootSelector],
  resultFn: (id, annotatedEntities) => annotatedEntities[id] ?? EMPTY_OBJECT
})

/**
 * Creates all of the standard selectors for an entity bundle.
 * @param {Object} config
 * @param {string} config.name The entity schema name (usually plural)
 * @param {boolean} config.autoFetch Whether to create an autoFetchReactor
 * @param {string} config.idAttribute The attribute to use as the entity ID
 * @param {boolean} config.readOnly Whether to create and integrate dirty selector
 * @param {Scope} config.scope The scope to use for the selectors
 * @param {string?} config.singularName The singular form of the entity name
 * @returns {Object<string, function>} The selectors
 */
export const entitySelectorsFactory = ({
  name,
  autoFetch = false,
  idAttribute = 'id',
  readOnly = false,
  scope,
  singularName = singularize(name),
}) => {
  const logger = createLogger(`${name}/selectors`)
  const selectName = `select${camelize(name)}`
  const singularTitle = camelize(singularName)
  const selectSingularName = `select${singularTitle}`
  const selectCurrentIdName = `selectCurrent${singularTitle}Id`
  const rootSelectorName = `${selectName}Root`
  const inflightSelectorName = `${selectName}Inflight`
  const autoFetchReactorName = `react${singularTitle}AutoFetch`

  const defaultSelectors = {
    [rootSelectorName]: selectRootFactory(name),
    [selectName]: selectAnnotatedEntitiesFactory(name, rootSelectorName, scope),
    [inflightSelectorName]: selectInflightFactory(rootSelectorName),
    [selectCurrentIdName]: selectCurrentIdFactory(
      name,
      idAttribute,
      rootSelectorName
    ),
    [selectSingularName]: selectCurrentEntityFactory(
      selectCurrentIdName,
      selectName
    ),
  }

  if (autoFetch) {
    defaultSelectors[autoFetchReactorName] = createAppIsReadySelector({
      dependencies: [
        selectCurrentIdName,
        rootSelectorName,
        selectSingularName
      ],
      /**
       * When appropriate, fetch the entity.
       * @param {number} id - the entity ID
       * @param {{ dirty: Object<number, Object>, inflight: Object<number, string> }} root
       * @param {Object<number, { lastError: Object, lastUpdateAt: number }>} root.dirty - stores fetch errors for entities at the ID
       * @param {Object<number, string> } root.inflight - stores fetch inflight status for entities at the ID
       * @param {{ id: number, fetchedAt: import('luxon').DateTime}} entity
       * @returns
       */
      resultFn: (id, { dirty = EMPTY_OBJECT, inflight = EMPTY_OBJECT } = EMPTY_OBJECT, entity = EMPTY_OBJECT) => {
        if (!id || inflight[id]) return null
        const noEntityYet = !entity.id || entity.payloadType === 'summary'
        const lastFetchedIsStale = entity.fetchedAt && entity.fetchedAt < Date.now() - minutes(5)
        if ((noEntityYet || lastFetchedIsStale) && (
          !dirty[id]
          || !dirty[id].lastError
          || dirty[id].lastUpdateAt < Date.now() - minutes(5)
        )) {
          let priority = REACTOR_PRIORITIES.MEDIUM
          if (noEntityYet) {
            priority = REACTOR_PRIORITIES.HIGH
          }
          if (lastFetchedIsStale) {
            priority = REACTOR_PRIORITIES.LOW
          }
          return {
            actionCreator: `do${singularTitle}Fetch`,
            args: [{ id }],
            priority,
          }
        }
        return null
      }
    })
  }

  if (readOnly) {
    return defaultSelectors
  }

  const dirtySelectorName = `${selectName}Dirty`
  return {
    ...defaultSelectors,
    [dirtySelectorName]: selectDirtyFactory(rootSelectorName),
  }
}
