import ms from 'milliseconds'

import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import { KpiRecipe as schema } from '~/src/Store/Schemas'

const logger = createLogger('RD/kpiRecipes#bundle')

const initialState = {
  ...defaultInitialState,
}

const kpiRecipesBundle = createListBundle({
  entityName: 'kpiRecipes',
  name: 'kpiRecipes',
  initialState,
  actions: EMPTY_ARRAY,
  schema,
  permissions: { keys: ['view_cultivar_profiles'] },
  fetchHandler: ({ apiFetch }) => apiFetch('/kpiRecipes/', { pagination: 0 }),
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(15),
})

export default kpiRecipesBundle
