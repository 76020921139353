import i18n from 'i18n-literally'

export const HARVEST_OPTION_GROUPS = {
  RECENT_HARVESTS: 'RECENT_HARVESTS',
  RECENT_HARVESTS_MATCHING_RECIPE: 'RECENT_HARVESTS_MATCHING_RECIPE',
  RECENT_HARVESTS_MATCHING_CULTIVARS: 'RECENT_HARVESTS_MATCHING_CULTIVARS',
}

export const HARVEST_OPTION_GROUP_LABELS = {
  [HARVEST_OPTION_GROUPS.RECENT_HARVESTS]: () => i18n`Most recent Harvest Groups`,
  [HARVEST_OPTION_GROUPS.RECENT_HARVESTS_MATCHING_CULTIVARS]: () => i18n`Most recent Harvest Groups with matching Cultivars`,
  [HARVEST_OPTION_GROUPS.RECENT_HARVESTS_MATCHING_RECIPE]: sourceRecipe => i18n`Most recent Harvest Groups using recipe: ${sourceRecipe.name}`
}

export const dayNightOptions = [
  { label: i18n`Show day/night`, value: 'dayNight' },
  { label: i18n`Show day/night differential`, value: 'dayNightDifferential' },
  { label: i18n`Show night only`, value: 'nightOnly' },
  { label: i18n`Show day only`, value: 'dayOnly' },
]

export const lightOptions = [
  { label: i18n`DLI`, value: 'dli' },
  { label: i18n`Light intensity`, value: 'solar_quantum' },
  { label: i18n`Light indicator`, value: 'solar' },
]

export const humidityOptions = [
  { label: i18n`Relative Humidity`, value: 'rel_hum' },
  { label: i18n`Absolute Humidity`, value: 'abs_hum' },
]

export const climateDataTypes = [
  'light_generic',  // Placeholder; determined by settings
  'co2',
  'air_temp',
  'vpd',
  'humidity_generic',  // Placeholder; determined by settings
]
export const substrateDataTypes = [
  'soil_moist',
  'dryback_percentage',
  'pore_ec',
]
export const manualDataTypes = [
  'plant_height',
  'node_spacing',
  'stem_diameter',
  'flower_diameter',
]

export const orderedDataTypes = [
  climateDataTypes,
  substrateDataTypes,
  manualDataTypes,
].flat()
