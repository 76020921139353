import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { LabType } from '~/src/Store/Schemas'

const initialState = {
  data: defaultInitialState.data,
}

const entityName = 'labType'

const initialBundle = createListBundle({
  entityName,
  actions: EMPTY_ARRAY,
  initialState,
  schema: LabType,
  permissions: { keys: ['change_lab_result'] },
  staleAfter: ms.hours(12),
  retryAfter: ms.minutes(5),
  urlTest: url => url.includes('/inventory'),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/labtest/types/', params),
})

const listBundle = {
  ...initialBundle,
  selectCurrentLabTypeList: createSelector(
    'selectLabTypeList',
    'selectEntities',
    (list, { labTypes = EMPTY_OBJECT }) => (
      list?.results
        ? list.results.map(id => labTypes[id])
        : EMPTY_ARRAY
    )
  ),
}

export default listBundle
