import {
  groupBy,
  omit,
  pipe,
  prop,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import createEntityBundle, {
  doEntitiesReceived,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Item as schema } from '~/src/Store/Schemas'

const name = 'items'
const saveOrder = getAsyncActionIdentifiers('save_order', 'items')

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData: data => ({
      ...data,
      maximum: data.maximum || null,
      quantity: data.quantity || null,
    }),
    schema,
  },
})

const defaultState = {
  inflight: {},
  errors: {},
}

export default {
  ...initialBundle,
  reducer: reduceReducers(defaultState, initialBundle.reducer, (state, action) => {
    if (!action.type.startsWith(saveOrder.types.prefix)) return state
    switch (action.type) {
      case saveOrder.types.start: {
        return {
          ...state,
          inflight: {
            ...state.inflight,
            saveOrder: true,
          },
          errors: omit(['saveOrder'], state.errors)
        }
      }
      case saveOrder.types.succeed: {
        return {
          ...state,
          inflight: omit(['saveOrder'], state.inflight),
        }
      }
      case saveOrder.types.fail: {
        return {
          ...state,
          inflight: omit(['saveOrder'], state.inflight),
          errors: {
            ...state.errors,
            saveOrder: action.error
          }
        }
      }
      default:
        return state
    }
  }),
  doSaveItemsOrder: payload => async ({ apiFetch, store, dispatch }) => {
    const storeItems = store.selectItems()
    const items = payload.reduce((reordered, id, index) => ({
      ...reordered,
      [id]: {
        ...storeItems[id],
        sequence: index,
      }
    }), {})
    dispatch({ type: saveOrder.types.start })
    dispatch(doEntitiesReceived({ items }))
    try {
      const response = apiFetch('/items/sequence/', payload, { method: 'PUT' })
      const { entities } = normalize(response, [schema])
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: saveOrder.types.succeed })
      store.doAddSnackbarMessage('Item saved successfully.')
    } catch (error) {
      dispatch({ type: saveOrder.types.fail, error })
      store.doAddSnackbarMessage('Item failed to save.')
    }
  },
  selectColorsByItem: createSelector(
    'selectItems',
    'selectColorsByKey',
    (items, colors) => Object.values(items).reduce((itemColors, item) => ({
      ...itemColors,
      [item.id]: colors[item.category],
    }), EMPTY_OBJECT)
  ),
  selectItemsByCategory: createSelector(
    'selectItems',
    pipe(Object.values, groupBy(prop('category'))),
  ),
}
