import createEntityBundle from '~/src/Lib/createEntityBundle'
import { SubstrateSettings as schema } from '~/src/Store/Schemas'

const name = 'substrateSettings'

const entityBundle = createEntityBundle({
  name,
  schema,
  apiConfig: {
    schema
  }
})

export default { ...entityBundle }
