import {
  compose,
  filter,
  into,
  join,
  map,
  pipe,
  prop,
  split,
  toLower,
  trim,
} from 'ramda'

import { pluralize } from 'inflection'

import { EMPTY_OBJECT } from './constants'

const wordAndSpace = /[a-zA-Z0-9 ]/
/**
 * Returns initials from the custom name provided
 * @param {string} string
 * @returns {string}
 */
export const initials = string => (typeof string === 'string'
  ? string
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .split(/([a-zA-Z]+\s+)/)
    .filter(s => s && s.match(wordAndSpace))
    .map(s => (s.match(/^\d+$/) ? ` ${s}` : s.charAt(0).toUpperCase()))
    .join('')
  : ''
)

/**
 * Create a smart, shortened name from a long name or phrase
 * @param {string} string
 * @returns string
 */
export const shortName = string => (typeof string === 'string'
  ? string
    // trim off leading non-word characters
    .slice(string.search(/\w/))
    // split on non-word characters
    .split(/\W+/)
    // drop empty strings
    .filter(Boolean)
    // if the segment appears to already by an id (101) or abbr (FLR)
    // pass through as-is up to 8 characters
    // otherwise upper case first character
    .map(s => (s.match(/^[\dA-Z]+$/) ? s.slice(0, 8) : s[0]).toUpperCase())
    .join('')
  : ''
)

export const slugify = pipe(
  String,
  split(/[^a-zA-Z0-9]+/g),
  into([], compose(map(trim), filter(Boolean), map(toLower))),
  join('-')
)
/**
 * Returns string with a custom separator on end from the provided array
 * @param {array} list
 * @param {string} finalSeparator
 * @returns {string}
 */
export const formatList = (list, finalSeparator = 'and') => {
  if (!Array.isArray(list)) return list
  if (list.length < 2) return list[0] ?? ''
  const { [list.length - 1]: last } = list

  if (list.length === 2) return list.join(finalSeparator)

  return `${list.slice(0, -1).join(', ')}, ${finalSeparator} ${last}`
}

const formatUIDDefaultOptions = { increment: 6, separator: '\u00a0', toArray: false }
const trimPattern = /^\s+|\s+$/
/**
 * Format long UID strings for easier readability
 * @param {string} input UID string
 * @param {object} options Configuration options
 * @param {number} [options.increment=6] Insert a non-breaking space every increment characters
 * @param {string} [options.separator='\u00a0'] The separator character to use
 * @param {boolean} [options.toArray=false] The separator character to use
 */
export const formatUID = (input, options) => {
  if (typeof input !== 'string') return ''
  const opts = { ...formatUIDDefaultOptions, ...options }
  const { increment, separator, toArray } = opts
  const splitPattern = new RegExp(`(.{${increment}})`, 'g')
  const parts = input.split(splitPattern).filter(str => Boolean(str.replace(trimPattern, '')))
  if (toArray) return parts
  return parts.join(separator)
}

/**
 * Escapes RegExp special characters so they can be matched in strings
 * @param {string} input
 * @returns {string}
 */
export const escapeRegExp = input => (
  input != null && typeof input !== 'object'
    ? String(input).replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string)
    : ''
)

/**
 * An array sorter for arrays of strings
 * @param {string} a A string to compare
 * @param {string} b A string to compare
 */
export const textSort = (a, b) => {
  if (a == null || b == null || !a.localeCompare || !b.localeCompare) {
    console.error('[textSort] not a string:', { a, b })
    return 0
  }
  return a.localeCompare(b, undefined, { numeric: true, ignorePunctuation: true })
}

const getNameListData = zoneList => zoneList.filter(Boolean).map(name => {
  const tokens = name.split(/\s+/)
  const lastToken = tokens.slice(-1)[0]
  const lastTokenNumber = Number.parseInt(lastToken, 10)
  const lastTokenIsNumber = !Number.isNaN(lastTokenNumber)
  return ({
    lastCharCode: name.charCodeAt(name.length - 1),
    lastToken: lastTokenIsNumber ? lastTokenNumber : lastToken,
    lastTokenIsNumber,
    name: tokens.join(' '),
    prefix: tokens.length > 1 ? tokens.slice(0, -1).join(' ') : name,
  })
}).sort((a, b) => {
  if (a.prefix !== b.prefix) {
    return a.prefix < b.prefix ? -1 : 1
  }
  if (a.lastTokenIsNumber && b.lastTokenIsNumber) {
    return a.lastToken - b.lastToken
  }
  return a.lastCharCode - b.lastCharCode
})

/**
 * Generates a concise label from a list of names or objects, combining shared prefixes
 * and sorting alphabetically.
 *
 * ['Bench 1', 'Bench 2', 'Bench 3', 'Bench 5', 'Zone 1'] => 'Benches 1-3, 5, Zone 1'
 *
 * A list of objects will use the `name` attribute of each element by default.
 *
 * [{ name: 'Zone A' }, { name: 'Zone B' }, { name: 'Zone C' }] => 'Zones A-C'
 * @param {string[]|object[]} list List of objects or strings
 * @param {string|function} [nameGetter=name] A string attribute name or a function that given a list member returns a string
 * @returns {string} A string with pluralized prefix + combined suffixes
 */
export const getListLabel = (list = [], nameGetter = 'name') => {
  if (!list) return ''
  let label = ''

  let nameList = []
  if (typeof nameGetter === 'function') {
    nameList = list.map(nameGetter)
  } else if (list.filter(Boolean).every(element => typeof element === 'string')) {
    nameList = list
  } else {
    // Assumes list of entities and a string nameGetter that is an attribute of the entities
    nameList = list.map(prop(nameGetter))
  }

  let previousPrefix
  getNameListData(nameList).forEach((nameData, index, array) => {
    const {
      lastCharCode,
      lastToken,
      lastTokenIsNumber,
      name,
      prefix,
    } = nameData
    const next = array[index + 1] ?? EMPTY_OBJECT

    // Detect duplicates
    if (name === next.name) return

    const labelHasOpenSeries = label.charAt(label.length - 1) === '-'
    const prefixMatchesPrevious = prefix === previousPrefix
    const prefixMatchesNext = prefix === next.prefix

    const tokensIndicateSeries = lastTokenIsNumber && next.lastTokenIsNumber
      ? next.lastToken - lastToken === 1
      : (
        next.lastCharCode - lastCharCode === 1
        && lastToken.length === 1
        && next.lastToken.length === 1
      )
    const nextNameIsInSeries = prefixMatchesNext && tokensIndicateSeries

    if (nextNameIsInSeries) {
      if (labelHasOpenSeries) {
        // Series continues to next name
      } else {
        // Start new series
        label += `, ${prefixMatchesPrevious ? '' : `${pluralize(prefix)} `}${lastToken}-`
      }
    } else if (labelHasOpenSeries) {
      // Complete the series
      label += lastToken
    } else {
      const inflectedName = prefixMatchesNext ? `${pluralize(prefix)} ${lastToken}` : name
      label += `, ${prefixMatchesPrevious ? lastToken : inflectedName}`
    }

    previousPrefix = prefix
  })

  // Remove lazily-prepended ', ' from the front
  return label.slice(2)
}

/**
 * Given a phone number string of the form <country_code><number> (country code optional for US and CAN), returns a formatted phone number
 * NOTE: only supports US and CAN phone numbers at present
 * @param {string} phone
 * @returns {string} formatted phone number
 */
export const formatPhoneNumber = phone => {
  if (phone.match(/(\+1)?\d{10}/)) {
    return phone.replace(/(\+1)?(\d{3})(\d{3})(\d{4})/, '+1 ($2) $3-$4')
  }
  return phone
}

/**
 * Given a string, returns a string with the first letter capitalized
 * @param {string} str string to capitalize
 * @returns {string} string with first letter capitalized
 */
export const capitalizeInitial = str => str.charAt(0).toUpperCase() + str.slice(1)
