import { StrictMode, Suspense, useEffect } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { CssBaseline } from '@mui/material'

import createLogger from '~/src/Lib/Logging'
import { PUBLIC_ROUTES } from '~/src/Routes/constants'

import { lazyWithRetries } from '../Lib/Utils'
import Public from './Public'
import { fallbackContent } from './utils'

const Authenticated = lazyWithRetries(() => import('./Authenticated'))

const logger = createLogger('App/Main')

const Main = () => {
  const {
    appIsReady,
    auth,
    routeInfo = {},
    doUpdateUrl,
  } = useConnect(
    'selectAppIsReady',
    'selectAuth',
    'selectLandingPage',
    'selectRouteInfo',
    'selectRoutes',
    'doUpdateUrl'
  )
  const { pattern = '/', value: route } = routeInfo
  const { authenticated } = auth
  useEffect(() => {
    if (!authenticated && pattern === PUBLIC_ROUTES.HOME) {
      logger.debug(`not authenticated, redirecting from ${PUBLIC_ROUTES.HOME} to ${PUBLIC_ROUTES.LOGIN}`)
      doUpdateUrl(PUBLIC_ROUTES.LOGIN)
    }
  }, [authenticated, doUpdateUrl, pattern])

  let Component = Authenticated
  if (!appIsReady || !route || route.publicAccess) {
    Component = Public
  }
  logger.debug(`returning ${Component.displayName} entry component`)
  return (
    <StrictMode>
      <Suspense fallback={fallbackContent}>
        <CssBaseline />
        <Component />
      </Suspense>
    </StrictMode>
  )
}

export default Main
