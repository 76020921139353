import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createListBundle, { defaultCase } from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import { Event as schema } from '~/src/Store/Schemas'

import { calculateEventFields } from './utils'

const initialState = {
  data: { result: EMPTY_ARRAY },
  ordering: ['created_on', 'desc'],
  eventType: undefined,
  location: undefined,
  cultivar: undefined,
  search: '',
  synced: false,
}

const EVENT_LIST_REMOVE_EVENT = 'EVENT_LIST_REMOVE_EVENT'

const removeEvent = (event, data) => {
  const eventIds = data.results.filter(eventId => eventId !== event)
  return {
    ...data,
    count: eventIds.length,
    results: eventIds,
  }
}

const listBundle = createListBundle({
  entityName: 'event',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/events/', params),
  urlTest: (_, pattern) => pattern.startsWith('/compliance') && !pattern.endsWith('/history'),
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
})

export default {
  ...listBundle,
  reducer: reduceReducers(listBundle.reducer, (state, action) => {
    switch (action.type) {
      case EVENT_LIST_REMOVE_EVENT:
        return { ...state, data: removeEvent(action.payload, state.data) }
      default:
        return defaultCase(initialState, state)
    }
  }),
  doRemoveEventFromList: event => ({ dispatch, store }) => {
    dispatch({ type: EVENT_LIST_REMOVE_EVENT, payload: event.id })
    store.doAddSnackbarMessage('Successfully synced event')
  },
  selectEventTypes: createSelector(
    'selectEntities',
    entities => (entities?.eventTypes ?? {})
  ),
  selectCurrentEventList: createSelector(
    'selectEvents',
    'selectEventListRaw',
    'selectEventTypes',
    'selectRooms',
    'selectFacilityCultivars',
    (events, eventListRaw, eventTypes, ...props) => (
      eventListRaw.data?.results?.map(
        eventId => (
          eventId in events
            ? ({
              ...events[eventId],
              ...calculateEventFields(events[eventId], eventTypes, ...props),
            })
            : null
        )
      ).filter(Boolean) ?? EMPTY_ARRAY
    )
  )
}
