import { getTokens } from '~/src/Lib/Auth'
import safeStorage from '~/src/Lib/safeStorage'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

const localStorage = safeStorage('local')

export const getLSMRoot = () => {
  try {
    return localStorage.lastSelectedMembership?.length
      ? JSON.parse(localStorage.lastSelectedMembership)
      : EMPTY_OBJECT
  } catch (_) {
    return EMPTY_OBJECT
  }
}

export const getLSM = () => getLSMRoot()?.[getTokens().user] ?? EMPTY_OBJECT

export const clearLSM = () => {
  const { [getTokens().user]: _, ...rest } = getLSMRoot()
  localStorage.lastSelectedMembership = JSON.stringify(rest)
}

export const setLSM = membership => {
  const { user, facilities } = getTokens()
  if (!user) return
  localStorage.lastSelectedMembership = JSON.stringify({
    ...getLSMRoot(),
    [user]: {
      facilities: facilities.map(({ id, name }) => ({ id, name })),
      facility: membership.facility,
      id: membership.id
    }
  })
}
