import i18n from 'i18n-literally'
import {
  always,
  compose,
  either,
  filter,
  groupBy,
  identity,
  into,
  map,
  pick,
  pipe,
  prop,
  uniq,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { humanize } from 'inflection'

import createEntityBundle, { asyncActionFactory } from '~/src/Lib/createEntityBundle'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatList,
  parseApiErrors,
} from '~/src/Lib/Utils'
import { Device as schema, facilityScope as scope } from '~/src/Store/Schemas'

import { prepareData } from './shape'

const name = 'devices'

const bulkActionSnackbar = ({ action, dispatch, payload, response, status, store }) => {
  const { room: roomId, zone: zoneId, facility: facilityId } = payload
  const { [facilityId]: facility } = store.selectFacilities()
  const room = store.selectRooms()[roomId]
  const zone = store.selectZones()[zoneId]

  const deviceSNs = formatList(response.map(prop('serialNumber')).filter(Boolean), '&')

  const severity = status === 'succeed' ? 'success' : 'error'
  let msg
  if (status === 'succeed') {
    if (action === 'bulk_update') {
      msg = i18n`Assigned ${deviceSNs} to room "${room.name}"`
      if (zoneId && zone) msg += `& zone "${zone.name}"`
    } else {
      msg = i18n`Moved ${deviceSNs} to facility "${facility.name}"`
    }
  } else {
    msg = action === 'bulk_update'
      ? i18n`Failed to assign ${deviceSNs} to room "${room.name}"`
      : i18n`Failed to move ${deviceSNs} to facility "${facility.name}"`
  }
  dispatch({
    actionCreator: 'doAddSnackbarMessage',
    args: [msg, severity],
  })
}
const bulkUpdateAction = asyncActionFactory({
  action: 'bulk_update',
  name,
  schema: [schema],
  snackbar: bulkActionSnackbar,
  handler: ({ payload, apiFetch }) => apiFetch('/devices/bulk/', payload, { method: 'POST' }),
})
const moveUpdateAction = asyncActionFactory({
  action: 'move_device',
  name,
  schema: [schema],
  snackbar: bulkActionSnackbar,
  handler: ({ payload, apiFetch }) => apiFetch('/devices/move/', payload, { method: 'POST' }),
})

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData,
    schema,
    snackbar: 'serialNumber'
  },
  customActions: [{
    action: 'sensor_update',
    async: true,
    handler: ({ payload, apiFetch }) => apiFetch(`/devices/sensor/${payload.id}/`, payload, { method: 'PATCH' }),
    prepareData: identity,
    snackbar: ({ error, status, payload, dispatch, store }) => {
      const { [payload.id]: sensor } = store.selectSensors()
      const { [payload.modelKey]: model } = store.selectAvailableModels()
      const identifier = `${model.name} (${sensor.serialNumber})`
      if (status === 'succeed') {
        const msg = payload.calibrationType !== sensor.calibrationType
          ? `Updated ${identifier} flow measurement type to ${humanize(payload.calibrationType)}`
          : `Updated ${identifier}`
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [msg, 'success'],
        })
      } else {
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [i18n`Update failed for ${identifier}: ${parseApiErrors(error)}`, 'error'],
        })
      }
    }
  }],
  scope,
})
export default {
  ...initialBundle,
  reducer: (state, action) => {
    switch (action.type) {
      case bulkUpdateAction.types.start:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: true,
            success: null,
          },
        }
      case bulkUpdateAction.types.fail:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: false,
            success: false,
            error: action.error,
          },
        }
      case bulkUpdateAction.types.succeed:
        return {
          ...state,
          bulk: {
            ...state.bulk,
            saving: false,
            success: true,
          },
        }

      case moveUpdateAction.types.start:
        return {
          ...state,
          move: {
            ...state.move,
            saving: true,
            success: null,
          },
        }
      case moveUpdateAction.types.fail:
        return {
          ...state,
          move: {
            ...state.move,
            saving: false,
            success: false,
            error: action.error,
          },
        }
      case moveUpdateAction.types.succeed:
        return {
          ...state,
          move: {
            ...state.move,
            saving: false,
            success: true,
          },
        }

      default:
        return initialBundle.reducer(state, action)
    }
  },
  [bulkUpdateAction.actionName]: bulkUpdateAction,
  [moveUpdateAction.actionName]: moveUpdateAction,
  selectAvailableModels: createSelector(
    'selectDevices',
    'selectModels',
    (devices, models) => {
      if (!devices || !models) return EMPTY_OBJECT
      const deviceModels = uniq(Object.values(devices).map(prop('modelKey')))
      return pick(deviceModels, models)
    }
  ),
  selectDevicesByModel: createSelector(
    'selectDevices',
    'selectModels',
    (devices, models) => {
      if (!devices || !models) return EMPTY_OBJECT
      return Object.values(devices).filter(device => device.modelKey in models).reduce((dbm, device) => ({
        ...dbm,
        [device.modelKey]: [
          ...(dbm[device.modelKey] ?? []),
          device,
        ],
      }), {})
    },
  ),
  selectDevicesByRoom: createSelector(
    'selectDevices',
    'selectRooms',
    (devices, rooms) => {
      if (!devices) return EMPTY_OBJECT
      return Object.values(devices).filter(device => device.room in rooms).reduce((dbr, device) => ({
        ...dbr,
        [device.room]: [
          ...(dbr[device.room] ?? []),
          device,
        ],
      }), {})
    }
  ),
  selectDevicesByRoomAndZone: createSelector(
    'selectDevicesByRoom',
    /**
     * Given devices grouped by room ID, returns devices by room ID sub-grouped by zone ID and no zone ID.
     * @param {Object<number, Object[]>} byRoom
     * @returns {Object<number, Object<number|string, Object[]>>}
     */
    byRoom => {
      if (byRoom === EMPTY_OBJECT) return byRoom
      return Object.entries(byRoom).reduce((byRoomAndZone, [roomId, roomDevices]) => {
        const { room: devices = EMPTY_ARRAY, ...byZone } = groupBy(either(prop('zone'), always('room')), roomDevices)
        byRoomAndZone[roomId] = { ...byZone, devices }
        return byRoomAndZone
      }, {})
    }
  ),
  selectSensors: createSelector(
    'selectDevices',
    pipe(
      // Convert to list of devices
      Object.values,
      into(
        [],
        compose(
          // Filter out devices without sensors
          filter(device => device.sensor),
          // Map to sensor entries
          map(device => [device.sensor.id, device.sensor])
        )
      ),
      // Convert entries to object
      Object.fromEntries
    )
  )
}
