import { shallowEqualArrays, shallowEqualObjects } from 'shallow-equal'

import { getDateTime } from './datetime'

export const isDateType = obj => {
  if (!obj) return false
  if (typeof obj === 'string') {
    if (!Number.isNaN(new Date(obj).getTime())) return true
    return false
  }
  if (obj && obj.isLuxonDateTime) return true

  return Boolean(typeof obj === 'object' && 'getUTCDate' in obj && 'getUTCFullYear' in obj)
}

const defaultOptions = { allowedDepth: Infinity, depth: 1, shallow: false }
export const equals = (left, right, optsParam) => {
  const { allowedDepth, depth, shallow } = optsParam ? { ...defaultOptions, ...optsParam } : defaultOptions
  if (shallow && allowedDepth > 5) throw new Error('Keep it shallow, please. Use equals for deep checks.')
  let isEqual = left === right
  const eitherFalsy = !left || !right
  const leftType = typeof left
  const rightType = typeof right
  const bothPrimitive = (leftType !== 'object' && rightType !== 'object')
  const leftIsDate = isDateType(left)
  const rightIsDate = isDateType(right)
  const sameType = leftType === rightType || (leftIsDate && rightIsDate)

  if (isEqual || eitherFalsy || bothPrimitive || !sameType) {
    return isEqual
  }
  isEqual = Array.isArray(left) ? shallowEqualArrays(left, right) : shallowEqualObjects(left, right)

  if (isDateType(left) || isDateType(right)) {
    return +getDateTime(left) === +getDateTime(right)
  }

  if (!isEqual && allowedDepth > depth) {
    if (Array.isArray(left) || Array.isArray(right)) {
      isEqual = left.length === right.length
        ? left.every((item, index) => equals(item, right[index], { allowedDepth, depth: depth + 1 }))
        : false
    }
    isEqual = Object.keys(left).length === Object.keys(right).length
      ? Object.keys(left).every(key => equals(left[key], right[key], { allowedDepth, depth: depth + 1 }))
      : false
  }
  return isEqual
}

export const shallowEquals = (left, right, allowedDepth = 2, depth = 1) => equals(left, right, { allowedDepth, depth, shallow: true })
