import {
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import memoizeOne from 'memoize-one'
import {
  equals,
  fromPairs,
  pipe,
  prop,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { useTheme } from '@mui/material/styles'

import { camelize } from 'inflection'
import reduceReducers from 'reduce-reducers'

import {
  activeUserAllowedTypes,
  annotationTypeMap,
  defaultAllowedTypes,
} from '~/src/Annotations/constants'
import { MAX_CHART_DAYS } from '~/src/App/constants'
import { INDIVIDUAL_ZONE_GRAPHS } from '~/src/Chart'
import {
  ENVIRONMENT_AND_SUBSTRATE_SPLIT,
  getChartYDimensions,
  getGraphsAndAxes,
  getPaddingForChart,
  getXDomain,
  getXOffset,
  getXScale,
  getYAxes,
  getYScale,
  ROOM_GRAPH_SINGLE,
} from '~/src/Chart/utils'
import { selectUniqueCultivarZones, selectZonesForHarvest } from '~/src/Harvest/bundle'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  getId,
  memoize,
  shallowEquals,
  shallowEqualsMemoizer,
  useDebouncedBatchQueue,
  useIsLoaded,
} from '~/src/Lib/Utils'
import {
  selectFrom,
  selectRoomDashboardGraphs,
  selectTimeframeFrom,
  selectTo,
} from '~/src/Room/bundle'
import { DEFAULT_TIMEFRAME, TIMEFRAME_PRESETS, ZOOM_MODE } from '~/src/Room/constants'

import { defaultState } from './defaults'
import { createNamespacedLogger } from './logger'
import { getQueryParam, rehydrateQuery, useUrlState } from './query'

const logger = createNamespacedLogger('utils#state')

export const READ_ONLY = { chartId: 1, defaultTimeframe: 1, timeframePresets: 1, growlog: 1 }
export const DEBOUNCED = { individualSensors: 1 }
export const ENSURE_ARRAY = ['selectedDataTypes', 'selectedZones']
const LABEL_VARIANT = {
  xs: 'button',
  sm: 'button',
  md: 'button',
}

/* eslint-disable babel/camelcase */
const CUSTOM_DATATYPE_NAMES = {
  wind_speed: 'Wind'
}
/* eslint-enable babel/camelcase */

const getContext = shallowEqualsMemoizer((state, dispatchers, dispatchPatch) => ({
  ...state,
  ...dispatchers,
  dispatchPatch,
  get defaultFrom() {
    const {
      chartTimeframe,
      defaultTimeframe,
      growlog,
      selectedHarvest: harvest,
      timeframePresets
    } = this

    if (growlog && harvest?.startDate) {
      return getDateTime(harvest.startDate)
    }
    return this.chartTimeframe
      ? selectTimeframeFrom(chartTimeframe, timeframePresets, defaultTimeframe)
      : undefined
  },
  get defaultTo() {
    const { growlog, selectedHarvest: harvest } = this
    return growlog && (harvest.endDate ?? harvest.harvestDate)
      ? getDateTime(harvest.endDate ?? harvest.harvestDate ?? 'now')
      : undefined
  }
}), { depth: 3 })

const getDefaults = memoizeOne(props => {
  const { isMobile, ...restProps } = props
  return Object.entries(defaultState).reduce((defaults, [key, value]) => (
    isMobile && key === 'activeNotification' ? defaults : {
      ...defaults,
      [key]: key in restProps ? restProps[key] : value
    }
  ), EMPTY_OBJECT)
}, shallowEquals)

export const getInitialState = (props, rawQuery) => {
  const {
    chart = EMPTY_OBJECT,
    chartId,
    dataTypes,
    defaultDataTypes,
    growlog,
    harvest,
    harvests,
    room,
    selectedZones: propsSelectedZones,
    settings,
    users,
    isMobile,
    isLandscape,
    allDataTypes,
  } = props
  const defaults = getDefaults(props)
  const query = rehydrateQuery({ ...settings, ...rawQuery })
  const { zones } = room
  const allZoneIds = Array.isArray(zones) ? zones.map(getId) : EMPTY_ARRAY
  const {
    data: chartData,
    graphZones
  } = chart
  const now = getDateTime('now')

  const queryHarvest = getQueryParam(query, 'harvest', null)
  let selectedHarvest = harvest
    ?? room.currentHarvests?.find(h => h.id === queryHarvest)
    ?? defaultState.selectedHarvest
  const { zones: queryZones = EMPTY_ARRAY } = query

  let selectedZones = growlog
    ? selectUniqueCultivarZones(harvest?.cultivars ?? EMPTY_ARRAY)
    : propsSelectedZones
  let mainGraphZone = null
  if (!growlog) {
    if (!selectedZones && queryZones?.length) {
      selectedZones = queryZones
    }

    if (
      !selectedZones?.length
      && !selectedHarvest
      && room.currentHarvests?.length === 1
    ) {
      const [onlyHarvest] = room.currentHarvests
      const harvestZones = selectZonesForHarvest(onlyHarvest)
      const onlyHarvestInAllZones = allZoneIds.every(zoneId => harvestZones.includes(zoneId))

      if (onlyHarvestInAllZones) {
        selectedHarvest = typeof onlyHarvest === 'number' ? harvests[onlyHarvest] : onlyHarvest
        selectedZones = harvestZones
      }
    } else if (
      selectedHarvest
      && !room.currentHarvests?.find(({ id }) => id === selectedHarvest.id)
    ) {
      selectedHarvest = defaultState.selectedHarvest
    }

    if (selectedHarvest && !selectedZones?.length) {
      selectedZones = selectZonesForHarvest(selectedHarvest)
    }

    selectedZones = selectedZones?.filter(zone => allZoneIds.includes(zone))

    if (!selectedZones?.length) {
      selectedZones = allZoneIds ?? EMPTY_ARRAY
    }

    ([mainGraphZone] = selectedZones)
  }
  let manualTo = query.to ? getDateTime(query.to) : null
  let manualFrom = query.from && +(query.from || 0) !== +selectTimeframeFrom()
    ? getDateTime(query.from)
    : null
  if (manualFrom && !manualTo) {
    manualFrom = now > manualFrom ? manualFrom : null
  }

  if (harvest && harvest.startDate) {
    const harvestStart = getDateTime(harvest.startDate)
    const harvestEnd = getDateTime(harvest.harvestDate ?? harvest.endDate ?? 'now')
    logger.debug('updating dates based on harvest', { harvestStart, harvestEnd, manualFrom, manualTo })
    if (manualFrom) {
      manualFrom = (manualFrom >= harvestStart) && (manualFrom <= harvestEnd) ? manualFrom : null
    }
    if (manualTo) {
      manualTo = (manualTo >= harvestStart) && (manualTo <= harvestEnd) ? manualTo : null
    }
  }

  let chartTimeframe = getQueryParam(query, 'timeframe', null)
  if (chartTimeframe === 'selectedHarvest' && !selectedHarvest) {
    chartTimeframe = defaultState.chartTimeframe
  } else if (growlog && selectedHarvest && !manualFrom && !manualTo && !chartTimeframe) {
    const { startDate, endDate } = selectedHarvest
    const harvestInterval = getDateTime(startDate).until(getDateTime(endDate))
    chartTimeframe = harvestInterval.contains(now) ? TIMEFRAME_PRESETS['1W'] : 'selectedHarvest'
  } else if (!TIMEFRAME_PRESETS[chartTimeframe] && chartTimeframe !== 'selectedHarvest') {
    chartTimeframe = (manualFrom || manualTo) ? null : defaultState.chartTimeframe
  }

  const activeAnnotationUser = query.annotationUser
    ? users[query.annotationUser] ?? null
    : null
  const activeAnnotationTypes = (activeAnnotationUser
    ? activeUserAllowedTypes
    : defaultAllowedTypes
  ).filter(t => {
    const { [t]: type } = annotationTypeMap
    return !(type.queryParam in query)
  })

  if (manualTo && manualTo <= manualFrom) {
    if (manualTo < now) {
      manualFrom = getDateTime(manualTo).minus({ days: 1 }).startOf('day')
    } else {
      manualTo = null
      manualFrom = null
      chartTimeframe = defaultState.chartTimeframe
    }
  }

  const selectedDataTypes = (getQueryParam(query, 'dataTypes', defaultDataTypes) ?? EMPTY_ARRAY).filter(dataType => dataType in allDataTypes)
  const state = {
    ...defaults,
    // TODO: when split graphs is released for indoor, this ternary needs to be removed
    activeAnnotationTypes,
    activeAnnotationUser,
    allowedDataTypes: chartData?.dataTypes ?? Object.values(dataTypes).map(prop('key')),
    chartId,
    chartRange: {},
    chartTimeframe,
    cursorMode: ZOOM_MODE,
    graphZones,
    growlog: props.growlog ?? getQueryParam(query, 'growlog', defaultState.growlog),
    individualGraphs: growlog ? false : getQueryParam(query, 'individualGraphs', defaultState.individualGraphs),
    individualSensors: getQueryParam(query, 'individualSensors', defaultState.individualSensors),
    journalOpen: getQueryParam(query, 'journalOpen', defaultState.journalOpen),
    mainGraphZone,
    manualFrom,
    manualTo,
    openAnnotation: getQueryParam(query, 'annotation', defaultState.openAnnotation),
    room,
    selectedDataTypes,
    selectedDepths: getQueryParam(query, 'depths', defaultState.selectedDepths),
    selectedHarvest,
    selectedZones,
    showRoomAvg: getQueryParam(query, 'showRoomAvg', defaultState.showRoomAvg),
    showYAxis: getQueryParam(query, 'showYAxis', !isMobile || isLandscape),
    viewMode: getQueryParam(query, 'viewMode', defaultState.viewMode),
  }

  const chartGraphs = selectRoomDashboardGraphs({ ...state, chartData })
  return Object.assign(state, chartGraphs)
}

export const PATCH_STATE = Symbol.for('ROOM_DASHBOARD_PATCH_STATE')

export const getDispatchers = memoize((initialState, dispatch, queue) => (
  Object.entries(initialState).reduce((acc, [type]) => {
    const handlers = {}
    if (READ_ONLY[type] || type.indexOf('show') === 0) {
      return acc
    }
    handlers[`set${camelize(type)}`] = DEBOUNCED[type]
      ? payload => queue.add([type, payload])
      : payload => dispatch({ type, payload })

    return {
      ...acc,
      ...handlers,
    }
  }, { dispatch })
))

const shouldCallFnPayload = (payload, key) => typeof payload === 'function' && typeof defaultState[key] !== 'function'

const shortCircuitWhenSame = (reducer, key) => (state = EMPTY_OBJECT, action = EMPTY_OBJECT, isSame = (a, b) => Object.is(a, b)) => {
  if (isSame(state[key], action.payload)) {
    return state
  }
  return reducer(state, action)
}

const shortCircuitWhenShallowEqual = (reducer, key) => (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  let { payload } = action
  if (shouldCallFnPayload(payload, key)) {
    payload = payload(state[key])
  }
  if (shallowEquals(state[key], payload)) {
    return state
  }
  return reducer(state, { ...action, payload })
}

const defaultReducer = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  const { [action.type]: oldValue } = state
  let { payload } = action
  if (shouldCallFnPayload(payload, action.type)) {
    payload = payload(oldValue)
  }
  if (oldValue === payload) return state
  return {
    ...state,
    [action.type]:
      payload === undefined && typeof oldValue === 'boolean'
        ? !oldValue
        : payload,
  }
}

const reducers = {
  activeNotification: shortCircuitWhenSame((state, action) => {
    const { allowedDataTypes = EMPTY_ARRAY } = state
    let { payload } = action
    if (typeof payload === 'function') {
      const { [action.type]: newVal } = defaultReducer(state, action)
      payload = newVal
    }

    if (!allowedDataTypes.includes(payload?.dataTypeKey)) {
      const { [action.type]: oldVal } = state
      return oldVal == null ? state : { ...state, [action.type]: null }
    }

    if (payload) {
      const from = getDateTime(selectFrom(state))
      const to = getDateTime(selectTo(state) ?? 'now').plus({ hours: 1 })
      const displayedRange = from.until(to)
      const { ts, startDate = ts, endDate = ts } = payload

      return displayedRange.contains(getDateTime(startDate)) || displayedRange.contains(getDateTime(endDate))
        ? { ...state, [action.type]: payload }
        : state
    }
    return defaultReducer(state, action)
  }, 'activeNotification'),
  chartTimeframe: shortCircuitWhenSame((state, action) => {
    const { payload: chartTimeframe } = action

    return {
      ...state,
      chartTimeframe,
      manualFrom: defaultState.manualFrom,
      manualTo: defaultState.manualTo,
    }
  }, 'chartTimeframe'),
  individualGraphs: shortCircuitWhenSame((state, action) => {
    const { individualGraphs } = state
    const nextState = defaultReducer(state, action)

    // We're disabling individualGraphs
    if (individualGraphs) {
      return nextState
    }
    return {
      ...nextState,
      viewMode: ROOM_GRAPH_SINGLE,
    }
  }, 'individualGraphs'),
  manualFrom: shortCircuitWhenShallowEqual((state, action) => {
    const { payload: from } = action
    const nextState = {
      ...state,
      manualFrom: from,
      chartTimeframe: null,
    }

    if (!from) return nextState

    const { manualTo: to } = state
    const nowDT = getDateTime('now')
    const toDT = to ? getDateTime(to) : nowDT
    const fromDT = getDateTime(from)
    if (toDT.diff(fromDT).as('days') > MAX_CHART_DAYS) {
      logger.debug(
        `manualFrom is too far in the past, setting manualTo to ${MAX_CHART_DAYS} days after manualFrom`,
        JSON.stringify({ manualFrom: fromDT, oldManualTo: toDT, now: nowDT })
      )
      nextState.manualTo = fromDT.plus({ days: MAX_CHART_DAYS })
    }
    return nextState
  }, 'manualFrom'),
  manualTo: shortCircuitWhenShallowEqual((state, action) => {
    const { payload: to } = action
    const { chartTimeframe, manualFrom: from } = state
    const nextState = {
      ...state,
      manualTo: to,
      chartTimeframe: null,
    }

    if (!to) return nextState

    const toDT = getDateTime(to)
    const fromDT = from ? getDateTime(from) : null
    if (fromDT && toDT.diff(fromDT).as('days') > MAX_CHART_DAYS) {
      nextState.manualFrom = toDT.minus({ days: MAX_CHART_DAYS }).startOf('day')
    }
    if (chartTimeframe && !fromDT) {
      nextState.manualFrom = chartTimeframe in TIMEFRAME_PRESETS
        ? toDT.minus(TIMEFRAME_PRESETS[chartTimeframe]).startOf('day')
        : toDT.minus(TIMEFRAME_PRESETS[DEFAULT_TIMEFRAME]).startOf('day')
    }
    return nextState
  }, 'manualTo'),
  selectedHarvest: shortCircuitWhenSame((state, action) => {
    const { payload: selectedHarvest } = action
    const { growlog, room } = state
    const allZoneIds = room.zones?.map(getId) ?? EMPTY_ARRAY

    let selectedZones
    if (growlog) {
      selectedZones = selectUniqueCultivarZones(selectedHarvest?.cultivars ?? EMPTY_ARRAY)
    } else {
      selectedZones = selectedHarvest
        ? selectZonesForHarvest(selectedHarvest).filter(id => allZoneIds.includes(id))
        : allZoneIds
    }

    return {
      ...state,
      selectedHarvest,
      selectedZones
    }
  }, 'selectedHarvest'),
  selectedZones: shortCircuitWhenShallowEqual((state, { payload }) => ({
    ...state,
    selectedZones: payload,
  }), 'selectedZones'),
  viewMode: shortCircuitWhenSame((state, action) => {
    const {
      viewMode,
      activeTargetType
    } = state
    const nextState = defaultReducer(state, action)
    const {
      viewMode: nextSplitGraphs
    } = nextState

    // We're disabling viewMode
    if (nextSplitGraphs === viewMode) {
      return nextState
    }

    // TODO: Revisit in future
    if (nextSplitGraphs === INDIVIDUAL_ZONE_GRAPHS && activeTargetType) {
      return {
        ...nextState,
        activeTargetType: defaultState.activeTargetType,
      }
    }

    return {
      ...nextState,
      individualGraphs: false,
    }
  }, 'viewMode'),
}

reducers[PATCH_STATE] = (state, action) => {
  const patch = Object.entries(action.payload).reduce((acc, [key, value]) => {
    const prev = state[key]
    if (key in READ_ONLY || prev === value || !(key in state)) return acc
    if (key in reducers) {
      const newState = reducers[key](state, { type: key, payload: value })
      return newState === state ? acc : {
        ...acc,
        ...newState,
      }
    }
    return { ...acc, [key]: value }
  }, EMPTY_OBJECT)

  if (patch !== EMPTY_OBJECT) {
    return {
      ...state,
      ...patch,
    }
  }
  return state
}

export const dashboardReducer = (state = EMPTY_OBJECT, action = EMPTY_OBJECT) => {
  let newState = state
  if (!action || action.type in READ_ONLY) {
    logger.warn('tried to set read-only value:', action)
    return state
  }

  if (action.type in reducers) {
    newState = reducers[action.type](state, action) ?? state
  } else if (action.type in state) {
    newState = defaultReducer(state, action) ?? state
  }

  if (selectFrom(newState) >= selectTo(newState)) {
    const { manualFrom, manualTo } = newState
    logger.debug('invalid date range', { manualFrom, manualTo })
    const nowDT = getDateTime('now')
    const fromDT = getDateTime(manualFrom)
    const toDT = getDateTime(manualTo)
    let nextFrom = !fromDT.isValid || fromDT >= nowDT ? null : fromDT
    const nextTo = !toDT.isValid || (toDT >= nowDT && nextFrom == null) ? null : toDT
    if (nextFrom && nextTo) {
      logger.debug('invalid manualFrom datetime, setting to manualTo - 1 day')
      nextFrom = (nextTo >= nowDT ? nowDT : toDT).minus({ days: 1 }).startOf('day')
    }
    if (!nextFrom && !nextTo) {
      logger.debug('invalid date range, resetting to', defaultState.chartTimeframe)
      newState = {
        ...state,
        chartTimeframe: nextFrom || nextTo ? null : defaultState.chartTimeframe,
        manualFrom: nextFrom,
        manualTo: nextTo,
      }
    }
  }

  newState = ENSURE_ARRAY.reduce((ns, key) => {
    if (Array.isArray(ns[key])) return ns
    ns[key] = EMPTY_ARRAY
    return ns
  }, newState)

  return newState
}

export const sidebarReducer = (state, action) => {
  const { individualGraphs, journalOpen } = state
  if (action.type === 'individualGraphs' && individualGraphs) {
    return journalOpen ? { ...state, journalOpen: false } : state
  }
  if (action.type === 'journalOpen' && journalOpen) {
    return individualGraphs ? { ...state, individualGraphs: false } : state
  }
  return state
}

export const getAvailableDepths = memoizeOne(graphs => [
  ...new Set(graphs.map(g => g.depth).filter(Boolean)),
].sort((a, b) => a - b))

export const getDispatchPatch = memoizeOne(dispatch => payload => dispatch({ type: PATCH_STATE, payload }))
export const getDispatchDebouncedPatch = memoizeOne(dispatchPatch => pipe(
  fromPairs,
  dispatchPatch
))

export const useDashBoardState = (props, chartDimensionsRef = EMPTY_OBJECT, chartBbox = EMPTY_OBJECT) => {
  const { queryAdvanced: query, doLightScheduleListSetParams } = useConnect('selectQueryAdvanced', 'doLightScheduleListSetParams')
  const { breakpoint, chart = EMPTY_OBJECT, dataTypes, defaultDataTypes, growlog, harvest, room } = props
  const { current: chartDimensions = EMPTY_OBJECT } = chartDimensionsRef
  const { data: chartData = EMPTY_OBJECT, graphZones } = chart
  const [initialState] = useState(() => getInitialState(props, query, chartData))

  const [state, dispatch] = useReducer(
    reduceReducers(initialState, dashboardReducer, sidebarReducer),
    initialState
  )
  const theme = useTheme()
  const loading = Boolean(room.inflight || chart.inflight)
  const loaded = useIsLoaded(loading, 2000)
  const dispatchPatch = getDispatchPatch(dispatch)
  // when called with a queue of [key, value] pairs, dispatches a PATCH_STATE
  // action with a payload object of { [key1]: <last value for key1>... }
  const dispatchDebouncedPatch = getDispatchDebouncedPatch(dispatchPatch)
  const queue = useDebouncedBatchQueue(dispatchDebouncedPatch, 2, 33)

  const dispatchers = getDispatchers(initialState, dispatch, queue)

  const {
    viewMode,
    activeNotification,
    activeTargetType,
    chartWidth,
    chartRange,
    individualGraphs,
    mainChartGraphs,
    mainGraphZone,
    manualGraphs,
    selectedDataTypes,
    selectedHarvest,
    selectedDepths,
    selectedZones,
    showRoomAvg,
    showYAxis,
  } = state
  const {
    setMainGraphZone,
    setSelectedZones
  } = dispatchers

  useEffect(() => {
    if (room?.id) {
      doLightScheduleListSetParams({ room: room?.id, start: chartRange?.start, end: chartRange?.end })
    }
  }, [room?.id, chartRange, doLightScheduleListSetParams])

  useEffect(() => {
    if (state.chartWidth === chartDimensions.width && state.chartHeight === chartDimensions.height) {
      return
    }

    dispatchPatch({
      chartWidth: chartDimensions.width,
      chartHeight: chartDimensions.height
    })
  }, [
    state.chartWidth,
    state.chartHeight,
    chartDimensions.width,
    chartDimensions.height,
    dispatchPatch
  ])

  useEffect(() => {
    if (!loaded || loading || !individualGraphs || !selectedZones || !graphZones) {
      return
    }
    const mgzIsValid = typeof mainGraphZone === 'string'
      ? graphZones.includes(mainGraphZone)
      : selectedZones.includes(mainGraphZone)
      && graphZones.includes(mainGraphZone)
    if (!mgzIsValid) {
      const newMGZone = selectedZones.find(id => graphZones.includes(id))
      logger.warn('could not find mainGraphZone in selectedZones or graphZones:', {
        mainGraphZone,
        selectedZones,
        graphZones,
      })
      setMainGraphZone(newMGZone)
    }
  }, [
    graphZones,
    individualGraphs,
    mainGraphZone,
    setMainGraphZone,
    selectedZones,
    loading,
    loaded,
  ])

  useEffect(() => {
    if (
      Array.isArray(props.selectedZones)
      && !shallowEquals(
        props.selectedZones.slice().sort(),
        selectedZones.slice().sort()
      )
    ) {
      setSelectedZones(props.selectedZones)
    }
  }, [props.selectedZones, selectedZones, setSelectedZones])

  useEffect(() => {
    if (equals(props.room, state.room)) {
      return
    }
    dispatch({ type: 'room', payload: props.room })
  }, [props.room, state.room, dispatch])

  // Effect to keep graph sets up to date
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!loaded || loading) return
    const newGraphs = selectRoomDashboardGraphs({
      ...state,
      chartData,
      growlog,
    })

    const payload = Object.entries(newGraphs).reduce((acc, [key, curr]) => {
      const {
        [key]: prev
      } = state

      if (!shallowEquals(prev, curr)) {
        return {
          ...acc,
          [key]: curr
        }
      }
      return acc
    }, EMPTY_OBJECT)
    if (payload !== EMPTY_OBJECT) {
      dispatchPatch(payload)
    }
  }, [
    activeNotification,
    mainGraphZone,
    individualGraphs,
    selectedZones,
    selectedDataTypes,
    selectedDepths,
    viewMode,
    showRoomAvg,
    chartData,
    growlog,
    loading,
    loaded,
    dispatchPatch,
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (chartData?.dataTypes) {
      dispatch({
        type: 'allowedDataTypes',
        payload: chartData.dataTypes
      })
    }
  }, [chartData?.dataTypes])

  // useEffect(() => {
  //   if (chart?.data?.graphs) {
  //     const depths = getAvailableDepths(chart?.data?.graphs)

  //     if (selectedDepths?.length === 0) {
  //       if (depths?.length > 0) {
  //         dispatch({
  //           type: 'selectedDepths',
  //           payload: [depths.length === 2 ? depths[1] : getMiddleDepth(depths)],
  //         })
  //       }
  //     }
  //     if (selectedDepths.some(d => !depths.includes(d))) {
  //       dispatch({
  //         type: 'selectedDepths',
  //         payload: selectedDepths.filter(d => depths.includes(d)),
  //       })
  //     }
  //   }
  // }, [chart?.data?.graphs])

  useEffect(() => {
    if (graphZones) {
      dispatch({
        type: 'graphZones',
        payload: graphZones
      })
    }
  }, [graphZones])

  useEffect(() => {
    if (chartData?.range) {
      dispatch({
        type: 'chartRange',
        payload: chartData.range
      })
    }
  }, [chartData?.range])

  useEffect(() => {
    if (!growlog) return
    if (
      harvest?.cultivars
      && !shallowEquals(selectedHarvest?.cultivars, harvest?.cultivars)
    ) {
      dispatch({
        type: 'selectedHarvest',
        payload: harvest
      })
    }
  })

  useEffect(() => {
    if (viewMode !== ROOM_GRAPH_SINGLE || !loaded || loading) return

    if (viewMode === ENVIRONMENT_AND_SUBSTRATE_SPLIT) {
      const uniqueDTCategories = new Set(
        selectedDataTypes.map(dt => dataTypes[dt]?.category)
      )
      if (uniqueDTCategories.size < 2) {
        dispatch({
          type: 'viewMode',
          payload: ROOM_GRAPH_SINGLE
        })
      }
    }
  }, [selectedDataTypes, viewMode, loading, loaded, dataTypes])

  const yAxes = getYAxes({
    chartData,
    dataTypes,
    mainChartGraphs,
    manualGraphs,
    viewMode,
  })
  const graphData = getGraphsAndAxes({
    activeNotification,
    activeTargetType,
    dataTypes,
    mainChartGraphs,
    manualGraphs,
    viewMode,
    theme,
    yAxes,
    mainChartData: chartData.data,
  })
  const xOffset = getXOffset({
    dataTypes,
    showYAxis,
    graphData,
  })
  const chartPadding = getPaddingForChart({
    xOffset,
    breakpoint
  })
  const xDomain = getXDomain(chartData?.range)
  const xScale = getXScale({
    chartPadding,
    chartWidth: (chartBbox && chartBbox.width) || chartWidth,
    xDomain
  }, chartBbox)
  const yRange = getChartYDimensions(chartPadding, chartDimensions)
  const yScale = getYScale(yRange, Object.values(graphData.groups))
  const isDrybackModeDisabled = useMemo(() => (
    !selectedDataTypes.includes('soil_moist') || !mainChartGraphs.find(g => g.dataType === 'soil_moist')
  ), [mainChartGraphs, selectedDataTypes])
  const dashboardState = Object.assign(getContext(state, dispatchers, dispatchPatch), {
    chartPadding,
    defaultDataTypes,
    graphData,
    isDrybackModeDisabled,
    loaded,
    loading,
    xDomain,
    xOffset,
    xScale,
    yScale,
    yAxes,
  })

  useUrlState(dashboardState, props)

  return dashboardState
}
