import { defaultAllowedTypes } from '~/src/Annotations/constants'
import { ROOM_GRAPH_SINGLE } from '~/src/Chart'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import { DEFAULT_TIMEFRAME, ZOOM_MODE } from '~/src/Room/constants'

export const defaultState = Object.freeze({
  // journal
  activeAnnotationTypes: defaultAllowedTypes,
  activeAnnotationUser: null,
  allowedAnnotationTypes: defaultAllowedTypes,
  journalOpen: false,
  // chart
  activeNotification: null,
  activeTargetType: null,
  chartTimeframe: DEFAULT_TIMEFRAME,
  cursorMode: ZOOM_MODE,
  drybackViewOverlay: false,
  individualGraphs: false,
  individualSensors: false,
  selectedDataTypes: EMPTY_ARRAY,
  selectedZones: EMPTY_ARRAY,
  showRoomAvg: false,
  showYAxis: true,
  viewMode: ROOM_GRAPH_SINGLE,
  // shared
  manualFrom: null,
  manualTo: null,
  openAnnotation: undefined,
  selectedHarvest: null,
})
