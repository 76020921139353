import colorUtil from 'color'
import i18n from 'i18n-literally'
import {
  groupBy,
  isEmpty,
  map,
  mapObjIndexed,
  pipe,
} from 'ramda'

import { CATEGORY_CATALOG, climatePattern, irrigationPattern } from '~/src/DataType/constants'
import { isSubstrate } from '~/src/DataType/utils'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

const logger = createLogger('Chart/utils')

export const ROOM_GRAPH_SINGLE = 'RoomGraphSingle'
export const INDIVIDUAL_ZONE_GRAPHS = 'IndividualZoneGraphs'
export const ENVIRONMENT_AND_SUBSTRATE_SPLIT = 'EnvironmentAndSubstrateSplit'
export const SENSOR_AND_DRYBACK_SPLIT = 'SensorAndDrybackSplit'
export const PLANT_GROWTH_AND_DRYBACK_SPLIT = 'PlantGrowthAndDrybackSplit'
export const COMPARE_HARVESTS = 'CompareHarvests'
export const IRRIGATION_VIEW_SPLIT = 'IrrigationViewSplit'

export const GRAPH_MAX_POINTS = 500
export const GRAPH_X_AXIS_CLASSNAME = 'time-series__x-axis-line'
export const LABEL_GAP = 2
export const LABEL_X_PADDING = 8
export const LABEL_Y_PADDING = 4
export const LABEL_HEIGHT = 16
export const Y_AXIS_WIDTH = 51

/* istanbul ignore next: currently unused but don't want to lose the code */
const zonesSplitGraphs = pipe(
  groupBy(item => (item.id.match(/device:\d+/) ? `zone:${item.zone}` : item.id.replace(/^\w+:/, ''))),
  mapObjIndexed(value => ({ graphs: value })),
)
export const DATA_GROUPING = {
  [ROOM_GRAPH_SINGLE]: ({ data, type = 'graphs', reducerInitial = EMPTY_OBJECT }) => ({
    ...reducerInitial,
    ALL: { ...reducerInitial?.ALL, [type]: data }
  }),
  [INDIVIDUAL_ZONE_GRAPHS]: /* istanbul ignore next: currently unused but don't want to lose the code */ ({
    data,
    type = 'graphs',
    reducerInitial = EMPTY_OBJECT
  }) => {
    if (type === 'graphs' && reducerInitial === EMPTY_OBJECT) {
      return zonesSplitGraphs(data)
    }
    if (type === 'yAxes' && Object.values(reducerInitial).some(entry => entry.graphs?.length)) {
      const withYAxes = Object.entries(reducerInitial).reduce((groups, [groupKey, group]) => {
        const { graphs, ...rest } = group
        const yAxes = [...new Set(graphs.map(({ id, dataType, unit }) => data.find(axis => (
          (axis.boundsType === 'graph' && id === axis.graph)
          || (axis.boundsType === 'dataType' && dataType === axis.dataType)
          || (axis.boundsType === 'unit' && unit === axis.unit)
        ))))]
        groups[groupKey] = { ...rest, graphs, yAxes }
        return groups
      }, reducerInitial)

      return withYAxes
    }
    return reducerInitial
  },
  /**
   * Splits sensor data into environment and substrate
   * @param {Object} params
   * @param {Object[]} params.data - graph definitions with sensor data
   * @param {Object<number, Object>} params.dataTypes - sensor data types
   * @param {Set<string>} params.graphDataTypes - data types in the chart data
   * @param {String} [params.type=graphs] - Type of data being grouped
   * @returns {Object<string, Object>}
   */
  [ENVIRONMENT_AND_SUBSTRATE_SPLIT]: ({
    data,
    dataTypes,
    graphDataTypes,
    type = 'graphs',
    theme = EMPTY_OBJECT,
    reducerInitial = {
      SUBSTRATE: {
        title: CATEGORY_CATALOG.SUBSTRATE,
        background: theme.palette?.type === 'light'
          ? theme.palette?.contrast.enabled
          : colorUtil(theme.palette?.contrastInverse.main ?? '#000').alpha(0.1).string(),
        [type]: []
      },
      CLIMATE: {
        title: CATEGORY_CATALOG.CLIMATE,
        background: theme.palette?.type === 'light'
          ? 'transparent'
          : colorUtil(theme.palette?.contrast.main ?? '#fff').alpha(0.02).string(),
        [type]: []
      },
    },
  }) => data.reduce((allGroups, item) => {
    if (!graphDataTypes.has(item.dataType) || !(item.dataType in dataTypes)) {
      return allGroups
    }
    const { SUBSTRATE: soil, CLIMATE: other } = allGroups
    const { [item.dataType]: dataType } = dataTypes
    if (isSubstrate(dataType)) {
      soil[type] ??= []
      soil[type].push(item)
    } else {
      other[type] ??= []
      other[type].push(item)
    }
    return allGroups
  }, reducerInitial),
  [SENSOR_AND_DRYBACK_SPLIT]: /* istanbul ignore next: currently unused but don't want to lose the code */  ({
    data,
    dataTypes,
    graphDataTypes,
    type = 'graphs',
    reducerInitial = {
      DRYBACK: { title: i18n`DRYBACK`, background: '#00000017', [type]: [] },
      SENSORS: {
        title: i18n`SENSORS`,
        background: '#ffffff01',
        [type]: [],
      },
    },
  }) => data.reduce((allGroups, item) => {
    if (!graphDataTypes.has(item.dataType)) {
      return allGroups
    }
    const { DRYBACK: dryback, SENSORS: environment } = allGroups
    const { [item.dataType]: dataType } = dataTypes
    if (dataType.key === 'dryback') {
      dryback[type].push(item)
    }
    if (climatePattern.test(dataType.category)) {
      environment[type].push(item)
    }
    return allGroups
  }, reducerInitial),
  [PLANT_GROWTH_AND_DRYBACK_SPLIT]: /* istanbul ignore next: currently unused but don't want to lose the code */  ({
    data,
    dataTypes,
    graphDataTypes,
    type = 'graphs',
    reducerInitial = [
      { title: i18n`DRYBACK`, background: '#00000017', [type]: [] },
      {
        title: i18n`PLANT GROWTH`,
        background: '#ffffff01',
        [type]: [],
      },
    ],
  }) => {
    const [drybackGroup, plantHeightGroup] = data.reduce((AllGroups, item) => {
      if (!graphDataTypes.has(item.dataType)) {
        return AllGroups
      }
      const [dryback, plantGrowth] = AllGroups
      const { [item.dataType]: dataType } = dataTypes
      if (
        dataType.key === 'dryback_duration'
        || dataType.key === 'dryback_amplitude'
      ) {
        dryback[type].push(item)
      }
      if (
        dataType.key === 'plant_height'
        || dataType.key === 'canopy_density'
      ) {
        plantGrowth[type].push({
          ...item,
          data: item.data.filter(datum => datum.y),
        })
      }
      return AllGroups
    }, reducerInitial)

    if (!drybackGroup[type].length && plantHeightGroup[type].length) {
      const fakeDuration = map(
        value => {
          if (String(value).startsWith('canopy_density')) {
            return value.replace('canopy_density', 'dryback_duration')
          }
          if (Array.isArray(value)) {
            return value.map(datum => ({
              ...datum,
              y: 12 + Math.random() * 2,
            }))
          }
          return value
        },
        plantHeightGroup[type].find(
          item => item.dataType === 'canopy_density'
        )
      )
      const fakeAmplitude = map(
        value => {
          if (String(value).startsWith('plant_height')) {
            return value.replace('plant_height', 'dryback_amplitude')
          }
          if (Array.isArray(value)) {
            return value.map(datum => ({
              ...datum,
              y: (datum.y * 25) / 100,
            }))
          }
          return value
        },
        plantHeightGroup[type].find(
          graph => graph.dataType === 'plant_height'
        )
      )

      return [
        { ...drybackGroup, graphs: [fakeDuration, fakeAmplitude] },
        plantHeightGroup,
      ]
    }

    return [drybackGroup, plantHeightGroup]
  },
  [IRRIGATION_VIEW_SPLIT]: ({
    data,
    dataTypes,
    graphDataTypes,
    type = 'graphs',
    reducerInitial = {
      IRRIGATION_DRAIN: {
        title: '',
        [type]: []
      },
      IRRIGATION_DRIP: {
        title: CATEGORY_CATALOG.IRRIGATION,
        [type]: []
      },
      SUBSTRATE: {
        title: CATEGORY_CATALOG.SUBSTRATE,
        [type]: []
      },
      CLIMATE: {
        title: CATEGORY_CATALOG.CLIMATE,
        [type]: []
      },
    },
  }) => {
    let hasClimate = false
    let hasIrrigation = false
    const newGroups = data.reduce((allGroups, item) => {
      if (!graphDataTypes.has(item.dataType) || !(item.dataType in dataTypes)) {
        return allGroups
      }
      const {
        SUBSTRATE: soil,
        CLIMATE: other,
        IRRIGATION_DRIP: irrigationDrip,
        IRRIGATION_DRAIN: irrigationDrain
      } = allGroups
      const { [item.dataType]: dataType } = dataTypes
      if (item.dataType == 'irrigation_drain_event') {
        irrigationDrain[type] ??= []
        irrigationDrain[type].push(item)
      } else if (item.dataType == 'irrigation_drip_event') {
        irrigationDrip[type] ??= []
        irrigationDrip[type].push(item)
      } else if (isSubstrate(dataType)) {
        soil[type] ??= []
        soil[type].push(item)
      } else {
        if (!hasClimate) {
          hasClimate = climatePattern.test(dataType.category)
        }
        if (!hasIrrigation) {
          hasIrrigation = irrigationPattern.test(dataType.category)
        }
        other[type] ??= []
        other[type].push(item)
      }
      return allGroups
    }, reducerInitial)
    const filteredGroups = Object.fromEntries(
      Object.entries(newGroups)
        .filter(([key, { graphs }]) => key === 'IRRIGATION_DRIP' || key === 'IRRIGATION_DRAIN' || !isEmpty(graphs))
    )
    if (hasIrrigation) {
      filteredGroups.CLIMATE.title = hasClimate
        ? [CATEGORY_CATALOG.CLIMATE, CATEGORY_CATALOG.IRRIGATION_FLOW].join(' \u0026 ')
        : CATEGORY_CATALOG.IRRIGATION_FLOW
    }
    return filteredGroups
  },
}
export const GROUPING_ORDER = {
  [ROOM_GRAPH_SINGLE]: []
}
