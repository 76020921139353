const HAS_EVERYTHING = { has() { return true } }

export const TIER_ALLOWED_ANNOTATION_TYPES = {
  GO: new Set(['note', 'lightSchedule', 'reading', 'targetRange', 'notification']),
  DEFAULT: HAS_EVERYTHING,
}

export const ALLOWED_TIER_JOURNAL_TABS = {
  GO: new Set(['everything', 'notes', 'alerts']),
  DEFAULT: HAS_EVERYTHING,
}

export const ALLOWED_TIER_SETUP_TABS = {
  GO: new Set(['devices', 'facility']),
  DEFAULT: HAS_EVERYTHING,
}

export const HISTORICAL_LIMITED_DATA_DAYS = {
  GO: 60,
  DEFAULT: 0
}
