import createEntityBundle from '~/src/Lib/createEntityBundle'
import { facilityScope as scope, Pesticide as schema } from '~/src/Store/Schemas'

const name = 'pesticides'

export default createEntityBundle({
  name,
  apiConfig: { schema },
  scope,
})
