const ADD_SNACKBAR_MESSAGE = 'ADD_SNACKBAR_MESSAGE'
const DISMISS_SNACKBAR_MESSAGE = 'DISMISS_SNACKBAR_MESSAGE'

let counter = 0

export default {
  name: 'snackbar',
  reducer: (state = [], action = {}) => {
    const { type, payload, meta = {} } = action

    switch (type) {
      case ADD_SNACKBAR_MESSAGE:
        counter += 1
        return [
          ...state,
          {
            key: `snackbar_${counter}`,
            message: payload,
            status: meta?.status,
          },
        ]
      case DISMISS_SNACKBAR_MESSAGE:
        return state.filter(({ key }) => key !== payload)
      default:
        return state
    }
  },
  selectSnackbarMessages: state => state.snackbar,
  doAddSnackbarMessage: (message, status) => ({
    type: ADD_SNACKBAR_MESSAGE,
    payload: message,
    meta: { status },
  }),
  doDismissSnackbarMessage: key => ({
    type: DISMISS_SNACKBAR_MESSAGE,
    payload: key,
  }),
}
