import {
  compose,
  join,
  map,
  partialRight,
  prepend,
  reverse,
  toUpper,
  unapply,
} from 'ramda'

import { camelize as camelizeRaw, underscore as underscoreRaw } from 'inflection'

export const ENTITIES_RECEIVED = 'ENTITIES_RECEIVED'
export const ENTITIES_REMOVED = 'ENTITIES_REMOVED'
export const ENTITIES_CLEARED = 'ENTITIES_CLEARED'

export const DEFAULT_ASYNC_ACTIONS = ['start', 'succeed', 'fail']

const camelize = partialRight(camelizeRaw, [true])
const underscore = join('_')

/**
 * Creates a default action type identifier from an entity name and an action
 * @type {function}
 * @param {string} action
 * @param {string} name
 * @returns {string} A flux/redux action type identifier $NAME_$ACTION
 */
export const constantize = compose(
  toUpper,
  underscore
)

/**
 * Creates a default action type identifier from an entity name and an action
 * @type {function}
 * @param {string} action
 * @param {string} name
 * @returns {string} A flux/redux action type identifier $NAME_$ACTION
 */
export const getActionType = compose(
  constantize,
  unapply(reverse)
)

/**
 * A set of action types for a given asynchronous action
 * @typedef {Object} AsyncActionTypes
 * @property {string} prefix Typically $NAME_$ACTION
 * @property {string} start Typically $NAME_$ACTION_START
 * @property {string} succeed Typically $NAME_$ACTION_SUCCEED
 * @property {string} fail Typically $NAME_$ACTION_FAIL
 */
/**
 * Creates a set of action types to represent the transition points of an async action
 * @param {string} type
 * @param {string} name
 * @param {...string} args
 * @returns {AsyncActionTypes}
 */
export const getAsyncActionTypes = (type, name, ...args) => {
  const typePrefix = getActionType(type, name, ...args)
  return DEFAULT_ASYNC_ACTIONS.reduce(
    (acc, asyncAction) => ({
      ...acc,
      [asyncAction]: constantize([typePrefix, asyncAction]),
    }),
    { prefix: typePrefix }
  )
}

/**
 * Creates a default action creator name from an entity name and an action
 * @type {function}
 * @param {string} action
 * @param {string} name
 * @returns {string} An action creator name do$Name$Action
 */
export const getActionCreatorName = compose(
  // 5. 'do_plant_batch_save' => 'doPlantBatchSave'
  camelize,
  // 4. 'do', 'plant_batch', 'save'] => 'do_plant_batch_save'
  underscore,
  // 3. ['plant_batch', 'save'] => ['do', 'plant_batch', 'save']
  prepend('do'),
  // 2. ['plantBatch', 'save'] => ['plant_batch', 'save']
  map(underscoreRaw),
  // 1. action, name => [name, action]
  unapply(reverse)
)

/**
 * Default action type and action creator name for a given action and entity name
 * @typedef ActionIdentifiers
 * @property {string} type
 * @property {string} actionName
 */
/**
 * Given a name, action, and or sub-actions returns a config with an action type and action
 * creator name
 * @param  {...string} args Same args as getActionCreatorName and getActionType
 * @returns {{ type: string, actionName: string }}
 * @example
 * // getActionIdentifiers('change', 'color')
 * {
 *   type: 'COLOR_CHOOSE',
 *   actionName: 'doColorChange'
 * }
 */
export const getActionIdentifiers = (...args) => ({
  type: getActionType(...args),
  actionName: getActionCreatorName(...args),
})

/**
 * Default action types and action creator name for a given async action and entity name
 * @typedef AsyncActionIdentifiers
 * @property {AsyncActionTypes} types
 * @property {string} actionName
 */
/**
 * Given a name, action, and or sub-actions returns a config with async action types and action
 * creator name
 * @param  {...string} args Same args as getActionCreatorName and getAsyncActionType
 * @returns {AsyncActionIdentifiers}
 * @example
 * // getActionIdentifiers('save', 'color')
 * {
 *   types: {
 *     prefix: 'COLOR_SAVE',
 *     start: 'COLOR_SAVE_START',
 *     succeed: 'COLOR_SAVE_SUCCEED',
 *     fail: 'COLOR_SAVE_FAIL'
 *   },
 *   actionName: 'doColorSave'
 * }
 */
export const getAsyncActionIdentifiers = (...args) => ({
  types: getAsyncActionTypes(...args),
  actionName: getActionCreatorName(...args),
})
