import { trim } from 'ramda'

import { /* compressToUTF16, */ decompressFromUTF16 } from 'lz-string'
import ms from 'milliseconds'
import { getConfiguredCache as configureCache } from 'money-clip'

import config from '~/src/App/config'
import { getTokens } from '~/src/Lib/Auth'
import createLogger from '~/src/Lib/Logging'
import { repr } from '~/src/Lib/Utils'

const logger = createLogger('IO/Cache')
const { VERSION: CACHE_VERSION, CACHE_MAX_AGE_DAYS, CACHE_MAX_AGE_HOURS } = config

const fauxMethod = () => Promise.resolve(null)
const fauxCache = {
  clear: fauxMethod,
  del: fauxMethod,
  get: fauxMethod,
  getAll: fauxMethod,
  keys: fauxMethod,
  set: fauxMethod,
  logger,
}
const getConfiguredCache = options => {
  const cache = configureCache(options)
  const { get, set } = cache
  cache.get = (...args) => get(...args).then(stored => {
    if (stored == null || stored === '') {
      return null
    }

    try {
      return JSON.parse(stored.charCodeAt(0) === 7137 ? decompressFromUTF16(stored) : stored)
    } catch (error) {
      logger.error('failed to decompress cached object:', { error, compressed: stored })
      return null
    }
  })
  cache.set = (key, value) => {
    try {
      // return set(key, compressToUTF16(JSON.stringify(value)))
      return set(key, JSON.stringify(value))
    } catch (err) {
      logger.error('failed to compress cached object:', err)
      return null
    }
  }
  cache.getAll = () => cache.keys().then(keys => {
    const promises = keys.map(key => cache.get(key))
    return Promise.all(promises).then(data => data.reduce((acc, bundleData, index) => {
      if (bundleData) {
        acc[keys[index]] = bundleData
      }
      return acc
    }, {}))
  })
  return cache
}

let cache = fauxCache
const DEFAULT_MAX_AGE = ms.days(30)
export const getOptions = (userToken, scope) => {
  const version = [
    userToken.split(':')[0],
    CACHE_VERSION || 1,
  ].filter(o => o != null).join('|')
  let maxAge = DEFAULT_MAX_AGE
  if (Number(CACHE_MAX_AGE_HOURS)) {
    maxAge = ms.hours(Number(CACHE_MAX_AGE_HOURS))
  } else if (Number(CACHE_MAX_AGE_DAYS)) {
    maxAge = ms.days(Number(CACHE_MAX_AGE_DAYS))
  }

  return {
    name: scope ? `aroya_${scope}` : 'aroya_main',
    version,
    maxAge,
  }
}

const setUserToken = token => {
  const options = getOptions(token)
  logger.info('configuring cache with options:', options)
  cache = getConfiguredCache(options)
}

const { user: userToken } = getTokens()
if (userToken) {
  setUserToken(userToken)
}

export const getScopedCache = (scope, token = (getTokens()?.user || 'foo:bar')) => {
  if (typeof scope !== 'string' || !trim(scope)) {
    throw new TypeError(`[getScopedCache] scope must be a non-empty string. Received ${repr(scope)}`)
  }
  const options = getOptions(token, scope)
  logger.info('creating configured cache:', options)
  return getConfiguredCache(options)
}

const exportCache = {
  ...Object.keys(fauxCache).reduce((wrappedCache, key) => ({
    ...wrappedCache,
    [key]: (...args) => cache[key](...args),
  }), {}),
  logger,
  setUserToken,
}

export default exportCache
