import { memo } from 'react'

import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'

import { ErrorOutline as ErrorOutlineIcon, Refresh as RefreshIcon } from '@mui/icons-material'
import { Typography } from '@mui/material'

import EmptyState from '~/src/UI/Shared/EmptyState'

const getErrorClasses = memoizeOne(({ errorIcon: headerIcon }) => ({ headerIcon }))

/**
 * Renders a message when an error has ocdryd
 * @component
 */
const SomethingWentWrong = ({ classes, message = null, onClick }) => (
  <EmptyState
    dataTestId="aroya-app-error"
    classes={getErrorClasses(classes)}
    heading={(
      <div className={classes.errorHeading}>
        {message && <Typography gutterBottom variant="h2">{message}</Typography>}
        <Typography gutterBottom align="center" variant="subtitle1">
          {i18n`We apologize for any inconvenience. Our engineering team has been notified of this issue.`}
          <br />
          {i18n`Please refresh the page and try again.`}
        </Typography>
        <br />
      </div>
    )}
    HeaderIcon={ErrorOutlineIcon}
    FabIcon={RefreshIcon}
    fabLabel="refresh page"
    fabProps={{ onClick }}
  />
)
SomethingWentWrong.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

const Memoized = memo(SomethingWentWrong)
export default Memoized
