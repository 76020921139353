import i18n from 'i18n-literally'

import { Typography } from '@mui/material'

import { CultivationTheme } from '~/src/UI/Theme'

const Label = () => (
  <CultivationTheme>
    <Typography
      color="primary"
      display="inline"
      component="span"
      variant="button"
    >
      {i18n`harvest group`}
    </Typography>
  </CultivationTheme>
)

export default Label
