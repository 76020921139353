import { EMPTY_OBJECT } from '~/src/Lib/Utils'

export const buildHierarchy = (
  currentFacility = EMPTY_OBJECT,
  facilities = EMPTY_OBJECT,
  rooms = EMPTY_OBJECT,
  zones = EMPTY_OBJECT,
  devices = EMPTY_OBJECT,
) => ({
  currentFacility,
  facilities,
  rooms,
  zones,
  devices,
})

export const isPerformanceRoute = routeInfo => Boolean(
  routeInfo.pattern && routeInfo.pattern.startsWith('/facility/performance')
)
