import { useConnect } from 'redux-bundler-hook'
import { path } from 'ramda'

import { useGGV1Locked } from '~/src/Irrigation/GGV1Locked/utils'

export const EMERGENCY_SHOT_BEGINNER_MSG_PATH = ['irrigation', 'emergencyShot', 'beginnerMsg']
const getUserSetting = mySettings => path(EMERGENCY_SHOT_BEGINNER_MSG_PATH, mySettings)

export const useDismisssEmergencyShotWarning = () => {
  const { mySettings } = useConnect('selectMySettings')
  return !useGGV1Locked() && (getUserSetting(mySettings) ?? true)
}
