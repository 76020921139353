import { SvgIcon } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

const displayName = 'Icon/PoreEC'

const boltPath = 'M12.572 1L8 10.059l3.428-.823V15L16 5.941l-3.398.81L12.572 1z'
const substratePath = `
  M6.707 19c-.35-.007-.721.118-1.064.406-.423.355-1.054.3-1.41-.123-.354-.424-.299-1.054.124
  -1.41.675-.565 1.5-.885 2.371-.873.821-.01 1.458.39 1.838.628.014.009.028.017.04.026.47.293
  .605.348.73.344h.038l.038.001c.108.005.235-.04.706-.337l.046-.029c.378-.239 1.018-.642 1.837
  -.633.82-.01 1.458.39 1.838.628.014.009.028.017.04.026.47.293.605.348.73.344h.072c.113.006.25
  -.045.72-.335.016-.01.034-.02.051-.032.38-.235 1.018-.63 1.821-.63.861 0 1.679.312 2.356.86
  .43.348.496.978.148 1.407-.347.43-.977.496-1.406.148-.353-.285-.734-.416-1.098-.416-.184 0
  -.37.086-.822.365l-.061.039c-.363.225-.989.615-1.76.594-.768.01-1.385-.38-1.742-.606l-.068
  -.043c-.44-.274-.617-.352-.799-.349h-.04c-.177-.003-.352.075-.798.355l-.066.041c-.357.228
  -.984.626-1.762.602-.768.01-1.384-.38-1.74-.606l-.068-.043c-.44-.274-.617-.352-.799-.349h
  -.04zM6.707 23c-.35-.007-.721.118-1.064.406-.423.355-1.054.3-1.41-.123-.354-.424-.299-1.054
  .124-1.41.675-.565 1.5-.885 2.371-.873.821-.01 1.458.39 1.838.628.014.009.028.017.04.026.47
  .293.605.348.73.344h.038l.038.001c.108.005.235-.04.706-.337l.046-.029c.378-.239 1.018-.642
  1.837-.633.82-.01 1.458.39 1.838.628.014.009.028.017.04.026.47.293.605.348.73.344h.072c.113
  .006.25-.045.72-.335.016-.01.034-.02.051-.032.38-.235 1.018-.63 1.821-.63.861 0 1.679.312
  2.356.86.43.348.496.978.148 1.407-.347.43-.977.496-1.406.148-.353-.285-.734-.416-1.098-.416
  -.184 0-.37.086-.822.365l-.061.039c-.363.225-.989.615-1.76.594-.768.01-1.385-.38-1.742-.606l
  -.068-.043c-.44-.274-.617-.352-.799-.349h-.04c-.177-.003-.352.075-.798.355l-.066.041c-.357.228
  -.984.626-1.762.602-.768.01-1.384-.38-1.74-.606l-.068-.043c-.44-.274-.617-.352-.799-.349h-.04z
`

export const PoreECIconComponent = props => {
  const { palette } = useTheme()
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { pore_ec: dataType = {} } = dataTypes
  const { color = palette.yellow.main } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon
      {...props}
    >
      <title>EC: Pore Water EC</title>
      <path data-iconid="aroya-ec-bolt" d={boltPath} fill={fill} transform="translate(-1)" />
      <path
        data-iconid="aroya-pore_ec-substrate"
        clipRule="evenodd"
        d={substratePath}
        fill={fill}
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}
PoreECIconComponent.displayName = displayName

export default PoreECIconComponent
