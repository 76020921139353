import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { calculateSumFromPhaseData } from '../Analytics/utils'
import urls from './urls'

export const initialParams = {
  rooms: null,
  recipes: null,
  cultivars: null,
  after: null,
  before: null,
}

const initialState = {
  harvest: null,
  ...initialParams,
  hasDismissedComparisonMessage: false,
}

const queryKeyMap = {
  rooms: 'flower_room',
  recipes: 'source_recipe',
  cultivars: 'cultivar',
  before: 'harvest_date__lte',
  after: 'harvest_date__gte',
}

const harvestAnalyticsBundle = createAsyncResourceBundle({
  name: 'harvestAnalytics',
  actionBaseType: 'HARVEST_ANALYTICS',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvest)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvest})`))
    }
    const query = store.selectHarvestAnalyticsQueryParams()
    return apiFetch(`/harvests/${harvest}/analytics/`, query)
  },
})

export default {
  ...harvestAnalyticsBundle,
  reducer: reduceReducers(harvestAnalyticsBundle.reducer, (state, action) => {
    if (action.type === 'HARVEST_ANALYTICS_PARAMS_SET') {
      return {
        ...state,
        ...action.payload,
      }
    }
    if (!Object.keys(initialState).every(key => key in state)) {
      return { ...initialState, ...state }
    }
    return state
  }),
  doHarvestAnalyticsSetParams: payload => ({ dispatch }) => {
    dispatch({ type: 'HARVEST_ANALYTICS_PARAMS_SET', payload: { ...payload } })
    if ('hasDismissedComparisonMessage' in payload) return
    dispatch({ actionCreator: 'doMarkHarvestAnalyticsAsOutdated' })
    dispatch({ actionCreator: 'doMarkHarvestNotificationsAsOutdated' })
    dispatch({ actionCreator: 'doMarkHarvestTasksAsOutdated' })
    dispatch({ actionCreator: 'doMarkHarvestYieldsDistributionAsOutdated' })
    if ('harvest' in payload) {
      dispatch({ actionCreator: 'doMarkHarvestAnalyticsChartAsOutdated' })
      dispatch({ actionCreator: 'doMarkHarvestChartConfidenceAsOutdated' })
      dispatch({ actionCreator: 'doMarkHarvestChartTargetRangesAsOutdated' })
      dispatch({ actionCreator: 'doMarkHarvestGalleryAsOutdated' })
    }
  },
  reactSetHarvestAnalyticsFromRoute: createSelector(
    'selectHarvestAnalyticsRaw',
    'selectRouteInfo',
    ({ harvest }, { params, pattern }) => {
      const harvestId = Number(params.id)
      if (pattern === urls.analytics && harvest !== harvestId) {
        return { actionCreator: 'doHarvestAnalyticsSetParams', args: [{ harvest: harvestId }] }
      }
      return undefined
    },
  ),
  reactHarvestAnalyticsFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestAnalyticsShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics) {
        return { actionCreator: 'doFetchHarvestAnalytics' }
      }
      return undefined
    },
  }),
  selectHarvestAnalyticsQueryParams: createSelector(
    'selectHarvestAnalyticsRaw',
    params => Object.entries(queryKeyMap).reduce((query, [key, param]) => ({
      ...query,
      [param]: Array.isArray(params[key]) ? params[key].join(',') : params[key] ?? undefined,
    }), EMPTY_OBJECT)
  ),
  selectHarvestWasteWeight: createSelector(
    'selectHarvestAnalytics',
    analytics => (!analytics ? EMPTY_OBJECT : {
      current: calculateSumFromPhaseData(analytics.currentWaste),
      historical: Object.values(analytics.avgWaste).reduce((sum, val) => sum + val, 0),
    })
  )
}
