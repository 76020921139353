import { normalize } from 'normalizr'

import { createCustomAction, doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { EMPTY_OBJECT, parseApiErrors } from '~/src/Lib/Utils'
import { Harvest as harvestSchema } from '~/src/Store/Schemas'

const {
  actionIdentifiers: savePhases,
  actionReducer: phasesReducer,
  defaultState: defaultPhasesState,
} = createCustomAction({
  actionType: 'save',
  actionName: 'harvest_phases',
  reducerKey: 'phases',
  loadingKey: 'saving'
})

const createPhaseSnackbar = ({ dispatch, status, payload, result, error = {} }) => {
  const apiError = parseApiErrors(error)

  switch (status) {
    case 'succeed':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Saved ${result.name} phases.`]
      })
    case 'fail':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to save ${payload.name} phases${apiError ? `: ${apiError}` : ''}`]
      })
    default:
      return undefined
  }
}

/** @deprecated use `doPhaseSave` from the entity bundle */
const deprecatedHarvestPhasesSave = payload => async ({ dispatch, apiFetch }) => {
  dispatch({ type: savePhases.types.start })
  let result = false
  try {
    result = await apiFetch(`/harvests/${payload.id}/phases/`, payload, {
      method: 'PUT',
    })
    dispatch({ type: savePhases.types.succeed, payload, result })
    createPhaseSnackbar({ dispatch, status: 'succeed', payload, result })
    const { entities } = normalize(result, harvestSchema)
    dispatch(doEntitiesReceived(entities))
  } catch (error) {
    result = error
    dispatch({ type: savePhases.types.fail, error })
    createPhaseSnackbar({ dispatch, status: 'fail', payload, error })
  }
  return result
}

export const getLegacyBundle = entityBundle => {
  const initialState = {
    ...entityBundle.reducer.defaultState,
    ...defaultPhasesState,
  }

  return {
    reducer: (state = initialState, action = EMPTY_OBJECT) => {
      if (!action.type) return state

      if (action.type.startsWith(savePhases.types.prefix)) {
        return phasesReducer(state, action)
      }

      return entityBundle.reducer(state, action)
    },
    doBulkPhasesSave: deprecatedHarvestPhasesSave,
  }
}
