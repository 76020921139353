import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { createAppIsReadySelector } from '~/src/Store/utils'

import { getStartDate } from './chart'
import { isPerformanceRoute } from './utils'

const graphDataTypeToDurationsKey = {
  propagation: 'prop',
  vegetation: 'veg',
  flowering: 'flower',
  drying: 'dry',
}

const graphDataTypeToPhaseKey = {
  propagation: 'prop',
  vegetation: 'veg',
  flowering: 'flower',
  drying: 'dry',
}

const facilityEfficiencyBundle = createAsyncResourceBundle({
  name: 'facilityEfficiency',
  actionBaseType: 'FACILITY_EFFICIENCY',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { facility, timeFrame } = store.selectFacilityChartRaw()
    if (!facility) return Promise.reject(new Error('Waiting to load current facility'))
    return apiFetch('/facility/efficiency/', {
      start: getStartDate(timeFrame),
      facility,
    })
  },
})

export default {
  ...facilityEfficiencyBundle,
  selectFacilityDurations: createSelector(
    'selectFacilityEfficiency',
    'selectFacilityChartDomain',
    'selectFacilityPerformanceGraphs',
    (efficiency, domain, graphs) => efficiency && ['propagation', 'vegetation', 'flowering', 'drying'].map(dataType => ({
      ...graphs[dataType],
      key: graphDataTypeToPhaseKey[dataType],
      averageDays: { ...efficiency.durations[graphDataTypeToDurationsKey[dataType]] },
      domain,
    }))
  ),
  reactMarkFacilityEfficiencyOutdated: createSelector(
    'selectFacilityChartIsStale',
    'selectFacilityEfficiencyIsStale',
    'selectAvailableFeatures',
    'selectPermittedActions',
    'selectRouteInfo',
    (chartStale, selfStale, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (allowed && chartStale && !selfStale) {
        return { actionCreator: 'doMarkFacilityEfficiencyAsOutdated' }
      }
      return undefined
    }
  ),
  reactFacilityEfficiencyFetch: createAppIsReadySelector({
    dependencies: [
      'selectFacilityEfficiencyShouldUpdate',
      'selectAvailableFeatures',
      'selectPermittedActions',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (allowed && shouldUpdate) {
        return { actionCreator: 'doFetchFacilityEfficiency' }
      }
      return null
    }
  }),
}
