import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import memoizeOne from 'memoize-one'
import { identity, path } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'

import { EMPTY_OBJECT } from '../constants'
import { shallowEquals } from '../equality'
import memoize from '../memoizer'
import { debounce, defer } from '../timing'
import { usePrevious } from './usePrevious'

export * from './filestack'
export * from './useBluetoothScale'
export * from './useBluetoothScaleRedux'
export * from './useBarcodeScanner'
export * from './useInterval'
export * from './useIsLoaded'
export * from './useIsShortLandscape'
export * from './usePrevious'
export * from './useResizeObserver'

const logger = createLogger('Utils#hooks')

export const useClientRect = (...args) => {
  const nodeRef = useRef(null)
  const [rect, setRect] = useState(null)
  const ref = useCallback(node => {
    if (node !== null) {
      nodeRef.current = node
      setRect(node.getBoundingClientRect())
    }
  }, [])
  useEffect(() => {
    if (!nodeRef.current) return
    setRect(nodeRef.current.getBoundingClientRect())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args)
  return [rect, ref]
}

const getDBQPublicAPI = memoizeOne((add, flush) => ({ add, flush }))

export const useDebouncedBatchQueue = (
  receiver,
  maxQueued = 2,
  timeout = 3000
) => {
  const queue = useRef([])
  const scheduled = useRef(null)
  const flush = useCallback(() => {
    clearTimeout(scheduled.current)
    scheduled.current = null
    receiver(queue.current)
    queue.current = []
  }, [queue, scheduled, receiver])
  const add = useCallback(
    item => {
      clearTimeout(scheduled.current)
      queue.current.push(item)
      if (queue.current.length >= maxQueued) {
        flush()
        return
      }
      scheduled.current = setTimeout(flush, timeout)
    },
    [maxQueued, flush, timeout]
  )

  return getDBQPublicAPI(add, flush)
}

const getBubbleTaskEnqueuer = memoize((key, enqueuerConfig) => data => {
  const { flush, maxQueued, queue, scheduled, timeout } = enqueuerConfig
  // Cancel pre-existing scheduled flush
  clearTimeout(scheduled.current)
  queue.current = { ...queue.current, [key]: data }
  // If our current task fills the queue to max capacity, flush it.
  if (Object.keys(queue.current).length === maxQueued) {
    return flush()
  }
  // else schedule future auto-flush
  scheduled.current = setTimeout(flush, timeout)
  return scheduled.current
})

const getTQPublicAPI = memoizeOne((pause, restart, flush, add) => ({
  pause,
  restart,
  flush,
  add,
}))
/*
 * A hook that creates an auto-flushing task queue
 * @param {Object.<string, function>} config - A mapping of queueable task types
 * and their handler functions
 * @param {number} [maxQueued=2] - The maximum capacity of the queue. When this limit is reached,
 * the queue is automatically flushed
 * @param {numer} [timeout=3000] - The maximum wait (in ms) before auto-flushing the queue,
 * if no new tasks have been added
 */
export const useTaskQueue = config => {
  const { maxQueued = 2, timeout = 3000 } = config
  const handlers = useRef(config.handlers)
  useEffect(() => {
    Object.entries(config.handlers).forEach(([key, bubbler]) => {
      if (handlers.current[key] === bubbler) {
        return
      }
      handlers.current[key] = bubbler
    })
  }, [config.handlers])
  const queue = useRef({})
  const scheduled = useRef(null)

  const cancel = useCallback(() => {
    clearTimeout(scheduled.current)
    scheduled.current = null
  }, [scheduled])

  const flush = useCallback(() => {
    const toFlush = queue.current
    queue.current = {}
    cancel()
    Object.entries(toFlush).forEach(([key, data]) => handlers.current?.[key]?.(data))
    if (typeof config.onFlush === 'function') {
      defer(config.onFlush, defer.priorities.low)
    }
  }, [cancel, config.onFlush])

  const restart = useCallback(() => {
    const prevQueued = Object.keys(queue.current).length
    if (!prevQueued) return null
    if (prevQueued === maxQueued) {
      return flush()
    }
    scheduled.current = setTimeout(flush, timeout)
    return scheduled.current
  }, [maxQueued, flush, timeout])

  const enqueuerConfig = useRef({
    queue,
    flush,
    scheduled,
    maxQueued,
    timeout,
  })
  const enqueuers = useRef(
    Object.entries(config.handlers).reduce(
      (acc, [key]) => ({
        ...acc,
        [key]: getBubbleTaskEnqueuer(key, enqueuerConfig.current),
      }),
      EMPTY_OBJECT
    )
  )

  return getTQPublicAPI(cancel, restart, flush, enqueuers.current)
}

/**
 * Creates local state that is auto-updated when the props it's derived from change
 * @param {object} props
 * @param {string|string[]} propKey
 * @param {function(props.propKey, props): stateValue}
 * @returns - [drynt state value, setter function]
 */
export const useControlledState = (props = EMPTY_OBJECT, propKey = null, transform = identity) => {
  const prop = Array.isArray(propKey) ? path(propKey, props) : props[propKey]
  const prevArgs = usePrevious([props, propKey, transform])
  const stateAndSetter = useState(() => transform(prop, props))
  const [, setState] = stateAndSetter

  useEffect(() => {
    if (prevArgs === usePrevious.INITIAL_STATE) return
    const transformed = transform(prop, props)
    const [prevProps, prevKey] = prevArgs
    const prevTransformed = transform(prevProps[prevKey], prevProps)
    if (shallowEquals(transformed, prevTransformed)) return
    setState(transformed)
  }, [props, propKey, transform, prevArgs, prop, setState])

  return stateAndSetter
}

/**
 * Creates local state that is auto-updated when the prop it's derived from changes
 * @param {Object} config
 * @param {any} config.prop the value from props to use as the controlled value
 * @param {any} [config.defaultValue=config.prop]
 * @param {function} config.setter
 * @param {function} config.transform
 * @param {function} config.isEqual
 * @returns {[any, function]} [state value, setter function]
 */
export const useControlledStateV2 = ({
  prop,
  defaultValue: defaultValueProp = prop,
  isEqual = Object.is,
  setter: setterProp,
  transform = identity,
}) => {
  const debounceRef = useRef(0)
  const defaultValueRef = useRef(defaultValueProp)
  const defaultValueCounter = useRef(0)
  const setterRef = useRef(setterProp)
  const setterCounter = useRef(0)
  const [state, setState] = useState(() => (transform(prop) ?? defaultValueProp))

  if (setterRef.current !== setterProp) {
    setterRef.current = setterProp
    setterCounter.current += 1
    if (setterCounter.current > 5 && setterCounter.current % 5 === 0) {
      console.error(`${[
        'useControlledStateV2:\nsetter cannot be changed.',
        'Please stabilize your setter function.'
      ].join('\n')}\n`, { original: setterRef.current, current: setterProp })
    }
  }
  if (defaultValueRef.current !== defaultValueProp) {
    defaultValueRef.current = defaultValueProp
    defaultValueCounter.current += 1
    if (defaultValueCounter.current > 5 && defaultValueCounter.current % 5 === 0) {
      console.error(`${[
        'useControlledStateV2:\ndefaultValue cannot be changed.',
        'Please stabilize your defaultValue.',
        'If your defaultValue is dynamic, please use the defaultValue as a key for the component.'
      ].join('\n')}\n`, { original: defaultValueRef.current, current: defaultValueProp })
    }
  }

  const updateState = useCallback(newValue => setState(oldValue => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    const value = typeof newValue === 'function' ? newValue(oldValue) : newValue
    if (isEqual(oldValue, value)) return oldValue

    const { current: setter } = setterRef
    if (setter && typeof setter?.call === 'function') {
      debounceRef.current = setTimeout(() => {
        logger.debug('useControlledStateV2: calling setter', value)
        setter(value)
      }, 400)
    }
    return value ?? defaultValueRef.current
  }), [isEqual])

  useEffect(() => {
    updateState(transform(prop) ?? defaultValueRef.current)
  }, [prop, transform, updateState])

  return [state, updateState]
}

/**
 * Works like this.setState in a React class component (minus the post update callback)
 * @param {Object|function} initial an object or function that returns an object
 * @returns {Object} this initial state object or an empty object if passed initial state is null or undefined
 */
export const useSetState = initial => {
  const [state, setInnerState] = useState(() => {
    let initialState = initial
    if (typeof initial === 'function') {
      initialState = initial()
    }
    return initialState ?? {}
  })

  const setState = useCallback(update => {
    setInnerState(previous => {
      let next = update
      if (typeof update === 'function') {
        next = update(previous)
      }

      return next === useSetState.NOOP ? previous : { ...previous, ...next }
    })
  }, [])

  return [state, setState]
}
useSetState.NOOP = {}
Object.freeze(useSetState)

export const useItemColor = category => {
  const { itemCategories } = useConnect('selectItemCategories')

  return itemCategories[category]?.color?.main
}

/**
 *  Given a ref, returns an object with width and height properties that is updated when ref dimensions change
 *  @param {import('react').RefObject} myRef
 *  @returns {{ width: number, height: number }} dimensions object with width and height properties
 */
export const useResize = myRef => {
  const [dimensions, setDimensions] = useSetState({ width: 0, height: 0 })

  useEffect(() => {
    const handleResize = debounce(() => setDimensions(oldDimensions => {
      const { offsetHeight: height, offsetWidth: width } = myRef.current
      if (height === oldDimensions.height && width === oldDimensions.width) return useSetState.NOOP
      return { height, width }
    }), 66)

    handleResize()
    const global = getGlobal()
    global.addEventListener('resize', handleResize)

    return () => global.removeEventListener('resize', handleResize)
  }, [myRef, setDimensions])

  return dimensions
}
