import createListBundle, {
  defaultInitialState,
  defaultListActions,
} from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { PesticideApplication as schema } from '~/src/Store/Schemas'

export const initialState = {
  ...defaultInitialState,
  data: { result: EMPTY_ARRAY },
  filter: undefined,
  sort: ['ts', 'desc'],
  start: undefined,
  end: undefined,
  search: undefined
}

const queryKeyMap = {
  filter: 'room',
  sort: 'ordering',
  start: 'ts__gte',
  end: 'ts__lte',
}
const queryValueMap = {
  end: end => end && getDateTime(end).toUTC().toISO(),
  start: start => start && getDateTime(start).toUTC().toISO(),
}

const queryBuilder = params => Object.keys(params).reduce((acc, key) => ({
  ...acc,
  [queryKeyMap[key] ?? key]: (
    key in queryValueMap
      ? queryValueMap[key](params[key])
      : params[key]
  )
}), EMPTY_OBJECT)

export default createListBundle({
  entityName: 'pesticideApplication',
  actions: [...defaultListActions, 'set_start', 'set_end'],
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/pesticideApplications/', params),
  transformParams: queryBuilder,
  urlTest: (_, pattern) => pattern === '/ipm',
})
