import i18n from 'i18n-literally'

import { Comment as CommentIcon, Warning as WarningIcon } from '@mui/icons-material'

import { pluralize, transform } from 'inflection'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import BatchIcon from '~/src/UI/Icons/Batch'
import LightsOnIcon from '~/src/UI/Icons/LightsOn'
import MeasurementIcon from '~/src/UI/Icons/Measurement'
import NoteTaskCompleteIcon from '~/src/UI/Icons/NoteTaskComplete'
import PestIcon from '~/src/UI/Icons/Pest'
import TargetRangeIcon from '~/src/UI/Icons/TargetRange'

import HarvestGroupLabel from './HarvestGroupLabel'

const noUser = ['notification']

export const ANNOTATION_TYPES = {
  NOTE: 'note',
  TASK: 'task',
  READING: 'reading',
  LIGHT_SCHEDULE: 'lightSchedule',
  TARGET_RANGE: 'targetRange',
  HARVEST_PHASE: 'harvestPhase',
  PESTICIDE_APPLICATION: 'pesticideApplication',
  NOTIFICATION: 'notification',
  MANUAL_DRYBACK: 'manualDryback',
}

export const annotationLabels = {
  [ANNOTATION_TYPES.NOTE]: i18n`Notes`,
  [ANNOTATION_TYPES.TASK]: i18n`Tasks`,
  [ANNOTATION_TYPES.READING]: i18n`Readings`,
  [ANNOTATION_TYPES.LIGHT_SCHEDULE]: i18n`Light Schedules`,
  [ANNOTATION_TYPES.TARGET_RANGE]: i18n`Target Ranges & Alerts`,
  [ANNOTATION_TYPES.HARVEST_PHASE]: i18n`Harvests`,
  [ANNOTATION_TYPES.PESTICIDE_APPLICATION]: i18n`Pesticide Applications`,
  [ANNOTATION_TYPES.NOTIFICATION]: i18n`Notifications`,
  [ANNOTATION_TYPES.MANUAL_DRYBACK]: i18n`Manual Drybacks`,
}

export const annotationTypes = ([
  {
    type: ANNOTATION_TYPES.NOTE,
    label: annotationLabels[ANNOTATION_TYPES.NOTE],
    singularLabel: i18n`Note`,
    Icon: CommentIcon,
    actions: ['change_note', 'view_room'],
    anyAction: true,
  },
  {
    type: ANNOTATION_TYPES.TASK,
    label: annotationLabels[ANNOTATION_TYPES.TASK],
    singularLabel: i18n`Task`,
    Icon: NoteTaskCompleteIcon,
    actions: ['change_task', 'view_task'],
    anyAction: true,
  },
  {
    type: ANNOTATION_TYPES.READING,
    label: annotationLabels[ANNOTATION_TYPES.READING],
    singularLabel: i18n`Reading`,
    model: 'manualreading',
    Icon: MeasurementIcon,
    actions: ['change_manualreading', 'view_room'],
    anyAction: true,
  },
  {
    type: ANNOTATION_TYPES.LIGHT_SCHEDULE,
    label: annotationLabels[ANNOTATION_TYPES.LIGHT_SCHEDULE],
    singularLabel: i18n`Light Schedule`,
    Icon: LightsOnIcon,
    zones: false,
    readonly: true,
    actions: ['change_lightschedule', 'view_room'],
    anyAction: true,
  },
  {
    type: ANNOTATION_TYPES.TARGET_RANGE,
    Icon: TargetRangeIcon,
    label: annotationLabels[ANNOTATION_TYPES.TARGET_RANGE],
    zones: false,
    mobile: false,
    readonly: true,
    actions: ['change_targetrange', 'view_room'],
    anyAction: true,
  },
  {
    type: ANNOTATION_TYPES.HARVEST_PHASE,
    Icon: () => <BatchIcon />,
    label: <HarvestGroupLabel />,
    filterLabel: 'Harvests',
    textLabel: annotationLabels[ANNOTATION_TYPES.HARVEST_PHASE],
    readonly: true,
    alternateSchema: 'harvests',
    actions: ['view_harvest'],
  },
  {
    type: ANNOTATION_TYPES.NOTIFICATION,
    label: annotationLabels[ANNOTATION_TYPES.NOTIFICATION],
    readonly: true,
    extraProps: {
      noDialogs: true,
    },
    Icon: WarningIcon,
  },
  {
    type: ANNOTATION_TYPES.PESTICIDE_APPLICATION,
    model: 'pesticide_application',
    Icon: PestIcon,
    label: annotationLabels[ANNOTATION_TYPES.PESTICIDE_APPLICATION],
    singularLabel: i18n`Pesticide Application`,
    actions: ['view_pesticide_application', 'change_pesticide_application'],
  },
]).filter(at => Boolean(at.type)).map(({
  type,
  schema = pluralize(type),
  ...rest
}) => ({
  ...rest,
  type,
  schema,
  queryParam: `hide${transform(schema, ['underscore', 'camelize'])}`
}))

export const annotationTypeMap = annotationTypes.reduce((acc, entry) => ({
  ...acc,
  [entry.type]: entry,
}), EMPTY_OBJECT)

export const schemaTypeMap = annotationTypes.reduce((acc, entry) => ({
  ...acc,
  [entry.schema]: entry,
}), EMPTY_OBJECT)

export const defaultAllowedTypes = annotationTypes.map(({ type }) => type)
export const activeUserAllowedTypes = annotationTypes.filter(({ type }) => !noUser.includes(type)).map(({ type }) => type)
export const schemas = annotationTypes.flatMap(({ alternateSchema, schema }) => (
  alternateSchema ? [schema, alternateSchema] : schema
))
