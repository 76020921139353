import colorUtil from 'color'

import memoize from './memoizer'

const MAIN_CONTRAST_THRESHOLD = 2.5
const MUTED_CONTRAST_THRESHOLD = 2.1

export const getMainColor = memoize((original, bg) => {
  let color = colorUtil(original)
  const contrast = color.contrast(colorUtil(bg))
  if (contrast < (MAIN_CONTRAST_THRESHOLD - (MAIN_CONTRAST_THRESHOLD / 10))) {
    color = color.lighten((MAIN_CONTRAST_THRESHOLD - contrast) / contrast)
  }
  return color
})

export const getMutedColor = memoize((original, bg) => {
  let color = colorUtil(original)
  const bgColor = colorUtil(bg)
  const testColor = bgColor.mix(color, 0.68)
  const contrast = testColor.contrast(bgColor)
  const hueDistance = Math.abs(bgColor.hue() - testColor.hue())
  if (contrast < (MUTED_CONTRAST_THRESHOLD - (MUTED_CONTRAST_THRESHOLD / 10)) || hueDistance < 36) {
    const lightenAmt = hueDistance >= 50
      ? (MUTED_CONTRAST_THRESHOLD - contrast) / contrast
      : ((MUTED_CONTRAST_THRESHOLD - contrast) / contrast) + (((50 - hueDistance) / hueDistance) / 2)
    color = color.lighten(lightenAmt)
  }
  return color
})
