import PropTypes from 'prop-types'

import * as yup from 'yup'

export const shape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  activeIngredient: PropTypes.string.isRequired,
  restrictedEntryTime: PropTypes.number.isRequired,
  registrationNumber: PropTypes.string.isRequired,
  defaultApplicationRate: PropTypes.string,
})

export default yup.object({
  name: yup.string().trim().required('please enter name'),
  activeIngredient: yup.string().trim()
    .required('please enter active ingredient'),
  restrictedEntryTime: yup.number().integer()
    .typeError('please enter a number of hours')
    .required('please enter a number of hours')
    .min(0, 'please enter a positive number'),
  registrationNumber: yup.string().trim()
    .min(3, 'must be at least three (3) characters')
    .required('please enter registration number'),
  defaultApplicationRate: yup.string().trim().nullable(),
})
