import { createSelector } from 'redux-bundler'
import onlineBundle from 'redux-bundler/dist/online-bundle'

import getGlobal from '~/src/Lib/getGlobal'
import { TAB_STATES } from '../constants'

export default {
  ...onlineBundle,
  reactUpdateOnlineStatus: createSelector(
    'selectIsOnline',
    'selectTabState', // this is to ensure we re-evaluate online status when tab state changes
    (isOnline, tabState) => {
      if (tabState !== TAB_STATES.ACTIVE) return null
      const { navigator } = getGlobal()
      if (navigator && navigator.onLine !== isOnline) {
        return { type: navigator.onLine ? 'ONLINE' : 'OFFLINE' }
      }
      return null
    }
  ),
}
