import { createInventoryBundle } from '~/src/Inventory/bundle'
import { REPLACE_PLANT } from '~/src/Inventory/constants'

const name = 'replacePlantInventory'

const initialState = {
  types: 'plant',
  page: 1,
  pageSize: 50
}

export default createInventoryBundle(name, {
  name,
  initialState,
  urlTest: url => url.includes(REPLACE_PLANT),
})
