import { useEffect, useRef } from 'react'

import { useConnect } from 'redux-bundler-hook'

import createLogger from '~/src/Lib/Logging'

const logger = createLogger('useBarcodeScanner')

export const useBarcodeScanner = (scanHandler, manualInputHandler) => {
  const ref = useRef(null)
  const scanRef = useRef(scanHandler)
  const manualRef = useRef(manualInputHandler)
  const handlerChanges = useRef(0)
  const { doBarcodeConnect, doBarcodeDisconnect } = useConnect('doBarcodeConnect', 'doBarcodeDisconnect')

  useEffect(() => {
    doBarcodeConnect(ref, scanRef, manualRef)
    return () => {
      doBarcodeDisconnect(ref)
    }
  }, [])

  useEffect(() => {
    if (scanHandler !== scanRef.current || manualInputHandler !== manualRef.current) {
      handlerChanges.current += 1
      scanRef.current = scanHandler
      manualRef.current = manualInputHandler
      doBarcodeConnect(ref, scanRef, manualRef)
      if (handlerChanges.current > 5) {
        logger.warn('handler for', ref.current, 'has changed', handlerChanges.current, 'times')
      }
    }
  }, [scanHandler, manualInputHandler])

  return ref
}
