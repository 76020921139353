import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import createLogger from '~/src/Lib/Logging'
import { createAppIsReadySelector } from '~/src/Store/utils'

const logger = createLogger('accountSubscription/bundle')

const accountSubscriptionBundle = createAsyncResourceBundle({
  name: 'accountSubscription',
  actionBaseType: 'ACCOUNT_SUBSCRIPTION',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch }) => apiFetch('/facility/subscription/')
})

export default {
  ...accountSubscriptionBundle,
  doFetchActiveDevices: () => async ({ apiFetch, store }) => {
    const currentFacilityId = await store.selectCurrentFacilityId()
    try {
      // eslint-disable-next-line babel/camelcase
      const activeDevices = await apiFetch(`/crm/facility_active_devices/${currentFacilityId}/`, { teros_only: true })
      return activeDevices
    } catch (error) {
      return error
    }
  },
  reactAccountSubscriptionFetch: createAppIsReadySelector({
    dependencies: [
      'selectAccountSubscriptionShouldUpdate',
      'selectPermittedActions'
    ],
    resultFn: (shouldUpdate, permittedActions) => {
      const allowed = permittedActions.has('manage_account')
      if (allowed && shouldUpdate) {
        return { actionCreator: 'doFetchAccountSubscription' }
      }
      return null
    }
  }),
}
