import { filter, mapObjIndexed, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'

import createEntityBundle, {
  actionFactory,
  createCustomAction,
  ENTITIES_RECEIVED,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { defer, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Event as schema, InventoryItem } from '~/src/Store/Schemas'

const name = 'events'
const singularName = 'event'
const defaultConfig = { name, singularName, schema }

const logger = createLogger('Compliance/bundle')

const getDirtiesWithErrors = filter(prop('lastError'))

const {
  actionIdentifiers: fetchInventoryEvent,
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'inventoryEvent',
  reducerKey: 'inventory',
  loadingKey: 'fetching'
})

const {
  actionIdentifiers: fetchHistoryEvents,
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'historyEvents',
  reducerKey: 'historyEvents',
  loadingKey: 'fetching'
})

const {
  actionIdentifiers: fetchHistory,
  actionReducer: fetchHistoryReducer,
  defaultState: defaultHistoryState,
} = createCustomAction({
  actionName: 'history',
  actionType: 'fetch',
  defaultData: EMPTY_OBJECT,
  reducerKey: 'history',
  loadingKey: 'fetching'
})

const defaultFetchInventoryState = {
  error: null,
  loading: false,
  data: [],
}

const defaultFetchHistoryEventsState = {
  error: null,
  loading: false,
  data: [],
}

const initialBundle = createEntityBundle({
  ...defaultConfig,
  apiConfig: { schema },
  customActions: [{
    ...defaultConfig,
    action: 'sync',
    handler: ({ payload, apiFetch, dispatch }) => (
      apiFetch(`/events/${payload.id}/sync/`, null, { method: 'POST' })
        .then(result => {
          defer(() => dispatch({ actionCreator: 'doMarkEventListAsOutdated' }), defer.priorities.low)
          defer(() => dispatch({ actionCreator: 'doFetchEventStats' }), defer.priorities.low)
          return result
        })
    ),
    snackbar: false,
    async: true
  },
  {
    ...defaultConfig,
    action: 'download_csv',
    handler: ({ payload, apiFetch }) => apiFetch(`/events/${payload.id}/csv/`, null, { download: true })
      .then(() => payload),
    snackbar: false,
    async: true
  },
  {
    ...defaultConfig,
    action: 'undo',
    handler: ({ payload, apiFetch, dispatch, store }) => apiFetch(`/events/${payload.id}/undo/`, null, { method: 'POST' })
      .then(result => {
        const { [payload.eventType]: eventType } = store.selectEventTypes()
        dispatch({ actionCreator: 'doAddSnackbarMessage', args: [`Event ${eventType.name} undone`] })
        defer(() => dispatch({ actionCreator: 'doMarkEventListAsOutdated' }), defer.priorities.low)
        defer(() => dispatch({ actionCreator: 'doFetchEventStats' }), defer.priorities.low)
        return result
      }),
    remove: true,
    snackbar: false,
    async: true
  },
  {
    ...defaultConfig,
    action: 'confirm_sync',
    handler: ({ payload, apiFetch, dispatch }) => (
      apiFetch(`/events/${payload.id}/sync/`, { resolveErrors: true }, { method: 'POST' })
        .then(result => {
          defer(() => dispatch({ actionCreator: 'doMarkEventListAsOutdated' }), defer.priorities.low)
          defer(() => dispatch({ actionCreator: 'doFetchEventStats' }), defer.priorities.low)
          return result
        })
    ),
    snackbar: ({
      status,
      dispatch,
    }) => (
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? 'Event has been marked synced'
            : 'Unable to confirm sync'
        ]
      })
    ),
    async: true
  },
  {
    ...defaultConfig,
    action: 'replace_mother',
    handler: ({ payload, apiFetch, dispatch }) => (
      apiFetch(`/events/${payload.eventId}/replace_mother/`, payload, { method: 'PUT' })
        .then(result => {
          defer(() => dispatch({ actionCreator: 'doMarkEventListAsOutdated' }), defer.priorities.low)
          defer(() => dispatch({ actionCreator: 'doFetchEventStats' }), defer.priorities.low)
          return result
        })
    ),
    snackbar: ({
      status,
      dispatch,
    }) => (
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          status === 'succeed'
            ? 'Mother plant has been successfully replaced'
            : 'Unable to replace mother plant'
        ]
      })
    ),
    async: true
  }],
  // scope,
})

const {
  reducer: entityReducer,
  doEventSync: syncEvent
} = initialBundle

const initialState = {
  ...entityReducer.defaultState,
  ...defaultHistoryState,
  inventory: EMPTY_OBJECT,
}

const doEventSyncErrorResolve = actionFactory('resolve', 'event_sync_error')
const { type: resolveSyncErrorType } = doEventSyncErrorResolve

export default {
  ...initialBundle,
  reducer: (state = initialState, action = EMPTY_OBJECT) => {
    if (!action.type) return state

    if (action.type === fetchInventoryEvent.types.start) {
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.payload.eventId]: {
            ...defaultFetchInventoryState,
            loading: true,
          }
        }
      }
    }
    if (action.type === fetchInventoryEvent.types.succeed) {
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.payload.eventId]: {
            ...defaultFetchInventoryState,
            data: action.payload.data,
          }
        }
      }
    }
    if (action.type === fetchInventoryEvent.types.fail) {
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.payload.eventId]: {
            ...defaultFetchInventoryState,
            error: action.payload.error,
          }
        }
      }
    }
    if (action.type === fetchHistoryEvents.types.start) {
      const { key, params } = action.payload
      return {
        ...state,
        historyEvents: {
          ...state.historyEvents,
          [key]: {
            ...defaultFetchHistoryEventsState,
            loading: true,
            params,
          }
        }
      }
    }
    if (action.type === fetchHistoryEvents.types.succeed) {
      return {
        ...state,
        historyEvents: {
          ...state.historyEvents,
          [action.payload.key]: {
            ...state.historyEvents[action.payload.key],
            ...defaultFetchHistoryEventsState,
            data: action.payload.data,
          }
        }
      }
    }
    if (action.type === fetchHistoryEvents.types.fail) {
      return {
        ...state,
        historyEvents: {
          ...state.historyEvents,
          [action.payload.key]: {
            ...state.historyEvents[action.payload.key],
            ...defaultFetchHistoryEventsState,
            error: action.payload.error,
          }
        }
      }
    }
    if (action.type.startsWith(fetchHistory.types.prefix)) {
      return fetchHistoryReducer(state, action)
    }
    if (action.type.startsWith(syncEvent.types.prefix)) {
      if (action.type === resolveSyncErrorType) {
        const { id, uuid } = action.payload
        const { [id]: oldDirty = EMPTY_OBJECT } = state.dirty
        const { resolvedErrors: oldResolved } = oldDirty
        return {
          ...state,
          dirty: {
            ...state.dirty,
            [id]: {
              ...oldDirty,
              resolvedErrors: oldResolved ? new Set([...oldResolved, uuid]) : new Set([uuid])
            }
          }
        }
      }
    }
    return entityReducer(state, action)
  },
  selectEventHistory: createSelector(
    'selectEventsRoot',
    eventsRoot => (eventsRoot?.history ?? EMPTY_OBJECT),
  ),
  selectCurrentEventSyncErrors: createSelector(
    'selectEventsDirty',
    dirty => {
      const withErrors = getDirtiesWithErrors(dirty)
      return mapObjIndexed(({ resolvedErrors = new Set(), lastError }) => {
        if (!Array.isArray(lastError.error)) return null
        return lastError.error.filter(({ uuid }) => (
          !resolvedErrors.has(uuid)
        ))
      }, withErrors)
    }
  ),
  selectEventInventory: createSelector(
    'selectEventsRoot',
    eventsRoot => (eventsRoot?.inventory ?? EMPTY_OBJECT),
  ),
  selectHistoryEvents: createSelector(
    'selectEventsRoot',
    eventsRoot => (eventsRoot?.historyEvents ?? EMPTY_OBJECT),
  ),
  doEventSyncErrorResolve,
  doFetchEventHistory: () => async ({ store, dispatch, apiFetch }) => {
    const params = store.selectEventListApiParams()
    dispatch({ type: fetchHistory.types.start, payload: { params } })
    let result = false
    try {
      result = await apiFetch('/events/available_months/', { ...params, synced: true })
      dispatch({ type: fetchHistory.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({ type: fetchHistory.types.fail, error })
    }
    return result
  },
  doMarkEventSynced: event => async ({ dispatch, store, apiFetch }) => {
    dispatch({ type: syncEvent.types.start })
    let result = false
    try {
      result = await apiFetch(`/events/${event.id}/mark_synced/`, null, { method: 'POST' })
      store.doFetchEventStats()
      const { entities } = normalize(result, schema)
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      dispatch({ type: syncEvent.types.succeed, payload: result })
      dispatch({ actionCreator: 'doMarkEventListAsOutdated' })
    } catch (error) {
      result = error
      dispatch({ type: syncEvent.types.fail, error })
    }
    return result
  },
  doFetchEventInventory: eventId => async ({ dispatch, apiFetch }) => {
    dispatch({ type: fetchInventoryEvent.types.start, payload: { eventId } })
    try {
      const payload = await apiFetch('/inventory/', {
        event: eventId,
        types: ['plantBatch', 'plant', 'harvestBatch', 'package'],
        hideFinished: 'false',
      })
      const { entities, result } = normalize(payload, [InventoryItem])
      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      dispatch({ type: fetchInventoryEvent.types.succeed, payload: { eventId, data: result } })
      return result
    } catch (error) {
      dispatch({ type: fetchInventoryEvent.types.fail, payload: { eventId, error } })
      return error
    }
  },
  doFetchHistoryEvents: (key, yearMonth, eventType) => async ({ apiFetch, dispatch, store }) => {
    const params = {
      ...store.selectEventListApiParams(),
      yearMonth,
      eventType,
      synced: true
    }
    dispatch({ type: fetchHistoryEvents.types.start, payload: { key, params } })
    try {
      const payload = await apiFetch('/events/', params)
      dispatch({ type: fetchHistoryEvents.types.succeed, payload: { key, data: payload } })
      return payload
    } catch (error) {
      dispatch({ type: fetchHistoryEvents.types.fail, payload: { key, error } })
      return error
    }
  },
}
