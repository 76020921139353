import { omit } from 'ramda'

import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import createEntityBundle, {
  doEntitiesReceived,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import { Grade as schema } from '~/src/Store/Schemas'

const name = 'grades'
const saveOrder = getAsyncActionIdentifiers('save_order', 'grades')

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
  },
})

const defaultState = {
  inflight: {},
  errors: {},
}

export default {
  ...initialBundle,
  reducer: reduceReducers(defaultState, initialBundle.reducer, (state, action) => {
    if (!action.type.startsWith(saveOrder.types.prefix)) return state
    switch (action.type) {
      case saveOrder.types.start: {
        return {
          ...state,
          inflight: {
            ...state.inflight,
            saveOrder: true,
          },
          errors: omit(['saveOrder'], state.errors)
        }
      }
      case saveOrder.types.succeed: {
        return {
          ...state,
          inflight: omit(['saveOrder'], state.inflight),
        }
      }
      case saveOrder.types.fail: {
        return {
          ...state,
          inflight: omit(['saveOrder'], state.inflight),
          errors: {
            ...state.errors,
            saveOrder: action.error
          }
        }
      }
      default:
        return state
    }
  }),
  doSaveGradesOrder: payload => async ({ apiFetch, store, dispatch }) => {
    const storeGrades = store.selectGrades()
    const grades = payload.reduce((reordered, id, index) => ({
      ...reordered,
      [id]: {
        ...storeGrades[id],
        sequence: index,
      }
    }), {})
    dispatch({ type: saveOrder.types.start })
    dispatch(doEntitiesReceived({ grades }))
    try {
      const response = apiFetch('/grades/sequence/', payload, { method: 'PUT' })
      const { entities } = normalize(response, [schema])
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: saveOrder.types.succeed })
      store.doAddSnackbarMessage('Grade saved successfully.')
    } catch (error) {
      dispatch({ type: saveOrder.types.fail, error })
      store.doAddSnackbarMessage('Grade failed to save.')
    }
  }
}
