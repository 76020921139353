import { transform } from 'inflection'

export const SCROLL_MARGIN = 30
export const ROW_HEIGHT = 65
export const TABLE_MIN_WIDTH = 960
export const INVENTORY_TYPES = {
  GROWING: ['plant', 'plantBatch', 'package'],
  HARVESTED: ['harvestBatch', 'package'],
  ALL: ['plant', 'plantBatch', 'harvestBatch', 'package']
}
const OPTION_LABELS = {
  plantBatch: 'Plant lot'
}

export const INVENTORY_TYPES_OPTIONS = Object.entries(INVENTORY_TYPES).reduce((optionsMap, [key, list]) => ({
  ...optionsMap,
  [key]: list.map(value => ({
    label: value in OPTION_LABELS
      ? OPTION_LABELS[value]
      : transform(value, ['underscore', 'humanize']),
    value: [value]
  }))
}), {})

INVENTORY_TYPES.HARVEST = [...INVENTORY_TYPES.GROWING.slice(0, -1), ...INVENTORY_TYPES.HARVESTED]
INVENTORY_TYPES_OPTIONS.HARVEST = [
  ...INVENTORY_TYPES_OPTIONS.GROWING.slice(0, -1),
  ...INVENTORY_TYPES_OPTIONS.HARVESTED
]

export const REPLACE_PLANT = 'replacePlant'
export const REPLACE_PLANT_ROUTE = `${REPLACE_PLANT}/:id`
