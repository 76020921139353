import { pick } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { normalize, schema } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { createCustomAction } from '~/src/Lib/createEntityBundle'
import { defaultCase } from '~/src/Lib/createListBundle'
import { EMPTY_OBJECT, getDateTime, parseApiErrors } from '~/src/Lib/Utils'
import { Harvest, Recipe, Room } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const RunSchema = new schema.Object({
  harvests: [Harvest],
  recipes: [Recipe],
  rooms: [Room],
})

const RUNS_SET_PARAMS = 'RUNS_SET_PARAMS'
const CULTIVAR_RUNS_SET_PARAMS = 'CULTIVAR_RUNS_SET_PARAMS'

const cultivarRunsKey = 'cultivarRuns'

const pagination = {
  count: '',
  current: '',
  next: '',
  numPages: '',
  pageSize: '',
  previous: '',
  page: 1,
}

const defaultState = {
  cultivar: '',
  facility: '',
  recipe: '',
  ordering: '',
  search: '',
  start: '',
}

const initialState = {
  ...pagination,
  ...defaultState
}

const {
  actionIdentifiers: cultivarRuns,
  actionReducer: cultivarRunsReducer,
  defaultState: cultivarRunsDefaultState,
} = createCustomAction({
  actionType: 'fetch',
  actionName: cultivarRunsKey,
  reducerKey: cultivarRunsKey,
  loadingKey: 'loading'
})

const ensureEntityEntryKeys = ['harvests', 'phases', 'recipes', 'rooms', 'zones']
const formatResponse = response => {
  const { entities } = normalize(response, RunSchema)

  return {
    ...pick(Object.keys(pagination), response),
    entities: ensureEntityEntryKeys.reduce((acc, key) => {
      acc[key] ??= EMPTY_OBJECT
      return acc
    }, entities),
    runs: response.results,
  }
}

const initialBundle = createAsyncResourceBundle({
  name: 'runs',
  getPromise: async ({ apiFetch, store }) => {
    const runs = store.selectRunsRaw()

    try {
      const req = {
        // eslint-disable-next-line babel/camelcase
        completion_status: runs.completion_status || undefined,
        cultivar: runs.cultivar || undefined,
        facility: runs.facility || undefined,
        recipe: runs.recipe || undefined,
        search: runs.search || undefined,
        page: runs.page || undefined,
        ordering: runs.ordering || undefined,
        start: runs.start
          ? getDateTime('now').minus(JSON.parse(runs.start)).toISODate()
          : undefined,
      }
      const response = await apiFetch('/strainruns/', req)
      return formatResponse(response)
    } catch (e) {
      console.error('/strainruns ', e)
      return false
    }
  },
})

const bundle = {
  ...initialBundle,
  reducer: reduceReducers(initialBundle.reducer, (state = cultivarRunsDefaultState, action = EMPTY_OBJECT) => {
    if (action.type.startsWith(cultivarRuns.types.prefix)) {
      return cultivarRunsReducer(state, action)
    }
    switch (action.type) {
      case CULTIVAR_RUNS_SET_PARAMS:
        return {
          ...state,
          [cultivarRunsKey]: {
            ...state[cultivarRunsKey],
            ...action.payload
          }
        }
      case RUNS_SET_PARAMS:
        return { ...state, ...action.payload }
      default:
        return defaultCase(initialState, state)
    }
  }),
  doCultivarRunsFetch: () => async ({ dispatch, apiFetch, store }) => {
    dispatch({ type: cultivarRuns.types.start })
    let result = false
    try {
      result = await apiFetch(
        '/strainruns/',
        pick(Object.keys(initialState), store.selectCultivarRunsRaw()),
        { method: 'GET' }
      )
      dispatch({ type: cultivarRuns.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({
        type: cultivarRuns.types.fail,
        error: parseApiErrors(error) || 'An unexpected error occurred.',
      })
    }
    return result
  },
  selectRunsIsLoading: createSelector(
    'selectRunsRaw',
    raw => raw?.isLoading || raw?.cultivarRuns?.loading
  ),
  selectCultivarRuns: createSelector(
    'selectRunsRaw',
    raw => (raw?.cultivarRuns?.data ? formatResponse(raw?.cultivarRuns?.data) : null)
  ),
  selectCultivarRunsRaw: createSelector(
    'selectRunsRaw',
    raw => raw?.cultivarRuns ?? EMPTY_OBJECT
  ),
  selectRunsIsFiltered: createSelector(
    'selectRunsRaw',
    raw => Object.values(pick(Object.keys(defaultState), raw)).filter(Boolean).length > 0
  ),
  doRunsSetParams: payload => ({ dispatch }) => {
    dispatch({ type: RUNS_SET_PARAMS, payload })
    dispatch({ actionCreator: 'doMarkRunsAsOutdated' })
  },
  doCultivarRunsSetParams: payload => ({ dispatch }) => {
    dispatch({ type: CULTIVAR_RUNS_SET_PARAMS, payload })
    dispatch({ actionCreator: 'doCultivarRunsFetch' })
  },
  reactRunsFetch: createAppIsReadySelector({
    dependencies: [
      'selectRunsShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern.startsWith('/runs')) {
        return { actionCreator: 'doFetchRuns' }
      }
      return undefined
    }
  }),
}

export default bundle
