import { memo, useState } from 'react'

import i18n from 'i18n-literally'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, IconButton, InputAdornment } from '@mui/material'

import { Field, useFormikContext } from 'formik'

import { FormikTextField } from '~/src/UI/Shared/Form/TextField'
import RequirementsList from '~/src/UI/Shared/RequirementsList'

const SetPassword = ({
  fields,
  valid = true
}) => {
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const { values, errors } = useFormikContext()

  return (
    <Box display="flex" flexDirection="column" gap="1ch">
      <Box display="flex" flexDirection="column" gap="1ch">
        <Field
          required
          fullWidth
          disabled={!valid}
          data-testid="account-creation-password"
          name="password1"
          type={showPassword1 ? 'text' : 'password'}
          label={i18n`New Password`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword1(!showPassword1)}
                  size="large"
                >
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          component={FormikTextField}
        />
        <Field
          required
          fullWidth
          disabled={!valid}
          data-testid="account-creation-confirm-password"
          name="password2"
          label={i18n`Confirm Password`}
          type={showPassword2 ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword2(!showPassword2)}
                  size="large"
                >
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          component={FormikTextField}
        />
      </Box>
      <RequirementsList
        matchFGColors
        showWithoutErrors
        tooltip={false}
        fields={fields}
        values={values}
        errors={errors}
      />
    </Box>
  )
}

const Memoized = memo(SetPassword)
export default Memoized
