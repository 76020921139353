import { SvgIcon } from '@mui/material'

export const path = `
  M19 13h-.5a.5.5 0 1 1 0-1h.5v-2h-.5a.5.5 0 1 1 0-1h.5V7h-.5a.5.5 0 1 1
  0-1h.5V5a1 1 0 0 0-2 0v9.999l-.799.6a3 3 0 1 0 3.598 0l-.799-.6V13zm-4-8a3
  3 0 0 1 6 0v9a5 5 0 1 1-6 0V5zM7.18 17.573l-2.9-2.928a.967.967 0 0 1
  0-1.362.953.953 0 0 1 1.354 0L7 14.658V9.342l-1.366 1.375a.953.953 0 0
  1-1.353 0 .967.967 0 0 1 0-1.362l2.9-2.928a.999.999 0 0 1 1.638-.002l2.9
  2.92a.967.967 0 0 1 0 1.363.953.953 0 0 1-1.353 0L9 9.344v5.312l1.366-1.364a.953.953
  0 0 1 1.353 0 .967.967 0 0 1 0 1.363l-2.9 2.92a.999.999 0 0 1-1.639-.002z
`

export default props => (
  <SvgIcon {...props}>
    <path
      data-iconid="aroya-icons-targetRange"
      d={path}
      transform="scale(-1, 1) translate(-26, 0)"
    />
  </SvgIcon>
)
