import {
  compose,
  filter,
  identity,
  map,
  trim,
} from 'ramda'

import { getDateTime } from '~/src/Lib/Utils'

/**
 * Intended to ensure that numeric IDs whether from a data entity or a URL are only sent
 * to the API as a number or not at all
 * @param {number|string} val The ID to transform
 * @returns {number|undefined}
 */
export const validIdOrUndefined = val => {
  if (typeof val === 'symbol') return undefined
  return Number(val) || undefined
}
/**
 * Intended to ensure that numeric IDs whether from a data entity or a URL are only sent
 * to the API as a number or null if invalid
 * @param {number|string} val The ID to transform
 * @returns {number|null}
 */
export const validIdOrNil = val => {
  if (typeof val === 'symbol') return null
  return Math.round(Number(val)) || null
}
/**
 * If the val isn't a valid number, transform it to null
 * @param {number} val
 * @returns {number|null}
 */
export const validNumberOrNil = val => {
  if (typeof val === 'symbol') return null
  return Number(val) === val ? val : null
}
/**
 * Cast items to null if not a valid number, then filter out nulls
 * @type {function}
 * @param {number[]}
 */
export const validIdArray = compose(
  filter(identity),
  map(validIdOrNil)
)

/**
 * Returns non-empty trimmed string val or null
 * @param {string} val
 * @returns {string|null}
 */
export const nonEmptyStringOrNil = val => {
  if (typeof val === 'string') {
    const trimmed = trim(val)
    if (trimmed.length) return trimmed
  }
  return null
}

/**
 * Cleans out empty and nullish values in an array
 * @param {Array} val
 * @returns {Array}
 */
export const nonEmptyArrayOrNil = val => {
  if (Array.isArray(val) && val.length > 0) {
    const cleaned = val.filter(o => {
      const type = typeof o
      let oVal = o != null
      if (type === 'string') oVal = nonEmptyStringOrNil(o)
      if (type === 'number') oVal = validNumberOrNil(o)
      if (Array.isArray(o)) oVal = nonEmptyArrayOrNil(o)
      return oVal
    })
    if (cleaned.length) return cleaned
  }
  return null
}

/**
 * Attempts to output an ISO date string, if date is a valid date string, JS Date,
 * or Luxon DateTime. If it's not valid returns null
 * @param {string|Date|DateTime} date
 * @returns {string|null}
 */
export const toISODateOrNil = date => {
  if (date == null) return null
  const dateTime = getDateTime(date)
  if (dateTime.isValid) return dateTime.toISODate()
  return null
}
/**
 * Attempts to output an ISO date and time string, if date is a valid date string, JS Date,
 * or Luxon DateTime. If it's not valid returns null
 * @param {string|Date|DateTime} date
 * @returns {string|null}
 */
export const toISOOrNil = date => {
  const dateTime = getDateTime(date)
  if (dateTime.isValid) return dateTime.toUTC().toISO()
  return null
}

export const phoneNumberOrNil = phoneNumber => {
  const phone = trim(phoneNumber)
  return phone.length
    ? phone
      .replace(/^\s*\+?1?\s*/, '')
      .replace(/[^\d]/g, '')
      .slice(0, 10)
    : null
}
