export const SUBSTRATE_SETTINGS = 'substrateSettings'

export const SUBSTRATE_OPTIONS = [
  { value: 'GENERIC', label: 'Generic soilless' },
  { value: 'ROCKWOOL', label: 'Rockwool' },
  { value: 'COCO', label: 'Coco coir' },
  { value: 'PEAT', label: 'Peat moss' },
  { value: 'SOIL', label: 'Soil' },
]

export const SUBSTRATE_VALUES = {
  SOILLESS: 'SOILLESS',
  ROCKWOOL: 'ROCKWOOL',
  COCOCOIR: 'COCOCOIR',
  PEATMOIS: 'PEATMOIS',
  SOIL: 'SOIL'
}
