import { memo } from 'react'

import classNames from 'clsx'
import PropTypes from 'prop-types'

import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { stylePropType } from '~/src/Lib/PropTypes'

const displayName = 'CloseButton'
const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
    transform: 'scale(0.75)',
    transformOrigin: 'top right',
    pointerEvents: 'auto',
    '&$noMargin': {
      top: 0,
      right: 0,
    }
  },
  defaultColor: {
    color: theme.palette.monochrome[10],
  },
  noMargin: {},
}), { name: displayName })

/**
 * A generic close button that positions itself in the top right corner of any position: relative container
 * @alias CloseButton
 * @component
 * @named_export CloseButtonComponent
 * @example
 * <Box
 *   display="grid"
 *   gridTemplateColumns="repeat(2, 1fr)"
 *   gridTemplateRows="repeat(2, 1fr)"
 *   gap="1rem"
 *   width="100%"
 *   height="25rem"
 * >
 *   <Box border={1} borderColor="primary.main" position="relative" width="100%" height="100%">
 *     <CloseButton />
 *   </Box>
 *   <Box border={1} borderColor="primary.main" position="relative" width="100%" height="100%">
 *     <CloseButton noMargin color="primary" />
 *   </Box>
 *   <Box border={1} borderColor="currentColor" color="error.main" position="relative" width="100%" height="100%">
 *     <CloseButton noMargin color="inherit" />
 *   </Box>
 *   <Box border={1} borderColor="primary.main" position="relative" width="100%" height="100%">
 *     <CloseButton color="secondary" />
 *   </Box>
 * </Box>
 */
export const CloseButtonComponent = ({
  color = 'default',
  onClose,
  style = undefined,
  noMargin = false,
  'aria-label': ariaLabel = 'AROYA dialog close button',
  ...props
}) => {
  const classes = useStyles(props)
  return (
    <IconButton
      aria-label={ariaLabel}
      className={classNames({
        [classes.closeButton]: true,
        [classes.noMargin]: noMargin,
        [classes.defaultColor]: color === 'default'
      })}
      color={color === 'default' ? undefined : color}
      data-testid="aroya-close-button"
      onClick={onClose}
      style={style}
      size="large"
    >
      <CloseIcon color="inherit" />
    </IconButton>
  )
}
CloseButtonComponent.displayName = 'CloseButton'
CloseButtonComponent.propTypes = {
  'aria-label': PropTypes.string,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  noMargin: PropTypes.bool,
  style: stylePropType,
  onClose: PropTypes.func.isRequired,
}

export default memo(CloseButtonComponent)
