import {
  always,
  curry,
  either,
  path,
} from 'ramda'

import { createInventoryBundle } from '~/src/Inventory/bundle'
import createLogger from '~/src/Lib/Logging'
import { URL_ACTION } from '~/src/Store/constants'

import urls from './urls'

const name = 'roomInventory'
const logger = createLogger(name)

const initialBundle = createInventoryBundle(name, {
  name,
  initialState: {
    types: ['package', 'harvestBatch'],
  },
  urlTest: (_, pattern) => pattern === urls.inventory,
})

const idParam = path(['params', 'id'])
const payloadUrl = either(path(['payload', 'url']), always(''))

export default {
  ...initialBundle,
  getMiddleware: () => curry((store, next, action) => {
    const url = payloadUrl(action)
    if (action?.type === URL_ACTION && url) {
      const {
        rooms,
        routeInfo: previousRouteInfo,
        routeMatcher,
      } = store.select(['selectRooms', 'selectRouteInfo', 'selectRouteMatcher'])
      const nextRouteInfo = routeMatcher(new URL(url).pathname)
      const nextIsRoomDashboard = nextRouteInfo.pattern === urls.view
      // bail early if next URL isn't a room dashboard page
      if (!nextIsRoomDashboard) {
        next(action)
        return
      }

      const nextIdParam = idParam(nextRouteInfo)
      const idParamChanged = idParam(previousRouteInfo) !== nextIdParam
      const { [nextIdParam]: nextRoom } = rooms
      const isInventoryRoom = Boolean(nextRoom?.forPackages || nextRoom?.forHarvests)

      if (idParamChanged && isInventoryRoom) {
        store.dispatch({
          actionCreator: 'doRoomInventorySetParams',
          args: [{ rooms: Number(nextIdParam) }, { replace: true }],
        })
      }
    }

    next(action)
  })
}
