import { prop } from 'ramda'
import { createSelector } from 'redux-bundler'

import { humanize } from 'inflection'
// This bundle is used to manage different flows in the cloud
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import createEntityBundle, {
  defaultAsyncHandlersFactory,
  doEntitiesReceived,
  getAsyncActionTypes,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, parseApiErrors } from '~/src/Lib/Utils'
import { Flows as schema } from '~/src/Store/Schemas'

export const LIST_KEY = 'LIST_KEY'

const logger = createLogger('FlowEntity')

const bundle = createEntityBundle({
  name: 'flows',
  apiConfig: {
    conditional: true,
    schema,
    snackbar: config => {
      const { dispatch, action, error } = config

      if (error) {
        const errorMessage = parseApiErrors(error)
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [`${humanize(action)} failed for flow: ${errorMessage}`],
        })
      } else if (action === 'save') {
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: ['Flow saved successfully'],
        })
      }
    },
    url: 'postponedFlow',
  },
})

export const flowsFetch = getAsyncActionTypes('fetch', 'flows')
const flowsFetchHandlers = defaultAsyncHandlersFactory(flowsFetch, { action: 'fetch' })

export default {
  ...bundle,
  reducer: reduceReducers(bundle.reducer, (state, action) => {
    if (action.type in flowsFetchHandlers) {
      const { [action.type]: handler } = flowsFetchHandlers
      return handler(state, action)
    }
    return state
  }),
  doFlowsFetch: params => async ({ dispatch, apiFetch }) => {
    dispatch({ type: flowsFetch.start, payload: LIST_KEY })
    try {
      const result = await apiFetch('/postponedFlow/', params, { method: 'GET' })
      const { entities } = normalize(result, [schema])
      const { flows = EMPTY_OBJECT, ...rest } = entities
      dispatch(doEntitiesReceived({ ...rest, flows }, { fullReplace: true }))
      dispatch({ type: flowsFetch.succeed, payload: LIST_KEY })
    } catch (error) {
      if (error?.error?.status) {
        const { error: { status } } = error
        if (status === 404) {
          return null
        }
      }

      dispatch({ type: flowsFetch.fail, error, payload: LIST_KEY })
      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to get flow from the cloud: ${parseApiErrors(error)}`]
      })
      throw error
    }

    return null
  },
  selectFlowsFetchInflight: createSelector(
    'selectFlowsInflight',
    prop(LIST_KEY),
  ),
}
