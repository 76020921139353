import {
  always,
  either,
  equals,
  map,
  pick,
  pipe,
  uniqBy,
} from 'ramda'

import {
  getItemsWithColors,
  getLicenseItemIdentifier,
  getSortedItemCategories,
  groupItemsByCategory,
  sortSourcesByLabel,
} from '~/src/Flow/bundle/utils'
import { HARVESTING_FLOW } from '~/src/Flow/constants'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'

export const isHarvestingUrl = routeInfo => routeInfo?.pattern && routeInfo.pattern === `/${HARVESTING_FLOW}/:id`

export const getPlantsReadyForHarvesting = (plants, cultivars) => plants?.filter(plant => plant.state === 'TRACKED')
  .map(plant => {
    const { [plant.cultivar]: relatedCultivar } = cultivars
    return {
      ...plant,
      cultivarName: relatedCultivar?.name || 'No harvest cultivar name',
    }
  }) ?? EMPTY_ARRAY

export const formLatestHarvestingData = props => {
  const {
    facilityCultivars,
    harvest,
    isMetrcCompliant,
    items,
    itemCategories,
    plants,
    units,
  } = props

  const sources = isMetrcCompliant ? getPlantsReadyForHarvesting(plants, facilityCultivars) : harvest.cultivars
  if (isMetrcCompliant) {
    sources.sort(sortSourcesByLabel)
  }

  const sortedItemCategories = getSortedItemCategories(itemCategories, isMetrcCompliant ? ['WET', 'PROCESSED'] : ['WET'])
  const itemsArrWithColors = getItemsWithColors(items, itemCategories, ['WET'], isMetrcCompliant ? ['Fresh Frozen'] : [], units)
  const itemsByCategory = groupItemsByCategory(itemsArrWithColors)

  // we need to preset all wet category items rooms to the dry room of the harvest obj
  const wetCategoryItems = itemsArrWithColors.filter(item => item.category === 'WET')
  const initialProcessedRooms = wetCategoryItems.reduce((roomsPerItem, item) => ({ ...roomsPerItem, [item.id]: harvest.dryRoom }), {})

  return {
    sources,
    harvestId: harvest.id,
    ...(isMetrcCompliant ? {
      lastSelectedItem: itemsByCategory.WET?.[0], // default requirement
      sortedItemCategories,
      itemsByCategory,
      processedRooms: initialProcessedRooms,
    } : {})
  }
}

export const getMetrcQueuedSources = queue => queue.reduce((qs, { sources: itemSources }) => {
  itemSources.forEach(({ label }) => {
    qs[label] ??= 0
    qs[label] += 1
  })
  return qs
}, {})
export const getGenericQueuedSources = (queue, settings) => {
  if (settings.weighingIndividually) {
    return queue.reduce((qs, queued) => {
      qs[queued.cultivar] ??= 0
      qs[queued.cultivar] += 1
      if (!queued.uid && !queued.id) return qs
      qs[queued.uid || queued.id] ??= 0
      qs[queued.uid || queued.id] += 1
      return qs
    }, {})
  }
  return queue.reduce((qs, queued) => {
    qs[queued.cultivar] ??= 0
    qs[queued.cultivar] += 1
    return qs
  }, {})
}
export const getSourcesAvailableForHarvesting = (apiSources, queuedSources) => (Object.keys(queuedSources).length
  ? apiSources.filter(source => (!(source.label in queuedSources)))
  : apiSources)

export const getNewQueue = ({
  addedAt = new Date().toISOString(),
  modifiedAt = addedAt,
  weight: rawWeight,
  version = 0,
  ...entryToAdd
}, queue) => {
  let weight = rawWeight
  if (typeof weight !== 'number' || Number.isNaN(weight)) {
    if (typeof weight === 'string') {
      weight = Number(weight)
    }
    if (Number.isNaN(weight)) {
      weight = 0
    }
  }
  const indexInQueue = queue.findIndex(elem => elem.id === entryToAdd.id)

  const entry = {
    ...entryToAdd,
    addedAt,
    modifiedAt,
    version: version + 1,
    isInQueue: true,
    weight
  }
  // if we've found an index - we need to update an exisitng item in the queue. Otherwise, we need to add a new item at the beginning of the queue
  if (indexInQueue > -1) {
    const newQueue = [...queue]
    entry.modifiedAt = new Date().toISOString()
    newQueue[indexInQueue] = entry
    return newQueue
  }
  return [entry, ...queue]
}

export const getHarvestedCultivars = (queue = []) => queue.reduce((cultivars, queued) => {
  const { harvestCultivarId, cultivarName } = queued
  if (!cultivars.find(cultivar => cultivar.id === harvestCultivarId)) {
    return [...cultivars, { id: harvestCultivarId, name: cultivarName }]
  }

  return cultivars
}, [])

// since split harvest is not a thing for non-metrc harvesting - we return an empty array to not pass the check
export const getRemainingCultivars = (plants = [], harvestedCultivars = [], isMetrcCompliant = true) => (
  isMetrcCompliant
    ? plants
      .reduce((cultivars, plant) => {
        const { harvestCultivar, cultivarName } = plant
        if (!cultivars.find(cultivar => cultivar.id === harvestCultivar)) {
          return [...cultivars, { id: harvestCultivar, name: cultivarName }]
        }

        return cultivars
      }, [])
      .filter(cultivar => !harvestedCultivars.find(harvestedCultivar => harvestedCultivar.id === cultivar.id))
    : [])

const plantCultivarFields = ['cultivar', 'cultivarName', 'harvestCultivar']
const pickPlantCultivarFields = pick(plantCultivarFields)
const queuedCultivarFields = ['cultivarName', 'harvestCultivarId']
export const getUpdatedQueue = (queue, sources) => {
  let queueUpdated = false
  const changed = []

  const updatedQueue = queue.map(queued => {
    const queuedSources = queued.sources.map(plant => {
      const source = sources.find(p => p.uuid === plant.uuid)
      if (source && plantCultivarFields.some(key => source[key] !== plant[key])) {
        queueUpdated = true
        changed.push({ previous: plant, current: { ...plant, ...pickPlantCultivarFields(source) } })
        return { ...plant, ...pickPlantCultivarFields(source) }
      }
      return plant
    })
    const { 0: firstSource } = queuedSources
    let license = queued.license
    if (!license) {
      license = firstSource.license
      queueUpdated = true
    }
    const nextQueued = {
      ...queued,
      cultivarName: firstSource.cultivarName,
      harvestCultivarId: firstSource.harvestCultivar,
      license,
      sources: queuedSources
    }

    if (queuedCultivarFields.some(key => queued[key] !== nextQueued[key])) {
      queueUpdated = true
      changed.push(nextQueued)
    }
    return nextQueued
  })

  return [updatedQueue, queueUpdated]
}

export const getSelectedItemsPayload = map(pick(['item', 'license']))
export const getSelectedItems = either(pipe(getSelectedItemsPayload, uniqBy(getLicenseItemIdentifier)), always(EMPTY_ARRAY))

const RECONCILERS = {
  GENERIC: (state, flow) => {
    const {
      processedRooms: flowProcessedRooms = EMPTY_OBJECT,
    } = ('data' in flow ? flow.data : flow)
    const {
      processedRooms: localProcessedRooms = null,
      queue,
      settings,
    } = state

    return {
      ...state,
      processedRooms: localProcessedRooms ?? flowProcessedRooms,
      queuedSources: getGenericQueuedSources(queue, settings),
    }
  },
  METRC: (state, flow) => {
    const {
      processedRooms: flowProcessedRooms = EMPTY_OBJECT,
    } = ('data' in flow ? flow.data : flow)
    const {
      processedRooms: localProcessedRooms = EMPTY_OBJECT,
      queue,
    } = state

    return {
      ...state,
      processedRooms: {
        ...localProcessedRooms,
        ...flowProcessedRooms
      },
      queuedSources: getMetrcQueuedSources(queue),
    }
  },
  SHARED: (state, flow) => {
    const {
      dequeued: flowDequeued = EMPTY_ARRAY,
      queue: flowQueue = EMPTY_ARRAY,
    } = ('data' in flow ? flow.data : flow)
    const {
      dequeued: localDequeued = EMPTY_ARRAY,
      queue: localQueue = EMPTY_ARRAY,
    } = state

    // merge the current flow with the current harvesting state
    const dequeuedSet = new Set([...flowDequeued, ...localDequeued])
    const [localFilteredQueue, lfqIDs] = localQueue.reduce((lfq, item) => {
      const { id } = item
      if (dequeuedSet.has(id)) return lfq
      const [queue, ids] = lfq
      queue.push(item)
      ids.add(id)
      return lfq
    }, [[], new Set()])
    const dequeued = [...dequeuedSet]
    const queue = [
      ...localFilteredQueue,
      ...flowQueue.filter(({ id }) => !lfqIDs.has(id) && !dequeuedSet.has(id))
    ]

    return { ...state, dequeued, queue }
  },
}

export const reconcileStateAndFlow = (state, flow, which) => (
  [RECONCILERS.SHARED, RECONCILERS[which]].reduce((nextState, reconciler) => reconciler(nextState, flow), state)
)

const textSorter = (a, b) => a.localeCompare(b)
export const isOutOfSync = (state, flow) => {
  const { dequeued: stateDequeued, queue: stateQueue } = state
  if (!flow.data) return true
  const { dequeued: flowDequeued, queue: flowQueue } = flow.data

  const dequeuedDiff = !flowDequeued
    || stateDequeued.length !== flowDequeued.length
    || !equals(stateDequeued.slice().sort(textSorter), flowDequeued.slice().sort(textSorter))

  if (dequeuedDiff) {
    return dequeuedDiff
  }
  const queueDiffLength = stateQueue.length !== flowQueue.length
  if (queueDiffLength) {
    return queueDiffLength
  }

  const fqSet = new Set(flowQueue.map(({ id }) => id))
  const stateQueueHasNewItems = stateQueue.some(({ id }) => !fqSet.has(id))

  return stateQueueHasNewItems
}
