import PropTypes from 'prop-types'
import {
  compose,
  evolve,
  pick,
  trim,
} from 'ramda'

import { validIdOrNil } from '~/src/Lib/Data'

export const cleanTotalSpace = (totalSpace, shouldRound) => {
  if (shouldRound) {
    // Previous logic, rounding number
    const space = totalSpace.toString().replace(/([^\d]+)/g, '')
    return space.length
      ? Number(space)
        .toString()
        .slice(0, 6)
      : ''
  }
  // Max 2 decimals, trim leading zeros (allow for lone decimal while typing, e.g. '10.')
  let space = totalSpace.toString().match(/\d+\.?\d{0,2}/)?.[0] ?? ''
  space = space.replace(/^(0+)(\d)/, '$2')
  return Number(space) > 99999 ? space.slice(0, 6) : space
}

const zoneShapeConfig = {
  name: PropTypes.string,
  totalSpace: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sequence: PropTypes.number,
  dataRooms: PropTypes.arrayOf(PropTypes.number),
}
export const zoneShape = PropTypes.shape(zoneShapeConfig)

const zoneTransforms = {
  name: trim,
  totalSpace: cleanTotalSpace,
  sequence: Number,
}
const zoneTransformer = evolve(zoneTransforms)
export const zoneCleaner = compose(
  zoneTransformer,
  pick(Object.keys(zoneShapeConfig))
)

const shapeConfig = {
  id: PropTypes.number,
  archived: PropTypes.bool,
  areaUnit: PropTypes.number,
  facility: PropTypes.number,
  forPlantBatches: PropTypes.bool,
  forPlants: PropTypes.bool,
  forHarvests: PropTypes.bool,
  forPackages: PropTypes.bool,
  metrcLocations: PropTypes.arrayOf(PropTypes.number),
  name: PropTypes.string,
  outdoor: PropTypes.bool,
  roomType: PropTypes.string,
  sequence: PropTypes.number,
  substrateType: PropTypes.string,
  totalSpace: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zones: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, zoneShape])),
  // Default irrigation settings
  substrateConfig: PropTypes.string,
  substrateName: PropTypes.string,
  blockCapacity: PropTypes.number,
  slabCapacity: PropTypes.number,
  capacityUnit: PropTypes.number,
  blocksPerSlab: PropTypes.number,
  drippersPerPlant: PropTypes.number,
  flowRate: PropTypes.number,
  flowUnit: PropTypes.number,
  irrigationMode: PropTypes.string,
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  facility: validIdOrNil,
  totalSpace: cleanTotalSpace,
  name: trim,
  sequence: Number,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const cleanData = cleaner(raw)
  if ('zones' in cleanData && Array.isArray(cleanData.zones)) {
    cleanData.zones = cleanData.zones.map(({ id, ...zone }) => {
      if (Number.isNaN(Number(id))) {
        return zone
      }
      return { id, ...zone }
    })
  }
  return cleanData
}
