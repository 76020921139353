import { SvgIcon } from '@mui/material'

export const path = `
  M15,13 L15,5 C15,3.34 13.66,2 12,2 C10.34,2 9,3.34 9,5
  L9,13 C7.79,13.91 7,15.37 7,17 C7,19.76 9.24,22 12,22
  C14.76,22 17,19.76 17,17 C17,15.37 16.21,13.91 15,13 Z
  M13,11 L11,11 L11,5 C11,4.45 11.45,4 12,4 C12.55,4 13,4.45 13,5
  L12.5,5 C12.22,5 12,5.22 12,5.5 C12,5.78 12.22,6 12.5,6
  L13,6 L13,8 L12.5,8 C12.22,8 12,8.22 12,8.5
  C12,8.78 12.22,9 12.5,9 L13,9 L13,11 Z
`
export default props => (
  <SvgIcon {...props}>
    <path data-iconid="aroya-icons-measurement" d={path} />
  </SvgIcon>
)
