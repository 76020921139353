import { prop } from 'ramda'

import getGlobal from '~/src/Lib/getGlobal'
import safeStorage from '~/src/Lib/safeStorage'

export const getId = prop('id')

let uniqueIdCounter = 0
export const uniqueId = prefix => {
  uniqueIdCounter += 1
  return String(prefix) + uniqueIdCounter
}

/**
 * Returns random id
 * @param {number} length
 * @returns {string}
 */
export const randomId = (length = 10) => [...(
  typeof crypto !== 'undefined'
    ? crypto.getRandomValues(new Uint8Array(length))
    : Array.from({ length }, () => Math.ceil(Math.random() * 255))
)]
  .map(i => i.toString(36))
  .join('')
if (getGlobal()) getGlobal().randomId = randomId
/**
 * Returns client id from local storage or set it there if doesn't exist
 * @returns {string}
 */
export const getClientId = () => {
  const sessionStorage = safeStorage('session')
  let clientId = sessionStorage.getItem('aroyaClientId')
  if (!clientId) {
    clientId = randomId()
    sessionStorage.setItem('aroyaClientId', clientId)
  }
  return clientId
}
