import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialActiveSchedulesBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationActiveSchedules',
  actionBaseType: 'FACILITY_IRRIGATION_ACTIVE_SCHEDULES',
  getPromise: ({ apiFetch, dispatch }) => apiFetch('/irrigationSchedules/', {
    pagination: 0,
    status: 'ACTIVE',
  }).then(response => {
    const entities = {
      irrigationSchedules: response.reduce((schedules, schedule) => ({
        ...schedules,
        [schedule.id]: schedule,
      }), {})
    }
    dispatch(doEntitiesReceived(entities))
    return response
  })
})

export default {
  ...initialActiveSchedulesBundle,
  selectFacilityIrrigationActiveSchedulesByProgramId: createSelector(
    'selectFacilityIrrigationActiveSchedules',
    activeSchedules => {
      if (!activeSchedules) return {}
      return activeSchedules.reduce((asbp, schedule) => {
        schedule.programs.forEach(({ id }) => {
          asbp[id] = schedule
        })
        return asbp
      }, {})
    },
  ),
  reactFetchFacilityIrrigationActiveSchedules: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationActiveSchedulesShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { view }, { pattern }) => {
      if (shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationActiveSchedules' }
      }
      return null
    },
  }),
}
