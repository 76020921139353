import { useCallback, useState } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'

import Dialog from '~/src/Dialog'
import Button from '~/src/UI/Shared/Button'

/**
 * A simple confirmation dialog that has cancel and confirm action buttons
 * @component
 * @composes
 * name: Dialog
 * from: ~/src/Dialog
 * @example
 * import Button from '~/src/UI/Shared/Button'
 * {() => {
 *   const [openDialog, setOpenDialog] = React.useState(null)
 *   const handleClick = dialogName => () => setOpenDialog(old => (old === dialogName ? null : dialogName))
 *   const handlePromiseClick = dialogName => () => {
 *     const promise = new Promise(resolve => {
 *       setTimeout(resolve, 2500)
 *     })
 *     const onResolve = handleClick(dialogName)
 *     return promise.finally(onResolve)
 *   }
 *   return (
 *     <div className="default-display">
 *       <Button danger onClick={handleClick('deleteIt')}>Delete it</Button>
 *       <ConfirmationDialog
 *         open={openDialog === 'deleteIt'}
 *         title="Delete it?"
 *         onConfirm={handleClick('deleteIt')}
 *         onCancel={handleClick('deleteIt')}
 *       />
 *       <Button onClick={handleClick('keepIt')}>Keep it</Button>
 *       <ConfirmationDialog
 *         cancelText="No, delete it."
 *         confirmText="Yes, keep it."
 *         destructive={false}
 *         open={openDialog === 'keepIt'}
 *         title="Keep it?"
 *         onConfirm={handleClick('keepIt')}
 *         onCancel={handleClick('keepIt')}
 *       >
 *         <T>Here's why you should keep it: it's a keeper!</T>
 *       </ConfirmationDialog>
 *       <Button onClick={handleClick('keepIt')}>Wait for it</Button>
 *       <ConfirmationDialog
 *         open={openDialog === 'waitForIt'}
 *         title="Wait for it?"
 *         onConfirm={handlePromiseClick('waitForIt')}
 *         onCancel={handleClick('waitForIt')}
 *       />
 *     </div>
 *   )
 * }}
 */
const ConfirmationDialog = ({
  dataTestId = 'aroya-confirmation-dialog',
  destructive = true,
  noCancel = false,
  open,
  title,
  cancelText = i18n`No, keep it.`,
  confirmText = i18n`Yes, delete it.`,
  onCancel = () => null,
  onConfirm,
  actions = null,
  ...passthru
}) => {
  const [disable, setDisable] = useState(false)
  const handleConfirm = useCallback(event => {
    if (event?.persist?.call) {
      event.persist()
    }
    const result = onConfirm(event)
    if (result && typeof result.finally === 'function') {
      setDisable(true)
      return result.finally(() => {
        setDisable(false)
      })
    }
    return result
  }, [onConfirm])

  return (
    <Dialog
      data-testid={dataTestId}
      open={open}
      onClose={onCancel}
      maxWidth="md"
      {...passthru}
      title={title}
      actions={actions || (
        <>
          {noCancel ? null : (
            <Button
              danger={!destructive}
              data-testid="confirm-dialog-no"
              variant="text"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
          )}
          <Button
            danger={destructive}
            data-testid="confirm-dialog-yes"
            disabled={disable}
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
        </>
      )}
    />
  )
}

ConfirmationDialog.propTypes = {
  dataTestId: PropTypes.string,
  destructive: PropTypes.bool,
  noCancel: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  cancelText: PropTypes.node,
  confirmText: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  actions: PropTypes.node,
}

export default ConfirmationDialog
