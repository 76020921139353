import PropTypes from 'prop-types'
import {
  always,
  both,
  compose,
  either,
  evolve,
  identity,
  is,
  isEmpty,
  omit,
  pick,
  pipe,
  trim,
} from 'ramda'

import { phoneNumberOrNil, validIdOrNil } from '~/src/Lib/Data'

const userShapeConfig = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  landline: PropTypes.string,
  languagePref: PropTypes.string,
}
export const userShape = PropTypes.shape(userShapeConfig)

const ensureStringAndTrim = pipe(either(identity, always('')), trim)
const userTransforms = {
  firstName: ensureStringAndTrim,
  lastName: ensureStringAndTrim,
  email: ensureStringAndTrim,
  phoneNumber: phoneNumberOrNil,
}
const userTransformer = evolve(userTransforms)
export const userCleaner = compose(
  userTransformer,
  pick(Object.keys(userShapeConfig))
)

const shapeConfig = {
  id: PropTypes.number,
  role: PropTypes.number,
  user: PropTypes.oneOfType([PropTypes.number, userShape]),
  notificationPreferences: PropTypes.array,
  photo: PropTypes.object,
}
export const shape = PropTypes.shape(shapeConfig)

const transforms = {
  id: Number,
  photo: either(identity, always(undefined)),
  role: validIdOrNil,
  user: both(is(Object), userCleaner),
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const cleanData = cleaner(raw)
  const userData = typeof cleanData.user === 'object' ? cleanData.user : userCleaner(raw)
  if (isEmpty(userData)) {
    return omit(['user'], cleanData)
  }
  // TODO: International fix pending, handle in backend
  userData.phoneNumber = (userData.phoneNumber ?? '')
  if (userData.phoneNumber && !userData.phoneNumber.startsWith('+1')) {
    userData.phoneNumber = `+1${userData.phoneNumber}`
  }
  userData.landline = (userData.landline ?? '')
  if (userData.landline && !userData.landline.startsWith('+1')) {
    userData.landline = `+1${userData.landline}`
  }

  return { ...cleanData, user: userData }
}
