import { createSelector } from 'redux-bundler'

import {
  CLIMATE_PREFERRED_DATATYPES,
  SUBSTRATE_PREFERRED_DATATYPES,
} from '~/src/DataType/constants'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'

const logger = createLogger('chart/bundle')

const preferredDatatypes = {
  SUBSTRATE: SUBSTRATE_PREFERRED_DATATYPES,
  CLIMATE: CLIMATE_PREFERRED_DATATYPES,
}
export const getDefaultDataTypes = (roomTypes, chartTypes) => {
  const DATATYPE_ORDER = ['SUBSTRATE', 'CLIMATE']
  const firstDataTypeArray = preferredDatatypes[DATATYPE_ORDER[0]]
  const secondDataTypeArray = preferredDatatypes[DATATYPE_ORDER[1]]

  const availableTypes = (chartTypes?.length && chartTypes) ?? (roomTypes?.length && roomTypes)
  if (!availableTypes) return firstDataTypeArray

  let defaultTypes = availableTypes ?? EMPTY_ARRAY
  if (firstDataTypeArray.some(type => availableTypes.includes(type))) {
    defaultTypes = firstDataTypeArray.filter(type => availableTypes.includes(type))
  } else if (secondDataTypeArray.some(type => availableTypes.includes(type))) {
    defaultTypes = secondDataTypeArray.filter(type => availableTypes.includes(type))
  }
  return defaultTypes.length > 0 ? defaultTypes.slice(0, 2) : EMPTY_ARRAY
}

export default {
  name: 'roomChart',
  selectRoomSelectedDataTypes: createSelector(
    'selectRoomChart',
    'selectRoomDefaultDataTypes',
    ({ data }, roomDataTypes) => {
      const { dataTypes } = data
      const defaultDataTypes = getDefaultDataTypes(roomDataTypes, dataTypes)
      return defaultDataTypes
    }
  )
}
