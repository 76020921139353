import memoizeOne from 'memoize-one'
import { pipe, prop, props as pluckAll } from 'ramda'

import { shallowEquals } from '~/src/Lib/Utils'

export const CHART_PADDING = {
  top: 16,
  right: 20,
  bottom: 40,
  left: 0,
}
const breakpointPadding = {
  xs: {
    top: 8,
    right: 0
  },
}
const paddingPlucker = pipe(prop(0), pluckAll(['xOffset', 'width', 'breakpoint']))

export const getPaddingForChart = memoizeOne((props, paddingLookup = breakpointPadding) => {
  const { xOffset: offsetRight } = props
  const { right: defaultRight } = CHART_PADDING

  return {
    ...CHART_PADDING,
    right: offsetRight + defaultRight,
    ...paddingLookup[props.width || props.breakpoint]
  }
}, (left, right) => (
  shallowEquals(paddingPlucker(left), paddingPlucker(right))
    && shallowEquals(left[1], right[1])
))
