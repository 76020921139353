import {
  DRYING_FLOW_ROUTE,
  HARVESTING_FLOW_ROUTE,
  LOT_TO_PLANT_FLOW_ROUTE,
  VEG_TO_FLOWER_FLOW_ROUTE,
} from '~/src/Flow/constants'
import { getUrlDefaults } from '~/src/Lib/Nav'
import { FACILITY_ROUTE_PREFIX } from '~/src/Routes/constants'

const urls = getUrlDefaults('harvests')

const additionalPaths = ['analytics', 'basics', 'compare', 'cultivars', 'growlog', 'inventory', 'schedule', 'yield']
const allUrls = {
  ...urls,
  ...additionalPaths.reduce((paths, action) => ({
    ...paths,
    [action]: urls.getUrl({ action, id: ':id' })
  }), {}),
  drying: DRYING_FLOW_ROUTE,
  harvesting: HARVESTING_FLOW_ROUTE,
  veg2flower: VEG_TO_FLOWER_FLOW_ROUTE,
  lot2plant: LOT_TO_PLANT_FLOW_ROUTE,
}

export const isGrowlogRoute = ({ pattern }) => pattern.replace(FACILITY_ROUTE_PREFIX, '').startsWith(allUrls.growlog)

export default allUrls
