import {
  allPass,
  anyPass,
  curry,
  findLastIndex,
  path,
  pathSatisfies,
  prop,
  propSatisfies,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { seconds } from 'milliseconds'

import { available } from '~/src/Flags/Has'
import { HARVESTING_FLOW } from '~/src/Flow/constants'
import { getQueueGroupId } from '~/src/Flow/Queue/Harvesting/Compliant/utils'
import { EPOCH_DATETIME } from '~/src/Lib/Constants'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { createAppIsReadySelector } from '~/src/Store/utils'

import actions, {
  HARVESTING_CLEAR_STATE,
  HARVESTING_FLOW_SAVE_FAIL,
  HARVESTING_FLOW_SAVE_RETRY,
  HARVESTING_FLOW_SAVE_START,
  HARVESTING_FLOW_SAVE_SUCCEED,
  HARVESTING_INITIALIZE_STATE,
  HARVESTING_RECONCILED_FLOW,
  HARVESTING_RECONCILING_FLOW,
  HARVESTING_RECONCILING_FLOW_RESET,
  HARVESTING_RESET_STATE,
} from './actions'
import allReducers, { initialFlowStateMap, initState } from './reducers'
import { flowDataPicker } from './reducers/utils'
import { isHarvestingUrl, isOutOfSync } from './utils'

export * from './actions'

export const DEFAULT_UNSAVED_STATE = Object.freeze({
  saveDelay: 0,
  showOfflineWarning: false,
  unsavedCount: 0,
})
const EMPTY_FLOW = { modifiedOn: EPOCH_DATETIME }
const OFFLINE_WARNING = {
  INITIAL: { countThreshold: 5, delayThreshold: 90 },
  WARNED: { countThreshold: 3, delayThreshold: 60 },
}
const WAIT_THRESHOLD = 20

const HIDE_UNSAVED_HARVESTING_LEAVE_DIALOG = 'HIDE_UNSAVED_HARVESTING_LEAVE_DIALOG'
const SHOW_UNSAVED_HARVESTING_LEAVE_DIALOG = 'SHOW_UNSAVED_HARVESTING_LEAVE_DIALOG'

const actionPrefix = `${HARVESTING_FLOW.toUpperCase()}_`
const middlewareFeatures = ['METRC']

const logger = createLogger('Harvesting/bundle')
const getErrorStatus = path(['lastError', 'status'])

const readyToSave = allPass([
  propSatisfies(Boolean, 'harvestId'),
  propSatisfies(ts => ts > EPOCH_DATETIME, 'stateModified'),
  anyPass([
    pathSatisfies(Boolean, ['queue', 'length']),
    pathSatisfies(Boolean, ['dequeued', 'length']),
  ]),
])

const shouldWaitFor = ts => ts && ts > Date.now() - seconds(WAIT_THRESHOLD)

export default {
  name: HARVESTING_FLOW,
  getMiddleware: () => curry((store, next, action) => {
    if (!action?.type || !action.type.startsWith(actionPrefix)) {
      return next(action)
    }
    const settings = store.selectHarvestingFlowSettings()
    const meta = { ...action.meta, settings }

    const routeInfo = store.selectDialogRouteInfo()
    if (!meta.harvest && isHarvestingUrl(routeInfo)) {
      meta.harvest = Number(routeInfo.params.id)
    }
    // on any dispatched action within the harvesting flow, we check the METRC flag to decide which reducer will handle the action
    const isMetrc = available(store.selectAvailableFeatures(), middlewareFeatures)
    meta.which = isMetrc ? 'METRC' : 'GENERIC'
    return next({ ...action, meta })
  }),
  reducer: (state = initState, action = EMPTY_OBJECT) => {
    const { meta: { which, harvest } = EMPTY_OBJECT, payload, type } = action

    if (type === HIDE_UNSAVED_HARVESTING_LEAVE_DIALOG) {
      return { ...state, showUnsavedHarvestingLeaveDialog: false }
    }
    if (type === SHOW_UNSAVED_HARVESTING_LEAVE_DIALOG) {
      return { ...state, showUnsavedHarvestingLeaveDialog: true }
    }

    const initFlowState = initialFlowStateMap[which]
    const init = initFlowState ? initFlowState(harvest) : {}

    if (type === HARVESTING_INITIALIZE_STATE) {
      logger.debug('[reducer] initializing state', action)
      return {
        ...state,
        flows: {
          ...state.flows,
          [payload]: init
        }
      }
    }

    if (type === HARVESTING_CLEAR_STATE) {
      logger.debug('[reducer] clearing state', action)
      const { [payload]: _, ...remainingFlows } = state.flows
      return {
        ...state,
        flows: remainingFlows
      }
    }

    // here is where we branch between METRC and GENERIC reducers
    const { [which]: reducers } = allReducers
    if (reducers && (type in reducers || type in allReducers.SHARED)) {
      // If the type is not found in METRC/GENERIC reducers, fallback to SHARED reducers
      const { [type]: reducer = allReducers.SHARED[type] } = reducers
      const {
        [harvest]: harvestingState = init
      } = state.flows
      const nextHarvestingState = reducer(harvestingState, action)
      if (type === HARVESTING_RESET_STATE) {
        logger.debug('[reducer] resetting state', {
          harvestingState: nextHarvestingState,
          action
        })
      }
      return {
        ...state,
        flows: {
          ...state.flows,
          [harvest]: Object.keys(init).some(key => !(key in nextHarvestingState))
            ? { ...init, ...nextHarvestingState }
            : nextHarvestingState
        }
      }
    }
    return state
  },
  doHarvestingFlowSave: harvestIdParam => async ({ dispatch, store }) => {
    const harvestId = harvestIdParam ?? store.selectHarvestingCurrentHarvestId()
    const meta = { harvest: harvestId }
    dispatch({ type: HARVESTING_FLOW_SAVE_START, meta })
    const { [harvestId]: harvestingState = EMPTY_OBJECT } = store.selectHarvestingStates()
    const { id } = harvestingState
    if (id) {
      await store.doReconcileFlowAndHarvestingState(harvestId, id)
    }
    const flowData = {
      flowType: HARVESTING_FLOW,
      ...(id ? { id } : EMPTY_OBJECT),
      harvest: harvestId,
      data: {
        ...flowDataPicker(store.selectHarvestingState()),
        settings: store.selectHarvestingFlowSettings()
      }
    }
    const response = await store.doFlowSave(flowData)
    logger.debug('[doHarvestingFlowSave] response', response, flowData)
    if (response && response.id) {
      dispatch({ type: HARVESTING_FLOW_SAVE_SUCCEED, payload: response, meta })
      return true
    }
    const { [flowData.id]: dirty } = store.selectFlowsDirty()
    if (getErrorStatus(dirty) === 412) {
      logger.debug('[doHarvestingFlowSave] local flow is stale, retrying')
      dispatch({ type: HARVESTING_FLOW_SAVE_RETRY, meta })
      return null
    }
    dispatch({
      type: HARVESTING_FLOW_SAVE_FAIL,
      payload: response,
      error: dirty.lastError,
      meta,
    })
    logger.debug('[doHarvestingFlowSave] got an unresolvable error', { error: dirty.lastError, response })
    return false
  },
  doReconcileFlowAndHarvestingState: (harvestId, flowId, outerMeta = EMPTY_OBJECT) => async ({ store, dispatch }) => {
    const { flows, harvestingStateRoot } = store.select(['selectFlows', 'selectHarvestingStateRoot'])
    const { [harvestId]: previousHarvestingState } = harvestingStateRoot.flows ?? EMPTY_OBJECT
    const { [flowId]: previousFlow } = flows
    const meta = { harvest: harvestId }
    if (!previousFlow) {
      logger.debug('[doReconcileFlowAndHarvestingState] no flow found', { flowId, flows })
      return true
    }
    dispatch({ type: HARVESTING_RECONCILING_FLOW, meta })
    if (outerMeta.noFetch !== true) {
      await store.doFlowFetch(flowId)
    }
    const { [flowId]: currentFlow } = store.selectFlows()
    if (previousFlow.modifiedOn === currentFlow.modifiedOn) {
      // If the last modified time is the same, we can assume that the flow has not changed
      logger.debug('[doReconcileFlowAndHarvestingState] flow has not changed', {
        currentFlow,
        previousFlow
      })
      dispatch({ type: HARVESTING_RECONCILING_FLOW_RESET, meta })
      return true
    }

    if (!currentFlow || !previousHarvestingState) {
      // if we don't have a flow or a previous harvesting state, there is nothing to reconcile
      logger.debug('[doReconcileFlowAndHarvestingState] no flow found or missing harvesting state', {
        currentFlow,
        previousHarvestingState
      })
      dispatch({ type: HARVESTING_RECONCILING_FLOW_RESET, meta })
      return true
    }

    // dispatch the merged harvesting state
    dispatch({ type: HARVESTING_RECONCILED_FLOW, payload: currentFlow, meta })
    return true
  },
  doHideUnsavedHarvestingLeaveDialog: () => ({ type: HIDE_UNSAVED_HARVESTING_LEAVE_DIALOG }),
  doShowUnsavedHarvestingLeaveDialog: () => ({ type: SHOW_UNSAVED_HARVESTING_LEAVE_DIALOG }),
  selectHarvestingStateRoot: state => state.harvesting ?? EMPTY_OBJECT,
  selectHarvestingCurrentHarvestId: createSelector(
    'selectDialogRouteInfo',
    routeInfo => {
      if (isHarvestingUrl(routeInfo)) {
        const { params } = routeInfo
        return params?.id ?? null
      }
      return null
    },
  ),
  selectHarvestingStates: createSelector(
    'selectHarvestingStateRoot',
    'selectHarvestingUnsavedStates',
    ({ flows = EMPTY_OBJECT }, unsavedStates = EMPTY_OBJECT) => new Proxy({}, {
      get: (cache, harvestId) => {
        if (!(harvestId in flows)) return EMPTY_OBJECT
        const { [harvestId]: harvestingState } = flows
        const { [harvestId]: unsavedState = DEFAULT_UNSAVED_STATE } = unsavedStates
        cache[harvestId] ??= { ...harvestingState, ...unsavedState }
        return cache[harvestId]
      },
      has: (_, harvestId) => harvestId in flows,
      ownKeys: () => Object.keys(flows),
    }),
  ),
  selectHarvestingState: createSelector(
    'selectHarvestingCurrentHarvestId',
    'selectHarvestingStates',
    'selectAppTime', // forces recompute on every app-time tick
    (currentHarvestId, harvestingStates) => harvestingStates[currentHarvestId] ?? EMPTY_OBJECT,
  ),
  selectHarvestingUnsavedStates: createSelector('selectHarvestingStateRoot', ({ flows = EMPTY_OBJECT }) => {
    if (flows === EMPTY_OBJECT) return EMPTY_OBJECT
    return Object.values(flows).reduce((unsaved, harvestingState) => {
      const { harvestId, offlineWarningAcknowledged, queue, savedAt } = harvestingState
      const firstUnsavedIndex = findLastIndex(({ modifiedAt }) => modifiedAt >= savedAt, queue)
      const defaultReturn = { ...DEFAULT_UNSAVED_STATE }
      if (firstUnsavedIndex === -1) {
        unsaved[harvestId] = defaultReturn
        return unsaved
      }

      const {
        [offlineWarningAcknowledged ? 'WARNED' : 'INITIAL']: {
          countThreshold,
          delayThreshold
        }
      } = OFFLINE_WARNING
      const unsavedCount = firstUnsavedIndex + 1
      if (unsavedCount) {
        defaultReturn.unsavedCount = unsavedCount
      }
      const { [firstUnsavedIndex]: firstUnsaved } = queue
      const saveDelay = Math.round(
        getDateTime('now')
          .diff(getDateTime(firstUnsaved.modifiedAt))
          .as('seconds')
        || 0
      )
      if (saveDelay) {
        defaultReturn.saveDelay = saveDelay
      }
      if (unsavedCount >= countThreshold || saveDelay >= delayThreshold) {
        defaultReturn.showOfflineWarning = true
      }
      unsaved[harvestId] = defaultReturn

      return unsaved
    }, {})
  }),
  selectHaveHarvestingUnsavedStates: createSelector(
    'selectHarvestingUnsavedStates',
    unsavedStates => Object.values(unsavedStates).some(({ unsavedCount }) => unsavedCount > 0)
  ),
  selectShowUnsavedHarvestingLeaveDialog: createSelector(
    'selectHarvestingStateRoot',
    prop('showUnsavedHarvestingLeaveDialog'),
  ),
  reactHarvestingAutoSetDryRoom: createAppIsReadySelector({
    dependencies: ['selectAvailableFeatures', 'selectHarvest', 'selectHarvestingState'],
    resultFn: (availableFeatures, harvest, harvestingState = EMPTY_OBJECT) => {
      const { queue, processedRooms } = harvestingState
      if (!queue || !queue.length || !harvest.dryRoom) {
        return null
      }

      const [lastAdded] = queue
      const isMetrc = available(availableFeatures, middlewareFeatures)
      const processedRoomsKey = isMetrc ? getQueueGroupId(lastAdded) : null
      const noChangeNeeded = !isMetrc || (
        processedRoomsKey in processedRooms && processedRooms[processedRoomsKey]
      )

      const lastIsWET = lastAdded?.item?.category === 'WET'
      const notdDryRoomItem = !isMetrc || !lastIsWET

      if (noChangeNeeded || notdDryRoomItem) {
        return null
      }
      const args = [processedRoomsKey, harvest.dryRoom]
      return { actionCreator: 'doOnProcessedRoomsChange', args, priority: REACTOR_PRIORITIES.HIGH }
    }
  }),
  reactHarvestingInitializeState: createAppIsReadySelector({
    dependencies: ['selectHarvestingStateRoot', 'selectDialogRouteInfo', 'selectAvailableFeatures'],
    resultFn: (harvestingStateRoot, routeInfo, availableFeatures) => {
      if (!isHarvestingUrl(routeInfo)) return null
      const { id } = routeInfo.params
      if (id in harvestingStateRoot.flows) return null
      return {
        actionCreator: 'doHarvestingInitializeState',
        args: [Number(id), { which: availableFeatures.has('METRC') ? 'METRC' : 'GENERIC' }],
        priority: REACTOR_PRIORITIES.HIGH
      }
    }
  }),
  reactUnsavedHarvestingUploadState: createAppIsReadySelector({
    dependencies: [
      'selectIsOnline',
      'selectHarvestingCurrentHarvestId',
      'selectHarvestingUnsavedStates',
      'selectHarvestingStates',
      'selectFlowsInflight',
    ],
    resultFn: (online, currentHarvestId, unsavedStates, harvestingStates, flowsInflight) => {
      logger.debug('[reactUnsavedHarvestingUploadState]', { online, currentHarvestId, unsavedStates, flowsInflight })
      if (!online || currentHarvestId) {
        logger.debug('[reactUnsavedHarvestingUploadState] not online or in harvesting flow', { online, currentHarvestId })
        return null
      }
      /* eslint-disable no-restricted-syntax, no-continue */
      for (const harvestId of Object.keys(unsavedStates)) {
        const { [harvestId]: harvestingState } = harvestingStates
        const { [harvestId]: unsavedState = EMPTY_OBJECT } = unsavedStates
        // if we don't have a harvesting state, we can't do anything
        if (!harvestingState) {
          logger.debug('[reactUnsavedHarvestingUploadState] no harvesting state', { harvestId })
          continue
        }
        const { flowSaving, id, reconciling } = harvestingState
        // if we don't have anything unsaved, we can't do anything
        if (!unsavedState.unsavedCount) {
          logger.debug('[reactUnsavedHarvestingUploadState] no unsaved count', { harvestId })
          continue
        }
        const { [id]: flowInflight } = flowsInflight
        const alreadyProcessing = flowInflight || shouldWaitFor(flowSaving) || shouldWaitFor(reconciling)
        // if we are already processing, we don't want to do anything else right now
        if (alreadyProcessing) {
          logger.debug('[reactUnsavedHarvestingUploadState] flow for', harvestId, 'already reconciling or saving')
          return null
        }
        return { actionCreator: 'doHarvestingFlowSave', args: [harvestId] }
      }
      return null
      /* eslint-enable no-restricted-syntax, no-continue */
    },
  }),
  reactHarvestingUploadState: createAppIsReadySelector({
    dependencies: ['selectIsOnline', 'selectHarvestingState', 'selectFlows'],
    resultFn: (online, harvestingState, flows) => {
      if (!online) return null
      if (!harvestingState || !readyToSave(harvestingState)) {
        logger.debug('[reactHarvestingUploadState] not ready to save to cloud', harvestingState)
        return null
      }
      const { flowSaving, id, reconciling, savedAt, stateModified } = harvestingState ?? EMPTY_OBJECT
      if (savedAt >= stateModified) {
        logger.debug('[reactHarvestingUploadState] unchanged since last save', { savedAt, stateModified })
        return null
      }
      if (shouldWaitFor(flowSaving) || shouldWaitFor(reconciling)) {
        logger.debug('[reactHarvestingUploadState] reconciling or saving', {
          flowSaving,
          reconciling,
          now: Date.now(),
        })
        return null
      }

      const { [id]: flow } = flows
      const nextFlowData = flowDataPicker(harvestingState)
      if (!flow || isOutOfSync(harvestingState, flow)) {
        const dispatchPayload = { actionCreator: 'doHarvestingFlowSave', priority: REACTOR_PRIORITIES.HIGH }
        logger.debug('[reactHarvestingUploadState] calling doHarvestingFlowSave', {
          nextFlowData,
          currentFlowData: flow?.data,
        })
        return dispatchPayload
      }

      logger.debug('[reactHarvestingUploadState] we reached the end')
      return null
    }
  }),
  ...actions,
}
