import { cloneElement, isValidElement } from 'react'

import classNames from 'clsx'
import PropTypes from 'prop-types'

import { Box, Fab, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { atLeastOneRequired } from '~/src/Lib/PropTypes'

import { calcFluidTypography } from '../Theme/typography'

const useStyles = makeStyles(theme => ({
  root: {
    gridColumn: '1 / 4',
    gridRow: '2 / 5',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    placeContent: 'center',
    justifyContent: 'center',
    gap: '1rem',
    '&$inline $headerIcon': {
      fontSize: theme.spacing(9),
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    },
    '&$inline $heading': {
      flex: '0 0 auto',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        ...theme.typography.body1,
        flex: '0 1 auto',
        paddingBottom: theme.spacing(),
      },
    },
  },
  inline: {},
  headerIcon: {
    fontSize: calcFluidTypography({ maxFont: 96, minFont: 72, baseRem: 2 }),
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    }
  },
  headerIconContainer: {
    display: 'flex',
    placeItems: 'center',
    overflow: 'hidden',
    '$inline &': {
      height: '100%'
    }
  },
  heading: {
    flex: '0 0 100%',
    textAlign: 'center',
    '$inline &': {
      margin: 0,
    }
  },
  fabContainer: {
    '$root:not($inline) &': {
      display: 'inline-flex',
      flex: '0 0 100%',
      justifyContent: 'center',
    }
  },
  fab: {
    '$root &': {
      padding: '0 1rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        paddingLeft: 2,
      }

    }
  },
  fabIcon: {
    marginRight: 4,
    fill: theme.palette.text.primary,
  },
}), { name: 'EmptyState' })

/**
 * A component to display when data is not present with an optional call to action
 * @component
 * @alias EmptyState
 * @named_export EmptyStateComponent
 * @composes
 * from: '@mui/material/Typography'
 * propName: headingProps
 * link: https://material-ui.com/api/typography/#props
 * @composes
 * from: '@mui/material/Fab'
 * propName: fabProps
 * link: https://material-ui.com/api/fab/#props
 * @example
 * import BubbleChart from '@mui/icons-material/BubbleChart'
 * import Cake from '@mui/icons-material/Cake'
 * import Fastfood from '@mui/icons-material/Fastfood'
 * import Timelapse from '@mui/icons-material/Timelapse'
 * <Box display="flex" style={{ gap: '2rem' }}>
 *   <EmptyState
 *     HeaderIcon={Cake}
 *     heading="It's (not) your birthday!"
 *     fabLabel="Make it my birthday*"
 *     fabProps={{ style: { marginBottom: '2rem' } }}
 *   >
 *     <T.Tiny>* We can't really make it your birthday, but, if we could we would. Really.</T.Tiny>
 *   </EmptyState>
 *   <EmptyState
 *     HeaderIcon={<BubbleChart htmlColor="orange" />}
 *     heading={<T.H5 color="error">We know your data is around here somewhere, but we can't find it right now.</T.H5>}
 *     fabLabel="Bring back my data!"
 *     fabProps={{ color: 'secondary' }}
 *   />
 *   <EmptyState
 *     HeaderIcon={<Timelapse color="primary" />}
 *     heading="You've been working for hours. It's time for a break."
 *     fabLabel="You're right. I'm hungry."
 *     FabIcon={Fastfood}
 *   />
 * </Box>
 */
// eslint-disable-next-line react/require-default-props
export const EmptyStateComponent = ({
  children,
  className = undefined,
  dataTestId = 'aroya-empty-state',
  FabIcon = undefined,
  fabLabel = undefined,
  fabProps = undefined,
  HeaderIcon = undefined,
  heading = undefined,
  headingProps = undefined,
  inline = undefined,
  style = undefined,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Box
      data-testid={dataTestId}
      className={classNames(classes.root, inline && classes.inline, className)}
      style={style}
      sx={restProps}
    >
      {HeaderIcon ? (
        <div className={classes.headerIconContainer}>
          {isValidElement(HeaderIcon) ? cloneElement(HeaderIcon, {
            className: HeaderIcon.props.className
              ? classNames(classes.headerIcon, HeaderIcon.props.className)
              : classes.headerIcon
          }) : (
            <HeaderIcon className={classes.headerIcon} />
          )}
        </div>
      ) : null}
      {isValidElement(heading) ? cloneElement(heading, {
        className: heading.props.className
          ? classNames(classes.heading, heading.props.className)
          : classes.heading
      }) : (
        <Typography
          gutterBottom={!inline}
          variant="subtitle1"
          className={classes.heading}
          {...headingProps}
        >
          {heading}
        </Typography>
      )}
      {fabLabel || FabIcon ? (
        <div className={classes.fabContainer}>
          <Fab
            variant="extended"
            color="primary"
            size="small"
            {...fabProps}
            className={classes.fab}
          >
            {FabIcon ? <FabIcon className={classes.fabIcon} /> : null}
            {fabLabel}
          </Fab>
        </div>
      ) : null}
      {children}
    </Box>
  )
}
EmptyStateComponent.displayName = 'EmptyState'
EmptyStateComponent.propTypes = {
  ...atLeastOneRequired({
    children: PropTypes.node,
    heading: PropTypes.node,
  }),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  FabIcon: PropTypes.elementType,
  fabLabel: PropTypes.node,
  fabProps: PropTypes.shape({
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    onClick: PropTypes.func,
  }),
  HeaderIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  headingProps: PropTypes.shape({
    color: PropTypes.string,
    gutterBottom: PropTypes.bool,
    variant: PropTypes.string,
  }),
  inline: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
}

export default EmptyStateComponent
