import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import { pick, props as pickValues, uniqBy } from 'ramda'

import { isNumber, shallowEqualsMemoizer } from '~/src/Lib/Utils'

export const dataPicker = pick(['startDate', 'endDate', 'dataType', 'phaseId', 'room'])
export const rangeFields = [
  'targetMin',
  'targetMax',
  'alertMin',
  'alertMax',
  'dataTypeKey',
  'nightTargetMin',
  'nightTargetMax',
  'nightAlertMin',
  'nightAlertMax',
]
export const nightFields = rangeFields.slice(-4)
export const rangePicker = pick(rangeFields)
export const rangeValuesPicker = pickValues(rangeFields)

export const haveTargets = ({ targetMin, targetMax }) => targetMin != null && targetMax != null
export const haveAlerts = ({ alertMin, alertMax }) => alertMin != null && alertMax != null
export const haveAll = targetRange => haveTargets(targetRange) && haveAlerts(targetRange)

export const getDataTypeByKey = memoizeOne(dataTypes => dataTypes.reduce((acc, dt) => ({
  ...acc,
  [dt.key]: dt
}), {}))

export const getUniqueRanges = shallowEqualsMemoizer(uniqBy(pickValues([
  'alertMin', 'alertMax',
  'targetMin', 'targetMax',
  'nightTargetMin', 'nightTargetMax',
  'nightAlertMin', 'nightAlertMax',
  'startDate', 'endDate'
])))

export const MINMAX_PATTERN = /[^0-9.]/g

export const getValidNumber = str => {
  if (str === '-' || str.endsWith('.')) {
    return str
  }
  if (!isNumber(Number(str))) {
    const newStr = str.replace(MINMAX_PATTERN, '')
    if (str[0] === '-') {
      return Number(`-${newStr}`)
    }
    return Number(newStr)
  }
  return Number(str)
}

export const updateTargetRange = (previous, current) => {
  if (!previous) return current

  const result = { ...previous, ...current }
  if (isNumber(current.targetMax) && current.targetMax !== previous.targetMax && previous.targetMax === previous.alertMax) {
    result.alertMax = current.targetMax
  }
  if (isNumber(current.targetMin) && current.targetMin !== previous.targetMin && previous.targetMin === previous.alertMin) {
    result.alertMin = current.targetMin
  }
  return result
}

export const getRowAction = ({ noRemove, expanded, hasTargetSet = null }) => {
  if (noRemove) return null
  if (!noRemove && expanded) {
    return i18n`Remove`
  }
  if (!expanded && hasTargetSet) {
    return i18n`Edit`
  }
  return i18n`Set Targets`
}
