import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

const modbusRegistersBundle = createAsyncResourceBundle({
  name: 'modbusRegisters',
  actionBaseType: 'MODBUS_REGISTERS',
  getPromise: ({ apiFetch, store }) => {
    const { id } = store.selectDialogRouteParams()
    return apiFetch(`/irrigationControllers/${id}/modbus_registers/`)
  },
})

export default {
  ...modbusRegistersBundle,
  reactFetchModbusRegisters: createSelector(
    'selectDialogRouteParams',
    'selectModbusRegisters',
    'selectModbusRegistersShouldUpdate',
    (dialogRouteParams, data, shouldUpdate) => {
      if (!dialogRouteParams) return null
      const { action, id, schema } = dialogRouteParams
      const matchesDialogRoute = schema === 'irrigationControllers' && action === 'registers' && id
      if (matchesDialogRoute && shouldUpdate) {
        return { actionCreator: 'doFetchModbusRegisters' }
      }
      if (!matchesDialogRoute && data) {
        return { actionCreator: 'doClearModbusRegisters' }
      }
      return null
    },
  ),
}
