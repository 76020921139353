import { createSelector } from 'redux-bundler'

export default {
  name: 'badges',
  selectNavBadgesByUrl: createSelector(
    'selectEventStats',
    eventStats => {
      const badges = {}
      if (eventStats?.events) {
        badges['/compliance'] = {
          color: 'error',
          value: Number(eventStats.events),
        }
      }
      return badges
    },
  ),
}
