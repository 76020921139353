const deferrerPriorities = {
  highest: typeof queueMicrotask === 'function'
    ? queueMicrotask
    : fn => Promise.resolve().then(fn).catch(e => requestIdleCallback(() => console.error(e))),
  high: fn => requestAnimationFrame(() => requestAnimationFrame(fn)),
  medium: fn => requestAnimationFrame(() => setTimeout(fn, 0)),
  low: typeof requestIdleCallback !== 'undefined'
    ? fn => requestIdleCallback(fn, { timeout: 500 })
    : fn => setTimeout(fn, 250),
  lowest: typeof requestIdleCallback !== 'undefined'
    ? requestIdleCallback
    : fn => setTimeout(fn, 2500),
}
/**
 * Run a function asynchronously with varying priorities
 * @param {function} fn The function to call after deferring
 * @param {function|number} [deferrer=setImmediate]
 * 1. One of the pre-set prioriies: high, medium or low
 * 2. A number milliseconds, using setTimeout
 * 3. Your custom deferrer function
 * @param  {...any} [args] Arguments to pass to fn
 * @property {Object} priorities
 * @property {function} priorities.highest Defer until any already running micro-tasks complete
 * @property {function} priorities.high Defer one animation frame
 * @property {function} priorities.medium Defer as immediately as possible with lower priority than animation frame
 * @property {function} priorities.low Defer until the soorner of idle or 500ms elapses
 * @property {function} priorities.lowest Defer until idle
 * @returns {number} The job ID
 */
export const defer = (fn, deferrer = deferrerPriorities.medium, ...args) => {
  const bound = args.length ? fn.bind(null, ...args) : fn
  if (typeof deferrer === 'number') {
    return setTimeout(bound, deferrer)
  }
  if (typeof deferrer === 'function') {
    return deferrer(bound)
  }
  return deferrerPriorities.medium(bound)
}
defer.priorities = deferrerPriorities

export const deferred = (fn, priority) => defer.bind(null, fn, priority)

/**
 * Defer running a function until a test passes
 * @param {function} fn The function to call when the test passes
 * @param {function} test Boolean returning test function that should pass before calling fn
 * @param {number} [tick=33] How often should we re-run the test function
 * @param {number} [timeout=10000] Milliseconds before we give up and call fn even though test is failing
 * @returns {function} Cancel function (fn is not called)
 */
export const deferUntil = (fn, test, tick = 33, timeout = 10000) => {
  let abort = false
  const start = Date.now()
  const deferrer = () => {
    if (!abort && (test() || Date.now() - start >= timeout)) {
      fn()
      return
    }
    if (!abort) {
      defer(deferrer, tick)
    }
  }

  deferrer()
  // return cancel function
  return function cancel() {
    abort = true
  }
}

export const debounce = (fn, wait = 66, leading = false, maxWait) => {
  let timeout
  let returned
  let called = 0

  const debounced = (...args) => {
    if (!called && !leading) {
      called = Date.now()
    }
    const later = () => {
      timeout = null
      if (!leading) {
        called = Date.now()
        returned = fn(...args)
      }
    }
    const callNow = (leading && !timeout) || (maxWait && called < Date.now() - maxWait)
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      called = Date.now()
      returned = fn(...args)
    }
    return returned
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export const throttle = (fn, limit = 250) => {
  let scheduled
  let previous
  let returned

  return (...args) => {
    if (!previous) {
      returned = fn(...args)
      previous = Date.now()
    } else {
      clearTimeout(scheduled)
      scheduled = setTimeout(() => {
        if ((Date.now() - previous) >= limit) {
          returned = fn(...args)
          previous = Date.now()
        }
      }, limit - (Date.now() - previous))
    }
    return returned
  }
}

export const rafThrottle = fn => {
  let scheduled
  const clear = () => {
    cancelAnimationFrame(scheduled)
    scheduled = null
  }

  const throttled = (...args) => {
    const later = () => {
      scheduled = null
      fn(...args)
    }
    clear()
    scheduled = requestAnimationFrame(later)
    return scheduled
  }

  throttled.cancel = () => {
    clear()
  }

  return throttled
}
