export const ANNOTATIONS_HEIGHT = 36
export const ANNOTATION_WIDTH = 36
export const CONTENT_PADDING = 12
export const EPOCH_DATE = '2018-04-02'
export const EPOCH_DATETIME = '2018-04-02T07:00:00.000Z'
export const MOBILE_LANDSCAPE_CHART_HEIGHT = 'calc(100vh - 28px)'
export const SPACES = Object.freeze({
  EMSP: '\u2003',
  ENSP: '\u2002',
  HRSP: '\u200a',
  NBSP: '\u00a0',
  NNBSP: '\u202f',
  THSP: '\u2009',
  ZWSP: '\u200b',
})
