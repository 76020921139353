import memoizeOne from 'memoize-one'
import { pick } from 'ramda'

import * as actions from '~/src/Flow/bundle/harvesting/actions'
import {
  getGenericQueuedSources,
  getNewQueue,
  reconcileStateAndFlow,
} from '~/src/Flow/bundle/harvesting/utils'
import { convertWeight, randomId } from '~/src/Lib/Utils'

import { SHARED_INITIAL_STATE } from './shared'
import { getSavedAt, setModifiedAt } from './utils'

export const initFlowState = memoizeOne(harvestId => ({
  ...SHARED_INITIAL_STATE,
  harvestId,
  lastSelectedCultivar: null,
  reconciling: 0,
}))

const pickInitialized = pick(['harvestId', 'sources'])
const pickCultivarSelected = pick(['cultivar', 'harvestCultivar', 'cultivarName', 'harvest'])

const refreshState = (state, payload, meta) => {
  const {
    dequeued,
    flowId,
    processedRooms,
    queue,
    settings,
  } = payload

  // we might receive new settings from the cloud or use existing settings, so extra-checking to use the correct flag
  const nextState = {
    ...reconcileStateAndFlow(state, { data: { queue, dequeued, processedRooms } }, meta.which),
    settings: settings ?? state.settings,
    id: flowId,
    savedAt: getSavedAt(payload, state),
  }
  if (nextState.queue?.length) {
    const queuedSources = getGenericQueuedSources(nextState.queue, nextState.settings)
    nextState.queuedSources = queuedSources
  }
  return nextState
}

const reducers = {
  [actions.HARVESTING_INITIALIZE_SOURCES]: (state, payload) => ({
    ...state,
    ...pickInitialized(payload),
  }),

  [actions.HARVESTING_PROCESSED_ROOMS_CHANGE]: (state, payload) => ({
    ...state,
    processedRooms: payload.room,
  }),

  [actions.HARVESTING_SOURCE_CLICK]: (state, payload) => {
    const {
      selected,
      sources,
      queuedSources,
      settings: { weighingIndividually },
    } = state

    const {
      autoSelect,
      id,
      cultivar,
      cultivarName,
      harvest,
    } = payload

    if (autoSelect) {
      if (selected) {
        return state
      }
      if (weighingIndividually) {
        const nextSelectedCultivar = sources.find(source => source.count - (queuedSources[source.cultivar] ?? 0))

        return {
          ...state,
          selected: nextSelectedCultivar ? {
            cultivar: nextSelectedCultivar.cultivar,
            harvestCultivar: nextSelectedCultivar.id,
            cultivarName: nextSelectedCultivar.cultivarName,
            harvest: nextSelectedCultivar.harvest,
          } : null,
        }
      }

      // bulk weighing
      return {
        ...state,
        selected: state.lastSelectedCultivar ? { ...state.lastSelectedCultivar } : null,
      }
    }

    const selectedObj = selected?.cultivar === cultivar
      ? null
      : {
        cultivar,
        harvestCultivar: id,
        cultivarName,
        harvest,
      }

    return {
      ...state,
      selected: selectedObj,
      lastSelectedCultivar: selectedObj,
    }
  },

  [actions.HARVESTING_CONVERT_QUEUED_WEIGHTS]: state => {
    const {
      queue,
      settings: { weightUnit: { id, symbol } }
    } = state

    const convertedQueue = queue.map(queued => ({
      ...queued,
      unit: symbol,
      unitId: id,
      weight: convertWeight(queued.weight, queued.unit, symbol),
    }))

    return {
      ...state,
      queue: convertedQueue,
    }
  },

  [actions.HARVESTING_REMOVE_QUEUED]: (state, payload) => {
    const { queue: previousQueue } = state
    const queue = previousQueue.filter(queued => queued.id !== payload.id)

    return {
      ...state,
      dequeued: [...state.dequeued, payload.id],
      selected: null,
      queue,
      queuedSources: getGenericQueuedSources(queue, state.settings),
    }
  },

  [actions.HARVESTING_REVIEW_DONE]: state => ({
    ...state,
    selected: null,
    queue: [],
    queuedSources: {},
    review: false,
  }),

  [actions.HARVESTING_SUBMIT_FORM]: (state, payload) => {
    const {
      queue: oldQueue,
      settings,
      selected,
      sources,
    } = state
    const selectedCount = sources.find(source => selected?.cultivar === source?.cultivar)?.count
    const uidToSubmit = settings.weighingIndividually && !settings.systemUidPerWeight && !payload.uid
      ? randomId(16)
      : payload.uid

    const queue = getNewQueue({ ...payload, uid: uidToSubmit }, oldQueue)
    const queuedSources = getGenericQueuedSources(queue, settings)

    return {
      ...state,
      queue,
      queuedSources,
      selected: queuedSources[selected?.cultivar] === selectedCount
        ? null
        : pickCultivarSelected(selected),
    }
  },

  [actions.HARVESTING_RESET_STATE]: state => initFlowState(state.harvestId),

  [actions.HARVESTING_REFRESH_STATE]: refreshState,

  [actions.HARVESTING_RECONCILED_FLOW]: (state, payload, meta) => ({
    ...state,
    ...reconcileStateAndFlow(state, payload, meta?.which),
    reconciling: 0,
  })
}

export default Object.entries(reducers).reduce((acc, [key, reducer]) => ({
  ...acc,
  [key]: setModifiedAt(reducer),
}), {})
