import PropTypes from 'prop-types'
import {
  always,
  ascend,
  either,
  prop,
  sortWith,
} from 'ramda'

import ECBoltIcon from '~/src/UI/Icons/ECBolt'
import MeasurementIcon from '~/src/UI/Icons/Measurement'
import PoreECIcon from '~/src/UI/Icons/PoreEC'
import RHIcon from '~/src/UI/Icons/RelativeHumidity'
import VPDIcon from '~/src/UI/Icons/VPD'
import WaterContentIcon from '~/src/UI/Icons/WaterContent'

import { substratePattern } from './constants'

/* eslint-disable babel/camelcase */
export const typeToIcon = {
  default: MeasurementIcon,
  pore_ec: PoreECIcon,
  feed_ec: ECBoltIcon,
  runoff_ec: ECBoltIcon,
  spot_ec: ECBoltIcon,
  rel_hum: RHIcon,
  vpd: VPDIcon,
  soil_moist: WaterContentIcon,
  spot_wc: WaterContentIcon,
  water_potential: WaterContentIcon,
}
/* eslint-enable babel/camelcase */

export const shape = {
  key: PropTypes.string,
  color: PropTypes.string,
  category: PropTypes.oneOf([
    'SOIL',
    'ENVIRONMENT',
    'MANUAL',
    'OTHER',
    'MANUAL_SUBSTRATE',
    'SENSOR_SUBSTRATE',
    'MANUAL_CLIMATE',
    'SENSOR_CLIMATE',
    'MANUAL_IRRIGATION',
    'SENSOR_IRRIGATION',
    'MANUAL_OTHER',
    'SENSOR_OTHER',
  ]),
}

export const dataTypeSort = sortWith([ascend(dt => {
  switch (dt.category) {
    case 'SOIL':
    case 'SENSOR_SUBSTRATE':
      return 1
    case 'ENVIRONMENT':
    case 'SENSOR_CLIMATE':
      return 2
    case 'OTHER':
    case 'SENSOR_IRRIGATION':
    case 'SENSOR_OTHER':
      return 3
    case 'MANUAL':
    case 'MANUAL_CLIMATE':
    case 'MANUAL_PLANT':
    case 'MANUAL_SUBSTRATE':
      return 4
    default:
      return 5
  }
}), ascend(either(prop('sequence'), always(1000))), ascend(prop('name'))])

export const manualDataTypeSort = sortWith([ascend(dt => {
  switch (dt.category) {
    case 'MANUAL_CLIMATE':
      return 1
    case 'MANUAL_SUBSTRATE':
      return 2
    case 'MANUAL_PLANT':
      return 3
    case 'MANUAL_OTHER':
      return 4
    default:
      return 5
  }
}), ascend(prop('sequence')), ascend(prop('name'))])

export const isSubstrate = dataType => substratePattern.test(dataType.category)
