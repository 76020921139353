import i18n from 'i18n-literally'

import { styled } from '@mui/material/styles'

import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import Tooltip from '~/src/UI/Shared/Tooltip'

import { useLock } from './utils'

const Outer = styled('div')(({ theme }) => ({
  opacity: theme.palette.states.inactive,
}))

const Inner = styled('div')({ display: 'contents' })

/**
 * Component to lock a feature based on a test with support for passing redux-bundler selectors.
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {?string[]} props.selectors
 * @param {function(?string[], Object<string, any>): boolean} props.test
 * @param {import('react').ReactNode} [props.tooltip]
 * @returns {import('react').ReactNode}
 */
const Lock = ({
  children,
  selectors = EMPTY_ARRAY,
  test,
  tooltip = i18n`This feature is locked.`,
  ...props
}) => (
  useLock(test, selectors, props)
    ? (
      <Tooltip variant="light" title={tooltip} placement="top">
        <Outer>
          <Inner inert="">{children}</Inner>
        </Outer>
      </Tooltip>
    )
    : children
)

export default Lock
