const name = 'drawer'

const actions = {
  MOUNTED: 'DRAWER_MOUNTED',
  UNMOUNTED: 'DRAWER_UNMOUNTED',
  TAB_RENDERED: 'DRAWER_TAB_RENDERED',
  OPENED: 'DRAWER_OPENED',
  CLOSED: 'DRAWER_CLOSED',
}

const initialState = {
  isMounted: false,
  isOpen: false,
  tab: null,
}

export default {
  name,
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case actions.MOUNTED:
        return { ...state, isMounted: true }
      case actions.UNMOUNTED:
        return { ...state, isMounted: false }
      case actions.TAB_RENDERED:
        return { ...state, tab: action.payload }
      case actions.OPENED:
        return { ...state, isOpen: true }
      case actions.CLOSED:
        return { ...state, isOpen: false }
      default:
        return state
    }
  },
  selectDrawer: state => state[name],
  doMountDrawer: () => ({ type: actions.MOUNTED }),
  doUnmountDrawer: () => ({ type: actions.UNMOUNTED }),
  doRenderDrawerTab: tab => ({ type: actions.TAB_RENDERED, payload: tab }),
  doOpenDrawer: () => ({ type: actions.OPENED }),
  doCloseDrawer: () => ({ type: actions.CLOSED }),
}
