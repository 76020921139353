import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'
import {
  compose,
  evolve,
  pick,
  trim,
} from 'ramda'

import { string as yupString } from 'yup'

import { phoneNumberOrNil } from '~/src/Lib/Data'
import { idPropType } from '~/src/Lib/PropTypes'

const shapeConfig = {
  id: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  currentMembership: idPropType,
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: idPropType,
      name: PropTypes.string,
    })
  ),
}
export const shape = PropTypes.shape(shapeConfig)

export const cleanPhoneNumber = (phone = '', countryCode = '+1', length = 10) => {
  if (phone === '') return ''
  const phoneNumber = phone.replace(countryCode, '').replace(/([^\d]+)/g, '')
  return phoneNumber.length
    ? Number(phoneNumber)
      .toString()
      .slice(0, length)
    : ''
}

export const formattedPhoneNumber = (phone = '', countryCode = '+1', length = 10) => {
  const cleaned = cleanPhoneNumber(phone, countryCode, length).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ')-', match[3], '-', match[4]].join('')
  }
  return cleaned
}

export const maskPhoneNumber = (phone = '', countryCode = '+1', length = 10) => {
  const str = formattedPhoneNumber(phone, countryCode, length).toString()
  const mask = '*'
  return ('+1', str).slice(0, -4).replace(/./g, mask) + str.slice(-4)
}

export const validatePhoneNumber = phoneNumber => {
  if (!phoneNumber) return false
  const phone = phoneNumber.trim()
  return /^\+?1?\d{10}$/.test(phone)
}

const emailValidator = yupString().email('Invalid email address')
export const isValidEmail = memoizeOne(email => {
  try {
    emailValidator.validateSync(email)
    return true
  } catch (e) {
    return false
  }
})

const passwordTest = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[@_!#$%^&*()?])[A-Za-z\d@_!#$%^&*()?]{8,}$/
export const isValidPassword = pw => passwordTest.test(pw)

const transforms = {
  id: Number,
  email: trim,
  firstName: trim,
  lastName: trim,
  phoneNumber: phoneNumberOrNil,
}
const transformer = evolve(transforms)
export const cleaner = compose(
  transformer,
  pick(Object.keys(shapeConfig))
)

export const prepareData = raw => {
  const clean = cleaner(raw)
  return {
    ...clean,
    // TODO: International fix pending, handle in backend
    phoneNumber: clean.phoneNumber ? `+1${clean.phoneNumber}` : '',
  }
}
