import { prop } from 'ramda'

import { getAsyncActionIdentifiers } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'

const logger = createLogger('Support/bundle')

export const submitFeedback = getAsyncActionIdentifiers('submit', 'feedback')

export const initialState = { error: null, result: null, submitting: false }

export default {
  name: 'support',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case submitFeedback.types.start: {
        return {
          ...state,
          error: null,
          result: null,
          submitting: true,
        }
      }
      case submitFeedback.types.succeed: {
        return {
          ...state,
          result: action.payload,
          submitting: false,
        }
      }
      case submitFeedback.types.fail:
        return {
          ...state,
          error: action.error,
          submitting: false,
        }
      default:
        return state
    }
  },
  doSubmitFeedback: payload => async ({ apiFetch, dispatch }) => {
    dispatch({ type: submitFeedback.types.start })
    let result = false
    try {
      result = await apiFetch('/support/', payload, { method: 'POST' })
      dispatch({ type: submitFeedback.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({ type: submitFeedback.types.fail, error })
    }
    return result
  },
  selectSupport: prop('support'),
}
