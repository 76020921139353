import {
  map,
  pipe,
  prop,
  sort,
  uniq,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { FacilityPhase as schema } from '~/src/Store/Schemas'

const name = 'facilityPhases'

const phaseSequence = {
  PROP: 0,
  VEG: 1,
  FLOWER: 2,
  DRY: 3,
}

export default createEntityBundle({
  name,
  apiConfig: { schema, returnRaw: true, snackbar: false },
  // scope,
  selectFacilityPhaseTypes: createSelector(
    'selectFacilityPhases',
    pipe(Object.values, map(prop('phaseType')), uniq, sort((a, b) => phaseSequence[a] - phaseSequence[b])),
  )
})
