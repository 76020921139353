import createEntityBundle from '~/src/Lib/createEntityBundle'
import { DeviceNumberOfPlants } from '~/src/Store/Schemas'

const name = 'deviceNumberOfPlants'

export default createEntityBundle({
  name,
  apiConfig: {
    schema: DeviceNumberOfPlants,
    url: 'deviceNumberOfPlants',
    fetch: {
      handler: ({
        apiFetch,
        payload
      }) => apiFetch(`/deviceNumberOfPlants/by_device/${payload.id}/`)
    },
    save: {
      handler: ({
        apiFetch,
        payload
      }) => apiFetch('/deviceNumberOfPlants/', payload, { method: 'POST' })
    }
  },
  autoFetch: false,
})
