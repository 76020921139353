import humanizeDuration from 'humanize-duration'
import { DateTime, Duration } from 'luxon'
import milliseconds from 'milliseconds'

import getGlobal from '~/src/Lib/getGlobal'

import { EMPTY_OBJECT } from './constants'
import { capitalizeInitial } from './strings'

/**
 * Returns a valid DateTime object by handling various input types
 * @param {Date|DateTime|string|number} dateOrString
 * @param {{locale: string, zone: string}} [opts]
 * @returns DateTime object
 */
export const getDateTime = (dateOrString, opts) => {
  if (dateOrString === 'now') return DateTime.local()
  if (DateTime.isDateTime(dateOrString)) return dateOrString
  if (typeof dateOrString === 'number' || !Number.isNaN(Number(dateOrString))) {
    return DateTime.fromMillis(Number(dateOrString), opts)
  }
  const isString = typeof dateOrString === 'string'
  let date = isString
    ? DateTime.fromISO(dateOrString, opts)
    : DateTime.fromJSDate(dateOrString, opts)
  if (!date.isValid && dateOrString && isString) {
    date = DateTime.fromJSDate(new Date(dateOrString), opts)
  }
  return date
}
if (getGlobal()) getGlobal().getDateTime = getDateTime

/**
 * Returns date or transformed date from the input
 * @param {Date|string} dateOrString
 * @returns Date
 */
export const getDate = dateOrString => {
  if (dateOrString instanceof Date) return dateOrString
  return getDateTime(dateOrString).toJSDate()
}

/**
 * @typedef {Object} DateFormat
 * @property {string} [year]
 * @property {string} [month]
 * @property {string} [day]
 * @property {string} [hour]
 * @property {string} [minute]
 * @property {string} [second]
 * @property {boolean} [hour12]
 */
/**
 * Constant with date formats for toLocaleString
 * @typedef {Object} DATE_FORMATS
 * @property {DateFormat} FULL_DATE Example: Tuesday, July 14, 2020
 * @property {DateFormat} FULL_TIME Example: 01:49:04 PM
 * @property {DateFormat} LONG_DATE Example: July 14, 2020
 * @property {DateFormat} LONG_TIME Example: 01:49 PM
 * @property {DateFormat} SHORT_DATE Example: 7/14/20
 * @property {DateFormat} SHORT_DATETIME Example: 7/14/20, 1:49 PM
 * @property {DateFormat} SHORT_TIME Example: 1:49 PM
 */
export const DATE_FORMATS = {
  FULL_DATE: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  },
  FULL_TIME: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  LONG_DATE: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  LONG_TIME: {
    hour: '2-digit',
    minute: '2-digit',
  },
  SHORT_DATE: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  },
  SHORT_DATE_WITH_WEEKDAY: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    weekday: 'short'
  },
  SHORT_DATETIME: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  TINY_DATE: {
    month: 'numeric',
    day: 'numeric',
  },
  TINY_DATETIME: {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  LONG_MONTH: {
    month: 'long',
    day: 'numeric',
  },
  SHORT_MONTH: {
    month: 'short',
    day: 'numeric',
  },
  LONG_DATE_SHORT_MONTH: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  TINY_HOUR: {
    shape: 'ha'
  },
  SHORT_TIME: {
    shape: 'h:mma'
  }
}
/**
 * Returns formatted date
 * @param {Date|string} date
 * @param {(string|DateFormat)} [format = DATE_FORMATS.SHORT_DATE]
 * @param {{locale: string, zone: string}} opts
 * @returns {string|DateTime} a string formatted according to the format specified
 */
export const formattedDate = (
  date,
  format = DATE_FORMATS.SHORT_DATE,
  opts = EMPTY_OBJECT
) => {
  const finalFormat = (format in DATE_FORMATS ? DATE_FORMATS[format] : format) ?? DATE_FORMATS.LONG_DATE
  return finalFormat.shape
    ? getDateTime(date, opts).toFormat(finalFormat.shape)
    : getDateTime(date, opts).toLocaleString(finalFormat)
}
Object.assign(formattedDate, DATE_FORMATS)

/**
 * Returns date difference between two dates
 * @param {DateTime|Date|string} firstDate
 * @param {DateTime} [secondDate = DateTime.local]
 * @returns {{daysDiff: number, text: string}}
 */
export const getDateDiff = (firstDate, secondDate = 'now') => {
  const diffDuration = getDateTime(secondDate).diff(getDateTime(firstDate))
  const daysDiff = Math.floor(diffDuration.as('days'))
  const absDiff = Math.abs(daysDiff)

  let text = ''
  if (absDiff > 20) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: [absDiff > 60 ? 'mo' : 'w'],
    })
  } else if (absDiff > 1) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: ['d'],
    })
  } else if (absDiff === 1) {
    text = daysDiff > 0 ? 'yesterday' : 'tomorrow'
  } else {
    text = 'today'
  }

  return { daysDiff, text }
}

export const annotationTypeDateFormats = {
  DEFAULT: 'SHORT_DATETIME',
  lightSchedules: 'SHORT_DATE',
  targetRanges: 'SHORT_DATE',
  harvests: 'SHORT_DATE',
  harvestPhases: 'SHORT_DATE',
}
export const formatAnnotationTypeTimestamp = (ts, annotationType) => {
  if (!ts) return ''
  const {
    [annotationType]: format = annotationTypeDateFormats.DEFAULT
  } = annotationTypeDateFormats
  const { text: rawText } = getDateDiff(getDateTime(ts).startOf('day'))
  let text = rawText
  if (!['yesterday', 'today', 'tomorrow'].some(day => text === day)) {
    text = formattedDate(ts, DATE_FORMATS[format])
  } else if (format.endsWith('TIME')) {
    text = `${capitalizeInitial(text)}, ${formattedDate(ts, format.replace('DATE', ''))}`
  }

  return text.replace(/\s*PM$/, 'pm').replace(/\s*AM$/, 'am')
}

const DEFAULT_DUEDATE_OPTS = {
  after: ({ text }) => `${text} ago`,
  before: ({ text }) => `in ${text}`
}
/**
 * Returns time due to provided date
 * @param {DateTime|string} [dueDate]
 * @param {DateTime|string} [other = DateTime.local]
 * @returns {{daysDiff: number, text: string}}
 */
export const getDueDateDiff = (dueDate, other, opts = DEFAULT_DUEDATE_OPTS) => {
  if (!dueDate) return { daysDiff: 0, text: '' }

  const diff = getDateDiff(getDateTime(dueDate), other)
  const { daysDiff } = diff

  if (opts.default) {
    return { daysDiff, text: opts.default(diff) }
  }
  if (daysDiff > 1) {
    return { daysDiff, text: opts.after(diff) }
  }
  if (daysDiff < -1) {
    return { daysDiff, text: opts.before(diff) }
  }

  return diff
}

/**
 * Returns time difference between two dates
 * @param {DateTime} firstDT
 * @param {DateTime} [secondDT = DateTime.local]
 * @returns {string}
 */
export const getTimeDiff = (firstDT, secondDT = getDateTime('now')) => {
  const diff = secondDT.diff(getDateTime(firstDT)).as('milliseconds')
  const humanized = humanizeDuration(Math.abs(diff), { largest: 1 })

  if (milliseconds.seconds(60) > Math.abs(diff)) {
    return 'now'
  }

  return diff > 0 ? `${humanized} ago` : `in ${humanized}`
}

/**
 * Returns time difference between two dates
 * @param {DateTime} firstDT
 * @param {DateTime} [secondDT = DateTime.local]
 * @returns {integer}
 */
export const getTimeDiffHours = (firstDT, secondDT = getDateTime('now')) => (
  secondDT.diff(getDateTime(firstDT)).as('milliseconds') / milliseconds.hours(1)
)

/**
 * Checks dates if they are equal
 * @param {DateTime} left
 * @param {DateTime} right
 * @returns {boolean}
 */
export const isEqualDateTime = (left, right) => DateTime.isDateTime(left) && DateTime.isDateTime(right) && left.equals(right)

export const shortDurationHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    }
  },
  delimiter: ' ',
  largest: 2,
  round: true,
  spacer: '',
  units: ['y', 'w', 'd', 'h', 'm', 's'],
})

/**
 * Returns time difference between provided dates and current time with short names
 * @param {DateTime} from
 * @param {DateTime} to? default to now
 * @returns {string}
 */
export const getShortDateDiff = (from, to = 'now', opts = EMPTY_OBJECT) => {
  const diff = getDateTime(to).diff(getDateTime(from))
  if (Math.abs(diff.as('minutes')) < 1) return '<1m'
  return shortDurationHumanizer(diff.as('milliseconds'), opts)
}

/**
 * Converts duration string to milliseconds
 * Example of duration: '1 09:00:00.1111'
 * @param {string} duration
 * @returns {number}
 */
export const durationToMillis = duration => {
  let parts = duration.split('.')
  parts = parts[0].split(/\D/g).map(value => Number(value) || 0)
  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts
    return Duration.fromObject({ hours, minutes, seconds }).as('milliseconds')
  }
  if (parts.length === 4) {
    const [days, hours, minutes, seconds] = parts
    return Duration.fromObject({ days, hours, minutes, seconds }).as('milliseconds')
  }
  return 0
}

/**
 * Given a luxon dateTime object, it returns time in minutes of that day starting from 00:00
 * @param {DateTime} dateTime
 * @returns {number}
 */
export const convertDateTimeToMinutes = dateTime => (dateTime.hour * 60) + dateTime.minute

/**
 * Given a luxon dateTime object, it returns time in seconds of that day starting from 00:00
 * @param {DateTime} dateTime
 * @returns {number}
 */
export const convertDateTimeToSeconds = dateTime => (convertDateTimeToMinutes(dateTime) * 60) + dateTime.second

/**
 * Given a integer minutes, it returns a luxon datetime at that time in the day
 * @param {number}
 * @return {DateTime}
 */
export const convertMinutesToDateTime = minutes => getDateTime('now').startOf('day').plus({ minutes })

/**
 * Given a time in seconds, it returns a object with hour, minute and seconds shape
 * @param {number} seconds
 * @return {object}
 */
export const convertSecondsToTimeObject = seconds => {
  const hour = Math.floor(((seconds) / 60) / 60)
  const minute = Math.floor((seconds) / 60) - (hour * 60)
  return ({
    hour,
    minute,
    second: seconds % 60
  })
}

const daysPattern = /^(\d+)\s+/
const hoursPattern = /(\d{2}):(\d{2}):(\d{2})/
const patterns = [daysPattern, hoursPattern]
// Converts API duration to hours
export const parseDuration = raw => {
  if (!raw || typeof raw === 'number') return raw
  const [days, hours, minutes] = patterns.flatMap(pattern => {
    const match = raw.match(pattern)
    return match
      ? match.filter(p => !p.match(/[:\s]/)).flatMap(p => p.split(/^0/).filter(Boolean).map(Number))
      : match
  })

  let duration = 0
  if (days) {
    duration += days * 24
  }
  if (hours) {
    duration += hours
  }
  if (minutes) {
    duration += minutes / 60
  }

  return duration
}
