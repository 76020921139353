import PropTypes from 'prop-types'

import { datePropType } from '~/src/Lib/PropTypes'

import {
  IRRIGATION_CONTROLLER_PORT_STATUSES,
  IRRIGATION_CONTROLLER_STATUSES,
} from '../../constants'

const controllerPortShapeConfig = {
  id: PropTypes.number,
  controller: PropTypes.number,
  master: PropTypes.bool,
  masterPorts: PropTypes.arrayOf(PropTypes.number),
  number: PropTypes.number,
  status: PropTypes.oneOf(Object.values(IRRIGATION_CONTROLLER_PORT_STATUSES)),
  zones: PropTypes.arrayOf(PropTypes.number),
}
export const controllerPortShape = PropTypes.shape(controllerPortShapeConfig)

// Configuration data can vary by controller model
const controllerConfigShapeConfig = {
  hostname: PropTypes.string,
  port: PropTypes.number,
  username: PropTypes.string,
  password: PropTypes.string,
}

const controllerShapeConfig = {
  id: PropTypes.number,
  config: PropTypes.shape(controllerConfigShapeConfig),
  model: PropTypes.number,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  ports: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, controllerPortShape])),
  room: PropTypes.number,
  gateway: PropTypes.number,
  provisioningStatus: PropTypes.oneOf(Object.values(IRRIGATION_CONTROLLER_STATUSES)),
  sequential: PropTypes.bool,
  location: PropTypes.string,
  lastCommunication: datePropType,
  lastOnline: datePropType,
  ggVersion: PropTypes.oneOf(['1', '2']),
}
export const shape = PropTypes.shape(controllerShapeConfig)
