import memoizeOne from 'memoize-one'
import { groupBy, reduce, reduced } from 'ramda'

import { convertWeight, EMPTY_OBJECT } from '~/src/Lib/Utils'

export const getQueueGroupId = queueItem => {
  if (queueItem.item?.category === 'WET') {
    return queueItem.item.category
  }
  return queueItem.item?.id
}

const queueGrouper = memoizeOne(groupBy(getQueueGroupId))
const wetGrouper = memoizeOne(groupBy(queueItem => [queueItem.license, queueItem.item.id].join('-')))
const processedGrouper = memoizeOne(groupBy(queueItem => queueItem.license))

const processGroup = (props, [groupId, queued], includeRoomChooser = true) => {
  const {
    itemCategories,
    licenses,
    plants = {},
    queue,
    queuedSources,
    units
  } = props
  const [queueFirst] = queue
  const [first] = queued
  const { item } = first
  const { [first.license]: license } = licenses
  const { [item.category]: category } = itemCategories
  const totalPlants = queued.reduce((sum, queueItem) => sum + queueItem.sources.length, 0)
  let headingSymbol = reduce((symbol, queueItem) => {
    const { [queueItem.unitId]: qUnit } = units
    if (symbol) {
      if (qUnit.symbol !== symbol) {
        return reduced('lb')
      }
    }
    return qUnit?.symbol
  }, null, queued)
  let totalWeight = queued.reduce((sum, queueItem) => (
    sum + convertWeight(queueItem.weight, units[queueItem.unitId]?.symbol, headingSymbol)
  ), 0)

  if (headingSymbol === 'g' && totalWeight >= 1e5) {
    headingSymbol = 'kg'
    totalWeight /= 1000
  }

  return [
    { groupId, category, headingSymbol, item, license, totalPlants, totalWeight, includeRoomChooser },
    ...queued.sort((a, b) => {
      const aIsFirst = a === queueFirst
        || a.sources.some(plant => (
          (plant.id in plants && plants[plant.id].state !== 'TRACKED')
            || (plant.label in queuedSources && queuedSources[plant.label] > 1)
        ))
      const bIsFirst = b === queueFirst
        || b.sources.some(plant => (
          (plant.id in plants && plants[plant.id].state !== 'TRACKED')
            || (plant.label in queuedSources && queuedSources[plant.label] > 1)
        ))
      if (aIsFirst) {
        return bIsFirst ? 0 : -1
      }
      return bIsFirst ? 1 : 0
    })
  ]
}

export const getGroupedQueue = params => {
  const { queue } = params
  const [first] = queue
  const queueItemSort = ([, aGroup], [, bGroup]) => {
    const [a] = aGroup
    const [b] = bGroup
    const aMatchesFirst = a.item.id === first.item.id && a.license === first.license
    const bMatchesFirst = b.item.id === first.item.id && b.license === first.license
    let sortValue = 0
    if (aMatchesFirst && !bMatchesFirst) {
      sortValue = -1
    }
    if (bMatchesFirst && !aMatchesFirst) {
      sortValue = 1
    }

    return sortValue
  }
  const groupedQueueByLicenseAndItem = queueGrouper(queue)
  return Object.entries(groupedQueueByLicenseAndItem).sort(([a], [b]) => {
    const aMatchesFirst = a === first.item.category || a === String(first.item.id)
    const bMatchesFirst = b === first.item.cbtegory || b === String(first.item.id)

    let sortValue = 0
    if (aMatchesFirst && !bMatchesFirst) {
      sortValue = -1
    }
    if (bMatchesFirst && !aMatchesFirst) {
      sortValue = 1
    }

    return sortValue
  }).flatMap(group => {
    const [categoryOrItem, queued] = group

    if (categoryOrItem === 'WET') {
      const wetGroups = wetGrouper(queued)
      return Object.entries(wetGroups).sort(queueItemSort).flatMap((wetGroup, index) => {
        const [wetGroupId, wetQueued] = wetGroup
        return processGroup(
          params,
          [index ? `${wetGroupId}-${categoryOrItem}` : categoryOrItem, wetQueued],
          !index
        )
      })
    }
    const processedGroups = processedGrouper(queued)
    return Object.entries(processedGroups).sort(queueItemSort).flatMap((procGroup, index) => {
      const [procGroupId, procQueued] = procGroup
      return processGroup(
        params,
        [index ? `${procGroupId}-${categoryOrItem}` : categoryOrItem, procQueued],
        !index
      )
    })
  })
}

const headerFields = ['headingSymbol', 'totalPlants', 'totalWeight']
export const isHeader = queueItem => headerFields.some(field => Boolean(queueItem[field]))

export const getItemSizer = ({ itemList: queue, selectedHarvestBatch }) => index => {
  const { [index]: item } = queue

  let height = 65
  if (isHeader(item ?? EMPTY_OBJECT)) {
    height = item.includeRoomChooser ? 140 : 80
  }

  if (selectedHarvestBatch?.id && selectedHarvestBatch?.id === item?.id) {
    height += 17
  }

  return height
}

export const groupQueueByCultivar = groupBy(queued => queued.cultivar)
