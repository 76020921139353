import ms from 'milliseconds'

// This needs to be updated when we make breaking changes to the shape of redux data
export const REQUEST_ABORTED = Object.freeze({ __ID__: Symbol.for('REQUEST_ABORTED') })
export const LONG_SPINNER_TIMEOUT = 5000
export const MAX_CHART_DAYS = 366
export const QUICK_STALE_AFTER = ms.minutes(5)
export const EULA_URLS = {
  DEFAULT: 'https://addium.io/en/company/subscription-agreement',
  GO: 'https://aroyago.io/license-agreement/',
}
export const TERMS_AND_CONDITIONS_URL = 'https://aroya.io/en/company/terms-and-conditions'
