import getGlobal from '~/src/Lib/getGlobal'
import safeStorage from '~/src/Lib/safeStorage'

const localStorage = safeStorage('local')

const LB_TO_G = 453.59237
const WEIGHT_CONVERSIONS = {
  g: {
    mg: 1e3,
    // g
    oz: 16 / LB_TO_G,
    lb: 1 / LB_TO_G,
    kg: 1 / 1e3,
  },
  kg: {
    mg: 1e6,
    g: 1e3,
    oz: 16 * (1e3 / LB_TO_G),
    lb: 1e3 / LB_TO_G,
    // kg
  },
  mg: {
    // mg
    g: 1 / 1e3,
    oz: 16 / (LB_TO_G * 1e3),
    lb: 1 / (LB_TO_G * 1e3),
    kg: 1 / 1e6,
  },
  lb: {
    mg: LB_TO_G * 1e3,
    g: LB_TO_G,
    oz: 16,
    // lb
    kg: LB_TO_G / 1e3,
  },
  oz: {
    mg: (LB_TO_G / 16) * 1e3,
    g: LB_TO_G / 16,
    // oz
    lb: 0.0625,
    kg: (LB_TO_G / 16) / 1e3,
  },
}
export const WEIGHT_UNIT_SYMBOLS = Object.keys(WEIGHT_CONVERSIONS)

/**
 * Converts between g, kb, lb, oz
 * @param {number} value
 * @param {string} sourceUnit
 * @param {string} targetUnit
 */
export const convertWeight = (value, sourceUnit, targetUnit = sourceUnit) => {
  if (sourceUnit === targetUnit) return value
  const ratio = WEIGHT_CONVERSIONS[sourceUnit]?.[targetUnit]
  if (!ratio) {
    console.error(`invalid source (${sourceUnit}) or target (${targetUnit}) unit`)
    return NaN
  }

  return value * ratio
}
if (localStorage.debug === 'true') {
  getGlobal().convertWeight = convertWeight
}
