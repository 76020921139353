import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { calculateSumFromPhaseData } from '../Analytics/utils'
import urls from './urls'

const harvestTasksBundle = createAsyncResourceBundle({
  name: 'harvestTasks',
  actionBaseType: 'HARVEST_TASKS',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvest)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvest})`))
    }
    const query = store.selectHarvestAnalyticsQueryParams()
    return apiFetch(`/harvests/${harvest}/tasks/`, query)
  },
})

export default {
  ...harvestTasksBundle,
  reactHarvestTasksFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestTasksShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics) {
        return { actionCreator: 'doFetchHarvestTasks' }
      }
      return undefined
    },
  }),
  selectHarvestTasksChart: createSelector(
    'selectPhasesByHarvest',
    'selectHarvests',
    'selectHarvestTasks',
    (phasesByHarvest, harvests, harvestTasks) => {
      if (!harvestTasks || !phasesByHarvest[harvestTasks.id] || !harvests[harvestTasks.id]) {
        return EMPTY_OBJECT
      }
      const { id: harvestId, currentTasks, historicalTasks } = harvestTasks
      const harvest = harvests[harvestId]
      const maxTasks = [
        ...Object.values(currentTasks).flat(),
        ...Object.values(historicalTasks).flat()
      ].reduce((max, { y }) => Math.max(max, y), 0)
      const domain = {
        x: [getDateTime(harvest.startDate).toJSDate(), getDateTime(harvest.endDate).toJSDate()],
        y: [0, maxTasks],
      }
      const data = { current: [], historical: [] }

      let currentPhaseType = ''
      let phaseStartOffset = null
      let phaseTypeStartDate = null
      phasesByHarvest[harvestId].forEach(({ id, phaseType, startDate, totalDays }) => {
        if (phaseType !== currentPhaseType) {
          currentPhaseType = phaseType
          phaseStartOffset = 0
          phaseTypeStartDate = startDate
        }
        const completedDuringPhase = ({ x }) => x >= phaseStartOffset && x < (phaseStartOffset + totalDays)
        const augmentData = datum => ({
          ...datum,
          x: getDateTime(phaseTypeStartDate).plus({ days: datum.x }).toJSDate(),
          phase: id,
          phaseType,
        })
        const sortByX = (a, b) => a.x - b.x
        data.current = data.current.concat(currentTasks[phaseType].filter(completedDuringPhase).map(augmentData).sort(sortByX))
        data.historical = data.historical.concat(historicalTasks[phaseType].filter(completedDuringPhase).map(augmentData).sort(sortByX))
        phaseStartOffset += totalDays
      })

      return { data, domain }
    },
  ),
  selectHarvestTasksByPhase: createSelector(
    'selectPhasesByHarvest',
    'selectHarvestTasks',
    (phasesByHarvest, harvestTasks) => {
      if (!harvestTasks || !phasesByHarvest[harvestTasks.id]) {
        return EMPTY_OBJECT
      }
      const { id: harvestId, currentTasks, historicalTasks } = harvestTasks
      const phases = phasesByHarvest[harvestId]
      const maxTasks = [
        ...Object.values(currentTasks).flat(),
        ...Object.values(historicalTasks).flat()
      ].reduce((max, { y }) => Math.max(max, y), 0)

      let currentPhaseType = ''
      let phaseStart = null
      let stageStartDate = null
      return phases.reduce((tbp, { id, phaseType, startDate, totalDays }) => {
        if (phaseType !== currentPhaseType) {
          currentPhaseType = phaseType
          phaseStart = 0
          stageStartDate = startDate
        }
        const nextPhaseStart = phaseStart + totalDays
        const completedDuringPhase = ({ x }) => (
          x >= phaseStart && x < nextPhaseStart
        )

        const phase = {
          id,
          domain: { x: [phaseStart, nextPhaseStart - 1], y: [0, maxTasks] },
          currentTasks: currentTasks[phaseType].filter(completedDuringPhase),
          historicalTasks: historicalTasks[phaseType].filter(completedDuringPhase).sort((a, b) => a.x - b.x),
          stageStartDate,
        }

        phaseStart = nextPhaseStart
        return { ...tbp, [id]: phase }
      }, EMPTY_OBJECT)
    },
  ),
  selectHarvestTasksCompleted: createSelector(
    'selectHarvestTasks',
    harvestTasks => (harvestTasks ? ({
      current: calculateSumFromPhaseData(harvestTasks.currentTasks),
      historical: calculateSumFromPhaseData(harvestTasks.historicalTasks),
    }) : EMPTY_OBJECT)
  ),
}
