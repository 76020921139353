import { useConnect } from 'redux-bundler-hook'

import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'

/**
 * Returns a boolean based on the test function, selections, and props.
 * @param {function(string[], Object<string, any>): boolean} test
 * @param {?string[]} selectors
 * @param {?Object<string, any>} props
 * @returns {boolean}
 */
export const useLock = (test, selectors = EMPTY_ARRAY, props = EMPTY_OBJECT) => {
  if (typeof test !== 'function') {
    throw new TypeError('useLock requires a test function')
  }
  const selections = useConnect(...selectors)
  const shouldLock = test(selections, props)

  return Boolean(shouldLock)
}
