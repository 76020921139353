import { memo } from 'react'

import i18n from 'i18n-literally'

import { PanTool as HaltIcon } from '@mui/icons-material'

import T from '~/src/UI/Shared/Typography'

const containerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  placeContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
}

const fullWidth = { flex: '0 0 100%', textAlign: 'center' }
const iconStyle = { fontSize: 72 }
const FourOhThreeComponent = ({ routeInfo }) => (
  <div style={containerStyle} data-testid="aroya-permission-denied-route">
    <div style={fullWidth}><HaltIcon color="error" style={iconStyle} /></div>
    <T.H1 gutterBottom className="mar-t-lg" style={fullWidth}>
      {i18n`You don't have permission to access ${routeInfo.url}.`}
    </T.H1>
    <T.H3 align="center" style={fullWidth}>
      {i18n`If you believe that you should have permission, please contact your supervisor.`}
    </T.H3>
  </div>
)

const Memoized = memo(FourOhThreeComponent)

export default Memoized
