import appConfig from '~/src/App/config'

export const configured = ({ config = appConfig, setting, test }) => {
  const haveSetting = setting !== null
  const haveTest = test !== null
  if (haveSetting && haveTest) {
    return config[setting] && test(config)
  }
  if (haveSetting) return config[setting]
  if (haveTest) return test(config)
  return true
}
