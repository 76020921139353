import i18n from 'i18n-literally'
import { prop } from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  camelize,
  humanize,
  inflect,
  pluralize,
  titleize,
  underscore,
} from 'inflection'

import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_OBJECT,
  formattedNumber,
  memoize,
  noop,
} from '~/src/Lib/Utils'

const logger = createLogger('Inventory/utils')

export const INVENTORY_PERMISSIONS = ['view_package', 'view_plant']

export const getSelectedItemsForHarvest = selected => selected.reduce(
  (p, c) => {
    const [type] = c.uuid.split('_')
    p[type] ??= []
    p[type].push(c.id)
    return p
  },
  { harvestBatches: [], packages: [], plantBatches: [], plants: [] }
)

export const getInventoryPermits = permittedActions => INVENTORY_PERMISSIONS.some(p => permittedActions.has(p))

export const getSelectMap = ({ id, name }) => ({
  label: name,
  value: id,
})

const FILTER_LABEL_MAP = {
  cultivars: i18n`cultivars`,
  grades: i18n`grades`,
  harvests: i18n`harvests`,
  items: i18n`items`,
  licenses: i18n`licenses`,
  rooms: i18n`rooms`,
  types: i18n`types`,
}
export const getAll = (label, value = null) => ({
  label: `All ${FILTER_LABEL_MAP[label] ?? label}`,
  value
})

export const getTypeName = types => (types?.includes('plant') ? 'plants' : 'packages')

export const getRoomFilters = types => (Array.isArray(types) ? types : [types]).map(t => {
  if (t === 'harvestBatch') {
    return 'forHarvests'
  }
  return `for${pluralize(camelize(t))}`
})

const SELECTED_SUBSTITUTIONS = {
  plantBatches: 'plantLots'
}

export const getInventorySchemaName = schema => SELECTED_SUBSTITUTIONS[schema] ?? schema

export const getForSelected = (selected, singleVerbose = false) => {
  if (selected.length === 1 && singleVerbose) {
    const { name, label, uuid } = selected[0]
    const schema = getInventorySchemaName(uuid.split('_').shift())

    return label || name || humanize(underscore(inflect(schema, 1)))
  }
  const map = selected.reduce((p, c) => {
    const schema = getInventorySchemaName(c.uuid.split('_').shift())

    if (p[schema]) {
      p[schema] += 1
    } else {
      p[schema] = 1
    }
    return p
  }, {})

  const list = Object.keys(map).map(
    key => `${map[key]} ${humanize(underscore(inflect(key, map[key])), true)}`
  )
  const str = `${list.join(list.length > 1 ? ' & ' : '')}`

  return str
}

/* istanbul ignore next */
export const getTableTheme = theme => ({
  ...theme,
  palette: {
    ...theme.palette,
    secondary: {
      ...theme.palette.secondary,
      main: theme.palette.primary.main,
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: theme.palette.primary.main,
      },
    },
  },
})

const RAW_VALUE_BY_ITEM_TYPE = {
  harvestBatches: prop('currentWeight'),
  packages: prop('currentQuantity'),
  plantBatches: prop('untrackedCount'),
  plants: prop('wetWeight'),
}
export const getInventoryItemRawValue = ({
  uuid = '',
  ...props
}) => {
  const [schema] = uuid.split('_')
  const { [schema]: getValue = noop } = RAW_VALUE_BY_ITEM_TYPE

  return getValue(props) ?? 0
}

export const getInventoryItemName = memoize(
  (props = EMPTY_OBJECT, { items = EMPTY_OBJECT, currentPhaseByHarvest = EMPTY_OBJECT } = EMPTY_OBJECT) => {
    const { item, plantType, growthPhase, harvest } = props
    const { [harvest]: currentPhase } = currentPhaseByHarvest
    const { [item]: itemData } = items

    let name = itemData?.name || plantType || growthPhase
    if (plantType && currentPhase?.phaseType) {
      name = `${currentPhase.phaseType} ${name}`
    }

    if (props.mother) {
      return i18n`Mother`
    }

    return name ? titleize(name) : null
  }
)

export const useInventoryItemName = props => getInventoryItemName(
  props,
  useConnect('selectItems', 'selectCurrentPhaseByHarvest')
)

export const getInventoryPlantQuantity = ({ uuid = '', untrackedCount, currentQuantity }) => {
  if (uuid.startsWith('plants')) return 1
  return (
    uuid.startsWith('package') ? currentQuantity : untrackedCount
  ) ?? null
}

export const getInventoryItemValue = (props, { units }) => {
  const {
    quantityUnit,
    weightUnit,
    wetWeightUnit,
  } = props

  const value = getInventoryItemRawValue(props)

  const {
    [wetWeightUnit ?? weightUnit ?? quantityUnit]: unit = EMPTY_OBJECT
  } = units

  return value != null
    ? formattedNumber(
      value,
      unit.precision ?? 0,
      unit.symbol ? { suffix: unit.symbol } : undefined
    )
    : null
}

export const addSeparator = (list, index, separator = ',') => index !== list.length - 1 && list.length > 1 && separator

export const useInventoryItemValue = props => getInventoryItemValue(props, useConnect('selectUnits'))

export const getListStyles = theme => ({
  list: {
    maxHeight: 150,
    overflowY: 'auto',
    marginBottom: 20,
  },
  item: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: 4,
    background: theme.palette.primary.enabled,
  },
})
/* istanbul ignore next */
export const FormHelperTextProps = {
  style: { position: 'absolute', bottom: '-22px', whiteSpace: 'nowrap' },
}

export const getErrorProps = (key, errors, touched) => ({
  error: (touched ? touched[key] : true) && !!errors[key],
  helperText: (touched ? touched[key] : true) && errors[key]
})

export const isInventoryUID = (schema, { currentFacility }) => {
  const harvestBatchItem = schema === 'harvestBatches'
  const plantBatchItem = schema === 'plantBatches'

  if (harvestBatchItem) {
    return false
  }
  if (plantBatchItem && currentFacility?.behaviors?.tagPlantBatches === false) {
    return false
  }

  return true
}

// Returns an array of uuids from all event errors on the compliance page
export const getDirtyUUIDS = dirtyEvents => (
  Object.values(dirtyEvents).flatMap(dirtyEvent => {
    if (!Array.isArray(dirtyEvent)) return null
    if (!dirtyEvent.length || typeof dirtyEvent[0] === 'string') return null
    return dirtyEvent.map(error => error?.uuid)
  })
)

// Returns an array of event ids that have an error on the compliance page
export const getDirtyEventIds = ({ isComplianceInbox, syncErrors }) => (
  isComplianceInbox && syncErrors ? Object.keys(syncErrors) : null
)

// Returns an event id given an error from the compliance page
export const getDirtyEventId = ({ error, isComplianceInbox, syncErrors }) => {
  const dirtyEventIds = getDirtyEventIds({ isComplianceInbox, syncErrors })
  return dirtyEventIds.flatMap(dirtyEventId => {
    if (!syncErrors[dirtyEventId]) return null
    return (
      syncErrors[dirtyEventId].map(err => {
        if (err.uuid === error?.uuid) return dirtyEventId
        return null
      })
    )
  }).filter(Boolean)[0]
}

// Matches a current event sync error entity up with a given uuid
export const getCurrentError = ({ isComplianceInbox, syncErrors, uuid }) => {
  const dirtyEventIds = getDirtyEventIds({ isComplianceInbox, syncErrors })
  const dirtyUUIDS = () => (dirtyEventIds?.length ? getDirtyUUIDS(syncErrors) : null)
  const uuidWithError = dirtyUUIDS()?.find(dirtyUUID => dirtyUUID === uuid)
  return syncErrors && Object.values(syncErrors).flatMap(event => {
    if (!event) return null
    return event.map(err => (err?.uuid === uuidWithError ? err : null))
  }).filter(Boolean)[0]
}

// Chooses error and badge styles depending on error type
export const getErrorStyles = ({ classes, error }) => {
  if (error?.errorType === 'INVALID_TAG_ERROR') {
    return {
      badge: classes.errorBadge,
      error: classes.error
    }
  }
  if (error?.errorType === 'PLANT_ALREADY_DESTROYED' || error?.errorType === 'PLANT_ALREADY_HARVESTED') {
    return {
      badge: classes.warningBadge,
      error: classes.warning
    }
  }
  return EMPTY_OBJECT
}
