/**
 * Merges additional chart data (B) into the primary chart data object (A).
 */
export const mergeChartData = (dataA, dataB) => (dataA && dataB ? {
  ...dataA,
  bounds: {
    dataType: {
      ...dataA.bounds.dataType,
      ...dataB.bounds.dataType,
    },
    graph: { ...dataA.bounds.graph, ...dataB.bounds.graph },
    unit: {
      ...dataA.bounds.unit,
      ...Object.entries(dataB.bounds.unit).reduce((acc, [unitId, { min, max }]) => {
        if (min == null || max == null) return acc
        if (!(unitId in dataA.bounds.unit)) {
          return { ...acc, [unitId]: { min, max } }
        }
        const { [unitId]: { min: existingMin, max: existingMax } } = dataA.bounds.unit
        return {
          ...acc,
          [unitId]: { min: Math.min(min, existingMin), max: Math.max(max, existingMax) },
        }
      }, {})
    },
  },
  data: { ...dataA.data, ...dataB.data },
  dataTypes: [...dataA.dataTypes, ...dataB.dataTypes],
  graphs: [...dataA.graphs, ...dataB.graphs],
  latest: { ...dataA.latest, ...dataB.latest },
} : dataA ?? dataB)
