import {
  always,
  both,
  compose,
  either,
  evolve,
  filter,
  identity,
  into,
  map,
  omit,
  pick,
  pipe,
  prop,
  uniq,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import {
  camelize,
  humanize,
  inflect,
  underscore,
} from 'inflection'
import ms from 'milliseconds'
import { normalize } from 'normalizr'

import {
  DRYING_FLOW_ROUTE,
  HARVESTING_FLOW_ROUTE,
  LOT_TO_PLANT_FLOW_ROUTE,
  PROCESSING_FLOW_ROUTE,
  VEG_TO_FLOWER_FLOW_ROUTE,
} from '~/src/Flow/constants'
import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import createListBundle from '~/src/Lib/createListBundle'
import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formatList,
  isValidParam,
  parseApiErrors,
} from '~/src/Lib/Utils'
import {
  Harvest,
  HarvestBatch,
  InventoryItem,
  Package,
  Plant,
  PlantBatch,
} from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { getInventoryPermits } from '../utils'

const logger = createLogger('createInventoryBundle')

const truthyOrNil = either(identity, always(null))
const arrayIdOrNil = either(
  // if an array, filter out falsey values and return null if empty
  both(
    Array.isArray,
    pipe(
      filter(Boolean),
      uniq,
      either(
        both(prop('length'), identity),
        always(null)
      )
    )
  ),
  // otherwise return truthy values or null
  truthyOrNil
)

const PARAMS_TRANSFORMS = {
  search: truthyOrNil,
  types: arrayIdOrNil,
  harvests: arrayIdOrNil,
  cultivars: arrayIdOrNil,
  rooms: arrayIdOrNil,
  items: arrayIdOrNil,
  itemCategories: arrayIdOrNil,
  grades: arrayIdOrNil,
  licenses: arrayIdOrNil,
  packageType: value => (value === 'growing' || value === 'harvested' ? value : null),
}

const getError = err => parseApiErrors(err) || 'An unexpected error occurred.'

const getSuccessMessage = data => `Successfully updated ${formatList(Object.keys(data).map(key => humanize(underscore(key), true)))}`

export const getInventoryList = (list, entities, itemFilter = Boolean) => into(
  [],
  compose(map(({ uuid }) => entities[uuid]), filter(itemFilter)),
  list.results ?? list ?? EMPTY_ARRAY
)

export const initialInventoryState = {
  search: '',
  types: ['plant', 'plantBatch'],
  harvests: null,
  cultivars: null,
  rooms: null,
  items: null,
  itemCategories: null,
  hideFinished: true,
  ordering: '-modifiedOn',
  grades: null,
  licenses: null,
  packageType: null,
}

const initialPaginationState = {
  page: 1,
  pageSize: 50,
}

const paramsPicker = pick(Object.keys({ ...initialInventoryState, ...initialPaginationState }))

export const createInventoryBundle = (
  entityName = 'inventory',
  { initialState: initial, name = `${entityName}List`, ...opts } = EMPTY_OBJECT
) => {
  const initialState = { ...initialInventoryState, ...initial }
  const camelName = camelize(name ?? `${entityName}List`)
  const { [`react${camelName}Fetch`]: reactFetch, ...listBundle } = createListBundle({
    actions: [],
    entityName,
    name,
    initialState,
    permissions: { any: true, keys: [getInventoryPermits] },
    schema: InventoryItem,
    fetchHandler: ({ listState, apiFetch }) => {
      const isProductionInventory = entityName === 'inventory'
      let params = pick(Object.keys(initialState), listState)
      const noScrollPagination = Boolean(listState.rooms || listState.harvests)

      if (isProductionInventory && !listState.page) {
        params = { ...params, page: 1 }
      }

      if (isProductionInventory && noScrollPagination) {
        params = { ...params, page: null, pageSize: null }
      }

      const query = Object.entries(params).reduce((q, [key, value]) => {
        if (isValidParam(value)) {
          return ({
            ...q,
            [key]: value,
          })
        }
        return q
      }, EMPTY_OBJECT)
      return apiFetch('/inventory/', { ...query })
    },
    staleAfter: ms.minutes(15),
    retryAfter: ms.seconds(5),
    ...opts,
  })
  logger.debug('creating inventory bundle for', listBundle.name, 'with initial state', initialState)

  const { [`do${camelName}SetParams`]: setParams } = listBundle

  const metrcDataTypes = ['plant', 'batch', 'harvest', 'package']

  return {
    ...listBundle,
    [`do${camelName}SetParams`]: pipe(setParams, evolve(PARAMS_TRANSFORMS)),
    [`selectCurrent${camelName}`]: createSelector(
      `select${camelName}`,
      'selectEntities',
      (list, entities) => (list?.results?.length ?? list?.length
        ? getInventoryList(list, entities)
        : EMPTY_ARRAY)
    ),
    [`select${camelName}Params`]: createSelector(
      `select${camelName}Raw`,
      pick(Object.keys(initialState))
    ),
    [`react${camelName}Fetch`]: createAppIsReadySelector({
      dependencies: [
        'selectCurrentMembership',
        `select${camelName}ShouldUpdate`,
        `select${camelName}Raw`,
        'selectCurrentMetrcFacilities',
        'selectIsOnline',
        'selectUrlObject',
        'selectRouteInfo',
      ],
      resultFn: (
        currentMembership,
        shouldUpdate,
        listRaw,
        metrcFacilities,
        isOnline,
        urlObject,
        routeInfo
      ) => {
        const fetchAction = reactFetch.resultFn(currentMembership, true, urlObject, routeInfo)
        const canFetch = Boolean(fetchAction)
        let urlMatch = true
        if ('urlTest' in opts) {
          const url = urlObject.href.replace(urlObject.origin, '')
          urlMatch = opts.urlTest(url, routeInfo ? (routeInfo.pattern ?? '') : '')
        }
        const { failedPermanently, lastSuccess, isLoading } = listRaw
        if (failedPermanently || !isOnline || !currentMembership || !canFetch || !urlMatch || isLoading) return null

        if (shouldUpdate) {
          return fetchAction
        }

        const syncedSinceLastFetch = metrcFacilities.some(
          metrcFacility => {
            const dataTypeNeedsUpdating = metrcDataTypes.some(metrcDataType => {
              const updateDateType = (
                metrcFacility[`last${camelize(metrcDataType)}Refresh`]
                && lastSuccess < new Date(metrcFacility[`last${camelize(metrcDataType)}Refresh`])
              )
              return updateDateType
            })
            return dataTypeNeedsUpdating
          }
        )

        if (syncedSinceLastFetch) {
          return fetchAction
        }

        return null
      }
    })
  }
}

const { doInventoryListSetParams: setParams, ...initialBundle } = createInventoryBundle('inventory', {
  initialState: initialPaginationState,
  urlTest: (url, pattern = '') => pattern.startsWith('/inventory') || url.includes(DRYING_FLOW_ROUTE) || url.includes(PROCESSING_FLOW_ROUTE)
})

export default {
  ...initialBundle,
  doInventoryListSetParams: (params, meta) => ({ store, ...kwargs }) => {
    const { queryObject, routeInfo } = store.select(['selectQueryObject', 'selectRouteInfo'])
    if (routeInfo.pattern === '/inventory') {
      const { view } = queryObject
      return setParams({ ...params, packageType: view ? view.toLowerCase() : null }, meta)({ ...kwargs, store })
    }
    return setParams(params, meta)({ ...kwargs, store })
  },
  selectCurrentInventory: createSelector(
    'selectDialogRouteInfo',
    'selectRouteInfo',
    (dialogRoute, route) => {
      if (dialogRoute && dialogRoute.params?.id) {
        const isHarvest = [
          DRYING_FLOW_ROUTE,
          HARVESTING_FLOW_ROUTE,
          PROCESSING_FLOW_ROUTE,
          VEG_TO_FLOWER_FLOW_ROUTE,
          LOT_TO_PLANT_FLOW_ROUTE,
        ].some(url => dialogRoute.pattern.startsWith(url))
        if (isHarvest) return 'harvestInventory'
      }

      if (route.pattern.match(/harvests\/:id/)) {
        return 'harvestInventory'
      }

      if (route.pattern.match(/rooms\/:id/)) {
        return 'roomInventory'
      }

      return 'globalInventory'
    }
  ),
  selectCurrentInventoryList: createSelector(
    'selectEntities',
    'selectCurrentInventory',
    'selectInventoryList',
    'selectHarvestInventory',
    'selectRoomInventory',
    (entities, whichInventory, globalInventory, harvestInventory, roomInventory) => {
      const lists = { globalInventory, harvestInventory, roomInventory }
      const { [whichInventory]: list } = lists

      return list ? getInventoryList(list, entities) : EMPTY_ARRAY
    }
  ),
  selectCurrentInventoryIsLoading: createSelector(
    'selectCurrentInventory',
    'selectInventoryListIsLoading',
    'selectHarvestInventoryIsLoading',
    'selectRoomInventoryIsLoading',
    (whichInventory, globalInventory, harvestInventory, roomInventory) => {
      const options = { globalInventory, harvestInventory, roomInventory }
      const { [whichInventory]: isLoading } = options

      return isLoading
    }
  ),
  selectCurrentInventoryParams: createSelector(
    'selectCurrentInventory',
    'selectInventoryListParams',
    'selectHarvestInventoryParams',
    'selectRoomInventoryParams',
    (whichInventory, globalInventory, harvestInventory, roomInventory) => {
      const options = { globalInventory, harvestInventory, roomInventory }
      const { [whichInventory]: params } = options

      return params
    }
  ),
  selectCurrentInventoryRaw: createSelector(
    'selectCurrentInventory',
    'selectInventoryListRaw',
    'selectHarvestInventoryRaw',
    'selectRoomInventoryRaw',
    (whichInventory, globalInventory, harvestInventory, roomInventory) => {
      const options = { globalInventory, harvestInventory, roomInventory }
      const { [whichInventory]: raw } = options

      return raw
    }
  ),
  selectCurrentInventoryMetadata: createSelector(
    'selectCurrentInventoryRaw',
    root => (Array.isArray(root.data) ? EMPTY_OBJECT : omit(['results'], root.data ?? EMPTY_OBJECT))
  ),
  doCurrentInventorySetParams: (...args) => ({ store, dispatch }) => {
    const whichInventory = store.selectCurrentInventory()

    let actionCreator = 'doInventoryListSetParams'
    if (whichInventory.startsWith('harvest')) {
      actionCreator = 'doHarvestInventorySetParams'
    }
    if (whichInventory.startsWith('room')) {
      actionCreator = 'doRoomInventorySetParams'
    }

    dispatch({ actionCreator, args })
  },
  doCurrentInventoryFetch: () => ({ store, dispatch }) => {
    const whichInventory = store.selectCurrentInventory()

    let actionCreator = 'doFetchInventoryList'
    if (whichInventory.startsWith('harvest')) {
      actionCreator = 'doFetchHarvestInventory'
    }
    if (whichInventory.startsWith('room')) {
      actionCreator = 'doFetchRoomInventory'
    }

    dispatch({ actionCreator })
  },
  doCurrentInventoryMarkOutdated: () => ({ store, dispatch }) => {
    const whichInventory = store.selectCurrentInventory()

    let actionCreator = 'doMarkInventoryListAsOutdated'
    if (whichInventory.startsWith('harvest')) {
      actionCreator = 'doMarkHarvestInventoryAsOutdated'
    }
    if (whichInventory.startsWith('room')) {
      actionCreator = 'doMarkRoomInventoryAsOutdated'
    }

    dispatch({ actionCreator })
  },
  doFetchPartOfInventoryList: apiParams => async ({ apiFetch }) => {
    const response = await apiFetch('/inventory', apiParams)
    // const { entities } = normalize(response, [InventoryItem])
    // TODO: add some error handling
    return response.map(({ uuid, schema, data }) => ({ uuid, schema, ...data }))
  },
  doInventoryFetchPage: ({ page }) => async ({ store, dispatch, apiFetch }) => {
    const { data } = store.selectCurrentInventoryRaw()
    const apiParams = paramsPicker(store.selectCurrentInventoryParams())
    try {
      const response = await apiFetch('/inventory/', {
        ...apiParams,
        page,
      })
      const { entities } = normalize(response.results, [InventoryItem])
      dispatch(doEntitiesReceived(entities, { replace: false }))
      dispatch({
        type: 'INVENTORY_LIST_FETCH_FINISHED',
        payload: {
          ...data,
          ...response,
          results: [
            ...data.results,
            ...response.results,
          ]
        }
      })
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
    }
  },
  doPackageSplit: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/packages/${id}/split/`, req, { method: 'POST' })
      store.doPackageFetch(id)
      store.doAddSnackbarMessage('Successfully split package')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantsDestroy: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/plants/destroy_plant/', payload, { method: 'PUT' })
      if (payload.length === 1) {
        const [ent] = payload
        store.doPlantFetch(ent.id)
      }
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage([
        `Successfully destroyed ${inflect('plant', payload?.length ?? 1)}`,
        Array.isArray(payload)
          ? formatList(res.map(plant => plant.label && plant.label.slice(-6)).filter(Boolean))
          : ''
      ].filter(Boolean).join('\n'))
      return res
    } catch (error) {
      console.error(error)
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantsSoftDestroy: payload => async ({ apiFetch, store }) => {
    try {
      const res = await Promise.all(
        payload.map(async ({ id }) => {
          const response = await apiFetch(`/plants/${id}/soft_delete/`, null, { method: 'DELETE' })
          return response
        })
      )
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage([
        `Successfully destroyed ${inflect('plant', payload?.length ?? 1)}`,
        Array.isArray(payload)
          ? formatList(payload.map(plant => plant.label && plant.label.slice(-6)).filter(Boolean))
          : ''
      ].filter(Boolean).join('\n'))
      return res
    } catch (error) {
      console.error(error)
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantBatchDestroy: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/plantBatches/${id}/destroy_plantbatch/`, req, { method: 'PUT' })
      store.doPlantBatchFetch(id)
      store.doAddSnackbarMessage('Successfully destroyed plant lot')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPackagesRoomUpdate: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/packages/room/', payload, { method: 'PUT' })
      if (payload.packages.length === 1) {
        const [id] = payload.packages
        store.doPackageFetch(id)
      }
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage(`Successfully moved ${inflect('packages', payload?.packages.length ?? 1)}`)
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPackagesGradeUpdate: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/packages/grade/', payload, { method: 'PUT' })
      if (payload.packages.length === 1) {
        const [id] = payload.packages
        store.doPackageFetch(id)
      }
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage(`Successfully graded ${inflect('packages', payload?.packages?.length ?? 1)}`)
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPackageQuantityAdjust: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/packages/${id}/quantity/`, req, { method: 'PUT' })
      store.doPackageFetch(id)
      store.doAddSnackbarMessage('Successfully adjusted package quantity')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPackageLabResultAdd: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/packages/${id}/lab_results/`, req, { method: 'POST' })
      store.doPackageFetch(id)
      store.doAddSnackbarMessage('Successfully added lab result(s)')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doHarvestBatchWaste: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/harvestBatches/${id}/waste/`, req, { method: 'POST' })
      store.doHarvestBatchFetch(id)
      store.doAddSnackbarMessage('Successfully recorded waste')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doHarvestBatchesWaste: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/harvestBatches/waste/', payload, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully recorded waste')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doRoomWaste: payload => async ({ apiFetch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/rooms/${id}/waste/`, req, { method: 'POST' })
      store.doRoomFetch(id)
      store.doAddSnackbarMessage('Successfully recorded waste')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantWaste: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/plants/waste/', payload, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully recorded waste')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantBatchWaste: payload => async ({ apiFetch, store }) => {
    try {
      const res = await apiFetch('/plantBatches/waste/', payload, { method: 'POST' })
      store.doAddSnackbarMessage('Successfully recorded waste')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doItemsAddToHarvest: payload => async ({ apiFetch, store, dispatch }) => {
    try {
      const { id, harvestName, ...req } = payload
      const res = await apiFetch(`/harvests/${id}/associate/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, Harvest)?.entities))
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage(`Successfully added plants to ${harvestName}`)
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doItemsRemoveFromHarvest: payload => async ({ apiFetch, store, dispatch }) => {
    try {
      const { id, harvestName, ...req } = payload
      const res = await apiFetch(`/harvests/${id}/dissociate/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, Harvest)?.entities))
      store.doCurrentInventoryMarkOutdated()
      store.doAddSnackbarMessage(`Successfully removed plants from ${harvestName}`)
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantEdit: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/plants/${id}/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, Plant)?.entities))
      store.doAddSnackbarMessage(getSuccessMessage(req))
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPackageEdit: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/packages/${id}/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, Package)?.entities))
      store.doAddSnackbarMessage(getSuccessMessage(req))
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doHarvestBatchEdit: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/harvestBatches/${id}/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, HarvestBatch)?.entities))
      store.doAddSnackbarMessage(getSuccessMessage(req))
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantBatchEdit: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const { id, ...req } = payload
      const res = await apiFetch(`/plantBatches/${id}/`, req, { method: 'PUT' })
      dispatch(doEntitiesReceived(normalize(res, PlantBatch)?.entities))
      store.doAddSnackbarMessage(getSuccessMessage(req))
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantsMotherUpdate: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const res = await apiFetch('/plants/update_mother/', payload, { method: 'POST' })
      dispatch(doEntitiesReceived(normalize(res, [Plant])?.entities))
      store.doAddSnackbarMessage('Successfully recorded as mother')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  },
  doPlantBatchMotherUpdate: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const res = await apiFetch('/plantBatches/update_mother/', payload, { method: 'POST' })
      dispatch(doEntitiesReceived(normalize(res, [PlantBatch])?.entities))
      store.doAddSnackbarMessage('Successfully recorded as mother')
      return res
    } catch (error) {
      store.doAddSnackbarMessage(getError(error))
      return false
    }
  }
}
