import { normalize } from 'normalizr'

import createEntityBundle, {
  doEntitiesReceived,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import { parseApiErrors } from '~/src/Lib/Utils'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Package as schema } from '~/src/Store/Schemas'

const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }
const processPackagesSnackbar = ({
  dispatch,
  status,
  // payload,
  error,
}) => {
  switch (status) {
    case 'succeed':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: ['Successfully saved packages'], // [payload.apiSuccessMessage]
      })
    case 'fail':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to process packages: ${parseApiErrors(error)}`]
      })
    default:
      return undefined
  }
}

const { types: processActionTypes } = getAsyncActionIdentifiers('process', 'packages')

const name = 'packages'
const bundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
    update: writesConfig,
  },
  customActions: [
    {
      action: 'change_tag',
      name,
      schema: [schema],
      handler: ({ payload, apiFetch }) => {
        const body = payload.map(([packageEntity, newTag]) => ({ [packageEntity.id]: newTag }))
        return apiFetch('/packages/change_tag/', body, { method: 'PUT' })
      },
      async: true,
      snackbar: ({
        status,
        dispatch,
        payload,
        response
      }) => {
        const successMessage = payload.length > 1 ? 'Invalid package tags have been replaced and updated' : `Invalid package tag ${payload[0][0].label} has been replaced with ${response[0].label}`
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [
            status === 'succeed'
              ? successMessage
              : 'Unable to update package tag'
          ]
        })
      },
    }
  ],
  scope,
  doProcessPackages: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: processActionTypes.start, payload })
    let result = {}
    try {
      result = await apiFetch('/packages/', payload, {
        method: 'POST',
      })
      dispatch({ type: processActionTypes.succeed, payload, result })
      processPackagesSnackbar({ dispatch, status: 'succeed', payload, result })
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
    } catch (error) {
      result.error = error
      dispatch({ type: processActionTypes.fail, error })
      processPackagesSnackbar({ dispatch, status: 'fail', payload, error })
    }
    return result
  },
})

export default bundle
