import { createElement, Fragment } from 'react'

import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'

import { dataTypeSort } from '~/src/DataType/utils'
import createEntityBundle from '~/src/Lib/createEntityBundle'
import { ENTITIES_RECEIVED, ENTITIES_REMOVED } from '~/src/Lib/createEntityBundle/constants'
import { NotificationPreference as schema } from '~/src/Store/Schemas'
import DataTypeName from '~/src/UI/Shared/DataTypeName'

const name = 'notificationPreferences'

const initialBundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
    snackbar: ({ action, dispatch, error, payload, store }) => {
      const { [payload.notificationType]: notificationType } = store.selectNotificationTypes()

      dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [
          createElement(Fragment, {}, [
            error ? `Failed to ${action}` : null,
            '\u00a0',
            createElement(DataTypeName, { name: notificationType.name }),
            '\u00a0',
            'notification preferences',
            !error ? `\u00a0${action}d` : null
          ].filter(Boolean)),
          error ? 'error' : 'success',
        ],
      })
    },
    url: 'membershipNotificationPreferences',
  },
})

export default {
  ...initialBundle,
  doClearNotificationPreferences: () => async ({ store, dispatch }) => {
    dispatch({
      type: ENTITIES_REMOVED,
      payload: {
        notificationPreferences: Object.keys(
          store.selectNotificationPreferences()
        ),
      },
    })
  },
  selectDataNotificationTypes: createSelector(
    'selectFacilityActiveDataTypes',
    'selectNotificationTypes',
    (facilityActiveDataTypes, notificationTypes) => {
      const allowedDataTypes = dataTypeSort(Object.values(facilityActiveDataTypes)).reduce((acc, dt) => {
        const type = notificationTypes?.[dt.key.toUpperCase()]
        const allowed = type && dt.active && !(dt.hidden || dt.disabled)
        return type && allowed ? [...acc, { ...dt, ...type }] : acc
      }, [])

      return allowedDataTypes
    }
  ),
  selectOtherNotificationTypes: createSelector(
    'selectNotificationTypes',
    types => Object.keys(types).reduce(
      (acc, item) => (types[item].dataType ? acc : [...acc, types[item]]),
      []
    )
  ),
  doNotificationPreferencesUpdateBulk: payload => async ({ apiFetch, dispatch, store }) => {
    try {
      const response = await apiFetch(
        '/membershipNotificationPreferences/bulk/',
        payload,
        { method: 'POST' },
      )
      const { entities } = normalize(response, [schema])

      dispatch({ type: ENTITIES_RECEIVED, payload: entities })
      store.doAddSnackbarMessage('Successfuly saved preferences.')
    } catch (error) {
      store.doAddSnackbarMessage('Failed to save preferences.')
    }
  },
}
