import { useEffect, useRef, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { minutes, seconds } from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'

const STALENESS_MINUTES = minutes(30)
const WAIT_BETWEEN_FETCHES = seconds(5)

// NOTE: we aren’t checking payloadType because there is only the entity type
const haveRecipeEntity = (recipeId, recipe) => recipeId === recipe?.id

const isStale = recipe => {
  if (!recipe?.fetchedAt) {
    return true
  }

  let fetchedAt = recipe.fetchedAt
  if (typeof fetchedAt !== 'number') {
    fetchedAt = getDateTime(fetchedAt)
    if (!fetchedAt.isValid) {
      return true
    }
  }

  const diff = Date.now() - fetchedAt
  return diff > 0 && diff >= STALENESS_MINUTES
}

export const useRecipeEntity = id => {
  const recipeId = Number(id)
  const {
    recipe: entity,
    recipes,
    doRecipeFetch,
  } = useConnect(
    'selectRecipe',
    'selectRecipes',
    'doRecipeFetch',
  )

  const lastTry = useRef(0)

  const [recipe, setRecipe] = useState(() => (
    haveRecipeEntity(recipeId, entity) ? entity : (recipes[id] ?? null)
  ))
  useEffect(() => {
    const now = Date.now()
    const shouldFetch = Boolean(recipeId && (
      !haveRecipeEntity(recipeId, entity) || isStale(entity) || !lastTry.current
    ))
    const shouldWait = now - WAIT_BETWEEN_FETCHES < lastTry.current

    if (shouldFetch && !entity.inflight) {
      if (shouldWait) {
        // eslint-disable-next-line no-console
        console.warn('trying to refetch too often', { recipeId, entity, now })
      } else {
        lastTry.current = now
        doRecipeFetch(recipeId)
        return
      }
    }

    setRecipe(entity)
  }, [doRecipeFetch, entity, recipeId])

  return recipe
}
