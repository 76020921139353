import { filter, identity, partial } from 'ramda'
import { createSelector } from 'redux-bundler'

import { repr } from '~/src/Lib/Utils'

/**
 * Defines a scope for limiting a selectors results based on another selection,
 * using either the provided test function or selectionProp and entityProp.
 * @typedef {Object} Scope
 * @property {string} selector - The selector to use
 * @property {function?} test - (Optional) The test function to use
 * @property {string?} selectionProp - The selection property to use in auto-test creation
 * @property {string?} entityProp - The entity property to use in auto-test creation
 * @example { selector: 'selectThings', test: (selection, entity) => selection.id === entity.thingId }
 */

/**
 * Validate a scope object. Throws if scope is invalid.
 * @param {Scope} scope
 */
export const validateScope = scope => {
  if (!scope) throw new TypeError('"scope" must be defined')

  const hasProps = typeof scope.entityProp === 'string'
  const hasTest = typeof scope.test === 'function'

  if (!hasProps && !hasTest) {
    throw new TypeError([
      'Either "scope.test" must be a function or "scope.entityProp" must be a string.',
      `Got "scope.test"=${repr(scope.test)} and "scope.entityProp"=${repr(scope.entityProp)} instead.`
    ].join(' '))
  }

  const { selector } = scope
  const selectorType = typeof selector
  if (selectorType !== 'function' && selectorType !== 'string') {
    throw new TypeError(`"scope.selector" must be a function or selector name. Got ${repr(selector)} instead.`)
  }
  if (selectorType === 'string' && !selector.startsWith('select')) {
    throw new TypeError(`"scope.selector" must be a selector name. Got ${repr(selector)} instead.`)
  }
}

/**
 * Prepare a scope object. Adds default test function if test is not provided.
 * @param {Scope} scope
 * @throws {TypeError} If scope is invalid
 * @returns {Scope}
 */
export const prepareScope = scope => {
  validateScope(scope)
  return {
    ...scope,
    test: scope.test || ((selection, entity) => (
      selection && entity
      && (
        scope.selectionProp
          ? selection[scope.selectionProp]
          : selection
      ) === entity[scope.entityProp])
    ),
  }
}

/**
 *
 * @typedef {Object} Kwargs
 * @property {Array<string|function>} dependencies - The dependencies to pass to createSelector
 * @property {function} resultFn - The result function to pass to createSelector
 * @property {*} defaultReturnValue=null] - The default return value to use
 */

/**
 * Wrapper function returned by kwargsValidationWrapper that ensures that the correct kwargs are provided.
 * @typedef {function} KwargsValidationWrapper
 * @param {Kwargs} kwargs
 * @param {...function} [additionalTests] - Additional tests to run on the kwargs
 */

/**
 * @typedef {Function} Selector
 * @property {function} resultFunc - The inner result function that accepts the selected dependencies
 * @returns {any} - The result of the selector
 */

/**
 * Creates a wrapper that validates a functions kwargs and throws if they fail any tests
 * @param  {...function} args - The function to guard and the tests to run on its kwargs
 * @returns {KwargsValidationWrapper}
 */
export const kwargsValidationWrapper = (...args) => (
  /**
   * @param {Kwargs} kwargs
   * @param  {...function} [additionalTests]
   */
  (kwargs, ...additionalTests) => {
    const [fn, ...validators] = args
    const { dependencies, resultFn } = kwargs
    if (!Array.isArray(dependencies)) {
      throw new TypeError(`"dependencies" must be an array. Got ${repr(dependencies)} instead.`)
    }
    if (typeof resultFn !== 'function') {
      throw new TypeError(`"resultFn" must be a function. Got ${repr(resultFn)} instead.`)
    }
    [...validators, ...additionalTests].forEach(validator => validator(kwargs))
    return fn(kwargs)
  }
)
/**
 * Returns default value if guardFn returns false. Primary use case is guarded reactors.
 * @type {KwargsValidationWrapper}
 * @returns {Selector}
 */
export const createGuardedSelector = kwargsValidationWrapper(({
  dependencies,
  resultFn,
  defaultReturnValue = null,
  guardDependencies,
  guardFn,
}) => {
  const guardedResultFn = (...allArgs) => {
    const guardArgs = allArgs.slice(0, guardDependencies.length)
    const dependencyArgs = allArgs.slice(guardDependencies.length)
    if (!guardFn(...guardArgs)) {
      return defaultReturnValue
    }
    return resultFn(...dependencyArgs)
  }
  return Object.assign(
    createSelector(...[...guardDependencies, ...dependencies], guardedResultFn),
    { resultFn }
  )
}, ({ guardDependencies }) => {
  if (!Array.isArray(guardDependencies)) {
    throw new TypeError(`"guardDependencies" must be an array. Got ${repr(guardDependencies)} instead.`)
  }
}, ({ guardFn }) => {
  if (typeof guardFn !== 'function') {
    throw new TypeError(`"guardFn" must be a function. Got ${repr(guardFn)} instead.`)
  }
})

/**
 * Returns default value if user is not authenticated. Primary use case is guarded reactors.
 * @type {KwargsValidationWrapper}
 * @returns {Selector}
 */
export const createAuthenticatedSelector = kwargs => createGuardedSelector({
  ...kwargs,
  guardDependencies: ['selectAuth'],
  guardFn: auth => auth?.authenticated ?? false,
})

/**
 * Returns default value if app is not ready. Primary use case is guarded reactors.
 * @param {Kwargs} kwargs
 * @returns {Selector}
 */
export const createAppIsReadySelector = kwargs => createGuardedSelector({
  ...kwargs,
  guardDependencies: ['selectAppIsReady'],
  guardFn: identity,
})

/**
 * Returns a selector that filters its result based on a scope.
 */
export const createScopedSelector = kwargsValidationWrapper(({
  dependencies,
  resultFn,
  scope: rawScope,
  defaultReturnValue = null,
}) => {
  const scope = prepareScope(rawScope)
  return createSelector(
    'selectAppIsReady',
    scope.selector,
    ...dependencies,
    (appIsReady, scopeSelection, ...dependencyArgs) => {
      if (!appIsReady) return defaultReturnValue
      const unfiltered = resultFn(...dependencyArgs)
      return filter(partial(scope.test, [scopeSelection]), unfiltered)
    }
  )
}, ({ scope }) => validateScope(scope))
