import { SvgIcon } from '@mui/material'

export const LightIntensityIcon = props => (
  <SvgIcon {...props}>
    <path d="M8.05466 21.1988C7.13026 21.706 5.8492 22 3.99994 22V20.4615C5.71381 20.4615 6.72658 20.1862 7.35831 19.8395C7.96739 19.5053 8.28661 19.0702 8.55058 18.5794C8.64021 18.4128 8.70889 18.2699 8.78324 18.1151C8.83523 18.0069 8.88999 17.8929 8.95663 17.761C9.10014 17.477 9.27572 17.1618 9.51941 16.8776C10.053 16.2552 10.8011 15.9072 11.9276 15.9072C13.0548 15.9072 13.8033 16.2553 14.3425 16.8718C14.5892 17.154 14.7696 17.4672 14.9182 17.7503C14.9845 17.8766 15.0403 17.9878 15.0934 18.0936C15.1731 18.2525 15.2467 18.3994 15.3409 18.5673C15.6164 19.0584 15.9505 19.4984 16.58 19.8363C17.2315 20.1861 18.2661 20.4615 19.9999 20.4615V22C18.1314 22 16.8345 21.7061 15.8955 21.202C14.9343 20.686 14.4187 19.9874 14.0554 19.3399C13.9489 19.1501 13.8406 18.9352 13.7464 18.7483C13.6969 18.6501 13.6513 18.5597 13.612 18.4847C13.4804 18.2341 13.3671 18.0492 13.2404 17.9043C13.0328 17.6669 12.7153 17.4457 11.9276 17.4457C11.1393 17.4457 10.8299 17.6671 10.6314 17.8986C10.5091 18.0413 10.4001 18.2243 10.2739 18.4739C10.2359 18.5492 10.191 18.6421 10.142 18.7434C10.0523 18.929 9.94898 19.1427 9.84942 19.3278C9.50096 19.9756 9.00173 20.6791 8.05466 21.1988Z" />
    <path d="M11.9596 13.6844H11.965C12.2607 13.6844 12.4973 13.4391 12.4973 13.1324V12.5972C12.4973 12.2905 12.2607 12.0452 11.965 12.0452H11.9596C11.6639 12.0452 11.4273 12.2905 11.4273 12.5972V13.1324C11.4273 13.4391 11.6639 13.6844 11.9596 13.6844Z" />
    <path d="M7.79763 11.8942C8.00734 12.1117 8.34609 12.1117 8.55579 11.8942L8.76549 11.6768C8.97519 11.4593 8.96982 11.1081 8.76549 10.8962L8.76011 10.8906C8.65965 10.7862 8.52327 10.7275 8.38104 10.7275C8.23881 10.7275 8.10242 10.7862 8.00196 10.8906L7.79226 11.1081C7.58793 11.3311 7.58793 11.6768 7.79763 11.8942Z" />
    <path d="M16.2668 7.57177V7.57735C16.2668 7.88401 16.5034 8.12934 16.7991 8.12934H17.3422C17.6379 8.12934 17.8745 7.88401 17.8745 7.57735V7.57177C17.8745 7.26511 17.6379 7.01978 17.3422 7.01978H16.7991C16.5034 7.01978 16.2668 7.26511 16.2668 7.57177Z" />
    <path d="M7.13076 7.01978H6.58231C6.28657 7.01978 6.04999 7.26511 6.04999 7.57177V7.57735C6.04999 7.88401 6.28657 8.12934 6.58231 8.12934H7.12538C7.42112 8.12934 7.6577 7.88401 7.6577 7.57735V7.57177C7.66308 7.26511 7.42649 7.01978 7.13076 7.01978Z" />
    <path d="M13.5781 4.68281V2.55776C13.5781 2.25099 13.3362 2 13.0404 2H10.8895C10.5937 2 10.3517 2.25099 10.3517 2.55776V4.68281C9.25478 5.34096 8.56648 6.64611 8.7762 8.10186C8.99129 9.5576 10.1528 10.7233 11.5616 10.9018C13.519 11.1472 15.1913 9.56318 15.1913 7.58314C15.1913 6.33935 14.5407 5.26288 13.5781 4.68281Z" />
    <path d="M15.1591 11.6823L15.3688 11.8997C15.5785 12.1172 15.9173 12.1172 16.127 11.8997C16.3367 11.6823 16.3367 11.331 16.127 11.1136L15.9173 10.8961C15.7076 10.6787 15.3688 10.6842 15.1645 10.8961C14.9494 11.1191 14.9494 11.4648 15.1591 11.6823Z" />
  </SvgIcon>
)
LightIntensityIcon.displayName = 'Icon/LightIntensity'

export default LightIntensityIcon
