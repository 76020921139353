import { findIndex, findLastIndex, prop } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  shallowEquals,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'
import { DEFAULT_TIMEFRAME, TIMEFRAME_PRESETS } from '~/src/Room/constants'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { isRoomDashboardRoute } from './urls'

const logger = createLogger('Room/bundle#currentRoom')

const CURRENT_ROOM_SET_NEXT_PARAMS = 'CURRENT_ROOM_SET_NEXT_PARAMS'

const getDatesForTimeframe = timeframe => {
  let { [timeframe ?? DEFAULT_TIMEFRAME]: tf } = TIMEFRAME_PRESETS
  if (!tf && timeframe) {
    const [value, unit] = timeframe.split(/([DHWY])/)
    const tfValue = Number(value ?? 0)
    switch (unit) {
      case 'H':
        tf = { hours: tfValue }
        break
      case 'D':
        tf = { days: tfValue }
        break
      case 'W':
        tf = { weeks: tfValue }
        break
      case 'Y':
        tf = { years: tfValue }
        break
      default:
        tf = EMPTY_ARRAY
    }
  }

  return [getDateTime('now').minus(tf).startOf('day').toISODate()]
}

const getHarvestDatesInRoom = (roomId, harvest, phaseEntities) => {
  if (!harvest) return EMPTY_ARRAY
  const room = Number(roomId)
  const phases = harvest.phases.map(id => phaseEntities[id]).filter(Boolean)
  const findByRoomId = phase => room === phase.room

  const {
    [findIndex(findByRoomId, phases)]: first,
    [findLastIndex(findByRoomId, phases)]: last,
  } = phases
  const start = getDateTime(first?.startDate)
  const end = getDateTime(last?.endDate)

  return [
    start.isValid ? start.toISODate() : getDatesForTimeframe()[0],
    end.isValid ? end.toISODate() : undefined,
  ]
}

const queryToParams = shallowEqualsMemoizer(({
  params,
  query,
  harvests = EMPTY_OBJECT,
  phases = EMPTY_OBJECT
}) => {
  const { to, from, timeframe, harvest } = query
  let fetchParams = EMPTY_OBJECT
  if (from) {
    fetchParams = { ...fetchParams, start: getDateTime(from).toISODate() }
  }
  if (to) {
    fetchParams = { ...fetchParams, end: getDateTime(to).toISODate() }
  }
  if (timeframe) {
    const [start, end] = timeframe === 'selectedHarvest'
      ? getHarvestDatesInRoom(params.id, harvests[harvest], phases)
      : getDatesForTimeframe(timeframe)
    fetchParams = end ? { start, end } : { start }
  }

  if (fetchParams === EMPTY_OBJECT) {
    const [start] = getDatesForTimeframe()
    fetchParams = { start }
  }

  return fetchParams
}, { depth: 2 })

const currentRoomBundle = createAsyncResourceBundle({
  name: 'currentRoom',
  staleAfter: ms.minutes(5),
  retryAfter: ms.seconds(2),
  getPromise: async ({ store }) => {
    const next = store.selectCurrentRoomNextParams()
    const { id, query } = next
    const result = await store.doRoomFetch({ id, params: query })
    if (result) {
      return next
    }
    const { lastError } = store.selectRoom()
    if (lastError) {
      const { error, ...rest } = lastError
      throw Object.assign(new Error(error), rest)
    }
    return store.selectCurrentRoom()
  },
})

export default {
  ...currentRoomBundle,
  reducer: reduceReducers(currentRoomBundle.reducer, (state, action) => {
    if (action.type === CURRENT_ROOM_SET_NEXT_PARAMS) {
      return {
        ...state,
        nextParams: action.payload ?? EMPTY_OBJECT
      }
    }
    if (!state.nextParams && !state.data) {
      return {
        ...state,
        data: EMPTY_OBJECT,
        nextParams: EMPTY_OBJECT,
      }
    }
    return state
  }),
  selectRouteRoomQuery: createSelector(
    'selectRouteInfo',
    'selectQueryAdvanced',
    'selectEntities',
    ({ params }, query, { harvests, phases }) => queryToParams({ params, query, harvests, phases })
  ),
  selectCurrentRoomNextParams: createSelector(
    'selectCurrentRoomRaw',
    prop('nextParams'),
  ),
  doCurrentRoomUpdateParams: payload => ({ dispatch }) => {
    dispatch({ type: CURRENT_ROOM_SET_NEXT_PARAMS, payload })
    dispatch({ actionCreator: 'doMarkCurrentRoomAsOutdated' })
  },
  reactCurrentRoomUpdateParams: createSelector(
    'selectCurrentRoomIsLoading',
    'selectRouteInfo',
    'selectRouteRoomQuery',
    'selectCurrentRoomNextParams',
    (
      loading,
      { params, ...routeInfo },
      routeQuery,
      { id: nextId, query: nextQuery }
    ) => {
      if (loading || !isRoomDashboardRoute(routeInfo)) {
        return undefined
      }
      const roomChanged = params.id !== nextId
      const queryChanged = !shallowEquals(routeQuery, nextQuery, 2)
      if (roomChanged || queryChanged) {
        return {
          actionCreator: 'doCurrentRoomUpdateParams',
          args: [{
            id: params.id,
            query: queryChanged ? routeQuery : nextQuery
          }]
        }
      }
      return undefined
    }
  ),
  reactCurrentRoomFetch: createAppIsReadySelector({
    dependencies: [
      'selectCurrentRoomIsLoading',
      'selectCurrentRoomShouldUpdate',
      'selectRouteInfo',
      'selectRooms',
    ],
    resultFn: (loading, shouldUpdate, routeInfo, rooms) => {
      if (loading || !isRoomDashboardRoute(routeInfo)) {
        return undefined
      }
      const { [routeInfo.params.id]: room = EMPTY_OBJECT } = rooms
      if (shouldUpdate || room.payloadType !== 'entity') {
        return { actionCreator: 'doFetchCurrentRoom', args: [] }
      }
      return undefined
    }
  })
}
