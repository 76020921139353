import { EMPTY_OBJECT } from '~/src/Lib/Utils'

import GENERIC, { initFlowState as initGenericFlowState } from './generic'
import METRC, { initFlowState as initMetrcFlowState } from './metrc'
import SHARED from './shared'

export const initState = {
  flows: EMPTY_OBJECT,
  showUnsavedHarvestingLeaveDialog: false,
}

export const initialFlowStateMap = {
  METRC: initMetrcFlowState,
  GENERIC: initGenericFlowState,
}

export default {
  GENERIC,
  METRC,
  SHARED,
}
