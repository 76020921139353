import { prop, sortBy } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import urls from './urls'

const initialState = {
  dataType: null,
}

const harvestChartTargetRangesBundle = createAsyncResourceBundle({
  name: 'harvestChartTargetRanges',
  actionBaseType: 'HARVEST_CHART_TARGET_RANGES',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest: harvestId } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvestId)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvestId})`))
    }
    const harvest = store.selectHarvests()[harvestId]
    if (!harvest) {
      return Promise.reject(new Error(`Harvest ${harvestId} not found in store`))
    }
    const { dataType } = store.selectHarvestChartTargetRangesRaw()
    if (!dataType) {
      return Promise.reject(new Error('Data type required for harvest chart fetch'))
    }
    return apiFetch(`/harvests/${harvestId}/chart/`, {
      grouping: 'ROOM',
      start: getDateTime(harvest.startDate).toUTC().toISO(),
      end: getDateTime(harvest.endDate).toUTC().toISO(),
      dataTypes: [dataType],
      features: ['ranges'],
    })
  },
})

export default {
  ...harvestChartTargetRangesBundle,
  reducer: reduceReducers(harvestChartTargetRangesBundle.reducer, (state, action) => {
    if (action.type === 'HARVEST_CHART_TARGET_RANGES_PARAMS_SET') {
      return {
        ...state,
        ...action.payload,
      }
    }
    if (!Object.keys(initialState).every(key => key in state)) {
      return { ...initialState, ...state }
    }
    return state
  }),
  doHarvestChartTargetRangesSetParams: payload => ({ dispatch }) => {
    dispatch({ type: 'HARVEST_CHART_TARGET_RANGES_PARAMS_SET', payload })
    if (payload.dataType) {
      dispatch({ actionCreator: 'doMarkHarvestChartTargetRangesAsOutdated' })
    }
  },
  reactSetHarvestChartTargetRangesParams: createSelector(
    'selectHarvestChartTargetRangesRaw',
    'selectTargetRangeDataTypes',
    ({ dataType }, targetRangeDataTypes) => {
      if (!targetRangeDataTypes) return undefined
      const sortedDataTypes = sortBy(prop('category'), targetRangeDataTypes)
      const [firstDataType] = sortedDataTypes
      if (!dataType && firstDataType) {
        return { actionCreator: 'doHarvestChartTargetRangesSetParams', args: [{ dataType: firstDataType.key }] }
      }
      return undefined
    }
  ),
  reactHarvestChartTargetRangesFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestChartTargetRangesRaw',
      'selectHarvestChartTargetRangesShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: ({ dataType }, shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics && dataType) {
        return { actionCreator: 'doFetchHarvestChartTargetRanges' }
      }
      return undefined
    }
  }),
  selectHarvestTargetRangesChart: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectHarvestChartTargetRangesRaw',
    'selectHarvests',
    'selectTargetRanges',
    (harvestId, phasesByHarvest, { data: harvestChartTargetRanges, dataType }, harvests, targetRanges) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !harvestChartTargetRanges) {
        return EMPTY_OBJECT
      }
      const harvest = harvests[harvestId]
      const { min, max } = harvestChartTargetRanges.bounds
      const chart = {
        data: [],
        domain: {
          x: [getDateTime(harvest.startDate).toJSDate(), getDateTime(harvest.endDate).toJSDate()],
          y: [min[dataType], max[dataType]],
        },
        targetRanges: [],
      }
      phasesByHarvest[harvestId].forEach(phase => {
        const start = getDateTime(phase.startDate).toJSDate()
        const end = getDateTime(phase.endDate).toJSDate()
        const key = `${dataType}:room:${phase.room}`
        chart.data = chart.data.concat(
          harvestChartTargetRanges.data[key]
            ?.map(datum => ({ ...datum, x: getDateTime(datum.x).toJSDate(), phase: phase.id }))
            ?.filter((_, i, d) => d[i + 1]?.x >= start && d[i - 1]?.x <= end) ?? []
        )
        const range = phase.targetRanges
          ?.map(id => targetRanges[id])
          ?.find(tr => tr.dataTypeKey === dataType)
        if (range) {
          chart.targetRanges.push(range)
        }
      })
      return chart
    },
  ),
  selectHarvestTargetRangesChartData: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectHarvestChartTargetRangesRaw',
    'selectTargetRanges',
    (harvestId, phasesByHarvest, { data: harvestChartTargetRanges, dataType }, targetRanges) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !harvestChartTargetRanges) {
        return EMPTY_OBJECT
      }
      const { bounds, data } = harvestChartTargetRanges
      return phasesByHarvest[harvestId].reduce((dbp, phase) => {
        const start = getDateTime(phase.startDate).toJSDate()
        const end = getDateTime(phase.endDate).toJSDate()
        const key = `${dataType}:room:${phase.room}`
        return {
          ...dbp,
          [phase.id]: {
            domain: {
              x: [start, end],
              y: [bounds.min[dataType], bounds.max[dataType]],
            },
            data: data[key]
              ?.map(datum => ({ ...datum, x: getDateTime(datum.x).toJSDate() }))
              ?.filter((_, i, d) => d[i + 1]?.x >= start && d[i - 1]?.x <= end)
              ?? [],
            targetRange: phase.targetRanges?.map(id => targetRanges[id])?.find(tr => tr.dataTypeKey === dataType),
          }
        }
      }, EMPTY_OBJECT)
    },
  ),
}
