import { createSelector } from 'redux-bundler'

import config from '~/src/App/config'
import safeStorage from '~/src/Lib/safeStorage'

export default {
  name: 'flags',
  selectAvailableFeatures: createSelector(
    'selectMyFlags',
    flags => {
      const notProd = config.ENVIRONMENT !== 'production'
      let testFlags = {}
      if (notProd) {
        try {
          const testFlagsJSON = safeStorage().getItem('testFlags')
          if (testFlagsJSON) {
            testFlags = JSON.parse(testFlagsJSON)
          }
        } catch (e) {
          console.warn('Failed to parse testFlags from storage.')
        }
      }
      return new Set(Object.entries({
        ...flags,
        ...testFlags
      }).map(([key, value]) => (value ? key : null)).filter(Boolean))
    }
  ),
  selectPermittedActions: createSelector(
    'selectMyPermissions',
    permissions => {
      const notProd = config.ENVIRONMENT !== 'production'
      let testPermissions = {}
      if (notProd) {
        try {
          const testPermissionsJSON = safeStorage().getItem('testPermissions')
          if (testPermissionsJSON) {
            testPermissions = JSON.parse(testPermissionsJSON)
          }
        } catch (e) {
          console.warn('Failed to parse testPermissions from storage.')
        }
      }

      return new Set(Object.entries({
        ...permissions,
        ...testPermissions
      }).map(([key, value]) => (value ? key : null)).filter(Boolean))
    }
  )
}
