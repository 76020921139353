export const pxToRem = size => `${(size / 16).toFixed(4)}rem`
const remToPixels = size => (size * 16) || 0

export const calcFluidTypography = (params = {}, opts = {}) => {
  const { pxToRem: getRem = pxToRem, remToPixels: getPixels = remToPixels } = opts
  const {
    maxFont = 50,
    minFont = 36,
    maxScreen = 1680,
    minScreen = 960,
    baseRem = 0.5,
  } = params
  const basePx = getPixels(baseRem)
  const vmax = (Math.min((maxFont - basePx) / maxScreen, (minFont - basePx) / minScreen) * 1e4) / 100
  const fontSize = [
    `clamp(${getRem(minFont)}`,
    `${baseRem}rem + ${vmax.toFixed(2)}vmax`,
    `${getRem(maxFont)})`
  ].join(', ')

  return fontSize
}

export const defaultFontFamily = [
  'Akkurat',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
].join(', ')

export const boldFontFamily = `"Akkurat Bold", ${defaultFontFamily}`

const displayTextDefaults = {
  fontWeight: 700,
}

const h4 = {
  ...displayTextDefaults,
  fontSize: calcFluidTypography({ maxFont: 24, minFont: 18 }, { pxToRem }),
  fontFamily: boldFontFamily,
}

const h5 = {
  fontSize: '1.25rem',
  fontFamily: boldFontFamily,
  fontWeight: 700,
}

const h6 = {
  fontSize: '1.125rem',
  fontFamily: boldFontFamily,
  fontWeight: 700,
}

export const typography = {
  h1: {
    ...displayTextDefaults,
    fontSize: calcFluidTypography({ maxFont: 50, minFont: 36 }),
    fontFamily: boldFontFamily,
    lineHeight: 'normal',
  },
  h2: {
    ...displayTextDefaults,
    fontSize: calcFluidTypography({ maxFont: 36, minFont: 24 }),
    fontFamily: boldFontFamily,
    lineHeight: 'normal',
  },
  h3: {
    ...displayTextDefaults,
    fontSize: calcFluidTypography({ maxFont: 28, minFont: 20 }),
    fontFamily: boldFontFamily,
    lineHeight: 1.2,
  },
  h4,
  h5,
  h6,
  subtitle1: {
    ...h6,
    fontSize: '1rem',
  },
  subtitle2: {
    ...h6,
    fontSize: '1rem',
    fontFamily: defaultFontFamily,
    fontWeight: 400,
  },
  body1: {
    fontSize: '0.875rem',
    letterSpacing: 0.5,
  },
  body2: {
    fontSize: '0.75rem',
    letterSpacing: 0.25,
  },
  button: {
    textTransform: 'none',
    fontFamily: boldFontFamily,
    fontWeight: 700,
  },
}

const fontsBucket = 'https://phytochrome-assets.s3-us-west-2.amazonaws.com/fonts'
export const AkkuratFontFace = `
  @font-face {
    font-family: 'Akkurat';
    src: ${[
  `url('${fontsBucket}/lineto-akkurat-pro-regular.ttf') format('truetype')`,
  `url('${fontsBucket}/lineto-akkurat-pro-regular.woff') format('woff')`,
  `url('${fontsBucket}/lineto-akkurat-pro-regular.woff2') format('woff2')`
].join(',\n')};
    font-style: normal;
    font-display: swap;
  }
`
export const AkkuratBoldFontFace = `
  @font-face {
    font-family: 'Akkurat Bold';
    src: ${[
  `url('${fontsBucket}/lineto-akkurat-pro-bold.ttf') format('truetype')`,
  `url('${fontsBucket}/lineto-akkurat-pro-bold.woff') format('woff')`,
  `url('${fontsBucket}/lineto-akkurat-pro-bold.woff2') format('woff2')`
].join(',\n')};
    font-style: normal;
    font-display: swap;
  }
`
