import i18n from 'i18n-literally'

import { LineSegment, VictoryCursorContainer, VictoryLabel } from 'victory'

import { inflect } from 'inflection'

import getGlobal from '~/src/Lib/getGlobal'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

export const comparisonMessage = (current, usual, config = {}) => {
  if (!current || !usual || current === usual) return ''
  const currentNum = Number(current)
  const usualNum = Number(usual)
  if (Number.isNaN(currentNum) || Number.isNaN(usualNum) || currentNum === usualNum) return ''
  const arrow = currentNum > usualNum ? '\u2197 ' : '\u2198 '
  const diff = Math.round(Math.abs(currentNum - usualNum))
  const percentDiff = `${Math.round(Math.abs(1 - currentNum / usualNum) * 100)}%`
  const value = config.percentage ? percentDiff : diff
  return [
    config.prefix ?? arrow,
    value,
    config.unit ? ` ${inflect(config.unit, value)} ` : ' ',
    currentNum > usualNum ? config.more || i18n`more` : config.less || i18n`less`,
    i18n` than usual`,
  ].filter(Boolean).join('')
}

export const daysToWeeksAndDays = (days, shorthand = false) => {
  const weeks = Math.floor(days / 7)
  const leftover = Math.round(days - weeks * 7)
  const weekString = `${weeks} ${weeks === 1 ? i18n`week` : i18n`weeks`}`
  const dayString = `${leftover} ${leftover === 1 ? i18n`day` : i18n`days`}`
  const fullString = weeks
    ? `${weekString}${leftover ? `, ${dayString}` : ''}`
    : dayString
  return shorthand ? fullString.replace(/\b[a-z]+\b/g, match => match[0]) : fullString
}
getGlobal().daysToWeeksAndDays = daysToWeeksAndDays

export const calculateSumFromPhaseData = dataByPhaseType => Object.values(dataByPhaseType ?? EMPTY_OBJECT).flat()
  .reduce((sum, datum) => sum + (datum.y ?? 0), 0)

const findLocalMin = (start, func) => {
  if (func(start + 1) < func(start)) {
    let a = start
    let b = start + 1
    while (func(b) < func(a)) {
      a += 1
      b += 1
    }
    return a
  }
  let a = start
  let b = start - 1
  while (func(b) < func(a)) {
    a -= 1
    b -= 1
  }
  return a
}

export const getClosestPointLinear = (chartData = [], x = null, threshold = Infinity) => {
  if (x == null) return null
  return chartData.reduce((acc, datum) => {
    const currentDiff = Math.abs(datum.x - x)
    return (currentDiff <= threshold && currentDiff < acc.diff)
      ? { closest: datum, diff: currentDiff }
      : acc
  }, { closest: null, diff: Infinity }).closest
}
export const getClosestPointBinary = (chartData, x, threshold = Infinity) => {
  if (!chartData) return null
  let start = 0
  let end = chartData.length - 1
  let target
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    target = chartData[mid]
    if (Math.abs(target.x - x) <= threshold) {
      const min = findLocalMin(mid, a => Math.abs((chartData[a]?.x ?? 0) - x))
      return chartData[min]
    }
    if (target.x < x) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
  return null
}

export const getCursorContainer = ({ active, cursorLabelStyle, theme = { palette: EMPTY_OBJECT }, getLabel }) => {
  const { palette: colors } = theme

  return (
    <VictoryCursorContainer
      disable={!active}
      cursorComponent={active ? (
        <LineSegment style={{ stroke: colors.gray, strokeWidth: 2 }} />
      ) : undefined}
      cursorDimension="x"
      cursorLabel={({ datum }) => getLabel(datum.x)}
      cursorLabelComponent={active ? (
        <VictoryLabel
          style={cursorLabelStyle}
          backgroundPadding={8}
          backgroundStyle={{ fill: colors.background?.dark, rx: '4px' }}
          lineHeight={1.2}
          renderInPortal
        />
      ) : undefined}
      cursorLabelOffset={{ x: 20, y: -6 }}
    />
  )
}

export const getPlaceholderChart = (yValues = []) => {
  const data = yValues.map((y, i) => ({ x: i, y }))
  const maxY = yValues.reduce((max, y) => Math.max(max, y), 0)
  return { data, domain: { x: [0, yValues.length - 1], y: [0, maxY] } }
}
export const IMAGE_HEIGHT = 200
