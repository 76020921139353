import i18n from 'i18n-literally'
import { filter, mean, propEq } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import { isPerformanceRoute } from './utils'

export const orderedTimeFrames = [
  'thirtyDays', 'sixtyDays', 'ninetyDays', 'sixMonths', 'oneYear', 'allTime',
]

export const timeFrames = {
  thirtyDays: {
    key: 'thirtyDays',
    label: i18n`last 30 days`,
    description: i18n`in the last 30 days`,
    duration: { days: 30 },
  },
  sixtyDays: {
    key: 'sixtyDays',
    label: i18n`last 60 days`,
    description: i18n`in the last 60 days`,
    duration: { days: 60 },
  },
  ninetyDays: {
    key: 'ninetyDays',
    label: i18n`last 90 days`,
    description: i18n`in the last 90 days`,
    duration: { days: 90 },
  },
  sixMonths: {
    key: 'sixMonths',
    label: i18n`last 6 months`,
    description: i18n`in the last 6 months`,
    duration: { months: 6 },
  },
  oneYear: {
    key: 'oneYear',
    label: i18n`1 year`,
    description: i18n`in the past year`,
    duration: { years: 1 },
  },
  allTime: {
    key: 'allTime',
    label: i18n`all time`,
    description: i18n`across all harvest groups`,
  },
}

export const getStartDate = timeFrame => {
  const duration = timeFrames[timeFrame].duration
  return duration && getDateTime('now').minus(duration).toISODate()
}

const transformChartData = data => data
  .filter((datum, index, list) => typeof datum.y === 'number' || index === 0 || index === list.length - 1)
  .map(datum => ({ ...datum, x: getDateTime(datum.x).startOf('day').toJSDate() }))

const getUnit = graph => {
  if (['propagation', 'vegetation', 'flowering', 'drying', 'total_duration', 'room_downtime'].includes(graph)) {
    return i18n`days`
  }
  switch (graph) {
    case 'mortality_rate':
      return '%'
    case 'plant_density':
      return i18n`/ sqft`
    case 'staff_size':
      return ''
    default:
      return null
  }
}

const initialState = {
  facility: null,
  graph: 'wet_weight_per_sqft',
  rate: 'perSqft',
  timeFrame: 'ninetyDays',
}

const facilityChartBundle = createAsyncResourceBundle({
  name: 'facilityChart',
  actionBaseType: 'FACILITY_CHART',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { facility, timeFrame } = store.selectFacilityChartRaw()
    if (!facility) return Promise.reject(new Error('Waiting to load current facility'))
    return apiFetch('/facility/chart/', {
      start: getStartDate(timeFrame),
      facility,
    })
  },
})

export default {
  ...facilityChartBundle,
  reducer: reduceReducers(facilityChartBundle.reducer, (state, action) => {
    if (action.type === 'FACILITY_CHART_PARAMS_SET') {
      return {
        ...state,
        ...action.payload,
      }
    }
    if (!Object.keys(initialState).every(key => key in state)) {
      return { ...initialState, ...state }
    }
    return state
  }),
  doFacilityChartSetParams: payload => ({ dispatch, store }) => {
    const currentState = store.selectFacilityChartRaw()
    // Don't thrash when the same value is getting set
    if (Object.entries(payload).every(([key, value]) => currentState[key] === value)) return
    dispatch({ type: 'FACILITY_CHART_PARAMS_SET', payload })
    if (payload.facility || payload.timeFrame) {
      dispatch({ actionCreator: 'doMarkFacilityChartAsOutdated' })
    }
  },
  reactSetPerformanceFacility: createSelector(
    'selectAuth',
    'selectCurrentFacilities',
    'selectCurrentFacility',
    'selectFacilityChartRaw',
    'selectAvailableFeatures',
    'selectPermittedActions',
    'selectRouteInfo',
    ({ authenticated }, currentFacilities, currentFacility, { facility }, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (!allowed || !authenticated || !currentFacility) return null
      if (!Array.isArray(facility) || facility.every(facilityId => !currentFacilities[facilityId])) {
        return { actionCreator: 'doFacilityChartSetParams', args: [{ facility: [currentFacility.id] }] }
      }
      return null
    }
  ),
  reactFacilityChartFetch: createAppIsReadySelector({
    dependencies: [
      'selectFacilityChartShouldUpdate',
      'selectAvailableFeatures',
      'selectPermittedActions',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, availableFeatures, permittedActions, routeInfo) => {
      if (!isPerformanceRoute(routeInfo)) return null
      const allowed = availableFeatures.has('FACILITY_PERFORMANCE')
        && permittedActions.has('view_performance')
      if (allowed && shouldUpdate) {
        return { actionCreator: 'doFetchFacilityChart' }
      }
      return undefined
    }
  }),
  selectFacilityPerformanceGraphs: createSelector(
    'selectFacilityChart',
    facilityChart => facilityChart?.reduce((graphs, chart) => ({
      ...graphs,
      [chart.dataType]: {
        ...chart,
        data: transformChartData(chart.data),
      },
    }), EMPTY_OBJECT) ?? {}
  ),
  selectFacilityChartFinalPackageWeightIsFinal: createSelector(
    'selectFacilityChart',
    chartList => {
      // Final package weights have "is final" flags per day, utilizing a unique chart type with boolean y-values
      const finalPackageWeightIsFinalChart = chartList?.find(c => c.dataType === 'final_package_weight_is_final')
      if (!finalPackageWeightIsFinalChart) return {}
      return finalPackageWeightIsFinalChart.data.reduce((isFinalByDate, d) => {
        isFinalByDate[d.x] = d.y
        return isFinalByDate
      }, {})
    },
  ),
  selectFacilityChartDomain: createSelector(
    'selectFacilityChartRaw',
    ({ timeFrame }) => {
      const today = getDateTime('now').startOf('day')
      const duration = timeFrames[timeFrame].duration

      if (!duration) return undefined

      return {
        x: [
          today.minus(duration).toJSDate(),
          today.toJSDate()
        ]
      }
    }
  ),
  selectCurrentPerformanceGraph: createSelector(
    'selectFacilityChartRaw',
    'selectFacilityChartDomain',
    'selectUnits',
    ({ data: charts, graph }, domain, units) => {
      const chart = charts?.find(({ dataType }) => dataType === graph)

      return chart ? {
        ...chart,
        average: mean(chart.data.map(d => d.y).filter(y => typeof y === 'number')),
        data: transformChartData(chart.data),
        domain,
        unitSymbol: getUnit(graph) ?? units[chart.weightUnit]?.symbol,
      } : {
        dataType: graph,
      }
    }
  ),
  selectCurrentFacilities: createSelector(
    'selectCurrentOrganization',
    'selectFacilities',
    ({ id: orgId }, facilities) => (
      filter(propEq(orgId, 'organization'), facilities)
    )
  ),
}
