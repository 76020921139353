import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { ENTITIES_RECEIVED } from '~/src/Lib/createEntityBundle'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import { FacilityCultivar } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const CULTIVAR_LIST_SET_PAGE = 'CULTIVAR_LIST_SET_PAGE'
const CULTIVAR_LIST_SET_SORT = 'CULTIVAR_LIST_SET_SORT'
const CULTIVAR_LIST_SET_FILTER = 'CULTIVAR_LIST_SET_FILTER'
const CULTIVAR_LIST_SET_SEARCH = 'CULTIVAR_LIST_SET_SEARCH'
const CULTIVAR_LIST_RESET = 'CULTIVAR_LIST_RESET'

const additionalState = {
  archived: false,
  searchTerms: '',
  currentFilter: 'ACTIVE',
  currentSort: 'name',
  currentPage: 1,
}

const { selectCultivarListShouldUpdate, ...cultivarListBundle } = createAsyncResourceBundle({
  name: 'cultivarList',
  actionBaseType: 'CULTIVAR_LIST',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  getPromise: ({ dispatch, apiFetch, getState }) => {
    const { cultivarList } = getState()
    const {
      archived,
      searchTerms,
      currentFilter,
      currentSort,
      currentPage,
    } = cultivarList
    const params = {
      archived,
      currentFilter,
      page: currentPage,
      ordering: currentSort,
      search: searchTerms.length >= 3 ? searchTerms : '',
    }
    if (currentFilter === 'ACTIVE') {
      params.archived = false
      delete params.currentFilter
    }
    if (currentFilter === 'ALL' || currentFilter === 'ARCHIVED') {
      params.archived = true
      params.archivedOnly = currentFilter === 'ARCHIVED'
    }
    return apiFetch('/cultivars/', params).then(payload => {
      // we are adding payloadType: 'entity' to the payload since the endpoints serializer is not adding it
      const { entities, result } = normalize(payload.results.map(c => ({ ...c, payloadType: 'entity' })), [
        FacilityCultivar,
      ])
      dispatch({
        type: ENTITIES_RECEIVED,
        payload: entities,
      })
      return { ...payload, results: result }
    })
  },
})

export default {
  ...cultivarListBundle,
  reducer: reduceReducers(cultivarListBundle.reducer, (state, action) => {
    switch (action.type) {
      case CULTIVAR_LIST_SET_PAGE:
        return { ...state, currentPage: action.payload }
      case CULTIVAR_LIST_SET_SORT:
        return { ...state, currentSort: action.payload }
      case CULTIVAR_LIST_SET_FILTER:
        return { ...state, currentPage: 1, currentFilter: action.payload }
      case CULTIVAR_LIST_SET_SEARCH:
        return { ...state, currentPage: 1, searchTerms: action.payload }
      case CULTIVAR_LIST_RESET:
        return { ...state, ...additionalState }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doCultivarListSetPage: page => ({ dispatch, store }) => {
    dispatch({ type: CULTIVAR_LIST_SET_PAGE, payload: page })
    store.doMarkCultivarListAsOutdated()
  },
  doCultivarListSetSort: sort => ({ dispatch, store }) => {
    dispatch({ type: CULTIVAR_LIST_SET_SORT, payload: sort })
    store.doMarkCultivarListAsOutdated()
  },
  doCultivarListSetFilter: filter => ({ dispatch, store }) => {
    dispatch({ type: CULTIVAR_LIST_SET_FILTER, payload: filter })
    store.doMarkCultivarListAsOutdated()
  },
  doCultivarListSetSearch: search => ({ dispatch, store }) => {
    dispatch({ type: CULTIVAR_LIST_SET_SEARCH, payload: search })
    if (search.length >= 3 || search.length === 0) {
      store.doMarkCultivarListAsOutdated()
    }
  },
  doCultivarListReset: () => ({ dispatch, store }) => {
    dispatch({ type: CULTIVAR_LIST_RESET })
    store.doMarkCultivarListAsOutdated()
  },
  selectCurrentCultivarList: createSelector(
    'selectFacilityCultivars',
    'selectCultivarListRaw',
    (facilityCultivars, cultivarListRaw) => {
      if (!cultivarListRaw.data) return EMPTY_ARRAY
      const { data: { results } } = cultivarListRaw
      return results.map(cultivarId => facilityCultivars?.[cultivarId]).filter(Boolean)
    }
  ),
  reactCultivarListFetch: createAppIsReadySelector({
    dependencies: [
      'selectCultivarListShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && (pattern.startsWith('/cultivars') || pattern.startsWith('/facility/cultivars'))) {
        return { actionCreator: 'doFetchCultivarList' }
      }
      return undefined
    }
  }),
  selectCultivarListShouldNormallyUpdate: selectCultivarListShouldUpdate,
  selectCultivarListShouldUpdate: createSelector(
    'selectCultivarListShouldNormallyUpdate',
    'selectCultivarListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastCultivarRefresh && lastSuccess < new Date(metrcFacility.lastCultivarRefresh)
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
