import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'

import createListBundle from '~/src/Lib/createListBundle'
import { WasteReasonPlantBatch as schema } from '~/src/Store/Schemas'

export const initialState = {
  search: '',
  ordering: ['name', 'asc'],
}

const { selectWasteReasonPlantBatchListShouldUpdate, ...initialBundle } = createListBundle({
  entityName: 'wasteReasonPlantBatch',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/wasteReasonPlantBatches/', { ...params, pagination: 0 }),
  flags: { keys: ['METRC'] },
  staleAfter: minutes(60),
})

export default {
  ...initialBundle,
  selectWasteReasonPlantBatchListShouldNormallyUpdate: selectWasteReasonPlantBatchListShouldUpdate,
  selectWasteReasonPlantBatchListShouldUpdate: createSelector(
    'selectWasteReasonPlantBatchListShouldNormallyUpdate',
    'selectWasteReasonPlantBatchListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastWasteReasonPlantBatchRefresh && lastSuccess < new Date(metrcFacility.lastWasteReasonPlantBatchRefresh)
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
