import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'

import {
  createCustomAction,
  doEntitiesReceived,
  doEntitiesRemoved,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { Comment as schema } from '~/src/Store/Schemas'

import { EMPTY_OBJECT } from '../Lib/Utils'

const logger = createLogger('Comments/bundle')

const {
  actionIdentifiers: sendComment,
  actionReducer: sendCommentReducer,
  defaultState: sendCommentDefaultState
} = createCustomAction({
  actionType: 'send',
  actionName: 'send_comment',
  reducerKey: 'send_comment',
  loadingKey: 'loading'
})

const {
  actionIdentifiers: editComment,
  actionReducer: editCommentReducer,
  defaultState: editCommentDefaultState,
} = createCustomAction({
  actionType: 'edit',
  actionName: 'edit_comment',
  reducerKey: 'edit_comment',
  loadingKey: 'loading'
})

const {
  actionIdentifiers: deleteComment,
  actionReducer: deleteCommentReducer,
  defaultState: deleteCommentDefaultState,
} = createCustomAction({
  actionType: 'delete',
  actionName: 'delete_comment',
  reducerKey: 'delete_comment',
  loadingKey: 'loading'
})

const dispatchMessage = (message, dispatch) => dispatch({
  actionCreator: 'doAddSnackbarMessage',
  args: [message]
})

const getMentionsIds = mentions => mentions.map(m => Number(m.id))

const initialState = {
  ...editCommentDefaultState,
  ...sendCommentDefaultState,
  ...deleteCommentDefaultState
}

export default {
  name: 'comments',
  reducer: (state = initialState, action = EMPTY_OBJECT) => {
    if (action.type.startsWith(sendComment.types.prefix)) {
      return sendCommentReducer(state, action)
    }
    if (action.type.startsWith(editComment.types.prefix)) {
      return editCommentReducer(state, action)
    }
    if (action.type.startsWith(deleteComment.types.prefix)) {
      return deleteCommentReducer(state, action)
    }
    return state
  },
  doCommentSend: payload => async ({ dispatch, apiFetch, store }) => {
    dispatch({ type: sendComment.types.start })
    let result = false
    try {
      result = await apiFetch(
        '/comments/',
        { ...payload, mentions: getMentionsIds(payload.mentions) },
        { method: 'POST' }
      )
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: sendComment.types.succeed, payload: result })
      dispatchMessage('Comment successfully saved!', dispatch)
      const { notes = EMPTY_OBJECT, tasks = EMPTY_OBJECT } = store.selectEntities()
      if (payload.note in tasks) {
        dispatch({
          actionCreator: 'doTaskFetch',
          args: [payload.note]
        })
      }
      if (payload.note in notes) {
        dispatch({
          actionCreator: 'doNoteFetch',
          args: [payload.note]
        })
      }
    } catch (error) {
      result = error
      dispatch({ type: sendComment.types.fail, error })
      dispatchMessage('Comment saving failed', dispatch)
    }
    return result
  },
  doCommentEdit: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: editComment.types.start, payload })
    let result = false
    try {
      result = await apiFetch(
        `/comments/${payload.id}/`,
        { ...payload, mentions: getMentionsIds(payload.mentions) },
        { method: 'PUT' }
      )
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: editComment.types.succeed, payload: result })
      dispatchMessage('Comment successfully saved!', dispatch)
    } catch (error) {
      result = error
      dispatch({ type: editComment.types.fail, error })
      dispatchMessage('Comment editing failed', dispatch)
    }
    return result
  },
  doCommentDelete: payload => async ({ dispatch, apiFetch, store }) => {
    dispatch({ type: deleteComment.types.start, payload: payload.id })
    try {
      await apiFetch(`/comments/${payload.id}/`, null, { method: 'DELETE' })
      dispatch(doEntitiesRemoved({ comments: [payload.id] }))
      dispatch({ type: deleteComment.types.succeed, payload: payload.id })
      dispatchMessage('Comment successfully deleted!', dispatch)
      const { notes = EMPTY_OBJECT, tasks = EMPTY_OBJECT } = store.selectEntities()
      if (payload.note in tasks) {
        dispatch({
          actionCreator: 'doTaskFetch',
          args: [payload.note]
        })
      }
      if (payload.note in notes) {
        dispatch({
          actionCreator: 'doNoteFetch',
          args: [payload.note]
        })
      }
      return true
    } catch (error) {
      dispatch({ type: deleteComment.types.fail, error })
      dispatchMessage('Comment failed to delete!', dispatch)
      return false
    }
  },
  selectMentionable: createSelector(
    'selectFacilityMemberships',
    'selectUsers',
    (memberships, users) => Object.values(memberships)
      .map(({ id, user }) => (users[user]?.fullName ? { id, display: users[user].fullName } : null))
      .filter(Boolean)
  )
}
