/* eslint-disable no-restricted-imports */
import { PureComponent } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Alert } from '@mui/lab'
import { Box, Grid } from '@mui/material'

import { Form, Formik } from 'formik'

import config from '~/src/App/config'
import { errorMessagePropType } from '~/src/Lib/PropTypes'
import Loading from '~/src/Routes/Loading'
import SetPassword from '~/src/Routes/Shared/SetPassword'
import { SUBMIT_REQUIREMENTS } from '~/src/Routes/utils'
import Button from '~/src/UI/Shared/Button'
import PageTitle from '~/src/UI/Shared/PageTitle'
import T from '~/src/UI/Shared/Typography'

import { PUBLIC_ROUTES } from '../constants'

export class ResetPasswordComponent extends PureComponent {
  static propTypes = {
    auth: PropTypes.shape({
      authenticated: PropTypes.bool,
      reset: PropTypes.shape({
        error: errorMessagePropType,
        loading: PropTypes.bool,
      }),
    }).isRequired,
    config: PropTypes.shape({ APP: PropTypes.string }).isRequired,
    firstTime: PropTypes.bool.isRequired,
    formikProps: PropTypes.shape({
      values: PropTypes.shape({
        password1: PropTypes.string,
        password2: PropTypes.string,
      }),
      handleChange: PropTypes.func,
      isValid: PropTypes.bool,
      dirty: PropTypes.bool,
      handleSubmit: PropTypes.func,
    }).isRequired,
    routeParams: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
    token: PropTypes.string,
    tokenValidationResponse: PropTypes.shape({
      loading: PropTypes.bool,
      response: PropTypes.shape({
        valid: PropTypes.bool,
        email: PropTypes.string
      })
    }).isRequired,
    doResetClearState: PropTypes.func.isRequired,
    doResetTokenValidate: PropTypes.func.isRequired,
    doUpdateUrl: PropTypes.func.isRequired,
  }

  static defaultProps = {
    token: ''
  }

  constructor(props) {
    super(props)
    this.setState = this.setState.bind(this)
  }

  componentDidMount() {
    this.props.doResetClearState()
    this.props.doResetTokenValidate({ token: this.props.token })
  }

  componentDidUpdate() {
    const { loading, response } = this.props.tokenValidationResponse
    const { valid, email } = response

    if (!loading && !valid && email) {
      this.props.doUpdateUrl(PUBLIC_ROUTES.FORGOT)
    }
  }

  render() {
    const {
      auth: {
        reset: { loading, error },
      },
      firstTime,
      formikProps = {},
      tokenValidationResponse,
    } = this.props
    const { errors = {} } = formikProps

    const { loading: awaitingTokenValidation, response } = tokenValidationResponse
    const { valid } = response

    let errorMessage = null
    if (error || !valid) {
      let innerContent = error
      if (!innerContent && !valid) {
        innerContent = (
          <>
            {i18n`Reset token is invalid. Please go to the `}
            <T.Subtitle.Lite
              underline
              color="primary"
              component="a"
              href={PUBLIC_ROUTES.FORGOT}
            >
              {i18n`forgot password`}
            </T.Subtitle.Lite>
            {i18n` page to send a new one.`}
          </>
        )
      }
      errorMessage = innerContent ? (
        <Alert severity="error" variant="outlined" style={{ display: 'inline-flex' }}>
          <T.Subtitle.Lite align="center">
            {innerContent}
          </T.Subtitle.Lite>
        </Alert>
      ) : null
    }

    if (awaitingTokenValidation) {
      return <Loading />
    }

    const fields = Object.fromEntries(
      Object.entries(SUBMIT_REQUIREMENTS)
        .map(([key, { description }]) => [key, { description, error: errors[key] }])
    )

    return (
      <Grid data-testid="password-reset-route" container spacing={0}>
        <Grid item xs={12} sx={{ paddingBottom: theme => theme.spacing(3) }}>
          <PageTitle
            disablePortal
            title={firstTime ? i18n`Welcome to ${config.APP}` : i18n`Reset your password`}
            titleText={firstTime ? i18n`Welcome` : i18n`Reset password`}
            align="center"
          />
        </Grid>
        {errorMessage ? (
          <div className="aroya-login-banner">
            {errorMessage}
          </div>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Box
            component={Form}
            data-testid="reset-form"
            px={{ xs: 0, sm: 'calc(50% - 260px)' }}
          >
            <Box component="fieldset" border="none" display="flex" flexDirection="column" gap="1rem">
              {firstTime ? (
                <T.Body align="center">
                  {i18n`To get started, please create a password for your account.`}
                </T.Body>
              ) : null}
              <SetPassword fields={fields} valid={valid} />
              <Box textAlign="center">
                <Button
                  data-testid="submit-button"
                  type="submit"
                  disabled={loading || !valid || !formikProps?.isValid || !formikProps?.dirty}
                >
                  {i18n`${firstTime ? i18n`Create` : i18n`Reset`} password`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

const Connected = props => {
  const { queryObject, ...connectedProps } = useConnect(
    'selectAuth',
    'selectQueryObject',
    'selectRouteParams',
    'selectTokenValidationResponse',
    'doResetPassword',
    'doResetClearState',
    'doResetTokenValidate',
    'doUpdateUrl',
  )
  const { routeParams, doResetPassword } = connectedProps
  const handleSubmit = payload => {
    doResetPassword(payload)
  }
  return (
    <Formik
      validateOnMount
      initialValues={{
        password1: '',
        password2: '',
        token: routeParams.token,
      }}
      onSubmit={handleSubmit}
      validate={values => Object.fromEntries(
        Object.entries(SUBMIT_REQUIREMENTS)
          .filter(([, { test }]) => test(values))
          .map(([key]) => [key, true])
      )}
    >
      {formikProps => (
        <ResetPasswordComponent
          {...connectedProps}
          {...props}
          formikProps={formikProps}
          token={routeParams.token}
          firstTime={Boolean(queryObject.first)}
        />
      )}
    </Formik>
  )
}
export default Connected
/* eslint-enable no-restricted-imports */
