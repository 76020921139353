import { isValidElement } from 'react'

import PropTypes from 'prop-types'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT, noop } from '~/src/Lib/Utils'

import { useAllowed } from './utils'

const logger = createLogger('Flags/Allowed')

export const Allowed = ({
  children = null,
  Fallback: OriginalFallback = noop,
  FallbackProps = EMPTY_OBJECT,
  PermissionFallback = OriginalFallback,
  ...props
}) => {
  const allowedResult = useAllowed(props)
  if (!Array.isArray(allowedResult)) {
    console.error('allowedResult is not an array', { allowedResult, ...props })
    return <OriginalFallback {...FallbackProps} />
  }
  const [isAllowed, failedPermissions] = allowedResult
  logger.debug('Allowed:', { isAllowed, failedPermissions, props })
  if (isAllowed) {
    return children
  }
  const Fallback = failedPermissions ? PermissionFallback : OriginalFallback

  return isValidElement(Fallback) ? Fallback : <Fallback {...FallbackProps} />
}
const flagType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.string])),
])
const appType = PropTypes.oneOf(['AROYA'])

/* eslint-disable react/no-unused-prop-types */
Allowed.propTypes = {
  actions: flagType,
  anyAction: PropTypes.bool,
  anyFeature: PropTypes.bool,
  features: flagType,
  apps: PropTypes.oneOfType([appType, PropTypes.arrayOf(appType)]),
  children: PropTypes.node,
  Fallback: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  // eslint-disable-next-line react/forbid-prop-types
  FallbackProps: PropTypes.object,
  PermissionFallback: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element])
}
