import { getUrlDefaults } from '~/src/Lib/Nav'
import { FACILITY_ROUTE_PREFIX } from '~/src/Routes/constants'

const urlDefaults = getUrlDefaults('rooms')
const urls = {
  ...urlDefaults,
  dashboard: urlDefaults.getUrl({ id: ':id', action: 'dashboard' }),
  inventory: urlDefaults.getUrl({ id: ':id', action: 'inventory' }),
}
const RDRoutePattern = new RegExp(`^${[urls.view, urls.dashboard].join('|')}`)

export const isRoomDashboardRoute = ({ pattern = '' }) => RDRoutePattern.test(pattern.replace(FACILITY_ROUTE_PREFIX, ''))

export default urls
