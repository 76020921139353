import { memo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'

import { Box } from '@mui/material'

import { lazyWithRetries } from '~/src/Lib/Utils'
import AroyaLogo from '~/src/UI/Assets/Aroya_Addium_Logo.svg'

const NotFound = lazyWithRetries(() => import('~/src/Routes/NotFound'))

const displayName = 'AccountCreationWrapper'

const FlagAccountCreationWrapper = ({
  handler: Handler,
  requiredToken,
}) => {
  const { accountCreation } = useConnect('selectAccountCreation')
  const { token } = accountCreation

  return (token && requiredToken) || !requiredToken ? (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 25,
          left: 25
        }}
      >
        <img src={AroyaLogo} alt={i18n`Aroya Logo`} />
      </Box>
      <Handler />
    </>
  ) : <NotFound />
}

FlagAccountCreationWrapper.displayName = displayName
FlagAccountCreationWrapper.propTypes = {
  handler: PropTypes.elementType.isRequired,
  requiredToken: PropTypes.bool.isRequired,
}

const Memoized = memo(FlagAccountCreationWrapper)
export default Memoized
