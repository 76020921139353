import { useEffect, useRef } from 'react'

import memoizeOne from 'memoize-one'
import {
  equals,
  fromPairs,
  map,
  omit,
  pick,
  pipe,
  prop,
  splitEvery,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import {
  activeUserAllowedTypes,
  annotationTypeMap,
  annotationTypes,
  defaultAllowedTypes,
} from '~/src/Annotations/constants'
import { selectZonesForHarvest } from '~/src/Harvest/bundle'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  getId,
  isEqualDateTime,
  noop,
  shallowEquals,
} from '~/src/Lib/Utils'
import { DEFAULT_TIMEFRAME, SETTINGS_PATHS } from '~/src/Room/constants'

import { defaultState } from './defaults'
import { createNamespacedLogger } from './logger'

const logger = createNamespacedLogger('utils#query')

export const getQueryParam = (query, paramName, defaultValue) => {
  const param = query[paramName]
  const paramType = typeof param
  if (paramType === 'undefined') return defaultValue
  const defaultType = typeof defaultValue
  // this allows use to use key=true|false in the query and get a boolean
  if (paramType !== defaultType && paramType === 'string' && defaultValue != null) {
    try {
      return JSON.parse(param)
    } catch (error) {
      logger.debug('Failed to parse query param', { paramName, param, error })
      return defaultValue
    }
  }
  return param
}

const stateToUrlMap = {
  chartTimeframe: 'timeframe',
  cursorMode: false,
  individualGraphs: false,
  individualSensors: false,
  journalOpen: false,
  manualFrom: 'from',
  manualTo: 'to',
  openAnnotation: 'annotation',
  selectedHarvest: 'harvest',
  selectedZones: 'zones',
  selectedDataTypes: 'dataTypes',
  showRoomAvg: false,
  showYAxis: false,
  viewMode: false,
}
const stateToUrlKeys = Object.freeze(Object.keys(stateToUrlMap))

const urlToStateMap = Object.freeze(Object.fromEntries(
  Object.entries(stateToUrlMap)
    .map(([key, val]) => [val || key, key])
))

const nonTransferableKeys = new Set(['harvest', 'zones', 'annotation'])

const getStateForUrl = pick(stateToUrlKeys)

const dateDefaultValue = (key, val, state) => {
  const defaultKey = key.replace('manual', 'default')
  const defaultVal = state[defaultKey]

  return isEqualDateTime(defaultVal, val) || (key.endsWith('To') && val === 'now')
}
const defaultValueTests = {
  selectedDataTypes: (key, value, state) => {
    const { initialized: loaded, loading } = state
    if (loading || !loaded) return true
    return defaultValueTests.fallback(key, value)
  },
  manualFrom: dateDefaultValue,
  manualTo: dateDefaultValue,
  selectedZones: (_, val, state) => {
    const { growlog, room, harvest } = state
    const harvestZones = selectZonesForHarvest(harvest)
    const zones = growlog && harvest ? harvestZones : (room?.zones ?? EMPTY_ARRAY).map(getId)

    return zones?.every(zoneId => val.includes(zoneId)) || !val?.length
  },
  selectedHarvest: (_, val) => !val?.id,
  fallback: (key, val) => {
    if (typeof val === 'object' && val != null) {
      return shallowEquals(defaultState[key], val)
    }
    return defaultState[key] === val
  },
  chartTimeframe: (key, val, state) => {
    const { growlog, harvest } = state

    if (growlog && harvest) {
      const now = getDateTime('now')
      return val === (
        now > getDateTime(harvest.endDate ?? now)
          ? 'selectedHarvest'
          : DEFAULT_TIMEFRAME
      )
    }

    return defaultValueTests.fallback(key, val)
  },
}

const isDefaultVal = (...args) => {
  const [key] = args
  const getIsDefaultVal = defaultValueTests[key] ?? defaultValueTests.fallback
  return getIsDefaultVal(...args)
}

const persistentSettings = [
  'from',
  'to',
  'individualGraphs',
  'individualSensors',
  'cursorMode',
  'dataTypes',
  'depths',
  'showYAxis',
  'showRoomAvg',
  'timeframe',
  'annotationUser',
  'viewMode',
  ...annotationTypes.map(prop('queryParam')),
]

const persistPicker = pick(persistentSettings)

const getQuery = memoizeOne(state => {
  const {
    activeAnnotationTypes = EMPTY_ARRAY,
    activeAnnotationUser,
    growlog,
  } = state

  const currState = getStateForUrl(state)

  const query = Object.entries(currState).reduce((acc, [key, val]) => {
    if (growlog && key === 'selectedHarvest') return acc
    const isDefault = isDefaultVal(key, val, state)

    if (val == null || isDefault) {
      return acc
    }

    const finalKey = stateToUrlMap[key] || key

    return {
      ...acc,
      [finalKey]: val?.id ?? val,
    }
  }, {})

  if (activeAnnotationUser) {
    query.annotationUser = activeAnnotationUser.id
  }

  const hiddenTypes = (activeAnnotationUser ? activeUserAllowedTypes : defaultAllowedTypes)
    .filter(t => !activeAnnotationTypes.includes(t))

  if (hiddenTypes.length) {
    hiddenTypes.forEach(t => {
      const { [t]: type } = annotationTypeMap

      if (type && type.queryParam) {
        query[type.queryParam] = null
      }
    })
  }

  return query
})

const hydrateBoolean = rawVal => {
  if (rawVal === 'true') return true
  if (rawVal === 'false') return false
  return !!Number(rawVal)
}

const hydrateDateTime = rawVal => {
  if (rawVal) {
    const parsed = getDateTime(rawVal)
    if (parsed.isValid) {
      return parsed
    }
  }
  return undefined
}

const hydrateList = list => {
  if (list === EMPTY_ARRAY) return list
  if (Array.isArray(list)) {
    return list.map(Number)
  }
  if (typeof list === 'number') {
    return [list]
  }
  return (list.includes(':') ? list.split(':') : Array.of(list)).map(Number)
}

/**
 * Hydrators to convert query params to expected room dashboard state values
 * @type {Object.<string, function>}
 * @property {function(any): string} $default - Default hydrator that is used if key not in hydrators
 */
const queryHydrators = {
  $default: String,
  annotationUser: Number,
  dataTypes: list => {
    if (Array.isArray(list)) {
      return list
    }
    return list.includes(':') ? list.split(':') : Array.of(list)
  },
  harvest: Number,
  individualGraphs: hydrateBoolean,
  individualSensors: hydrateBoolean,
  journalOpen: hydrateBoolean,
  showRoomAvg: hydrateBoolean,
  showYAxis: hydrateBoolean,
  zones: hydrateList,
  depths: hydrateList,
  from: hydrateDateTime,
  to: hydrateDateTime,
  ...annotationTypes.reduce((acc, { queryParam }) => ({ ...acc, [queryParam]: noop }), EMPTY_OBJECT)
}
const dehydratedToRehydratedKeys = {
  f: 'timeframe',
  h: 'selectedHarvest',
  n: 'annotation',
  d: 'dataTypes',
  g: 'individualGraphs',
  s: 'individualSensors',
  j: 'journalOpen',
  a: 'showAlerts',
  c: 'showComments',
  m: 'showMeasurements',
  r: 'showRoomAvg',
  y: 'showYAxis',
  z: 'zones',
}
const dehydratedPattern = new RegExp(
  `(${Object.keys(dehydratedToRehydratedKeys).join('|')})\\.`
)

const zipIt = pipe(
  splitEvery(2),
  map(([rawKey, rawVal]) => {
    const key = rawKey in dehydratedToRehydratedKeys
      ? dehydratedToRehydratedKeys[rawKey]
      : rawKey
    const val = key in queryHydrators ? queryHydrators[key](rawVal) : rawVal
    return [key, val]
  }),
  fromPairs
)

const isSlug = rawQuery => {
  const keys = Object.keys(rawQuery)
  const [first] = keys
  return keys.length === 1 && rawQuery[first] == null && dehydratedPattern.test(first)
}

export const rehydrateQuery = memoizeOne((rawQuery, withDefaults = false, includeNonTransferable = false) => {
  const rqKeys = typeof rawQuery === 'string' ? Array.of(rawQuery) : Object.keys(rawQuery)
  const [first] = rqKeys

  if (isSlug(rawQuery)) {
    const split = first.split(dehydratedPattern).slice(1)
    return zipIt(split)
  }

  let query = rawQuery
  if (withDefaults) {
    query = Object.entries(urlToStateMap).reduce((acc, [settingsKey, stateKey]) => {
      if (!includeNonTransferable && nonTransferableKeys.has(settingsKey)) return acc
      if (settingsKey in rawQuery) {
        acc[settingsKey] = rawQuery[settingsKey]
      } else {
        acc[settingsKey] = defaultState[stateKey]
      }
      return acc
    }, {})
  }

  return Object.entries(query).reduce((acc, [rawKey, rawVal]) => {
    const key = dehydratedToRehydratedKeys[rawKey] || rawKey
    const { [key]: hydrator = queryHydrators.$default } = queryHydrators

    return hydrator
      ? {
        ...acc,
        [key]: rawVal == null ? rawVal : hydrator(rawVal),
      }
      : acc
  }, EMPTY_OBJECT)
}, (left, right) => shallowEquals(left, right, 3))

export const queryToPatch = (rawQuery, withDefaults = false, includeNonTransferable = false) => {
  const rehydrated = rehydrateQuery(rawQuery, withDefaults, includeNonTransferable)
  return Object.entries(rehydrated).reduce((patch, [key, val]) => {
    patch[urlToStateMap[key]] = val
    return patch
  }, {})
}

export const useUrlState = state => {
  const { growlog, initialized: loaded, loading = false, selectedZones } = state

  const {
    queryAdvanced,
    doUpdateQueryAdvanced: doUpdateQuery,
    doUpdateMySettings,
  } = useConnect('selectQueryAdvanced', 'doUpdateQueryAdvanced', 'doUpdateMySettings')
  const prevQuery = rehydrateQuery(queryAdvanced)
  let newQuery = getQuery(state)

  const toOmit = []
  if (!!(newQuery.from || newQuery.to) && newQuery.timeframe) {
    toOmit.push('timeframe')
  }
  if (newQuery.harvest && newQuery.zones?.length) {
    toOmit.push('zones')
  }
  Object.keys(newQuery).forEach(key => {
    if (Array.isArray(newQuery[key]) && !newQuery[key].length) {
      toOmit.push(key)
    }
  })
  if (toOmit.length) {
    newQuery = omit(toOmit, newQuery)
  }

  const newPersist = persistPicker(newQuery)
  const prevPersist = persistPicker(prevQuery)
  const urlDebounce = useRef(0)

  useEffect(() => {
    if (!loaded || loading) {
      return
    }

    const now = Date.now()
    if (urlDebounce.current > now - 500) {
      // logger.debug('debouncing url update', now - urlDebounce.current, 'ms since last call')
      urlDebounce.current = Date.now()
      return
    }

    const queryChanged = !equals(prevQuery, newQuery)
    const queryIsSlug = isSlug(queryAdvanced)
    if (queryIsSlug || queryChanged) {
      doUpdateQuery(newQuery, { replace: true })
    }

    if (!equals(newPersist, prevPersist)) {
      doUpdateMySettings({
        path: growlog ? SETTINGS_PATHS.growlog : SETTINGS_PATHS.roomDashboard,
        value: newPersist
      })
    }
    urlDebounce.current = Date.now()
  }, [
    prevQuery,
    newQuery,
    prevPersist,
    newPersist,
    queryAdvanced,
    loading,
    loaded,
    growlog,
    doUpdateMySettings,
    doUpdateQuery,
    selectedZones
  ])
}
