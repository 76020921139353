import { cloneElement, isValidElement, memo } from 'react'

import memoizeOne from 'memoize-one'
import PropTypes from 'prop-types'

import { stylePropType } from '~/src/Lib/PropTypes'
import { dtLabelSanitizer, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { dataTypeMap } from '~/src/UI/Icons'

const defaultStyle = {
  textTransform: 'none',
}

const getStyle = memoizeOne(({ dataType = EMPTY_OBJECT, style = EMPTY_OBJECT }) => {
  const { color = 'inherit' } = dataType
  return { ...defaultStyle, color, ...style }
})

/**
 * A component for rendering data type names
 * @component
 * @example
 * <div className="default-display">
 *   <T noWrap>
 *     <DataTypeName icon dataType={{ name: 'EC<sub>pw</sub>', key: 'pore_ec', color: 'yellow' }} />
 *   </T>
 *   <T noWrap>
 *     <DataTypeName icon dataType={{ name: 'Water Potential', key: 'water_potential', color: 'cyan' }} />
 *   </T>
 *   <T noWrap>
 *     <DataTypeName icon dataType={{ name: 'VPD', key: 'vpd', color: 'limegreen' }} />
 *   </T>
 *   <DataTypeName
 *     icon
 *     caption={<T.Tiny color="inherit" component="span" display="inline">3.56 <small>dS/m</small></T.Tiny>}
 *     component={({ style, ...props }) => (
 *        <Box
 *          component={T.Tiny}
 *          display="flex"
 *          flexDirection="column"
 *          alignItems="center"
 *          style={{ ...style, gap: 2 }}
 *          {...props}
 *        />
 *      )}
 *     dataType={{ name: 'EC<sub>pw</sub>', key: 'pore_ec', color: 'yellow' }}
 *     iconStyle={{ margin: 0 }}
 *   />
 * </div>
 */
const DataTypeName = ({
  caption: captionRaw = null,
  Component = 'span',
  Icon = undefined,
  iconStyle = { marginRight: '0.5rem' },
  name,
  style = EMPTY_OBJECT,
  ...rest
}) => {
  const content = (
    /* eslint-disable react/no-danger */
    <span className="datatype-name" dangerouslySetInnerHTML={{ __html: dtLabelSanitizer(name) }} />
    /* eslint-enable react/no-danger */
  )
  let caption = captionRaw
  if (isValidElement(captionRaw)) {
    caption = cloneElement(captionRaw, {
      className: captionRaw.props.className
        ? `${captionRaw.props.className} datatype-caption`
        : 'datatype-caption'
    })
  }
  if (caption && typeof caption === 'string') {
    caption = <span className="datatype-caption">{caption}</span>
  }

  return (
    <Component {...rest} style={style}>
      {caption}
      {Icon ? <Icon className="datatype-icon" color="inherit" style={iconStyle} /> : null}
      {content}
    </Component>
  )
}

DataTypeName.propTypes = {
  caption: PropTypes.string,
  Component: PropTypes.elementType,
  Icon: PropTypes.elementType,
  iconStyle: stylePropType,
  name: PropTypes.string.isRequired,
  style: stylePropType,
}

const Wrapped = ({ component, icon, name, dataType, style, ...passthru }) => {
  const { [icon && dataType && dataType.key?.toLowerCase()]: Icon } = dataTypeMap
  return (
    <DataTypeName
      {...passthru}
      Component={component}
      Icon={Icon}
      name={name ?? dataType?.name}
      style={getStyle({ dataType, style })}
    />
  )
}

const Memoized = memo(Wrapped)
export default Memoized
