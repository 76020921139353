import i18n from 'i18n-literally'
import {
  groupBy,
  pipe,
  prop,
  reduce,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { titleize } from 'inflection'
import { normalize } from 'normalizr'

import createEntityBundle, {
  createCustomAction,
  doEntitiesReceived,
  getActionType,
} from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formattedDate,
  getDateTime,
  getListLabel,
  parseApiErrors,
} from '~/src/Lib/Utils'
import { EMPTY_CHART, markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { FacilityCultivar, facilityScope as scope, Harvest as schema } from '~/src/Store/Schemas'

import { CULTIVATION_ROOM_TYPES } from '../constants'
import { selectZonesForHarvest } from './selectors'
import { prepareData } from './shape'

const name = 'harvests'
const singularName = 'harvest'
const defaultConfig = { name, singularName, schema }

const HARVEST_PHASE_SET_CURRENT = 'HARVEST_PHASE_SET_CURRENT'
export const WEIGHT_FIELDS = ['wetWeight', 'dryWeight', 'flowerWeight', 'trimWeight']
export const METRC_FIELDS = ['dryWeight', 'wetWeight']
const ERROR_REPLACEMENTS = {
  'Please select unique rooms.': i18n`A harvest group may not leave a room then return to it in a later phase.`,
}

const formatError = error => {
  if (Array.isArray(error)) {
    return error.map(formatError)
  }
  return ERROR_REPLACEMENTS[error] || error
}

const logger = createLogger('Harvest/bundle')
const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }
const apiConfig = {
  formatError,
  schema,
  prepareData,
  save: writesConfig,
  delete: writesConfig,
}
const initialBundle = createEntityBundle({
  ...defaultConfig,
  apiConfig,
  customActions: [{
    action: 'advance',
    async: 'true',
    handler: markAnnotationsOutdatedWrapper(({
      payload: { id, ...payload },
      apiFetch
    }) => apiFetch(`/harvests/${id}/advance/`, payload, { method: 'PUT' })),
    snackBar: ({ status, dispatch, payload, response, store }) => {
      const phases = store.selectPhases()
      const harvest = store.selectHarvest()
      const { [payload.currentPhase]: currentSub } = phases

      let message
      if (status === 'succeed') {
        if (payload.currentPhase === response.currentPhase?.id) {
          message = i18n`Postponed end of ${currentSub.name} until ${formattedDate(payload.endDate, 'SHORT_DATE')}`
        } else {
          const { currentPhase } = response
          const now = getDateTime('now')
          const currentStart = getDateTime(currentPhase.startDate)
          message = i18n`Started ${currentPhase.name} as of ${
            now.hasSame(currentStart, 'day') ? i18n`today` : formattedDate(currentStart, 'SHORT_DATE')
          }.`
        }
      } else if (!payload.endDate || getDateTime(payload.endDate).hasSame(getDateTime(currentSub.endDate), 'day')) {
        const nextPhaseId = harvest.phases.find(id => phases[id]?.sequence === currentSub.sequence + 1)
        const { [nextPhaseId]: nextPhase } = phases
        message = i18n`Failed to advance to ${nextPhase?.name ?? i18n`next phase`}.`
      } else {
        message = i18n`Failed to postpone ${currentSub.name} until ${formattedDate(currentSub.endDate, 'SHORT_DATE')}`
      }

      if (message) {
        dispatch({ actionCreator: 'doaddSnackbarMessage', args: [message] })
      }
    }
  }, {
    action: 'apply_recipe',
    async: 'true',
    handler: markAnnotationsOutdatedWrapper(({
      payload,
      apiFetch
    }) => apiFetch(`/harvests/${payload.id}/apply_recipe/`, payload, { method: 'POST' })),
    snackBar: ({ dispatch, error, payload, status, store }) => {
      const { name: harvestName } = store.selectHarvest()
      const { [payload.recipe]: recipe } = store.selectRecipes()
      if (status === 'succeed') {
        dispatch({
          actionCreator: 'doaddSnackbarMessage',
          args: [{
            message: i18n`Successfully applied recipe "${recipe.name}" to ${harvestName}`,
            status: 'success'
          }]
        })
      } else {
        dispatch({
          actionCreator: 'doaddSnackbarMessage',
          args: [{
            message: i18n`Failed to apply recipe to "${recipe.name}" ${harvestName}: ${parseApiErrors(error)}`,
            status: 'error',
          }]
        })
      }
    }
  }],
  scope,
})

const {
  reducer: entityReducer,
  doHarvestSave: { createSnackbarAction },
} = initialBundle

const {
  actionIdentifiers: fetchMetrcBatch,
  actionReducer: metrcReducer,
  defaultState: defaultMetrcState
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'metrc_batch',
  reducerKey: 'metrc'
})

const {
  actionIdentifiers: fetchHarvestTransitions,
  actionReducer: harvestTransitionsReducer,
  defaultState: defaultHarvestTransitionsState,
} = createCustomAction({
  actionType: 'fetch',
  actionName: 'harvest_transitions',
  reducerKey: 'transitions',
})

const {
  actionIdentifiers: saveYield,
  actionReducer: yieldReducer,
  defaultState: defaultYieldState,
} = createCustomAction({
  actionType: 'save',
  actionName: 'harvest_yield',
  reducerKey: 'yield',
  loadingKey: 'saving'
})

const initialState = {
  ...entityReducer.defaultState,
  ...defaultMetrcState,
  ...defaultHarvestTransitionsState,
  ...defaultYieldState,
}

const selectGrowthPhasesPipeline = pipe(
  groupBy(prop('phaseType')),
  Object.entries,
  reduce((growthPhases, [phaseType, subs]) => {
    const phaseTiming = subs.reduce((gp, sub) => {
      const { startDate = sub.startDate, endDate = sub.endDate, totalDays = 0 } = gp

      return {
        startDate: startDate > sub.startDate ? sub.startDate : startDate,
        endDate: endDate < sub.endDate ? sub.endDate : endDate,
        totalDays: totalDays + sub.totalDays
      }
    }, EMPTY_OBJECT)

    const subPhases = subs.map(subPhase => {
      const { id, name: subPhaseName, startDate, endDate, totalDays } = subPhase

      return {
        id,
        name: subPhaseName,
        startDate,
        endDate,
        totalDays,
        phaseStartDay: Math.round(getDateTime(startDate).diff(getDateTime(phaseTiming.startDate)).as('days') + 1)
      }
    })

    return {
      ...growthPhases,
      [phaseType]: {
        ...phaseTiming,
        phaseType,
        subPhases,
      }
    }
  }, EMPTY_OBJECT)
)
/**
 * Takes a rehydrated harvest entity from useHarvestEntity and returns an object that contains
 * phaseTypes as keys and subPhases, start, end, totalDays
 */
export const selectGrowthPhases = phases => (
  Array.isArray(phases)
    ? selectGrowthPhasesPipeline(phases)
    : EMPTY_OBJECT
)

export default {
  ...initialBundle,
  reducer: (state = initialState, action = EMPTY_OBJECT) => {
    if (!action.type) return state
    if (action.type.startsWith(fetchMetrcBatch.types.prefix)) {
      return metrcReducer(state, action)
    }
    if (action.type.startsWith(fetchHarvestTransitions.types.prefix)) {
      return harvestTransitionsReducer(state, action)
    }
    if (action.type.startsWith(saveYield.types.prefix)) {
      return yieldReducer(state, action)
    }
    if (action.type === HARVEST_PHASE_SET_CURRENT) {
      return { ...state, harvestPhase: action.payload ?? null }
    }
    return entityReducer(state, action)
  },
  doFetchMetrcHarvest: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: fetchMetrcBatch.types.start })
    let result = false
    try {
      result = await apiFetch('/harvests/metrc/', payload)
      const { entities } = normalize(result.map(batch => batch.cultivar), [
        FacilityCultivar,
      ])
      dispatch(doEntitiesReceived(entities))
      dispatch({ type: fetchMetrcBatch.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({
        type: fetchMetrcBatch.types.fail,
        error,
      })
    }
    return result
  },
  doFetchHarvestTransitions: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: fetchHarvestTransitions.types.start })
    let result = false
    try {
      result = await apiFetch(`/harvests/${payload}/transitions/`)
      dispatch({ type: fetchHarvestTransitions.types.succeed, payload: result })
    } catch (error) {
      result = error
      dispatch({
        type: fetchHarvestTransitions.types.fail,
        error,
      })
    }
    return result
  },
  doHarvestYieldSave: payload => async ({ dispatch, apiFetch }) => {
    dispatch({ type: saveYield.types.start })
    let result = false
    try {
      result = await apiFetch(`/harvests/${payload.id}/yield_data/`, payload, {
        method: 'PUT',
      })
      dispatch({ type: saveYield.types.succeed, payload: result })
      createSnackbarAction({ dispatch, status: 'succeed', payload: result })
      const { entities } = normalize(result, schema)
      dispatch(doEntitiesReceived(entities))
    } catch (error) {
      result = error
      dispatch({ type: saveYield.types.fail, error })
      createSnackbarAction({ dispatch, status: 'fail', payload, error })
    }
    return result
  },
  // doHarvestApplyRecipe: payload => async ({ apiFetch, store }) => {
  //   let result
  //   try {
  //     result = await apiFetch(`/harvests/${payload.id}/apply_recipe/`, payload, { method: 'POST' })
  //     store.doAddSnackbarMessage('Successfully applied recipe')
  //   } catch (error) {
  //     store.doAddSnackbarMessage('Failed to apply recipe')
  //   }
  //   return result
  // },
  selectHarvestAnnotations: createSelector(
    'selectHarvest',
    'selectAnnotations',
    'selectEntities',
    (harvest, annotationsRoot, entitiesRoot) => {
      if (!harvest || !harvest?.cultivars) {
        return EMPTY_ARRAY
      }
      const chartId = harvest.uuid ?? `harvest_${harvest.id}`
      const { [chartId]: annotations } = annotationsRoot
      const harvestZones = selectZonesForHarvest(harvest)
      const harvestInterval = getDateTime(harvest.startDate).until(getDateTime(harvest.endDate ?? 'now'))

      return annotations ? annotations?.map?.(item => {
        const { uuid } = item
        const [type, id] = uuid.split('_')
        const { [type]: entities = EMPTY_OBJECT } = entitiesRoot
        const { [id]: annotation } = entities

        if (!annotation) {
          logger.warn('no annotation found for:', { uuid, entitiesRoot })
        }

        return {
          ...item,
          annotation
        }
      })?.filter(item => {
        if (!item.annotation) return false
        const { annotation: { phase, phaseId, zone }, timestamp } = item
        const inHarvestPhases = (!phase && !phaseId) || (
          Array.isArray(harvest.phases) && harvest.phases.includes(phase ?? phaseId)
        )
        const inHarvestZones = !zone || harvestZones.includes(zone)
        const inHarvestInterval = harvestInterval.contains(getDateTime(timestamp))
        if (!inHarvestPhases || !inHarvestZones || !inHarvestInterval) {
          return false
        }
        return true
      })?.reverse() : EMPTY_ARRAY
    }
  ),
  selectHarvestChart: createSelector(
    'selectHarvest',
    'selectChartRoot',
    (harvest, chartRoot) => {
      const chartId = harvest?.uuid ?? (harvest?.id ? `harvests_${harvest.id}` : '')
      if (!chartId) return EMPTY_CHART
      if (chartRoot.stale[chartId]) {
        return { ...EMPTY_CHART, stale: true }
      }
      const {
        data: { [chartId]: data = EMPTY_OBJECT },
        inflight: { [chartId]: inflight },
        params: { [chartId]: params = EMPTY_OBJECT },
        fetch: { [chartId]: fetch = EMPTY_OBJECT },
        stale: { [chartId]: stale = false }
      } = chartRoot
      const { graphs } = data
      const graphZones = graphs && graphs.reduce((acc, { id, zone }) => {
        const zoneIdentifier = zone != null ? zone : id.split(':').slice(-2).join('_')
        if (acc.includes(zoneIdentifier)) {
          return acc
        }
        return [...acc, zoneIdentifier]
      }, EMPTY_ARRAY)

      return { chartId, data, fetch, graphZones, inflight, params, stale }
    }
  ),
  selectPhasesByHarvest: createSelector(
    'selectHarvests',
    'selectPhases',
    'selectRecipes',
    (harvests, phases, recipes) => {
      const phasesByHarvest = Object.values(harvests).reduce((pbh, harvest) => {
        if (!harvest?.id || !harvest?.phases?.length) return pbh
        pbh[harvest.id] = harvest.phases.map(id => phases[id]).filter(Boolean).sort((a, b) => a.sequence - b.sequence)
        return pbh
      }, {})
      return Object.values(recipes).reduce((pbh, recipe) => {
        if (!recipe?.id || !recipe?.phases?.length) return pbh
        pbh[recipe.id] = recipe.phases
        return pbh
      }, phasesByHarvest)
    }
  ),
  selectCurrentPhaseByHarvest: createSelector(
    'selectHarvests',
    'selectPhasesByHarvest',
    'selectPhases',
    (harvests, phasesByHarvest, phases) => {
      if (!Object.values(phasesByHarvest).length) return []

      const growingHarvests = Object.values(harvests).filter(
        h => h.currentPhase
      )
      return growingHarvests?.length > 0
        ? growingHarvests.reduce((cpbh, harvest) => {
          const { [harvest.currentPhase]: currentSubPhase } = phases
          const { [harvest.id]: harvestPhases } = phasesByHarvest
          if (!harvestPhases) return cpbh

          const subPhases = harvestPhases
            .filter(p => p.phaseType === currentSubPhase.phaseType)
            .sort((a, b) => a.sequence - b.sequence)

          return {
            ...cpbh,
            [harvest.id]: {
              currentSubPhase,
              subPhases,
              name: titleize(currentSubPhase.phaseType),
              startDate: subPhases[0].startDate,
              endDate: subPhases[subPhases.length - 1].endDate,
              totalDays: subPhases.reduce((days, p) => days + p.totalDays, 0),
            },
          }
        }, EMPTY_OBJECT)
        : []
    }
  ),
  selectTimingByHarvest: createSelector(
    'selectHarvests',
    'selectPhasesByHarvest',
    (harvests, phasesByHarvest) => Object.entries(harvests).reduce((timing, [id, harvest]) => {
      const { startDate, endDate, harvestDate } = harvest
      const { [id]: phases } = phasesByHarvest
      const phaseTiming = selectGrowthPhases(phases)
      let totalDays = Object.values(phaseTiming).reduce((total, phase) => total + phase.totalDays, 0)
      if (!totalDays) {
        totalDays = Math.round(getDateTime(endDate).diff(getDateTime(startDate)).as('days') + 1)
      }
      return {
        ...timing,
        [id]: {
          startDate,
          endDate,
          harvestDate,
          totalDays,
          ...Object.entries(phaseTiming).reduce((phaseTimings, [phaseType, phase]) => ({
            ...phaseTimings,
            [phaseType]: {
              ...phase,
              cultivationStartDay: phase.startDate && startDate
                ? Math.round(getDateTime(phase.startDate).diff(getDateTime(startDate)).as('days') + 1)
                : null
            }
          }), EMPTY_OBJECT)
        }
      }
    }, EMPTY_OBJECT)
  ),
  selectHarvestLocationsByPhaseType: createSelector(
    'selectEntities',
    'selectFacilityLocationsTerm',
    ({
      harvests,
      phases,
      rooms,
      zones
    }, locationTerm) => (
      [harvests, phases, rooms, zones].every(Boolean)
        ? Object.values(harvests ?? EMPTY_OBJECT).filter(h => (
          (h.payloadType === 'entity' && Array.isArray(h.phases) && h.phases.length)
          || (h.currentPhase && Array.isArray(h.currentZones) && h.currentZones.length)
        )).reduce((zonesByHarvest, harvest) => {
          let location
          if (harvest.payloadType === 'entity' && Array.isArray(harvest.phases) && harvest.phases.length) {
            location = CULTIVATION_ROOM_TYPES.reduce((zonesByPhaseRoom, prefix) => {
              if (!harvest[`${prefix}Room`]) return zonesByPhaseRoom
              const room = rooms[harvest[`${prefix}Room`]]
              const phaseZones = [
                ...new Set(harvest.cultivars.flatMap(c => c[`${prefix}Zones`]).filter(Boolean))
              ].map(zoneId => zones[zoneId])
              return {
                ...zonesByPhaseRoom,
                [prefix.toUpperCase()]: {
                  room,
                  zones: phaseZones,
                  zonesLabel: phaseZones.length === room?.zones?.length
                    ? i18n`All ${locationTerm(EMPTY_ARRAY, 'zone')}`
                    : getListLabel(phaseZones)
                }
              }
            }, EMPTY_OBJECT)
          } else if (harvest.currentPhase && Array.isArray(harvest.currentZones) && harvest.currentZones.length) {
            const {
              [harvest.currentPhase]: currentPhase = EMPTY_OBJECT
            } = phases
            const room = rooms[currentPhase.room]
            const currentZones = harvest.currentZones.map(zoneId => zones[zoneId])
            const zonesLabel = currentZones.length === room?.zones?.length
              ? i18n`All ${locationTerm(EMPTY_ARRAY, 'zone')}`
              : getListLabel(currentZones)

            if (currentPhase.phaseType) {
              location = {
                [currentPhase.phaseType]: {
                  room,
                  zones: currentZones,
                  zonesLabel
                }
              }
            }
          }

          return location ? {
            ...zonesByHarvest,
            [harvest.id]: location
          } : zonesByHarvest
        }, EMPTY_OBJECT)
        : EMPTY_OBJECT
    )
  ),
  persistActions: [getActionType('update', name)],
}
