import { useConnect } from 'redux-bundler-hook'

import { FACILITY_SEGMENT_PATTERN } from './constants'
import createLogger from '../Lib/Logging'

const logger = createLogger('RedirectToFacilityPrefixed')

const RedirectToFacilityPrefixed = () => {
  const { currentFacilityId, urlObject, doUpdateUrl } = useConnect(
    'selectCurrentFacilityId',
    'selectUrlObject',
    'doUpdateUrl',
  )
  const { hash, pathname, search } = urlObject

  if (!FACILITY_SEGMENT_PATTERN.test(pathname ?? '')) {
    logger.debug(pathname, 'does not contain a facility segment')
    const prefix = `/f/${currentFacilityId}`
    doUpdateUrl([prefix, pathname, search, hash].join(''))
  }

  return null
}

export default RedirectToFacilityPrefixed
