import i18n from 'i18n-literally'

import { PUBLIC_ROUTES } from './constants'

const publicRoutesPattern = new RegExp(`^(${Object.values(PUBLIC_ROUTES).join('|')})`)
export const isPublicRoute = pathnameOrRouteUrl => publicRoutesPattern.test(pathnameOrRouteUrl)

export const SUBMIT_REQUIREMENTS = {
  minLength: {
    description: i18n`At least 8 characters`,
    test: values => values.password1.length < 8,
  },
  noUpperNoSpecial: {
    description: i18n`At least one lowercase letter or number`,
    test: values => !String(values.password1).match(/[a-z0-9]/),
  },
  oneUppercase: {
    description: i18n`At least one uppercase letter`,
    test: values => !String(values.password1).match(/[A-Z]/),
  },
  oneSpecial: {
    description: i18n`At least one special character: @_!#$%^&*()?`,
    test: values => !String(values.password1).match(/[@_!#$%^&*()?]/),
  },
  passwordsMatch: {
    description: i18n`Passwords must match`,
    test: values => String(values.password1) !== String(values.password2)
      || (!values.password1 || !values.password2),
  },
}
