import createEntityBundle from '~/src/Lib/createEntityBundle'
import { WasteType as schema } from '~/src/Store/Schemas'

const name = 'wasteTypes'

export default createEntityBundle({
  name,
  apiConfig: {
    schema,
  },
})
