import { memo } from 'react'

import classNames from 'clsx'
import PropTypes from 'prop-types'

import { SvgIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'
import { EMPTY_OBJECT, goldenth } from '~/src/Lib/Utils'

const useStyles = makeStyles({
  faded: {
    opacity: goldenth(2),
  },
}, { name: 'DrybackIcon' })

export const paths = [
  `
  M18.6002 17.6561C18.6002 19.9453 16.7004 21.801 14.3568 21.801C12.0133 21.801 10.1135 19.9453
  10.1135 17.6561C10.1135 13.615 14.3568 9.67725 14.3568 9.67725C14.3568 9.67725 18.6002 13.7185
  18.6002 17.6561Z
`,
  `
  M5.96528 20.8331C7.30444 20.8331 8.39004 19.7197 8.39004 18.3462C8.39004 15.9836 5.96528 13.5588
  5.96528 13.5588C5.96528 13.5588 3.54053 15.9215 3.54053 18.3462C3.54053 19.7197 4.62613 20.8331
  5.96528 20.8331Z
`,
  `
  M9.7049 0.88251C10.1134 1.41307 10.0144 2.17429 9.48384 2.58276C9.14674 2.84229 9.01613 3.05897
  8.95943 3.21561C8.90163 3.37527 8.88296 3.57714 8.93835 3.86561C9.05885 4.49321 9.44396 5.19947
  9.96952 6.16331C9.99616 6.21216 10.0232 6.26168 10.0505 6.31189C10.5541 7.2365 11.1922 8.42741
  11.3101 9.6677C11.3725 10.324 11.2941 11.0128 10.9833 11.6935C10.6749 12.3688 10.1699 12.966
  9.48384 13.4942C8.95328 13.9026 8.19205 13.8037 7.78359 13.2731C7.37512 12.7425 7.47409 11.9813
  8.00465 11.5728C8.44595 11.2331 8.66571 10.9313 8.7776 10.6863C8.88697 10.4468 8.92441 10.194
  8.8962 9.89711C8.83289 9.231 8.46436 8.46902 7.92114 7.47174C7.87782 7.39221 7.83297 7.31054
  7.78706 7.22693C7.32416 6.38405 6.75326 5.34448 6.55709 4.32283C6.44136 3.7201 6.43779 3.05789
  6.67945 2.39029C6.92221 1.71966 7.37542 1.14588 8.00465 0.66145C8.5352 0.252983 9.29643 0.351955
  9.7049 0.88251Z
`,
  `
  M3.82453 1.68842C4.23299 2.21897 4.13402 2.9802 3.60347 3.38867C3.26636 3.6482 3.13576 3.86488
  3.07906 4.02151C3.02126 4.18118 3.00259 4.38304 3.05798 4.67151C3.17848 5.29911 3.56359 6.00538
  4.08914 6.96921C4.11578 7.01807 4.14278 7.06759 4.17013 7.11779C4.67377 8.04241 5.31182 9.23332
  5.4297 10.4736C5.49208 11.1299 5.41376 11.8187 5.10288 12.4994C4.79452 13.1747 4.28954 13.7719
  3.60347 14.3001C3.07291 14.7085 2.31168 14.6096 1.90322 14.079C1.49475 13.5484 1.59372 12.7872
  2.12428 12.3788C2.56558 12.039 2.78534 11.7372 2.89723 11.4922C3.0066 11.2527 3.04404 10.9999
  3.01582 10.703C2.95252 10.0369 2.58399 9.27493 2.04077 8.27765C1.99745 8.19812 1.9526 8.11645
  1.90669 8.03284C1.44379 7.18996 0.872889 6.15039 0.676722 5.12874C0.560992 4.52601 0.557418 3.8638
  0.799082 3.19619C1.04184 2.52556 1.49505 1.95179 2.12428 1.46736C2.65483 1.05889 3.41606 1.15786
  3.82453 1.68842Z
`,
]

const DrybackIconComponent = ({
  className = '',
  faded = false,
  ...rest
}) => {
  const classes = useStyles(rest)
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { dryback: dataType = EMPTY_OBJECT } = dataTypes
  const { color = 'blueviolet' } = dataType
  const fill = rest.color || rest.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon
      {...rest}
      className={classNames({
        [classes.root]: !color,
        [classes.faded]: faded,
        [className]: !!className,
      })}
    >
      {/* First two paths didn't have fill-rule or clip-rule */}
      {paths.map(path => (
        <path
          key={path.slice(0, 20)}
          d={path}
          fill={fill}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      ))}
    </SvgIcon>
  )
}
DrybackIconComponent.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  faded: PropTypes.bool,
}
DrybackIconComponent.displayName = 'DrybackIcon'

export default memo(DrybackIconComponent)
