import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { EMPTY_ARRAY, EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { createAppIsReadySelector } from '~/src/Store/utils'

import urls from './urls'

const harvestAnalyticsChartBundle = createAsyncResourceBundle({
  name: 'harvestAnalyticsChart',
  actionBaseType: 'HARVEST_ANALYTICS_CHART',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ apiFetch, store }) => {
    const { harvest: harvestId } = store.selectHarvestAnalyticsRaw()
    if (!Number.isInteger(harvestId)) {
      return Promise.reject(new Error(`Parameter not set: harvest (${harvestId})`))
    }
    const harvest = store.selectHarvests()[harvestId]
    if (!harvest) {
      return Promise.reject(new Error(`Harvest ${harvestId} not found in store`))
    }
    return apiFetch(`/harvests/${harvestId}/chart/`, {
      grouping: 'ROOM',
      start: getDateTime(harvest.startDate).toUTC().toISO(),
      end: getDateTime(harvest.endDate).toUTC().toISO(),
    })
  },
})

export default {
  ...harvestAnalyticsChartBundle,
  reactHarvestAnalyticsChartFetch: createAppIsReadySelector({
    dependencies: [
      'selectHarvestAnalyticsChartShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === urls.analytics) {
        return { actionCreator: 'doFetchHarvestAnalyticsChart' }
      }
      return undefined
    },
  }),
  selectHarvestAnalyticsChartDataByPhase: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectHarvestAnalyticsChart',
    'selectTargetRanges',
    (harvestId, phasesByHarvest, harvestAnalyticsChart, targetRanges) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !harvestAnalyticsChart) {
        return EMPTY_OBJECT
      }
      return harvestAnalyticsChart.dataTypes.reduce((d, dataType) => ({
        ...d,
        [dataType]: phasesByHarvest[harvestId].reduce((dbp, phase) => {
          const start = getDateTime(phase.startDate).toJSDate()
          const end = getDateTime(phase.endDate).toJSDate()
          const key = `${dataType}:room:${phase.room}`
          return {
            ...dbp,
            [phase.id]: {
              domain: {
                x: [start, end],
                y: [harvestAnalyticsChart.bounds.min[dataType], harvestAnalyticsChart.bounds.max[dataType]],
              },
              data: harvestAnalyticsChart.data[key]
                ?.map(datum => ({ ...datum, x: getDateTime(datum.x).toJSDate() }))
                ?.filter(({ x }) => x >= start && x <= end)
              ?? [],
              targetRange: phase.targetRanges?.map(id => targetRanges[id])?.find(tr => tr.dataTypeKey === dataType),
            }
          }
        }, EMPTY_OBJECT),
      }), EMPTY_OBJECT)
    },
  ),
  selectHarvestManualChartsByType: createSelector(
    'selectCurrentHarvestId',
    'selectHarvestAnalyticsChart',
    'selectHarvests',
    'selectPhasesByHarvest',
    (harvestId, harvestAnalyticsChart, harvests, phasesByHarvest) => {
      if (!harvestId || !harvests[harvestId] || !phasesByHarvest[harvestId] || !harvestAnalyticsChart?.manualGraphs) {
        return EMPTY_OBJECT
      }
      const harvest = harvests[harvestId]
      const domain = { x: [new Date(harvest.startDate), new Date(harvest.endDate)] }
      return Object.entries(harvestAnalyticsChart.manualGraphs).reduce((cbt, [key, { color, data }]) => {
        const [dataType, aggType, roomId] = key.split(':')
        const prevData = cbt[dataType]?.data ?? []
        return aggType === 'room' ? {
          ...cbt,
          [dataType]: {
            ...cbt[dataType],
            color,
            data: [...prevData, ...phasesByHarvest[harvestId].reduce((d, phase) => {
              const start = new Date(phase.startDate)
              const end = new Date(phase.endDate)
              return phase.room === Number(roomId) ? [
                ...d,
                ...data
                  .map(datum => ({ ...datum, x: new Date(datum.x) }))
                  .filter(({ x }) => x >= start && x < end)
              ] : d
            }, EMPTY_ARRAY)],
            domain,
          },
        } : cbt
      }, EMPTY_OBJECT)
    },
  ),
  selectHarvestAnalyticsChartManualDataByType: createSelector(
    'selectCurrentHarvestId',
    'selectHarvestAnalyticsChart',
    'selectPhasesByHarvest',
    (currentHarvestId, harvestAnalyticsChart, phasesByHarvest) => {
      if (!currentHarvestId || !phasesByHarvest[currentHarvestId] || !harvestAnalyticsChart) {
        return EMPTY_OBJECT
      }
      const phases = phasesByHarvest[currentHarvestId]
      const { bounds, manualGraphs } = harvestAnalyticsChart
      return Object.entries(manualGraphs).reduce((dbt, [key, value]) => {
        const [dataType, aggType, roomId] = key.split(':')
        return aggType === 'room' ? {
          ...dbt,
          [dataType]: {
            ...dbt[dataType],
            ...phases.reduce((phaseData, phase) => {
              const start = new Date(phase.startDate)
              const end = new Date(phase.endDate)
              return phase.room === Number(roomId) ? {
                ...phaseData,
                [phase.id]: {
                  ...value,
                  phase: phase.id,
                  data: value.data
                    .map(datum => ({ ...datum, x: new Date(datum.x) })),
                  domain: { x: [start, end], y: [bounds.min[dataType], bounds.max[dataType]] },
                },
              } : phaseData
            }, EMPTY_OBJECT),
          },
        } : dbt
      }, EMPTY_OBJECT)
    },
  ),
  selectHarvestAnalyticsChartTargetRangeCounts: createSelector(
    'selectCurrentHarvestId',
    'selectPhasesByHarvest',
    'selectTargetRanges',
    (harvestId, phasesByHarvest, targetRanges = {}) => {
      if (!harvestId || !phasesByHarvest[harvestId] || !targetRanges) {
        return EMPTY_OBJECT
      }
      return phasesByHarvest[harvestId].map(phase => (
        (phase.targetRanges ?? []).map(id => (
          id in targetRanges ? targetRanges[id].dataTypeKey : null
        )).filter(Boolean)
      ))
        .flat()
        .reduce((counts, key) => ({ ...counts, [key]: (counts[key] ?? 0) + 1 }), EMPTY_OBJECT)
    }
  ),
}
