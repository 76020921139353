import {
  always,
  either,
  equals,
  filter,
  findLast,
  flatten,
  groupBy,
  nthArg,
  path,
  pick,
  pipe,
  prop,
  unapply,
  uniq,
} from 'ramda'

import { createSelector } from 'reselect'

import {
  selectZoneIds as selectHarvestZoneIds,
  selectZonesForHarvest,
} from '~/src/Harvest/bundle/selectors'
import { orderedRoomPrefixes } from '~/src/Harvest/utils'
import createLogger from '~/src/Lib/Logging'
import {
  createShallowEqualsSelector,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  getId,
  isNumber,
} from '~/src/Lib/Utils'

import { DEFAULT_TIMEFRAME, TIMEFRAME_PRESETS } from '../constants'

const logger = createLogger('Room/selectors')

export const selectRoomFromProps = prop('room')
const selectRooms = prop('rooms')
const selectAllZones = prop('zones')
const selectAllDevices = prop('devices')
export const selectIdParam = pipe(path(['routeInfo', 'params', 'id']), Number)

export const selectTimeframeFrom = (tf, presets = TIMEFRAME_PRESETS, defaultTimeframe = DEFAULT_TIMEFRAME) => {
  let timeframe = presets[tf]
  if (!timeframe) {
    ({ [defaultTimeframe]: timeframe } = presets)
  }
  return getDateTime('now').minus(timeframe).startOf(timeframe.days ? 'day' : 'hour')
}

/**
 * A selector that returns the `from` date for the chart given the current room dashboard state.
 * @type {function}
 * @param {object} state
 * @param {import('luxon').DateTime} props.manualFrom
 * @param {string} props.chartTimeframe
 * @param {{ phases: { startDate: import('luxon').DateTime } }} props.selectedHarvest
 * @param {{ id: number }} props.room
 */
export const selectFrom = createShallowEqualsSelector(
  prop('manualFrom'),
  prop('chartTimeframe'),
  prop('selectedHarvest'),
  prop('room'),
  (manualFrom, tf = DEFAULT_TIMEFRAME, selectedHarvest = null, room = EMPTY_OBJECT) => {
    let from
    if (manualFrom) {
      from = getDateTime(manualFrom)
    } else if (tf === 'selectedHarvest' && selectedHarvest) {
      const { phases, startDate } = selectedHarvest
      const harvestStartInRoom = room.id && phases?.find(phase => phase?.room === room.id)?.startDate

      from = getDateTime(harvestStartInRoom ?? startDate)
    } else {
      from = selectTimeframeFrom(tf)
    }
    return from
  }
)

export const selectTo = createShallowEqualsSelector(
  prop('manualTo'),
  prop('chartTimeframe'),
  prop('selectedHarvest'),
  prop('room'),
  (manualTo, tf, selectedHarvest, room = EMPTY_OBJECT) => {
    let to
    if (manualTo) {
      to = getDateTime(manualTo)
    } else if (tf === 'selectedHarvest' && selectedHarvest) {
      const { startDate, endDate, harvestDate, phases } = selectedHarvest
      let harvestEndInRoom
      if (phases && phases.length) {
        const lastPhaseInRoom = room.id && findLast(
          phase => phase.room === room.id,
          phases.slice()
            .filter(Boolean)
            .sort((a, b) => a.sequence - b.sequence)
        )
        harvestEndInRoom = lastPhaseInRoom?.endDate
      }
      if (!harvestEndInRoom) {
        harvestEndInRoom = harvestDate || (endDate === startDate ? null : endDate) || 'now'
      }
      to = getDateTime(harvestEndInRoom)
    }
    return to
  }
)

export const selectRoomFromRoute = createSelector(
  selectIdParam,
  selectRooms,
  (id, rooms) => rooms?.[id] ?? EMPTY_OBJECT
)

export const selectRoom = either(selectRoomFromProps, selectRoomFromRoute)

export const selectAllRoomDevices = createSelector(
  selectRoom,
  selectAllDevices,
  (room, devices) => room?.devices?.map(id => devices[id]).filter(Boolean) ?? EMPTY_ARRAY
)

const deviceGrouper = groupBy(device => device.zone || 'room')
export const selectDevicesByZone = createSelector(
  selectAllRoomDevices,
  devices => (devices ? deviceGrouper(devices) : EMPTY_OBJECT)
)

export const selectRoomZones = createSelector(
  selectRoom,
  selectAllZones,
  selectDevicesByZone,
  (room, allZones, roomDevices) => room?.zones
    ?.map(id => {
      const zone = allZones[id]
      if (!zone) return zone
      return {
        ...zone,
        devices: roomDevices[zone.id] ?? EMPTY_ARRAY,
      }
    })
    .filter(Boolean)
    ?? EMPTY_ARRAY
)

export const selectHarvestZones = createSelector(
  selectHarvestZoneIds,
  selectAllZones,
  selectDevicesByZone,
  (ids, zoneEntities, roomDevices) => {
    const zones = (ids ? ids.map(id => {
      const zone = zoneEntities[id]
      if (!zone) return zone
      return {
        ...zone,
        devices: roomDevices[id] ?? EMPTY_ARRAY
      }
    }).filter(Boolean) : EMPTY_ARRAY)
    return zones
  }
)

export const selectHarvestForZones = createSelector(
  prop('growlog'),
  prop('selectedHarvest'),
  prop('room'),
  nthArg(1),
  (growlog, selectedHarvest, { currentHarvests, zones: roomZones }, selectedZones) => {
    // Don't change harvest when in growlog
    if (growlog && selectedHarvest) return selectedHarvest
    if (currentHarvests?.length === 1) {
      const [onlyHarvest] = currentHarvests
      const onlyHarvestZones = selectZonesForHarvest(onlyHarvest)
      if (equals(onlyHarvestZones.sort(), roomZones.map(getId).sort())) {
        return onlyHarvest
      }
    }
    const harvests = currentHarvests.filter(harvest => {
      const harvestZones = selectZonesForHarvest(harvest)
      return harvestZones.every(z => selectedZones.includes(z))
        && selectedZones.every(z => harvestZones.includes(z))
    })
    if (harvests.length === 1) {
      return harvests[0]
    }
    return null
  }
)

export const selectCurrentHarvests = pipe(
  nthArg(1),
  path(['room', 'currentHarvests'])
)

export const selectHydratedHarvests = createShallowEqualsSelector(
  pipe(nthArg(1), prop('growlog')),
  pipe(nthArg(1), prop('selectedHarvest')),
  selectCurrentHarvests,
  prop('phases'),
  (growlog, selectedHarvest, currentHarvests = EMPTY_ARRAY, phases = EMPTY_OBJECT) => {
    if (growlog) return [selectedHarvest]

    return currentHarvests.map(harvest => ({
      ...harvest,
      phases: harvest?.phases?.map(p => (p?.id ? p : phases[p])).filter(Boolean),
    }))
  }
)

export const selectTimelineHarvests = createShallowEqualsSelector(
  selectIdParam,
  selectHydratedHarvests,
  pipe(
    nthArg(1),
    prop('selectedHarvest')
  ),
  pipe(
    nthArg(1),
    prop('selectedZones')
  ),
  pipe(nthArg(1), selectFrom),
  pipe(nthArg(1), selectTo),
  (roomId, harvests, selectedHarvest, selectedZones, from, to = getDateTime('now')) => {
    if (harvests.length === 1) return harvests
    const viewedInterval = from ? from.until(to) : null

    return harvests.filter(harvest => {
      const { phases } = harvest

      if (!phases || (selectedHarvest && selectedHarvest.id !== harvest.id)) {
        return false
      }

      const phaseTypesInRoom = orderedRoomPrefixes.filter(prefix => harvest[`${prefix}Room`] === roomId)
      const phasesInRoom = phases.filter(({ phaseType, room, startDate, endDate }) => {
        const phaseInterval = getDateTime(startDate).until(getDateTime(endDate))
        return (phaseTypesInRoom.includes(phaseType.toLowerCase()) || room === roomId)
          && (!viewedInterval || phaseInterval.overlaps(viewedInterval))
      })

      return Boolean(phasesInRoom.length)
    })
  }
)

export const selectPartialHarvests = createShallowEqualsSelector(
  selectHydratedHarvests,
  harvests => harvests.filter(harvest => {
    const zones = selectZonesForHarvest(harvest)
    return !zones.length
  })
)

export const selectFullHarvests = createShallowEqualsSelector(
  selectHydratedHarvests,
  harvests => harvests.filter(harvest => Boolean(selectZonesForHarvest(harvest).length))
)

const groupGraphsByDataType = createSelector(
  either(path(['chartData', 'graphs']), always(EMPTY_ARRAY)),
  groupBy(prop('dataType'))
)

export const selectActiveDataType = createShallowEqualsSelector(
  path(['activeTargeType', 'key']),
  path(['activeNotification', 'dataTypeKey']),
  (activeTargetRangeDataType, activeNotificationDataType) => activeTargetRangeDataType ?? activeNotificationDataType
)

export const selectActiveDataTypes = createShallowEqualsSelector(
  selectActiveDataType,
  prop('selectedDataTypes'),
  pipe(unapply(flatten), filter(Boolean), uniq)
)

export const selectActiveDepths = createShallowEqualsSelector(
  prop('selectedDepths'),
  selectedDepths => (selectedDepths?.length > 0 ? selectedDepths.reduce((p, c) => ({ ...p, [c]: true }), EMPTY_OBJECT) : null),
)

export const selectRoomDashboardGraphs = createSelector(
  ({ chartData }) => Boolean((
    Array.isArray(chartData?.graphs)
    && chartData.data
    && chartData.graphs.some(graph => chartData.data[graph.id]?.length)
  ) || (
    chartData?.manualGraphs && Object.keys(chartData?.manualGraphs).length
  )),
  groupGraphsByDataType,
  pick(['chartData', 'growlog', 'individualGraphs', 'mainGraphZone', 'selectedZones', 'showRoomAvg']),
  selectActiveDepths,
  selectActiveDataType,
  selectActiveDataTypes,
  (
    haveGraphs,
    groupedByType,
    {
      growlog,
      chartData,
      individualGraphs,
      mainGraphZone,
      selectedZones = EMPTY_ARRAY,
      showRoomAvg
    },
    activeDepths,
    activeDataType,
    activeDataTypes = EMPTY_ARRAY,
  ) => {
    if (!haveGraphs || !chartData) {
      return {
        mainChartGraphs: EMPTY_ARRAY,
        miniGraphs: EMPTY_ARRAY,
        manualGraphs: EMPTY_ARRAY
      }
    }
    const { graphs, manualGraphs: rawManualGraphs } = chartData
    let selected = graphs.filter(({ bounds, dataType, id, zone }) => {
      const dataTypeIsActive = activeDataTypes.includes(dataType)
      const isRoomLevel = typeof zone !== 'number'
      const zoneIsActive = selectedZones.includes(zone)
      const isRoomAvg = id.includes(':room:')
      const roomAvgOnlyForType = !individualGraphs && isRoomAvg && groupedByType[dataType].length === 1

      return Boolean(dataTypeIsActive
        && (!isRoomAvg || (showRoomAvg && !roomAvgOnlyForType))
        && (isRoomLevel || zoneIsActive)
        && bounds)
    })

    if (activeDepths != null) {
      selected = selected.filter(g => {
        if (isNumber(g.depth)) {
          if (!activeDepths) { // if deselects all depths then depth graphs are removed
            return false
          }
          if (activeDepths) { // if select some depths then only depth graphs are filtered
            return activeDepths[g.depth]
          }
        }
        return true
      })
    }

    const manualGraphs = Object.values(rawManualGraphs).filter(({ dataType, zone }) => {
      const dataTypeIsActive = activeDataTypes.includes(dataType)
      const zoneIsActive = selectedZones.includes(zone) || zone?.startsWith?.('room_')
      return dataTypeIsActive && zoneIsActive
    })

    if (growlog || !individualGraphs) {
      return {
        mainChartGraphs: selected,
        miniGraphs: EMPTY_ARRAY,
        manualGraphs,
      }
    }

    return {
      mainChartGraphs: selected.filter(({ id, zone, dataType }) => {
        const zoneExact = zone === mainGraphZone
        const zoneIsRoom = (mainGraphZone?.startsWith?.('room') && (id.includes(':room:') || zone?.startsWith?.('room')))
        const isActiveDataType = dataType === activeDataType

        return zoneExact || zoneIsRoom || isActiveDataType
      }),
      miniGraphs: selected.filter(({ zone }) => zone !== mainGraphZone),
      manualGraphs: manualGraphs.filter(({ zone }) => zone === mainGraphZone)
    }
  }
)
