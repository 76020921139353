import { memo } from 'react'

import i18n from 'i18n-literally'
import PropTypes from 'prop-types'

import { Formik } from 'formik'
import * as Yup from 'yup'

import TextField from './TextField'

/**
 * A text field in a single-value Formik context
 * @deprecated
 */
export const FormikFieldComponent = ({
  required = false,
  value = '',
  validationSchema = undefined,
  onChange,
  error = undefined,
  ...props
}) => (
  <Formik
    validateOnMount
    data-testid="formik-field"
    enableReinitialize
    initialValues={{ value }}
    validationSchema={validationSchema}
  >
    {formikProps => {
      const { errors, handleBlur } = formikProps

      return (
        <TextField
          fullWidth
          data-testid="formik-text-field"
          error={errors.value || error}
          {...props}
          required={required}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
        />
      )
    }}
  </Formik>
)

FormikFieldComponent.propTypes = {
  error: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  validationSchema: PropTypes.shape({
    value: PropTypes.func,
  }),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.string,
}

const defaultRequired = 'Required'
const addRequired = (schema, required) => {
  if (!required) return schema
  return schema.required(typeof required === 'string' ? required : defaultRequired)
}

const defaultSchemas = {
  email: ({ required }) => addRequired(
    Yup.string().email(i18n`Please enter a valid email address`),
    required
  ),
  number: ({ required }) => addRequired(Yup.number().typeError(i18n`Please enter a number`), required),
  text: ({ required }) => {
    let schema = Yup.string()
    if (required) {
      schema = schema.min(1, typeof required === 'string' ? required : defaultRequired)
        .typeError(typeof required === 'string' ? required : defaultRequired)
    }
    return addRequired(schema, required)
  },
  integer: ({ required, ...props }) => {
    let schema = Yup.number(i18n`Please enter a number`)
      .integer(i18n`Please enter a whole number`)
      .typeError(i18n`Please enter a number`)
    if (props.min) {
      schema = schema.min(props.min, i18n`Please enter a value greater than ${props.min}`)
    }
    if (props.max) {
      schema = schema.max(props.max, i18n`Please enter a value lower than ${props.max}`)
    }
    return addRequired(schema, required)
  },
}

export default memo(props => {
  // eslint-disable-next-line react/prop-types
  const { type = 'text', validationSchema } = props
  if (validationSchema || !(type in defaultSchemas)) {
    return <FormikFieldComponent {...props} />
  }

  const schema = defaultSchemas[type]
  return (
    <FormikFieldComponent
      {...props}
      type={type === 'integer' ? 'number' : type}
      validationSchema={Yup.object().shape({ value: schema(props) })}
    />
  )
})
