import memoizeOne from 'memoize-one'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, shallowEquals } from '~/src/Lib/Utils'

import { DATA_GROUPING, ROOM_GRAPH_SINGLE } from './constants'

const logger = createLogger('Chart/utils')

export * from './constants'
export * from './data'
export * from './guides'
export * from './padding'
export * from './xAxis'
export * from './yAxes'

/**
 * @typedef {Object} DataType
 * @property {number} unit
 */

/**
 * Returns an object with graphs, manualGraphs, yAxes, activeDataType keys.
 * If viewMode is true, graphs, manualGraphs, yAxes have SOIL and OTHER keys, else they have one ALL key.
 * Under each of the keys is an array of graph definitions
 * @param {Object} props Chart component props
 * @param {{ dataTypeKey: string }} activeNotification
 * @param {{ key: string }} activeTargetType
 * @param {Object<string, DataType>} dataTypes
 * @param {Array<{dataType: string}>} mainChartGraphs
 * @param {Array<{dataType: string}>} manualGraphs
 * @param {boolean} [viewMode=false]
 * @param {Array<{ key: string, unit: number }>} yAxes
 * @returns {{ graphs: Object, manualGraphs: Object, graphDataTypes: Set<string>, activeDataType: string }}
 */
export const getGraphsAndAxes = memoizeOne(({
  activeNotification,
  activeTargetType,
  dataTypes,
  mainChartGraphs = EMPTY_ARRAY,
  manualGraphs = EMPTY_ARRAY,
  viewMode = ROOM_GRAPH_SINGLE,
  theme,
  yAxes: yAxesParam = EMPTY_ARRAY,
  mainChartData,
}) => {
  const activeDataType = activeNotification?.dataTypeKey ?? activeTargetType?.key
  const graphs = [
    ...mainChartGraphs.map(graph => ({
      ...graph,
      data: mainChartData?.[graph.id] ?? EMPTY_ARRAY,
    })),
    ...manualGraphs.map(graph => ({ ...graph, type: 'scatter' })),
  ]
  const graphDataTypes = new Set(graphs.map(graph => graph.dataType))
  const {
    [viewMode]: partition = DATA_GROUPING[ROOM_GRAPH_SINGLE],
  } = DATA_GROUPING
  const groups = partition({
    data: graphs,
    dataTypes,
    graphDataTypes,
    theme,
  })
  const withYAxes = partition({
    data: yAxesParam,
    dataTypes,
    graphDataTypes,
    theme,
    type: 'yAxes',
    reducerInitial: groups
  })
  return {
    groups: withYAxes,
    activeDataType,
    graphDataTypes,
  }
}, (left, right) => shallowEquals(left, right, { depth: 3 }))
