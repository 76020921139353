import {
  assocPath,
  equals,
  is,
  mergeDeepRight,
  omit,
  path,
} from 'ramda'

import { decompressFromUTF16 } from 'lz-string'

import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'

const MODULE_NAME = '[Redux-LocalStorage-Simple]'
export const NAMESPACE_DEFAULT = 'redux_localstorage_simple'
const NAMESPACE_SEPARATOR_DEFAULT = '_'
const STATES_DEFAULT = []
const IGNORE_STATES_DEFAULT = []
const DEBOUNCE_DEFAULT = 500
const IMMUTABLEJS_DEFAULT = false
const DISABLE_WARNINGS_DEFAULT = false
const STATE_DELIMITER = '.'

export const NO_DEBOUNCE = [NAMESPACE_DEFAULT, 'NO', 'DEBOUNCE'].join(NAMESPACE_SEPARATOR_DEFAULT)

const debounceTimeouts = new Map()

const isInteger = n => Number.isInteger(n)
const isObject = is(Object)
const isString = is(String)

const logger = createLogger('redux', 'localStorage-simple')
const storage = safeStorage('local')

/**
 * Create an object from a specified path, with
 * the innermost property set with an initial value
 * @param {String} objectPath The path to the innermost property
 * @param {Any} objectInitialValue The value of the innermost property
 * @example
 * realiseObject('myObj.prop1.prop2', 123)
 * // returns
 * // {
 * //    myObj: {
 * //      prop1: {
 * //        prop2: 123
 * //      }
 * //    }
 * // }
*/
function realiseObject(objectPath, objectInitialValue = {}) {
  return assocPath(objectPath.split('.'), objectInitialValue, {})
}

/**
 * Removes ignored states from the main state object
 * @param {string[]} ignoreStates Array of keys to omit from the stateFull object
 * @param {Object} stateFull Full redux state object
 * @returns {Object} A copy of the stateFull object with the ignored state keys omitted
 */
function handleIgnoreStates(ignoreStates, stateFull) {
  if (!Array.isArray(ignoreStates) || ignoreStates.length === 0) {
    return stateFull
  }
  return omit(ignoreStates.filter(isString), stateFull)
}

/**
 * Digs into rootState for the data to put in localStorage
 * @param {String} state The state to dig for
 * @param {Object} rootState The root state to dig into
 * @returns {any} The data to put in localStorage
 */
function getStateForLocalStorage(state, rootState) {
  if (state.includes(STATE_DELIMITER)) {
    return path(state.split(STATE_DELIMITER), rootState)
  }
  return path([state], rootState)
}

/**
 * Sets the specified key to a JSON-serialized, compressed version of value in localstorage
 * @param {string} key Key to save to localstorage
 * @param {any} value Value to serialize as JSON, compress, and save to localStorage
 * @returns {void}
 */
export function setItem(key, value) {
  try {
    const serialized = JSON.stringify(value)
    if (serialized == null) {
      throw new Error(`Cannot serialize value for '${key}'.`)
    }
    // storage.setItem(key, compressToUTF16(serialized))
    storage.setItem(key, serialized)
  } catch (e) {
    logger.error(
      `Error saving to localStorage key '${key}'`,
      e.message ?? String(e)
    )
  }
}

/**
 * Returns the specified key from localstorage, decompressed and parsed as JSON
 * @param {string} key Key to retrieve from localstorage then decompress and parse as JSON
 * @returns {any}
 */
export function getItem(key) {
  let result = null
  if (!(key in storage)) return result
  const lsData = storage.getItem(key)
  try {
    const decompressed = lsData && lsData.charCodeAt(0) === 7137 ? decompressFromUTF16(lsData) : lsData
    if (decompressed == null) {
      throw new Error('decompressed data is invalid')
    }
    result = JSON.parse(decompressed)
  } catch (_) {
    try {
      result = JSON.parse(lsData)
      logger.error(
        `Error loading as compressed from localStorage key '${key}'.`,
        'Successfully loaded as uncompressed JSON.'
      )
    } catch (parseError) {
      logger.error(
        `Error loading from localStorage key '${key}'`,
        parseError.message ?? String(parseError)
      )
      storage.removeItem(key)
    }
  }
  return result
}

/**
  Saves specified parts of the Redux state tree into localstorage
  Note: this is Redux middleware. Read this for an explanation:
  http://redux.js.org/docs/advanced/Middleware.html
  @param {Object} config Contains configuration options (leave blank to save entire state tree to localstorage)
  @param {string[]} config.states? Parts of state tree to save e.g. ['user', 'products']
  @param {string} config.namespace? Namespace to add before your LocalStorage items
  @param {string} config.namespaceSeparator? Separator to use between namespace and state name
  @param {number} config.debounce? Debouncing period (in milliseconds) to wait before saving to LocalStorage
  @returns {function} Redux middleware function
  @example
    // save entire state tree - EASIEST OPTION
    save()

    // save specific parts of the state tree
    save({
      states: ['user', 'products']
    })

    // save the entire state tree under the namespace 'my_cool_app'. The key 'my_cool_app' will appear in LocalStorage
    save({
      namespace: 'my_cool_app'
    })

    // save the entire state tree only after a debouncing period of 500 milliseconds has elapsed
    save({
      debounce: 500
    })

    // save specific parts of the state tree with the namespace 'my_cool_app'. The keys 'my_cool_app_user' and 'my_cool_app_products' will appear in LocalStorage
    save({
        states: ['user', 'products'],
        namespace: 'my_cool_app',
        debounce: 500
    })
*/
export function save({
  states = STATES_DEFAULT,
  ignoreStates = IGNORE_STATES_DEFAULT,
  namespace: nsArg = NAMESPACE_DEFAULT,
  namespaceSeparator: separatorArg = NAMESPACE_SEPARATOR_DEFAULT,
  debounce: debounceArg = DEBOUNCE_DEFAULT,
} = {}) {
  let namespace = nsArg
  let namespaceSeparator = separatorArg
  let debounce = debounceArg

  return store => next => action => {
    const prevState = store.getState()
    const nextState = next(action)

    if (prevState === nextState) return nextState

    // Validate 'namespace' parameter
    if (!isString(namespace)) {
      logger.error(
        "'namespace' parameter in 'save()' method was passed a non-string value.",
        "Setting default value instead. Check your 'save()' method."
      )
      namespace = NAMESPACE_DEFAULT
    }

    // Validate 'namespaceSeparator' parameter
    if (!isString(namespaceSeparator)) {
      logger.error(
        "'namespaceSeparator' parameter in 'save()' method was passed a non-string value.",
        "Setting default value instead. Check your 'save()' method."
      )
      namespaceSeparator = NAMESPACE_SEPARATOR_DEFAULT
    }

    // Validate 'debounce' parameter
    if (!isInteger(debounce)) {
      logger.error(
        "'debounce' parameter in 'save()' method was passed a non-integer value.",
        "Setting default value instead. Check your 'save()' method."
      )
      debounce = DEBOUNCE_DEFAULT
    }

    // Local function to avoid duplication of code above
    function $save() {
      // Check if there are states to ignore
      const $state = ignoreStates.length > 0
        ? handleIgnoreStates(ignoreStates, store.getState())
        : store.getState()

      if (!Array.isArray(states) || states.length === 0) {
        setItem(namespace, $state)
      } else {
        states.forEach(state => {
          const key = namespace + namespaceSeparator + state
          const stateForLocalStorage = getStateForLocalStorage(state, $state)
          const alreadySaved = getItem(key)
          if (!equals(stateForLocalStorage, alreadySaved)) {
            setItem(key, stateForLocalStorage)
          }
        })
      }
    }

    // Check to see whether to debounce LocalStorage saving
    if (debounce && !action.meta?.[NO_DEBOUNCE]) {
      // Clear the debounce timeout if it was previously set
      if (debounceTimeouts.get(states + namespace)) {
        clearTimeout(debounceTimeouts.get(states + namespace))
      }

      // Save to LocalStorage after the debounce period has elapsed
      debounceTimeouts.set(
        states + namespace,
        setTimeout($save, debounce)
      )
      // No debouncing necessary so save to LocalStorage right now
    } else {
      $save()
    }

    return nextState
  }
}

/**
 * Loads specified states from localstorage into the Redux state tree.
 * @param {Object} config Contains configuration options (leave blank to load entire state tree, if it was saved previously that is)
 * @param {string[]} config.states Parts of state tree to load e.g. ['user', 'products']
 * @param {string} config.namespace? Namespace required to retrieve your LocalStorage items, if any
 * @param {string} config.namespaceSeparator? Separator used between namespace and state name
 * @param {Object} config.preloadedState? Custom initial state to use when creating the Redux store
 * @returns {Object} The loaded state tree to use when creating the Redux store
 * @example
 *   // load entire state tree - EASIEST OPTION
 *   load()
 *
 *   // load specific parts of the state tree
 *   load({
 *     states: ['user', 'products']
 *   })
 *
 *   // load the entire state tree which was previously saved with the namespace "my_cool_app"
 *   load({
 *     namespace: 'my_cool_app'
 *   })
 *
 *   // load specific parts of the state tree which was previously saved with the namespace "my_cool_app"
 *   load({
 *       states: ['user', 'products'],
 *       namespace: 'my_cool_app'
 *   })
 */
export function load({
  states = STATES_DEFAULT,
  namespace = NAMESPACE_DEFAULT,
  namespaceSeparator = NAMESPACE_SEPARATOR_DEFAULT,
  preloadedState = {},
} = {}) {
  // Validate 'namespaceSeparator' parameter
  if (!isString(namespaceSeparator)) {
    throw new Error([
      "'namespaceSeparator' parameter in 'load()' method was passed a non-string value.",
      "Check your 'load()' method."
    ].join(' '))
  }

  // Load all of the namespaced Redux data from LocalStorage into local Redux state tree
  if (!Array.isArray(states) || states.length === 0) {
    const val = getItem(namespace)
    if (val) {
      return mergeDeepRight(preloadedState, val)
    }
    return preloadedState
  }
  // Load only specified states into the local Redux state tree
  return states.reduce((loadedState, state) => {
    const key = namespace + namespaceSeparator + state
    logger.debug('restoring state for', state, 'using', key)
    const val = getItem(key)
    if (val) {
      return mergeDeepRight(loadedState, realiseObject(state, val))
    }

    logger.debug([
      `Invalid load '${key}' provided. Check your 'states' in 'load()'.`,
      'If this is your first time running this app you may see this message.',
    ].join(' '))
    return loadedState
  }, preloadedState)
}

/**
  Combines multiple 'load' method calls to return a single state for use in Redux's createStore method.
  Use this when parts of the loading process need to be handled differently e.g. some parts of your state tree use different namespaces
  @param {...Object} loads 'load' method return values passed into this method as normal arguments
  @example
    // Load parts of the state tree saved with different namespaces
    combineLoads(
        load({ states: ['user'], namespace: 'account_stuff' }),
        load({ states: ['products', 'categories'], namespace: 'site_stuff' )
    )
*/
export const combineLoads = (...loads) => loads.reduce((acc, loaded) => {
  // Make sure current 'load' is an object
  if (!isObject(loaded)) {
    logger.error([
      "One or more loads provided to 'combineLoads()' is not a valid object.",
      "Ignoring the invalid loaded data. Check your 'combineLoads()' method."
    ].join(' '))
    return acc
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(loaded)) {
    acc[key] = loaded[key]
  }

  return acc
}, {})

/**
 * Clears all Redux state tree data from LocalStorage
 * Remember to provide a namespace if you used one during the save process
 * @param {Object} config Contains configuration options (leave blank to clear entire state tree from LocalStorage, if it was saved without a namespace)
 * @param {String} config.namespace Namespace that you used during the save process
 * @returns {void}
 * @example
 * // clear all Redux state tree data saved without a namespace
 * clear()
 * // clear Redux state tree data saved with a namespace
 * clear({ namespace: 'my_cool_app' })
*/
export function clear({ namespace: namespaceArg = NAMESPACE_DEFAULT } = {}) {
  let namespace = namespaceArg

  // Validate 'namespace' parameter
  if (!isString(namespace)) {
    logger.error("'namespace' parameter in 'clear()' method was passed a non-string value. Setting default value instead. Check your 'clear()' method.")
    namespace = NAMESPACE_DEFAULT
  }

  const keysToRemove = Array.from(storage, (_, i) => storage.key(i))
    .filter(key => key.startsWith(namespace))

  keysToRemove.forEach(key => storage.removeItem(key))
}
