/* eslint-disable no-restricted-globals */
import { always } from 'ramda'

import onscan from 'onscan.js'

import config from '~/src/App/config'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { defer } from '~/src/Lib/Utils'

const localStorage = safeStorage('local')
const isBrowser = typeof window !== 'undefined'
const BARCODE_CONNECT_LISTENERS = 'BARCODE_CONNECT_LISTENERS'
const BARCODE_DISCONNECT_LISTENERS = 'BARCODE_DISCONNECT_LISTENERS'
const BARCODE_RECEIVED = 'BARCODE_RECEIVED'
const BARCODE_DEBUG = 'BARCODE_DEBUG'
const IGNORE_IF_FOCUSON = [
  'input:not([data-barcode="true"])',
  'textArea:not([data-barcode="true"])',
  '[contenteditable="true"]:not([data-barcode="true"])'
]
const MANUAL_INPUT_TIMEOUT = 1000
const VALUE_SETTERS = {
  INPUT: isBrowser ? Object.getOwnPropertyDescriptor(getGlobal().HTMLInputElement.prototype, 'value').set : () => {},
  TEXTAREA: isBrowser ? Object.getOwnPropertyDescriptor(getGlobal().HTMLTextAreaElement.prototype, 'value').set : () => {},
}

const logger = createLogger('barcode')

const isPrintingCharacter = ({ code, key }) => {
  if (code === key || (key.length > 1 && code.startsWith(key))) return false
  return true
}

if (isBrowser && localStorage.debug && config.ENVIRONMENT !== 'production') {
  getGlobal().onscan = onscan
}

const { _isFocusOnIgnoredElement: focusOnIgnored } = onscan

const scanListeners = new Map()
const manualInputListeners = new Map()

const init = store => {
  let cursor = null
  let ignore = null
  let lastTarget = null
  let scan = ''
  let selection = null
  let timer = null

  const resetRefs = () => {
    cursor = null
    lastTarget = null
    scan = ''
    selection = null
  }

  const blurHandler = () => {
    clearTimeout(timer)
    ignore?.removeEventListener('blur', blurHandler)
    ignore = null
    timer = null
    defer(resetRefs)
  }

  const onKeyDetect = (keyCode, event) => {
    if (onscan.isAttachedTo(document) && focusOnIgnored(document)) {
      ignore = event.target
    }
    if (event.target === ignore) {
      clearTimeout(timer)
      timer = setTimeout(blurHandler, MANUAL_INPUT_TIMEOUT)
      return false
    }
    const printing = isPrintingCharacter(event)
    const isBarcodeChar = Boolean(onscan.decodeKeyEvent(event))
    const isSpecial = event.ctrlKey || event.metaKey
    if (keyCode === 8) {
      scan = scan.slice(0, -1)
    } else if (printing && !isSpecial) {
      scan += event.key
      lastTarget = event.target
      if (cursor == null) {
        const { selectionStart, selectionEnd } = event.target
        cursor = selectionStart
        selection = selectionEnd - selectionStart
      }
    }

    return isBarcodeChar && !isSpecial
  }

  const options = {
    avgTimeByChar: 20,
    captureEvents: true,
    reactToKeydown: true,
    preventDefault: true,
    ignoreIfFocusOn: IGNORE_IF_FOCUSON,
    // Don't swallow key events that definitely aren't bar codes
    onKeyDetect,
    onScanError: () => {
      const string = scan
      const target = lastTarget
      const cursorPosition = cursor
      const selectionLength = selection
      resetRefs()
      if (target && target.addEventListener) {
        ignore = target
        target.addEventListener('blur', blurHandler)
        timer = setTimeout(blurHandler, MANUAL_INPUT_TIMEOUT * 5)
      }

      if (target && manualInputListeners.size) {
        const payload = { target, value: string }
        defer(() => manualInputListeners.forEach(({ current: fn }) => fn(payload)), defer.priorities.low)
      }
      if (target && string) {
        const { [target.tagName]: valueSetter } = VALUE_SETTERS
        if (!valueSetter) return
        // If the active element is an input or textarea and the key events weren't a bar code,
        // update its value and trigger an input event so that React knows to fire event its event handlers
        const { value: oldValue } = target
        const valueArray = Array.from(oldValue)
        valueArray.splice(cursorPosition, selectionLength || 0, string)
        const newValue = valueArray.join('')
        logger.debug('updating input value from', oldValue, 'to', newValue)
        valueSetter.call(target, newValue)
        const inputEvent = new Event('input', { bubbles: true, cancelable: true, view: window })
        target.dispatchEvent(inputEvent)
        const setCursorPosition = () => target.setSelectionRange(cursorPosition + string.length, cursorPosition + string.length)
        if (!target.dataset.isWeight) {
          setCursorPosition()
          return
        }
        defer(setCursorPosition, defer.priorities.high)
      }
    },
    onScan: value => {
      scanListeners.forEach(({ current: fn }) => fn(value))
      store.doBarcodeReceive(value)
      resetRefs()
    }
  }

  if (!onscan.isAttachedTo(document)) {
    onscan.attachTo(document, options)
  } else {
    onscan.setOptions(document, options)
  }

  return () => onscan.detachFrom(document)
}

const defaultState = {
  connected: 0,
  value: null,
  receivedAt: null,
  debug: config.ENVIRONMENT !== 'production',
}

export default {
  name: 'barcode',
  init,
  reducer: (state = defaultState, action) => {
    const { type, payload } = action ?? {}

    if (type === BARCODE_CONNECT_LISTENERS || type === BARCODE_DISCONNECT_LISTENERS) {
      const uniqueRefs = new Set([...scanListeners.keys(), ...manualInputListeners.keys()])
      return { ...state, connected: uniqueRefs.size }
    }
    if (type === BARCODE_RECEIVED) {
      return {
        ...state,
        value: payload,
        received: Date.now()
      }
    }
    if (type === BARCODE_DEBUG) {
      return { ...state, debug: !state.debug }
    }

    return state
  },
  doBarcodeConnect: (ref, scanRef, manualInputRef) => {
    if (scanRef.current) {
      scanListeners.set(ref, scanRef)
    }
    if (manualInputRef.current) {
      manualInputListeners.set(ref, manualInputRef)
    }
    return { type: BARCODE_CONNECT_LISTENERS }
  },
  doBarcodeDisconnect: ref => {
    scanListeners.delete(ref)
    manualInputListeners.delete(ref)
    return { type: BARCODE_DISCONNECT_LISTENERS }
  },
  doBarcodeReceive: payload => ({ type: BARCODE_RECEIVED, payload }),
  doBarcodeDebugToggle: always(BARCODE_DEBUG),
  selectBarcodeRoot: state => ({
    ...state.barcode,
    scanListeners: Array.from(scanListeners.entries()),
    manualInputListeners: Array.from(manualInputListeners.entries()),
  }),
}
