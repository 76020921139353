import memoizeOne from 'memoize-one'
import {
  always,
  either,
  filter,
  flatten,
  groupBy,
  map,
  path,
  pick,
  pipe,
  prop,
  props,
  reduce,
  uniq,
} from 'ramda'

import { titleize } from 'inflection'
import { createSelector } from 'reselect'

import { HARVEST_ROOM_TYPES } from '~/src/Harvest/constants'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  getDateTime,
  shallowEquals,
} from '~/src/Lib/Utils'

export const selectUniqueCultivarZones = memoizeOne(
  pipe(
    map(props(HARVEST_ROOM_TYPES.map(prefix => `${prefix}Zones`))),
    flatten,
    filter(Boolean),
    uniq
  )
)

export const selectZonesForHarvest = memoizeOne(
  pipe(
    either(prop('cultivars'), always(EMPTY_ARRAY)),
    selectUniqueCultivarZones
  ),
  shallowEquals,
)

export const selectZoneIds = createSelector(
  path(['harvest', 'cultivars']),
  selectUniqueCultivarZones
)

const shallowEqualsThree = (left, right) => shallowEquals(left, right, 3)

const phaseProps = ['phaseType', 'startDate', 'totalDays']
export const getCurrentPhaseDayInfo = memoizeOne((harvest, phases) => {
  const { currentPhase: csp, phases: harvestPhases } = harvest
  const currentSubphase = typeof csp !== 'object' ? phases[csp] : csp
  const currentPhase = harvestPhases.reduce((phase, sp) => {
    const subphase = typeof sp !== 'object' ? phases[sp] : sp
    if (!subphase || !currentSubphase || subphase.phaseType !== currentSubphase.phaseType) {
      return phase
    }
    if (phase === EMPTY_OBJECT) {
      return pick(phaseProps, subphase)
    }

    const { startDate, totalDays } = subphase
    if (startDate < phase.startDate) {
      phase.startDate = startDate
    }
    phase.totalDays += totalDays

    return phase
  }, EMPTY_OBJECT)

  if (currentPhase === EMPTY_OBJECT) return currentPhase

  currentPhase.day = 1 + Math.floor(getDateTime('now').diff(getDateTime(currentPhase.startDate)).as('days'))
  currentPhase.name = titleize(currentPhase.phaseType)

  return currentPhase
}, shallowEqualsThree)

const selectGrowthPhasesPipeline = pipe(
  groupBy(prop('phaseType')),
  Object.entries,
  reduce((growthPhases, [phaseType, subPhases]) => ({
    ...growthPhases,
    [phaseType]: {
      phaseType,
      subPhases,
      ...(subPhases.reduce((gp, sub) => {
        const { startDate = sub.startDate, endDate = sub.endDate, totalDays = 0 } = gp

        return {
          startDate: startDate > sub.startDate ? sub.startDate : startDate,
          endDate: endDate < sub.endDate ? sub.endDate : endDate,
          totalDays: totalDays + sub.totalDays
        }
      }, EMPTY_OBJECT))
    }
  }), EMPTY_OBJECT)
)
/**
 * Takes a rehydrated harvest entity from useHarvestEntity and returns an object that contains
 * phaseTypes as keys and subPhases, start, end, totalDays
 */
export const selectGrowthPhases = memoizeOne(harvest => (
  Array.isArray(harvest?.phases)
    ? selectGrowthPhasesPipeline(harvest.phases)
    : EMPTY_ARRAY
), shallowEqualsThree)
