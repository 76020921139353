import { equals, prop } from 'ramda'
import { createSelector } from 'redux-bundler'

const ACTION_BASE_TYPE = 'FACILITY_IRRIGATION'
export const ACTION = {
  SET_SELECTED_ROOMS: `${ACTION_BASE_TYPE}_SET_SELECTED_ROOMS`,
}

const initialState = {
  selectedRooms: [],
}

const facilityIrrigationStateBundle = {
  name: 'facilityIrrigationState',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case ACTION.SET_SELECTED_ROOMS:
        return {
          ...state,
          selectedRooms: action.payload,
        }
      default:
        return state
    }
  },
  selectFacilityIrrigationState: prop('facilityIrrigationState'),
  selectFacilityIrrigationSelectedRooms: createSelector(
    'selectFacilityIrrigationState',
    prop('selectedRooms'),
  ),
  selectFacilityIrrigationIsLoading: createSelector(
    'selectFacilityIrrigationActiveSchedules',
    'selectFacilityIrrigationEventHistory',
    (activeSchedules, eventHistory) => (
      !activeSchedules || !eventHistory
    ),
  ),
  doFacilityIrrigationSetSelectedRooms: selectedRooms => ({
    type: ACTION.SET_SELECTED_ROOMS,
    payload: selectedRooms,
  }),
  reactFacilityIrrigationDeselectRooms: createSelector(
    'selectFacilityIrrigationSelectedRooms',
    'selectRooms',
    (selectedRooms, rooms) => {
      const updatedSelection = selectedRooms.filter(roomId => roomId in rooms)
      if (!equals(updatedSelection, selectedRooms)) {
        return { actionCreator: 'doFacilityIrrigationSetSelectedRooms', args: [updatedSelection] }
      }
      return null
    },
  ),
  persistActions: Object.values(ACTION),
}

export default {
  ...facilityIrrigationStateBundle,
}
