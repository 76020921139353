import ms from 'milliseconds'
import queryString from 'query-string'

import createListBundle from '~/src/Lib/createListBundle'
import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { ControlSensorBasedEvent as schema } from '~/src/Store/Schemas'

const logger = createLogger('Irrigation/controlSensorBasedEvents#bundle')

const initialState = {
  pageSize: 100,
}

export default createListBundle({
  entityName: 'controlSensorBasedEvents',
  name: 'controlSensorBasedEventsList',
  initialState,
  actions: EMPTY_ARRAY,
  schema,
  flags: {
    keys: ['EMERGENCY_SHOT']
  },
  permissions: {
    any: true,
    keys: ['change_irrigation', 'view_irrigation']
  },
  fetchHandler: ({ apiFetch, params }) => apiFetch(`/controlSensorBasedEvent/?${queryString.stringify({
    ...params,
    start: getDateTime('now').minus({ hours: 24 }).startOf('minute').toISO(),
  })}`),
  staleAfter: ms.minutes(5),
  fetchReactionPriority: REACTOR_PRIORITIES.MEDIUM,
})
