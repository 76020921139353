import createEntityBundle from '~/src/Lib/createEntityBundle'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Logger } from '~/src/Store/Schemas'

const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }

const bundle = createEntityBundle({
  name: 'loggers',
  apiConfig: { schema: Logger, update: writesConfig },
  scope,
})

export default bundle
