import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'

import { createAppIsReadySelector } from '~/src/Store/utils'

// TODO: Probably move this into src/Facility/bundle as 'facilityLightSchedules'

const initialLightSchedulesBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationLightSchedules',
  actionBaseType: 'FACILITY_IRRIGATION_LIGHT_SCHEDULES',
  getPromise: ({ apiFetch }) => apiFetch('/lightSchedules/get_effective_light_schedule_for_all_rooms/'),
  staleAfter: ms.minutes(15),
})

export default {
  ...initialLightSchedulesBundle,
  reactFetchFacilityIrrigationLightSchedules: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationLightSchedulesShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { view }, { pattern }) => {
      if (shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationLightSchedules' }
      }
      return null
    },
  }),
}
