import { always, prop } from 'ramda'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { breakpoints } from '~/src/UI/Theme/basics'

import getGlobal from '../getGlobal'

const global = getGlobal()

/**
 * Prevents default behavior of HTML element
 * @param {Event} event
 * @returns called preventDefault or undefined
 */
export const preventDefault = event => event?.preventDefault?.()

/**
 * Replaces mustache pattern {{ anything }} with data value provided
 * @param {string} template
 * @param {Object} data
 * @returns {string}
 */
export const templateRenderer = (template, data) => template.replace(/{{\s*(\w+)\s*}}/g, (m, key) => (
  data[key] != null ? String(data[key]) : ''
))

/**
 * Returns bound templateRenderer function with a template as 1 argument
 * @param {string} template
 * @returns {Function}
 */
export const compileTemplate = template => templateRenderer.bind(null, template)

/**
 * Sanitizes strings that _may_ contain sub/sup html tags
 * @param {string} input - a string that may contain HTML
 * @returns string - a string with all HTML tags except sub/sup removed
 */
export const dtLabelSanitizer = string => (
  string
    ? string.replace(/(<[^>]+>)/g, match => (/<\/?(span|sub|sup|u)>/.test(match) ? match : ''))
    : ''
)

/**
 * Create a collision detector suitable for map/reduce
 * @param {(string|function)} edge
 *   either the property name from which to get the edge value
 *   or a function which returns the edge value
 * @param {(number|function)} length
 *   either a length value or a function which returns the length value
 * @returns boolean
 */
export const getCollidesWith = (edge, length) => {
  const edgeGetter = typeof edge === 'function' ? edge : prop(edge)
  const lengthGetter = typeof length === 'function' ? length : always(length)

  return (a, b) => {
    const aEdge = edgeGetter(a)
    const bEdge = edgeGetter(b)
    const aLength = lengthGetter(a)
    const bLength = lengthGetter(b)

    const collides = (
      // a's low edge collides with b's high edge
      (aEdge <= bEdge && aEdge + aLength >= bEdge)
      // b's low edge collides with a's high edge
      || (aEdge >= bEdge && aEdge <= bEdge + bLength)
    )
    return collides
  }
}
/**
 * Determines current window width breakpoint
 * @returns {('xs'|'sm'|'md'|'lg'|'xl')}
 */
export const useBreakpoint = () => {
  const theme = useTheme()
  if (!breakpoints?.keys) return 'xs'

  const keys = [...breakpoints.keys].reverse()

  return keys.reduce((output, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(theme.breakpoints.only(key))

    return !output && matches ? key : output
  }, null) ?? 'xs'
}

/**
 * Determines whether current window width lower or equal to {max} breakpoint provided
 * @param {('xs'|'sm'|'md'|'lg'|'xl')} max
 * @param {('xs'|'sm'|'md'|'lg'|'xl')} breakpoint
 * @returns {boolean}
 */
export const useIsBreakpointDown = (max, breakpoint) => {
  if (!breakpoints?.keys) return false

  const keys = [...breakpoints.keys].reverse()

  if (!keys.includes(max)) {
    throw new Error(`Invalid breakpoint provided. Valid breakpoints are: ${keys.join(', ')}`)
  }

  return keys.indexOf(max) <= keys.indexOf(breakpoint)
}

/**
 * Determines whether current window width is in the range of the breakpoint(s) provided
 * @param {('up'|'down'|'only'|'between')} range
 * @param {('xs'|'sm'|'md'|'lg'|'xl')[]} args
 * @returns {boolean}
 */
export const useMatchesBreakpoint = (range, breakpoint1, breakpoint2) => {
  const allowedRanges = ['up', 'down', 'only', 'between']
  const theme = useTheme()

  if (!range || !breakpoint1) {
    throw new Error('Expected range and at least one breakpoint parameter.')
  }

  if (range !== 'between' && breakpoint2) {
    throw new Error('Expected only one breakpoint parameter but had two.')
  }

  if (range === 'between' && !breakpoint2) {
    throw new Error('Expected second breakpoint parameter but had only one.')
  }

  if (!breakpoints.keys.includes(breakpoint1) || (breakpoint2 && !breakpoints.keys.includes(breakpoint2))) {
    throw new Error(`Invalid breakpoint provided. Valid breakpoints are: ${breakpoints.keys.join(', ')}`)
  }

  if (!allowedRanges.includes(range)) {
    throw new Error(`Invalid range provided. Valid breakpoints are: ${allowedRanges.join(', ')}`)
  }
  const matches = useMediaQuery(theme.breakpoints[range](...[breakpoint1, breakpoint2].filter(Boolean)))
  return matches
}

/**
 * Get root element font size for rem-based calculations
 * @returns {number} Root element font size in CSS pixels
 */
export const getRemSize = () => {
  const { document } = global
  if (!document) return 16
  const fontSize = global.getComputedStyle(document.querySelector(':root'))['font-size']

  return Number(fontSize.replace('px', '')) || 16
}

/**
 * Returns element's ancestor tree
 * @param {HTMLElement} el
 * @param {Array<HTMLElement>} ps
 * @returns {Array<HTMLElement>}
 */
const parents = (el, ps = []) => (
  el.parentNode === null ? ps : parents(el.parentNode, ps.concat(el))
)

const scrollingPattern = /auto|scroll/
/**
 * Tests whether a given element is a scroll context
 * @param {HTMLElement} el
 * @returns {boolean}
 */
const scrollCheck = el => {
  const computed = global.getComputedStyle(el, null)

  return scrollingPattern.test(computed.overflow || computed.overflowY || computed.overflowX || '')
}

/**
 * Returns the nearest ancestor that is a scroll context
 * @param {HTMLElement} element
 * @returns {HTMLElement|Window}
 */
export const getScrollContexts = element => {
  if (element instanceof HTMLElement) {
    const tree = parents(element)
    return tree.filter(scrollCheck)
  }
  return [document.querySelector('main#content'), global]
}
