import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'
import { DEVICE_STATES } from '~/src/Setup/Devices/utils'

const FETCH_INTERVAL = ms.seconds(5)
const MAX_POLLING_CALLS = 360
const MIN_POLLING_CALLS = 10

const PREFIX = 'PROVISIONING_STATUS'

export const ACTIONS = {
  pollingStarted: `${PREFIX}_POLLING_STARTED`,
  pollingFinished: `${PREFIX}_POLLING_FINISHED`,
  pollingCounted: `${PREFIX}_POLLING_COUNTED`,
  cleared: `${PREFIX}_CLEARED`,
  clearFailed: `CLEAR_${PREFIX}_FAILED`,
  displaySuccessLabel: `${PREFIX}_SUCCESS_LABEL_DISPLAYED`,
  hideSuccessLabel: `${PREFIX}_SUCCESS_LABEL_HIDDEN`,
}

export const additionalState = {
  displaySuccessLabel: false,
  isPolling: false,
  isFetching: false,
  pollingCount: 0,
  pollingStart: null,
}

const provisioningStatusBundle = createAsyncResourceBundle({
  name: 'provisioningStatus',
  actionBaseType: 'PROVISIONING_STATUS',
  getPromise: ({ apiFetch }) => apiFetch('/self_provisioning/status/')
})

export default {
  ...provisioningStatusBundle,
  reducer: reduceReducers(provisioningStatusBundle.reducer, (state, action) => {
    switch (action.type) {
      case ACTIONS.pollingStarted:
        return { ...state, isPolling: true, pollingCount: 1, pollingStart: getDateTime('now').minus({ seconds: 2 }) }
      case ACTIONS.pollingCounted:
        return { ...state, isPolling: true, pollingCount: state.pollingCount + 1 }
      case ACTIONS.pollingFinished:
        return { ...state, isPolling: false }
      case ACTIONS.displaySuccessLabel:
        return { ...state, displaySuccessLabel: true }
      case ACTIONS.hideSuccessLabel:
        return { ...state, displaySuccessLabel: false, pollingStart: null }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state, data: state.data ?? [] }
        }
        return state
    }
  }),
  doPollDevicesProvisioningStatus: () => async ({ dispatch, store }) => {
    const { isPolling, pollingCount, pollingStart, data } = store.selectProvisioningStatusRaw()
    const devices = store.selectDevices()

    if (!store.selectIsOnline()) {
      dispatch({ type: ACTIONS.pollingFinished })
    }

    const deviceStatus = data?.gateways && data?.devices ? [...data.gateways, ...data.devices] : EMPTY_ARRAY
    const hasPendingDevices = deviceStatus.some(d => d.status === 'PENDING')
    const newDevices = Object.values(devices).filter(d => (
      d.provisionedOn && pollingStart ? getDateTime(d.provisionedOn).diff(pollingStart).toObject() : null
    ))?.length

    if (newDevices) {
      dispatch({ type: ACTIONS.displaySuccessLabel })
    }

    if ((isPolling && hasPendingDevices && pollingCount < MAX_POLLING_CALLS) || (pollingCount < MIN_POLLING_CALLS)) {
      dispatch({ type: ACTIONS.pollingCounted })
      setTimeout(() => store.doPollDevicesProvisioningStatus(), FETCH_INTERVAL)
    } else {
      dispatch({ type: ACTIONS.pollingFinished })
    }

    return Promise.all([store.doFetchProvisioningStatus(), store.doFetchDeviceList()])
  },
  doStartPollingDevicesProvisioningStatus: () => ({ dispatch, store }) => {
    dispatch({ type: ACTIONS.pollingStarted })
    store.doDeviceListResetParams()
    store.doDeviceListSetStateFilter(DEVICE_STATES.RECENTLY_PROVISIONED)
    return store.doPollDevicesProvisioningStatus()
  },
  doClearDevicesProvisioningStatus: () => async ({ apiFetch, dispatch, store }) => {
    try {
      await apiFetch('/self_provisioning/provision/clear/', null, { method: 'POST' })
      dispatch({ type: ACTIONS.cleared })
      store.doFetchProvisioningStatus()
      return true
    } catch (error) {
      console.error(error)
      dispatch({ type: ACTIONS.clearFailed })
      return false
    }
  },
  doDismissSuccessLabel: () => ({ dispatch }) => {
    dispatch({ type: ACTIONS.hideSuccessLabel })
  },
  selectDevicesProvisioningStatus: createSelector(
    'selectProvisioningStatus',
    provisioningStatus => (
      provisioningStatus?.devices && provisioningStatus?.gateways
        ? [...provisioningStatus.devices, ...provisioningStatus.gateways]
        : EMPTY_ARRAY
    )
  ),
  selectSuccessLabelStatus: createSelector(
    'selectProvisioningStatusRaw',
    ({ displaySuccessLabel }) => displaySuccessLabel
  ),
  persistActions: [
    ...provisioningStatusBundle.persistActions,
    ...Object.values(ACTIONS)
  ]
}
