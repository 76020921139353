/* eslint-disable no-plusplus */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useConnect } from 'redux-bundler-hook'

import config from '~/src/App/config'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { throttle } from '~/src/Lib/Utils/timing'

import { EMPTY_OBJECT } from '../constants'
import { randomId } from '../ids'

const global = getGlobal()
const isBrowser = typeof global.document !== 'undefined'
const localStorage = safeStorage('local')

const logger = createLogger('useBluetoothScale')
const isDev = localStorage.debug === 'true' && config.ENVIRONMENT !== 'production'

/* eslint-disable no-underscore-dangle */
const defaultFakeDevice = {
  get id() {
    if (!this._id) {
      this._id = randomId()
    }
    return this._id
  },
  get name() {
    if (!this._name) {
      this._name = ['fake_scale', randomId(1)].join('_').toUpperCase()
    }
    return this._name
  },
}
/* eslint-enable no-underscore-dangle */
const SERVICE_UUID = '175f8f23-a570-49bd-9627-815a6a27de2a'
const READ_CHARACTERISTIC = 'cacc07ff-ffff-4c48-8fae-a9ef71b75e26'
const formatWeightReading = reading => {
  const weightString = String.fromCodePoint.apply(null, new Uint8Array(reading))
  if (!weightString) return EMPTY_OBJECT
  const [, rawWeight, unit, instability] = weightString.split(/\s+/)
  const value = Number(rawWeight) || 0
  const unitSymbol = unit === '?' ? undefined : (unit || undefined)
  const unstable = Boolean(instability) || unit === '?'

  const output = { value, unitSymbol, unstable }

  return output
}

const sleep = async seconds => new Promise(resolve => {
  setTimeout(resolve, seconds * 1000)
})

const exponentialBackoff = async params => {
  const { toTry, onError, remaining = 3, delay = 2 } = params
  try {
    const result = await toTry()
    return result
  } catch (error) {
    logger.debug('exponential backoff encountered error:', error)
    if (remaining === 0) {
      onError(error)
      throw error
    }
    await sleep(delay)
    return exponentialBackoff({ ...params, remaining: remaining - 1, delay: delay * 2 })
  }
}

const getProgress = step => Math.round((step / 6) * 100)

const OPTION_DEFAULTS = {
  onWeightReceived: () => {
    throw new Error('useBluetoothScale requires an onWeightReceived callback. None provided.')
  },
  onScaleConnect: () => {},
  maxRetries: 3,
  delay: 2,
  noThrottle: false,
}

const debug = throttle(logger.debug, 5000)
/**
 *
 * @param {Object} options
 * @param {function(Object)} options.onWeightReceived Called with { value: ##.##, unitSymbol: 'lb' }
 * @param {number} [options.maxRetries=3] One of g, kg, lb, oz
 * @param {number} [options.delay=2] One of g, kg, lb, oz
 */
export const useBluetoothScaleRedux = (opts = OPTION_DEFAULTS) => {
  const options = {
    ...OPTION_DEFAULTS,
    ...opts
  }
  const deviceRef = useRef(null)
  const listenerRef = useRef({ onWeightReceived: options.onWeightReceived })
  const [connectProgress, setConnectProgress] = useState(null)
  const [connectError, setConnectError] = useState(null)
  const { doSetHarvestingFlowSettings, ...scaleProps } = useConnect(
    'selectListenerScales',
    'selectScales',
    'doScaleConnect',
    'doScaleDisconnect',
    'doScaleListen',
    'doScaleUnlisten',
    'doScaleWeightReceived',
    'doSetHarvestingFlowSettings'
  )
  const {
    listenerScales = new Map(),
    scales,
    doScaleConnect,
    doScaleDisconnect,
    doScaleListen,
    doScaleUnlisten,
    doScaleWeightReceived,
  } = scaleProps
  const connectedScale = listenerScales.get(listenerRef)

  const onScaleListen = useCallback(scale => {
    deviceRef.current = scale
    doScaleListen({ listenerRef, scale })
    options.onScaleConnect(scale)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doScaleListen, options.onScaleConnect])
  const onScaleDisconnect = useCallback(() => {
    if (listenerRef.current) doScaleUnlisten(listenerRef)
    if (deviceRef.current) doScaleDisconnect({ scale: deviceRef.current })
    options.onScaleConnect(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doScaleDisconnect, doScaleUnlisten, options.onScaleConnect])

  const connectServer = useCallback(async () => {
    logger.debug('connect server called')
    return exponentialBackoff({
      toTry: async () => {
        const { current: scale } = deviceRef
        if (!scale?.gatt?.connect) {
          setConnectError('No scale service found on connected bluetooth device. Code 100.')
          return false
        }
        const server = await scale.gatt.connect()
        setConnectProgress(getProgress(2))
        const service = await server.getPrimaryService(SERVICE_UUID)
        setConnectProgress(getProgress(3))
        logger.debug('service located:', service)
        const characteristic = await service.getCharacteristic(READ_CHARACTERISTIC)

        const weightNotificationListener = event => {
          if (!event.target.value?.buffer) return
          debug('characteristicListener called:', event.target)

          // logger.debug('characteristicListener handling weight:', rawWeight, event.target)
          const formatted = formatWeightReading(event.target.value.buffer)
          if (!formatted.value) return

          doScaleWeightReceived({ scale, weight: formatted })
        }
        const characteristicListener = options.noThrottle
          ? weightNotificationListener
          : throttle(weightNotificationListener, 250)

        setConnectProgress(getProgress(4))
        logger.debug('characteristic retrieved:', characteristic)
        characteristic.addEventListener('characteristicvaluechanged', characteristicListener)
        setConnectProgress(getProgress(5))
        characteristic.startNotifications()

        const disconnectListener = async () => {
          characteristic.removeEventListener('characteristicvaluechanged', characteristicListener)
          logger.warn('Disconnected from scale. Attempting to reconnect.')
          return connectServer()
        }
        scale.addEventListener('gattserverdisconnected', disconnectListener)
        setConnectProgress(getProgress(6))
        doScaleConnect(Object.freeze({
          scale,
          server,
          service,
          characteristic,
          listeners: new Set()
        }))
        onScaleListen(scale)
        setConnectProgress(null)
        return true
      },
      onError: error => {
        setConnectError(error)
        setConnectProgress(null)
      },
      remaining: options.maxRetries,
      delay: options.delay
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onScalePair = useCallback(async () => {
    setConnectProgress(0)

    try {
      deviceRef.current = await navigator.bluetooth.requestDevice({
        filters: [{ services: [SERVICE_UUID] }]
      })
      logger.debug('device connected:', deviceRef.current)
      if (!deviceRef.current?.addEventListener) {
        setConnectError('Connected bluetooth device is incompatible. Code 10.')
        return false
      }
      setConnectProgress(getProgress(1))
      await connectServer()
      return true
    } catch (error) {
      setConnectProgress(null)
      logger.error('[useBluetoothScale] error encountered:', error)
      setConnectError('Unable to connect to bluetooth device. Code 1.')

      return false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (opts.scale && opts.scale.id && !connectedScale) {
      const scaleOption = scales.find(({ value: scale }) => scale.id === opts.scale.id)
      if (scaleOption) onScaleListen(scaleOption.value)
    }
  }, [scales, connectedScale, opts.scale, onScaleListen])

  useEffect(() => {
    listenerRef.current.onWeightReceived = options.onWeightReceived
  }, [options.onWeightReceived])

  useEffect(() => {
    if (isBrowser && isDev) {
      logger.debug('setting up scale simulator')
      let step = 0
      const connectSimulatedScale = () => {
        doScaleConnect(Object.freeze({
          scale: deviceRef.current,
          listeners: new Set()
        }))
        onScaleListen(deviceRef.current)
        setConnectProgress(null)
        logger.debug('connection stablished')
        if (global.location?.hash && global.location.hash.includes('harvesting')) {
          doSetHarvestingFlowSettings({ scale: deviceRef.current })
        }
      }
      const simulatedScale = {
        initialize: (fakeDevice = defaultFakeDevice, connect = false) => {
          deviceRef.current = typeof fakeDevice === 'string' ? { name: fakeDevice } : fakeDevice
          if (connect) {
            connectSimulatedScale()
          }
        },
        advanceStep: () => {
          if (!deviceRef.current) {
            simulatedScale.initialize()
          }
          if (step > 6) {
            connectSimulatedScale()
            return step
          }
          setConnectProgress(getProgress(step))
          step += 1
          return step
        },
        connect: (name, ms = 1500) => {
          if (!deviceRef.current) simulatedScale.initialize(name)
          step = 0
          const takeStep = () => {
            setConnectProgress(getProgress(step))
            if (step === 6) {
              connectSimulatedScale()
              return deviceRef.current
            }
            step += 1
            setTimeout(takeStep, isNaN(ms) ? 5000 : ms)
            return deviceRef.current
          }
          takeStep()
        },
        sendWeight: weightObj => {
          if (!deviceRef.current) simulatedScale.initialize(defaultFakeDevice, true)
          if (typeof weightObj.value !== 'number' || !String(weightObj.unitSymbol).match(/^k?g|lb|oz$/)) {
            throw new TypeError('simulatedScale.sendWeight accepts only the following object shape: { value: Number, unitSymbol: String(/^g|kg|lb|oz$/)}')
          }
          doScaleWeightReceived({
            scale: deviceRef.current,
            weight: weightObj,
          })
        },
        print: string => {
          if (!deviceRef.current) simulatedScale.initialize(defaultFakeDevice, true)
          const { Buffer, TextEncoder } = global
          doScaleWeightReceived({
            scale: deviceRef.current,
            weight: formatWeightReading(Buffer ? Buffer.from(String(string), 'utf-8') : new TextEncoder().encode(String(string)))
          })
        }
      }
      global.simulatedScale = simulatedScale
    }
    return () => doScaleUnlisten(listenerRef)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => ({
    getOnScaleConnect: scale => () => onScaleListen(scale),
    onScaleListen,
    onScalePair,
    onScaleDisconnect,
    connecting: connectProgress !== null,
    connectProgress,
    connectError,
    scales,
    scale: connectedScale
  }), [
    connectError,
    connectProgress,
    connectedScale,
    onScaleDisconnect,
    onScaleListen,
    onScalePair,
    scales
  ])
}
