import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'

import createListBundle from '~/src/Lib/createListBundle'
import { PackageAdjustmentReason as schema } from '~/src/Store/Schemas'

const PACKAGES_ROUTE = '/packages'

export const initialState = {
  search: '',
  ordering: ['name', 'asc'],
}

const { selectPackageAdjustmentReasonListShouldUpdate, ...initialBundle } = createListBundle({
  entityName: 'packageAdjustmentReason',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/packageAdjustmentReasons/', { ...params, pagination: 0 }),
  urlTest: url => url.includes(PACKAGES_ROUTE),
  flags: { keys: ['METRC'] },
  staleAfter: minutes(60),
})

export default {
  ...initialBundle,
  selectPackageAdjustmentReasonListShouldNormallyUpdate: selectPackageAdjustmentReasonListShouldUpdate,
  selectPackageAdjustmentReasonListShouldUpdate: createSelector(
    'selectPackageAdjustmentReasonListShouldNormallyUpdate',
    'selectPackageAdjustmentReasonListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastPackageAdjustmentReasonRefresh && lastSuccess < new Date(metrcFacility.lastPackageAdjustmentReasonRefresh)
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
