import { createSelector } from 'redux-bundler'

import { getAsyncActionIdentifiers } from '~/src/Lib/createEntityBundle'
import createLogger from '~/src/Lib/Logging'
import { parseApiErrors } from '~/src/Lib/Utils'

const logger = createLogger('Device/bundle#provisioning')

const provisionDevicesActions = getAsyncActionIdentifiers('provision', 'devices')
const provisionGatewaysActions = getAsyncActionIdentifiers('provision', 'gateways')

const CLEAR_PROVISIONING_ERRORS = 'CLEAR_PROVISIONING_ERRORS'
const SET_PROVISIONING_ERRORS = 'SET_PROVISIONING_ERRORS'

const initialState = {
  provisioningErrors: {},
}

const deviceProvisioningBundle = {
  name: 'deviceProvisioning',
  reducer: (state = initialState, action = {}) => {
    switch (action.type) {
      case CLEAR_PROVISIONING_ERRORS:
        return { ...state, provisioningErrors: {} }
      case SET_PROVISIONING_ERRORS:
        return {
          ...state,
          provisioningErrors: {
            ...state.provisioningErrors,
            ...action.payload,
          }
        }
      default:
        return state
    }
  },
  selectDeviceProvisioning: state => state.deviceProvisioning,
  selectDeviceProvisioningErrors: createSelector(
    'selectDeviceProvisioning',
    ({ provisioningErrors }) => provisioningErrors,
  ),
  doClearProvisioningErrors: () => ({ dispatch }) => {
    dispatch({ type: CLEAR_PROVISIONING_ERRORS })
  },
  doProvisionDevices: payload => async ({ apiFetch, dispatch }) => {
    dispatch({ type: CLEAR_PROVISIONING_ERRORS })
    dispatch({ type: provisionDevicesActions.types.start })
    return apiFetch('/self_provisioning/provision/', payload, { method: 'POST' }).then(response => {
      dispatch({ type: provisionDevicesActions.types.succeed, payload: response })
      return response
    }).catch(e => {
      const error = parseApiErrors(e) || e.message || 'Unknown error. Please try again.'
      dispatch({ type: provisionDevicesActions.types.fail, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })
    })
  },
  doProvisionGateways: payload => async ({ apiFetch, dispatch }) => {
    dispatch({ type: CLEAR_PROVISIONING_ERRORS })
    dispatch({ type: provisionGatewaysActions.types.start, payload })
    return apiFetch('/self_provisioning/provision/gateway/', payload, { method: 'POST' }).then(response => {
      dispatch({ type: provisionGatewaysActions.types.succeed, payload: response })
      return response
    }).catch(e => {
      let error = parseApiErrors(e) || e.message || 'Unknown error. Please try again.'
      if (error === 'Failed to fetch') {
        // 504 API timeout (provisioning lambda took too long)
        error = 'Gateway is not ready. Please check that it is powered and try again.'
      }
      dispatch({ type: provisionGatewaysActions.types.fail, payload, error })
      dispatch({ actionCreator: 'doAddSnackbarMessage', args: [error] })

      if (error.startsWith('Gateway ')) {
        const [_, sn] = error.split(/\s+/)
        if (sn && payload.devices.find(d => d.serialNumber === sn)) {
          dispatch({ type: SET_PROVISIONING_ERRORS, payload: { [sn]: error } })
        } else {
          dispatch({ type: SET_PROVISIONING_ERRORS, payload: { general: error } })
        }
      } else {
        dispatch({ type: SET_PROVISIONING_ERRORS, payload: { general: error } })
      }
    })
  },
}

export default deviceProvisioningBundle
