/* eslint-disable consistent-return */
import PropTypes from 'prop-types'

import { getType, repr } from './Utils'

export const defaultInvalidMsg = (prop, name, component) => `Invalid prop '${name}' of type '${repr(prop)}' supplied to '${component}'.`
export const formatErrorMessage = message => message.replace(/(\s)\s+/g, '$1')
const OTHER_RENDERABLES = new Set(['Function', 'Null', 'Number', 'String'])
const REACT_TYPES = new Set(['element', 'forward_ref', 'lazy', 'memo'].map(type => Symbol.for(`react.${type}`)))

/**
 * @callback propTypeChecker
 * @param {Object} props - a React component's props
 * @param {string} name - a prop's name
 * @param {string} component - the component's name
 */

/**
 * @callback advancedPropTypeChecker
 * @augments propTypeChecker
 * @property {propTypeChecker} isRequired
 * @property {propTypeChecker} [nullable]
 */

export const innerRenderableType = prop => {
  // Simple node types + render functions
  if (OTHER_RENDERABLES.has(getType(prop))) {
    return true
  }
  // Object with a render function
  if (prop && typeof prop.render === 'function') {
    return true
  }
  // React element types
  const reactType = prop && prop.$$typeof
  return REACT_TYPES.has(reactType)
}

export const errorMessagePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
])

const isRequiredFactory = propType => (...args) => {
  const [props, name, component] = args
  const prop = props[name]
  if (!(name in props) || prop == null || prop === '') {
    return new Error(
      `'${name}' is required by '${component}', but it wasn't provided.`
    )
  }
  return propType(...args)
}

/**
 * Factory for creating custom prop type checkers
 * @param {propTypeChecker} validator
 * @param {Object} opts
 * @param {boolean} [opts.nullable=false]
 * @returns {} A validator function that has an isRequired property that disallows undefined or null \
 * and optionally a nullable property that explicitly allows null
 */
export const propTypeFactory = (validator, opts = {}) => {
  /* eslint-disable no-param-reassign */
  validator.isRequired = isRequiredFactory(validator)
  if (opts.nullable) {
    validator.nullable = (props, name, ...args) => {
      const prop = props[name]
      if (prop == null) return undefined
      return validator(props, name, ...args)
    }
  }
  return validator
  /* eslint-enable no-param-reassign */
}
const isMissing = prop => (prop == null || (typeof prop === 'object' && !Object.keys(prop).length))

/**
 * Builds prop-type checkers that verify that their prop or one of the sibling props exists and is valid
 * @param {Object<propTypeChecker>} propTypes
 * @returns {Object<propTypeChecker>} An object of prop-type checkers that validate their own type AND that at one of the specified types exists and is valid
 */
export const atLeastOneRequired = (propTypes = {}) => {
  const list = Object.entries(propTypes)
  if (!list.length || list.length === 1) {
    throw new Error(
      'atLeastOneRequired requires at least two defined propTypes. For one, please use propType.isRequired.'
    )
  }
  return list.reduce((config, entry, index) => {
    const [key, propType] = entry
    const otherProps = list.slice().filter((_, i) => i !== index)
    return {
      ...config,
      [key]: (props, propName, componentName, ...rest) => {
        const prop = props[propName]
        const missing = isMissing(prop)
        const propTypeError = propType(props, propName, componentName, ...rest)
        if (missing || propTypeError) {
          const one = otherProps.find(([oKey, oPropType]) => {
            const oProp = props[oKey]
            return oProp != null && !oPropType(props, oKey, componentName, ...rest)
          })
          if (!one) {
            return new Error(
              formatErrorMessage(`
              Invalid prop '${propName}' passed to '${componentName}'.
              ${missing ? 'It did not exist, ' : ''}${
  propTypeError
    ? `It failed validation: [${propTypeError.toString()}], `
    : ' '
}and it's alternates: [${otherProps
  .map(([name]) => name)
  .join(', ')}]
              were not present or failed validation.
            `)
            )
          }
          if (propTypeError) {
            return propTypeError
          }
        }
      },
    }
  }, {})
}

const findExcluded = (excludeProps, props, propName, componentName) => {
  const foundExcluded = excludeProps.filter(ex => {
    const exProp = props[ex]
    if (typeof exProp === 'undefined' || exProp === null) return false
    return true
  })
  if (props[propName] && foundExcluded.length) {
    return new Error(
      `[${componentName}] cannot have both [${propName}] and [${foundExcluded.join(
        ', '
      )}] props.`
    )
  }
}

export const exclusiveOf = (propType, ...excludeProps) => {
  const validator = (props, propName, componentName, ...rest) => findExcluded(excludeProps, props, propName, componentName)
    || propType(props, propName, componentName, ...rest)

  validator.isRequired = (props, propName, componentName, ...rest) => findExcluded(excludeProps, props, propName, componentName)
    || propType.isRequired(props, propName, componentName, ...rest)

  return validator
}

export const datePropType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  if (!prop) return undefined
  const isValidJSDate = prop instanceof Date && prop.getTime()
  const isValidLuxonDatetime = typeof prop !== 'string' && 'isLuxonDateTime' in prop && prop.isValid
  // it passes if it is not defined or a js Date or a luxon DateTime
  if (isValidJSDate || isValidLuxonDatetime) return undefined

  // if it is a string that can be parsed into a date, it passes
  if (new Date(prop).getTime()) return undefined

  return new Error(
    formatErrorMessage(`${defaultInvalidMsg(prop, name, component)}. Expected a valid date string or object.`)
  )
}, { nullable: true })

const newChildIdPattern = /^(([a-z]+)(_|-)){1,}(new-)?(\d+)/i
const allDigits = /^\d+$/
const idPropTypeStringTests = [
  newChildIdPattern.test.bind(newChildIdPattern),
  allDigits.test.bind(allDigits),
  prop => prop === '',
  prop => prop.toLowerCase() === 'new',
]
const idPropTypeStringValid = prop => typeof prop === 'string' && idPropTypeStringTests.some(test => test(prop))
export const idPropType = propTypeFactory(
  (props, name, component) => {
    const prop = props[name]
    if (prop === undefined) {
      return prop
    }
    const typeIsInvalid = String(prop) !== prop && Number(prop) !== +prop

    if (idPropTypeStringValid(prop)) {
      return undefined
    }
    // eslint-disable-next-line no-self-compare
    if (typeIsInvalid || !Number(prop)) {
      return new Error(
        formatErrorMessage(`
      ${defaultInvalidMsg(prop, name, component)}
      Expected a whole Number greater than 0 or a String convertible to such a Number.
    `)
      )
    }
  },
  { nullable: true }
)

export const idAndNameShape = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
})

export const numberOrEmptyType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  const isNumber = Number(prop) === +prop
  if (isNumber || prop === '' || prop == null) {
    return undefined
  }
  return new Error(
    formatErrorMessage(
      `${defaultInvalidMsg(
        prop,
        name,
        component
      )} Expected number or empty string.`
    )
  )
})

export const paginationPropTypes = PropTypes.shape({
  count: PropTypes.number,
  current: PropTypes.number,
  next: PropTypes.number,
  numPages: PropTypes.number,
  pageSize: PropTypes.number,
  previous: PropTypes.number,
  results: PropTypes.arrayOf(idPropType),
})

const defaultRefTest = ref => ref.current instanceof Element
export const refTypeFactory = (refTest = defaultRefTest, nullable = false) => (props, propName, componentName) => {
  const { [propName]: ref } = props
  //
  if (ref == null) {
    if (nullable) return null
    return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Received "${repr(ref)}" when it is required.`)
  }
  const notObjectRef = typeof ref !== 'object' || 'current' in ref === false
  const invalidReference = notObjectRef || (ref.current !== null && !refTest(ref))
  if (typeof ref !== 'function' && invalidReference) {
    return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a DOM element reference.`)
  }
  return null
}
export const elementRefType = refTypeFactory(defaultRefTest, true)

/**
 * A propType that accepts a single renderable type or an array of renderable types
 * where a renderable type either satisfies the definition of PropTypes.node or PropTypes.elementType
 */
export const renderableType = propTypeFactory((props, name, component) => {
  const prop = props[name]
  if (Array.isArray(prop) && prop.every(item => innerRenderableType(item))) {
    return undefined
  }
  if (innerRenderableType(prop)) {
    return undefined
  }

  return new Error(
    formatErrorMessage(`
    Invalid prop '${name}' supplied to '${component}'.
    Expected a renderable type: a single (String, Function, { render() {} }, null)
    or an Array of the same.
    Received: ${repr(prop)}
  `)
  )
}, { nullable: true })

export const regexPropType = (regex, nullable = true) => propTypeFactory(
  (props, name, component) => {
    const prop = props[name]
    if (typeof prop !== 'string' || !regex.test(prop)) {
      return new Error(
        formatErrorMessage(`
        ${defaultInvalidMsg(prop, name, component)}
        Expected it to be a string matching pattern (${regex})
      `)
      )
    }
  },
  { nullable }
)

export const stylePropType = PropTypes.objectOf(PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]))

export const samplingArrayOf = checker => propTypeFactory((props, name, component) => {
  const prop = props[name]
  if (!Array.isArray(prop)) {
    return new Error(
      formatErrorMessage(`
        ${defaultInvalidMsg(prop, name, component)}
        Expected it to be an Array.
      `)
    )
  }

  const sample = Math.round(Math.random()) ? prop.slice(0, 2) : prop.slice(-2)

  for (let i = 0; i < sample.length; i += 1) {
    const iName = `${name}[${i}]`
    PropTypes.checkPropTypes({ [iName]: checker }, { [iName]: sample[i] }, 'prop', component)
  }
  return null
})

/**
 * Allows creating prop-type definitions for dynamic props.
 * For example, prop idAttribute is a string.isRequired that defines the prop name for a number.isRequired id
 * idAttribute: dynamicPropType(string.isRequired, number.isRequired)
 * props: { idAttribute: 'pk', pk: null } fails the check because pk is null
 * props: { idAttribute: null } fails the check because idAttribute is null
 * props: { idAttribute: 'pk', pk: 1 } passes the check because pk is a number
 * @param {propTypeChecker} ownPropType -
 * @param {propTypeChecker} dynamicPropPropType
 */
export const dynamicPropType = (ownPropType, dynamicPropPropType) => (props, name, component) => {
  const { [name]: prop, [prop]: dynamicProp } = props

  PropTypes.checkPropTypes({
    [name]: ownPropType,
    [prop]: dynamicPropPropType,
  }, {
    [name]: prop,
    [prop]: dynamicProp,
  }, 'prop', component)

  return null
}

// represents the style classes
export const classesType = PropTypes.objectOf(PropTypes.string)

export const nullType = (props, name, component) => {
  const prop = props[name]
  if (prop !== null) {
    return new Error(
      formatErrorMessage(`${defaultInvalidMsg(prop, name, component)} Expected it to be null.`)
    )
  }
}
