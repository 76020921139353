export const firmwareVersionCompareFn = (a, b) => {
  if (a === b) return 0
  const versionA = a.split('.')
  const versionB = b.split('.')
  let index = 0
  while (versionA[index] != null && versionB[index] != null) {
    const subA = Number(versionA[index])
    const subB = Number(versionB[index])
    if (subA !== subB) {
      return subA - subB
    }
    index += 1
  }
  if (versionA[index]) return 1
  return -1
}

export const firmwareVersionIsLessThan = (a, b) => firmwareVersionCompareFn(a, b) < 0
export const firmwareVersionIsGreaterThan = (a, b) => firmwareVersionCompareFn(a, b) > 0
