import { useEffect, useMemo } from 'react'

import i18n from 'i18n-literally'
import memoizeOne from 'memoize-one'
import {
  filter,
  flatten,
  groupBy,
  map,
  mergeWith,
  omit,
  pick,
  pipe,
  prop,
  uniq,
} from 'ramda'
import { useConnect } from 'redux-bundler-hook'

import { titleize } from 'inflection'
import { Interval } from 'luxon'
import * as yup from 'yup'

import { HARVEST_ROOM_TYPES, PHASE_TYPES } from '~/src/Harvest/constants'
import createLogger from '~/src/Lib/Logging'
import {
  dateTimeSchema,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  formattedDate,
  getDateTime,
  idValidator,
  initials,
  memoize,
} from '~/src/Lib/Utils'

const logger = createLogger('HG/utils')

export const orderedRoomPrefixes = HARVEST_ROOM_TYPES
export const roomPrefixes = Object.freeze(['flower', 'prop', 'veg', 'dry'])

export const roomToFriendlyName = Object.freeze(roomPrefixes.reduce((rtfn, prefix) => ({
  ...rtfn,
  [`${prefix}Room`]: `${titleize(prefix)} Room`
}), EMPTY_OBJECT))

export const getDefaultRoom = harvest => (
  harvest[`${roomPrefixes.find(prefix => harvest[`${prefix}Room`])}Room`]
)

export const defaultCultivar = {
  cultivarName: '',
  count: null,
  vegZones: [],
  flowerZones: [],
  dryZones: [],
}

export const getOnCultivarAdd = memoize(push => () => push({ ...defaultCultivar }))
export const getOnCultivarRemove = memoize((index, remove) => () => remove(index))

const getRecipeRoomMessage = prefix => `Recipe requires a ${prefix} room.`

const cultivarFields = Object.freeze([
  'id',
  'count',
  'cultivar',
  'cultivarName',
  'metrcBatchGroups',
  // zones
  'propZones',
  'vegZones',
  'flowerZones',
  'dryZones',
])
export const tagTypes = Object.freeze([
  'hexadecimal',
  'uuid',
  'integer',
])
const isHexadecimal = value => (/[0-9A-Fa-f]{6}/g).test(value)
const isUUID = value => (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi).test(value)
const isTagValid = value => !Number.isInteger(value) || isHexadecimal(value) || isUUID(value)

export const cultivarPicker = pick(cultivarFields)
export const cultivarOmitter = omit(cultivarFields)

export const mergeSameCultivar = pipe(
  groupBy(prop('cultivarName')),
  map(list => (
    list.length === 1 ? list[0] : list.reduce((prev, next) => ({
      ...next,
      count: prev.count + next.count,
      metrcBatchGroups: [...prev.metrcBatchGroups, ...next.metrcBatchGroups]
    }))
  )),
  Object.values
)

export const getUsedMetrcBatches = memoizeOne(pipe(
  filter(c => Boolean(c.metrcBatchGroups?.length)),
  map(prop('metrcBatchGroups')),
  flatten,
  uniq
))

export const validationSchema = yup.object().shape({
  name: yup.string().trim().required(i18n`Please enter a harvest group name`).max(50),
  ...HARVEST_ROOM_TYPES.reduce((schemas, prefix) => {
    const fieldName = `${prefix}Room`
    schemas[fieldName] = yup.mixed().when('recipe', ([recipe], schema) => {
      if (recipe && recipe.phases?.length && recipe.phases.some(phase => phase.phaseType === prefix.toUpperCase())) {
        return idValidator(fieldName, true, getRecipeRoomMessage(prefix))
      }
      return schema.nullable().optional()
    })
    return schemas
  }, {}),
  startDate: dateTimeSchema.required(i18n`Please specify start date`).typeError(i18n`Please specify start date`),
  harvestDate: yup.mixed().notRequired().test({
    name: 'valid-harvestDate',
    message: i18n`Harvest date must be after start date`,
    test: (value, ctx) => {
      const { recipe, startDate } = ctx.parent
      if (!startDate || !value) return true
      const harvestDate = getDateTime(value)
      const start = getDateTime(startDate)
      const dryOnly = Array.isArray(recipe?.phases) && !recipe.phases.some(phase => phase.phaseType !== 'DRY')
      if (dryOnly) {
        return harvestDate.hasSame(start, 'day') ? true : ctx.createError({
          message: i18n`Harvest date must be same as start date`
        })
      }
      return harvestDate > start
    }
  }),
  cultivars: yup.array().of(yup.object({
    id: idValidator(),
    startingTag: yup.string().test('valid', i18n`Starting tag must be of any these types: ${tagTypes.join(', ')}`, isTagValid).nullable(),
    tagType: yup.string().when('startingTag', {
      is: v => Boolean(v),
      then: s => s.test('valid', i18n`Tag type can be any of these types: ${tagTypes.join(', ')}`, tagTypes.includes)
    }).nullable(),
    cultivarName: yup.string().required(i18n`Cultivar is required`),
    count: yup.number().integer()
      .when('id', ([id], schema) => (id ? schema : schema.min(1, i18n`Count should be at least one`)))
      .max(10000, i18n`Count should be no more than 10,000.`)
      .required(i18n`Please enter 1 or more`)
      .typeError(i18n`Please enter 1 or more`),
    plants: yup.array().of(yup.object({
      label: yup.string().required(),
    })),
    plantBatches: yup.array().of(yup.object({
      label: yup.string().required(),
    })),
    flowerZones: yup.array().of(idValidator()),
    vegZones: yup.array().of(idValidator()),
    dryZones: yup.array().of(idValidator()),
  })),
  recipe: yup.mixed().when(['state', 'id'], ([state, id]) => {
    // when saved, recipe is a boolean that means this is a recipe not a real harvest group
    if (id) return yup.bool()
    return state?.continuous
      ? yup.object().required(i18n`A recipe is required in continuous harvest facilities.`)
      : yup.object().notRequired()
  }),
}).atLeastOneRequired(HARVEST_ROOM_TYPES.map(prefix => `${prefix}Room`), roomToFriendlyName)

export const formikPicker = memoizeOne(pick([
  'dirty',
  'errors',
  'status',
  'touched',
  'values',
  'isSubmitting',
  'isValid',
  'handleSubmit',
  'setFieldValue',
  'setValues'
]))

export const generateShortName = ({ values, rooms }) => {
  const { startDate, harvestDate } = values
  const roomId = getDefaultRoom(values)
  const { [roomId]: room } = rooms
  const roomInitials = room ? initials(room.name) : null
  const dates = ([
    startDate ? formattedDate(startDate, 'TINY_DATE') : null,
    harvestDate ? formattedDate(harvestDate, 'TINY_DATE') : null,
  ]).filter(Boolean)

  return roomInitials && dates.length ? `${roomInitials} ${dates.join('-')}` : ''
}

const phasePicker = pick(['name', 'phaseType', 'sequence', 'totalDays'])
const lightsPicker = pick(['alert', 'alertThreshold', 'alertWindow', 'duration', 'startTime'])
const targetPicker = pick(['alertMax', 'alertMin', 'dataType', 'tarvetMax', 'targetMin'])
export const prepareRecipe = ({ phases }) => phases.map(phase => {
  const { lightSchedules, targetRanges } = phase

  return {
    ...phasePicker(phase),
    lightSchedules: lightSchedules ? lightSchedules.map(lightsPicker) : lightSchedules,
    targetRanges: targetRanges ? targetRanges.map(targetPicker) : targetRanges,
  }
})

export const getHarvestCultivarLabels = memoize(hc => {
  if (hc.groups) {
    const { groups } = hc
    let transfer = false
    const hcLabels = groups.reduce((all, group) => {
      transfer = group.transfer
      const { source, labels: groupLabels } = group
      let { sources, labels } = all
      if (!transfer && source.label) {
        sources = [...sources, source.label]
      }
      if (groupLabels?.length) {
        labels = [...labels, ...groupLabels]
        if (transfer) {
          sources = [...sources, ...groupLabels]
        }
      }

      return { sources, labels }
    }, { sources: [], labels: [] })

    hcLabels.labels.sort((a, b) => a.localeCompare(b))
    hcLabels.startLabel = hcLabels.labels[0]
    hcLabels.endLabel = hcLabels.labels.length > 1 ? hcLabels.labels[hcLabels.labels.length - 1] : null
    hcLabels.transfer = transfer
    return hcLabels
  }
  if (hc.source) {
    const { label, additionalLabels, source } = hc
    const labels = [label, ...additionalLabels].sort((a, b) => a.localeCompare(b))
    const { 0: startLabel, [labels.length > 1 && labels.length - 1]: endLabel } = labels
    return { sources: [source.label], labels, startLabel, endLabel, transfer: hc.transfer }
  }

  return EMPTY_OBJECT
})

export const getTaskDataByPhase = memoizeOne((phases, phaseData) => {
  if (!phases?.length) { return EMPTY_OBJECT }
  const groupByPhaseType = groupBy(prop('phaseType'))
  const phasesByType = groupByPhaseType(phases.map(id => phaseData[id]))
  const finalPhaseType = phaseData[phases[phases.length - 1]]?.phaseType

  const arbitraryStartDate = getDateTime('now')
  let dateOffset = 0
  const intervalsByPhase = phases.reduce((ibp, phaseId) => {
    const { totalDays } = phaseData[phaseId]
    const phaseStart = arbitraryStartDate.plus({ days: dateOffset })
    const phaseEnd = phaseStart.plus({ days: totalDays })
    const phaseInterval = Interval.fromDateTimes(phaseStart, phaseEnd)
    dateOffset += totalDays
    return { ...ibp, [phaseId]: phaseInterval }
  }, EMPTY_OBJECT)

  dateOffset = 0
  const allTasks = phases.reduce((t, phaseId) => {
    const { tasks: rawTasks, phaseType } = phaseData[phaseId]
    const { start: phaseStart, end: phaseEnd } = intervalsByPhase[phaseId]

    // Expand recurring tasks with their expected timestamp
    const expandedTasks = rawTasks?.reduce((expanded, task) => {
      const { parentTask, recurrenceRule, relativeStartDay, relativeStartTime, title } = task
      if (parentTask) return expanded
      const { count, interval, unit, untilEndOf } = recurrenceRule ?? EMPTY_OBJECT
      const { hours, minutes } = relativeStartTime
      const taskStart = phaseStart.plus({ days: relativeStartDay - 1, hours, minutes })
      const isRecurringTask = unit

      let end
      if (unit && !(interval || untilEndOf || count)) {
        // every [unit] until end of phase
        end = phaseEnd
      } else if (!count) {
        // every [interval]x[unit] until end of [untilEndOf]
        if (untilEndOf === 'stage') {
          end = intervalsByPhase[phasesByType[phaseType].slice(-1)[0].id].end
        }
        if (untilEndOf === 'phase') {
          end = phaseEnd
        }
        if (untilEndOf === 'harvest') {
          end = intervalsByPhase[phasesByType[finalPhaseType].slice(-1)[0].id].end
        }
      } else {
        // [count] tasks, every [interval] [unit]
        end = taskStart.plus({ [unit]: count * interval })
      }

      // This can occur while phase 'totalDays' is being modified
      if (end < taskStart) return expanded

      const numberOfTasks = isRecurringTask ? (
        Math.ceil(Interval.fromDateTimes(taskStart, end).length(unit) / (interval ?? 1))
      ) : 1
      const tasks = Array(numberOfTasks).fill().map((_, index) => ({
        title: index === 0 ? title : `${title} (${index + 1})`,
        ts: isRecurringTask ? taskStart.plus({ [unit]: (interval ?? 1) * index }) : taskStart,
      }))

      return [...expanded, ...tasks]
    }, EMPTY_ARRAY) ?? EMPTY_ARRAY
    return [...t, ...expandedTasks]
  }, EMPTY_ARRAY)

  const taskDataByPhase = allTasks.reduce((tdbp, task) => {
    const phaseId = phases.find(id => intervalsByPhase[id].contains(task.ts))
    if (!phaseId) return tdbp
    const phaseInterval = intervalsByPhase[phaseId]
    const dayInPhase = task.ts.diff(phaseInterval.start, 'days').days + 1
    const prevTasks = tdbp[phaseId] ?? EMPTY_ARRAY
    return { ...tdbp, [phaseId]: [...prevTasks, { ...task, dayInPhase }] }
  }, EMPTY_OBJECT)

  return taskDataByPhase
})

/**
 * Given the values from a harvest formik state (create or edit), fetches availability and
 * @param {!Object} values
 * @param {string|date} values.startDate harvest start date
 * @param {Array<Object>} [values.phases] existing harvest's phases
 * @param {Object} [values.recipe] new harvest's recipe
 * @param {Array<Object>} [values.recipe.phases] new harvest recipe's phases
 * @returns { }
 */
export const useAvailabilityByPhase = values => {
  const {
    roomAvailability,
    doAvailabilitiesFetch,
  } = useConnect(
    'selectRoomAvailability',
    'doAvailabilitiesFetch',
  )

  const { id: harvest, phases: harvestPhases, recipe, startDate } = values
  const datesByPhase = useMemo(() => {
    const list = harvestPhases ?? recipe?.phases
    if (!list?.length || !startDate) return EMPTY_OBJECT
    let phaseStart = getDateTime(startDate)
    return PHASE_TYPES.reduce((dates, type) => {
      const typePhases = list.filter(({ phaseType }) => phaseType === type)
      if (!typePhases.length) return dates
      const phaseEnd = phaseStart.plus({
        days: typePhases.reduce((days, phase) => days + (phase.totalDays || 0), 0)
      })
      const next = {
        ...dates,
        [type.toLowerCase()]: {
          start: phaseStart.toISODate(),
          end: phaseEnd.toISODate(),
          harvest,
        },
      }
      phaseStart = phaseEnd
      return next
    }, EMPTY_OBJECT)
  }, [harvest, harvestPhases, recipe?.phases, startDate])

  useEffect(() => {
    doAvailabilitiesFetch(datesByPhase)
  }, [datesByPhase, doAvailabilitiesFetch])

  return mergeWith(
    (dates, availability) => ({ ...dates, availability }),
    datesByPhase,
    roomAvailability.data,
  )
}
