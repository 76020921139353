import { isValidElement, memo } from 'react'

import PropTypes from 'prop-types'

import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import Null from '~/src/UI/Shared/Null'

import { configured } from './utils'

const Configured = ({
  children,
  Fallback = Null,
  FallbackProps = EMPTY_OBJECT,
  setting = null,
  test = null
}) => {
  const isConfigured = configured({ setting, test })
  if (isConfigured) return children
  return isValidElement(Fallback) || typeof Fallback === 'string'
    ? Fallback
    : <Fallback {...FallbackProps} />
}
Configured.displayName = 'Configured'
Configured.propTypes = {
  children: PropTypes.node.isRequired,
  Fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  FallbackProps: PropTypes.shape({}),
  setting: PropTypes.string,
  test: PropTypes.func
}

const Memoized = memo(Configured)

export default Memoized
