import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'

export const hasDripDrain = (devicesByRoom, roomId, zoneIds = EMPTY_ARRAY) => {
  if (!roomId) return false
  const { [roomId]: roomDevices = EMPTY_ARRAY } = devicesByRoom
  return roomDevices.some(({ modelKey, calibrationType, zone }) => {
    const isDripDrain = modelKey === 'ter15' && calibrationType !== 'NONE'
    if (!zoneIds.length) return isDripDrain
    return isDripDrain && zoneIds.includes(zone)
  })
}

const useHasDripDrain = (roomId, zoneIds) => {
  const { devicesByRoom } = useConcurrentConnect('selectDevicesByRoom')
  return hasDripDrain(devicesByRoom, roomId, zoneIds)
}

export default useHasDripDrain
