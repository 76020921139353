import { useEffect, useRef, useState } from 'react'

import { useConnect } from 'redux-bundler-hook'

import { minutes, seconds } from 'milliseconds'

import { getDateTime } from '~/src/Lib/Utils'

const STALENESS_MINUTES = minutes(5)
const WAIT_BETWEEN_FETCHES = seconds(5)

const haveHarvestEntity = (harvestId, harvest) => (
  harvestId === harvest?.id && harvest?.payloadType === 'entity'
)

const isStale = harvest => {
  if (!harvest?.fetchedAt) {
    return true
  }

  let fetchedAt = harvest.fetchedAt
  if (typeof fetchedAt !== 'number') {
    fetchedAt = getDateTime(fetchedAt)
    if (!fetchedAt.isValid) {
      return true
    }
  }

  const diff = Date.now() - fetchedAt
  return diff > 0 && diff >= STALENESS_MINUTES
}

export const useHarvestEntity = id => {
  const harvestId = Number(id)
  const {
    harvest: entity,
    harvests,
    doHarvestFetch,
    phases,
  } = useConnect(
    'selectHarvest',
    'selectHarvests',
    'doHarvestFetch',
    'selectPhases'
  )

  const lastTry = useRef(0)

  const [harvest, setHarvest] = useState(() => (
    haveHarvestEntity(harvestId, entity) ? entity : (harvests[harvestId] ?? null)
  ))

  useEffect(() => {
    const now = Date.now()
    const shouldFetch = !haveHarvestEntity(harvestId, entity) || isStale(entity) || !lastTry.current
    const shouldWait = now - WAIT_BETWEEN_FETCHES < lastTry.current

    if (shouldFetch && !entity?.inflight) {
      if (shouldWait) {
        // eslint-disable-next-line no-console
        console.warn('trying to refetch too often', { harvestId, entity, now })
      } else if (harvestId) {
        lastTry.current = now
        doHarvestFetch(harvestId)
        return
      }
    }

    setHarvest(entity)
  }, [doHarvestFetch, entity, harvestId])

  const harvestPhases = harvest?.phases || []

  return harvestPhases.length ? {
    ...harvest,
    phases: harvestPhases
      .map(phaseId => phases[phaseId])
      .filter(Boolean)
      .sort((a, b) => a.sequence - b.sequence),
  } : harvest
}
