import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'

import { TAB_STATE_TYPE as TAB_STATE, TAB_STATES } from '../constants'

const logger = createLogger('Store/tabState')
const window = getGlobal()

const STANDARD_EVENTS = ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume']
const HANDLER_OPTS = { capture: true, passive: true }

const getTabState = () => {
  if (document.visibilityState === 'hidden') {
    return TAB_STATES.HIDDEN
  }
  if (document.hasFocus()) {
    return TAB_STATES.ACTIVE
  }
  return TAB_STATES.PASSIVE
}
const initialState = getTabState()

export default {
  name: 'tabState',
  init: store => {
    STANDARD_EVENTS.forEach(type => {
      window.addEventListener(type, () => {
        const state = getTabState()
        if (state === store.selectTabState()) return
        store.dispatch({
          type: TAB_STATE,
          payload: state
        })
      }, HANDLER_OPTS)
    })

    window.addEventListener('freeze', () => {
      store.dispatch({
        type: TAB_STATE,
        payload: TAB_STATES.FROZEN
      })
    }, HANDLER_OPTS)

    window.addEventListener('pagehide', event => {
      store.dispatch({
        type: TAB_STATE,
        payload: event.persisted ? TAB_STATES.FROZEN : TAB_STATES.TERMINATED
      })
    })
  },
  reducer: (state = initialState, action = {}) => {
    if (action.type === TAB_STATE) {
      return action.payload
    }
    return state
  },
  selectTabState: state => state.tabState,
}
