import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { urls as harvestUrls } from '~/src/Harvest/bundle'
import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { IrrigationSchedule } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const irrigationScheduleTemplatesBundle = createAsyncResourceBundle({
  name: 'irrigationScheduleTemplates',
  actionBaseType: 'IRRIGATION_SCHEDULE_TEMPLATES',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: async ({ apiFetch, store }) => {
    const response = await apiFetch('/irrigationSchedules/', {
      rooms: 'null',
      phases: 'null',
      ordering: ['name', 'asc'],
      pagination: 0,
    })
    const templateList = response.results ?? response
    if (Array.isArray(templateList) && templateList.length) {
      const { entities } = normalize(templateList, [IrrigationSchedule])
      store.dispatch(doEntitiesReceived(entities))
    }
    return templateList
  },
})

export default {
  ...irrigationScheduleTemplatesBundle,
  reactIrrigationScheduleTemplatesFetch: createAppIsReadySelector({
    dependencies: [
      'selectIrrigationScheduleTemplatesShouldUpdate',
      'selectRouteInfo',
      'selectPermittedActions',
      'selectAuth',
    ],
    resultFn: (shouldUpdate, { pattern }, permittedActions, { authenticated }) => {
      if (!shouldUpdate || !authenticated) return null
      const hasIrrigationPermission = permittedActions.has('view_irrigation')
      const isAllowedRoute = pattern.match(/\/(rooms|recipes)\/:id/) || pattern === harvestUrls.schedule

      if (hasIrrigationPermission && isAllowedRoute) {
        return { actionCreator: 'doFetchIrrigationScheduleTemplates', priority: REACTOR_PRIORITIES.HIGH }
      }

      return undefined
    }
  }),
}
