import { useConnect } from 'redux-bundler-hook'

import { defer } from '~/src/Lib/Utils'

import Null from './Null'

const RedirectTo = ({ url }) => {
  const { doUpdateUrl } = useConnect('doUpdateUrl')
  defer(() => doUpdateUrl(url), defer.priorities.highest)
  return <Null />
}

export default RedirectTo
