import { SvgIcon } from '@mui/material'

const displayName = 'Icon/Pest'

export const path = `
  M16.299 4.636c-.386.22-.922.71-1.319 1.822-.05.14-.129.266-.229.374 1.157.748 2.095 1.952 2.66
  3.422.487-.267.988-.44 1.508-.452.967-.025 1.71.496 2.328 1.159.376.403.354 1.036-.05 1.413-.404
  .377-1.036.354-1.413-.05-.452-.485-.683-.526-.815-.523-.178.005-.49.1-1.046.491.05.393.077.796.077
  1.207 0 .059 0 .118-.002.176.67.154 1.268.402 1.723.834.701.667.858 1.56.827 2.466-.02.552-.482
  .983-1.034.964-.552-.02-.984-.482-.965-1.034.023-.663-.11-.855-.206-.946-.092-.088-.265-.19-.6
  -.285-.148.617-.36 1.2-.623 1.738.634.328 1.172.73 1.51 1.284.505.825.426 1.73.16 2.596-.16.528
  -.72.825-1.248.663-.528-.161-.825-.72-.664-1.248.194-.634.115-.855.046-.967-.092-.15-.325-.369
  -.924-.65-1.062 1.187-2.463 1.91-4 1.91-1.591 0-3.038-.775-4.112-2.039-.817.344-1.109.606-1.214
  .779-.069.112-.148.333.046.967.161.528-.136 1.087-.664 1.248-.528.162-1.087-.135-1.249-.663-.264
  -.866-.344-1.77.16-2.596.396-.646 1.062-1.087 1.838-1.443-.241-.52-.434-1.079-.57-1.668-.594.115
  -.858.258-.98.374-.095.09-.23.283-.206.946.02.552-.412 1.015-.964 1.034-.552.02-1.015-.412-1.035
  -.964-.031-.905.126-1.8.827-2.466.544-.517 1.292-.77 2.124-.914L6 13.499c0-.316.016-.628.046-.934
  -.02-.013-.04-.028-.06-.043-.756-.586-1.148-.716-1.357-.72-.132-.004-.363.037-.815.522-.377.404
  -1.01.427-1.413.05-.404-.377-.426-1.01-.05-1.414.618-.662 1.361-1.183 2.328-1.158.638.016 1.246
  .272 1.838.647.617-1.731 1.744-3.116 3.141-3.857-.023-.043-.042-.088-.059-.134-.397-1.111-.933
  -1.602-1.318-1.822-.411-.236-.784-.242-.945-.21-.541.11-1.13-.224-1.316-.744-.186-.52.102-1.03
  .644-1.14.644-.129 1.595-.051 2.54.49.96.55 1.804 1.514 2.34 2.988.15-.014.303-.02.456-.02.343 0
  .679.035 1.006.104l.014-.04c.535-1.499 1.386-2.477 2.356-3.033.944-.54 1.895-.618 2.54-.488.541
  .109.83.619.644 1.139-.186.52-.775.853-1.317.744-.16-.032-.533-.026-.944.21zM13 18.806c1.53-.581
  3-2.455 3-5.307 0-.823-.123-1.565-.334-2.218-2.816 1.22-5.659.661-7.327-.016C8.124 11.922 8 12.67
  8 13.5c0 2.852 1.47 4.726 3 5.307V15c0-.552.448-1 1-1s1 .448 1 1v3.807zM9.283 9.49c1.292.483 3.392
  .844 5.446.015C13.949 8.526 12.927 8 12 8c-.922 0-1.939.522-2.717 1.49z
`

const PestIcon = props => (
  <SvgIcon {...props}>
    <g data-icon-id="aroya-pest" clipRule="evenodd" fillRule="evenodd">
      <path id="aroya-pest-icon" d={path} />
    </g>
  </SvgIcon>
)
PestIcon.displayName = displayName

export default PestIcon
