import { SvgIcon } from '@mui/material'

import useConcurrentConnect from '~/src/Lib/useConcurrentConnect'

export const path = `
  M12 2c1.657 0 3 1.343 3 3v9c1.214.912 2 2.364 2 4 0 2.761-2.239 5-5 5s-5-2.239-5-5c0-1.636.786
  -3.088 2-4V5c0-1.657 1.343-3 3-3zm1 12.999V5c0-.552-.448-1-1-1s-1 .448-1 1v1c.552 0 1 .448 1 1s
  -.448 1-1 1v1c.552 0 1 .448 1 1s-.448 1-1 1v1c.552 0 1 .448 1 1s-.448 1-1 1v.999l-.002.001h2.004
  L13 14.999zM2 10c0-.552.448-1 1-1h3c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1zM17 10c0
  -.552.448-1 1-1h3c.552 0 1 .448 1 1s-.448 1-1 1h-3c-.552 0-1-.448-1-1z
`

const Icon = props => {
  const { dataTypes } = useConcurrentConnect('selectDataTypes')
  const { soil_temp: dataType = {} } = dataTypes
  const { color = 'coral' } = dataType
  const fill = props.color || props.htmlColor ? 'currentColor' : color

  return (
    <SvgIcon {...props}>
      <path d={path} fill={fill} fillRule="evenodd" clipRule="evenodd" />
    </SvgIcon>
  )
}

export default Icon
