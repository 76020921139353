import { pipe, trim } from 'ramda'

import { camelize, pluralize, underscore } from 'inflection'
import { schema } from 'normalizr'

import { EMPTY_ARRAY, EMPTY_OBJECT, parseDuration } from '~/src/Lib/Utils'
import { parseStart } from '~/src/Room/LightSchedule/utils'

export const annotationSchemaNormalizer = annotationSchema => {
  switch (annotationSchema) {
    case 'ManualReading':
      return 'readings'
    case 'HarvestPhase':
      return 'harvestPhases'
    default:
      return pluralize(camelize(annotationSchema, true))
  }
}

export const facilityScope = Object.freeze({
  selector: 'selectCurrentFacilityId',
  entityProp: 'facility',
})

export const facilityIdScope = Object.freeze({
  selector: 'selectCurrentFacilityId',
  entityProp: 'facilityId',
})

export const labelReadability = props => (
  typeof props.label === 'string' ? {
    ...props,
    label: props.label
      .slice(0, 25)
      .split(/(\w{5})/)
      .filter(Boolean)
      .join('\u205f')
      .toUpperCase()
  } : props
)

export const processLightSchedule = entity => ({
  ...entity,
  startTimeMinutes: parseStart(entity.startTime),
  durationHours: parseDuration(entity.duration),
})

const uuidEntityFactory = (
  entityType,
  def = EMPTY_OBJECT,
  opts = EMPTY_OBJECT
) => {
  const { idAttribute = 'id', processStrategy: optsProcess } = opts
  const addUUID = (entity, parent, key) => ({
    ...entity,
    uuid: entity.uuid ?? (
      key === 'data' && parent?.uuid
        ? parent.uuid
        : `${entityType}_${entity[idAttribute]}`
    ),
  })
  const processStrategy = optsProcess ? pipe(addUUID, optsProcess) : addUUID
  return new schema.Entity(entityType, def, {
    ...opts,
    processStrategy,
  })
}

export const Organization = new schema.Entity('organizations')
export const Role = new schema.Entity('roles')

// sensor related
export const DataType = uuidEntityFactory(
  'dataTypes',
  {},
  {
    idAttribute: 'key',
    processStrategy: ({ name, template, ...rest }) => ({
      ...rest,
      name,
      nameText: trim(name.replace(/<\/?\w+>/g, ' ')),
      template,
      units: template.replace(/\s*\{\{ value \}\}\s*/, ''),
    }),
  }
)
export const Device = new schema.Entity('devices')
export const DeviceNumberOfPlants = new schema.Entity('deviceNumberOfPlants', {}, { idAttribute: 'device' })
export const Gateway = new schema.Entity('gateways')
export const Logger = new schema.Entity('loggers')
export const Model = new schema.Entity('models', EMPTY_OBJECT, { idAttribute: 'key' })
export const Reading = uuidEntityFactory('readings')
export const Unit = new schema.Entity('units', EMPTY_OBJECT, {
  processStrategy: ({ template, precision = 0, ...rest }) => ({
    ...rest,
    precision,
    template,
    symbol: template ? template.replace(/\s*\{\{.*\}\}\s*/, '') : '',
  })
})

// facility related
export const Permission = new schema.Entity('permissions')
export const Room = uuidEntityFactory('rooms', undefined)
export const Zone = uuidEntityFactory('zones', undefined, {
  processStrategy: ({ roomId, room, ...rest }) => ({
    ...rest,
    roomId: roomId ?? room,
    room: room ?? roomId,
  })
})

export const RoomList = new schema.Array(Room)
export const Facility = new schema.Entity('facilities')
export const License = new schema.Entity('licenses')
export const Recipe = uuidEntityFactory('recipes', {}, {
  processStrategy: entity => ({
    ...entity,
    // TODO: remove this once the API is fixed
    payloadType: 'entity',
    phases: entity.phases?.reduce((acc, phase) => ([
      ...acc,
      {
        ...phase,
        lightSchedule: phase.lightSchedule ? processLightSchedule(phase.lightSchedule) : {},
        taskCount: Array.isArray(phase.tasks) ? phase.tasks.length : 0,
      }
    ]), [])
  })
})
export const BasicUser = new schema.Entity('users')
export const Kpi = new schema.Entity('kpis')
export const KpiProfile = new schema.Entity('kpiProfiles')
export const KpiRecipe = new schema.Entity('kpiRecipes')
export const FacilityCultivar = new schema.Entity('facilityCultivars')
export const FacilityPhase = new schema.Entity('facilityPhases')
export const FacilityRole = new schema.Entity('facilityRoles')
export const Floorplan = new schema.Entity('floorplans')
export const NotificationPreference = new schema.Entity('notificationPreferences')
export const Membership = new schema.Entity('memberships', {
  facility: Facility,
  role: FacilityRole,
  user: BasicUser,
  notificationPreferences: [NotificationPreference],
})

export const Event = new schema.Entity('events')

// misc
export const PlantScoreCriteria = new schema.Entity('plantScoreCriteria')
export const NoteCategory = new schema.Entity('noteCategories')
export const Comment = new schema.Entity('comments')
export const Note = uuidEntityFactory('notes', {
  facility: Facility,
  room: Room,
  zone: Zone,
  comments: [Comment],
})
export const Task = uuidEntityFactory('tasks', {
  facility: Facility,
  room: Room,
  zone: Zone,
  assigned: Membership,
  comments: [Comment],
})
export const Pesticide = new schema.Entity('pesticides')
export const PesticideApplication = uuidEntityFactory('pesticideApplications', {
  pesticide: Pesticide,
  task: Task,
})
Task.define({ pesticideApplication: PesticideApplication })

export const Notification = uuidEntityFactory('notifications', {
  facility: Facility,
  room: Room,
  zone: Zone,
  device: Device,
  dataType: DataType,
})
export const NotificationType = new schema.Entity(
  'notificationTypes',
  {},
  { idAttribute: 'key' }
)
export const User = new schema.Entity('users', {
  memberships: [Membership],
  organizations: [Organization],
})
export const UserSetting = new schema.Entity('userSettings', {}, { idAttribute: 'key' })
export const PackageAdjustmentReason = new schema.Entity('packageAdjustmentReasons')
export const WasteReason = new schema.Entity('wasteReasons')
export const WasteReasonPlantBatch = new schema.Entity('wasteReasonsPlantBatch')
export const WasteType = new schema.Entity('wasteTypes')
export const WasteMethod = new schema.Entity('wasteMethods')
export const MetrcLocation = new schema.Entity('metrcLocations')

Facility.define({
  cultivars: [FacilityCultivar],
  dataTypes: [DataType],
  devices: [Device],
  licenses: [License],
  members: [Membership],
  pesticides: [Pesticide],
  phases: [FacilityPhase],
  packageAdjustmentReasons: [PackageAdjustmentReason],
  roles: [FacilityRole],
  rooms: [Room],
  wasteReasons: [WasteReason],
  wasteTypes: [WasteType],
  wasteMethods: [WasteMethod],
  zones: [Zone],
})

export const Grade = new schema.Entity('grades')
export const PackageStatus = new schema.Entity('packageStatuses', {}, { idAttribute: 'key' })
export const Item = new schema.Entity('items')
export const ItemCategory = new schema.Entity('itemCategories', {}, { idAttribute: 'key' })
export const EventType = new schema.Entity('eventTypes', {}, { idAttribute: 'key' })
export const System = new schema.Object({
  models: [Model],
  units: [Unit],
  noteCategories: [NoteCategory],
  notificationTypes: [NotificationType],
  packageStatuses: [PackageStatus],
  itemCategories: [ItemCategory],
  permissions: [Permission],
  eventTypes: [EventType],
})
Organization.define({
  items: [Item]
})

// control/irrigation related
export const ControlDailyJournal = new schema.Entity('controlDailyJournals')
export const IrrigationControllerPort = new schema.Entity('irrigationControllerPorts', {}, {
  processStrategy: ({ masterPorts = EMPTY_ARRAY, ...rest }) => (
    { ...rest, masterPorts: masterPorts.map(port => port.id || port) }
  ),
})
export const IrrigationController = new schema.Entity('irrigationControllers', {
  ports: [IrrigationControllerPort],
})
export const IrrigationSchedule = new schema.Entity('irrigationSchedules', {}, {
  processStrategy: ({
    sensorBasedIrrigationCooldownSeconds,
    sensorBasedIrrigationSeconds,
    ...rest
  }) => ({
    ...rest,
    sensorBasedIrrigationCooldownSeconds,
    sensorBasedIrrigationSeconds,
    sensorBasedIrrigationCooldownMinutes: Math.round(sensorBasedIrrigationCooldownSeconds / 60),
    sensorBasedIrrigationMinutes: Math.round(sensorBasedIrrigationSeconds / 60),
  })
})
export const IrrigationManualEvent = new schema.Entity('irrigationManualEvents')
export const IrrigationProgramHistory = new schema.Entity('irrigationProgramHistories')
export const IrrigationScheduleChangeNotification = new schema.Entity('irrigationScheduleChangeNotifications')

// harvest and inventory related
export const HarvestBatch = uuidEntityFactory('harvestBatches')
export const HarvestCultivars = uuidEntityFactory('harvestCultivars')
export const MetrcFacility = new schema.Entity('metrcFacilities')
export const Phase = uuidEntityFactory('phases', {
  irrigationSchedule: IrrigationSchedule,
}, {
  processStrategy: (entity, harvest) => {
    // TODO: when taskCount comes in the endpoint drop lines 248 - 250
    if (entity.taskCount == null) {
      Object.assign(entity, { taskCount: Array.isArray(entity.tasks) ? entity.tasks.length : 0 })
    }
    if (!entity.harvest && !entity.harvestId) {
      Object.assign(entity, { harvest: harvest?.id, harvestId: harvest?.id })
    }
    return entity
  }
})
export const Plant = uuidEntityFactory('plants')
export const PlantBatch = uuidEntityFactory('plantBatches')
export const Package = uuidEntityFactory('packages')
export const Harvest = uuidEntityFactory(
  'harvests',
  {
    phases: [Phase],
  },
  {
    processStrategy: entity => ({
      ...entity,
      cultivars: entity.cultivars ?? EMPTY_ARRAY,
      cultivarIds: Array.isArray(entity.cultivars)
        ? entity.cultivars.map(c => c.cultivar)
        : (entity.cultivarIds ?? EMPTY_ARRAY),
      currentPhase: entity.phases?.find(p => (
        p.payloadType === 'entity' && p.id === entity.currentPhase?.id
      )) ?? entity.currentPhase,
      phases: entity.phases?.sort((a, b) => a.sequence - b.sequence) ?? EMPTY_ARRAY,
    }),
  }
)

export const ControlSensorBasedEvent = uuidEntityFactory('controlSensorBasedEvents', {}, {
  processStrategy: ({ measuredReadings, setpointReadings, ...rest }) => ({
    ...rest,
    measuredReadings: Object.fromEntries(Object.entries(measuredReadings).map(([key, value]) => [
      underscore(key),
      value
    ])),
    setpointReadings: Object.fromEntries(Object.entries(setpointReadings).map(([key, value]) => [
      underscore(key),
      value
    ])),
  }),
}) // EMERGENCY_EVENT

export const TargetRange = uuidEntityFactory('targetRanges', {
  dataType: DataType,
  phase: Phase,
  room: Room,
})

export const LightSchedule = uuidEntityFactory('lightSchedules', {
  room: Room,
}, {
  processStrategy: entity => (processLightSchedule(entity))
})

export const MetrcHarvest = new schema.Entity('metrcHarvests', {
  cultivar: FacilityCultivar,
})

Phase.define({
  harvest: Harvest,
  targetRanges: [TargetRange],
  lightSchedule: LightSchedule,
})

Harvest.define({ currentPhase: Phase })

export const AnnotationData = new schema.Union(
  {
    harvests: Harvest,
    harvestPhases: Phase,
    lightSchedules: LightSchedule,
    notes: Note,
    notifications: Notification,
    readings: Reading,
    targetRanges: TargetRange,
    tasks: Task,
    pesticideApplications: PesticideApplication,
  },
  (_, { schema: annotationSchema }) => annotationSchemaNormalizer(annotationSchema)
)

export const Annotation = new schema.Object({ data: AnnotationData })

export const InventoryData = new schema.Union({
  harvestBatches: HarvestBatch,
  packages: Package,
  plantBatches: PlantBatch,
  plants: Plant,
}, (_, { uuid }) => uuid.split('_').shift())

export const InventoryItem = new schema.Object({ data: InventoryData })

const FacilityStatusArea = new schema.Values({ notifications: [Notification] })
export const FacilityStatus = new schema.Object({
  rooms: FacilityStatusArea,
  zones: FacilityStatusArea,
})

export const LabType = new schema.Entity('labTypes')
export const Flows = new schema.Entity('flows', {}, {
  processStrategy: ({ data, ...rest }) => {
    try {
      const flowData = typeof data === 'string' ? JSON.parse(data) : data
      return { ...rest, data: flowData }
    } catch (error) {
      console.error('Error parsing Flow data', error)
      return { ...rest, data }
    }
  }
})
export const CultivarStatistic = new schema.Entity('cultivarStatistics')

export const SubstrateSettings = new schema.Entity('substrateSettings')

Room.define({
  currentHarvests: [Harvest],
  currentLightSchedule: LightSchedule,
  zones: [Zone],
  devices: [Device],
  lastHarvest: Harvest,
  nextHarvest: Harvest,
})
