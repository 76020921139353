import {
  always,
  either,
  equals,
  pick,
  prop,
} from 'ramda'

import { EPOCH_DATETIME } from '~/src/Lib/Constants'

export const flowDataPicker = pick(['queue', 'processedRooms', 'dequeued'])

export const setModifiedAt = handler => (prevState, action) => {
  const nextState = handler(prevState, action.payload, action.meta, action.error)
  const prevFlowData = flowDataPicker(prevState)
  const nextFlowData = flowDataPicker(nextState)
  const stateUnchanged = equals(prevFlowData, nextFlowData)
  if (!stateUnchanged && prevState.stateModified >= nextState.stateModified) {
    return {
      ...nextState,
      stateModified: new Date().toISOString()
    }
  }
  return nextState
}

export const getSavedAt = (flow, state) => {
  const { modifiedOn, queue = [] } = flow
  const { savedAt } = state
  if (!modifiedOn && !queue.length) return savedAt
  return [
    modifiedOn,
    ...queue.map(either(prop('modifiedAt'), always(EPOCH_DATETIME)))
  ].sort((a, b) => b.localeCompare(a))[0] ?? EPOCH_DATETIME
}
