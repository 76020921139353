import { createSelector } from 'redux-bundler'

import createEntityBundle from '~/src/Lib/createEntityBundle'
import { getDateTime } from '~/src/Lib/Utils'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, PesticideApplication as schema } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const name = 'pesticideApplications'

const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }

const pesticideApplicationsBundle = createEntityBundle({
  name,
  apiConfig: {
    schema,
    delete: writesConfig,
    save: writesConfig,
  },
  scope,
})

export default {
  ...pesticideApplicationsBundle,
  selectRestrictedEntry: createSelector(
    'selectPesticideApplications',
    'selectPesticides',
    'selectRooms',
    (pesticideApplications, pesticides, rooms) => {
      if (!pesticideApplications || !pesticides || !rooms) {
        return {}
      }
      return Object.values(pesticideApplications).reduce((restrictions, application) => {
        const roomId = application.room
        const pesticide = pesticides[application.pesticide]
        if (!pesticide) {
          return restrictions
        }
        let restrictedUntil = getDateTime(application.ts).plus({ hours: pesticide.restrictedEntryTime }).toMillis()
        if (restrictions[roomId] > restrictedUntil) {
          restrictedUntil = restrictions[roomId]
        }
        return { ...restrictions, [roomId]: restrictedUntil }
      }, {})
    },
  ),
  reactFetchPesticideApplications: createAppIsReadySelector({
    dependencies: [
      'selectPesticideApplicationListShouldUpdate',
      'selectRouteInfo'
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (!shouldUpdate) return null
      if (pattern.startsWith('/dashboard') || pattern.startsWith('/ipm')) {
        return { actionCreator: 'doFetchPesticideApplicationList' }
      }
      return undefined
    },
  }),
}
