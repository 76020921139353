import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createListBundle from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, getDateTime } from '~/src/Lib/Utils'
import { Harvest } from '~/src/Store/Schemas'

const DEFAULT_PARAMS = {
  ordering: 'name',
  pagination: 0,
}
const IGNORE_HARVEST_PHASE_CHANGE_REVIEW = 'IGNORE_HARVEST_PHASE_CHANGE_REVIEW'
const REFRESH_IGNORED_HARVESTS = 'REFRESH_IGNORED_HARVESTS'

const growingHarvestsBundle = createListBundle({
  entityName: 'harvests',
  name: 'growingHarvests',
  schema: Harvest,
  retryAfter: ms.seconds(5),
  fetchHandler: async ({ apiFetch, params }) => apiFetch('/harvests/', params),
  flags: { keys: ['PHASE_NOTIFICATION'] },
})

const reducer = reduceReducers(growingHarvestsBundle.reducer, (state, action) => {
  switch (action.type) {
    case IGNORE_HARVEST_PHASE_CHANGE_REVIEW: {
      if (state.ignoredHarvests) {
        return {
          ...state,
          ignoredHarvests: [...state.ignoredHarvests, action.payload],
        }
      }

      return {
        ...state,
        ignoredHarvests: [action.payload],
      }
    }
    case REFRESH_IGNORED_HARVESTS: {
      if (state.ignoredHarvests) {
        const stillIgnoredHarvests = state.ignoredHarvests.filter(harvest => {
          const now = getDateTime('now')
          const phaseEndDate = getDateTime(harvest.phaseEndDate)
          const harvestEndDate = getDateTime(harvest.harvestEndDate)
          const phaseHasEnded = now >= phaseEndDate
          const harvestHasEnded = now >= harvestEndDate

          return !phaseHasEnded && !harvestHasEnded
        })

        return {
          ...state,
          ignoredHarvests: stillIgnoredHarvests,
        }
      }

      return state
    }
    default: {
      if (state.ignoredHarvests && state.data) {
        return state
      }
      const newState = { ...state }
      if (!state.ignoredHarvests) {
        newState.ignoredHarvests = EMPTY_ARRAY
      }
      if (!state.data) {
        newState.data = EMPTY_ARRAY
      }
      return newState
    }
  }
})

export default {
  ...growingHarvestsBundle,
  reducer,
  doHarvestIgnore: harvest => ({
    type: IGNORE_HARVEST_PHASE_CHANGE_REVIEW,
    payload: {
      id: harvest.id,
      phaseEndDate: harvest.currentPhase.endDate,
      harvestEndDate: harvest.endDate
    }
  }),
  doRefreshIgnoredHarvests: () => ({ type: REFRESH_IGNORED_HARVESTS }),
  selectGrowingHarvestsApiParams: createSelector(
    'selectAvailableFeatures',
    availableFeatures => ({
      ...DEFAULT_PARAMS,
      currentFilter: availableFeatures.has('METRC') ? 'GROWING' : 'CURRENT'
    })
  ),
  selectIgnoredHarvests: state => state.growingHarvests.ignoredHarvests || [],
  persistActions: [
    IGNORE_HARVEST_PHASE_CHANGE_REVIEW,
    REFRESH_IGNORED_HARVESTS,
  ],
}
