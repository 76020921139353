import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import reduceReducers from 'reduce-reducers'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_ARRAY } from '~/src/Lib/Utils'
import { Kpi as schema } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const logger = createLogger('cultivarKpis/bundle')

const SET_CULTIVAR_KPIS_PARAMS = 'SET_CULTIVAR_KPIS_PARAMS'

const QUICK_STALE_AFTER = ms.minutes(5)
const NONE = Object.freeze({})

const initialState = {
  cultivar: null
}

const initialBundle = createAsyncResourceBundle({
  name: 'cultivarKpis',
  actionBaseType: 'CULTIVAR_KPIS',
  staleAfter: ms.minutes(15),
  retryAfter: ms.seconds(5),
  schema,
  getPromise: ({ apiFetch, store }) => {
    const { cultivar } = store.selectCultivarKpisRaw()
    if (!cultivar) {
      return Promise.reject(new Error('No cultivar selected'))
    }
    return apiFetch(`/cultivars/${cultivar}/kpis/`).then(res => res ?? NONE)
  },
})

export default {
  ...initialBundle,
  reducer: reduceReducers(initialBundle.reducer, (state, action) => {
    switch (action.type) {
      case SET_CULTIVAR_KPIS_PARAMS: {
        const { cultivar } = action.payload
        const newState = { ...state, cultivar, data: cultivar && cultivar == state.data?.organizationCultivar ? state.data : null }
        return newState
      }
      default:
        if (!Object.keys(initialState).every(key => key in state)) {
          return { ...initialState, ...state }
        }
        return state
    }
  }),
  doCultivarKpisSetParams: payload => ({ dispatch, store }) => {
    if (!payload) return
    const cultivarKpisRaw = store.selectCultivarKpisRaw()
    const { params } = store.selectRouteInfo()
    // Don't thrash when the same value is getting set
    if (Object.entries(payload).every(([key, value]) => cultivarKpisRaw[key] === value)) return
    dispatch({ type: SET_CULTIVAR_KPIS_PARAMS, payload })
    if (payload.cultivar && params?.id && payload.cultivar != params.id) {
      dispatch({ actionCreator: 'doMarkCultivarKpisAsOutdated' })
    }
  },
  selectCultivarKpisWithData: createSelector(
    'selectCultivarKpis',
    'selectCultivarKpiProfile',
    'selectKpiRecipes',
    (cultivarKpis, cultivarKpiProfile, kpiRecipes) => {
      if (cultivarKpis) {
        return cultivarKpis.reduce((kpis, { id, kpiProfile, kpiRecipe: kpiRecipeId, value }) => {
          if (kpiProfile === cultivarKpiProfile?.id) {
            return [
              {
                id,
                kpiProfile: cultivarKpiProfile,
                kpiRecipe: kpiRecipes[kpiRecipeId],
                value
              },
              ...kpis
            ]
          }
          return kpis
        }, [])
      }
      return EMPTY_ARRAY
    }
  ),
  selectCultivarKpisShouldNormallyUpdate: initialBundle.selectCultivarKpisShouldUpdate,
  selectCultivarKpisShouldUpdate: createSelector(
    'selectCultivarKpisRaw',
    'selectCultivarKpisShouldNormallyUpdate',
    'selectRouteInfo',
    ({ isLoading, lastSuccess, cultivar }, shouldNormallyUpdate, { pattern }) => {
      if (isLoading || !cultivar || !pattern.match(/^\/cultivars\/:id/)) {
        logger.debug('should not update, already fetching or no cultivar or not in cultivar profile')
        return false
      }
      if (shouldNormallyUpdate) {
        logger.debug('should update, normally')
        return true
      }
      const isQuickStale = (!lastSuccess || Date.now() - lastSuccess >= QUICK_STALE_AFTER)
      if (isQuickStale) {
        logger.debug('should update, quick stale?', { isQuickStale })
      }
      return isQuickStale
    }
  ),
  reactSetCultivarKpisParams: createSelector(
    'selectCultivarKpisRaw',
    'selectRouteInfo',
    ({ cultivar }, routeInfo) => {
      const { pattern, params } = routeInfo
      const isCultivarDetail = pattern.match(/^\/cultivars\/:id/)
      if (!isCultivarDetail && cultivar) {
        logger.debug('[reactSetCultivarKpisParams] not in cultivar profile')
        return { actionCreator: 'doCultivarKpisSetParams', args: [initialState] }
      }
      const { id } = params

      if (isCultivarDetail && cultivar != id) {
        const nextParams = { cultivar: id }
        logger.debug('[reactSetCultivarKpisParams] params changed, updating', nextParams)
        return { actionCreator: 'doCultivarKpisSetParams', args: [nextParams] }
      }
      return null
    }
  ),
  reactCultivarKpisFetch: createAppIsReadySelector({
    dependencies: [
      'selectCultivarKpisShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern.match(/^\/cultivars\/:id/)) {
        return { actionCreator: 'doFetchCultivarKpis' }
      }
      return undefined
    }
  }),
}
