import { prop, sort } from 'ramda'
import { createSelector } from 'redux-bundler'

import { addBreadcrumb } from '@sentry/react'
import { normalize } from 'normalizr'
import queryString from 'query-string'

import cache from '~/src/IO/Cache'
import { AUTH_URL, clearTokens, setTokens } from '~/src/Lib/Auth'
import { doEntitiesReceived, getAsyncActionIdentifiers } from '~/src/Lib/createEntityBundle'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import safeStorage from '~/src/Lib/safeStorage'
import { defer, EMPTY_OBJECT, parseApiErrors } from '~/src/Lib/Utils'
import { getNumberSort } from '~/src/Lib/Utils/arrays'
import { DEFAULT_NEXT, REGISTER_ROUTES } from '~/src/Routes/constants'

import { REACTOR_PRIORITIES } from '../constants'
import { Facility } from '../Schemas'
import { clearLSM, getLSM } from './me/utils'

const logger = createLogger('Store/auth')

const { location } = getGlobal()

const LOGIN_PATTERN = /^\/(login|switching)/

export const loginActions = getAsyncActionIdentifiers('login')
export const logoutActions = getAsyncActionIdentifiers('logout')
const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE'

export const forgotActions = getAsyncActionIdentifiers('forgot')
const CLEAR_FORGOT_STATE = 'CLEAR_FORGOT_STATE'

export const resetActions = getAsyncActionIdentifiers('reset')
const CLEAR_RESET_STATE = 'CLEAR_RESET_STATE'

export const validateActions = getAsyncActionIdentifiers('validate')

const SET_URL_HISTORY = 'SET_URL_HISTORY'

export const defaultState = {
  authenticated: false,
  loginProcessing: false,
  loginFailed: false,
  logoutProcessing: false,
  loginMessage: '',
  urlHistory: [],
  forgot: {
    loading: false,
    success: null,
    error: null,
  },
  reset: {
    loading: false,
    success: null,
    error: null,
  },
  validate: {
    loading: true,
    error: null,
    response: {
      valid: null,
      email: null
    }
  }
}
const {
  types: { start: loginStart, succeed: loginSucceed, fail: loginFail },
} = loginActions
const {
  types: { start: logoutStart, succeed: logoutSucceed, fail: logoutFail },
} = logoutActions
const {
  types: { start: forgotStart, succeed: forgotSucceed, fail: forgotFail },
} = forgotActions
const {
  types: { start: resetStart, succeed: resetSucceed, fail: resetFail },
} = resetActions
const {
  types: { start: validateStart, succeed: validateSucceed, fail: validateFail },
} = validateActions

export default {
  name: 'auth',
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    if (!action || !action.type) return state
    switch (action.type) {
      case loginStart:
        return { ...state, loginProcessing: true, loginFailed: false }
      case loginFail:
        return {
          ...state,
          loginProcessing: false,
          loginFailed: true,
          loginMessage: action.error ?? action.payload?.error ?? 'Unable to login',
        }
      case loginSucceed:
        return {
          ...state,
          ...defaultState,
          authenticated: action?.payload?.authenticated ?? false,
          authenticatedAt: Date.now(),
        }
      case logoutStart:
        return { ...state, logoutProcessing: true }
      case logoutFail:
        return { ...state, logoutProcessing: false }
      case logoutSucceed:
      case CLEAR_AUTH_STATE:
        return { ...defaultState }
      case forgotStart:
        return { ...state, forgot: { ...defaultState.forgot, loading: true } }
      case forgotSucceed:
        return { ...state, forgot: { ...defaultState.forgot, success: true } }
      case forgotFail:
        return {
          ...state,
          forgot: { ...defaultState.forgot, error: action.error },
        }
      case CLEAR_FORGOT_STATE:
        return { ...state, forgot: defaultState.forgot }
      case resetStart:
        return { ...state, reset: { ...defaultState.reset, loading: true } }
      case resetSucceed:
        return { ...state, reset: { ...defaultState.reset, success: true } }
      case resetFail:
        return {
          ...state,
          reset: { ...defaultState.reset, error: action.payload },
        }
      case CLEAR_RESET_STATE:
        return { ...state, reset: defaultState.reset }
      case SET_URL_HISTORY:
        return { ...state, urlHistory: [...state.urlHistory, action.payload].slice(-2) }
      case validateStart:
        return { ...state, validate: { ...defaultState.validate } }
      case validateFail:
        return { ...state, validate: { ...defaultState.validate, loading: false, error: action.payload } }
      case validateSucceed:
        return { ...state, validate: { ...defaultState.validate, loading: false, response: action.payload } }
      default:
        return state
    }
  },
  selectAuth: state => state.auth,
  selectLastUrl: state => state.auth.urlHistory.slice(-2, -1).pop() || '',
  doLogin: credentials => async ({ apiFetch, dispatch, store }) => {
    const { types } = loginActions
    const { alreadyHasAccount } = store.selectAccountCreation()

    dispatch({ type: types.start })
    addBreadcrumb({
      category: 'auth',
      message: `Attempting to authenticate "${credentials.email}"`,
      level: 'info',
    })
    try {
      const auth = await apiFetch(AUTH_URL, credentials, {
        method: 'POST',
        allowedCodes: [400],
        credentials: 'include',
        authenticated: false,
      })
      if (auth && 'nonFieldErrors' in auth) {
        dispatch({ type: types.fail, error: auth.nonFieldErrors[0] })
        addBreadcrumb({
          category: 'auth',
          message: `Authentication failed for "${credentials.email}"`,
          data: auth.nonFieldErrors,
          level: 'warning',
        })
      } else {
        setTokens(auth)
        cache.setUserToken(auth.user)
        dispatch({ type: types.succeed, payload: { authenticated: !!auth.user } })
        if (alreadyHasAccount) {
          dispatch({ actionCreator: 'doUpdateUrl', args: [REGISTER_ROUTES.FACILITY_INFO] })
        }

        if (Array.isArray(auth?.facilities)) {
          const { entities } = normalize(auth.facilities, [Facility])
          const { facilities } = entities
          const { facility } = getLSM()
          if (!(facility in facilities)) {
            logger.debug('facility', facility, 'not found in', facilities)
            clearLSM()
            const [defaultFacility] = sort(getNumberSort(prop('id')), auth.facilities)
            if (defaultFacility?.id) {
              dispatch({ actionCreator: 'doNextFacilitySet', args: [defaultFacility.id] })
            } else {
              logger.debug('no default facility:', auth.facilities)
            }
          }
          dispatch(doEntitiesReceived(entities, { replace: true }))
        }
        addBreadcrumb({
          category: 'auth',
          message: `Authenticated "${credentials.email}"`,
          level: 'info',
        })
        dispatch({ actionCreator: 'doFetchMe' })
        dispatch({ actionCreator: 'doFetchSystem' })
      }
    } catch (error) {
      if (safeStorage('local').debug === 'true') console.error(error)
      dispatch({ type: types.fail, error: parseApiErrors(error) || 'Unable to login.' })
      addBreadcrumb({
        category: 'auth',
        message: `Authentication failed for "${credentials.email}"`,
        data: error?.response,
        level: 'warning',
      })
    }
  },
  doLogout: (reset = false) => async ({ dispatch, store }) => {
    const { types } = logoutActions
    dispatch({ type: types.start })
    let success
    try {
      store.doClearAccountToken()
      clearTokens()
      dispatch({ type: types.succeed })
      cache.logger.info('clearing cache')
      await cache.clear()
      success = true
    } catch (error) {
      success = false
      logger.error(error)
      dispatch({ type: types.fail, error })
    } finally {
      defer(() => {
        location.replace(`/login${reset === true ? '?reset=1' : ''}`)
      }, defer.priorities.low)
    }
    return success
  },
  doAuthReset: () => ({ type: CLEAR_AUTH_STATE }),
  doForgotPassword: payload => async ({ apiFetch, dispatch }) => {
    const { types } = forgotActions
    dispatch({ type: types.start })
    try {
      const response = await apiFetch('/forgot/', payload, { method: 'POST', authenticated: false })
      if (response.success) {
        dispatch({ type: types.succeed })
      } else {
        dispatch({ type: types.fail, error: 'No account with that email address was found.' })
      }
    } catch (error) {
      dispatch({ type: types.fail, error: 'Unable to reset your password' })
    }
  },
  doForgotClearState: () => async ({ dispatch }) => {
    dispatch({ type: CLEAR_FORGOT_STATE })
  },
  doResetPassword: payload => async ({ apiFetch, dispatch, store }) => {
    const { types } = resetActions
    dispatch({ type: types.start })
    try {
      const response = await apiFetch('/reset/', payload, {
        method: 'POST',
        allowedCodes: [400],
        authenticated: false,
      })
      if ('success' in response) {
        dispatch({ type: types.succeed })
        store.doLogout(true)
      } else {
        dispatch({ type: types.fail, error: response[0] })
      }
    } catch (error) {
      dispatch({ type: types.fail, error: 'Unable to reset your password' })
    }
  },
  doResetTokenValidate: payload => async ({ apiFetch, dispatch }) => {
    const { types } = validateActions
    dispatch({ type: types.start })
    try {
      const response = await apiFetch('/token/validate/', payload, { authenticated: false })
      dispatch({ type: types.succeed, payload: response })
    } catch (error) {
      dispatch({ type: types.fail, error: 'Unable to validate reset password token.' })
    }
  },
  doResetClearState: () => async ({ dispatch }) => {
    dispatch({ type: CLEAR_RESET_STATE })
  },
  doSetUrlHistory: payload => async ({ dispatch }) => {
    dispatch({ type: SET_URL_HISTORY, payload })
  },
  doGoBack: defaultUrl => ({ store }) => {
    const lastUrl = store.selectLastUrl()
    store.doUpdateUrl(lastUrl || defaultUrl)
  },
  selectTokenValidationResponse: createSelector('selectAuth', auth => auth.validate),
  selectIsLoggingOut: createSelector('selectAuth', auth => !!auth.logoutProcessing),
  reactChangedUrl: createSelector(
    'selectRouteInfo',
    'selectAuth',
    ({ url }, { urlHistory }) => {
      if (urlHistory.slice(-1).pop() !== url) {
        return {
          actionCreator: 'doSetUrlHistory',
          args: [url],
          priority: REACTOR_PRIORITIES.HIGH
        }
      }
      return undefined
    }
  ),
  reactLoginSuccess: createSelector(
    'selectRouteInfo',
    'selectAuth',
    'selectMeRaw',
    'selectSystemRaw',
    ({ url }, { authenticated }, me, system) => {
      if (!me.lastSuccess || !system.lastSuccess) return undefined
      if (url.match(LOGIN_PATTERN) && authenticated) {
        let { next = DEFAULT_NEXT } = queryString.parse(location.search)
        if (next === '/login') next = DEFAULT_NEXT
        return {
          actionCreator: 'doUpdateUrl',
          args: [next],
          priority: REACTOR_PRIORITIES.HIGH,
        }
      }
      return undefined
    }
  ),
  reactForgotSuccess: createSelector(
    'selectRouteInfo',
    'selectAuth',
    ({ pattern }, { forgot }) => {
      if (pattern === '/forgot' && forgot.success) {
        return {
          actionCreator: 'doUpdateUrl',
          args: ['/login'],
          priority: REACTOR_PRIORITIES.HIGH,
        }
      }
      return undefined
    }
  ),
}
