import createEntityBundle from '~/src/Lib/createEntityBundle'
import { HarvestCultivars as schema } from '~/src/Store/Schemas'

const bundle = createEntityBundle({
  name: 'harvestCultivars',
  apiConfig: {
    schema,
  },
  readOnly: true,
})

export default bundle
