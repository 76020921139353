import { createSelector } from 'redux-bundler'

import { normalize } from 'normalizr'

import createEntityBundle, {
  doEntitiesReceived,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import { parseApiErrors } from '~/src/Lib/Utils'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { facilityScope as scope, Harvest, Plant as schema } from '~/src/Store/Schemas'

const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }
const processPlantsSnackbar = ({ dispatch, status, data, error }) => {
  switch (status) {
    case 'succeed':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [data.apiSuccessMessage]
      })
    case 'fail':
      return dispatch({
        actionCreator: 'doAddSnackbarMessage',
        args: [`Unable to save harvest: ${parseApiErrors(error)}`]
      })
    default:
      return undefined
  }
}

const { types: processActionTypes } = getAsyncActionIdentifiers('process', 'plants')

const name = 'plants'
export default createEntityBundle({
  name,
  apiConfig: {
    schema,
    delete: writesConfig,
    save: writesConfig,
  },
  customActions: [
    {
      action: 'change_tag',
      name,
      schema: [schema],
      handler: ({ payload, apiFetch }) => {
        const body = payload.map(([plant, newTag]) => ({ [plant.id]: newTag }))
        return apiFetch('/plants/change_tag/', body, { method: 'PUT' })
      },
      async: true,
      snackbar: ({
        status,
        dispatch,
        payload,
        response
      }) => {
        const successMessage = payload.length > 1 ? 'Invalid plant tags have been replaced and updated' : `Invalid plant tag ${payload[0][0].label} has been replaced with ${response[0].label}`
        dispatch({
          actionCreator: 'doAddSnackbarMessage',
          args: [
            status === 'succeed'
              ? successMessage
              : 'Unable to update plant tag'
          ]
        })
      },
    }
  ],
  scope,
  doProcessPlants: data => async ({ dispatch, apiFetch }) => {
    dispatch({ type: processActionTypes.start, payload: data })
    const { isMetrcCompliant, harvestId, payload } = data
    let result = {}
    try {
      result = await apiFetch(`/harvests/${harvestId}/${isMetrcCompliant ? 'harvest_plants' : 'harvest_cultivars'}/`, payload, {
        method: 'PUT',
      })
      dispatch({ type: processActionTypes.succeed, payload: data, result })
      processPlantsSnackbar({ dispatch, status: 'succeed', data, result })
      const { entities } = normalize(result, [Harvest])
      dispatch(doEntitiesReceived(entities))
    } catch (error) {
      result.error = error
      dispatch({ type: processActionTypes.fail, error })
      processPlantsSnackbar({ dispatch, status: 'fail', data, error })
    }
    return result
  },
  selectTrackVegPlants: createSelector(
    'selectCurrentFacility',
    currentFacility => currentFacility?.behaviors?.trackVegPlants ?? false,
  ),
})
