import { createSelector } from 'redux-bundler'

import ms from 'milliseconds'

import createListBundle, {
  defaultInitialState,
  defaultListActions,
} from '~/src/Lib/createListBundle'
import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import { Harvest } from '~/src/Store/Schemas'

import { PHASE_TYPES } from '../constants'

export const HARVEST_STATE_FILTERS = {
  METRC: [{
    label: 'Planned Groups',
    value: 'PLANNED',
  }, {
    label: 'Currently Growing',
    value: 'GROWING',
  },
  {
    label: 'Drying',
    value: 'DRYING',
  },
  {
    label: 'Finished',
    value: 'FINISHED',
  }],
  NOT_METRC: [{
    label: 'Currently Growing',
    value: 'CURRENT',
  },
  {
    label: 'Already Harvested',
    value: 'HARVESTED',
  }]
}

const DEFAULT_FILTERS = {
  METRC: HARVEST_STATE_FILTERS.METRC[1],
  NOT_METRC: HARVEST_STATE_FILTERS.NOT_METRC[0],
}

const initialState = {
  ...defaultInitialState,
  filter: 'CURRENT',
  sort: ['name', 'asc'],
  page: 1,
  cultivar: undefined,
  phase: null,
  room: undefined,
  recipe: undefined,
}

const queryKeyMap = {
  filter: 'currentFilter',
  sort: 'ordering',
  recipe: 'sourceRecipeId',
}

const queryValueMap = {
  end: end => end && getDateTime(end).toUTC().toISO(),
  start: start => start && getDateTime(start).toUTC().toISO(),
}

const queryBuilder = params => Object.keys(params).reduce((acc, key) => (params[key] ? {
  ...acc,
  [queryKeyMap[key] ?? key]: (
    key in queryValueMap
      ? queryValueMap[key](params[key])
      : params[key]
  )
} : acc), params.filter === initialState.filter ? { pagination: 0 } : EMPTY_OBJECT)

const initialBundle = createListBundle({
  entityName: 'harvest',
  actions: [...defaultListActions, 'set_cultivar', 'set_phase', 'set_room', 'set_recipe'],
  initialState,
  permissions: { keys: ['view_harvest'] },
  schema: Harvest,
  retryAfter: ms.seconds(5),
  staleAfter: ms.minutes(15),
  fetchHandler: ({ apiFetch, params }) => apiFetch('/harvests/', params),
  transformParams: queryBuilder
})

export default {
  ...initialBundle,
  reactUpdateFilter: createSelector(
    'selectHarvestListParams',
    'selectAvailableFeatures',
    ({ filter }, availableFeatures) => {
      const featureKey = availableFeatures.has('METRC') ? 'METRC' : 'NOT_METRC'
      const validFilters = [
        {
          label: 'All Harvest Groups',
          value: 'ALL',
        },
        ...HARVEST_STATE_FILTERS[featureKey],
      ]
      const haveValidFilter = PHASE_TYPES.includes(filter) || validFilters.find(({ value }) => value === filter)
      if (!haveValidFilter) {
        return { actionCreator: 'doHarvestListSetParams', args: [{ filter: DEFAULT_FILTERS[featureKey].value }] }
      }
      return undefined
    }
  )
}
