import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { doEntitiesReceived } from '~/src/Lib/createEntityBundle'
import { Room } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const ROOM_LIST_SET_SORT = 'ROOM_LIST_SET_SORT'

const additionalState = {
  currentSort: 'sequence',
}

const roomListBundle = createAsyncResourceBundle({
  name: 'roomList',
  actionBaseType: 'ROOM_LIST',
  staleAfter: ms.minutes(60),
  retryAfter: ms.seconds(5),
  getPromise: ({ dispatch, apiFetch, getState }) => {
    const { roomList } = getState()
    const { currentSort } = roomList

    return apiFetch('/rooms/', {
      ordering: currentSort,
    }).then(payload => {
      const { entities, result } = normalize(payload, [Room])
      dispatch(doEntitiesReceived(entities, { replace: true }))
      return { ...payload, results: result }
    })
  },
})

export default {
  ...roomListBundle,
  reducer: reduceReducers(roomListBundle.reducer, (state, action) => {
    switch (action.type) {
      case ROOM_LIST_SET_SORT:
        return { ...state, currentSort: action.payload }
      default:
        if (!Object.keys(additionalState).every(key => key in state)) {
          return { ...additionalState, ...state }
        }
        return state
    }
  }),
  doRoomListSetSort: sort => ({ type: ROOM_LIST_SET_SORT, payload: sort }),
  reactRoomListFetch: createAppIsReadySelector({
    dependencies: [
      'selectRoomListShouldUpdate',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { pattern }) => {
      if (shouldUpdate && pattern === '/setup/facility') {
        return { actionCreator: 'doFetchRoomList' }
      }
      return undefined
    }
  }),
  selectCurrentRoomList: createSelector(
    'selectRooms',
    'selectZones',
    'selectRoomListRaw',
    (rooms, zones, roomListRaw) => {
      if (!roomListRaw.data) {
        return []
      }

      return roomListRaw.data.results
        .map(roomId => {
          if (roomId in rooms) {
            const room = rooms[roomId]
            return { ...room, zones: room.zones.map(zoneId => zones[zoneId]) }
          }
          return null
        })
        .filter(val => val !== null)
    }
  ),
}
