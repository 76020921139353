import createListBundle from '~/src/Lib/createListBundle'
import { Flows as schema } from '~/src/Store/Schemas'

const initialState = {
  flowType: null,
  harvest: null,
}

export default createListBundle({
  entityName: 'flows',
  fetchHandler: ({ apiFetch, params }) => apiFetch('/postponedFlow/', params, { method: 'GET' }),
  initialState,
  schema,
})
