import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { Pesticide as schema } from '~/src/Store/Schemas'

export const initialState = {
  ...defaultInitialState,
  data: { result: EMPTY_ARRAY },
  filter: undefined,
  sort: ['name', 'asc'],
}

const queryKeyMap = {
  filter: 'room',
  sort: 'ordering',
}

const queryBuilder = params => Object.keys(params).reduce((acc, key) => ({
  ...acc,
  [queryKeyMap[key] ?? key]: params[key]
}), EMPTY_OBJECT)

export default createListBundle({
  entityName: 'pesticide',
  initialState,
  schema,
  permissions: { keys: ['view_pesticide_application'] },
  fetchHandler: ({ apiFetch, params }) => apiFetch('/pesticides/', params),
  transformParams: queryBuilder,
  urlTest: (url, pattern) => pattern.startsWith('/ipm/pesticides') || url.match(/#\/tasks\/(new|\d+\/edit)/)
})
