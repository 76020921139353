/* eslint-disable */
function checkColorSupport() {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false;
  }

  var chrome = !!window.chrome,
    firefox = /firefox/i.test(navigator.userAgent),
    firefoxVersion,
    electron = typeof process !== 'undefined' && process.versions && process.versions.electron;

  if (firefox) {
    var match = navigator.userAgent.match(/Firefox\/(\d+\.\d+)/);
    if (match && match[1] && Number(match[1])) {
      firefoxVersion = Number(match[1]);
    }
  }
  return chrome || firefoxVersion >= 31.0 || electron;
}

function getLocalStorageSafely() {
  var localStorage;
  try {
    localStorage = window.localStorage;
  } catch (e) {
    // failed: access to localStorage is denied
  }
  return localStorage;
}

var yieldColor = function() {
  var goldenRatio = 0.618033988749895;
  hue += goldenRatio;
  hue = hue % 1;
  return hue * 360;
};

function getLogger({ inNode, ls }) {
  var out = {};

  if (inNode || !ls) {
  module.exports = console;
  return;
  }

  var andlogKey = ls.andlogKey || 'debug'
  if (ls && ls[andlogKey] && window.console) {
  out = window.console;
  } else {
  var methods = "assert,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profileEnd,time,timeEnd,trace,warn".split(","),
      l = methods.length,
      fn = function () {};

  while (l--) {
      out[methods[l]] = fn;
  }
  }
  return out
}

var inNode = typeof window === 'undefined',
  ls = !inNode && getLocalStorageSafely(),
  debugKey = ls && ls.andlogKey ? ls.andlogKey : 'debug',
  debug = ls && ls[debugKey] ? ls[debugKey] : false,
  logger = getLogger({ inNode, ls }),
  bind = Function.prototype.bind,
  hue = 0,
  padding = true,
  separator = '|',
  padLength = 15,
  noop = function() {},
  // if ls.debugColors is set, use that, otherwise check for support
  colorsSupported =
    ls && ls.debugColors ? ls.debugColors !== 'false' : checkColorSupport(),
  bows = null,
  debugRegex = null,
  invertRegex = false,
  moduleColorsMap = {},
  colorScheme = !inNode && window.matchMedia('prefers-color-scheme: dark')?.matches
    ? 'dark'
    : 'light';

if (debug && debug[0] === '!' && debug[1] === '/') {
  invertRegex = true;
  debug = debug.slice(1);
}
debugRegex =
  debug &&
  debug[0] === '/' &&
  new RegExp(debug.substring(1, debug.length - 1));

var logLevels = ['log', 'debug', 'warn', 'error', 'info'];

//Noop should noop
for (var i = 0, ii = logLevels.length; i < ii; i++) {
  noop[logLevels[i]] = noop;
}

bows = function(str, ...args) {
  // If localStorage is not available just don't log
  if (!ls) return noop;

  var identifier, colorString, logfn;

  if (padding) {
    identifier = str.slice(0, padLength);
    identifier += Array(padLength + 3 - identifier.length).join(' ') + separator;
  } else {
    identifier = str + Array(3).join(' ') + separator;
  }

  if (debugRegex) {
    var matches = str.match(debugRegex);
    if ((!invertRegex && !matches) || (invertRegex && matches)) return noop;
  }

  if (!bind) return noop;

  var logArgs = [logger];
  if (colorsSupported) {
    if (!moduleColorsMap[str]) {
      moduleColorsMap[str] = yieldColor();
    }
    var color = moduleColorsMap[str];
    identifier = `%c${identifier}`;
    var lightness = colorScheme === 'dark' ? '75%' : '40%'
    colorString = `color: hsl(${color},99%,${lightness}); font-weight: bold`;

    logArgs.push(identifier, colorString);
  } else {
    logArgs.push(identifier);
  }

  if (args.length) {
    logArgs = logArgs.concat(args);
  }

  logfn = bind.apply(logger.log, logArgs);

  logLevels.forEach(function(f) {
    logfn[f] = bind.apply(logger[f] || logfn, logArgs);
  });
  return logfn;
};

bows.config = function(config) {
  if (config.padLength) {
    padLength = config.padLength;
  }

  if (typeof config.padding === 'boolean') {
    padding = config.padding;
  }

  if (config.separator) {
    separator = config.separator;
  } else if (config.separator === false || config.separator === '') {
    separator = '';
  }
};

export default bows
