import { createSelector } from 'redux-bundler'

import { minutes } from 'milliseconds'

import createListBundle from '~/src/Lib/createListBundle'
import { WasteMethod as schema } from '~/src/Store/Schemas'

export const initialState = {
  search: '',
  ordering: ['name', 'asc'],
}

const { selectWasteMethodListShouldUpdate, ...initialBundle } = createListBundle({
  entityName: 'wasteMethod',
  initialState,
  schema,
  fetchHandler: ({ apiFetch, params }) => apiFetch('/wasteMethods/', { ...params, pagination: 0 }),
  flags: { keys: ['METRC'] },
  staleAfter: minutes(60),
})

export default {
  ...initialBundle,
  selectWasteMethodListShouldNormallyUpdate: selectWasteMethodListShouldUpdate,
  selectWasteMethodListShouldUpdate: createSelector(
    'selectWasteMethodListShouldNormallyUpdate',
    'selectWasteMethodListRaw',
    'selectCurrentMetrcFacilities',
    'selectIsOnline',
    (shouldNormallyUpdate, { failedPermanently, lastSuccess, isLoading }, metrcFacilities, isOnline) => {
      if (shouldNormallyUpdate) return true

      if (!isOnline || failedPermanently || isLoading) return false

      const syncedSinceLastFetch = metrcFacilities.some(
        metrcFacility => metrcFacility.lastWasteMethodRefresh && lastSuccess < new Date(metrcFacility.lastWasteMethodRefresh).getTime()
      )
      if (syncedSinceLastFetch) {
        return true
      }

      return false
    }
  ),
}
