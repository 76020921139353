import { SvgIcon } from '@mui/material'

export const DliIcon = props => (
  <SvgIcon {...props}>
    <path d="M11.9957 21.0001H12.0044C12.4796 21.0001 12.8597 20.6222 12.8597 20.1498V19.3252C12.8597 18.8528 12.4796 18.4749 12.0044 18.4749H11.9957C11.5205 18.4749 11.1404 18.8528 11.1404 19.3252V20.1498C11.1404 20.6222 11.5205 21.0001 11.9957 21.0001Z" />
    <path d="M5.30813 18.2421C5.64508 18.5771 6.18939 18.5771 6.52634 18.2421L6.8633 17.9072C7.20025 17.5722 7.19161 17.031 6.8633 16.7046L6.85466 16.6961C6.69324 16.5352 6.47409 16.4448 6.24555 16.4448C6.01701 16.4448 5.79786 16.5352 5.63644 16.6961L5.29949 17.031C4.97117 17.3746 4.97117 17.9072 5.30813 18.2421Z" />
    <path d="M18.9167 11.5828V11.5914C18.9167 12.0638 19.2969 12.4417 19.7721 12.4417H20.6447C21.1199 12.4417 21.5001 12.0638 21.5001 11.5914V11.5828C21.5001 11.1104 21.1199 10.7324 20.6447 10.7324H19.7721C19.2969 10.7324 18.9167 11.1104 18.9167 11.5828Z" />
    <path d="M4.23661 10.7327H3.35534C2.88015 10.7327 2.5 11.1106 2.5 11.583V11.5916C2.5 12.064 2.88015 12.442 3.35534 12.442H4.22797C4.70316 12.442 5.08331 12.064 5.08331 11.5916V11.583C5.09195 11.1106 4.7118 10.7327 4.23661 10.7327Z" />
    <path d="M14.0918 7.9927C13.7834 7.81449 13.5936 7.48703 13.5936 7.13287V3.99497H10.4149V7.13287C10.4149 7.48771 10.2244 7.8157 9.91506 7.99366C8.49214 8.81214 7.59933 10.4315 7.87204 12.2514C8.15045 14.0522 9.66082 15.5045 11.4772 15.7255C14.0167 16.0306 16.1857 14.0622 16.1857 11.6008C16.1857 10.0551 15.3443 8.71637 14.0918 7.9927ZM11.3562 16.7132C9.09246 16.4383 7.22614 14.6425 6.88053 12.3999C6.54355 10.1573 7.64952 8.14676 9.41216 7.13287V3.85923C9.41216 3.38665 9.80097 3 10.2762 3H13.7323C14.2076 3 14.5964 3.38665 14.5964 3.85923V7.13287C16.143 8.02647 17.1885 9.68477 17.1885 11.6008C17.1885 14.6511 14.5013 17.0913 11.3562 16.7132Z" />
    <path d="M17.1367 17.9154L17.4737 18.2504C17.8106 18.5854 18.3549 18.5854 18.6919 18.2504C19.0288 17.9154 19.0288 17.3743 18.6919 17.0393L18.3549 16.7043C18.018 16.3693 17.4737 16.3779 17.1454 16.7043C16.7998 17.0479 16.7998 17.5804 17.1367 17.9154Z" />
    <path d="M12.1809 8.51782C12.4578 8.51782 12.6823 8.74055 12.6823 9.01531V11.4383L13.8847 12.6314C14.0805 12.8257 14.0805 13.1407 13.8847 13.335C13.6889 13.5293 13.3715 13.5293 13.1757 13.335L11.6796 11.8505V9.01531C11.6796 8.74055 11.904 8.51782 12.1809 8.51782Z" />
  </SvgIcon>
)
DliIcon.displayName = 'Icon/Dli'

export default DliIcon
